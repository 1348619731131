import { toastr } from "react-redux-toastr";
import { updateAdminEmailRequest, updatePartnerEmailRequest } from "../../services/emailUpdateServices";
import emailUpdateConstant from "../../util/emailUpdateConstant";
import {
  START_LOADER,
  STOP_LOADER,
  EMAIL_UPDATE_API_REQUEST,
  EMAIL_UPDATE_API_END,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_FAILURE
} from "../actionTypes";

const { EMAIL_UPDATE_API_TYPES } = emailUpdateConstant;

export const updateEmailAddressAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: EMAIL_UPDATE_API_REQUEST,
      payload: EMAIL_UPDATE_API_TYPES.EMAIL_UPDATE,
    } );

    let response;

    if( param.roleType === "admin" ) {
      response = await updateAdminEmailRequest( param );
    }
    else {
      response = await updatePartnerEmailRequest( param );
    }

    dispatch( { type: UPDATE_EMAIL_SUCCESS, payload: response.data } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    const err = Object.values( error?.data?.error || {} ) || "";

    dispatch( { type: STOP_LOADER } );
    dispatch( { type: UPDATE_EMAIL_FAILURE, payload: error } );

    if( err[ 0 ] ) {
      toastr.error( err[ 0 ] );
    }
    else {
      toastr.error( error.data.message );
    }
  }
  finally {
    dispatch( {
      type: EMAIL_UPDATE_API_END,
    } );
  }
};
