import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import NotificationsIcon from "@material-ui/icons/Notifications";
import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import constant from "../../../util/constant";

const { BACK_BUTTON_COMPONENTS, ROUTES, NO_NOTIFICATION_HEADER, APPLICATION_STATUS, ROLE } = constant;

const Header = () => {
  const { user, partner, adminDetail } = useSelector( state => state );
  const isPartner = user.user[ "custom:role" ] === ROLE.PARTNER;
  const history = useHistory();
  const location = useLocation();
  const activeComponent = location?.pathname?.split( "/" )[ 2 ] || "";
  const isAvailableBack = BACK_BUTTON_COMPONENTS.some(
    path => path === activeComponent
  );
  const notificationsRoute = isPartner ? ROUTES.PARTNER_NOTIFICATIONS : ROUTES.ADMIN_NOTIFICATIONS;

  const [ unreadNotificationsCount, setunreadNotificationsCount ] = useState( 0 );
  const [ showNotificationIcon, setShowNotificationIcon ] = useState( false );

  useEffect( () => {
    const count = isPartner ? partner.partnerDetail.unreadNotificationsCount : adminDetail.data.unreadNotificationsCount;

    setunreadNotificationsCount( count );

    const isUserActive = isPartner ?
      ( partner.partnerDetail.isActive && ( partner.partnerDetail.applicationStatus === APPLICATION_STATUS.APPROVED ) )
      : adminDetail.data.isActive;

    setShowNotificationIcon( isUserActive );
  }, [ partner, adminDetail ] );

  return (
    <>
      <div className='main-right-wrapper'>
        {!isAvailableBack && !NO_NOTIFICATION_HEADER.includes( activeComponent ) && (
          <div className='main-head-wrapper main-heading-with-right-side'>
            <h3 className='main-heading'>Hi, {user.user.name}</h3>
            {
              showNotificationIcon &&
            <div className='notification-icon-box' onClick={() => history.push( notificationsRoute )} data-testid='back'>
              <NotificationsIcon />
              {Boolean( unreadNotificationsCount ) && <span className='notification-count'>{unreadNotificationsCount}</span>}
            </div>
            }
          </div>
        )}
        {isAvailableBack && (
          <div className='main-head-wrapper'>
            <Button variant='link-secondary' className="p-0" onClick={() => history.goBack()}>
              <ArrowBackIosIcon />
              Back
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
