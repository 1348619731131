import PropTypes from "prop-types";
import React, { useEffect, memo } from "react";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { getGamerDetailAction } from "../../../store/actions/adminGamer";
import constant from "../../../util/constant";
import { getBackNavigationRoute } from "../../../util/helpers";
import FilterIcon from "../../shared/FilterIcon/FilterIcon";
import BackButton from "../../shared/backButton";
import ProfileImage from "../../shared/profileImage";

const { ROUTES } = constant;

const ListSubHeader = ( {
  title,
  searchHandle,
  searchText,
  searchPlaceholder,
  showFilter,
  showFilterScreen,
  isFilterApplied,
  gamerId,
} ) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const backScreens = [ "adminGamerDetail" ];
  const { gamerDetail } = useSelector( state => ( {
    gamerDetail: state.adminGamer.gamerDetail,
  } ) );

  useEffect( () => {
    if( gamerId ) {
      dispatch( getGamerDetailAction( gamerId ) );
    }
  }, [ gamerId ] );

  const handleBackNavigation = () => {
    return getBackNavigationRoute( ROUTES.GAMER_SECTION, location, backScreens );
  };

  return (
    <>
      <BackButton
        backLink={handleBackNavigation()}
        state={location.state}
      />
      <div className="page-head-wrapper">
        <div className='d-flex align-items-center'>
          <ProfileImage profileImage={gamerDetail?.profileImage} />
          <div className='profile-title mb-0'>{gamerDetail.gamerFullName}</div>
        </div>
        <div className="page-head-right">
          <Form.Group className="custom-search w-300">
            <span className="icon-search"></span>
            <Form.Control
              type="text"
              placeholder={searchPlaceholder}
              data-testid='searchKeyTest'
              onChange={searchHandle}
              value={searchText}
              name='searchKey'
            />
          </Form.Group>
          <FilterIcon
            showFilter={showFilter}
            showFilterScreen={showFilterScreen}
            isFilterApplied={isFilterApplied} />
        </div>
      </div>
      <div className="page-head-wrapper">
        <h4 className="page-heading">{title}</h4>
      </div>
    </>
  );
};

ListSubHeader.propTypes = {
  title: PropTypes.string,
  searchHandle: PropTypes.func,
  searchText: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  gamerId: PropTypes.string,
  isFilterApplied: PropTypes.func,
  showFilterScreen: PropTypes.func,
  showFilter: PropTypes.string,

};

export default memo( ListSubHeader );
