import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";

import { useForm } from "../../../hooks";
import { getLeaderboardAction } from "../../../store/actions/leaderboard";
import constant from "../../../util/constant";
import { getUrlParameter } from "../../../util/helpers";
import LeaderboardAllParticipants from "../../shared/tournamentLeaderboard/leaderboardAllParticipants";

const { ORDER_BY } = constant;

const AdminLeaderboardAllParticipants = () => {
  const isAdmin = true;
  const { tournamentId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    leaderboard: {
      participants = 0,
      participantsList = [],
    },
  } = useSelector( state => ( {
    leaderboard: state.leaderboard.data,
    apiType: state.leaderboard.apiType,
    status: state.leaderboard.status,
  } ) );

  const { values, handleChange } = useForm(
    {
      searchKey: getUrlParameter( "searchKey", null ),
      sortBy: getUrlParameter( "sortBy", "rank" ),
      sortOrder: getUrlParameter( "sortOrder", ORDER_BY.ASC ),
    },
    {}
  );

  const requestParams = Object.assign( {
    sortOrder: values.sortOrder,
    fetchAllRecords: true,
  },
  values.searchKey && { searchKey: values.searchKey },
  values.sortBy && { sortBy: values.sortBy }
  );

  useEffect( () => {
    if( tournamentId ) {
      dispatch( getLeaderboardAction( { tournamentId, isAdmin }, requestParams ) );
    }
  }, [
    values.sortOrder,
    values.sortBy,
    values.searchKey,
  ] );

  return (
    <>
      <LeaderboardAllParticipants
        participants={participants}
        participantsList={participantsList}
        values={values}
        handleChange={handleChange}
        locationState={location.state}
      />
    </>
  );
};

export default AdminLeaderboardAllParticipants;
