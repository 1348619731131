import constant from "../../util/constant";
import {
  PARTNER_GAMER_LIST_API_REQUEST,
  PARTNER_GAMERS_API_END,
  PARTNER_GAMERS_LIST_SUCCESS,
  PARTNER_GAMERS_LIST_FAILURE,
  PARTNER_GAMER_DETAIL_API_REQUEST,
  PARTNER_GAMER_DETAIL_SUCCESS,
  PARTNER_GAMER_DETAIL_FAILURE,
  PARTNER_GAMER_TOURNAMENTS_LIST_API_REQUEST,
  PARTNER_GAMER_TOURNAMENTS_LIST_SUCCESS,
  PARTNER_GAMER_TOURNAMENTS_LIST_FAILURE,
  PARTNER_GAMER_ASSETS_LIST_API_REQUEST,
  PARTNER_GAMER_ASSETS_LIST_SUCCESS,
  PARTNER_GAMER_ASSETS_LIST_FAILURE,
  PARTNER_GAMER_LINKED_GAMES_API_REQUEST,
  PARTNER_GAMER_LINKED_GAMES_SUCCESS,
  PARTNER_GAMER_LINKED_GAMES_FAILURE,
  PARTNER_GAMER_LINKED_GAMES_DETAIL_API_REQUEST,
  PARTNER_GAMER_LINKED_GAMES_DETAIL_SUCCESS,
  PARTNER_GAMER_LINKED_GAMES_DETAIL_FAILURE,
  PARTNER_GAMER_LINKED_GAMES_ASSETS_API_REQUEST,
  PARTNER_GAMER_LINKED_GAMES_ASSETS_SUCCESS,
  PARTNER_GAMER_LINKED_GAMES_ASSETS_FAILURE,
  PARTNER_GAMER_LINKED_GAMES_OFFERS_API_REQUEST,
  PARTNER_GAMER_LINKED_GAMES_OFFERS_SUCCESS,
  PARTNER_GAMER_LINKED_GAMES_OFFERS_FAILURE,
  PARTNER_GAMER_EXCHANGE_TRANSACTION_API_REQUEST,
  PARTNER_GAMER_EXCHANGE_TRANSACTION_SUCCESS,
  PARTNER_GAMER_EXCHANGE_TRANSACTION_FAILURE
} from "../actionTypes";

const { API_STATUS } = constant;

const DEFAULT_STATE = {
  loading: false,
  message: "",
  status: "",
  apiType: "",
  gamerLists: [],
  gamerNames: [],
  linkedGamesRange: {
    min: 0,
    max: 0,
  },
  qxPointsRange: {
    min: 0,
    max: 0,
  },
  gamerDetail: {},
  tournamentList: [],
  gamerPlaceRange: {
    min: 0,
    max: 0,
  },
  gamerTournamentsFilterList: {
    tournamentNameFilterList: [],
    gameNameFilterList: [],
  },
  assets: {
    assets: [],
    gameNames: [],
    assetNames: [],
    assetIds: [],
    qxPointRange: { min: 0, max: 0 },
  },
  gamerLinkedGamesList: [],
  gameList: [],
  LinkedGameDetails: {},
  linkedGamesAssetList: {},
  gamerLinkedGameOffers: [],
  exchangeTransaction: {
    transaction: [],
    gameNames: [],
    assetNames: [],
    qxPointMinMax: [],
    qxPointsRange: { min: 0, max: 0 },
  },
  offerNames: [],
  gameNames: [],
};

// TODO: refactor to avoid complexity
// eslint-disable-next-line complexity
const partnerGamer = ( state = DEFAULT_STATE, action = "" ) => {
  switch ( action.type ) {
    case PARTNER_GAMER_LIST_API_REQUEST:
    case PARTNER_GAMER_DETAIL_API_REQUEST:
    case PARTNER_GAMER_TOURNAMENTS_LIST_API_REQUEST:
    case PARTNER_GAMER_ASSETS_LIST_API_REQUEST:
    case PARTNER_GAMER_LINKED_GAMES_API_REQUEST:
    case PARTNER_GAMER_LINKED_GAMES_DETAIL_API_REQUEST:
    case PARTNER_GAMER_LINKED_GAMES_ASSETS_API_REQUEST:
    case PARTNER_GAMER_LINKED_GAMES_OFFERS_API_REQUEST:
    case PARTNER_GAMER_EXCHANGE_TRANSACTION_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
      };
    case PARTNER_GAMERS_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case PARTNER_GAMERS_LIST_SUCCESS:
      return {
        ...state,
        gamerLists: action.payload.gamerList,
        gamerNames: action.payload.gamerNames,
        linkedGamesRange: {
          min: action.payload.gamerLinkedGamesMinMax.min,
          max: action.payload.gamerLinkedGamesMinMax.max,
        },
        qxPointsRange: {
          min: action.payload.qxPointsMinMax.min,
          max: action.payload.qxPointsMinMax.max,
        },
        status: API_STATUS.SUCCESS,
      };
    case PARTNER_GAMERS_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        loading: false,
        gamerLists: [],
        gamerNames: [],
        linkedGamesRange: {
          min: 0,
          max: 0,
        },
        qxPointsRange: {
          min: 0,
          max: 0,
        },
      };
    case PARTNER_GAMER_DETAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        gamerDetail: action.payload,
      };
    case PARTNER_GAMER_DETAIL_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        gamerDetail: {},
      };
    case PARTNER_GAMER_TOURNAMENTS_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        tournamentList: action.payload.tournamentList,
        gamerPlaceRange: {
          min: action.payload.gamerPlaceMinMax.min,
          max: action.payload.gamerPlaceMinMax.max,
        },
        gamerTournamentsFilterList: {
          tournamentNameFilterList: action.payload.tournamentNameFilterList,
          gameNameFilterList: action.payload.gameNameFilterList,
        },
      };
    case PARTNER_GAMER_TOURNAMENTS_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        loading: false,
        tournamentList: [],
        gamerPlaceRange: {
          min: 0,
          max: 0,
        },
        gamerTournamentsFilterList: {
          tournamentNameFilterList: [],
          gameNameFilterList: [],
        },
      };
    case PARTNER_GAMER_ASSETS_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        assets: {
          assets: action.payload.dataList,
          gameNames: action.payload.gameNames,
          assetNames: action.payload.assetNames,
          assetIds: action.payload.assetIds,
          qxPointRange: action.payload.qxPointMinMax,
        },
      };
    case PARTNER_GAMER_ASSETS_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        loading: false,
      };
    case PARTNER_GAMER_LINKED_GAMES_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        gamerLinkedGamesList: action.payload.linkedGames.userGames,
        gameList: action.payload.filterLinkedGames,
      };
    case PARTNER_GAMER_LINKED_GAMES_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        gamerLinkedGamesList: [],
        gameList: [],
        loading: false,
      };
    case PARTNER_GAMER_LINKED_GAMES_DETAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        LinkedGameDetails: action.payload.linkedGameDetails,
      };
    case PARTNER_GAMER_LINKED_GAMES_DETAIL_FAILURE:
      return {
        ...state,
        LinkedGameDetails: {},
        status: API_STATUS.FAILURE,
        loading: false,
      };
    case PARTNER_GAMER_LINKED_GAMES_ASSETS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        linkedGamesAssets: action.payload,
      };
    case PARTNER_GAMER_LINKED_GAMES_ASSETS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        linkedGamesAssetList: {},
        loading: false,
      };
    case PARTNER_GAMER_LINKED_GAMES_OFFERS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        gamerLinkedGameOffers: action.payload.gamerLinkedGameOffers,
        offerNames: action.payload.offerNames,
        gameNames: action.payload.gameNames,
      };
    case PARTNER_GAMER_LINKED_GAMES_OFFERS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        gamerLinkedGameOffers: [],
        loading: false,
      };
    case PARTNER_GAMER_EXCHANGE_TRANSACTION_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        exchangeTransaction: {
          transaction: action.payload.transactionList,
          gameNames: action.payload.gameNames,
          assetNames: action.payload.assetNames,
          qxPointsRange: {
            min: action.payload.qxPointMinMax.min,
            max: action.payload.qxPointMinMax.max },
        },
      };

    case PARTNER_GAMER_EXCHANGE_TRANSACTION_FAILURE: {
      return {
        ...state,
        status: API_STATUS.FAILURE,
        loading: false,
        exchangeTransaction: {
          transaction: [],
          gameNames: [],
          assetNames: [],
          qxPointMinMax: [],
          qxPointsRange: { min: 0, max: 0 } },
      };
    }

    default:
      return state;
  }
};

export default partnerGamer;
