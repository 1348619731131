import PropTypes from "prop-types";
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import constant from "../../../../util/constant";
import { formatNumberAsPerLocale } from "../../../../util/helpers";
import { ScrollPage } from "../../../shared/customScrollBar";
import TableHeaderRow from "../../../shared/tableHeadingRow";

const { BRANDING: { APP_SHORT_NAME } } = constant;

const ExchangeTransactionList = ( {
  exchangeTransactionList,
  memoizedTableConfig,
  memoizedHandleClick,
  showFilter,
} ) => {
  return (
    <div className="table-container gamer-exchange-transactions-table">
      <div className="table-head">
        <div className="table-row pos-relative">
          <TableHeaderRow
            configData={memoizedTableConfig}
            onAscOrder={memoizedHandleClick}
            onDescOrder={memoizedHandleClick}
          />
        </div>
      </div>
      <Scrollbars renderThumbVertical={ScrollPage}
        className={`custom-scroll-height max ${ !showFilter ? "" : "full-height" }`}
      >
        <div className="table-body no-scrollbar" >
          {Boolean( exchangeTransactionList.length ) && exchangeTransactionList.map( ( txn, index ) => (
            <div className="table-row" key={index}>
              <div className="td">
                <img src={txn.iconUrl} alt="games" className='logo' />
                <span className='big-text'>{txn.name}</span>
              </div>
              <div className="td"><span className='text-green font-medium'>{formatNumberAsPerLocale( txn.QXPoints )}</span></div>
              <div className="td">{formatNumberAsPerLocale( txn.soldAssetQuantity )}</div>
              <div className="td multiline-text">
                <span className='d-block'>{formatNumberAsPerLocale( txn.soldAssetQuantity )} {txn.assetName} ({txn.unitPriceQxPoints} { APP_SHORT_NAME } Points each)</span>
              </div>
              <div className="td">{txn.transactionDate}</div>
            </div>
          ) )}
          {!exchangeTransactionList.length && (
            <div className='no-record' data-testid='no-record-found'>
                No Exchange Transaction Found
            </div>
          )}
        </div>
      </Scrollbars>
    </div>
  );
};

ExchangeTransactionList.propTypes = {
  exchangeTransactionList: PropTypes.array,
  memoizedTableConfig: PropTypes.object,
  memoizedHandleClick: PropTypes.func,
  showFilter: PropTypes.bool,
};

export default ExchangeTransactionList;
