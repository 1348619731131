import constant from "../../util/constant";
import {
  ANALYTICS_API_END,
  EXCHANGE_TRANSACTION_API_REQUEST,
  EXCHANGE_TRANSACTION_FAILURE,
  EXCHANGE_TRANSACTION_SUCCESS,
  GET_GAME_DETAILS_SUCCESS,
  GET_GAME_DETAILS_FAILURE,
  PARTNER_ANALYTICS_GAMER_LIST_SUCCESS,
  PARTNER_ANALYTICS_GAMER_LIST_FAILURE,
  PARTNER_ANALYTICS_GAMER_LIST_REQUEST,
  PARTNER_ANALYTICS_ASSETS_LIST_REQUEST,
  PARTNER_ANALYTICS_ASSETS_LIST_SUCCESS,
  PARTNER_ANALYTICS_ASSETS_LIST_FAILURE,
  CLEAR_QX_MEMBERS_GRAPH_DATA,
  CLEAR_EXCHANGE_TRANSACTION_GRAPH_DATA,
  CLEAR_ANALYTICS_GAME_DETAIL,
  TRANSACTION_LIST_SUCCESS,
  TRANSACTION_LIST_FAILURE,
  ANALYTICS_LINKED_GAMER_LIST_REQUEST,
  ANALYTICS_LINKED_GAMER_LIST_SUCCESS,
  ANALYTICS_LINKED_GAMER_LIST_FAILURE
} from "../actionTypes";

const { API_STATUS } = constant;

const DEFAULT_STATE = {
  loading: false,
  message: "",
  status: "",
  apiType: "",
  exchangeTransaction: {
    transaction: [],
    gameNames: [],
    assetNames: [],
    gamerNames: [],
    qxPointMinMax: [],
    qxPointsRange: { min: 0, max: 0 },
    gamerPlaceRange: { min: 0, max: 0 },
    initialDate: null,
    offerNames: [],
  },
  gameDetails: {},
  gamerLists: [],
  gamerFilterList: {
    gamerNames: [],
    gameNames: [],
    gamerPlans: [],
  },
  totalGamers: 0,
  initialDate: null,
  assetsLists: {},
  assetsFilterList: {
    assetIdList: null,
    assetNameList: null,
    quantityMinMax: { min: 0, max: 0 },
    priceMinMax: { min: 0, max: 0 },
  },
  optInTransaction: {
    transactionList: [],
    gameNames: [],
    gamerNames: [],
    qxPointMinMax: [],
    qxPointsRange: { min: 0, max: 0 },
    offerNames: [],
    transactionFor: [],
  },
  linkedGamersList: [],
  gamerNames: [],
  gamerPlans: [],
  linkedGamerGamesRange: {
    min: 0,
    max: 0,
  },
};

// TODO: refactor to avoid complexity
// eslint-disable-next-line complexity
const partnerAnalytics = ( state = DEFAULT_STATE, action = "" ) => {
  switch ( action.type ) {
    case EXCHANGE_TRANSACTION_API_REQUEST:
    case PARTNER_ANALYTICS_GAMER_LIST_REQUEST:
    case PARTNER_ANALYTICS_ASSETS_LIST_REQUEST:
    case ANALYTICS_LINKED_GAMER_LIST_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
      };
    case ANALYTICS_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case EXCHANGE_TRANSACTION_SUCCESS:
      return {
        ...state,
        exchangeTransaction: {
          transaction: action.payload.transactionList,
          gameNames: action.payload.gameNames,
          assetNames: action.payload.assetNames,
          gamerNames: action.payload.gamerNames,
          offerNames: action.payload.offerNames,
          qxPointsRange: {
            min: action.payload.qxPointMinMax.min,
            max: action.payload.qxPointMinMax.max,
          },
          gamerPlaceRange: {
            min: action.payload.gamerPlaceMinMax.min,
            max: action.payload.gamerPlaceMinMax.max,
          },
          totalExchangeTxnAmount: action.payload?.totalExchangeTxnAmount || 0,
          initialDate: action.payload.initialDate,
          analyticsGraphData: action.payload?.analyticsGraphData,
        },
        status: API_STATUS.SUCCESS,
      };
    case EXCHANGE_TRANSACTION_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        redeemTransaction: {
          transaction: [],
          gameNames: [],
          assetNames: [],
          gamerNames: [],
          qxPointMinMax: [],
          qxPointsRange: { min: 0, max: 0 },
          gamerPlaceRange: { min: 0, max: 0 },
          offerNames: [] },
      };
    case GET_GAME_DETAILS_SUCCESS:
      return {
        ...state,
        gameDetails: action.payload,
        status: API_STATUS.SUCCESS,
      };
    case GET_GAME_DETAILS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
      };
    case PARTNER_ANALYTICS_GAMER_LIST_SUCCESS:
      return {
        ...state,
        gamerLists: action.payload.gamerList,
        gamerFilterList: {
          gamerNames: action.payload.gamerNames,
          gameNames: action.payload.gameName,
          gamerPlans: action.payload.gamerPlans,
        },
        totalGamers: action.payload.totalGamers,
        initialDate: action.payload.initialDate,
        analyticsGraphData: action.payload?.analyticsGraphData,
        status: API_STATUS.SUCCESS,
      };
    case PARTNER_ANALYTICS_GAMER_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        loading: false,
        gamerLists: [],
        gamerFilterList: {
          gamerNames: [],
          gameNames: [],
          gamerPlans: [],
        },
      };
    case PARTNER_ANALYTICS_ASSETS_LIST_SUCCESS:
      return {
        ...state,
        assetsLists: action.payload.assetsList.analyticsAssetsLists,
        assetsFilterList: {
          assetIdList: action.payload.assetsList.assetIdList,
          assetNameList: action.payload.assetsList.assetNameList,
          quantityMinMax: action.payload.assetsList.quantityMinMax,
          priceMinMax: action.payload.assetsList.priceMinMax,
        },
        status: API_STATUS.SUCCESS,
      };
    case PARTNER_ANALYTICS_ASSETS_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        loading: false,
        assetsLists: {},
        assetsFilterList: {
          assetIdList: [],
          assetNameList: [],
          quantityMinMax: { min: 0, max: 0 },
          priceMinMax: { min: 0, max: 0 },
        },
      };
    case CLEAR_QX_MEMBERS_GRAPH_DATA:
      return {
        ...state,
        gamerLists: [],
        totalGamers: 0,
        initialDate: null,
        analyticsGraphData: "",
      };
    case CLEAR_EXCHANGE_TRANSACTION_GRAPH_DATA:
      return {
        ...state,
        exchangeTransaction: {
          transaction: [],
          totalExchangeTxnAmount: 0,
          initialDate: null,
          analyticsGraphData: "",
          qxPointMinMax: [],
          qxPointsRange: { min: 0, max: 0 },
        },
      };
    case CLEAR_ANALYTICS_GAME_DETAIL:
      return {
        ...state,
        gameDetails: {},
      };
    case TRANSACTION_LIST_SUCCESS:
      return {
        ...state,
        optInTransaction: {
          transactionList: action.payload.transactionList,
          gameNames: action.payload.gameNames,
          gamerNames: action.payload.gamerNames,
          offerNames: action.payload.offerNames,
          qxPointsRange: {
            min: action.payload.qxPointMinMax.min,
            max: action.payload.qxPointMinMax.max,
          },
          transactionFor: action.payload.transactionFor,
        },
        status: API_STATUS.SUCCESS,
      };
    case TRANSACTION_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        optInTransaction: {
          transactionList: [],
          gameNames: [],
          gamerNames: [],
          qxPointMinMax: [],
          qxPointsRange: { min: 0, max: 0 },
          offerNames: [],
          transactionFor: [] },
      };
    case ANALYTICS_LINKED_GAMER_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        linkedGamersList: action.payload.linkedGamersList,
        gamerNames: action.payload.gamerNames,
        gamerPlans: action.payload.gamerPlans,
        linkedGamerGamesRange: {
          min: action.payload.linkedGamerGamesMinMax.min,
          max: action.payload.linkedGamerGamesMinMax.max,
        },
      };
    case ANALYTICS_LINKED_GAMER_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        loading: false,
        linkedGamersList: [],
        gamerNames: [],
        gamerPlans: [],
        linkedGamerGamesRange: {
          min: 0,
          max: 0,
        },
      };
    default:
      return state;
  }
};

export default partnerAnalytics;
