import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

function ModificationModal( props ) {
  const [ reviewNoteSuggestion, setReviewNoteSuggestion ] = useState(
    props?.modificationModalData?.value
  );
  const inputRef = useRef();

  useEffect( () => {
    inputRef?.current?.focus();
  }, [] );

  return (
    <>
      <Modal
        show={props?.ModificationModalStatus}
        centered
        className='modification-modal'
      >
        <Modal.Header>
          <h2 className='modal-heading'>Suggest Modification</h2>
        </Modal.Header>
        <Modal.Body className='pb-0'>
          <Form.Group className='mb-0'>
            <Form.Label>For {props?.modificationModalData?.label}</Form.Label>
            <Form.Control
              as='textarea'
              type='text'
              placeholder={props?.modificationModalData?.label}
              value={reviewNoteSuggestion}
              onChange={e => setReviewNoteSuggestion( e.target.value )}
              ref={inputRef}
              data-testid='modification-textarea'
            />
            <Form.Control
              type='hidden'
              value={props?.modificationModalData?.type}
            />
          </Form.Group>
          <div className='invalid-feedback d-block'>
            {props?.modificationModalError ?? null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {props?.isShowResolveButton && (
            <Button
              variant='outline-primary'
              className='btn-md'
              onClick={() =>
                props.onResolveModification( props?.modificationModalData?.type )
              }
              data-testid='resolve-modification'
            >
              Resolve
            </Button>
          )}
          <Button
            variant='outline-primary'
            className='btn-md'
            onClick={() => props?.setModificationModalStatus( false )}
            data-testid='cancel-modification'
          >
            Cancel
          </Button>
          <Button
            variant='primary'
            className='btn-md'
            onClick={() =>
              props?.saveModification(
                props?.modificationModalData?.type,
                reviewNoteSuggestion
              )
            }
            data-testid='save-modification'
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

ModificationModal.propTypes = {
  modificationModalData: PropTypes.object,
  saveModification: PropTypes.func,
  ModificationModalStatus: PropTypes.string,
  onResolveModification: PropTypes.func,
  setModificationModalStatus: PropTypes.func,
  modificationModalError: PropTypes.string,
  isShowResolveButton: PropTypes.string,
};

export default ModificationModal;
