import "./../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/sass/index.scss";
// eslint-disable-next-line import/no-unresolved
import "@aws-amplify/ui-react/styles.css";

import {
  Authenticator,
  useAuthenticator
} from "@aws-amplify/ui-react";
import { Auth, Amplify } from "aws-amplify";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { BrowserRouter as Router } from "react-router-dom";
import { tokenExpireRedirection } from "../services/api";
import { getAdminDetailAction } from "../store/actions/adminUser";
import { setAuthorizationToken, setCurrentUser } from "../store/actions/auth";
import { getPartnerDetailAction } from "../store/actions/partner";
import { translate } from "../translate";
import { configureAxios } from "../util/apiMiddleware";
import constant from "../util/constant";
import { awsSecret } from "../util/helpers";
import { logoColor } from "../util/icons";
import RouteWrapper from "./RouteWrapper";

const { ROLE, AUTH_STATE, ERROR_MESSAGES } = constant;
const config = awsSecret();

Amplify.configure( config );

configureAxios();
translate();

const App = () => {
  const [ authState, setAuthState ] = React.useState();
  const [ user, setUser ] = React.useState();
  const disPatch = useDispatch();
  const { partnerLoading, adminLoading } = useSelector( state => ( { partnerLoading: state.partner.loading,
    adminLoading: state.adminDetail.loading }
  ) );
  const { route } = useAuthenticator( context => [ context.route ] );
  const { user: userData } = useAuthenticator( context => [ context.user ] );
  const loading = partnerLoading || adminLoading;
  const isAdmin = /^true$/i.test( process.env.REACT_APP_IS_ADMIN );
  const CUSTOM_ROLE = "custom:role";
  const checkRoleDefined = ( attr, roleType ) =>
    attr[ CUSTOM_ROLE ] && attr[ CUSTOM_ROLE ] === roleType;

  const handleAuthentication = async ( authData ) => {
    const { signInUserSession, attributes } = authData;

    tokenExpireRedirection( disPatch, () => {
      setAuthState( "" );
    } );

    if( checkRoleDefined( attributes, ROLE.PARTNER ) && !isAdmin ) {
      localStorage.setItem( "role", ROLE.PARTNER );

      const { username } = signInUserSession.accessToken.payload;

      disPatch( setCurrentUser( { ...attributes, username } ) );
      setAuthorizationToken( signInUserSession.accessToken.jwtToken );
      disPatch( getPartnerDetailAction( attributes.sub ) );
    }
    else if( checkRoleDefined( attributes, ROLE.ADMIN ) && isAdmin ) {
      localStorage.setItem( "role", ROLE.ADMIN );

      const { username } = signInUserSession.accessToken.payload;

      disPatch( setCurrentUser( { ...attributes, username } ) );
      setAuthorizationToken( signInUserSession.accessToken.jwtToken );
      disPatch( getAdminDetailAction( attributes.sub ) );
    }
    else {
      toastr.error( "Error", "User does not exist." );

      try {
        await Auth.signOut();
        setAuthState();
        setUser();
      }
      catch ( error ) {
        toastr.error( "Error", error );
      }
    }
  };

  const authentication = ( authData ) => {
    if( route === AUTH_STATE.AUTHENTICATED ) {
      handleAuthentication( authData );
    }

    setAuthState( route );
    setUser( authData );
  };

  useEffect( () => {
    if( route === AUTH_STATE.SIGN_OUT ) {
      setAuthState( "" );
    }
  }, [ route ] );

  useEffect( () => {
    if( userData?.attributes && userData?.attributes[ CUSTOM_ROLE ] ) {
      authentication( userData );
    }
    else if( userData && route === AUTH_STATE.AUTHENTICATED ) {
      Auth.currentAuthenticatedUser( { bypassCache: true } )
        .then( ( data ) => {
          authentication( data );
        } )
        .catch( () => {
          toastr.error( ERROR_MESSAGES.EXCEPTION_ERROR );
        } );
    }
  }, [ userData, route ] );

  const services = {
    handleSignUp: async ( formData ) => {
      const param = {
        ...formData,
        attributes: {
          ...formData.attributes,
          [ CUSTOM_ROLE ]: ROLE.PARTNER,
        },
      };

      return await Auth.signUp( param );
    },
  };

  return authState === AUTH_STATE.AUTHENTICATED && user && !loading
    ? (
      <Router>
        <RouteWrapper />
      </Router>
    )
    : (
      <div className='App' slot='sign-in'>
        <div className='banner-customizable'>
          <center>
            <img src={logoColor} alt='logo' />
          </center>
        </div>
        <Authenticator
          hideSignUp={isAdmin}
          services={services}
          formFields={{
            signIn: {
              username: {
                order: 1,
                label: "Username *",
                placeholder: "Enter your username",
                labelHidden: false,
                isRequired: true,
              },
              password: {
                order: 2,
                label: "Password *",
                placeholder: "Enter your password",
                labelHidden: false,
                isRequired: true,
              },
            },
            signUp: {
              name: {
                order: 1,
                label: "Name *",
                placeholder: "Enter your full name",
                labelHidden: false,
                isRequired: true,
              },
              username: {
                order: 2,
                label: "Username *",
                placeholder: "Enter your username",
                labelHidden: false,
              },
              email: {
                order: 3,
                label: "Email *",
                placeholder: "Enter your email address",
                labelHidden: false,
                isRequired: true,
              },
              password: {
                order: 4,
                label: "Password *",
                placeholder: "Enter your password",
                labelHidden: false,
              },
              // eslint-disable-next-line camelcase
              confirm_password: {
                order: 5,
                label: "Confirm Password *",
                placeholder: "Enter confirm password",
                labelHidden: false,
              },
            },
          }} />
      </div>
    );
};

export default App;
