import { API_URL } from "../util/apiPaths";
import { request } from "./api.js";

export function getAdminGames( params ) {
  return request( "GET", API_URL.ADMIN_GAMES, params );
}

export function getAdminGameDetails( params ) {
  return request( "GET", `${ API_URL.ADMIN_GAMES }/${ params }` );
}

export function addAdminModification( params ) {
  return request( "PUT", API_URL.ADMIN_GAMES_MODIFICATION, params );
}

export function updatePartnerGameStatus( params ) {
  return request( "PUT", API_URL.UPDATE_PARTNER_GAME_STATUS, params );
}

export function addAdminComment( params ) {
  return request( "POST", API_URL.ADD_ADMIN_COMMENT, params );
}

export function enableDisableGameRequest( params ) {
  return request( "PUT", API_URL.ENABLE_DISABLE_GAME_ADMIN, params );
}

export function getAdminGamesLinkedGamerList( gameId, params ) {
  return request( "GET", API_URL.ADMIN_GAMES_LINKED_GAMERS.replace( ":gameId", gameId ),
    params );
}

export function getAdminGamesLinkedGamerFilterList( gameId ) {
  return request( "GET", API_URL.ADMIN_GAMES_GAMERS_FILTER_LIST.replace( ":gameId", gameId ) );
}

export const adminGamesServices = {
  getAdminGames,
  getAdminGameDetails,
  addAdminModification,
  updatePartnerGameStatus,
  addAdminComment,
  enableDisableGameRequest,
  getAdminGamesLinkedGamerList,
  getAdminGamesLinkedGamerFilterList,
};
