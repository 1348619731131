import PropTypes from "prop-types";
import React from "react";

const Tabs = ( { values, setTab, tab, isPartnerDetail } ) => (
  <>
    { ( !isPartnerDetail ) && (
      <span onClick={() => setTab( tab.DASHBOARD )} className={values.tab === tab.DASHBOARD ? "active" : "" }>Summary Dashboard</span>
    ) }
    <span onClick={() => setTab( tab.GAMES )} className={values.tab === tab.GAMES ? "active" : "" }>{ isPartnerDetail ? "Game Details" : "Games" }</span>
    <span onClick={() => setTab( tab.GAMERS )} className={values.tab === tab.GAMERS ? "active" : "" }>Linked Gamers</span>
    <span onClick={() => setTab( tab.EXCHANGE_TRANSACTION )} className={values.tab === tab.EXCHANGE_TRANSACTION ? "active" : "" }>Exchange TXN</span>
    { isPartnerDetail && (
      <>
        <span onClick={() => setTab( tab.OFFER_REWARD_TXN )} className={values.tab === tab.OFFER_REWARD_TXN ? "active" : "" }>Offer Reward TXN</span>
        <span onClick={() => setTab( tab.TOURNAMENT_REWARD_TXN )} className={values.tab === tab.TOURNAMENT_REWARD_TXN ? "active" : "" }>Tournament Reward TXN</span>
        <span onClick={() => setTab( tab.OPT_IN_TXN )} className={values.tab === tab.OPT_IN_TXN ? "active" : "" }>Opt In TXN</span>
      </>
    ) }
  </>
);

Tabs.propTypes = {
  values: PropTypes.object,
  setTab: PropTypes.func,
  tab: PropTypes.object,
  isPartnerDetail: PropTypes.bool,
};

export default Tabs;
