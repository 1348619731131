import { DraftToOffer } from "../entities/draftToOffer";

const handleNullUndefinedValue = ( data, name ) => data[ name ] || "";

export class AdminTournamentDraftDetails extends DraftToOffer {
  constructor( obj ) {
    super( obj );
    this.tournamentName = handleNullUndefinedValue( obj, "name" );
  }
}
