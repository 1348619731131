import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { Col, Row, Form } from "react-bootstrap";

import { useAddQuery } from "../../../../hooks";
import { useSetUrlParams } from "../../../../util/helpers";
import offerConstant from "../../../../util/offerConstant";
import CustomDatePicker from "../../../shared/customDatePicker";
import MultiSelectCheckbox from "../../../shared/multiSelectCheckbox";
import SortAndViewToggleList from "../../../shared/sortAndViewToggleList";

const { OPT_IN_STATUS_DROPDOWN } = offerConstant;

const LinkedOffersFilters = ( {
  gameNames,
  offerNames,
  handleChange,
  showFilter,
  values,
  progressStatus = [],
  sortColumns,
  toggleClass,
  location,
  handleFilterChange,
  clearSelectedFilter,
} ) => {
  const { addQuery } = useAddQuery();

  const handleSelect = ( filterName, obj ) => {
    handleFilterChange( filterName, obj, handleChange, addQuery, location.state );
  };

  const onSelectFilterItems = useCallback(
    [ obj => handleSelect( "offerNames", obj ),
      obj => handleSelect( "gameNames", obj ),
      obj => handleSelect( "progressStatus", obj ),
      obj => handleSelect( "optInTypes", obj ),
    ],
    []
  );

  const multiSelectedItems = useMemo( () => [ values.offerNames, values.gameNames, progressStatus, values.optInTypes ] );

  const clearAllSelectedOptions = useCallback(
    params => clearSelectedFilter( params, addQuery, handleChange, location.state ), []
  );

  return (
    <>
      <div className='filter-tabs-wrapper align-items-end'>
        <div className="filter-boxes offer-filter tm-filer-box">
          <div className={`filter-boxes mr-0 ${ ( !showFilter ) ? "" : "remove-filter" }`}>
            <Row>
              <Col md={3} className="mb-20">
                <Form.Group className='custom-multiselect mb-0'>
                  <Form.Label>Offer Name</Form.Label>
                  <MultiSelectCheckbox
                    multiOptionsList={offerNames}
                    handleSelectChange={onSelectFilterItems[ 0 ]}
                    multiSelectedItems={multiSelectedItems[ 0 ]}
                    handleClearMultiSelect={() => clearAllSelectedOptions( "offerNames" )} />
                </Form.Group>
              </Col>
              <Col md={3} className="mb-20">
                <Form.Group className='custom-multiselect mb-0'>
                  <Form.Label>Game Name</Form.Label>
                  <MultiSelectCheckbox
                    multiOptionsList={gameNames}
                    handleSelectChange={onSelectFilterItems[ 1 ]}
                    multiSelectedItems={multiSelectedItems[ 1 ]}
                    handleClearMultiSelect={() => clearAllSelectedOptions( "gameNames" )} />
                </Form.Group>
              </Col>
              <Col md={3} className="mb-20">
                <Form.Group className='custom-datepicker mb-0'>
                  <Form.Label>Start Date</Form.Label>
                  <CustomDatePicker
                    value={values.startDate}
                    handleChange={( e ) => {
                      if( e ) {
                        addQuery( useSetUrlParams( "startDate", new Date( e ).toLocaleDateString( "fr-CA" ) ), location.state );
                        handleChange( "startDate", new Date( e ).toLocaleDateString( "fr-CA" ) );
                        handleChange( "initialStartDate", new Date( e ).toLocaleDateString( "fr-CA" ) );
                      }
                      else {
                        addQuery( useSetUrlParams( "startDate", "" ), location.state );
                        handleChange( "startDate", false );
                        handleChange( "initialStartDate", null );
                      }
                    } }
                  />
                </Form.Group>
              </Col>
              <Col md={3} className="mb-20">
                <Form.Group className='custom-datepicker mb-0'>
                  <Form.Label>End Date</Form.Label>
                  <CustomDatePicker
                    value={values.endDate}
                    handleChange={( e ) => {
                      if( e ) {
                        addQuery( useSetUrlParams( "endDate", new Date( e ).toLocaleDateString( "fr-CA" ) ), location.state );
                        handleChange( "endDate", new Date( e ).toLocaleDateString( "fr-CA" ) );
                        handleChange( "initialEndDate", new Date( e ).toLocaleDateString( "fr-CA" ) );
                      }
                      else {
                        addQuery( useSetUrlParams( "endDate", "" ), location.state );
                        handleChange( "endDate", false );
                        handleChange( "initialEndDate", null );
                      }
                    } }
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className='custom-multiselect'>
                  <Form.Label>Progress</Form.Label>
                  {<MultiSelectCheckbox
                    multiOptionsList={progressStatus}
                    handleSelectChange={onSelectFilterItems[ 2 ]}
                    multiSelectedItems={multiSelectedItems[ 2 ]}
                    handleClearMultiSelect={() => clearAllSelectedOptions( "progressStatus" )}/>}
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className='custom-multiselect mb-0'>
                  <Form.Label>Opt In</Form.Label>
                  <MultiSelectCheckbox
                    multiOptionsList={ OPT_IN_STATUS_DROPDOWN }
                    handleSelectChange={onSelectFilterItems[ 3 ]}
                    multiSelectedItems={multiSelectedItems[ 3 ]}
                    handleClearMultiSelect={() => clearAllSelectedOptions( "optInTypes" )}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </div>
        <SortAndViewToggleList
          values={values}
          addQuery={addQuery}
          showFilter={showFilter}
          sortColumns={sortColumns}
          toggleClass={toggleClass}
          handleChange={handleChange}
          locationState={location.state}
        />
      </div>
    </>
  );
};

LinkedOffersFilters.propTypes = {
  gameNames: PropTypes.array,
  offerNames: PropTypes.array,
  progressStatus: PropTypes.array,
  handleChange: PropTypes.func,
  showFilter: PropTypes.bool,
  values: PropTypes.object,
  sortColumns: PropTypes.array,
  toggleClass: PropTypes.func,
  location: PropTypes.object,
  handleFilterChange: PropTypes.func,
  clearSelectedFilter: PropTypes.func,
};

export default LinkedOffersFilters;
