import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import PersonIcon from "@material-ui/icons/Person";
import SettingsIcon from "@material-ui/icons/Settings";
import SportsEsportsIcon from "@material-ui/icons/SportsEsports";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import adminUserConstant from "../../../util/adminUserConstant";
import constant from "../../../util/constant";
import { multipleArrayExist } from "../../../util/helpers";
import CommonNavbar from "./CommonNavbar";

const { ROUTES, ADMIN_NAV_ENTITY } = constant;
const { ADMIN_PERMISSIONS } = adminUserConstant;

const AdminNavbarInner = ( { toggleDrawer } ) => {
  const { adminDetail } = useSelector( state => ( { adminDetail: state.adminDetail.data } ) );
  const location = useLocation();
  // eslint-disable-next-line prefer-destructuring
  const activeNav = location.pathname.split( "/" )[ 2 ];

  return (
    <>
      <div className='left-nav'>
        <ul>
          { ( adminDetail?.permissions?.includes( ADMIN_PERMISSIONS.MANAGE_PARTNERS ) ) && (
            <li>
              <CommonNavbar
                toggleDrawer={toggleDrawer}
                activeNav={activeNav}
                navTitle='Partners'
                activeTab={ADMIN_NAV_ENTITY.PARTNERS}
                route={ROUTES.ADMIN_PARTNER_LIST}
                icon= { <PersonIcon />}
                type='admin'
              />
            </li>
          )}
          <li>
            <CommonNavbar
              toggleDrawer={toggleDrawer}
              activeNav={activeNav}
              navTitle='Games'
              activeTab={ADMIN_NAV_ENTITY.GAMES}
              route={ROUTES.ADMIN_GAME_LIST}
              icon= { <SportsEsportsIcon />}
              type='admin'
            />
          </li>
          <li>
            <CommonNavbar
              toggleDrawer={toggleDrawer}
              activeNav={activeNav}
              navTitle='Offers'
              activeTab={ADMIN_NAV_ENTITY.OFFERS}
              route={ROUTES.ADMIN_OFFER_LIST}
              icon= {<LocalOfferIcon />}
              type='admin'
            />
          </li>
          <li>
            <CommonNavbar
              toggleDrawer={toggleDrawer}
              activeNav={activeNav}
              navTitle='Tournaments'
              activeTab={ADMIN_NAV_ENTITY.ADMIN_TOURNAMENTS_LIST}
              route={ROUTES.ADMIN_TOURNAMENTS_LIST}
              icon={<><span className='icon-tournament icon'></span></>}
              type='admin'
            />
          </li>
          { ( adminDetail?.permissions?.includes( ADMIN_PERMISSIONS.MANAGE_EVENTS ) ) && ( <li>
            <CommonNavbar
              toggleDrawer={toggleDrawer}
              activeNav= {activeNav}
              navTitle='Events'
              activeTab={ADMIN_NAV_ENTITY.EVENTS}
              route={ROUTES.ADMIN_EVENT_LIST}
              icon= {<EmojiEventsIcon />}
              type='admin'
            />
          </li>
          )}
          { ( adminDetail?.permissions?.includes( ADMIN_PERMISSIONS.ONBOARD_USERS ) ) && ( <li>
            <CommonNavbar
              toggleDrawer={toggleDrawer}
              activeNav= {activeNav}
              navTitle='Admin Users'
              activeTab={ADMIN_NAV_ENTITY.ADMIN_USERS}
              route={ROUTES.ADMIN_USER_LIST}
              icon= {<PeopleAltIcon />}
              type='admin'
            />
          </li> )}
          { ( adminDetail?.permissions?.includes( ADMIN_PERMISSIONS.MANAGE_GAMERS ) ) && ( <li>
            <CommonNavbar
              toggleDrawer={toggleDrawer}
              activeNav= {activeNav}
              navTitle='Gamers'
              activeTab={ADMIN_NAV_ENTITY.GAMERS}
              route={ROUTES.GAMER_LIST}
              icon= {<AssignmentIndIcon />}
              type='admin'
            />
          </li> )}
          { ( multipleArrayExist( adminDetail?.permissions, [ ADMIN_PERMISSIONS.MANAGE_REDEEM_REQUESTS, ADMIN_PERMISSIONS.VIEW_TRANSACTIONS ] ) ) && ( <li>
            <CommonNavbar
              toggleDrawer={toggleDrawer}
              activeNav={activeNav}
              navTitle='Transactions'
              activeTab={ADMIN_NAV_ENTITY.TRANSACTIONS}
              route={`${ ROUTES.TRANSACTION_LIST }?tab=Redeem`}
              icon= { <><svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd">
                <path d="M0 0h24v24H0z"/><g fill="#61A351" fillRule="nonzero">
                  <path d="M19.5 3.5 18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5
                  1.5L9 2 7.5 3.5 6 2v14H3v3c0 1.66 1.34 3 3 3h12c1.66 0
                  3-1.34 3-3V2l-1.5 1.5zM19 19c0 .55-.45 1-1 1s-1-.45-1-1v-3H8V5h11v14z"/>
                  <path d="M9 7h6v2H9zM16 7h2v2h-2zM9 10h6v2H9zM16 10h2v2h-2z"/></g></g></svg></>}
              type='admin'
            />
          </li> )}
          <li>
            <CommonNavbar
              toggleDrawer={toggleDrawer}
              activeNav= {activeNav}
              navTitle='Settings'
              activeTab={ADMIN_NAV_ENTITY.SETTINGS}
              route={ROUTES.ADMIN_SETTING}
              icon= {<SettingsIcon />}
              type='admin'
            />
          </li>
        </ul>
      </div>
    </>
  );
};

AdminNavbarInner.propTypes = {
  toggleDrawer: PropTypes.bool,
};

export default AdminNavbarInner;
