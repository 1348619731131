import { API_URL } from "../util/apiPaths";
import { request } from "./api.js";

export function getRedeemTransaction( params ) {
  return request( "GET", API_URL.ADMIN_REDEEM_TRANSACTION, params );
}

export function getExchangeTransaction( params ) {
  return request( "GET", API_URL.ADMIN_EXCHANGE_TRANSACTION, params );
}

export function getRedeemTransactionDetails( params ) {
  return request( "GET", `${ API_URL.ADMIN_REDEEM_TRANSACTION }/${ params }` );
}

export function rejectRedeemTransactionRequest( params ) {
  return request( "PUT", `${ API_URL.ADMIN_REDEEM_TRANSACTION }/${ params.transactionId }`, params );
}

export function approveRedeemTransactionRequest( params ) {
  return request( "PUT", `${ API_URL.ADMIN_REDEEM_TRANSACTION }/${ params.transactionId }`, params );
}

export function getRedeemFilterList() {
  return request( "GET", API_URL.ADMIN_REDEEM_FILTER_LIST );
}

export function getExchangeFilterList( gamerId ) {
  let url = API_URL.ADMIN_EXCHANGE_FILTER_LIST;

  if( gamerId ) {
    url = `${ API_URL.ADMIN_EXCHANGE_FILTER_LIST }?gamerQxId=${ gamerId }`;
  }

  return request( "GET", url );
}

export const adminTransactionServices = {
  getRedeemTransaction,
  getRedeemTransactionDetails,
  rejectRedeemTransactionRequest,
  approveRedeemTransactionRequest,
  getRedeemFilterList,
  getExchangeFilterList,
};
