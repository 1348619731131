import { debounce } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Constant from "../../../util/constant";
import { useSetUrlParams } from "../../../util/helpers";
import CustomSlider from "../../shared/customSlider";
import RangeDatePicker from "../../shared/datePicker/rangeDatePicker";
import MultiSelectCheckbox from "../../shared/multiSelectCheckbox";

const { BRANDING: { APP_SHORT_NAME } } = Constant;

const RedeemFilterList = ( {
  values,
  handleChange,
  addQuery,
  paymentVendors,
  transactionStatus,
  gamerNames,
  transactionRanges,
} ) => {
  const { qxPointsRange, netRedeemAmountRange } = transactionRanges;

  const redeemListHandle = ( obj, listName ) => {
    if( obj.length >= 1 ) {
      handleChange( "page", 1 );
      handleChange( listName, obj );
      addQuery( useSetUrlParams( listName, JSON.stringify( obj ) ) );
    }
    else {
      addQuery( useSetUrlParams( listName, "" ) );
      handleChange( listName );
    }
  };

  const redeemClearHandle = ( parmas ) => {
    if( parmas ) {
      addQuery( useSetUrlParams( parmas, "" ) );
      handleChange( parmas, [] );
    }
  };

  const redeemMultiSelectedItems = useMemo( () => [ values.paymentVendors, values.transactionStatus, values.gamerNames ] );
  const redeemHandleSelectChange = useCallback(
    [ obj => redeemListHandle( obj, "paymentVendors" ),
      obj => redeemListHandle( obj, "transactionStatus" ),
      obj => redeemListHandle( obj, "gamerNames" ),
    ],
    []
  );
  const redeemHandleClear = useCallback(
    parmas => redeemClearHandle( parmas ), []
  );
  const redeemChangeHandler = useCallback(
    debounce( ( newValue, p1, p2 ) => {
      handleChange( p1, newValue[ 0 ] );
      handleChange( p2, newValue[ 1 ] );
    }, 1000 ),
    []
  );

  const debounceRedeemQxPointsHandler = ( e, newValue ) => {
    handleChange( "page", 1 );
    addQuery( useSetUrlParams( "minRedeemPoints", newValue[ 0 ] ) );
    addQuery( useSetUrlParams( "maxRedeemPoints", newValue[ 1 ] ) );
    handleChange( "initialMinRedeemPoints", newValue[ 0 ] );
    handleChange( "initialMaxRedeemPoints", newValue[ 1 ] );
    redeemChangeHandler( newValue, "minRedeemPoints", "maxRedeemPoints" );
  };

  const debounceNetAmountHandler = ( e, newValue ) => {
    handleChange( "page", 1 );
    addQuery( useSetUrlParams( "minNetAmount", newValue[ 0 ] ) );
    addQuery( useSetUrlParams( "maxNetAmount", newValue[ 1 ] ) );
    handleChange( "initialMinNetAmount", newValue[ 0 ] );
    handleChange( "initialMaxNetAmount", newValue[ 1 ] );
    redeemChangeHandler( newValue, "minNetAmount", "maxNetAmount" );
  };

  return (
    <>
      <div className="filter-boxes trans-redeem-filter">
        <Row>
          <Col md="3">
            <Form.Group className='custom-multiselect'>
              <Form.Label>Cashback Vendor</Form.Label>
              <MultiSelectCheckbox
                multiOptionsList={paymentVendors}
                handleSelectChange={redeemHandleSelectChange[ 0 ]}
                multiSelectedItems={redeemMultiSelectedItems[ 0 ]}
                handleClearMultiSelect={() => redeemHandleClear( "paymentVendors" )}/>
            </Form.Group>
          </Col>
          <Col md="3">
            <Form.Group className='custom-multiselect'>
              <CustomSlider
                values={values}
                pointsHandler={debounceRedeemQxPointsHandler}
                handleChange={handleChange}
                min={qxPointsRange.minQxPoints}
                max={qxPointsRange.maxQxPoints}
                minName={"initialMinRedeemPoints"}
                maxName={"initialMaxRedeemPoints"}
                label={`${ APP_SHORT_NAME } Points Redeemed`}
              />
            </Form.Group>
          </Col>
          <Col md="3">
            <Form.Group className='custom-multiselect'>
              <Form.Label>Status</Form.Label>
              {<MultiSelectCheckbox
                multiOptionsList={transactionStatus}
                handleSelectChange={redeemHandleSelectChange[ 1 ]}
                multiSelectedItems={redeemMultiSelectedItems[ 1 ]}
                handleClearMultiSelect={() => redeemHandleClear( "transactionStatus" )}/>}
            </Form.Group>
          </Col>
          <Col md="3">
            <Form.Group className='custom-multiselect'>
              <CustomSlider
                values={values}
                pointsHandler={debounceNetAmountHandler}
                handleChange={handleChange}
                min={netRedeemAmountRange.minNetAmount}
                max={netRedeemAmountRange.maxNetAmount}
                minName={"initialMinNetAmount"}
                maxName={"initialMaxNetAmount"}
                label={"Net Redeem Amount"}
              />
            </Form.Group>
          </Col>
          <Col md="3">
            <Form.Group className='custom-datepicker mb-0'>
              <RangeDatePicker
                label='Date Redeemed'
                values={values}
                addQuery={addQuery}
                handleChange={handleChange}
                param={{ key1: "startDateRedmeed", key2: "endDateRedmeed", key3: "initialStartDateRedmeed", key4: "initialEndDateRedmeed", page: "page" }}
              />
            </Form.Group>
          </Col>
          <Col md="3">
            <Form.Group className='custom-multiselect'>
              <Form.Label>Gamer Name</Form.Label>
              {<MultiSelectCheckbox
                multiOptionsList={gamerNames}
                handleSelectChange={redeemHandleSelectChange[ 2 ]}
                multiSelectedItems={redeemMultiSelectedItems[ 2 ]}
                handleClearMultiSelect={() => redeemHandleClear( "gamerNames" )}/>}
            </Form.Group>
          </Col>
        </Row>
      </div>
    </>
  );
};

RedeemFilterList.propTypes = {
  values: PropTypes.object,
  onSearch: PropTypes.func,
  handleChange: PropTypes.func,
  addQuery: PropTypes.func,
  paymentVendors: PropTypes.array,
  transactionStatus: PropTypes.array,
  gamerNames: PropTypes.array,
  transactionRanges: PropTypes.object,
  onCancel: PropTypes.func,
};

export default RedeemFilterList;
