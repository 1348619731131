import constant from "../util/constant";

const { DEFAULT_UUID } = constant;

const unit = data => data?.EventUnit?.name || "";

export class EventDetails {
  constructor( obj ) {
    this.eventName = obj.event_name || "";
    this.eventIdLists = obj.event_id || "";
    this.description = obj.description || "";
    this.eventId = obj.event_id || "";
    this.isActive = obj.is_active || false;
    this.units = { label: unit( obj ), value: obj.event_unit_id } || "";
    this.partnerId = obj.partner_id || "";
    this.unitName = unit( obj );
    this.unitId = obj.event_unit_id || "";
    this.partnerName = obj?.Partner?.legal_entity_name || "";
    this.isActivePartner = obj?.Partner?.is_active || false;
    this.offerList = obj?.offerList || [];
    this.gameList = obj?.gameList || [];
    this.offerListCount = obj?.offerList?.length || 0;
    this.gameListCount = obj?.gameList?.length || 0;
    this.toSortPartner = obj?.Partner?.legal_entity_name || "QX";
    this.isAdmin = obj.partner_id === DEFAULT_UUID;
  }
}
