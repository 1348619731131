import constant from "../util/constant";

const { TRANSLATION_MESSAGE: {
  USERNAME_ERROR,
  PASSWORD_WITH_USERNAME_ERROR,
  VERIFY_EMAIL_ERROR,
  EMAIL_BLANK_SPACE_ERROR,
  PASSWORD_POLICY_ERROR,
  USERNAME_WITH_SPACE_ERROR,
} } = constant;

export const EN_TRANSLATION = {
  [ USERNAME_ERROR ]: "Username cannot contain whitespace",
  [ PASSWORD_WITH_USERNAME_ERROR ]: "Username and Password cannot contain whitespace",
  [ VERIFY_EMAIL_ERROR ]: "Please select the email address",
  [ EMAIL_BLANK_SPACE_ERROR ]: "Email cannot contain whitespace",
  [ PASSWORD_POLICY_ERROR ]: "Password did not conform with policy: Password must have at least 12 characters",
  [ USERNAME_WITH_SPACE_ERROR ]: "Username cannot contain whitespace",
};
