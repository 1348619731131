import PropTypes from "prop-types";
import React from "react";
import { useLocation, useHistory } from "react-router-dom";

import constant from "../../../util/constant";

const { ROUTES, BRANDING: { APP_SHORT_NAME } } = constant;

const GameOffer = ( {
  offer,
  gameIcon,
  gameName,
  isPartnerGamerLinkedGame,
} ) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <>
      <a
        href="javascript:void(0)"
        onClick={() => {
          history.push( {
            pathname: (
              ( isPartnerGamerLinkedGame
                ? ROUTES.PARTNER_LINKED_GAMES_OFFER_DETAIL
                : ROUTES.ADMIN_OFFER_DETAILS
              ).replace( ":offerId", offer.offerId )
            ),
            state: [
              ...location.state,
              ...[ {
                from: isPartnerGamerLinkedGame ? "partnerGamerLinkedGameDetail" : "adminGameDetail",
                path: location.pathname,
                state: location.search,
              } ],
            ],
          } );
        } }
        className="offer-detail-box border-no-shadow">
        <div className="offer-detail-box-top">
          <div className="image-box">
            <img src={gameIcon} alt="games" />
          </div>
          <div className="offer-detail-box-top-body">
            <div className="left-side">
              <strong>{offer.offerName}</strong>
              <p>
                {offer.startDate ?? "N/A"} -{" "}
                {offer.endDate ?? "N/A"}
              </p>
              <span
                className={`badge ${ offer?.statusTitle?.class }`}
              >
                {offer?.statusTitle?.title}
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between mt-4 mb-3">
          <div className="games-tags-list-wrapper my-0">
            <span className="game-name">Games: </span>
            {gameName
              ? (
                <div className="games-tags-list">
                  <div className="games-tags">
                    <div className="image-box">
                      {gameIcon && (
                        <img
                          src={gameIcon}
                          alt="User Icon"
                        />
                      )}
                    </div>
                    <span>{gameName}</span>
                  </div>
                </div>
              )
              : (
                <div className="games-desc-text">
                  {" "}
                  <p>No game assigned</p>{" "}
                </div>
              )}
          </div>
          {offer.rewardValue && (
            <div className="games-desc-text text-right">
              <strong className="mb-0">Reward:</strong>
              <p>{offer.rewardValue} { APP_SHORT_NAME } Points</p>
            </div>
          )}
        </div>
        <div className="games-desc-text">
          <strong>Description:</strong>
          <p className="text-truncate">{offer.descriptionText}</p>
        </div>
      </a>
    </>
  );
};

GameOffer.propTypes = {
  offer: PropTypes.object,
  gameIcon: PropTypes.string,
  gameName: PropTypes.string,
  isPartnerGamerLinkedGame: PropTypes.bool,
};

export default GameOffer;
