import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getGamerListsAction } from "../../../../store/actions/partnerAnalytics";
import analyticsConstant from "../../../../util/analyticsConstant";
import constant from "../../../../util/constant";
import { formatNumberAsPerLocale } from "../../../../util/helpers";
import Graph from "./graph";
import GraphFilter from "./graphFilter";

const { ANALYTICS_TAB } = analyticsConstant;
const { API_STATUS, GRAPH_TYPE, BRANDING: { APP_SHORT_NAME } } = constant;

const QxMembers = ( { redirectToAll } ) => {
  const { totalGamers, status, initialDate, analyticsGraphData } = useSelector( state => ( {
    totalGamers: state.partnerAnalytics.totalGamers,
    initialDate: state.partnerAnalytics.initialDate,
    status: state.partnerAnalytics.status,
    analyticsGraphData: state.partnerAnalytics.analyticsGraphData,
  } ) );
  const [ startDate, setStartDate ] = useState();
  const [ endDate, setEndDate ] = useState();

  useEffect( () => {
    if( !isEmpty( analyticsGraphData ) && ( status === API_STATUS.SUCCESS ) ) {
      setStartDate( analyticsGraphData.data[ 0 ]?.dataPoints[ 0 ].x );
      setEndDate( analyticsGraphData.data[ 0 ]?.dataPoints[ analyticsGraphData.data[ 0 ]?.dataPoints.length - 1 ].x );
    }
  }, [ status ] );

  return (
    <>
      <Col sm={6}>
        <div className="tag-box">
          <div className="tag-box-inner">
            <div className="tag-content">
              <div className="tag-title">{formatNumberAsPerLocale( totalGamers )}</div>
              <div className="tag-text">Total No. of { APP_SHORT_NAME } Members</div>
              <span onClick={() => redirectToAll( ANALYTICS_TAB.GAMERS )} title='Show All' className='show-link'>Show All</span>
            </div>
            <GraphFilter
              startDate={startDate }
              endDate={endDate }
              initialDate={initialDate}
              dispatchAction={getGamerListsAction}
              graphType={GRAPH_TYPE.QX_MEMBERS_LINE_GRAPH}
            />
          </div>
          <Graph options={analyticsGraphData} />
        </div>
      </Col>
    </>
  );
};

QxMembers.propTypes = {
  redirectToAll: PropTypes.func,
};

export default QxMembers;
