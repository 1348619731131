import { find, trim } from "lodash";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useModal, useForm } from "../../../hooks";
import {
  getPartnerDetailAction,
  rejectPartnerRequestAction,
  setAmmendmentsRequestAction,
  confirmForDocuSignAction,
  resolveAmendmentAction,
  updatePartnerStatusAction,
  approvePartnerManualAction
} from "../../../store/actions/admin";
import adminConstant from "../../../util/adminConstant";
import adminPartnerConstant from "../../../util/adminPartnerConstant";
import constant from "../../../util/constant";
import {
  checkAddedSuggestedModification,
  requestNotAccepted,
  formatNumberAsPerLocale,
  getFullBusinessLocationAdd,
  isAnySuggestion,
  getBackNavigationRoute
} from "../../../util/helpers";
import { logoColor } from "../../../util/icons";

import { GameListing, OfferListing } from "../../shared/adminTabsListing/";
import GamerListing from "../../shared/adminTabsListing/gamerListing";
import BackButton from "../../shared/backButton";
import ModalPopup from "../modal";
import ModificationModal from "./modificationModal";

const {
  PARTNER_TAB,
  REASON_QX_JOIN,
  MODAL_TYPE_DETAIL,
  ADMIN_API_TYPES,
  SECTION_NAME_DATA,
} = adminConstant;
const { API_STATUS, APPLICATION_STATUS, CATEGORY, ORDER_BY, ROUTES, BRANDING: { APP_SHORT_NAME } } = constant;
const { TABS } = adminPartnerConstant;

const initialState = {
  rejectionReason: "",
  envelopeId: "",
  page: 1,
  partnerDetailTab: "",
  sortBy: "",
  sortOrder: ORDER_BY.ASC,
};

// TODO: refactor to avoid complexity
// eslint-disable-next-line complexity
const PartnerRequestDetail = () => {
  const history = useHistory();
  const location = useLocation();
  const paramsQuery = new URLSearchParams( window.location.search );
  const activeTab = paramsQuery.get( "tab" );
  const dispatch = useDispatch();
  const backScreens = [ "partners" ];
  const [ ModificationModalStatus, setModificationModalStatus ] = useState( false );
  const [ errorMessage, setErrorMessage ] = useState( "" );
  const { isOpened, closeModal, openModal, modalType, setModalType } =
    useModal();
  const { values, handleChange } = useForm( initialState, {} );
  const [ modificationModalData, setModificationModalData ] = useState( false );
  const [ modificationModalError, setModificationModalError ] = useState( false );
  const {
    admin: { apiType, status, partnerDetail },
  } = useSelector( state => state );
  const isRequestAccepted = requestNotAccepted(
    partnerDetail?.application_status
  );
  const { id } = useParams();
  const { 0: selectedPartnerId } = id?.toString().split( "?" ) || 0;

  const setTab = ( tab ) => {
    history.push( {
      pathname: `${ ROUTES.PARTNER_REQUEST_DETAILS + selectedPartnerId }?tab=${ tab }`,
      state: location.state,
    } );
  };

  useEffect( () => {
    handleChange( "partnerDetailTab", activeTab );
  }, [ activeTab ] );

  const requestParams = Object.assign(
    {
      id: selectedPartnerId,
      showPartnerEvents: values.showPartnerEvents,
      partnerIds: selectedPartnerId,
    },
    values.sortBy && { sortBy: values.sortBy }
  );

  useEffect( () => {
    dispatch( getPartnerDetailAction( requestParams ) );
  }, [ ] );

  // TODO: refactor so all exits of this function return something
  // This might simply be returning null at the end, but needs validation
  // eslint-disable-next-line consistent-return
  const saveModification = ( type, reviewText ) => {
    if( !trim( reviewText, " " ) ) {
      setModificationModalError( "Please suggest some modicfication!" );

      return false;
    }

    setModificationModalStatus( false );

    const obj = {
      // eslint-disable-next-line camelcase
      partner_id: partnerDetail.partner_id,
      // eslint-disable-next-line camelcase
      section_name: type,
      // eslint-disable-next-line camelcase
      review_note: reviewText,
    };
    const ammendments = partnerDetail.PartnerAmendmentDetails;

    if( ammendments.length > 0 ) {
      const report = find( ammendments, [ "section_name", type ] );

      if( report ) {
        // eslint-disable-next-line camelcase
        report.review_note = reviewText;
        // eslint-disable-next-line camelcase
        report.is_resolved = false;
      }
      else {
        ammendments.push( obj );
      }
    }
    else {
      ammendments.push( obj );
    }
  };

  const handleResolveModification = ( suggestionType ) => {
    const ammendments = partnerDetail.PartnerAmendmentDetails;
    const suggestionObj =
      find( ammendments, [ "section_name", suggestionType ] ) || {};

    if( Object.keys( suggestionObj )?.length ) {
      if( Object.prototype.hasOwnProperty.call( suggestionObj, "id" ) ) {
        dispatch(
          resolveAmendmentAction( {
            partnerId: selectedPartnerId,
            section: suggestionObj.section_name,
          } )
        );
        setModificationModalStatus( false );
      }
      else {
        toastr.error( "Suggestion is not submitted yet" );
      }
    }
  };

  const sendModification = () => {
    if( partnerDetail.PartnerAmendmentDetails.length > 0 ) {
      dispatch(
        setAmmendmentsRequestAction( partnerDetail.PartnerAmendmentDetails )
      );
    }
    else {
      toastr.error( "Please suggest some modification!" );
    }
  };

  const suggestModification = ( type ) => {
    const ammendments = partnerDetail.PartnerAmendmentDetails;
    let reviewNote = null;

    if( ammendments.length > 0 ) {
      const report = find( ammendments, [ "section_name", type.key ] );

      if( report ) {
        reviewNote = report.review_note;
      }
    }

    setModificationModalError();
    setModificationModalStatus( true );
    setModificationModalData( {
      type: type.key,
      label: type.value,
      value: reviewNote,
    } );
  };

  useEffect( () => {
    if(
      status === API_STATUS.SUCCESS &&
      apiType === ADMIN_API_TYPES.REJECT_PARTNER_REQUEST
    ) {
      history.goBack();
    }
    else if(
      status === API_STATUS.SUCCESS &&
      apiType === ADMIN_API_TYPES.AMMENDMENTS_SUGGESTED
    ) {
      toastr.success( "Suggestions sent to partner" );
      history.goBack();
    }
    else if(
      status === API_STATUS.SUCCESS &&
      apiType === ADMIN_API_TYPES.UPDATE_PARTNER_STATUS
    ) {
      toastr.success(
        partnerDetail.is_active
          ? "Partner enabled successfully"
          : "Partner disabled successfully"
      );
    }
    else if(
      status === API_STATUS.SUCCESS &&
      apiType === ADMIN_API_TYPES.APPROVE_PARTNER_MANUALLY
    ) {
      toastr.success( "Partner approved successfully" );
      history.goBack();
    }
  }, [ status, apiType ] );

  const handleInputChange = ( e ) => {
    const { name, value } = e.target;

    handleChange( name, value );

    if( errorMessage ) {
      setErrorMessage( "" );
    }
  };

  const handleReject = () => {
    const { rejectionReason, envelopeId } = values;
    const requestObj = {
      partnerId: selectedPartnerId,
    };

    if( modalType === MODAL_TYPE_DETAIL.REASON_REJECTION.type ) {
      if( trim( rejectionReason, " " ) ) {
        handleCloseModal();
        // eslint-disable-next-line camelcase
        requestObj.reason_for_rejection = values.rejectionReason;
        dispatch( rejectPartnerRequestAction( requestObj ) );
      }
      else {
        setErrorMessage( "Please enter reason for rejection" );
      }
    }
    else if( modalType === MODAL_TYPE_DETAIL.DOCUSIGN.type ) {
      if( trim( envelopeId, " " ) ) {
        closeModal();
        requestObj.envelopeId = values.envelopeId;
        dispatch( confirmForDocuSignAction( requestObj ) );
      }
      else {
        setErrorMessage( "Please enter envelope ID" );
      }
    }
    else if(
      modalType === MODAL_TYPE_DETAIL.ACTIVE_PARTNER.type ||
      modalType === MODAL_TYPE_DETAIL.INACTIVE_PARTNER.type
    ) {
      closeModal();
      // eslint-disable-next-line camelcase
      requestObj.is_active = !partnerDetail.is_active;
      dispatch( updatePartnerStatusAction( requestObj ) );
    }
    else if( modalType === MODAL_TYPE_DETAIL.APPROVE_PARTNER_MANUALLY.type ) {
      closeModal();
      dispatch( approvePartnerManualAction( requestObj ) );
    }
    else {
      closeModal();
    }
  };

  const handleCloseModal = () => {
    closeModal();
    setErrorMessage();
    handleChange( "rejectionReason", "" );
  };

  const handleAcceptAndConfirm = () => {
    const { PartnerAmendmentDetails = [] } = partnerDetail;
    const isAllSuggestionResolved = PartnerAmendmentDetails.reduce(
      ( acc, currentValue ) => acc && currentValue.is_resolved,
      true
    );

    if( isAllSuggestionResolved ) {
      handleOpenModal( MODAL_TYPE_DETAIL.DOCUSIGN.type );
    }
    else {
      toastr.error( "Please resolve all suggested modifications" );
    }
  };

  const handleOpenModal = ( mType ) => {
    setModalType( mType );
    openModal();
  };

  const handleBackNavigation = () => {
    return getBackNavigationRoute( ROUTES.ADMIN_PARTNER_LIST, location, backScreens );
  };

  return (
    <>
      <div className='main-wrapper'>
        <div className='main-right-wrapper pos-relative'>
          <BackButton
            backLink={handleBackNavigation()}/>
          {partnerDetail && (
            <React.Fragment>
              <div className='page-head-wrapper'>
                <h4 className='page-heading'>
                  {partnerDetail.legal_entity_name}
                </h4>
                {PARTNER_TAB.REQUEST.includes( partnerDetail?.application_status ) && (
                  <div className='page-head-right'>
                    <Button
                      className='btn btn-md mr-4'
                      onClick={() =>
                        handleOpenModal( MODAL_TYPE_DETAIL.REASON_REJECTION.type )
                      }
                      variant='outline-danger'
                    >
                      Reject
                    </Button>
                    {partnerDetail?.application_status ===
                      APPLICATION_STATUS.DOCUSIGN_PENDING && (
                      <Button
                        className='btn btn-primary btn-lg'
                        onClick={() =>
                          handleOpenModal(
                            MODAL_TYPE_DETAIL.APPROVE_PARTNER_MANUALLY.type
                          )
                        }
                      >
                        Approve Manually
                      </Button>
                    )}
                    {isRequestAccepted && (
                      <Button
                        className='btn btn-primary btn-lg'
                        onClick={handleAcceptAndConfirm}
                      >
                        Accept & Confirm
                      </Button>
                    )}
                  </div>
                )}

                {( partnerDetail?.application_status === PARTNER_TAB.APPROVED || partnerDetail?.application_status === PARTNER_TAB.DISABLED ) && (
                  <div className='page-head-right'>
                    <div className='btn-switch large'>
                      <Form.Check
                        type='switch'
                        id='custom-switch'
                        label='Enabled'
                        checked={partnerDetail?.is_active || false}
                        onChange={() =>
                          handleOpenModal(
                            partnerDetail?.is_active
                              ? MODAL_TYPE_DETAIL.ACTIVE_PARTNER.type
                              : MODAL_TYPE_DETAIL.INACTIVE_PARTNER.type
                          )
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className='filter-tabs-wrapper'>
                <div className='custom-tabs'>
                  <span onClick={() => setTab( TABS.INFO )} className={values.partnerDetailTab === TABS.INFO ? "active" : "" }>Info</span>
                  <span onClick={() => setTab( TABS.GAMES )} className={values.partnerDetailTab === TABS.GAMES ? "active" : "" }>Games</span>
                  <span onClick={() => setTab( TABS.OFFERS )} className={values.partnerDetailTab === TABS.OFFERS ? "active" : "" }>Offers</span>
                  <span onClick={() => setTab( TABS.LINKED_GAMERS )} className={values.partnerDetailTab === TABS.LINKED_GAMERS ? "active" : "" }>Linked Gamers</span>
                </div>
                <div className='page-head-right'>
                  <Link to='#' className='btn btn-md btn-secondary dark disable-click'>
                      Dashboard
                  </Link>
                </div>
              </div>
            </React.Fragment>
          )}
          {values.partnerDetailTab === TABS.GAMES && (
            <GameListing
              value={values}
              selectedPartnerId={selectedPartnerId }
              previousState={location.state}
            />
          )}

          {values.partnerDetailTab === TABS.OFFERS && (
            <OfferListing
              value={values}
              selectedPartnerId={selectedPartnerId }
              previousState={location.state}
            />
          )}

          {values.partnerDetailTab === TABS.LINKED_GAMERS && (
            <GamerListing
              value={values}
              partnerId={selectedPartnerId}
              previousState={location.state}
            />
          )}

          {values.partnerDetailTab === TABS.INFO && ( <div
            className={
              isRequestAccepted
                ? "page-inner-box with-fixed-footer"
                : "page-inner-box"
            }
          >
            {partnerDetail?.application_status === PARTNER_TAB.REJECTED && (
              <React.Fragment>
                <h4 className='content-heading first'>Reason for Rejection</h4>
                <div className='content-section'>
                  <div className='content-des'>
                    {partnerDetail.reason_for_rejection}
                  </div>
                </div>
              </React.Fragment>
            )}
            <div className='heading-with-button'>
              <h4 className='content-heading first'>General Information</h4>
              {isRequestAccepted && (
                <Button
                  variant='outline-primary btn-xl'
                  onClick={() => {
                    suggestModification( SECTION_NAME_DATA.GENERAL );
                  }}
                >
                  {checkAddedSuggestedModification(
                    SECTION_NAME_DATA.GENERAL.key,
                    partnerDetail.PartnerAmendmentDetails
                  )}
                </Button>
              )}
            </div>

            <div className='uploaded-image-wrapper mb-30'>
              <div className='uploaded-image small'>
                <img src={partnerDetail.icon_url ?? logoColor} alt='games' />
              </div>
            </div>

            <div className='content-section'>
              <div className='content-label'>Legal Entity Name</div>
              <div className='content-des'>
                {partnerDetail.legal_entity_name}
              </div>
            </div>

            <div className='content-section'>
              <div className='content-label'>Category(s)</div>
              <div className='content-des'>
                {CATEGORY.find( category => category.id === partnerDetail.category_id )?.label || ""}
              </div>
            </div>

            <div className='content-section'>
              <div className='content-label'>Reason to Join { APP_SHORT_NAME }</div>
              <div className='content-des'>
                {REASON_QX_JOIN[ partnerDetail.join_reason ] ?? "-"}
              </div>
            </div>

            <div className='heading-with-button'>
              <h4 className='content-heading'>Contact Information</h4>
              {isRequestAccepted && (
                <Button
                  variant='outline-primary btn-xl'
                  onClick={() => {
                    suggestModification( SECTION_NAME_DATA.CONTACT );
                  }}
                >
                  {checkAddedSuggestedModification(
                    SECTION_NAME_DATA.CONTACT.key,
                    partnerDetail.PartnerAmendmentDetails
                  )}
                </Button>
              )}
            </div>
            <div className='content-section'>
              <div className='content-label'>DBA (Optional)</div>
              <div className='content-des'>{partnerDetail.dba ?? "-"}</div>
            </div>
            <div className='content-section'>
              <div className='content-label'>Business Location Address</div>
              <div className='content-des'>
                { getFullBusinessLocationAdd( partnerDetail ) ?? "-"}
              </div>
            </div>

            <h5 className='content-sub-heading'>Primary Contact Information</h5>
            <Row>
              <Col sm={6}>
                <div className='content-section'>
                  <div className='content-label'>Name</div>
                  <div className='content-des'>
                    {partnerDetail.primary_name ?? "-"}
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div className='content-section'>
                  <div className='content-label'>Title</div>
                  <div className='content-des'>
                    {partnerDetail.primary_title ?? "-"}
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div className='content-section'>
                  <div className='content-label'>Phone Number</div>
                  <div className='content-des'>
                    {partnerDetail.primary_phone ?? "-"}
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div className='content-section'>
                  <div className='content-label'>
                    Alternative Phone Number (Optional)
                  </div>
                  <div className='content-des'>
                    {partnerDetail.primary_alternative_phone ?? "-"}
                  </div>
                </div>
              </Col>
            </Row>

            <div className='content-section'>
              <div className='content-label'>Email</div>
              <div className='content-des'>
                {partnerDetail.primary_email ?? "-"}
              </div>
            </div>

            <h5 className='content-sub-heading'>
              Secondary Contact Information
            </h5>
            <Row>
              <Col sm={6}>
                <div className='content-section'>
                  <div className='content-label'>Name</div>
                  <div className='content-des'>
                    {partnerDetail.sec_name ?? "-"}
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div className='content-section'>
                  <div className='content-label'>Title</div>
                  <div className='content-des'>
                    {partnerDetail.sec_title ?? "-"}
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div className='content-section'>
                  <div className='content-label'>Phone Number</div>
                  <div className='content-des'>
                    {partnerDetail.sec_phone ?? "-"}
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div className='content-section'>
                  <div className='content-label'>
                    Alternative Phone Number (Optional)
                  </div>
                  <div className='content-des'>
                    {partnerDetail.sec_alternative_phone ?? "-"}
                  </div>
                </div>
              </Col>
            </Row>

            <div className='content-section'>
              <div className='content-label'>Email</div>
              <div className='content-des'>
                {partnerDetail.sec_email ?? "-"}
              </div>
            </div>

            <h5 className='content-sub-heading'>Authorized Signatory</h5>
            <Row>
              <Col sm={6}>
                <div className='content-section'>
                  <div className='content-label'>Name</div>
                  <div className='content-des'>
                    {partnerDetail.authorized_signature_name ?? "-"}
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div className='content-section'>
                  <div className='content-label'>Title</div>
                  <div className='content-des'>
                    {partnerDetail.authorized_signature_title ?? "-"}
                  </div>
                </div>
              </Col>
            </Row>

            <div className='heading-with-button'>
              <h4 className='content-heading'>Other Information</h4>
              {PARTNER_TAB.REQUEST.includes( partnerDetail?.application_status ) && isRequestAccepted && (
                <Button
                  variant='outline-primary btn-xl'
                  onClick={() => {
                    suggestModification( SECTION_NAME_DATA.OTHER );
                  }}
                >
                  {checkAddedSuggestedModification(
                    SECTION_NAME_DATA.OTHER.key,
                    partnerDetail.PartnerAmendmentDetails
                  )}
                </Button>
              )}
            </div>
            <div className='content-section'>
              <div className='content-label'>Estimated number of Games</div>
              <div className='content-des'>
                {formatNumberAsPerLocale( partnerDetail.no_games_estimates, "--" )}
              </div>
            </div>
            <div className='content-section'>
              <div className='content-label'>
                Estimated number of Gamers across those Games
              </div>
              <div className='content-des'>
                {formatNumberAsPerLocale( partnerDetail.no_games_estimates_across, "--" )}
              </div>
            </div>
            <Row>
              <Col sm={6}>
                <div className='content-section'>
                  <div className='content-label'>Game Growth (%)</div>
                  <div className='content-des'>
                    {partnerDetail.game_growth ?? "--"}
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div className='content-section'>
                  <div className='content-label'>Gamers Growth (%)</div>
                  <div className='content-des'>
                    {partnerDetail.gamer_growth ?? "--"}
                  </div>
                </div>
              </Col>
            </Row>

            <div className='content-section'>
              <div className='content-des'>
                Our company can do all the software integration work needed
              </div>
            </div>
            {PARTNER_TAB.REQUEST.includes( partnerDetail?.application_status ) && (
              <React.Fragment>
                <h4 className='content-heading'>Suggested Modifications</h4>
                <div className='content-section'>
                  <div className='content-des mb-4 scrollbar'>
                    {partnerDetail.suggested_changes ?? "--"}
                  </div>
                </div>
                {isRequestAccepted && isAnySuggestion( partnerDetail.PartnerAmendmentDetails ) && (
                  <div className='fixed-footer-wrapper'>
                    <Button
                      variant='primary'
                      className='btn-xl'
                      onClick={() => sendModification()}
                    >
                      Send Suggestion
                    </Button>
                  </div>
                )}
              </React.Fragment>
            )}
          </div> )}
        </div>
      </div>

      <ModalPopup
        isOpened={isOpened}
        onClose={handleCloseModal}
        type={modalType}
        values={values}
        onChangeInput={handleInputChange}
        errorMessage={errorMessage}
        onConfirm={handleReject}
      />
      {ModificationModalStatus && (
        <ModificationModal
          ModificationModalStatus={ModificationModalStatus}
          setModificationModalStatus={setModificationModalStatus}
          saveModification={saveModification}
          modificationModalData={modificationModalData}
          modificationModalError={modificationModalError}
          onResolveModification={handleResolveModification}
          isShowResolveButton={
            partnerDetail?.application_status ===
              APPLICATION_STATUS.AMENDMENTS_SUGGESTED &&
            find( partnerDetail.PartnerAmendmentDetails, [
              "section_name",
              modificationModalData.type,
            ] )?.review_note
          }
        />
      )}
    </>
  );
};

PartnerRequestDetail.propTypes = {
  match: PropTypes.object,
};

export default PartnerRequestDetail;
