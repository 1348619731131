import { debounce } from "lodash";
import React, { useEffect, useMemo, useCallback, useState } from "react";
import {
  Form
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useAddQuery, useForm, useScroll } from "../../../hooks";
import { getAdminGamerAction, getAdminFilterAction } from "../../../store/actions/adminGamer";
import Enums from "../../../util/Enums";
import adminGamerConstant from "../../../util/adminGamerConstant";
import constant from "../../../util/constant";
import { useSetUrlParams, concatStrings, getUrlParameter, checkFilterApplied } from "../../../util/helpers";
import { adminGamerTableConfigs } from "../../../util/tableConstant";
import FilterIcon from "../../shared/FilterIcon/FilterIcon";
import GamerListHeader from "./gamerListHeader";

const { ORDER_BY, API_STATUS, FILTERS_NAME } = constant;
const { GAMER_USERS_API_TYPES } = adminGamerConstant;
const { DEBOUNCE_WAIT_TIME_MS } = Enums;

const GamerList = () => {
  const dispatch = useDispatch();
  const { addQuery } = useAddQuery();
  const { scrollEvent } = useScroll();
  const min = 0;
  const { gamers, gamersMetaData, status, apiType, gamersFilterList,
    maxLinkedGames, maxAcqyrCashBalance, gamersPlan } = useSelector( state => ( {
    gamers: state.adminGamer.adminGamers.gamers, gamersMetaData: state.adminGamer.adminGamers.meta,
    status: state.adminGamer.status, apiType: state.adminGamer.apiType,
    gamersFilterList: state.adminGamer.gamersFilterList.gamersList, maxLinkedGames: state.adminGamer.gamersFilterList.maxLinkedGames,
    maxAcqyrCashBalance: state.adminGamer.gamersFilterList.maxAcqyrCashBalance,
    gamersPlan: state.adminGamer.gamersFilterList.gamersPlan,
  } ) );
  // dummyMinAcqyrCashBalance, dummyMaxAcqyrCashBalance, dummyMinLinkedGames, dummyMaxLinkedGames,
  // are used to set the min and max value of the slider on temporary basis,so that respective slider can move smoothly.
  // Without using above it also working but slider stuck maximum time.
  const { values, handleChange } = useForm(
    {
      searchDummyText: getUrlParameter( "searchKey", null ), searchKey: getUrlParameter( "searchKey", null ),
      sortBy: getUrlParameter( "sortBy", "fullName" ), sortOrder: getUrlParameter( "sortOrder", ORDER_BY.ASC ),
      page: 1, fullNames: getUrlParameter( "fullNames", "" ), minAcqyrCashBalance: getUrlParameter( "minAcqyrCashBalance", min ),
      maxAcqyrCashBalance: getUrlParameter( "maxAcqyrCashBalance" ), minLinkedGames: getUrlParameter( "minLinkedGames", min ),
      maxLinkedGames: getUrlParameter( "maxLinkedGames" ), dummyMinAcqyrCashBalance: getUrlParameter( "minAcqyrCashBalance", min ),
      dummyMaxAcqyrCashBalance: getUrlParameter( "maxAcqyrCashBalance" ), dummyMinLinkedGames: getUrlParameter( "minLinkedGames", min ),
      dummyMaxLinkedGames: getUrlParameter( "maxLinkedGames" ),
      planNames: getUrlParameter( "planNames", "" ),
      showModal: false,
      linkedGames: [],
    }, {}
  );
  const sliderMinMaxObj = {
    minLinkedGames: min,
    maxLinkedGames: maxLinkedGames,
    minAcqyrCashBalance: min,
    maxAcqyrCashBalance: maxAcqyrCashBalance,
  };
  const isFilterApplied = checkFilterApplied( window.location.search, FILTERS_NAME.ADMIN_GAMERS, sliderMinMaxObj );
  const requestParams = Object.assign(
    {
      sortOrder: values.sortOrder, sortBy: values.sortBy,
      perPage: 1000, page: values.page,
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      searchKey: values.searchKey, gamerNames: concatStrings( values.fullNames, "," ) || undefined,
      minAcqyrCashBalance: values.minAcqyrCashBalance, maxAcqyrCashBalance: values.maxAcqyrCashBalance,
      maxLinkedGames: values.maxLinkedGames, minLinkedGames: values.minLinkedGames,
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      planNames: concatStrings( values.planNames, "," ) || undefined,
    }
  );

  useEffect( () => {
    dispatch( getAdminFilterAction( { perPage: 1000, sortOrder: ORDER_BY.ASC, sortBy: "fullName" } ) );
  }, [] );

  useEffect( () => {
    if(
      status === API_STATUS.SUCCESS &&
      apiType === GAMER_USERS_API_TYPES.GET_GAMERS_FILTER_LIST
    ) {
      dispatch( getAdminGamerAction( requestParams ) );
    }
  }, [
    status,
    apiType,
  ] );

  useEffect( () => {
    if( !values.dummyMaxAcqyrCashBalance ) {
      handleChange( "dummyMaxAcqyrCashBalance", maxAcqyrCashBalance );
      handleChange( "maxAcqyrCashBalance", maxAcqyrCashBalance );
    }

    if( !values.dummyMaxLinkedGames ) {
      handleChange( "dummyMaxLinkedGames", maxLinkedGames );
      handleChange( "maxLinkedGames", maxLinkedGames );
    }
  }, [ maxAcqyrCashBalance, maxLinkedGames ] );

  useEffect( () => {
    if( maxAcqyrCashBalance || maxLinkedGames ) {
      dispatch( getAdminGamerAction( requestParams ) );
    }
  }, [
    values.sortOrder, values.page, values.sortBy, values.searchKey,
    values.fullNames, values.minAcqyrCashBalance, values.maxAcqyrCashBalance,
    values.maxLinkedGames, values.minLinkedGames, values.planNames,
  ] );

  const handleSorting = ( sortBy, sortingType ) => {
    handleChange( "page", 1 );
    handleChange( "sortBy", sortBy );
    handleChange( "sortOrder", sortingType );
    addQuery( useSetUrlParams( "sortBy", sortBy ) );
    addQuery( useSetUrlParams( "sortOrder", sortingType ) );
  };

  const onScroll = ( e ) => {
    scrollEvent( e, gamers, values, handleChange, gamersMetaData, "page" );
  };

  const debouncedSave = useCallback(
    debounce( nextValue => handleChange( "searchKey", nextValue ), 1000 ), []
  );

  const searchHandle = ( e ) => {
    const { value: nextValue } = e.target;

    addQuery( useSetUrlParams( "searchKey", nextValue ) );
    handleChange( "searchDummyText", nextValue );
    debouncedSave( nextValue );
    handleChange( "page", 1 );
  };

  const memoizedTableConfig = useMemo( () => {
    return adminGamerTableConfigs;
  }, [] );
  const memoizedHandleClick = useCallback(
    ( sortBy, orderType ) => handleSorting( sortBy, orderType ), []
  );

  const listHandle = ( obj, listName ) => {
    if( obj.length > 0 ) {
      handleChange( "page", 1 );
      handleChange( listName, obj );
      addQuery( useSetUrlParams( listName, JSON.stringify( obj ) ) );
    }
    else {
      addQuery( useSetUrlParams( listName, "" ) );
      handleChange( listName );
    }
  };

  const clearHandle = ( parmas ) => {
    if( parmas ) {
      addQuery( useSetUrlParams( parmas, "" ) );
      handleChange( parmas, [] );
    }
  };

  const multiSelectedItems = useMemo( () => [ values.fullNames, values.planNames ] );
  const handleSelectChange = useCallback(
    [ obj => listHandle( obj, "fullNames" ),
      obj => listHandle( obj, "planNames" ),
    ],
    []
  );
  const handleClearMultiSelect = useCallback(
    parmas => clearHandle( parmas ), []
  );

  const closeModal = () => {
    handleChange( "showModal", false );
  };

  const viewAllGames = ( linkedGames ) => {
    handleChange( "linkedGames", linkedGames );
    handleChange( "showModal", true );
  };

  const acqyrLinkedGameHandle = useCallback(
    debounce( ( newValue ) => {
      handleChange( "minLinkedGames", newValue[ 0 ] );
      handleChange( "maxLinkedGames", newValue[ 1 ] );
    }, DEBOUNCE_WAIT_TIME_MS ),
    []
  );

  const debouncedGameChangeHandler = ( e, newValue ) => {
    handleChange( "page", 1 );
    addQuery( useSetUrlParams( "minLinkedGames", newValue[ 0 ] ) );
    addQuery( useSetUrlParams( "maxLinkedGames", newValue[ 1 ] ) );
    handleChange( "dummyMinLinkedGames", newValue[ 0 ] );
    handleChange( "dummyMaxLinkedGames", newValue[ 1 ] );
    acqyrLinkedGameHandle( newValue );
  };

  const acqyrCashHandle = useCallback(
    debounce( ( newValue ) => {
      handleChange( "minAcqyrCashBalance", newValue[ 0 ] );
      handleChange( "maxAcqyrCashBalance", newValue[ 1 ] );
    }, DEBOUNCE_WAIT_TIME_MS ),
    []
  );

  const debouncedCashChangeHandler = ( e, newValue ) => {
    handleChange( "page", 1 );
    addQuery( useSetUrlParams( "minAcqyrCashBalance", newValue[ 0 ] ) );
    addQuery( useSetUrlParams( "maxAcqyrCashBalance", newValue[ 1 ] ) );
    handleChange( "dummyMinAcqyrCashBalance", newValue[ 0 ] );
    handleChange( "dummyMaxAcqyrCashBalance", newValue[ 1 ] );
    acqyrCashHandle( newValue );
  };

  const [ showFilter, setShowFilter ] = useState( false );
  const showFilterScreen = () => setShowFilter( !showFilter );

  return (
    <>
      <div className="main-wrapper">
        <div className="main-right-wrapper">
          <div className="page-head-wrapper">
            <h4 className="page-heading">Gamers</h4>
            <div className="page-head-right">
              <Form.Group className="custom-search w-300 mr-0">
                <span className="icon-search"></span>
                <Form.Control
                  type="text"
                  placeholder="Search by Name"
                  data-testid='searchKey'
                  onChange={searchHandle}
                  value={values.searchDummyText}
                />
              </Form.Group>
              <FilterIcon
                showFilter={showFilter}
                showFilterScreen={showFilterScreen}
                isFilterApplied={isFilterApplied} />
            </div>
          </div>
          <GamerListHeader
            values={values}
            gamersFilterList={gamersFilterList}
            handleSelectChange={handleSelectChange}
            multiSelectedItems={multiSelectedItems}
            handleClearMultiSelect={handleClearMultiSelect}
            debouncedGameChangeHandler={debouncedGameChangeHandler}
            handleChange={handleChange}
            min={min}
            maxLinkedGames={maxLinkedGames}
            debouncedCashChangeHandler={debouncedCashChangeHandler}
            maxAcqyrCashBalance={maxAcqyrCashBalance}
            memoizedTableConfig={memoizedTableConfig}
            memoizedHandleClick={memoizedHandleClick}
            gamers={gamers}
            onScroll={onScroll}
            viewAllGames={viewAllGames}
            closeModal={closeModal}
            showFilter={showFilter}
            gamersPlan={gamersPlan}
          />
        </div>
      </div>
    </>
  );
};

export default GamerList;
