import PropTypes from "prop-types";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import adminConstant from "../../../util/adminConstant";
import adminUserConstant from "../../../util/adminUserConstant";

const { ADMIN_PERMISSIONS } = adminUserConstant;
const {
  MODAL_TYPE_DETAIL,
} = adminConstant;


const AdminUserForm = ( {
  isEditPermission,
  values,
  handleInputChange,
  handleChangePermission,
  onSubmit,
  handleOpenModal,
  errors,
  isPermissionActive,
  onCancel,
} ) => {
  return (
    <div className="main-wrapper">
      <div className="main-right-wrapper">
        <div className="page-head-wrapper">
          <h4 className="page-heading">{isEditPermission ? "Edit Admin User" : "Add Admin User"}</h4>
          {isEditPermission && <div className="page-head-right">
            <div className="btn-switch large">
              <Form.Check
                type="switch"
                id="custom-switch"
                label="Enabled"
                checked={values.isActive || false}
                onChange={() =>
                  handleOpenModal(
                    values.isActive
                      ? MODAL_TYPE_DETAIL.ENABLED_ADMIN.type
                      : MODAL_TYPE_DETAIL.DISABLED_ADMIN.type
                  )
                }
              />
            </div>
          </div>
          }
        </div>
        <div className="page-inner-box profile-steps-box with-fixed-footer">
          <h4 className="content-heading pt-0 pb-10">General Information</h4>
          <Form>
            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter Full Name" name='fullName' value={values.fullName} data-testid='fullName'
                    onChange={handleInputChange} maxLength={45}
                    disabled={isEditPermission}/>
                  <div className='invalid-feedback d-block'>{errors.fullName}</div>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Username</Form.Label>
                  <Form.Control type="text"
                    placeholder="Enter Username"
                    name='username' value={values.username}
                    data-testid='username'
                    onChange={handleInputChange}
                    maxLength={128}
                    disabled={isEditPermission} />
                  <div className='invalid-feedback d-block'>{errors.username}</div>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" placeholder="Enter Email"
                    name='email' value={values.email} data-testid='email' onChange={handleInputChange} maxLength={90} disabled={isEditPermission} />
                  <div className='invalid-feedback d-block'>{errors.email}</div>
                </Form.Group>
              </Col>
            </Row>
            <h4 className="content-heading pb-10">Permissions</h4>
            <Row>
              <Col sm={6}>
                <div className="btn-switch big-with-label">
                  <Form.Label>Manage Admin Users</Form.Label>
                  <Form.Check
                    type="switch"
                    id="onboardUsers"
                    label=""
                    name="ONBOARD_USERS"
                    onChange={handleChangePermission}
                    checked={isPermissionActive( ADMIN_PERMISSIONS.ONBOARD_USERS )}
                    data-testid='onboardUsers'
                  />
                </div>
              </Col>
              <Col sm={6}>
                <div className="btn-switch big-with-label">
                  <Form.Label>Manage Partner users</Form.Label>
                  <Form.Check
                    type="switch"
                    id="approveIssuers"
                    label=""
                    name="MANAGE_PARTNERS"
                    onChange={handleChangePermission}
                    checked={isPermissionActive( ADMIN_PERMISSIONS.MANAGE_PARTNERS )}
                    data-testid='approveIssuers'
                  />
                </div>
              </Col>
              <Col sm={6}>
                <div className="btn-switch big-with-label">
                  <Form.Label>Approve and Manage Games</Form.Label>
                  <Form.Check
                    type="switch"
                    id="approveGames"
                    label=""
                    name="MANAGE_GAMES"
                    onChange={handleChangePermission}
                    checked={isPermissionActive( ADMIN_PERMISSIONS.MANAGE_GAMES )}
                    data-testid='approveGames'
                  />
                </div>
              </Col>
              <Col sm={6}>
                <div className="btn-switch big-with-label">
                  <Form.Label>Approve Offers</Form.Label>
                  <Form.Check
                    type="switch"
                    id="approveOffers"
                    label=""
                    name="APPROVE_OFFERS"
                    onChange={handleChangePermission}
                    checked={isPermissionActive( ADMIN_PERMISSIONS.APPROVE_OFFERS )}
                    data-testid='approveOffers'
                  />
                </div>
              </Col>
              <Col sm={6}>
                <div className="btn-switch big-with-label">
                  <Form.Label>Manage Gamers</Form.Label>
                  <Form.Check
                    type="switch"
                    id="manageGamers"
                    label=""
                    name="MANAGE_GAMERS"
                    onChange={handleChangePermission}
                    checked={isPermissionActive( ADMIN_PERMISSIONS.MANAGE_GAMERS )}
                    data-testid='manageGamers'
                  />
                </div>
              </Col>
              <Col sm={6}>
                <div className="btn-switch big-with-label">
                  <Form.Label>Adjust Rewards Points</Form.Label>
                  <Form.Check
                    type="switch"
                    id="offerReward"
                    label=""
                    name="OFFER_REWARD_POINTS"
                    onChange={handleChangePermission}
                    checked={isPermissionActive( ADMIN_PERMISSIONS.OFFER_REWARD_POINTS )}
                    data-testid='offerReward'
                  />
                </div>
              </Col>
              <Col sm={6}>
                <div className="btn-switch big-with-label">
                  <Form.Label>Manage Events</Form.Label>
                  <Form.Check
                    type="switch"
                    id="manageEvents"
                    label=""
                    name="MANAGE_EVENTS"
                    onChange={handleChangePermission}
                    checked={isPermissionActive( ADMIN_PERMISSIONS.MANAGE_EVENTS )}
                    data-testid='manageEvents'
                  />
                </div>
              </Col>
              <Col sm={6}>
                <div className="btn-switch big-with-label">
                  <Form.Label>View Transactions</Form.Label>
                  <Form.Check
                    type="switch"
                    id="viewTransactions"
                    label=""
                    name="VIEW_TRANSACTIONS"
                    onChange={handleChangePermission}
                    checked={isPermissionActive( ADMIN_PERMISSIONS.VIEW_TRANSACTIONS )}
                    data-testid='viewTransactions'
                  />
                </div>
              </Col>
              <Col sm={6}>
                <div className="btn-switch big-with-label">
                  <Form.Label>Manage Redeem Requests</Form.Label>
                  <Form.Check
                    type="switch"
                    id="manageRedeemRequests"
                    label=""
                    name="MANAGE_REDEEM_REQUESTS"
                    onChange={handleChangePermission}
                    checked={isPermissionActive( ADMIN_PERMISSIONS.MANAGE_REDEEM_REQUESTS )}
                    data-testid='manageRedeemRequests'
                  />
                </div>
              </Col>
            </Row>
            <div className="fixed-footer-wrapper">
              <Button variant="outline-primary mr-4" className="btn-md" onClick={onCancel}>Cancel</Button>
              <Button variant="primary" className="btn-md" onClick={onSubmit}>{isEditPermission ? "Update" : "Invite"}</Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

AdminUserForm.propTypes = {
  modificationModalData: PropTypes.object,
  isEditPermission: PropTypes.string,
  values: PropTypes.object,
  handleInputChange: PropTypes.func,
  handleChangePermission: PropTypes.func,
  onSubmit: PropTypes.func,
  handleOpenModal: PropTypes.func,
  errors: PropTypes.object,
  isPermissionActive: PropTypes.func,
  onCancel: PropTypes.func,
};

export default AdminUserForm;
