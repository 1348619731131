import PropTypes from "prop-types";
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { formatNumberAsPerLocale, isAssetError } from "../../../util/helpers";
import { ScrollPage } from "../../shared/customScrollBar";
import TableHeaderRow from "../../shared/tableHeadingRow";
import ToolTipError from "./toolTipError";

const AssetList = ( {
  assets,
  memoizedTableConfig,
  memoizedHandleClick,
  gamerDetail,
  showFilter,
} ) => {
  return (
    <div className="table-container gamer-assets-table">
      <div className="table-head">
        <div className="table-row pos-relative">
          <TableHeaderRow
            configData={memoizedTableConfig}
            onAscOrder={memoizedHandleClick}
            onDescOrder={memoizedHandleClick}
          />
        </div>
      </div>
      <Scrollbars renderThumbVertical={ScrollPage}
        className={`custom-scroll-height max ${ !showFilter ? "" : "full-height" }`}
      >
        <div className='table-body no-scrollbar'>
          { assets?.length > 0
            ? assets.map( ( asset, index ) => {
              const haveAssetError = isAssetError( asset.gameName, gamerDetail.serverErrorsGameNameList );

              return (
                <div className="table-row" key={index}>
                  <div className="td">{asset.assetId}</div>
                  <div className="td"><span className='big-text-common'>{asset.assetName}</span></div>
                  <div className="td">{formatNumberAsPerLocale( asset.quantity )}</div>
                  <div className="td"><span className='text-truncate'>{asset.gameName}</span>
                    {haveAssetError && ( <ToolTipError message='Error in fetching assets for this game.'/> )}</div>
                  {!haveAssetError && (
                    <div className="td text-green">{formatNumberAsPerLocale( asset.QXPoints, "--" )}</div>
                  )}
                  {haveAssetError && (
                    <div className="td text-green">
                      {"--"}
                    </div>
                  )}
                </div>
              );
            } )
            : (
              <div className='no-record' data-testid='no-record-found'>No Asset Found</div>
            )}
        </div>
      </Scrollbars>
    </div>
  );
};

AssetList.propTypes = {
  gamerDetail: PropTypes.string,
  assets: PropTypes.array,
  memoizedTableConfig: PropTypes.object,
  memoizedHandleClick: PropTypes.func,
  showFilter: PropTypes.bool,
};

export default AssetList;
