import React, { useEffect } from "react";

import ReduxToastr from "react-redux-toastr";

export const Toaster = ( ) => {
  useEffect( () => [] );

  return (
    <ReduxToastr
      timeOut={4000}
      newestOnTop={false}
      preventDuplicates
      position="top-center"
      getState={state => state.toastr} // This is the default
      transitionIn="fadeIn"
      transitionOut="fadeOut"
      className="toast-msg"
      closeOnToastrClick />
  );
};
