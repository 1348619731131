import PropTypes from "prop-types";
import React from "react";
import { Col } from "react-bootstrap";

import constant from "../../../util/constant";
import { formatNumberAsPerLocale } from "../../../util/helpers";
import AssetValueInfo from "./assetValueInfo";

const { BRANDING: { APP_SHORT_NAME } } = constant;

const GameAsset = ( {
  asset,
  usdPerQxPoint,
  partnerCostUsdPerQxPoint,
} ) => {
  return (
    <>
      <Col sm={2}>
        <div className="content-section">
          <div className="content-label">Asset Name</div>
          <div className="content-des">{asset.name}</div>
        </div>
      </Col>
      <Col sm={4}>
        <div className="content-section">
          <div className="content-label">Price</div>
          <div className="content-des">
            {formatNumberAsPerLocale( asset.price )} { APP_SHORT_NAME } Points
          </div>
          <AssetValueInfo
            qxPoints={formatNumberAsPerLocale( asset.price )}
            usdPerQxPoint={usdPerQxPoint}
            partnerCostUsdPerQxPoint={partnerCostUsdPerQxPoint}
          />
        </div>
      </Col>
      <Col sm={6}>
        <div className="content-section">
          <div className="content-label">{ APP_SHORT_NAME } Asset ID</div>
          <div className="content-des">{asset.gameAssetId}</div>
        </div>
      </Col>
    </>
  );
};

GameAsset.propTypes = {
  asset: PropTypes.object,
  usdPerQxPoint: PropTypes.number,
  partnerCostUsdPerQxPoint: PropTypes.number,
};

export default GameAsset;
