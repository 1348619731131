import { convertActualTime, createRelationExpression } from "../util/helpers";

export class OfferAchievements {
  constructor( obj ) {
    /* eslint-disable camelcase */
    this.achievements = obj.achievements.map( ( achievement ) => {
      return {
        name: achievement.name.trim(),
        description: achievement.rawDescription,
        description_text: achievement.descriptionText,
        event_id: achievement.event.value,
        event: achievement.event,
        comparison: achievement.comparison,
        function: achievement.function,
        eventUnit: achievement.eventUnit,
        eventDescription: achievement.eventDescription,
        operator: achievement.comparison.value,
        value: achievement.value,
        time_limit_as_seconds: convertActualTime( {
          days: achievement.days,
          hours: achievement.hours,
          minutes: achievement.minutes,
          seconds: achievement.seconds,
        } ).totalTimeInSec,
      };
    } );
    this.relation_expression = createRelationExpression( obj.achievements );
    /* eslint-enable camelcase */
  }
}
