import React, { useEffect, useState, useRef } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm, usePartnerDraft } from "../../../hooks";
import { setOtherInfoAction } from "../../../store/actions/profileInfo";
import constant from "../../../util/constant";
import { enableOnLoadEvent, disableOnLoadEvent } from "../../../util/helpers";
import partnerProfileConstant from "../../../util/partnerProfileConstant";
import ApplicationFooter from "../../shared/partner/applicationFooter";

const { ROUTES, SOFTWARE_DEVELOPMENT_ABILITY, API_STATUS } = constant;
const { API_TYPES, TAB } = partnerProfileConstant;

const initialState = {
  estimatedOfGames: "",
  estimatedOfGamers: "",
  gameGrowth: "",
  gamersGrowth: "",
  softwareDevelopmentAbility: "0",
};
const initialDirty = {
  estimatedOfGames: false,
  estimatedOfGamers: false,
  gameGrowth: false,
  gamersGrowth: false,
};

const OtherInfo = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const otherValuesRef = useRef( null );
  const [ isAutoSave, setAutoSave ] = useState( true );
  const [ isMount, setMount ] = useState( true );
  const {
    profileInfo: { data, apiType, status: apiStatus },
    partnerDraft: { partnerDraftDetail, status: draftApiStatus, apiType: draftApiType },
  } = useSelector( state => state );
  const { values, errors, handleChange } = useForm( initialState, initialDirty );

  otherValuesRef.current = values;

  const getRequestValue = name => data[ name ] || "";

  const handleInputChange = ( e ) => {
    const { name, value } = e.target;

    handleChange( name, value );
  };

  const saveOtherInfo = isRefValue => dispatch( setOtherInfoAction( isRefValue ? otherValuesRef.current : values ) );

  const handleNext = () => {
    setAutoSave( false );
    saveOtherInfo();
  };

  useEffect( () => {
    return () => saveOtherInfo( true );
  }, [] );

  usePartnerDraft( getRequestValue, partnerDraftDetail, apiType, apiStatus, TAB.OTHER_INFO, !isAutoSave );

  useEffect( () => {
    if(
      ( ( draftApiType === API_TYPES.ADD_OTHER_DRAFT_DETAIL ) || ( draftApiType === API_TYPES.UPDATE_OTHER_DRAFT_DETAIL ) ) &&
      ( draftApiStatus === API_STATUS.SUCCESS ) && !isAutoSave
    ) {
      disableOnLoadEvent();
      history.push( ROUTES.PARTNER_TERMS_CONDITIONS );
    }
  }, [ draftApiType, draftApiStatus ] );

  useEffect( () => {
    if( isMount && partnerDraftDetail.data ) {
      enableOnLoadEvent();
      setMount( false );
      Object.keys( initialState ).forEach( ( key ) => {
        if( partnerDraftDetail.data[ key ] ) {
          handleChange( key, partnerDraftDetail.data[ key ] );
        }
      } );
    }
  }, [ partnerDraftDetail ] );

  return (
    <>
      <div className='main-wrapper'>
        <div className='main-right-wrapper'>
          <div className='page-head-wrapper'>
            <h4 className='page-heading'>Other Information</h4>
            <div className='page-head-right'>
              <button
                className='link-btn back-btn'
                onClick={() => history.goBack()}
              >
                Back
              </button>
              <button className='btn btn-primary' onClick={handleNext}>
                Next
              </button>
            </div>
          </div>
          <div className='page-inner-box profile-steps-box with-fixed-footer'>
            <Form>
              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Estimated number of games</Form.Label>
                    <Form.Control
                      type='number'
                      value={values.estimatedOfGames}
                      name='estimatedOfGames'
                      onChange={handleInputChange}
                      onBlur={saveOtherInfo}
                    />
                    <div className='invalid-feedback d-block'>
                      {errors.estimatedOfGames}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>
                      Estimated number of gamers across those games
                    </Form.Label>
                    <Form.Control
                      type='number'
                      value={values.estimatedOfGamers}
                      name='estimatedOfGamers'
                      onChange={handleInputChange}
                      onBlur={saveOtherInfo}
                    />
                    <div className='invalid-feedback d-block'>
                      {errors.estimatedOfGamers}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Game Growth (%)</Form.Label>
                    <Form.Control
                      type='number'
                      value={values.gameGrowth}
                      name='gameGrowth'
                      onChange={handleInputChange}
                      maxLength={3}
                      onBlur={saveOtherInfo}
                    />
                    <div className='invalid-feedback d-block'>
                      {errors.gameGrowth}
                    </div>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Gamers Growth (%)</Form.Label>
                    <Form.Control
                      type='number'
                      value={values.gamersGrowth}
                      name='gamersGrowth'
                      onChange={handleInputChange}
                      maxLength={3}
                      onBlur={saveOtherInfo}
                    />
                    <div className='invalid-feedback d-block'>
                      {errors.gamersGrowth}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <div className='profile-radio-list'>
                {SOFTWARE_DEVELOPMENT_ABILITY.map( item => (
                  <div className='custom-radio' key={item.id}>
                    <label>
                      <input
                        type='radio'
                        name='softwareDevelopmentAbility'
                        value={item.id}
                        checked={item.id === values.softwareDevelopmentAbility}
                        onChange={handleInputChange}
                        onBlur={saveOtherInfo}
                      />
                      <span>
                        {item.label}
                      </span>
                    </label>
                  </div>
                ) )}
              </div>
            </Form>
            <ApplicationFooter></ApplicationFooter>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtherInfo;
