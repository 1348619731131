import PropTypes from "prop-types";
import React, { useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import { connect, useSelector } from "react-redux";
import { withRouter, Router, Route, Switch, Redirect } from "react-router-dom";
import PrivateRoutes from "../routes/PrivateRoutes";
import PublicRoutes from "../routes/PublicRoutes";
import constant from "../util/constant";
import history from "../util/history";

const { ROUTES, APPLICATION_STATUS } = constant;

const Wrapper = ( props ) => {
  const { partnerDetail = {} } = useSelector( state => state.partner );

  useEffect( () => {}, [] );

  const { loader } = props;

  const authentication = () => {
    const role = localStorage.getItem( "role" );
    let render;

    if( role === constant.ROLE.ADMIN ) {
      render = <Redirect to={ROUTES.ADMIN_PARTNER_LIST} />;
    }
    else if( role === constant.ROLE.PARTNER ) {
      if( partnerDetail?.applicationStatus === APPLICATION_STATUS.REJECTED ) {
        render = <Redirect to={ROUTES.PARTNER_PROFILE_REJECTED} />;
      }
      else if( partnerDetail?.applicationStatus === APPLICATION_STATUS.DISABLED ) {
        render = <Redirect to={ROUTES.PARTNER_PROFILE_DISABLED} />;
      }
      else if(
        partnerDetail?.applicationStatus === APPLICATION_STATUS.PENDING ||
        partnerDetail?.applicationStatus ===
          APPLICATION_STATUS.AMENDMENTS_SUGGESTED
      ) {
        render = <Redirect to={ROUTES.PARTNER_PROFILE_PENDING} />;
      }
      else if(
        partnerDetail?.applicationStatus === APPLICATION_STATUS.APPROVED
      ) {
        render = <Redirect to={ROUTES.PARTNER_DASHBOARD} />;
      }
      else if(
        partnerDetail?.applicationStatus === APPLICATION_STATUS.DOCUSIGN_PENDING
      ) {
        render = <Redirect to={ROUTES.PARTNER_DOCUSIGN_PENDING} />;
      }
      else {
        render = <Redirect to={ROUTES.PARTNER_GENERAL_INFO} />;
      }
    }
    else {
      render = <PublicRoutes />;
    }

    return render;
  };

  return (
    <React.Fragment>
      <LoadingOverlay active={loader.isShow} spinner text=''>
        <Router history={history}>
          <Switch>
            <Route path='/admin' component={PrivateRoutes} />
            <Route path='/partner' component={PrivateRoutes} />
            <Route path='' render={authentication} />
          </Switch>
        </Router>
      </LoadingOverlay>
    </React.Fragment>
  );
};

function mapStateToProps( state ) {
  return {
    currentUser: state.currentUser,
    errors: state.errors,
    loader: state.loader,
  };
}

Wrapper.propTypes = {
  loader: PropTypes.object,
};

export default withRouter( connect( mapStateToProps )( Wrapper ) );
