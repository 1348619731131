import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getLeaderboardAction } from "../../../store/actions/leaderboard";
import constant from "../../../util/constant";
import { bgLeader, crown, leaderboardIcon, defaultUser } from "../../../util/icons";
import LeaderboardGamerRow from "./leaderboardGamerRow";

const { ROUTES } = constant;

const Leaderboard = ( {
  tournamentId,
  isAdmin = false,
  entityType,
  gamerId,
} ) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { leaderboard: {
    participants = 0,
    tournamentEndDate = null,
    tournamentEndTime = null,
    firstPlace = null,
    secondPlace = null,
    thirdPlace = null,
    firstRowPlaces = [],
    secondRowPlaces = [],
    participantsList = [],
  } } = useSelector( state => ( {
    leaderboard: state.leaderboard.data,
  } ) );

  useEffect( () => {
    dispatch( getLeaderboardAction( { tournamentId, isAdmin }, { fetchAllRecords: true } ) );
  }, [ tournamentId ] );

  const redirectToLeaderboardParticipants = () => {
    let redirectRoute = "";

    if( isAdmin && ( entityType === "gamer" ) ) {
      redirectRoute = ROUTES.ADMIN_GAMER_LEADERBOARD_PARTICIPANTS.replace( ":gamerId", gamerId );
    }
    else if( ( !isAdmin ) && ( entityType === "gamer" ) ) {
      redirectRoute = ROUTES.PARTNER_GAMER_LEADERBOARD_PARTICIPANTS.replace( ":gamerId", gamerId );
    }
    else if( isAdmin && ( entityType === "tournamentDetail" ) ) {
      redirectRoute = ROUTES.ADMIN_LEADERBOARD_PARTICIPANTS;
    }
    else {
      redirectRoute = ROUTES.PARTNER_LEADERBOARD_PARTICIPANTS; // For partner leaderboard participants.
    }

    return redirectRoute.replace( ":tournamentId", tournamentId );
  };

  return (
    <div className='leaderboard'>
      <img src={bgLeader} alt="link-icon" className="bg-leader" />
      <div className='tour-heading'>
        <div className='tour-heading-inner'>
          Participants: {participants}
          <span
            className='link-text'
            onClick={ () => {
              history.push( {
                pathname: redirectToLeaderboardParticipants(),
                state: [ ...location.state,
                  ...[ { from: "gamerTournamentDetail", path: location.pathname, state: location.search } ],
                ],
              } );
            }}
          >
            Show All
          </span>
        </div>
        <div className='tour-heading-inner text-right'>
          <span>End Date: {tournamentEndDate}</span>
          <span>End Time: {tournamentEndTime}</span>
        </div>
      </div>
      <div className='leaderboard-container'>
        {secondPlace?.rank && (
          <div className='leader-content first'>
            <img src={secondPlace.gamer_profile} alt="Second gamer picture" className="leader-image" />
            <img src={defaultUser} alt="First gamer picture" className="leader-image default" />
            <span className='leader-text' title={secondPlace.nickname}>{secondPlace.nickname}</span>
          </div>
        )}
        {firstPlace?.rank && (
          <div className='leader-content second'>
            <img src={crown} alt="crown-icon" className="crown-icon" />
            <img src={firstPlace.gamer_profile} alt="First gamer picture" className="leader-image" />
            <img src={defaultUser} alt="First gamer picture" className="leader-image default" />
            <span className='leader-text' title={firstPlace.nickname}>{firstPlace.nickname}</span>
          </div>
        )}
        {thirdPlace?.rank && (
          <div className='leader-content third'>
            <img src={thirdPlace.gamer_profile} alt="Third gamer picture" className="leader-image" />
            <img src={defaultUser} alt="First gamer picture" className="leader-image default" />
            <span className='leader-text' title={thirdPlace.nickname}>{thirdPlace.nickname}</span>
          </div>
        )}
        <img src={leaderboardIcon} alt="link-icon" className="card-icon" />
      </div>
      {entityType !== "tournamentDetail" && (
        <div className='current-place'>
          <span>Gamer Current Place</span>
          <span>
            { participantsList.find( participant => participant.gamerId === gamerId )?.rank ?? "N/A" }
          </span>
        </div>
      )}
      <LeaderboardGamerRow gamerPlacesDetails={firstRowPlaces} />
      <LeaderboardGamerRow gamerPlacesDetails={secondRowPlaces} />
    </div>
  );
};

Leaderboard.propTypes = {
  tournamentId: PropTypes.string,
  isAdmin: PropTypes.bool,
  entityType: PropTypes.string,
  gamerId: PropTypes.string,
};

export default Leaderboard;
