import { useHistory, useLocation } from "react-router-dom";

const useAddQuery = (
) => {
  const history = useHistory();
  const location = useLocation();

  const addQuery = ( params, state = "", isExtraParam = true ) => {
    let { pathname } = location;

    if( isExtraParam ) {
      [ pathname ] = pathname.split( "?" );
    }

    history.push( {
      pathname,
      search: params,
      state,
    } );
  };

  return {
    addQuery,
  };
};

export default useAddQuery;
