import Enums from "./Enums";
import constant from "./constant";

const {
  NUMERIC_VALUE: {
    MAX_QX_POINTS,
  },
  OPT_IN_PARTICIPANTS_MAX_VALUE,
  OPT_IN_ENTRY_FEE_MIN_VALUE,
  OPT_IN_ENTRY_FEE_MAX_VALUE,
  MAX_TIMES_EARNED,
} = Enums;
const { ADVERTISE_STATUS, OPT_IN_STATUS, BRANDING: { APP_SHORT_NAME } } = constant;
const emailRegex =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const percentageRegex = /^-?([0-9]|[1-9][0-9]|100)(\.\d+)?$/;
const usZipCodeRegex = /^\d{5}?$/;
const integerRegex = /^\d*[1-9]\d*$/;
const spaceRegex = /^\S*$/;
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,}$/;
const fullNameRegex = /^(?! ).{8,50}$/;
const wholeNoRegex = /^\d*$/;

// true if user has interacted with all the fields
const isFormValid = dirtyObj =>
  Object.values( dirtyObj ).reduce(
    ( acc, currentValue ) => acc && currentValue,
    true
  );

const findError = formErrors =>
  Object.values( formErrors ).filter( value => value );

const validateInput = ( val, regex, required, valid ) => {
  let errorValue;

  if( !val ) {
    errorValue = required;
  }
  else if( valid && regex && !regex.test( val ) ) {
    errorValue = valid;
  }

  return errorValue;
};

const validateZipCode = ( val, country, regex, required, valid ) => {
  let errorValue;

  if( !val ) {
    errorValue = required;
  }
  else if( valid && regex && !regex.test( val ) && ( country?.value === "US" || country === "US" ) ) {
    errorValue = valid;
  }

  return errorValue;
};

const validateDescription = ( val, required, valid ) => {
  let errorValue;

  if( !val ) {
    errorValue = required;
  }
  else if( val.length > 500 ) {
    errorValue = valid;
  }

  return errorValue;
};

const validatePassword = ( val, newPassword, required, valid, key ) => {
  let errorValue;

  if( !val ) {
    errorValue = required;
  }
  else if( ( !passwordRegex.test( val ) ) && ( key === "newPassword" ) ) {
    errorValue = "Password must contain a lower case letter, an upper case letter, a special character, a number and at least 12 characters.";
  }
  else if( val !== newPassword ) {
    errorValue = valid;
  }

  return errorValue;
};

const validateNoOfDays = ( val, values, required, valid ) => {
  let errorValue;

  if( values.advertiseToGamer.value === ADVERTISE_STATUS.NO_OF_DAYS ) {
    if( !val ) {
      errorValue = required;
    }
    else if( valid && !integerRegex.test( val ) ) {
      errorValue = valid;
    }
  }

  return errorValue;
};

const validateAdvertiseDate = ( val, values, required ) => {
  let errorValue;

  if( ( values.advertiseToGamer.value === ADVERTISE_STATUS.SOME_SPECIFIC_DATE ) && !val ) {
    errorValue = required;
  }

  return errorValue;
};

const validateQxPoints = ( inputValue, regex, requiredMsg, validationMsg ) => {
  let errorValue;

  if( !inputValue ) {
    errorValue = requiredMsg;
  }
  else if( validationMsg && regex && !regex.test( inputValue ) ) {
    errorValue = validationMsg;
  }
  else if( Number( inputValue ) > MAX_QX_POINTS ) {
    errorValue = `${ APP_SHORT_NAME } points can't be more than ${ MAX_QX_POINTS }.`;
  }

  return errorValue;
};

const validateMinParticipants = ( val, regex, isOptIn, participantsNoLimit, required, valid ) => {
  let errorValue;

  if( ( isOptIn !== OPT_IN_STATUS.NO ) && ( !participantsNoLimit ) ) {
    if( val === "" ) {
      errorValue = required;
    }
    else if( valid && regex && ( !regex.test( val ) ) ) {
      errorValue = valid;
    }
    else if( Number( val ) > OPT_IN_PARTICIPANTS_MAX_VALUE ) {
      errorValue = `Minimum participants should be less than or equal to ${ OPT_IN_PARTICIPANTS_MAX_VALUE }.`;
    }
  }

  return errorValue;
};

const validateMaxParticipants = ( val, regex, minParticipants, isOptIn, participantsNoLimit, required, valid ) => {
  let errorValue;

  if( ( isOptIn !== OPT_IN_STATUS.NO ) && ( !participantsNoLimit ) ) {
    if( val === "" ) {
      errorValue = required;
    }
    else if( valid && regex && ( !regex.test( val ) ) ) {
      errorValue = valid;
    }
    else if( Number( val ) < Number( minParticipants ) ) {
      errorValue = "Maximum participants should be greater than or equal to minimum participants.";
    }
    else if( Number( val ) > OPT_IN_PARTICIPANTS_MAX_VALUE ) {
      errorValue = `Maximum participants should be less than or equal to ${ OPT_IN_PARTICIPANTS_MAX_VALUE }.`;
    }
  }

  return errorValue;
};

const validateOfferEarnedCount = ( val, regex, required, msg ) => {
  let errorValue;

  if( !val ) {
    errorValue = required;
  }
  else if( msg && regex && !regex.test( val ) ) {
    errorValue = msg;
  }
  else if( val && ( Number( val ) > MAX_TIMES_EARNED ) ) {
    errorValue = `Number of times offer can be earned shouldn't be more than ${ MAX_TIMES_EARNED }.`;
  }

  return errorValue;
};

const validateTimeZone = ( val, required ) => {
  let errorValue;

  if( !Object.keys( val ).length ) {
    errorValue = required;
  }

  return errorValue;
};

const validateOptInEntryFee = ( val, regex, isOptIn, valid ) => {
  let errorValue;

  if( isOptIn !== OPT_IN_STATUS.NO ) {
    if( val && valid && regex && ( !regex.test( val ) ) ) {
      errorValue = valid;
    }
    else if( ( Number( val ) < OPT_IN_ENTRY_FEE_MIN_VALUE ) ||
      ( Number( val ) > OPT_IN_ENTRY_FEE_MAX_VALUE ) ) {
      errorValue = `Entry fee should be less than or equal to ${ OPT_IN_ENTRY_FEE_MAX_VALUE }.`;
    }
  }

  return errorValue;
};

const validateDates = ( val, dateInput, required, existingDate ) => {
  let errorValue;

  if( !val ) {
    errorValue = required;
  }
  else if( ( dateInput === "startDate" ) && existingDate ) {
    if( new Date( val ) > new Date( existingDate ) ) {
      errorValue = "Start date shouldn't be greater than end date";
    }
  }

  return errorValue;
};

// TODO: refactor to avoid complexity
// eslint-disable-next-line complexity
const validateForm = ( values, dirtyObj, isNotRequireFormValid ) => {
  const formErrors = {};
  const keyArr = Object.keys( values );
  let errorStatus = true;

  for( const key of keyArr ) {
    const inputValue = values[ key ];

    if( dirtyObj[ key ] ) {
      switch ( key ) {
        case "legalEntityName":
          formErrors[ key ] = validateInput(
            inputValue,
            null,
            "Please enter legal entity name."
          );

          break;
        case "address1":
          formErrors[ key ] = validateInput( inputValue, null, "Please enter address" );

          break;
        case "zip":
          formErrors[ key ] = validateZipCode(
            inputValue,
            values.country,
            usZipCodeRegex,
            "Please enter zip code.",
            "Please enter valid zip code."
          );

          break;
        case "city":
        case "state":
        case "country":
          formErrors[ key ] = validateInput( inputValue, null, `Please select ${ key }` );

          break;
        case "primaryName":
        case "secondaryName":
          formErrors[ key ] = validateInput( inputValue, null, "Please enter name." );

          break;
        case "primaryTitle":
        case "secondaryTitle":
          formErrors[ key ] = validateInput( inputValue, null, "Please enter title." );

          break;
        case "primaryPhoneNumber":
        case "secondaryPhoneNumber":
          formErrors[ key ] = validateInput(
            inputValue,
            null,
            "Please enter phone number."
          );

          break;
        case "primaryEmail":
        case "secondaryEmail":
        case "email":
          formErrors[ key ] = validateInput(
            inputValue,
            emailRegex,
            "Please enter email address.",
            "Please enter valid email address."
          );

          break;
        case "authorizedName":
          formErrors[ key ] = validateInput(
            inputValue,
            null,
            "Please enter authorized name."
          );

          break;
        case "authorizedTitle":
          formErrors[ key ] = validateInput(
            inputValue,
            null,
            "Please enter authorized title."
          );

          break;
        case "estimatedOfGames":
          formErrors[ key ] = validateInput(
            inputValue,
            integerRegex,
            "Please enter estimated number of games.",
            "Please enter valid estimated number of games."
          );

          break;
        case "estimatedOfGamers":
          formErrors[ key ] = validateInput(
            inputValue,
            integerRegex,
            "Please enter estimated number of gamers across those games.",
            "Please enter valid estimated number of gamers."
          );

          break;
        case "gameGrowth":
          formErrors[ key ] = validateInput(
            inputValue,
            percentageRegex,
            "Please enter game growth.",
            "Game growth's percentage should not be greater than 100."
          );

          break;
        case "gamersGrowth":
          formErrors[ key ] = validateInput(
            inputValue,
            percentageRegex,
            "Please enter gamers growth.",
            "Gamers growth's percentage should not be greater than 100."
          );

          break;
        case "logo":
          formErrors[ key ] = validateInput( inputValue, null, "Please upload logo." );

          break;
        case "gameName":
          formErrors[ key ] = validateInput( inputValue, null, "Please enter game name." );

          break;
        case "gameIcon":
          formErrors[ key ] = validateInput(
            inputValue,
            null,
            "Please upload game icon."
          );

          break;
        case "descriptionText":
        case "description":
          formErrors[ key ] = validateDescription(
            inputValue,
            "Please enter description.",
            "Description should not be greater than 500."
          );

          break;
        case "eventName":
          formErrors[ key ] = validateInput(
            inputValue,
            null,
            "Please enter event name."
          );

          break;
        case "fullName":
          formErrors[ key ] = validateInput(
            inputValue,
            fullNameRegex,
            "Please enter full name.",
            "Full Name should have 8 to 50 characters with no space in the start or end."
          );

          //The fullNameRegex is not able to validate trailing space in safari. Let's validate that separately.
          if( !formErrors[ key ] && inputValue?.endsWith( " " ) ) {
            formErrors[ key ] = "Full Name should have 8 to 50 characters with no space in the start or end.";
          }

          break;
        case "username":
          formErrors[ key ] = validateInput(
            inputValue,
            spaceRegex,
            "Please enter username.",
            "No spaces are allowed in the username."
          );

          break;
        case "offerName":
          formErrors[ key ] = validateInput(
            inputValue.trim(),
            null,
            "Please enter offer name."
          );

          break;
        case "qxPoint":
          formErrors[ key ] = validateQxPoints(
            inputValue,
            integerRegex,
            dirtyObj?.startDate ? `Please enter ${ APP_SHORT_NAME } point.` : "",
            `Please enter valid ${ APP_SHORT_NAME } point.`
          );

          break;
        case "startDate":
          formErrors[ key ] = validateDates(
            inputValue,
            "startDate",
            "Please select start date.",
            values.endDate
          );

          break;
        case "endDate":
          formErrors[ key ] = validateDates(
            inputValue,
            "endDate",
            "Please select end date.",
            values.startDate
          );

          break;
        case "offerCanBeAchievedCount":
          formErrors[ key ] = validateOfferEarnedCount(
            inputValue,
            integerRegex,
            "",
            "Please enter valid number."
          );

          break;
        case "game":
          formErrors[ key ] = validateInput(
            inputValue,
            null,
            "Please select game."
          );

          break;
        case "gamers":
          formErrors[ key ] = validateInput(
            inputValue,
            null,
            "Please select gamers."
          );

          break;
        case "verificationCode":
          formErrors[ key ] = validateInput( inputValue, null, "Please enter verification code." );

          break;
        case "currentPassword":
          formErrors[ key ] = validateInput( inputValue, null, "Please enter current password." );

          break;
        case "newPassword":
          formErrors[ key ] = validatePassword(
            inputValue,
            null,
            "Please enter new password.",
            null,
            key
          );

          break;
        case "confirmPassword":
          formErrors[ key ] = validatePassword(
            inputValue,
            values.newPassword,
            "Please enter confirm password.",
            "New password and confirm password do not match."
          );

          break;
        case "noOfDays":
          formErrors[ key ] = validateNoOfDays(
            inputValue,
            values,
            "Please enter number of days.",
            "Please enter valid days."
          );

          break;
        case "advertiseDate":
          formErrors[ key ] = validateAdvertiseDate(
            inputValue,
            values,
            "Please select date."
          );

          break;
        case "tournamentName":
          formErrors[ key ] = validateInput(
            inputValue.trim(),
            null,
            "Please enter tournament name."
          );

          break;
        case "startTime":
        case "endTime":
          formErrors[ key ] = validateInput(
            inputValue,
            null,
            "Please select time."
          );

          break;
        case "timeZone":
          formErrors[ key ] = validateTimeZone(
            inputValue,
            "Please select time zone."
          );

          break;
        case "minParticipants":
          formErrors[ key ] = validateMinParticipants(
            inputValue,
            integerRegex,
            values?.optInType,
            values?.minParticipantsNoLimit,
            "Please enter minimum participants or select no minimum.",
            "Please enter a valid number for participants."
          );

          break;
        case "maxParticipants":
          formErrors[ key ] = validateMaxParticipants(
            inputValue,
            integerRegex,
            values?.minParticipants,
            values?.optInType,
            values?.maxParticipantsNoLimit,
            "Please enter maximum participants or select no maximum.",
            "Please enter a valid number for participants."
          );

          break;
        case "entryFee":
          formErrors[ key ] = validateOptInEntryFee(
            inputValue,
            wholeNoRegex,
            values?.optInType,
            "Please enter a valid entry fee."
          );

          break;
        default:
          break;
      }
    }
  }

  if( isFormValid( dirtyObj ) || isNotRequireFormValid ) {
    errorStatus = Boolean( findError( formErrors ).length );
  }

  return { formErrors, errorStatus };
};

export default validateForm;
