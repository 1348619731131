import VisibilityIcon from "@material-ui/icons/Visibility";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";

import { getEligibleSubscriptionPlans } from "../../../../util/helpers";
import tournamentConstant from "../../../../util/tournamentConstant";
import OptInDetails from "../../../partner/offers/optInDetails";
import { ScrollPage } from "../../../shared/customScrollBar";
import TableHeaderRow from "../../../shared/tableHeadingRow";

const { TABS } = tournamentConstant;

const OffersList = ( {
  offers,
  route,
  gamerId,
  location,
  showFilter,
  membershipTypes,
  memoizedTableConfig,
  history,
  memoizedHandleClick,
  isGamerTournaments,
} ) => {
  const ref = useRef( null );

  const navigateToDetail = ( offerId ) => {
    if( isGamerTournaments ) {
      history.push( {
        pathname: ( `${ route.replace( ":gamerId", gamerId ).replace( ":tournamentId", offerId )
        }?tab=${ TABS.TOURNAMENT_DETAIL }` ),
        state: [ ...location.state,
          ...[ { from: "gamerTournamentList", path: location.pathname, state: location.search } ],
        ],
      } );
    }
    else {
      history.push( {
        pathname: route.replace( ":gamerId", gamerId ).replace( ":offerId", offerId ),
        state: [ ...location.state,
          ...[ { from: "gamerOfferList", path: location.pathname, state: location.search } ],
        ],
      } );
    }
  };

  return (
    <>
      <div className={`table-container ${ isGamerTournaments ? "gamer-tournament-table" : "gamer-offer-table" }`}>
        <div className="table-head">
          <div className="table-row">
            <TableHeaderRow
              configData={memoizedTableConfig}
              onAscOrder={memoizedHandleClick}
              onDescOrder={memoizedHandleClick}
            />
          </div>
        </div>
        <Scrollbars
          renderThumbVertical={ScrollPage}
          className={`custom-scroll-height max ${ !showFilter ? "" : "full-height" }`}
        >
          <div className="table-body no-scrollbar" >
            {Boolean( offers.length ) && offers.map( ( offer, index ) => (
              <div className="table-row" key={index}>
                <div className="td">
                  <span className="big-text line-ellipses">{offer.offerName}</span>
                </div>
                <div className='td multiline-text' ref={ref}>
                  <OverlayTrigger
                    container={ref}
                    placement='auto'
                    overlay={<Tooltip className="event-tooltip">{offer.offerDescription}</Tooltip>}
                  >
                    <span className='line-ellipses'>{offer.offerDescription}</span>
                  </OverlayTrigger>
                </div>
                <div className="td">{offer.gameName}</div>
                <div className="td">{offer.startDate}</div>
                <div className="td">{offer.endDate}</div>
                <div className='td opt-in-text'>
                  <OptInDetails
                    optInDetail={offer.optInDetail}
                    eligibleSubPlans={getEligibleSubscriptionPlans( offer.optInDetail.membershipId, membershipTypes )}
                  />
                </div>
                <div className='td'>
                  <span className={`badge ${ offer?.statusTitle?.class }`}>{offer?.statusTitle?.title}</span>
                </div>
                <div className="td">{ isGamerTournaments ? offer.place : offer.offerProgress}</div>
                <div className="td">
                  <div className='icon cursor-pointer'>
                    <VisibilityIcon onClick={() => navigateToDetail( offer.offerId ) }/>
                  </div>
                </div>
              </div>
            ) )}
            {!offers.length && (
              <div className='no-record' data-testid='no-record-found'>
                No Offer Found
              </div>
            )}
          </div>
        </Scrollbars>
      </div>
    </>
  );
};

OffersList.propTypes = {
  offers: PropTypes.array,
  route: PropTypes.string,
  gamerId: PropTypes.string,
  showFilter: PropTypes.bool,
  handleSorting: PropTypes.func,
  membershipTypes: PropTypes.array,
  memoizedTableConfig: PropTypes.func,
  memoizedHandleClick: PropTypes.func,
  isGamerTournaments: PropTypes.bool,
  location: PropTypes.object,
  history: PropTypes.func,
};

export default OffersList;
