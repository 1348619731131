import { API_URL } from "../util/apiPaths";
import { request } from "./api.js";

export const getPartnerLeaderboard = ( params, queryParams ) => {
  return request( "GET", `${ API_URL.PARTNER_LEADER_BOARD }/${ params.tournamentId }`, queryParams );
};

export const getAdminLeaderboard = ( params, queryParams ) => {
  return request( "GET", `${ API_URL.ADMIN_LEADER_BOARD }/${ params.tournamentId }`, queryParams );
};
