import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
// Local-imports
import { useForm, useAddQuery, useFilter, useToggle } from "../../../../hooks";
import { getPartnerGamerDetailAction, getPartnerGamerLinkedGameOffersAction } from "../../../../store/actions/partnerGamer";
import Enums from "../../../../util/Enums";
import constant from "../../../../util/constant";
import { useSetUrlParams, handleParseJSON } from "../../../../util/helpers";
import offerConstant from "../../../../util/offerConstant";
import partnerGamerConstant from "../../../../util/partnerGamerConstant";
import { partnerGamerLinkedOfferTableConfigs } from "../../../../util/tableConstant";
import TableHeaderRow from "../../../shared/tableHeadingRow";
import LinkedOffersList from "./linkedOfferList";
import LinkedOffersFilters from "./linkedOffersFilters";
import LinkedOffersHeader from "./linkedOffersHeader";

const { API_STATUS, ORDER_BY } = constant;
const { OFFER_LIST_SORT } = offerConstant;
const { API_TYPES } = partnerGamerConstant;
const { DEFAULT_RADIX, DATA_VIEW_TYPE } = Enums;

const LinkedOffers = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { gamerId: URLString } = useParams();
  const { 0: gamerId } = String( URLString ).split( "?" );
  const { addQuery } = useAddQuery();
  const { gridViewToggle } = useToggle();
  const paramsQuery = new URLSearchParams( window.location.search );
  const { setPureData, filteredData, filterData, handleFilterChange, clearSelectedFilter } = useFilter();
  const reqParams = {
    fetchAllRecords: true,
    offerType: "Offer",
    offer: "currentOrExpired",
  };

  const {
    apiType,
    gamerDetail,
    status,
    gamerLinkedGameOffers,
    offerNames,
    gameNames,
  } = useSelector( state => ( {
    gamerDetail: state.partnerGamer.gamerDetail,
    status: state.partnerGamer.status,
    apiType: state.partnerGamer.apiType,
    gamerLinkedGameOffers: state.partnerGamer.gamerLinkedGameOffers,
    offerNames: state.partnerGamer.offerNames,
    gameNames: state.partnerGamer.gameNames,
  } ) );
  const { values, handleChange } = useForm(
    {
      viewType: parseInt( DATA_VIEW_TYPE.GRID, DEFAULT_RADIX ),
      searchKey: "",
      sortBy: "offerName",
      sortOrder: ORDER_BY.ASC,
      gameNames: [],
      offerNames: [],
      startDate: false,
      endDate: false,
      initialStartDate: paramsQuery.get( "startDate" ),
      initialEndDate: paramsQuery.get( "endDate" ),
      searchCol: [ "offerName", "gameName", "descriptionText" ],
      optInTypes: [],
    },
    {}
  );
  const requestParams = Object.assign(
    {
      sortOrder: values.sortOrder,
      sortBy: values.sortBy,
      gameNames: values.gameNames?.map( value => value.value ) || [],
      offerNames: values.offerNames?.map( value => value.value ) || [],
      startDate: values.initialStartDate,
      endDate: values.initialEndDate,
      searchCol: values.searchCol,
      searchKey: values.searchKey,
      optInTypes: values.optInTypes?.map( value => value.value ) || [],
    }
  );

  const updateStateFromUrl = () => {
    if( paramsQuery.get( "viewType" ) ) {
      handleChange( "viewType", parseInt( paramsQuery.get( "viewType" ), DEFAULT_RADIX ) );
    }

    if( paramsQuery.get( "sortBy" ) && paramsQuery.get( "sortOrder" ) ) {
      handleChange( "sortBy", paramsQuery.get( "sortBy" ) );
      handleChange( "sortOrder", paramsQuery.get( "sortOrder" ) );
      toggleClass( paramsQuery.get( "sortBy" ), paramsQuery.get( "sortOrder" ), true );
    }

    if( paramsQuery.get( "offerNames" ) ) {
      handleChange( "offerNames", handleParseJSON( paramsQuery.get( "offerNames" ) ) );
    }

    if( paramsQuery.get( "gameNames" ) ) {
      handleChange( "gameNames", handleParseJSON( paramsQuery.get( "gameNames" ) ) );
    }

    if( paramsQuery.get( "searchKey" ) ) {
      handleChange( "searchKey", paramsQuery.get( "searchKey" ) );
    }

    if( paramsQuery.get( "startDate" ) ) {
      handleChange( "startDate", paramsQuery.get( "startDate" ) );
    }

    if( paramsQuery.get( "endDate" ) ) {
      handleChange( "endDate", paramsQuery.get( "endDate" ) );
    }

    if( paramsQuery.get( "optInTypes" ) ) {
      handleChange( "optInTypes", JSON.parse( paramsQuery.get( "optInTypes" ) ) );
    }
  };

  useEffect( () => {
    dispatch( getPartnerGamerLinkedGameOffersAction( gamerId, reqParams ) );
  }, [] );
  useEffect( () => {
    if(
      ( status === API_STATUS.SUCCESS ) &&
      ( apiType === API_TYPES.GET_GAMER_LINKED_GAMES_ASSETS )
    ) {
      dispatch( getPartnerGamerDetailAction( gamerId ) );
      handleChange( "viewType", values.viewType );
      setPureData( gamerLinkedGameOffers );
      updateStateFromUrl();
      filterData( requestParams );
    }
  }, [ status, apiType ] );
  useEffect( () => {
    filterData( requestParams );
  }, [
    values.initialStartDate,
    values.initialEndDate,
    values.offerNames,
    values.gameNames,
    values.sortBy,
    values.sortOrder,
    values.searchKey,
    values.optInTypes,
  ] );

  const handleListSorting = ( sortBy, sortingType ) => {
    addQuery( useSetUrlParams( "sortBy", sortBy ) );
    addQuery( useSetUrlParams( "sortOrder", sortingType ) );
    toggleClass( sortBy, sortingType, true );
  };

  const handleMemoizedSortingClick = useCallback(
    ( sortBy, orderType ) => handleListSorting( sortBy, orderType ),
    []
  );
  const [ sortColumns, setSortColumns ] = useState( [
    { col: OFFER_LIST_SORT.OFFER_NAME, value: "Offer Name", status: true, order: ORDER_BY.ASC },
    { col: OFFER_LIST_SORT.GAME_NAME, value: "Game Name", status: false, order: null },
    { col: OFFER_LIST_SORT.STARTDATE, value: "Start Date", status: false, order: null },
    { col: OFFER_LIST_SORT.ENDDATE, value: "End Date", status: false, order: null },
    { col: OFFER_LIST_SORT.PROGRESS, value: "Progress", status: false, order: null },
    { col: OFFER_LIST_SORT.OPT_IN, value: "Opt In", status: false, order: null },
  ] );

  const toggleClass = ( params, order, isQueryParamsUpdated = false ) => {
    gridViewToggle( sortColumns, handleChange, params, setSortColumns, addQuery, useSetUrlParams,
      order, location.state, isQueryParamsUpdated );
  };

  const memoizedGamesTableConfig = useMemo( () => partnerGamerLinkedOfferTableConfigs, [] );
  const [ showFilter, setShowFilter ] = useState( false );
  const showFilterScreen = () => setShowFilter( !showFilter );

  return (
    <>
      <div className="main-wrapper">
        <div className="main-right-wrapper">
          <LinkedOffersHeader
            gamerDetail={gamerDetail}
            gamerId={gamerId}
            handleChange={handleChange}
            showFilter={showFilter}
            showFilterScreen={showFilterScreen}
            values={values}
            location={location}
          />
          <LinkedOffersFilters
            showFilter={showFilter}
            gameNames={gameNames}
            offerNames={offerNames}
            handleChange={handleChange}
            values={values}
            sortColumns={sortColumns}
            toggleClass={toggleClass}
            location={location}
            handleFilterChange={handleFilterChange}
            clearSelectedFilter={clearSelectedFilter}
          />
          <div className={`games-list-table-head gamer-offer-head ${ ( !values.viewType ) ? "active" : "" }`}>
            <div className='table-head-body'>
              <TableHeaderRow
                configData={memoizedGamesTableConfig}
                onAscOrder={handleMemoizedSortingClick}
                onDescOrder={handleMemoizedSortingClick}
              />
            </div>
          </div>
          <LinkedOffersList
            gamerId={gamerId}
            linkedOffersList={filteredData}
            values={values}
            location={location}
          />
        </div>
      </div>
    </>
  );
};

export default LinkedOffers;
