import * as moment from "moment-timezone";
import CanvasJSReact from "../assets/canvasJS/canvasjs.react";
import constant from "./constant";

const { CanvasJS } = CanvasJSReact;
const { DATE_TIME_FORMAT, GRAPH_TYPE, GRAPH_UNIT } = constant;

/**
 * @param {Array} analyticsData Raw data to be processed
 * @param {string} keyName date type attribute in analyticsData records for grouping of data
 * @param {Object} startDate Start date for the graph interval
 * @param {Object} endDate End date for the graph interval
 * @param {Array} graphData graph data to be added or updated in localStorage based of type of graph
 * @param {string} intervalType days, months, or years (compatible to momentjs interval)
 * @param {integer} interval time interval for showing pointers on x-axis
 * @param {string} labelFormatterType Format in which date has to be shown on x-axis
 * @returns {Object} Graph data as an Object
 **/
export const getGraphData = ( analyticsData, keyName, startDate, endDate, graphData, intervalType = "days", interval = 1, labelFormatterType = "YYYY" ) => {
  let pointer = moment( startDate ).format( DATE_TIME_FORMAT.FULL_DATE );
  let comparator = null;

  if( intervalType === "years" ) { //In case of 'All'
    comparator = "year";
  }
  else if( intervalType === "months" ) {
    comparator = "month";
  }

  const result = [];

  while( moment( pointer ).isSameOrBefore( endDate ) ) {
    if( graphData && graphData[ 0 ].graph === GRAPH_TYPE.EXCHANGE_TXN_LINE_GRAPH ) {
      // TODO: refactor to not use variables (pointer) used in the loop in a function
      // this may cause unexpected results
      // eslint-disable-next-line no-loop-func
      const exchangeTrxData = analyticsData.filter( x => moment( x[ keyName ] ).isSameOrBefore( pointer, comparator ) );
      const amountInUSD = exchangeTrxData.reduce( ( count, currentItem ) => {
        // TODO: refactor to not reassign count
        // eslint-disable-next-line no-param-reassign
        return count += currentItem.amountInUSD;
      }, 0 );

      // TODO: refactor to not use internal variable _d
      // eslint-disable-next-line no-underscore-dangle
      result.push( { x: moment( pointer ).startOf( intervalType )._d, y: parseInt( amountInUSD, 10 ) } );
    }
    else {
      // TODO: refactor to not use internal variable _d
      // TODO: refactor to not use variables (pointer) used in the loop in a function
      // eslint-disable-next-line no-underscore-dangle, no-loop-func
      const recordCount = analyticsData.filter( x => moment( x[ keyName ] ).isSameOrBefore( pointer, comparator ) ).length || 0;

      // TODO: refactor to not use internal variable _d
      // eslint-disable-next-line no-underscore-dangle
      result.push( { x: moment( pointer ).startOf( intervalType )._d, y: recordCount } );
    }

    // TODO: refactor to not reassign interval. This should also
    // resolve the no-plus-plus rule as well
    // eslint-disable-next-line no-param-reassign, no-plusplus
    pointer = moment( startDate ).add( interval++, intervalType )
      .endOf( "day" );
  }

  const lastIndexValue = result[ result.length - 1 ]?.y;

  if( intervalType === "days" ) {
    // TODO: refactor to not reassign intervalType
    // eslint-disable-next-line no-param-reassign
    intervalType = "day";
  }
  else if( intervalType === "years" ) {
    // TODO: refactor to not reassign intervalType
    // eslint-disable-next-line no-param-reassign
    intervalType = "year";
  }
  else if( intervalType === "months" ) {
    // TODO: refactor to not reassign intervalType
    // eslint-disable-next-line no-param-reassign
    intervalType = "month";
  }

  //Check if local storage already has data
  if( localStorage.getItem( "graphData" ) ) {
    // Get existing graphData from local storage
    const existingGraphData = JSON.parse( localStorage.getItem( "graphData" ) );

    // Iterate through the new graphData
    graphData.forEach( ( newItem ) => {
      // Check if the new item's graph type already exists in the existing graphData
      const existingItem = existingGraphData.find( item => item.graph === newItem.graph );

      // If the graph type already exists, update the existing item
      if( existingItem ) {
        existingItem.filterValue = newItem.filterValue;
        existingItem.range = newItem.range;
        existingItem.initialDate = newItem.initialDate;
      }
      else {
      // If the graph type does not exist, add the new item to the existing graphData
        existingGraphData.push( newItem );
      }
    } );
    // Save the updated graphData to local storage
    localStorage.setItem( "graphData", JSON.stringify( existingGraphData ) );
  }
  else {
    // If local storage does not have any data, save the new graphData
    localStorage.setItem( "graphData", JSON.stringify( graphData ) );
  }

  if( result.length ) {
    const graphOptions = {
      includeZero: true,
      gridThickness: 1,
      gridColor: "lightgray",
      gridDashType: "solid",
      labelFontFamily: "Poppins",
      labelFontSize: 12,
      labelFontColor: "#78759D",
    };
    const finalGraphData = {
      animationEnabled: true,
      axisY: {
        ...graphOptions,
        interval: lastIndexValue >= 10 ? parseInt( lastIndexValue / 10, 10 ) : 1,
        prefix: getPrefix( graphData[ 0 ].graph ),
      },
      axisX: {
        labelFormatter: function( e ) {
          return CanvasJS.formatDate( e.value, labelFormatterType ).toUpperCase();
        },
        ...graphOptions,
        intervalType: intervalType,
        interval: ( labelFormatterType === "D" ) ? 5 : 1,
      },
      data: [ {
        type: "splineArea",
        color: "rgba(97,163,81, .2)",
        showInLegend: false,
        toolTipContent: "{y}",
        dataPoints: result,
      } ],
    };

    //Calculate interval dynamically for month filter.
    if( labelFormatterType === "D" ) {
      const dayInterval = Math.max( moment( endDate ).diff( moment( startDate ), "days" ) / 6, 1 ); //Max. possible interval= 30/6=5

      finalGraphData.axisX.interval = dayInterval;
    }

    return finalGraphData;
  }

  // If we don't have any data points, return an empty object
  // This satisfies all usage that uses _.isEmpty() as well
  // as other usage that just checks the return value as
  // a Boolean value.
  // Note: Boolean([]) and Boolean({}) are both true
  return {};
};

export const getPrefix = ( graphType ) => {
  switch ( graphType ) {
    case GRAPH_TYPE.EXCHANGE_TXN_LINE_GRAPH:
      return GRAPH_UNIT.DOLLAR;
    default:
      return "";
  }
};
