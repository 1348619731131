import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PropTypes from "prop-types";
import React, { useMemo, useCallback, useRef } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Scrollbars } from "react-custom-scrollbars";
import { useHistory } from "react-router-dom";
import constant from "../../../util/constant";
import { eventsTableConfigs, eventsPartnerTableConfigs } from "../../../util/tableConstant";
import { ScrollPage } from "../../shared/customScrollBar";
import TableHeaderRow from "../tableHeadingRow";

const { ROUTES } = constant;

const Events = ( { onSorting, events, onScroll, eventEditUrl, role, showFilter } ) => {
  const ref = useRef( null );
  const history = useHistory();
  const memoizedTableConfig = useMemo( () => {
    return role === "partner" ? eventsTableConfigs : eventsPartnerTableConfigs;
  }, [ ] );


  const memoizedHandleClick = useCallback(
    ( sortBy, orderType ) => onSorting( sortBy, orderType ),
    []
  );

  const eventOperation = ( event ) => {
    return (
      <>
        <Dropdown.Item onClick={() => history.push(
          eventEditUrl.replace( ":eventId", event.eventId )
        )}>
          <span className='rounded'>
            <EditIcon />
          </span>
          <span>Edit</span>
        </Dropdown.Item>
      </>
    );
  };

  return (
    <>
      <div className='table-container event-partner-table'>
        <div className='table-head'>
          <div className='table-row'>
            <TableHeaderRow
              configData={memoizedTableConfig}
              onAscOrder={memoizedHandleClick}
              onDescOrder={memoizedHandleClick}
            />
          </div>
        </div>
        <Scrollbars renderThumbVertical={ScrollPage}
          className={`custom-scroll-height ${ !showFilter ? "" : "full-height" }`}
          onScroll={onScroll}
        >
          <div className='table-body no-scrollbar' >
            {Boolean( events.length ) && events.map( ( event, index ) => (
              <div className='table-row' key={index}>
                <div className='td'>{event.eventId}</div>
                <div className='td'>
                  <span className="strong line-ellipses">{event.eventName}</span>
                </div>
                <div className='td'>{event.unitName}</div>

                <div className='td'>{event.partnerName ? event.partnerName : "QX" }</div>

                <div className='td' ref={ref}>
                  <OverlayTrigger container={ref}
                    placement='auto'
                    overlay={<Tooltip className="event-tooltip">{event.description}</Tooltip>}
                  >
                    <span className='line-ellipses'>{event.description}</span>
                  </OverlayTrigger>
                </div>

                <div className='td'>{event.isActive ? "Enabled" : "Disabled"}</div>
                <div className='td'>
                  <Dropdown className='custom-dropdown more-dropdown'>
                    <Dropdown.Toggle variant='success' id='' className='m-0'>
                      <MoreVertIcon />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {role === "partner" && ( <Dropdown.Item > <span onClick={() => history.push(
                        ROUTES.PARTNER_EVENT_DETAIL.replace( ":eventId", event.eventId )
                      )}><VisibilityIcon />View</span></Dropdown.Item> )}

                      {role === "admin" && ( <Dropdown.Item ><span onClick={() => history.push(
                        ROUTES.ADMIN_EVENT_DETAIL.replace( ":eventId", event.eventId )
                      )}><VisibilityIcon />View</span></Dropdown.Item> )}

                      {( role === "partner" ) && ( <div className={ ( ( event.isAdmin ) || ( !event.isActive ) ) ? "disabled" : ""}>
                        { eventOperation( event ) }
                      </div>
                      ) }
                      {role === "admin" && ( <div className={ ( ( !event.isAdmin ) || ( !event.isActive ) ) ? "disabled" : ""}>

                        { eventOperation( event ) }
                      </div> )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            ) )}
            {!events.length && (
              <div className='no-record' data-testid='no-record-found'>
                  No Event Found
              </div>
            )}
          </div>
        </Scrollbars>
      </div>
    </>
  );
};

Events.propTypes = {
  onSorting: PropTypes.func,
  events: PropTypes.array,
  onScroll: PropTypes.func,
  eventEditUrl: PropTypes.string,
  role: PropTypes.string,
  showFilter: PropTypes.bool,
};

export default Events;
