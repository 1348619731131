import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import constant from "../../../../util/constant";
import { useSetUrlParams } from "../../../../util/helpers";
import CustomSlider from "../../../shared/customSlider";
import RangeDatePicker from "../../../shared/datePicker/rangeDatePicker";
import MultiSelectCheckbox from "../../../shared/multiSelectCheckbox";

const { BRANDING: { APP_SHORT_NAME } } = constant;

const OfferRewardTxnFilters = ( {
  values,
  handleChange,
  addQuery,
  offerList,
  partnerList,
  gameList,
  locationState,
  handleFilterChange,
  clearSelectedFilter,
  isGamerTournaments,
  qxPointMinMax = { min: 0, max: 0 },
  gamerPlaceMinMax = { min: 0, max: 0 },
} ) => {
  const clearAllSelectedOptions = useCallback(
    params => clearSelectedFilter( params, addQuery, handleChange, locationState ), []
  );

  const handleSelect = ( filterName, obj ) => {
    handleFilterChange( filterName, obj, handleChange, addQuery, locationState );
  };

  const handlePlace = ( e, newValue ) => {
    addQuery( useSetUrlParams( "minGamerPlace", newValue[ 0 ] ), locationState );
    addQuery( useSetUrlParams( "maxGamerPlace", newValue[ 1 ] ), locationState );
    handleChange( "minGamerPlace", newValue[ 0 ] );
    handleChange( "maxGamerPlace", newValue[ 1 ] );
  };

  const multiSelectedItems = useMemo( () => [
    values.gameNames,
    values.offerNames,
    values.partnerNames,
  ] );

  const offerSelectChange = useCallback(
    [ obj => handleSelect( "gameNames", obj ),
      obj => handleSelect( "offerNames", obj ),
      obj => handleSelect( "partnerNames", obj ),
    ],
    []
  );

  const handleQxPoints = ( e, newValue ) => {
    addQuery( useSetUrlParams( "minQxPoint", newValue[ 0 ] ), locationState );
    addQuery( useSetUrlParams( "maxQxPoint", newValue[ 1 ] ), locationState );
    handleChange( "minQxPoint", newValue[ 0 ] );
    handleChange( "maxQxPoint", newValue[ 1 ] );
  };

  return (
    <>
      <div className="filter-boxes offer-filter mr-0 mb-20">
        <Row>
          <Col sm={3}>
            <Form.Group className='custom-multiselect'>
              <Form.Label>Game Name</Form.Label>
              {<MultiSelectCheckbox
                multiOptionsList={gameList}
                handleSelectChange={offerSelectChange[ 0 ]}
                multiSelectedItems={multiSelectedItems[ 0 ]}
                handleClearMultiSelect={() => clearAllSelectedOptions( "gameNames" )}/>}
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group className='custom-multiselect'>
              <Form.Label>{isGamerTournaments ? "Tournament" : "Offer"} Name</Form.Label>
              <MultiSelectCheckbox
                multiOptionsList={offerList}
                handleSelectChange={offerSelectChange[ 1 ]}
                multiSelectedItems={multiSelectedItems[ 1 ]}
                handleClearMultiSelect={() => clearAllSelectedOptions( "offerNames" )}/>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group className='custom-multiselect'>
              <Form.Label>Partner</Form.Label>
              <MultiSelectCheckbox
                multiOptionsList={partnerList}
                handleSelectChange={offerSelectChange[ 2 ]}
                multiSelectedItems={multiSelectedItems[ 2 ]}
                handleClearMultiSelect={() => clearAllSelectedOptions( "partnerNames" )}/>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <CustomSlider
              values={values}
              pointsHandler={handleQxPoints}
              handleChange={handleChange}
              min={qxPointMinMax.min}
              max={qxPointMinMax.max}
              minName={"minQxPoint"}
              maxName={"maxQxPoint"}
              label={`${ APP_SHORT_NAME } Points`}
            />
          </Col>
          { isGamerTournaments && (
            <Col sm={3}>
              <div className='mb-0 mt-3'>
                <CustomSlider
                  values={values}
                  pointsHandler={handlePlace}
                  handleChange={handleChange}
                  min={gamerPlaceMinMax.min}
                  max={gamerPlaceMinMax.max}
                  minName={"minGamerPlace"}
                  maxName={"maxGamerPlace"}
                  label={"Place"}
                />
              </div>
            </Col>
          ) }
          <Col sm={3}>
            <Form.Group className='custom-datepicker range-picker mb-0 mt-3'>
              <RangeDatePicker
                label='Date of Achievement'
                values={values}
                addQuery={addQuery}
                handleChange={handleChange}
                param={{ key1: "startDate", key2: "endDate", key3: "", key4: "", page: "page" }}
                locationState={locationState}
                isExtraParam={true}
              />
            </Form.Group>
          </Col>
        </Row>
      </div>
    </>
  );
};

OfferRewardTxnFilters.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  addQuery: PropTypes.func,
  offerList: PropTypes.array,
  partnerList: PropTypes.array,
  gameList: PropTypes.array,
  locationState: PropTypes.string,
  handleFilterChange: PropTypes.func,
  clearSelectedFilter: PropTypes.func,
  progressStatus: PropTypes.array,
  isGamerTournaments: PropTypes.bool,
  qxPointMinMax: PropTypes.object,
  gamerPlaceMinMax: PropTypes.object,
};

export default OfferRewardTxnFilters;
