import { API_URL } from "../util/apiPaths";
import { request } from "./api.js";

export function checkEmailVerify( emailObj ) {
  return request( "POST", API_URL.VERIFY_EMAIL, emailObj );
}

export const emailVerifyService = {
  checkEmailVerify,
};
