import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link, useLocation } from "react-router-dom";
// local-imports
import Enums from "../../../util/Enums";
import constant from "../../../util/constant";

const { ROUTES, TOOLTIP_CLASS } = constant;

const ProfileNav = ( { toggleDrawer } ) => {
  const [ step, setStep ] = useState( Enums.NUMERIC_VALUE.ONE );
  const location = useLocation();

  useEffect( () => {
    switch ( location.pathname ) {
      case ROUTES.PARTNER_GENERAL_INFO:
        setStep( Enums.NUMERIC_VALUE.ONE );

        break;
      case ROUTES.PARTNER_CONTACT_INFO:
        setStep( Enums.NUMERIC_VALUE.TWO );

        break;
      case ROUTES.PARTNER_OTHER_INFO:
        setStep( Enums.NUMERIC_VALUE.THREE );

        break;
      case ROUTES.PARTNER_TERMS_CONDITIONS:
        setStep( Enums.NUMERIC_VALUE.FOUR );

        break;
      default:
        setStep( step );
    }
  }, [ location ] );

  return (
    <div className='left-nav'>
      <ul>
        <li>
          <OverlayTrigger
            placement='right'
            overlay={
              <Tooltip className={toggleDrawer ? TOOLTIP_CLASS.HIDE : TOOLTIP_CLASS.SHOW}>General Information</Tooltip>
            }
          >
            <Link
              to={ROUTES.PARTNER_GENERAL_INFO}
              className={step >= Enums.NUMERIC_VALUE.ONE ? "active" : "disabled"}
            >
              <span className='nav-count'>1</span>
              <span className='nav-text'>General Information</span>
            </Link>
          </OverlayTrigger>
        </li>
        <li>
          <OverlayTrigger
            placement='right'
            overlay={
              <Tooltip className={toggleDrawer ? TOOLTIP_CLASS.HIDE : TOOLTIP_CLASS.SHOW}>Contact Information</Tooltip>
            }
          >
            <Link
              to={ROUTES.PARTNER_CONTACT_INFO}
              className={step >= Enums.NUMERIC_VALUE.TWO ? "active" : "disabled"}
            >
              <span className='nav-count'>2</span>
              <span className='nav-text'>Contact Information</span>
            </Link>
          </OverlayTrigger>
        </li>
        <li>
          <OverlayTrigger
            placement='right'
            overlay={
              <Tooltip className={toggleDrawer ? TOOLTIP_CLASS.HIDE : TOOLTIP_CLASS.SHOW}>Other Information</Tooltip>
            }
          >
            <Link
              to={ROUTES.PARTNER_OTHER_INFO}
              className={
                step >= Enums.NUMERIC_VALUE.THREE ? "active" : "disabled"
              }
            >
              <span className='nav-count'>3</span>
              <span className='nav-text'>Other Information</span>
            </Link>
          </OverlayTrigger>
        </li>
        <li>
          <OverlayTrigger
            placement='right'
            overlay={
              <Tooltip className={toggleDrawer ? TOOLTIP_CLASS.HIDE : TOOLTIP_CLASS.SHOW}>Terms and Conditions</Tooltip>
            }
          >
            <Link
              to={ROUTES.PARTNER_TERMS_CONDITIONS}
              className={
                step === Enums.NUMERIC_VALUE.FOUR ? "active" : "disabled"
              }
            >
              <span className='nav-count'>4</span>
              <span className='nav-text'>Terms and Conditions</span>
            </Link>
          </OverlayTrigger>
        </li>
      </ul>
    </div>
  );
};

ProfileNav.propTypes = {
  toggleDrawer: PropTypes.bool,
};

export default ProfileNav;
