import Enums from "../util/Enums";
import constant from "../util/constant";
import { convertActualTime, createRelationExpression, getOptInType } from "../util/helpers";
import offerConstant from "../util/offerConstant";

const { REQUEST_TYPE: { OFFER, OFFER_TEMPLATE }, RANKING_OBJECTIVE } = offerConstant;
const { NUMERIC_VALUE: { ZERO, ONE } } = Enums;
const { OPT_IN_STATUS } = constant;

export class OfferTemplateRequest {
  constructor( obj ) {
    /* eslint-disable camelcase */
    const isOffer = ( obj.requestType === OFFER ) || ( obj.requestType === OFFER_TEMPLATE );

    this.name = isOffer ? obj.offerName.trim() : obj.tournamentName.trim();
    this.description = obj.rawDescription;
    this.description_text = obj.descriptionText;
    this.template_id = "";
    this.offerId = obj.offerId || "";
    this.offerType = obj.requestType;
    this.relation_expression = createRelationExpression( obj.achievements );
    this.achievements = obj.achievements.map( ( achievement ) => {
      return {
        name: achievement.name.trim(),
        description: achievement.rawDescription,
        description_text: achievement.descriptionText,
        event_id: achievement.event.value,
        function: achievement.function?.value,
        operator: achievement.comparison?.value || "1",
        value: achievement.value || "",
        ranking_objective: achievement.ranking?.value || RANKING_OBJECTIVE[ ZERO ].value,
        time_limit_as_seconds: convertActualTime( {
          days: achievement.days || ZERO,
          hours: achievement.hours || ZERO,
          minutes: achievement.minutes || ZERO,
          seconds: achievement.seconds || ZERO,
        } ).totalTimeInSec,
      };
    } );
    this.reward = isOffer ?
      [ {
        qx_points: obj.qxPoint,
        partner_cost: obj.qxPoint * obj.partnerCostUsdPerQxPoint,
      } ]
      :
      obj.rewards.map( ( reward, index ) => {
        return {
          qx_points: reward.qxPoint,
          partner_cost: reward.qxPoint * obj.partnerCostUsdPerQxPoint,
          place: index + ONE,
        };
      } );
    /* eslint-enable camelcase */

    const optInDetails = obj.isClone ? obj.optIn : obj;

    if( optInDetails.optInType === OPT_IN_STATUS.YES ) {
      this.optIn = {
        optInType: getOptInType( optInDetails.optInType, Number( optInDetails.entryFee ) ),
        membershipId: optInDetails.membershipId,
        entryFee: optInDetails.entryFee || null,
        minParticipants: optInDetails.minParticipants || null,
        maxParticipants: optInDetails.maxParticipants || null,
      };
    }
    else {
      this.optIn = {
        optInType: optInDetails.optInType,
        membershipId: null,
        entryFee: null,
        minParticipants: null,
        maxParticipants: null,
      };
    }
  }
}
