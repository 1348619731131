import draftToHtml from "draftjs-to-html";
import { markdownToDraft } from "markdown-draft-js";
import partnerGameConstant from "../util/partnerGameConstant";

const { GAME_STATUS_DROPDOWN, GAME_STATUS_ID } = partnerGameConstant;

export class PartnerGameDetails {
  constructor( obj ) {
    this.rawDescription = obj.description || "";
    this.description = obj.description ? draftToHtml( markdownToDraft( obj.description ) ) : "";
    this.gameId = obj.game_id || "";
    this.gameIcon = obj.icon_url || "";
    this.isActive = obj.is_active || "";
    this.gameName = obj.name || "";
    this.partnerId = obj.partner_id || "";
    this.testingComments = obj.comment || "";
    this.partnerComments = obj.partner_comments || "";
    this.status = ( obj.status === GAME_STATUS_ID.IN_DEVELOPMENT || obj.status === GAME_STATUS_ID.READY_FOR_TESTING ) ? obj.status : GAME_STATUS_ID.IN_DEVELOPMENT;
    this.descriptionText = obj.description_text || "";
    this.currentStatus = GAME_STATUS_DROPDOWN.find( status => status.value === Number( obj.status ) ).label;
    this.currentStatusId = obj.status;
    this.GameTestingComments = obj?.GameTestingComments?.map( ( commentObj ) => {
      return {
        ...commentObj,
        partnerId: commentObj.partner_id,
        gameId: commentObj.game_id,
      };
    } ) || [];
    this.downloadUrls = obj?.GameUrlDetails?.filter( gameUrl => gameUrl.url_type === "1" ).map( ( { url } ) => url ) || [
      "",
    ];
    this.apiUrls = obj?.GameUrlDetails?.filter( gameUrl => gameUrl.url_type === "2" ).map( ( { url } ) => url ) || [
      "",
    ];
    this.exchangeableAssets = obj?.GameAssetDetails?.length
      ? obj?.GameAssetDetails?.map( ( gameAsset ) => {
        return {
          gameAssetId: gameAsset.game_asset_id,
          name: gameAsset.name,
          price: gameAsset.unit_price_qx_points,
          isApproved: gameAsset.is_approved,
        };
      } )
      : [
        {
          name: "",
          price: "",
        },
      ];
    this.approvedDate = obj.approved_date || null;
    this.offerSchedules = obj.OfferSchedules || [];
    this.UserGames = obj.UserGames || [];
  }
}
