import constant from "../../util/constant";
import {
  SET_CONTACT_INFO_FAILURE,
  SET_CONTACT_INFO_API_REQUEST,
  SET_GENERAL_INFO_SUCCESS,
  SET_CONTACT_INFO_SUCCESS,
  UPLOAD_LOGO_SUCCESS,
  GET_PARTNER_DETAIL_SUCCESS,
  SET_PARTNER_INFO_EMPTY,
  SET_GENERAL_INFO_API_REQUEST,
  SET_GENERAL_INFO_API_END,
  SET_OTHER_INFO_API_REQUEST,
  SET_OTHER_INFO_SUCCESS,
  SET_OTHER_INFO_FAILURE,
  SET_TERMS_INFO_API_REQUEST,
  SET_TERMS_INFO_SUCCESS
} from "../actionTypes";

const DEFAULT_STATE = {
  loading: false,
  message: "",
  data: {},
};
const { API_STATUS } = constant;

const profileInfo = ( state = DEFAULT_STATE, action = "" ) => {
  switch ( action.type ) {
    case SET_GENERAL_INFO_API_REQUEST:
    case SET_CONTACT_INFO_API_REQUEST:
    case SET_OTHER_INFO_API_REQUEST:
    case SET_TERMS_INFO_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
      };
    case SET_GENERAL_INFO_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
          logo: state.data.logo || "",
        },
        status: API_STATUS.SUCCESS,
      };
    case SET_CONTACT_INFO_SUCCESS:
    case SET_OTHER_INFO_SUCCESS:
    case SET_TERMS_INFO_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        status: API_STATUS.SUCCESS,
      };
    case UPLOAD_LOGO_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          logo: action.payload.data.path,
        },
      };
    case GET_PARTNER_DETAIL_SUCCESS:
    case SET_PARTNER_INFO_EMPTY:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case SET_GENERAL_INFO_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case SET_CONTACT_INFO_FAILURE:
    case SET_OTHER_INFO_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
      };
    default:
      return state;
  }
};

export default profileInfo;
