import PropTypes from "prop-types";
import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

const OtherInformation = ( { values, errors, handleInputChange, constant } ) => {
  return (
    <>
      <div className='page-inner-box profile-steps-box'>
        <Form>
          <Row>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Estimated number of games</Form.Label>
                <Form.Control
                  disabled
                  min="0"
                  type='number'
                  value={values?.estimatedOfGames}
                  name='estimatedOfGames'
                  onChange={handleInputChange}
                />
                <div className='invalid-feedback d-block'>
                  {errors?.estimatedOfGames}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>
                      Estimated number of gamers across those games
                </Form.Label>
                <Form.Control
                  disabled
                  min="0"
                  type='number'
                  value={values?.estimatedOfGamers}
                  name='estimatedOfGamers'
                  onChange={handleInputChange}
                />
                <div className='invalid-feedback d-block'>
                  {errors?.estimatedOfGamers}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Game Growth (%)</Form.Label>
                <Form.Control
                  disabled
                  type='number'
                  value={values?.gameGrowth}
                  name='gameGrowth'
                  onChange={handleInputChange}
                  maxLength={3}
                />
                <div className='invalid-feedback d-block'>
                  {errors?.gameGrowth}
                </div>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Gamers Growth (%)</Form.Label>
                <Form.Control
                  disabled
                  type='number'
                  value={values?.gamersGrowth}
                  name='gamersGrowth'
                  onChange={handleInputChange}
                  maxLength={3}
                />
                <div className='invalid-feedback d-block'>
                  {errors?.gamersGrowth}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <div className='profile-radio-list'>
            {constant?.map( item => (
              <div className='custom-radio' key={item?.id}>
                <label>
                  <input
                    disabled
                    type='radio'
                    name='softwareDevelopmentAbility'
                    value={item?.id}
                    checked={item?.id === values?.softwareDevelopmentAbility}
                    onChange={handleInputChange}
                  />
                  <span>
                    {item?.label}
                  </span>
                </label>
              </div>
            ) )}
          </div>
        </Form>
      </div>
    </>
  );
};

OtherInformation.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  handleInputChange: PropTypes.func,
  constant: PropTypes.array,
};

export default OtherInformation;
