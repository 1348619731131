import { toastr } from "react-redux-toastr";
import { getAdminNotifications, getPartnerNotifications, readAdminNotifications, readPartnerNotifications } from "../../services/notificationService";
import constant from "../../util/constant";
import {
  START_LOADER,
  STOP_LOADER,
  NOTIFICATIONS_API_REQUEST,
  NOTIFICATIONS_FAILURE,
  NOTIFICATIONS_SUCCESS,
  READ_NOTIFICATIONS_SUCCESS,
  READ_NOTIFICATIONS_FAILURE,
  ADMIN_API_END,
  PARTNER_API_END
} from "../actionTypes";

const { ERROR_MESSAGES, NOTIFICATION_API_TYPES } = constant;

//Get notification actions
export const getAdminNotificationsAction = ( ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );

    const response = await getAdminNotifications( );
    const dataList = response.data;

    dispatch( { type: NOTIFICATIONS_SUCCESS,
      payload: { dataList, meta: null },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: NOTIFICATIONS_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_API_END,
    } );
  }
};

export const getPartnerNotificationsAction = requestParams => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );

    const response = await getPartnerNotifications( requestParams );
    const dataList = response.data;

    dispatch( { type: NOTIFICATIONS_SUCCESS,
      payload: { dataList, meta: null },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: NOTIFICATIONS_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: PARTNER_API_END,
    } );
  }
};

export const readPartnerNotificationsAction = requestParams => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: NOTIFICATIONS_API_REQUEST,
      payload: NOTIFICATION_API_TYPES.MARK_READ_NOTIFICATIONS,
    } );

    const response = await readPartnerNotifications( requestParams );
    const dataList = response.data;

    dispatch( { type: READ_NOTIFICATIONS_SUCCESS,
      payload: { dataList, meta: null },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: READ_NOTIFICATIONS_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: PARTNER_API_END,
    } );
  }
};

export const readAdminNotificationsAction = requestParams => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: NOTIFICATIONS_API_REQUEST,
      payload: NOTIFICATION_API_TYPES.MARK_READ_NOTIFICATIONS,
    } );

    const response = await readAdminNotifications( requestParams );
    const dataList = response.data;

    dispatch( { type: READ_NOTIFICATIONS_SUCCESS,
      payload: { dataList, meta: null },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: READ_NOTIFICATIONS_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_API_END,
    } );
  }
};
