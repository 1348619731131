const adminUserConstant = {
  ADMIN_USERS_API_TYPES: {
    GET_ADMIN_USERS: "GET_ADMIN_USERS",
    ADD_ADMIN_USER: "ADD_ADMIN_USER",
    GET_ADMIN_USER_DETAIL: "GET_ADMIN_USER_DETAIL",
    UPDATE_ADMIN_USER_PERMISSION: "UPDATE_ADMIN_USER_PERMISSION",
    GET_ADMIN_DETAIL: "GET_ADMIN_DETAIL",
    ENABLE_DISABLE_ADMIN_USER: "ENABLE_DISABLE_ADMIN_USER",
    SIGN_OUT_CONFIRMATION: "SIGN_OUT_CONFIRMATION",
  },
  ADMIN_PERMISSIONS: {
    ONBOARD_USERS: 1,
    MANAGE_PARTNERS: 2,
    MANAGE_GAMES: 3,
    APPROVE_OFFERS: 4,
    MANAGE_GAMERS: 5,
    OFFER_REWARD_POINTS: 6,
    MANAGE_EVENTS: 7,
    VIEW_TRANSACTIONS: 8,
    MANAGE_REDEEM_REQUESTS: 9,
  },
  ADMIN_USER_FILTER_LIST: [
    { label: "Enabled", value: 1 },
    { label: "Disabled", value: 0 },
  ],
};

export default adminUserConstant;
