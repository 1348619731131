/* eslint max-lines: [ 'warn', { 'max': 675, 'skipBlankLines': true, 'skipComments': true } ] */
import imageCompression from "browser-image-compression";
import { Country, State, City } from "country-state-city";
import { EditorState, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { minBy, maxBy } from "lodash";
import { markdownToDraft } from "markdown-draft-js";
import * as moment from "moment-timezone";
import { toastr } from "react-redux-toastr";
import { createFilter } from "react-select";

// Local-imports
import { rolesConfig } from "../../src/config/rolesConfig";
import adminUserConstant from "../util/adminUserConstant";
import partnerProfileConstant from "../util/partnerProfileConstant";
import Enums from "./Enums";
import constant from "./constant";
import offerConstant from "./offerConstant";
import partnerGameConstant from "./partnerGameConstant";
import tournamentConstant from "./tournamentConstant";
import { validateAchievements, validateRewards } from "./validateMultipleFields";

const { OFFER_TYPE_STATUS, OFFER_DETAIL_STATUS, DRAFT_ENTITY_NAME } = offerConstant;
const { TOURNAMENT_TYPE_STATUS } = tournamentConstant;
const { ADMIN_PERMISSIONS } = adminUserConstant;
const { MESSAGE, MIN_MAX, ACTION_TYPES, QUERY_PARAMS, OPT_IN_STATUS } = constant;
const { NUMERIC_VALUE } = Enums;
const { GENERAL_INFO_KEYS, CONTACT_INFO_KEYS, OTHER_INFO_KEYS } = partnerProfileConstant;

export const arrayToObject = ( array, keyField = "_id" ) =>
  array.reduce( ( obj, item ) => {
    obj[ item[ keyField ] ] = item;

    return obj;
  }, {} );

export const formatFileSize = ( bytes, decimalPoint ) => {
  if( bytes === 0 ) {
    return "0 Bytes";
  }

  const k = 1000,
    dm = decimalPoint || 2,
    sizes = [ "Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB" ],
    i = Math.floor( Math.log( bytes ) / Math.log( k ) );

  return {
    fileSize: parseFloat( ( bytes / Math.pow( k, i ) ).toFixed( dm ) ),
    size: sizes[ i ],
  };
};

export const getAllCountries = ( countryCode ) => {
  const countries = countryCode
    ? Country.getAllCountries()
    : Country.getCountryByCode( countryCode );

  return countries?.map( ( country ) => {
    return {
      label: country.name,
      value: country.isoCode,
    };
  } ) || [];
};

export const getStatesOfCountry = countryCode =>
  State.getStatesOfCountry( countryCode )?.map( ( state ) => {
    return {
      label: state.name,
      value: state.isoCode,
    };
  } ) || [];
export const getCitiesOfState = ( countryCode, stateCode ) =>
  City.getCitiesOfState( countryCode, stateCode ).map( ( city ) => {
    return {
      label: city.name,
      value: city.name,
    };
  } );

export function phoneFormat( number ) {
  if( number ) {
    // replace character digits to blank by regex.
    const x = number.replace( /\D/g, "" ).match( /(\d{0,3})(\d{0,3})(\d{0,4})/ );

    // converted phone format by given regex.
    return (
      ( x[ 1 ] ? `(${ x[ 1 ] }` : "" ) +
      ( x[ 2 ] ? `) ${ x[ 2 ] }` : "" ) +
      ( x[ 3 ] ? `-${ x[ 3 ] }` : "" )
    );
  }

  return "";
}

export const checkAddedSuggestedModification = (
  typeKey,
  partnerAmendmentDetails = []
) => {
  const suggestionObj = partnerAmendmentDetails.find(
    suggestion => suggestion.section_name === typeKey
  );

  if( suggestionObj?.is_resolved ) {
    return "Resolved";
  }
  else if( suggestionObj?.review_note ) {
    return "Edit Suggestion";
  }

  return "Suggest Modification";
};

export const requestNotAccepted = status =>
  status === constant.APPLICATION_STATUS.PENDING ||
  status === constant.APPLICATION_STATUS.AMENDMENTS_SUGGESTED;

export const validateFile = ( file ) => {
  const validTypes = [ "image/jpeg", "image/jpg", "image/png" ];

  if( validTypes.indexOf( file.type ) === -1 ) {
    return false;
  }

  return true;
};

export const exchangeableAssetsDisabled = gameDetail =>
  !gameDetail?.approvedDate &&
  gameDetail?.status !== partnerGameConstant.GAME_STATUS_ID.IN_DEVELOPMENT;

export const convertDate = ( value, validation ) => {
  const date = new Date( value );
  const year = date.getFullYear();
  const month = date.getMonth();
  let formattedMonth = month + 1;
  let dt = date.getDate();

  if( formattedMonth < 10 ) {
    formattedMonth = `0${ formattedMonth.toString() }`;
  }

  if( dt < 10 ) {
    dt = `0${ dt }`;
  }

  return validation ? `${ year }-${ formattedMonth }-${ dt }` : `${ dt } ${ constant.MONTHS[ month ] } ${ year }`;
};

export const convertQxPointsToCurrency = ( qxPoint, usdPerQxPoint ) =>
  ( qxPoint ? `($${ ( qxPoint * usdPerQxPoint ).toFixed( 2 ) })` : "" );

export const addActiveClass = ( navList, activeNav ) =>
  ( navList.includes( activeNav ) ? "active" : "" );

export const addActiveClassForTab = ( tab, activeTab ) => ( tab === activeTab ? "active" : "" );

export const stripHtml = ( html ) => {
  const temporalDivElement = document.createElement( "div" );

  temporalDivElement.innerHTML = html;

  return temporalDivElement.textContent || temporalDivElement.innerText || "";
};

export const useSetUrlParams = ( key, value ) => {
  const searchParams = new URLSearchParams( window.location.search );

  searchParams.set( key, value );

  return searchParams.toString();
};

export const convertActualTime = ( value ) => {
  const { seconds = 0, days = 0, hours = 0, minutes = 0 } = value;
  const totalTimeInSec = ( Number( days ) * 86400 ) + ( Number( hours ) * 3600 ) + ( Number( minutes ) * 60 ) + Number( seconds );

  const d = Math.floor( totalTimeInSec / ( 3600 * 24 ) );
  const h = Math.floor( totalTimeInSec % ( 3600 * 24 ) / 3600 );
  const m = Math.floor( totalTimeInSec % 3600 / 60 );
  const s = Math.floor( totalTimeInSec % 60 );

  const dDisplay = d > 0 ? `${ formatNumberAsPerLocale( d ) }d` : "";
  const hDisplay = h > 0 ? `${ h }h` : "";
  const mDisplay = m > 0 ? `${ m }m` : "";
  const sDisplay = s > 0 ? `${ s }s` : "";

  return {
    totalTimeInSec,
    actualTime: `${ dDisplay } ${ hDisplay } ${ mDisplay } ${ sDisplay }`,
    fieldTime: { d, h, m, s },
  };
};

export const getOfferHeaderData = ( offerType, entityName, isClone ) => {
  if( isClone ) {
    return {
      title: "Clone Offer",
      description: MESSAGE.OFFER_HEADER_DESCRIPTION,
    };
  }

  switch ( offerType ) {
    case OFFER_TYPE_STATUS.OFFER_TEMPLATE:
      return {
        title: "Create a new Offer Template",
        description: MESSAGE.OFFER_HEADER_DESCRIPTION,
      };
    case OFFER_TYPE_STATUS.OFFER_FROM_SCRATCH:
      return {
        title: "Add Offer",
        description: MESSAGE.OFFER_HEADER_DESCRIPTION,
      };
    case OFFER_TYPE_STATUS.OFFER_FROM_TEMPLATE:
      return {
        title: "Start an offer from a Template",
        description: MESSAGE.OFFER_HEADER_DESCRIPTION,
      };
    case OFFER_TYPE_STATUS.CLONE:
      return {
        title: "Clone Offer",
        description: MESSAGE.OFFER_HEADER_DESCRIPTION,
      };
    case OFFER_TYPE_STATUS.DRAFT:
      return {
        title: `Edit ${ ( entityName === DRAFT_ENTITY_NAME.OFFER_TEMPLATE_DRAFT ) ? "Template" : "Offer" } Draft`,
        description: MESSAGE.OFFER_HEADER_UPDATE_DESCRIPTION,
      };
    default:
      return {
        title: "Edit Offer Template",
        description: MESSAGE.OFFER_HEADER_UPDATE_DESCRIPTION,
      };
  }
};

export const getTournamentHeaderData = ( offerType, entityName, isClone ) => {
  if( isClone ) {
    return {
      title: "Clone Tournament",
      description: MESSAGE.OFFER_HEADER_DESCRIPTION,
    };
  }

  switch ( offerType ) {
    case TOURNAMENT_TYPE_STATUS.TOURNAMENT_TEMPLATE:
      return {
        title: "Create a Tournament Template",
        description: MESSAGE.OFFER_HEADER_DESCRIPTION,
      };
    case TOURNAMENT_TYPE_STATUS.TOURNAMENT:
      return {
        title: "Create Tournament",
        description: MESSAGE.OFFER_HEADER_DESCRIPTION,
      };
    case OFFER_TYPE_STATUS.DRAFT:
      return {
        title: `Edit ${ ( entityName === DRAFT_ENTITY_NAME.TOURNAMENT_TEMPLATE_DRAFT ) ? "Template" : "Tournament" } Draft`,
        description: MESSAGE.OFFER_HEADER_UPDATE_DESCRIPTION,
      };
    default:
      return {
        title: "Edit Tournament Template",
        description: MESSAGE.OFFER_HEADER_UPDATE_DESCRIPTION,
      };
  }
};

export const createRelationExpression = achievements => achievements.reduce( ( acc, c, i ) => {
  // TODO: acc shouldn't be modified. This could likely be changed to
  // return acc + ( i === 0 ? c.name : ` && ${ c.name }` );
  // eslint-disable-next-line no-param-reassign
  return acc += i === 0 ? c.name : ` && ${ c.name }`;
}, "" );

/**
 * Checks Offer/Tournament achievements for validation errors
 * @param {*} achievements - Array of achievement objects
 * @param {*} errors - Array of validation errors on the page
 * @param {*} isTournament - True if achievements belong to a Tournament else false
 * @returns {boolean} True if no validation error is found.
 */
export const doAchievementsHaveNoError = ( achievements, errors, isTournament ) =>
  validateAchievements( achievements, errors, null, isTournament )
    .filter( exError => Object.values( exError )
      .filter( err => err )?.length )?.length === 0;

export const convertHtmlToEditorHtml = ( description ) => {
  const contentBlock = htmlToDraft( description );
  const contentState = ContentState.createFromBlockArray( contentBlock.contentBlocks );

  return EditorState.createWithContent( contentState );
};

// Can change (length) 7 to 2 for longer results.
// It will generate anywhere between 0 and 6 characters due to the
// fact that trailing zeros get removed when stringifying floating points.
export const randomString = length => ( Math.random() + 1 ).toString( 36 ).substring( length );

export const adminRestrictComponents = ( pathRole, data ) => {
  let compArray = [];

  compArray = rolesConfig[ pathRole ].routes.filter( ( route ) => {
    if( !data.includes( ADMIN_PERMISSIONS.MANAGE_PARTNERS ) ) {
      return ![ "PartnerList", "PartnerRequestDetail" ].includes( route.component );
    }

    if( !data.includes( ADMIN_PERMISSIONS.ONBOARD_USERS ) ) {
      return ![ "AdminUserList" ].includes( route.component );
    }

    if( !data.includes( ADMIN_PERMISSIONS.MANAGE_EVENTS ) ) {
      return ![ "AdminEventList", "AdminEventDetail", "AddEditAdminEvent" ].includes( route.component );
    }

    if( !multipleArrayExist( data, [ ADMIN_PERMISSIONS.MANAGE_REDEEM_REQUESTS, ADMIN_PERMISSIONS.VIEW_TRANSACTIONS ] ) ) {
      return ![ "TransactionList", "ExchangeList", "RedeemList", "GamerExchangeList", "RedeemTransactions", "RedeemTxDetail" ].includes( route.component );
    }

    return route.component;
  } ).map( route => route.component );

  return compArray;
};

// concatenating all the attributes with delimiter
export const concatStrings = ( object, delimiter ) => object?.map( value => value.value ).join( delimiter );

export const awsSecret = () => {
  const { userPoolId, webClientId } = CONFIG_SETTINGS.awsConfig;

  // Output needs to have the properties Amplify expects
  // User Pool ID will always have the region in the value
  return {
    // eslint-disable-next-line camelcase
    aws_cognito_region: userPoolId.substring( 0, userPoolId.indexOf( "_" ) ),
    // eslint-disable-next-line camelcase
    aws_user_pools_id: userPoolId,
    // eslint-disable-next-line camelcase
    aws_user_pools_web_client_id: webClientId,
  };
};

export const enableOnLoadEvent = () => {
  window.onbeforeunload = function() {
    return "Dialog text here.";
  };
};

export const disableOnLoadEvent = () => {
  window.onbeforeunload = null;
};

export const getOfferStatus = ( val ) => {
  let status = val?.Offer?.approval_status;
  const currentDate = new Date().toUTCString();

  if( ( status === OFFER_DETAIL_STATUS.APPROVED ) ) {
    // check offer is expired or not
    if( moment( currentDate ).isBetween( val?.start_date, val?.end_date, "day", "[]" ) ) {
      status = OFFER_DETAIL_STATUS.CURRENT;
    }
    else if( moment( currentDate ).isAfter( val?.end_date, "day" ) ) {
      status = OFFER_DETAIL_STATUS.EXPIRED;
    }
  }

  return status;
};

export const createNewErrorObj = ( fields, errors ) => fields.reduce( ( acc, c ) => {
  const objArrayKey = Object.keys( errors );

  if( objArrayKey.includes( c ) && errors[ c ] ) {
    acc[ c ] = errors[ c ];
  }

  return acc;
}, {} );

export const resizeImageFile = async ( file ) => {
  const options = {
    maxSizeMB: 0.1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  try {
    const blobFile = await imageCompression( file, options );
    const imgName = blobFile?.name ? blobFile.name : `${ new Date().getTime() }image.jpg`;

    return new File( [ blobFile ], imgName, {
      type: blobFile.type,
    } );
  }
  catch ( error ) {
    toastr.error( error );
  }

  return null;
};

/**
 * Formats a raw value as per user's local number format
 * @param {*} rawValue - Value to be formatted
 * @param {*} fallbackValue - some alternative value to be shown in case
 *     the raw value is not a valid number
 * @returns {string} formatted string
 */
export const formatNumberAsPerLocale = ( rawValue, fallbackValue ) => {
  const locale = navigator.language ?? "en-US"; //Set the locale as 'en-US' if no language is set

  if( rawValue && ( !isNaN( rawValue ) ) ) {
    return new Intl.NumberFormat( locale ).format( rawValue );
  }
  else if( fallbackValue ) {
    return fallbackValue;
  }

  return rawValue;
};

export const convertMarkDownToDraft = ( description ) => {
  return draftToHtml( markdownToDraft( description ) );
};

const validateError = ( val, msg, actualMsg ) => {
  if( !val ) {
    return msg;
  }
  else if( actualMsg ) {
    return actualMsg;
  }

  return "";
};

export const changeErrorMessage = ( values, errors, allowTermsConditions, suggestion ) => {
  const objErrors = {
    generalInfo: [],
    contactInfo: [],
    otherInfo: [],
    termsConditions: [],
  };

  let err;

  // TODO: refactor to avoid complexity
  // eslint-disable-next-line complexity
  Object.keys( errors ).forEach( ( key ) => {
    const val = values[ key ];

    switch ( key ) {
      case "logo":
        err = validateError( val, "Logo is missing.", errors[ key ] );

        break;
      case "legalEntityName":
        err = validateError( val, "Legal entity name is missing.", errors[ key ] );

        break;
      case "address1":
        err = validateError( val, "Address is missing.", errors[ key ] );

        break;
      case "country":
        err = validateError( val, "Country is missing.", errors[ key ] );

        break;
      case "state":
        err = validateError( val, "State is missing.", errors[ key ] );

        break;
      case "city":
        err = validateError( val, "City is missing.", errors[ key ] );

        break;
      case "zip":
        err = validateError( val, "Zip code is missing.", errors[ key ] );

        break;
      case "primaryName":
        err = validateError( val, "Primary name is missing.", errors[ key ] );

        break;
      case "primaryTitle":
        err = validateError( val, "Primary title is missing.", errors[ key ] );

        break;
      case "primaryPhoneNumber":
        err = validateError( val, "Primary phone number is missing.", errors[ key ] );

        break;
      case "primaryEmail":
        err = validateError( val, "Primary email is missing.", errors[ key ] );

        break;
      case "secondaryName":
        err = validateError( val, "Secondary name is missing.", errors[ key ] );

        break;
      case "secondaryTitle":
        err = validateError( val, "Secondary title is missing.", errors[ key ] );

        break;
      case "secondaryPhoneNumber":
        err = validateError( val, "Secondary phone number is missing.", errors[ key ] );

        break;
      case "secondaryEmail":
        err = validateError( val, "Secondary email is missing.", errors[ key ] );

        break;
      case "authorizedName":
        err = validateError( val, "Authorized name is missing.", errors[ key ] );

        break;
      case "authorizedTitle":
        err = validateError( val, "Authorized title is missing.", errors[ key ] );

        break;
      case "estimatedOfGames":
        err = validateError( val, "Estimated number of games is missing.", errors[ key ] );

        break;
      case "estimatedOfGamers":
        err = validateError( val, "Estimated number of gamers is missing.", errors[ key ] );

        break;
      case "gameGrowth":
        err = validateError( val, "Game growth is missing.", errors[ key ] );

        break;
      case "gamersGrowth":
        err = validateError( val, "Gamer growth is missing.", errors[ key ] );

        break;
      default:
        err = `Unknown error in ${ key }.`;
    }

    if( err ) {
      if( GENERAL_INFO_KEYS.includes( key ) ) {
        objErrors.generalInfo.push( err );
      }
      else if( CONTACT_INFO_KEYS.includes( key ) ) {
        objErrors.contactInfo.push( err );
      }
      else if( OTHER_INFO_KEYS.includes( key ) ) {
        objErrors.otherInfo.push( err );
      }
    }
  } );

  if( !allowTermsConditions && !suggestion ) {
    objErrors.termsConditions.push( "Either accept terms and conditions or add some suggestions." );
  }

  return objErrors;
};

export const currencyFormat = ( data, param = "data" ) => {
  if( data && !isNaN( parseFloat( data[ param ] ) ) ) {
    const requestParams = Object.assign(
      {
        style: "currency",
        currency: "USD",
      },
      data.minimumFractionDigits && { minimumFractionDigits: data.minimumFractionDigits }
    );

    return new Intl.NumberFormat( "en-US", requestParams ).format( data[ param ] );
  }

  return "$--.--";
};

export const getUrlParameter = ( key, col ) => {
  const queryParamsOriginal = new URLSearchParams( window.location.search );
  let queryParams;

  if( QUERY_PARAMS.includes( key ) ) {
    queryParams = ( queryParamsOriginal.get( key ) === "" || queryParamsOriginal.get( key ) === null ) ? col : queryParamsOriginal.get( key );
  }

  if( [ "fullNames", "gamerNames", "gameNames", "assetNames", "paymentVendors", "transactionStatus", "offerNames", "status", "optInTypes", "planNames" ].includes( key ) ) {
    queryParams = queryParamsOriginal.get( key ) === "" ? [] : JSON.parse( queryParamsOriginal.get( key ) );
  }

  if( [ "startDate", "endDate" ].includes( key ) ) {
    queryParams = ( queryParamsOriginal.get( key ) === null || queryParamsOriginal.get( key ) === "" ) ? false : queryParamsOriginal.get( key );
  }

  return queryParams;
};

export const prepareLabelValueFormatData = ( data, key = null ) => {
  let uniqueObjArray = [];

  if( key ) {
    uniqueObjArray = data.map( name => ( { label: name[ key ], value: name[ key ] } ) );
  }
  else {
    uniqueObjArray = data.map( name => ( { label: name, value: name } ) );
  }

  return [ ...new Map( uniqueObjArray.map( item => [ item.label, item ] ) ).values() ]
    .filter( item => item.value )
    .sort( ( a, b ) => a.label.localeCompare( b.label ) );
};

export const extremum = ( arrayObj, key, operator ) => {
  let data;

  if( operator === MIN_MAX.MIN ) {
    data = minBy( arrayObj, key );
  }
  else {
    data = maxBy( arrayObj, key );
  }

  return data;
};

export const sliderStep = ( min, max ) => {
  const differencePoint = max - min;

  if( differencePoint < NUMERIC_VALUE.ONE ) {
    return differencePoint / NUMERIC_VALUE.HUNDRED;
  }

  return NUMERIC_VALUE.ONE;
};

export const cashbackName = ( vendor ) => {
  if( vendor.cashBackMethod.accountNickname ) {
    return `${ vendor.cashBackMethod.accountNickname } (${ vendor.gamersNameForMethod })`;
  }

  return vendor.gamersNameForMethod;
};

export const getUrlParamsValue = ( val, data ) => {
  let value = "";

  if( data.type === ACTION_TYPES.OTHER_SELECT ) {
    value = val;
  }

  try {
    if( data.type === ACTION_TYPES.MULTI_SELECT ) {
      value = JSON.parse( val );
    }
  }
  catch {
    value = [];
  }

  if( data.type === ACTION_TYPES.SLIDER_POINT ) {
    value = Number( val );
  }

  if( data.type === ACTION_TYPES.DATE ) {
    value = ( val === null || val === "" ) ? false : new Date( val );
  }

  return value;
};

export const getUniqueName = ( originalName, list ) => {
  let num = 1;
  let looping = true;
  let uniqueName = originalName;

  while( looping ) {
    uniqueName = `${ originalName } (${ num })`;

    if( list.includes( uniqueName ) ) {
      num += 1;
    }
    else {
      looping = false;
    }
  }

  return uniqueName;
};

export const multipleArrayExist = ( array1 = [], array2 = [] ) => array2.some( ( value ) => {
  return array1.includes( value );
} );

export const getFullBusinessLocationAdd = ( data = {} ) => {
  const {
    business_location_add1: add1 = "",
    business_location_add2: add2 = "",
    business_location_city: city = "",
    business_location_state: state = "",
    business_location_zip_code: zipCode = "",
    business_location_country: country = "",
  } = data;
  const fullAddress = [ add1, add2, city, state, country ].filter( Boolean ).join( ", " );

  return zipCode ? `${ fullAddress } - ${ zipCode }` : fullAddress;
};

export const isAnySuggestion = ( data = [] ) => data.some( suggestion => !suggestion.is_resolved );

export const isAssetError = ( gameName, serverErrorsGameNameList = [] ) => serverErrorsGameNameList.includes( gameName );

export const checkFilterApplied = ( url, filterNames, slidersObj = {} ) => {
  let filterApplied = false;

  if( url ) {
    const appliedFilters = url.replace( "?", "" )?.split( "&" ) || [];

    filterApplied = appliedFilters.reduce( ( acc, c ) => {
      const [ key, value ] = c.split( "=" );

      if( value && ( value !== String( slidersObj[ key ] ) ) && filterNames.includes( key ) ) {
        return true;
      }

      return acc;
    }, false );
  }

  return filterApplied;
};

export const doRewardsHaveNoError = ( rewards, isTournament ) => validateRewards( rewards, isTournament ).filter( err => err ).length === 0;

/**
 * @description - This function is used to convert selected date-time to UTC format.
 * @param {string} date
 * @param {string} time
 * @param {Object} timeZone conains timeZone value, offset, altName, dstOffset, etc.
 * @returns {string} formatted date-time string
 */
export const convertLocalTimeInUTC = ( date, time, timeZone ) => {
  let timeZoneBasedDate = moment().tz( new Intl.DateTimeFormat().resolvedOptions().timeZone )
    .format();

  if( timeZone?.label ) {
    timeZoneBasedDate = moment.tz( `${ date } ${ time }`, timeZone.value ).format();
  }
  else {
    timeZoneBasedDate = moment.tz( `${ date } ${ time }`, timeZone ).format();
  }

  return moment( timeZoneBasedDate ).utc()
    .format();
};

export const convertTimeStampToTime = ( date ) => {
  const time = new Date( date );

  return time.toLocaleString( "en-US", { hour: "numeric", hour12: true, minute: "numeric" } );
};

export const getTimeZone = ( date ) => {
  const t = new Date( date );
  const timeZone = t.toString().slice( 24 );

  return `${ timeZone.substring( 0, 7 ) }:${ timeZone.substring( 7, timeZone.length ) }`;
};

// Parse @data and return the result. If an error in parsing, return an empty array after displaying:
// if callerErrorMsg is null, then simply display the parse error message
// else if callerErrorMsg is the empty string, don't display anything
// else display callerErrorMsg along with the parse error message.
export const handleParseJSON = ( data, callerErrorMsg = null ) => {
  try {
    return JSON.parse( data );
  }
  catch ( error ) {
    let userMsg = null;

    if( callerErrorMsg === null ) {
      userMsg = "Error";
    }
    else if( callerErrorMsg !== "" ) {
      userMsg = callerErrorMsg;
    }

    // Display something only if userMsg is not null
    if( userMsg !== null ) {
      toastr.error( `${ userMsg }: ${ error.message }` );
    }

    return [];
  }
};


/**
 *
 * @param {string} defaultRoute - default route to redirect to.
 * @param {Object} location - location hook containing routes and states detail.
 * @param {Array} backScreens - number of screens to go back.
 * @returns {string} route to redirect to.
 */
export const getBackNavigationRoute = ( defaultRoute, location, backScreens ) => {
  let backRoute = defaultRoute;

  if( location?.state?.length ) {
    location.state.forEach( ( item ) => {
      if( backScreens.includes( item.from ) ) {
        backRoute = `${ item.path }${ item.state }`;
      }
    } );
  }

  return backRoute;
};

// Used to get the value of selected opt-in option as per the entered fee(qx points).
// If user selected 'Yes' and also entered the entry fee, then return the 'Fee' value else 'Yes'.
export const getOptInType = ( selectedOptIn, entryFee ) => {
  if( entryFee > 0 ) {
    return OPT_IN_STATUS.FEE;
  }

  return selectedOptIn;
};

// get eligible subscription plans for opt-in offer
export const getEligibleSubscriptionPlans = ( optInMembershipId, membershipTypes ) => {
  if( optInMembershipId && membershipTypes ) {
    return membershipTypes.filter( plan => plan.id >= optInMembershipId );
  }

  return [];
};

// Check the input value of number type input field.
// Prevent characters that are not numbers ("e", "E", ".", "+" & "-")
// but are allowed by HTML number input box.
export const checkNonNumericDecimalChars = ( e ) => {
  let checkIfChar = false;

  // TODO: this method should be rewritten
  // eslint-disable-next-line no-undefined
  if( e.key !== undefined ) {
    checkIfChar = [ "e", "E", ".", "+", "-" ].includes( e.key );
  }
  // eslint-disable-next-line no-undefined
  else if( e.keyCode !== undefined ) {
    // Check if it's a "e"/"E" (69), "." (190), "+" (187) or "-" (189).
    checkIfChar = [ 69, 190, 187, 189 ].includes( e.keyCode );
  }

  return checkIfChar && e.preventDefault();
};

// createFilter - function takes an options object as an argument with
// various options for customizing the filter function.
// stringify option is being used to customize how the options are
// stringified for filtering purposes.
// By default, the stringify option creates a string of the format ${option.label}
// ${option.value} to be used for filtering.
// Here, search for matches in the label property of the options and not in the value property.
export const customFilterOption = createFilter( {
  stringify: option => option.label,
} );

// Return the number of remaining days
export const getRemainingDays = ( endDate ) => {
  const now = moment().utc();

  // TODO: refactor to avoid reassigning parameter
  // eslint-disable-next-line no-param-reassign
  endDate = moment( endDate ).utc();

  const remainingDays = endDate.diff( now, "days" );

  return remainingDays;
};

// Formats the data to export to CSV.
export const getExportCSVData = ( dataList, listHeaders, listEnitityKeys ) => {
  const csvData = [ listHeaders ];

  for( let i = 0; i < dataList.length; i++ ) {
    const newList = [];

    listEnitityKeys.forEach( ( entity ) => {
      if( entity === "amount" ) {
        newList.push( `$${ dataList[ i ][ entity ] }` || "-" );
      }
      else {
        newList.push( dataList[ i ][ entity ] || "-" );
      }
    } );
    csvData.push( newList );
  }

  return csvData;
};
