import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { getGamerLinkedGamesAssetsAction } from "../../../../store/actions/partnerGamer";
import AssetList from "../../../shared/asset/assetList";

const PartnerGamerGameAssets = () => {
  const dispatch = useDispatch();
  const { gameId: urlParamString, gamerId } = useParams();
  const { 0: gameId } = String( urlParamString ).split( "?" );
  const { linkedGamesAssets } = useSelector( state => ( {
    linkedGamesAssets: state.partnerGamer.linkedGamesAssets,
  } ) );

  useEffect( () => {
    if( gameId ) {
      dispatch( getGamerLinkedGamesAssetsAction( { gamerId: gamerId, gameId: gameId } ) );
    }
  }, [ gameId ] );

  return (
    <>
      <AssetList assets={linkedGamesAssets?.assets} totalQxPoints={linkedGamesAssets?.totalQxPoints} />
    </>
  );
};

export default PartnerGamerGameAssets;
