import PropTypes from "prop-types";
import React, { useEffect, useCallback, useMemo, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
// local imports.
import { useAddQuery, useForm, useScroll, gamerExchangeEvent } from "../../../hooks";
import { getGamerDetailAction } from "../../../store/actions/adminGamer";
import { resetExchangeTransactionFilter } from "../../../store/actions/adminTransaction";
import constant from "../../../util/constant";
import {
  useSetUrlParams,
  concatStrings,
  getUrlParameter,
  checkFilterApplied,
  getBackNavigationRoute
} from "../../../util/helpers";
import { exchangeTransactionTableConfigs } from "../../../util/tableConstant";
import FilterIcon from "../../shared/FilterIcon/FilterIcon";
import BackButton from "../../shared/backButton";
import { ScrollPage } from "../../shared/customScrollBar";
import ProfileImage from "../../shared/profileImage";
import SearchBox from "../../shared/searchBox/searchBox";
import TableHeaderRow from "../../shared/tableHeadingRow";
import ExchangeFilterList from "../transactions/exchangeFilterList";

const { ORDER_BY, ROUTES, FILTERS_NAME, BRANDING: { APP_SHORT_NAME } } = constant;

const GamerExchangeList = () => {
  const dispatch = useDispatch();
  const { addQuery } = useAddQuery();
  const { scrollEvent } = useScroll();
  const location = useLocation();
  const { gamerId } = useParams();
  const backScreens = [ "adminGamerDetail" ];
  const {
    gamerExchangeTransaction,
    usersMetaData,
    gamerDetail,
    exchangeFilterList,
  } = useSelector( state => ( {
    gamerExchangeTransaction: state.adminGamer.exchangeTransaction.transaction,
    usersMetaData: state.adminGamer.exchangeTransaction.meta,
    gamerDetail: state.adminGamer.gamerDetail,
    exchangeFilterList: state.adminTransaction.exchangeFilterList,
  } ) );

  const qxPointsRange = exchangeFilterList.transactionRanges;
  const { values, handleChange } = useForm(
    {
      searchTempText: getUrlParameter( "searchKey", null ),
      searchKey: getUrlParameter( "searchKey", null ),
      sortBy: getUrlParameter( "sortBy", "transactionDate" ),
      sortOrder: getUrlParameter( "sortOrder", ORDER_BY.DESC ),
      page: 1,
      minRedeemPoints: getUrlParameter( "minExRedeemPoints" ),
      maxRedeemPoints: getUrlParameter( "maxExRedeemPoints" ),
      initialMinRedeemPoints: getUrlParameter( "minExRedeemPoints" ),
      initialMaxRedeemPoints: getUrlParameter( "maxExRedeemPoints" ),
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      gameNames: getUrlParameter( "gameNames", undefined ),
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      assetNames: getUrlParameter( "assetNames", undefined ),
      startDateTransaction: null,
      endDateTransaction: null,
      initialStartDateTransaction: getUrlParameter( "startDateTransaction" ),
      initialEndDateTransaction: getUrlParameter( "endDateTransaction" ),
    },
    {}
  );
  const sliderMinMaxObj = {
    minExRedeemPoints: qxPointsRange.minQxPoints,
    maxExRedeemPoints: qxPointsRange.maxQxPoints,
  };
  const isFilterApplied = checkFilterApplied( window.location.search, FILTERS_NAME.GAMER_EXCHANGE_LIST, sliderMinMaxObj );

  useEffect( () => {
    return () => dispatch( resetExchangeTransactionFilter() );
  }, [] );

  const exchangeOnScroll = ( e ) => {
    scrollEvent( e, gamerExchangeTransaction, values, handleChange, usersMetaData, "page" );
  };

  useEffect( () => {
    if( gamerId !== "0" ) {
      dispatch( getGamerDetailAction( gamerId ) );
    }
  }, [ gamerId ] );

  const requestParams = Object.assign(
    {
      sortOrder: values.sortOrder,
      perPage: 10,
      page: values.page,
      sortBy: values.sortBy,
      searchKey: values.searchKey,
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      gameNames: concatStrings( values.gameNames, "," ) || undefined,
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      assetNames: concatStrings( values.assetNames, "," ) || undefined,
      minQXPoints: values.minRedeemPoints,
      maxQXPoints: values.maxRedeemPoints,
      startDate: values.initialStartDateTransaction,
      endDate: values.initialEndDateTransaction,
    }
  );

  const handleSorting = ( sortBy, sortingType ) => {
    handleChange( "page", 1 );
    handleChange( "sortBy", sortBy );
    handleChange( "sortOrder", sortingType );
    addQuery( useSetUrlParams( "sortBy", sortBy ), location.state );
    addQuery( useSetUrlParams( "sortOrder", sortingType ), location.state );
  };

  const memoizedHandleClick = useCallback(
    ( sortBy, orderType ) => handleSorting( sortBy, orderType ),
    []
  );

  gamerExchangeEvent( values, handleChange, exchangeFilterList, qxPointsRange, gamerId, requestParams );

  const memoizedTableConfig = useMemo( () => {
    return [ exchangeTransactionTableConfigs ];
  }, [] );

  const [ showFilter, setShowFilter ] = useState( false );
  const showFilterScreen = () => setShowFilter( !showFilter );

  const handleBackNavigation = () => {
    return getBackNavigationRoute( ROUTES.GAMER_SECTION, location, backScreens );
  };

  return ( <>
    <div className="main-right-wrapper">
      <BackButton
        backLink={handleBackNavigation()}
        state={location.state}
      />
      <div className="page-head-wrapper">
        <div className="d-flex align-items-center">
          <ProfileImage profileImage={gamerDetail?.profileImage} />
          <div className="d-flex flex-column">
            <div className="profile-title">
              {gamerDetail.gamerFullName}{" "}
            </div>
          </div>
        </div>

        <div className="page-head-right">
          <SearchBox
            values={values}
            handleChange={handleChange}
            addQuery={addQuery}
            placeholder="Search by Game and Asset Name"
            locationState={location.state}
          />
          <FilterIcon
            showFilter={showFilter}
            showFilterScreen={showFilterScreen}
            isFilterApplied={isFilterApplied} />
        </div>
      </div>
      <div className="page-head-wrapper">
        <h4 className="page-heading">Exchange Transactions</h4>
      </div>
      <div className={`filter-tabs-wrapper mb-0 ${ !showFilter ? "" : "remove-filter" }`}>
        <ExchangeFilterList
          values={values}
          handleChange={handleChange}
          addQuery={addQuery}
          gameNames={exchangeFilterList.gameNames}
          assetNames={exchangeFilterList.assetNames}
          transactionRanges={exchangeFilterList.transactionRanges}
          isGamerNames={false}
          locationState={location.state}
        />
      </div>
      <div className="table-container gamer-exchange-trans-table">
        <div className="table-head">
          <div className="table-row">
            <TableHeaderRow
              configData={memoizedTableConfig[ 0 ]}
              onAscOrder={memoizedHandleClick}
              onDescOrder={memoizedHandleClick}
            />
          </div>
        </div>
        <Scrollbars
          renderThumbVertical={ScrollPage}
          className={`custom-scroll-height small ${ !showFilter ? "" : "full-height" }`}
          onScroll={exchangeOnScroll}
        >
          <div className="table-body no-scrollbar" >
            {Boolean( gamerExchangeTransaction.length ) && gamerExchangeTransaction.map( ( txn, index ) => (
              <div className="table-row" key={index}>
                <div className="td">
                  <span className='big-text'>
                    <img src={txn.iconUrl} alt="games" className='logo' /> {txn.name}
                  </span>
                </div>
                <div className="td"><span className='text-green font-medium'>{txn.qxPoints}</span></div>
                <div className="td">{txn.soldAssetQuantity}</div>
                <div className="td multiline-text">
                  <span className='d-block'>{txn.soldAssetQuantity} {txn.assetName} ({txn.unitPriceQxPoints} { APP_SHORT_NAME } Points each)</span>
                </div>
                <div className="td">{txn.transactionDate}</div>
              </div>
            ) )}
            {!gamerExchangeTransaction.length && (
              <div className='no-record' data-testid='no-record-found'>
                No Exchange Transaction Found
              </div>
            )}
          </div>
        </Scrollbars>
      </div>
    </div>
  </>
  );
};

GamerExchangeList.propTypes = {
  values: PropTypes.object,
  memoizedTableConfig: PropTypes.func,
  setTabData: PropTypes.string,
  tab: PropTypes.func,
};

export default GamerExchangeList;
