import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  setEmptyPartnerInfoAction
} from "../../../store/actions/profileInfo";
import constant from "../../../util/constant";
import { profilePending } from "../../../util/icons";

const { ROUTES } = constant;

const ProfileRejected = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect( () => {
    dispatch( setEmptyPartnerInfoAction( [] ) );
  } );

  const { partnerDetail } = useSelector( state => state.partner );

  return (
    <>
      <div className='main-wrapper'>
        <div className='main-right-wrapper'>
          <div className='profile-pending-box'>
            <div className='left-side'>
              <h2>Application Rejected</h2>
              <p>
                { partnerDetail.reasonForRejection }
              </p>
            </div>
            <div className='right-side'>
              <img src={profilePending} alt='Application Pending' />
            </div>
          </div>
          <div className='heading-with-textarea-outer-wrapper'>
            <div className='heading-with-textarea-wrapper'>
              <div className='btn-wrapper content-with-btn'>
                <p>If you wish to apply again, please click here</p>
                <Button
                  data-testid='apply'
                  variant='primary'
                  onClick={() => history.push( ROUTES.PARTNER_GENERAL_INFO )}
                >
                    Apply Again
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileRejected;
