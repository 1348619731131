import React, { useEffect, useState, useRef } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useSelector, useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import { useForm, usePartnerDraft } from "../../../hooks";
import {
  setGeneralInfoAction,
  uploadLogoAction,
  startLoader
} from "../../../store/actions/profileInfo";
import Enums from "../../../util/Enums";
import constant from "../../../util/constant";
import { formatFileSize, enableOnLoadEvent, disableOnLoadEvent, resizeImageFile } from "../../../util/helpers";
import { logoColor, uploadIcon } from "../../../util/icons";
import partnerProfileConstant from "../../../util/partnerProfileConstant";
import ApplicationFooter from "../../shared/partner/applicationFooter";


const { TAB, API_TYPES } = partnerProfileConstant;
const { ROUTES, FILE_SIZE_UNIT, API_STATUS, BRANDING: { APP_SHORT_NAME } } = constant;
const { NUMERIC_VALUE } = Enums;

const initialState = {
  logo: null,
  legalEntityName: "",
  categoryId: "0",
  joinReasonId: "0",
};
const initialDirty = {
  logo: false,
  legalEntityName: false,
};

const GeneralInfo = () => {
  const {
    profileInfo: { data, apiType, status: apiStatus },
    partnerDraft: { partnerDraftDetail, status: draftApiStatus, apiType: draftApiType },
  } = useSelector( state => state );
  const dispatch = useDispatch();
  const history = useHistory();
  const generalValuesRef = useRef( null );
  const [ isAutoSave, setAutoSave ] = useState( true );
  const [ isMount, setMount ] = useState( true );
  const { values, errors, handleChange } =
    useForm( initialState, initialDirty );

  generalValuesRef.current = values;

  const getRequestValue = name => data[ name ] || "";

  const handleInputChange = ( e ) => {
    const { name, value } = e.target;

    handleChange( name, value );
  };

  const handleNext = () => {
    setAutoSave( false );
    dispatch( setGeneralInfoAction( values ) );
  };

  useEffect( () => {
    return () => dispatch( setGeneralInfoAction( generalValuesRef.current ) );
  }, [] );

  usePartnerDraft( getRequestValue, partnerDraftDetail, apiType, apiStatus, TAB.GENERAL_INFO, !isAutoSave );

  useEffect( () => {
    if(
      ( ( draftApiType === API_TYPES.ADD_PARTNER_DRAFT_DETAIL ) || ( draftApiType === API_TYPES.UPDATE_PARTNER_DRAFT_DETAIL ) ) &&
      ( draftApiStatus === API_STATUS.SUCCESS ) && !isAutoSave
    ) {
      disableOnLoadEvent();
      history.push( ROUTES.PARTNER_CONTACT_INFO );
    }
  }, [ draftApiType, draftApiStatus ] );

  useEffect( () => {
    if( partnerDraftDetail.data?.logo ) {
      handleChange( "logo", partnerDraftDetail.data.logo );
    }
  }, [ partnerDraftDetail.data?.logo ] );

  useEffect( () => {
    if( isMount && partnerDraftDetail.data ) {
      enableOnLoadEvent();
      setMount( false );
      Object.keys( initialState ).forEach( ( key ) => {
        if( partnerDraftDetail.data[ key ] ) {
          handleChange( key, partnerDraftDetail.data[ key ] );
        }
      } );
    }
  }, [ partnerDraftDetail ] );

  const handleUploadImage = ( file ) => {
    const fileData = new FormData();

    fileData.append( "file", file );
    dispatch( uploadLogoAction( fileData ) );
  };

  const handleUploadLogo = async ( e ) => {
    const { files } = e.target;
    const file = files[ NUMERIC_VALUE.ZERO ];
    const { fileSize, size } = formatFileSize( file.size );

    if(
      ( fileSize <= NUMERIC_VALUE.HUNDRED && size === FILE_SIZE_UNIT.KB ) ||
      size === FILE_SIZE_UNIT.BYTES
    ) {
      handleUploadImage( file );
    }
    else if( ( ( fileSize <= NUMERIC_VALUE.TWO ) && ( size === FILE_SIZE_UNIT.MB ) ) || ( size === FILE_SIZE_UNIT.KB ) ) {
      dispatch( startLoader() );

      const resizedFile = await resizeImageFile( file );

      handleUploadImage( resizedFile );
    }
    else {
      toastr.error( "Logo should not be bigger than 2 MB." );
    }
  };

  const clearImage = ( event ) => {
    const { target = {} } = event || {};

    target.value = "";
  };

  const handleOnBlur = () => dispatch( setGeneralInfoAction( values ) );

  return (
    <>
      <div className='main-wrapper'>
        <div className='main-right-wrapper'>
          <div className='page-head-wrapper'>
            <h4 className='page-heading'>General Information</h4>
            <div className='page-head-right'>
              <button className='btn btn-primary' onClick={handleNext}>
                Next
              </button>
            </div>
          </div>
          <div className='page-inner-box profile-steps-box with-fixed-footer'>
            <Form>
              <div className='add-logo-wrapper'>
                <strong>Add Logo</strong>
                <div className='add-logo-wrapper-inner'>
                  <div className='logo-box'>
                    <img
                      src={values.logo ? values.logo : logoColor}
                      alt='logo'
                    />
                  </div>
                  <div className='custom-image-upload'>
                    <Form.Control
                      type='file'
                      id='logoUpload'
                      name='logo'
                      onChange={handleUploadLogo}
                      accept=".svg,.jpg,.jpeg,.png"
                      onClick={clearImage}
                    />
                    <label htmlFor='logoUpload'>
                      <img src={uploadIcon} alt='upload-icon' />
                      Upload
                    </label>
                  </div>
                </div>
                <div className='invalid-feedback d-block'>{errors.logo}</div>
              </div>
              <div className='profile-form'>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Legal Entity Name</Form.Label>
                    <Form.Control
                      type='text'
                      value={values.legalEntityName}
                      name='legalEntityName'
                      onChange={handleInputChange}
                      maxLength={45}
                      data-testid='legalEntityName'
                      onBlur={handleOnBlur}
                    />
                    <div className='invalid-feedback d-block'>
                      {errors.legalEntityName}
                    </div>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Select Category(s)</Form.Label>
                    <Form.Control
                      as='select'
                      name='categoryId'
                      value={values.categoryId}
                      onChange={handleInputChange}
                      onBlur={handleOnBlur}
                    >
                      {constant.CATEGORY.map( category => (
                        <option key={category.id} value={category.id}>{category.label}</option>
                      ) )}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className='last-form-group'>
                    <Form.Label>Reason to join { APP_SHORT_NAME }</Form.Label>
                    <Form.Control
                      as='select'
                      name='joinReasonId'
                      value={values.joinReasonId}
                      onChange={handleInputChange}
                      onBlur={handleOnBlur}
                    >
                      {constant.REASON_QX_JOIN.map( item => (
                        <option key={item.id} value={item.id}>{item.label}</option>
                      ) )}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </div>
            </Form>
            <ApplicationFooter></ApplicationFooter>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralInfo;
