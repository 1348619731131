import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import SettingsIcon from "@material-ui/icons/Settings";
import SportsEsportsIcon from "@material-ui/icons/SportsEsports";
import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { clearSummaryDashboardGraphState } from "../../../store/actions/partnerAnalytics";
import constant from "../../../util/constant";
import { help, analytics } from "../../../util/icons";
import DashboardHeader from "./dashboardHeader";

const { ROUTES } = constant;

const Dashboard = () => {
  const {
    user,
    partner: { partnerDetail },
  } = useSelector( state => state );
  const dispatch = useDispatch();

  const clearPartnerAnalyticsGraphData = ( activeTab ) => {
    // Remove graph data from localStorage in case of different active tabs except analytics tab.
    if( activeTab !== ROUTES.PARTNER_ANALYTICS ) {
      localStorage.removeItem( "graphData" );
      dispatch( clearSummaryDashboardGraphState() );
    }
  };


  return (
    <>
      <div className='main-wrapper flex-wrap'>
        <DashboardHeader
          partnerLogo={partnerDetail.logo}
          unreadNotificationsCount={partnerDetail.unreadNotificationsCount}
        />
        <div className='container-fluid'>
          <div className='dashboard-container'>
            <Row>
              <Col sm={12}>
                <h4 className='page-heading my-5'>Hi, {user.user.name}</h4>
              </Col>
              <Col md={4}>
                <Link onClick={() => clearPartnerAnalyticsGraphData( ROUTES.PARTNER_ANALYTICS )}
                  to={`${ ROUTES.PARTNER_ANALYTICS }?tab=dashboard`}>
                  <div className='card'>
                    <div className='card-image'>
                      <img src={analytics} alt='analytics' />
                    </div>
                    <h4 className='card-title'>Analytics</h4>
                    <div className='blur-image'>
                      <img src={analytics} alt='analytics' />
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={4}>
                <Link to={ROUTES.PARTNER_OFFER_LIST}>
                  <div className='card'>
                    <div className='card-image'>
                      <LocalOfferIcon />
                    </div>
                    <h4 className='card-title'>Offers</h4>
                    <div className='blur-image'>
                      <LocalOfferIcon />
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={4}>
                <Link onClick={() => clearPartnerAnalyticsGraphData( ROUTES.PARTNER_TOURNAMENTS )}
                  to={ROUTES.PARTNER_TOURNAMENTS}>
                  <div className='card'>
                    <div className='card-image'>
                      <span className='icon-tournament icon'></span>
                    </div>
                    <h4 className='card-title'>Tournaments</h4>
                    <div className='blur-image'>
                      <span className='icon-tournament icon'></span>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={4}>
                <Link onClick={() => clearPartnerAnalyticsGraphData( ROUTES.PARTNER_GAMES )}
                  to={ROUTES.PARTNER_GAMES}>
                  <div className='card'>
                    <div className='card-image'>
                      <SportsEsportsIcon />
                    </div>
                    <h4 className='card-title'>Games</h4>
                    <div className='blur-image'>
                      <SportsEsportsIcon />
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={4}>
                <Link onClick={() => clearPartnerAnalyticsGraphData( ROUTES.PARTNER_EVENT_LIST )}
                  to={ROUTES.PARTNER_EVENT_LIST}>
                  <div className='card'>
                    <div className='card-image'>
                      <EmojiEventsIcon />
                    </div>
                    <h4 className='card-title'>Events</h4>
                    <div className='blur-image'>
                      <EmojiEventsIcon />
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={4}>
                <Link onClick={() => clearPartnerAnalyticsGraphData( ROUTES.PARTNER_GAMER_LIST )}
                  to={ROUTES.PARTNER_GAMER_LIST}>
                  <div className='card'>
                    <div className='card-image'>
                      <AssignmentIndIcon />
                    </div>
                    <h4 className='card-title'>Gamers</h4>
                    <div className='blur-image'>
                      <AssignmentIndIcon />
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={4}>
                <Link
                  onClick={() => clearPartnerAnalyticsGraphData( ROUTES.PARTNER_SETTING )}
                  to={ROUTES.PARTNER_SETTING}
                >
                  <div className='card'>
                    <div className='card-image'>
                      <SettingsIcon />
                    </div>
                    <h4 className='card-title'>Settings</h4>
                    <div className='blur-image'>
                      <SettingsIcon />
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={4}>
                <a
                  href="https://acqyrexchange.com/gamers/#faq"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className='card'>
                    <div className='card-image'>
                      <img src={help} alt='help' />
                    </div>
                    <h4 className='card-title'>{"FAQ's"}</h4>
                    <div className='blur-image'>
                      <img src={help} alt='help' />
                    </div>
                  </div>
                </a>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
