import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { getPartnerGamerLinkedGameOffersAction } from "../../../../store/actions/partnerGamer";
import constant from "../../../../util/constant";

const { ROUTES, BRANDING: { APP_SHORT_NAME } } = constant;

const PartnerGamerGameOffers = () => {
  const { gameId: urlParamString, gamerId } = useParams();
  const { 0: gameId } = String( urlParamString ).split( "?" );
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const reqParams = {
    gameId,
    fetchAllRecords: true,
    offerType: "Offer",
    offer: "All",
  };

  const { gamerLinkedGameOffers } = useSelector( state => ( {
    gamerLinkedGameOffers: state.partnerGamer.gamerLinkedGameOffers,
  } ) );

  useEffect( () => {
    if( gameId ) {
      dispatch( getPartnerGamerLinkedGameOffersAction( gamerId, reqParams ) );
    }
  }, [ gameId ] );

  return (
    <>
      <div className="games-grid-wrapper no-scrollbar">
        {Boolean( gamerLinkedGameOffers?.length ) && gamerLinkedGameOffers.map( ( offer, index ) => (
          <div className="col-games-grid-box width-50" key={index}>
            <a
              onClick={() => {
                history.push( {
                  pathname: ( ( ROUTES.PARTNER_LINKED_GAMES_OFFER_DETAIL )
                    .replace( ":offerId", offer.offerId ) ),
                  state: [ ...location.state,
                    ...[ { from: "partnerGamerLinkedGameDetail", path: location.pathname, state: location.search } ],
                  ],
                } );
              } }
              className="offer-detail-box border-no-shadow less-bottom-margin cursor-pointer">
              <div className="offer-detail-box-top">
                <div className="image-box">
                  <img src={offer.gameIcon} alt="games" />
                </div>
                <div className="offer-detail-box-top-body">
                  <div className="left-side">
                    <strong>{offer.offerName}</strong>
                    <p>{offer.startDate ?? "N/A" } - {offer.endDate ?? "N/A"}</p>
                    <p className="mb-0">{offer.offerProgress}</p>
                  </div>
                  <div className="right-side">
                    <img src={offer.partnerLogo} alt="Partner Icon" className="partner-image bottom-medium" />
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mt-4 mb-3">
                <div className="games-tags-list-wrapper my-0">
                  <strong>Game: </strong>
                  {offer.gameName
                    ? (
                      <div className="games-tags-list">
                        <div className="games-tags">
                          <div className="image-box">
                            {offer.gameIcon && <img src={offer.gameIcon} alt="User Icon" />}
                          </div>
                          <span>{offer.gameName}</span>
                        </div>
                      </div>
                    )
                    : <div className="games-desc-text"> <p>No game assigned</p> </div> }
                </div>
                {offer.rewardValue && (
                  <div className="games-desc-text text-right">
                    <strong className='mb-0'>Reward:</strong>
                    <p>{offer.rewardValue} { APP_SHORT_NAME } Points</p>
                  </div>
                )}
              </div>
              <div className="games-desc-text">
                <strong>Description:</strong>
                <p className="text-truncate">{offer.descriptionText}</p>
              </div>
            </a>
          </div>
        ) )}
      </div>
      {!gamerLinkedGameOffers?.length && (
        <div className='no-record' data-testid='no-record-found'>
                  No Offer Found
        </div>
      )}
    </>
  );
};

export default PartnerGamerGameOffers;
