import constant from "../util/constant";

const { ORDER_BY } = constant;

const useToggle = () => {
  const gridViewToggle = ( sortColumns, handleChange, params, setSortColumns,
    addQuery, useSetUrlParams, order, locationState, isQueryParamsUpdated, isSetSortColumns = false ) => {
    let newOrder = ORDER_BY.ASC;

    sortColumns.forEach( ( value ) => {
      value.status = false;

      if( value.col === params ) {
        value.status = true;

        if( isQueryParamsUpdated ) {
          newOrder = order;
        }
        else {
          if( order === ORDER_BY.ASC ) {
            value.order = ORDER_BY.DESC;
          }
          else {
            value.order = ORDER_BY.ASC;
          }

          newOrder = value.order;
        }
      }
    } );
    setSortColumns( sortColumns );
    handleChange( "sortBy", params );
    handleChange( "sortOrder", newOrder );
    addQuery( useSetUrlParams( isSetSortColumns ? "sortColumn" : "sortBy", params ), locationState );
    addQuery( useSetUrlParams( "sortOrder", newOrder ), locationState );
  };

  return {
    gridViewToggle,
  };
};

export default useToggle;
