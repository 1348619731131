import { toastr } from "react-redux-toastr";
import { DraftToOffer } from "../../entities/draftToOffer";
import { OfferTemplateDetail } from "../../entities/offerTemplateDetail";
import { PartnerTemplateOfferDetails } from "../../entities/partnerTemplateOfferDetails";
import {
  getOfferTemplates,
  addPartnerNewOfferTemplate,
  getPartnerOfferTemplateDetail,
  addPartnerOfferFromScratch,
  getPartnerOffer,
  updatePartnerOfferTemplate,
  deletePartnerOfferTemplate
} from "../../services/partnerOfferServices";
import constant from "../../util/constant";
import offerConstant from "../../util/offerConstant";
import {
  START_LOADER,
  STOP_LOADER,
  PARTNER_OFFER_API_REQUEST,
  PARTNER_OFFER_API_END,
  GET_OFFER_TEMPLATES_SUCCESS,
  GET_OFFER_TEMPLATES_FAILURE,
  ADD_PARTNER_NEW_OFFER_TEMPLATE_SUCCESS,
  ADD_PARTNER_NEW_OFFER_TEMPLATE_FAILURE,
  GET_PARTNER_OFFER_TEMPLATE_DETAIL_SUCCESS,
  GET_PARTNER_OFFER_TEMPLATE_DETAIL_FAILURE,
  ADD_PARTNER_OFFER_FROM_SCRATCH_FAILURE,
  ADD_PARTNER_OFFER_FROM_SCRATCH_SUCCESS,
  GET_PARTNER_OFFER_SUCCESS,
  GET_PARTNER_OFFER_FAILURE,
  UPDATE_PARTNER_OFFER_TEMPLATE_FAILURE,
  UPDATE_PARTNER_OFFER_TEMPLATE_SUCCESS,
  DELETE_PARTNER_OFFER_SUCCESS,
  DELETE_PARTNER_OFFER_FAILURE,
  CLEAR_OFFER_DETAIL,
  CLONE_OFFER_TEMPLATE_SUCCESS
} from "../actionTypes";

const { OFFER_API_TYPES, TYPE_TO_BE_EDITED } = offerConstant;
const { ERROR_MESSAGES } = constant;

export const getPartnerOfferTemplatesAction = () => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_OFFER_API_REQUEST,
      payload: OFFER_API_TYPES.GET_PARTNER_OFFER_TEMPLATES,
    } );

    const response = await getOfferTemplates();

    dispatch( {
      type: GET_OFFER_TEMPLATES_SUCCESS,
      payload: response.data,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GET_OFFER_TEMPLATES_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: PARTNER_OFFER_API_END,
    } );
  }
};

export const addNewOfferTemplateAction = ( params, isClone ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_OFFER_API_REQUEST,
      payload: OFFER_API_TYPES.ADD_PARTNER_NEW_OFFER_TEMPLATE,
    } );

    const response = await addPartnerNewOfferTemplate( params );

    dispatch( {
      type: isClone ? CLONE_OFFER_TEMPLATE_SUCCESS : ADD_PARTNER_NEW_OFFER_TEMPLATE_SUCCESS,
      payload: response.data,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: ADD_PARTNER_NEW_OFFER_TEMPLATE_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: PARTNER_OFFER_API_END,
    } );
  }
};

export const getPartnerOfferTemplateDetailAction = ( param, isEditTournament = false ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_OFFER_API_REQUEST,
      payload: OFFER_API_TYPES.GET_PARTNER_OFFER_TEMPLATE_DETAIL,
    } );

    const response = await getPartnerOfferTemplateDetail( param );

    dispatch( {
      type: GET_PARTNER_OFFER_TEMPLATE_DETAIL_SUCCESS,
      payload: new OfferTemplateDetail( { ...response.data, isEditTournament } ),
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GET_PARTNER_OFFER_TEMPLATE_DETAIL_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: PARTNER_OFFER_API_END,
    } );
  }
};

export const addOfferTemplateFromScratchAction = params => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_OFFER_API_REQUEST,
      payload: OFFER_API_TYPES.ADD_PARTNER_OFFER_FROM_SCRATCH,
    } );

    const response = await addPartnerOfferFromScratch( params );

    dispatch( {
      type: ADD_PARTNER_OFFER_FROM_SCRATCH_SUCCESS,
      payload: response.data,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: ADD_PARTNER_OFFER_FROM_SCRATCH_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: PARTNER_OFFER_API_END,
    } );
  }
};

export const getPartnerOfferAction = params => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_OFFER_API_REQUEST,
      payload: OFFER_API_TYPES.GET_PARTNER_OFFER,
    } );

    const response = await getPartnerOffer( params );
    const dataList = response.data.offerList.map( ( item ) => {
      if( ( item.offer_type === TYPE_TO_BE_EDITED.DRAFT ) ||
      ( item.offer_type === TYPE_TO_BE_EDITED.TOURNAMENT_DRAFT ) ) {
        return new DraftToOffer( item );
      }

      // set offerType i.e either offer or tournament in case of offerType params is set
      item.offerType = params?.offerType;

      return new PartnerTemplateOfferDetails( item );
    } )
      .sort( ( a, b ) => a.offerName.localeCompare( b.offerName ) ) ?? [];

    dispatch( {
      type: GET_PARTNER_OFFER_SUCCESS,
      payload: dataList,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GET_PARTNER_OFFER_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: PARTNER_OFFER_API_END,
    } );
  }
};

export const updatePartnerOfferTemplateAction = params => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_OFFER_API_REQUEST,
      payload: OFFER_API_TYPES.UPDATE_PARTNER_OFFER_TEMPLATE,
    } );

    const response = await updatePartnerOfferTemplate( params );

    dispatch( {
      type: UPDATE_PARTNER_OFFER_TEMPLATE_SUCCESS,
      payload: response.data,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: UPDATE_PARTNER_OFFER_TEMPLATE_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: PARTNER_OFFER_API_END,
    } );
  }
};

export const deletePartnerOfferAction = params => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_OFFER_API_REQUEST,
      payload: OFFER_API_TYPES.DELETE_PARTNER_OFFER,
    } );

    const response = await deletePartnerOfferTemplate( params );

    dispatch( {
      type: DELETE_PARTNER_OFFER_SUCCESS,
      payload: { ...response.data, offerId: params.offerId },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: DELETE_PARTNER_OFFER_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: PARTNER_OFFER_API_END,
    } );
  }
};

export const clearOfferDetail = () => async dispatch => dispatch( { type: CLEAR_OFFER_DETAIL } );
