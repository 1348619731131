import React from "react";

const ApplicationFooter = () => (
  <div className='fixed-footer-wrapper text-left border-0'>
    <span className="opt-text">
      {"All fields are required, except where noted as 'Optional'."}
    </span>
  </div>
);

export default ApplicationFooter;
