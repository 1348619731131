import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { ConvertOfferDetailToDraftRequest } from "../../../entities/convertOfferDetailToDraftRequest";
import { OfferTemplateRequest } from "../../../entities/newOfferTemplateRequest";
import { useModal } from "../../../hooks";
import {
  getPartnerOfferTemplateDetailAction,
  addNewOfferTemplateAction,
  getPartnerOfferAction
} from "../../../store/actions/partnerOffer";
import { addNewPartnerOfferDraftAction, getPartnerOfferDraftDetailAction } from "../../../store/actions/partnerOfferDraft";
import Enums from "../../../util/Enums";
import adminConstant from "../../../util/adminConstant";
import constant from "../../../util/constant";
import { getBackNavigationRoute, getUniqueName } from "../../../util/helpers";
import offerConstant from "../../../util/offerConstant";
import BackButton from "../../shared/backButton";
import { OfferDetail } from "../../shared/offers";
import ModalPopup from "../modal";

const {
  OFFER_DETAIL_STATUS,
  DRAFT_ENTITY_NAME,
  OFFER_API_TYPES,
  OFFER_TYPE_STATUS,
  REQUEST_TYPE,
} = offerConstant;
const { API_STATUS, ROUTES } = constant;
const { MODAL_TYPE_DETAIL } = adminConstant;
const { PER_PAGE_RECORDS_LIMIT } = Enums;

const PartnerOfferDetail = ( ) => {
  const { gamerId } = useParams();
  const { offerId: urlParamString } = useParams();
  const { 0: offerId } = String( urlParamString ).split( "?" );
  const paramsQuery = new URLSearchParams( window.location.search );
  const tab = paramsQuery.get( "tab" );
  const fromScreen = paramsQuery.get( "from" );
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const backScreens = [
    "partnerOffers",
    "partnerTournaments",
    "partnerClonedOffer",
    "partnerGamerLinkedGameDetail",
    "gamerLinkedOffers",
    "gamerLinkedTournaments",
    "analyticsGameDetail",
    "partnerClonedTournament",
  ];
  const commonScreens = [ "partnerOffers", "partnerTournaments" ];
  const { offerDetails, draftApiType, draftStatus, cloneDraft, apiType, status,
    cloneOfferDetail, configuration, offers, partnerId,
  } = useSelector( state => ( {
    offerDetails: state.partnerOffer.offerDetail,
    draftApiType: state.partnerOfferDraft.apiType,
    draftStatus: state.partnerOfferDraft.status,
    cloneDraft: state.partnerOfferDraft.cloneDraft,
    apiType: state.partnerOffer.apiType,
    status: state.partnerOffer.status,
    cloneOfferDetail: state.partnerOffer.cloneOfferDetail,
    configuration: state.partner.partnerDetail?.configuration || {},
    offers: state.partnerOffer.offers,
    partnerId: state.partner.partnerDetail.partnerId,
  } ) );
  const [ isTournament, setTournament ] = useState( false );
  const [ errorMessage, setErrorMessage ] = useState( "" );
  const [ cloneName, setCloneName ] = useState( "" );
  const [ activeTab, setTab ] = useState( "" );
  const { isOpened, closeModal, openModal, modalType, setModalType } = useModal();
  const offerType = paramsQuery.get( "status" );
  const isDraft = offerType === OFFER_TYPE_STATUS.DRAFT;
  const isOfferTemplate = !offerDetails.offerSchedule;
  const [ , , entityType ] = location.pathname.split( "/" );

  useEffect( () => {
    setTab( tab );
  }, [ tab ] );

  useEffect( () => {
    dispatch( getPartnerOfferAction( {
      perPage: PER_PAGE_RECORDS_LIMIT.MAX,
      partnerId: partnerId,
      currentTimeZone: new Date().toUTCString(),
      offerType: "offer",
    } ) );
  }, [] );

  const handleChangeTab = ( selectedTab ) => {
    if( entityType === "tournamentDetail" ) {
      history.replace( {
        pathname: `${
          ROUTES.PARTNER_TOURNAMENT_DETAIL.replace( ":offerId", offerId )
        }?tab=${ selectedTab }&from=${ fromScreen }`,
        state: location.state,
      } );
    }
    else {
      history.replace( {
        pathname: `${
          ROUTES.PARTNER_GAMER_TOURNAMENT_DETAILS
            .replace( ":offerId", offerId )
            .replace( ":gamerId", gamerId )
        }?tab=${ selectedTab }`,
        state: location.state,
      } );
    }
  };

  useEffect( () => {
    if( offerId ) {
      if( offerType === OFFER_TYPE_STATUS.DRAFT ) {
        dispatch( getPartnerOfferDraftDetailAction( offerId, ( entityType === "tournamentDetail" ), true ) );
      }
      else {
        dispatch( getPartnerOfferTemplateDetailAction( offerId ) );
      }
    }
  }, [ offerId ] );

  const handleConvertToDraft = () => {
    const requestObj = new ConvertOfferDetailToDraftRequest( { ...offerDetails, requestType: REQUEST_TYPE.OFFER } );

    dispatch( addNewPartnerOfferDraftAction( requestObj, DRAFT_ENTITY_NAME.PARTNER_OFFER_DRAFT, true ) );
  };

  useEffect( () => {
    if( status === API_STATUS.SUCCESS && ( apiType === OFFER_API_TYPES.ADD_PARTNER_NEW_OFFER_TEMPLATE ) ) {
      setTimeout( () => {
        history.push( ROUTES.PARTNER_CREATE_AND_EDIT_OFFER.replace( ":offerId", cloneOfferDetail.offer_id ) );
      }, 500 );
    }

    if( draftStatus === API_STATUS.SUCCESS && draftApiType === OFFER_API_TYPES.ADD_ADMIN_NEW_DRAFT_OFFER ) {
      toastr.success( "Your offer successfully converted into draft." );
      history.goBack();
    }
    else if( ( draftStatus === API_STATUS.SUCCESS ) && ( draftApiType === OFFER_API_TYPES.ADD_PARTNER_CLONE_DRAFT_OFFER ) ) {
      setTimeout( () => {
        history.push( `${ `${ ROUTES.PARTNER_CREATE_AND_EDIT_OFFER.replace( ":offerId", cloneDraft.entity_id ) }?status=${ OFFER_TYPE_STATUS.DRAFT }` }` );
      }, 500 );
    }
  }, [ draftApiType, draftStatus, status, apiType ] );

  const handleInputChange = ( e ) => {
    setCloneName( e.target.value );

    if( errorMessage ) {
      setErrorMessage( "" );
    }
  };

  const onCloseModal = () => {
    closeModal();
    setErrorMessage();
  };

  const handleOpenModal = ( mType ) => {
    setModalType( mType );
    openModal();
  };

  const handleConfirm = () => {
    if( cloneName.trim() ) {
      const [ isLoader, isClone ] = Array( 2 ).fill( true );

      if( isDraft ) {
        const requestObj = { ...offerDetails.draftContent, name: cloneName };

        dispatch( addNewPartnerOfferDraftAction( requestObj, offerDetails.entityName, isLoader, isClone ) );
      }
      else {
        const { partnerCostUsdPerQxPoint } = configuration;
        const requestObj = new OfferTemplateRequest( { ...offerDetails, offerName: cloneName, offerId: "", partnerCostUsdPerQxPoint, requestType: REQUEST_TYPE.OFFER_TEMPLATE, isClone: true } );

        dispatch( addNewOfferTemplateAction( requestObj, isClone ) );
      }

      closeModal();
    }
    else {
      setErrorMessage( isDraft ? "Please enter draft name." : "Please enter template name." );
    }
  };

  const handleClone = () => {
    const offerList = offers?.map( item => item?.offerName ) || [];

    if( isDraft ) {
      setCloneName( getUniqueName( `Clone - ${ offerDetails.offerName }`, offerList ) );
      handleOpenModal( MODAL_TYPE_DETAIL.CLONE_DRAFT.type );
    }
    else if( isOfferTemplate ) {
      setCloneName( getUniqueName( `Clone - ${ offerDetails.offerName }`, offerList ) );
      handleOpenModal( MODAL_TYPE_DETAIL.CLONE_TEMPLATE.type );
    }
    else {
      history.push( {
        pathname: `${ ROUTES.PARTNER_CREATE_AND_EDIT_OFFER
          .replace( ":offerId", offerDetails.offerId ) }?clone=true`,
        state: [ ...location.state,
          ...[ { from: "partnerOfferDetail", path: location.pathname, state: location.search } ],
        ],
      } );
    }
  };

  useEffect( () => {
    if( ( entityType === "tournamentDetail" ) ||
        ( entityType === "gamer" ) ) {
      setTournament( true );
    }
  }, [ entityType ] );

  const handleBackNavigation = () => {
    return getBackNavigationRoute( ROUTES.PARTNER_OFFER_LIST, location, backScreens );
  };

  return (
    <div className="main-wrapper">
      <div className="main-right-wrapper">
        <BackButton
          backLink={handleBackNavigation()}
          state={commonScreens.includes( fromScreen ) ? "" : location.state}
        />
        <div className="page-head-wrapper">
          <h4 className="page-heading">{offerDetails.offerName}</h4>
          { ( entityType !== "linkedGames" ) && ( entityType !== "linkedOffers" ) && ( !isTournament && (
            <div className='page-head-right'>
              <Button
                className='btn btn-primary'
                onClick={handleClone}
              >Clone
              </Button>
              {offerDetails.approvalStatus === OFFER_DETAIL_STATUS.IN_REVIEW && (
                <Button
                  className='btn btn-primary btn-lg ml-3'
                  onClick={handleConvertToDraft}
                >Convert To Draft
                </Button>
              )}
            </div> )
          ) }
        </div>
        <OfferDetail
          offerDetail={offerDetails}
          configuration={configuration}
          partnerCostUsdPerQxPoint={offerDetails.partnerCostUsdPerQxPoint}
          isTournament={isTournament}
          onChangeTab={handleChangeTab}
          activeTab={activeTab}
          offerId={offerId}
          gamerId={gamerId}
          entityType={entityType}
          offerType={offerType}
        />
        <ModalPopup
          isOpened={isOpened}
          onClose={onCloseModal}
          type={modalType}
          onConfirm={handleConfirm}
          errorMessage={errorMessage}
          onChangeInput={handleInputChange}
          cloneName={cloneName}
          offerName={offerDetails.offerName}
        />
      </div>
    </div>
  );
};

export default PartnerOfferDetail;
