import { debounce } from "lodash";
import PropTypes from "prop-types";
import React, { useMemo, useCallback, useState } from "react";

import constant from "../../../util/constant";
import { useSetUrlParams, checkFilterApplied } from "../../../util/helpers";
import { adminPartnerLinkedGamersTableConfigs } from "../../../util/tableConstant";
import SearchHeader from "../../admin/games/searchHeader";
import LinkedGamers from "../../shared/gamer/linkedGamers";

const { FILTERS_NAME } = constant;

const Gamers = ( {
  id,
  action,
  values,
  addQuery,
  gamers,
  scrollEvent,
  handleChange,
  previousState,
  gamersFilterList,
  maxLinkedGames,
  gamersMetaData,
  maxAcqyrCashBalance,
  handleFilterChange,
  clearSelectedFilter,
} ) => {
  const sliderMinMaxObj = {
    minLinkedGames: maxLinkedGames.min, maxLinkedGames: maxLinkedGames.max,
    minAcqyrCashBalance: maxAcqyrCashBalance.min, maxAcqyrCashBalance: maxAcqyrCashBalance.max,
  };

  const isFilterApplied = checkFilterApplied( window.location.search, FILTERS_NAME.PARTNER_LINKED_GAMERS, sliderMinMaxObj );
  const multiSelectedItems = useMemo( () => [ values.fullNames ] );
  const memoizedTableConfig = useMemo( () => adminPartnerLinkedGamersTableConfigs, [] );

  const onScroll = ( e ) => {
    scrollEvent( e, gamers, values, handleChange, gamersMetaData, "page" );
  };

  const handleSorting = ( sortBy, sortingType ) => {
    handleChange( "page", 1 );
    handleChange( "sortBy", sortBy );
    handleChange( "sortOrder", sortingType );
    addQuery( useSetUrlParams( "sortBy", sortBy ), previousState );
    addQuery( useSetUrlParams( "sortOrder", sortingType ), previousState );
  };

  const memoizedHandleClick = useCallback(
    ( sortBy, orderType ) => handleSorting( sortBy, orderType ), []
  );

  const handleSelect = ( filterName, obj ) => {
    handleFilterChange( filterName, obj, handleChange, addQuery, previousState );
  };

  const onSelectFilterItems = useCallback(
    [ obj => handleSelect( "fullNames", obj ) ], []
  );

  const clearAllSelectedOptions = useCallback(
    params => clearSelectedFilter( params, addQuery, handleChange, previousState ), []
  );

  const closeModal = () => handleChange( "showModal", false );

  const viewAllGames = ( linkedGames ) => {
    handleChange( "linkedGames", linkedGames );
    handleChange( "showModal", true );
  };

  const handleLinkedGame = useCallback(
    debounce( ( newValue ) => {
      handleChange( "minLinkedGames", newValue[ 0 ] );
      handleChange( "maxLinkedGames", newValue[ 1 ] );
    }, 1000 ),
    []
  );

  const debouncedGameChangeHandler = ( e, newValue ) => {
    handleChange( "page", 1 );
    addQuery( useSetUrlParams( "minLinkedGames", newValue[ 0 ] ), previousState );
    addQuery( useSetUrlParams( "maxLinkedGames", newValue[ 1 ] ), previousState );
    handleChange( "dummyMinLinkedGames", newValue[ 0 ] );
    handleChange( "dummyMaxLinkedGames", newValue[ 1 ] );
    handleLinkedGame( newValue );
  };

  const handleCashBalance = useCallback(
    debounce( ( newValue ) => {
      handleChange( "minAcqyrCashBalance", newValue[ 0 ] );
      handleChange( "maxAcqyrCashBalance", newValue[ 1 ] );
    }, 1000 ),
    []
  );

  const debouncedCashChangeHandler = ( e, newValue ) => {
    handleChange( "page", 1 );
    addQuery( useSetUrlParams( "minAcqyrCashBalance", newValue[ 0 ] ), previousState );
    addQuery( useSetUrlParams( "maxAcqyrCashBalance", newValue[ 1 ] ), previousState );
    handleChange( "dummyMinAcqyrCashBalance", newValue[ 0 ] );
    handleChange( "dummyMaxAcqyrCashBalance", newValue[ 1 ] );
    handleCashBalance( newValue );
  };

  const debouncedSave = useCallback(
    debounce( nextValue => handleChange( "searchKey", nextValue ), 1000 ),
    []
  );

  const handleSearch = ( e ) => {
    const { value: nextValue } = e.target;

    handleChange( "page", 1 );
    addQuery( useSetUrlParams( "searchKey", nextValue ), previousState );
    handleChange( "searchDummyText", nextValue );
    debouncedSave( nextValue );
  };

  const [ showFilter, setShowFilter ] = useState( false );
  const showFilterScreen = () => setShowFilter( !showFilter );

  return (
    <>
      <SearchHeader
        values={values}
        searchHandle={handleSearch}
        className='w-300 border-1'
        showFilter={showFilter}
        showFilterScreen={showFilterScreen}
        isFilterApplied={isFilterApplied}
      />
      <LinkedGamers
        values={values}
        gamersFilterList={gamersFilterList}
        handleSelectChange={onSelectFilterItems}
        multiSelectedItems={multiSelectedItems}
        handleClearMultiSelect={clearAllSelectedOptions}
        debouncedGameChangeHandler={debouncedGameChangeHandler}
        handleChange={handleChange}
        min={0}
        debouncedCashChangeHandler={debouncedCashChangeHandler}
        memoizedTableConfig={memoizedTableConfig}
        memoizedHandleClick={memoizedHandleClick}
        gamers={gamers}
        onScroll={onScroll}
        viewAllGames={viewAllGames}
        closeModal={closeModal}
        linkedGamer={true}
        maxAcqyrCashBalance={maxAcqyrCashBalance.max}
        minAcqyrCashBalance={maxAcqyrCashBalance.min}
        maxLinkedGames={maxLinkedGames.max}
        minLinkedGames={maxLinkedGames.min}
        showFilter={showFilter}
        id={id}
        action={action}
      />
    </>
  );
};

Gamers.propTypes = {
  id: PropTypes.string,
  action: PropTypes.string,
  values: PropTypes.object,
  addQuery: PropTypes.func,
  scrollEvent: PropTypes.func,
  gamers: PropTypes.array,
  handleChange: PropTypes.func,
  previousState: PropTypes.string,
  gamersFilterList: PropTypes.array,
  maxLinkedGames: PropTypes.object,
  gamersMetaData: PropTypes.object,
  maxAcqyrCashBalance: PropTypes.object,
  handleFilterChange: PropTypes.func,
  clearSelectedFilter: PropTypes.func,
};

export default Gamers;
