import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { useAddQuery, useForm, useFilter, getUrlData } from "../../../../hooks";
import { getExchangeTransactionAction, getPartnerGamerDetailAction } from "../../../../store/actions/partnerGamer";
import constant from "../../../../util/constant";
import { useSetUrlParams, checkFilterApplied } from "../../../../util/helpers";
import partnerGamerConstant from "../../../../util/partnerGamerConstant";
import { partnerGamerExchangeTransactionTableConfigs } from "../../../../util/tableConstant";
import ExchangeTransactionFilters from "./exchangeTransactionFilters";
import ExchangeTransactionHeader from "./exchangeTransactionHeader";
import ExchangeTransactionList from "./exchangeTransactionList";

const { API_STATUS, FILTERS_NAME } = constant;
const { API_TYPES } = partnerGamerConstant;

const PartnerGamerExchangeTransaction = () => {
  const { addQuery } = useAddQuery();
  const dispatch = useDispatch();
  const { gamerId } = useParams();
  const { setPureData, filteredData, filterData } = useFilter();
  const location = useLocation();
  const {
    apiType,
    gamerDetail,
    gamerExchangeTransaction,
    status,
  } = useSelector( state => ( {
    gamerExchangeTransaction: state.partnerGamer.exchangeTransaction,
    gamerDetail: state.partnerGamer.gamerDetail,
    status: state.partnerGamer.status,
    apiType: state.partnerGamer.apiType,
  } ) );
  const { transaction, gameNames, assetNames, qxPointsRange } = gamerExchangeTransaction;
  const sliderMinMaxObj = {
    minQxPoint: qxPointsRange.min,
    maxQxPoint: qxPointsRange.max,
  };
  const isFilterApplied = checkFilterApplied( window.location.search, FILTERS_NAME.PARTNER_GAMER_EXCHANGE_LIST, sliderMinMaxObj );

  useEffect( () => {
    dispatch( getExchangeTransactionAction( gamerId ) );
  }, [] );

  const { values, handleChange } = useForm(
    {
      searchKey: "",
      sortBy: "",
      sortOrder: "",
      assetNames: [],
      gameNames: [],
      minQxPoint: 0,
      maxQxPoint: 0,
      startDate: null,
      endDate: null,
      searchCol: [ "name", "assetName" ],
      compareKeyArray: [ "QXPoints", "name" ],
    },
    {}
  );
  const requestParams = Object.assign(
    {
      isSingleDate: true,
      sortOrder: values.sortOrder,
      searchCol: values.searchCol,
      assetNames: values.assetNames?.map( value => value.value ) || [],
      gameNames: values.gameNames?.map( value => value.value ) || [],
      searchKey: values.searchKey,
      sortBy: values.sortBy,
      minQxPoint: values.minQxPoint,
      maxQxPoint: values.maxQxPoint,
      startDate: values.startDate,
      endDate: values.endDate,
      compareKeyArray: values.compareKeyArray,
    }
  );

  const handleSorting = ( sortBy, sortingType ) => {
    handleChange( "sortBy", sortBy );
    handleChange( "sortOrder", sortingType );
    addQuery( useSetUrlParams( "sortBy", sortBy ) );
    addQuery( useSetUrlParams( "sortOrder", sortingType ) );
  };

  const memoizedTableConfig = useMemo( () => partnerGamerExchangeTransactionTableConfigs, [] );
  const memoizedHandleClick = useCallback( ( sortBy, orderType ) => handleSorting( sortBy, orderType ), [] );

  useEffect( () => {
    if(
      ( status === API_STATUS.SUCCESS ) &&
      ( apiType === API_TYPES.GET_EXCHANGE_TRANSACTION_LISTS )
    ) {
      dispatch( getPartnerGamerDetailAction( gamerId ) );
      handleChange( "minQxPoint", qxPointsRange.min );
      handleChange( "maxQxPoint", qxPointsRange.max );
      setPureData( transaction );
      getUrlData( handleChange );
      filterData( requestParams );
    }
  }, [ status, apiType ] );
  useEffect( () => {
    filterData( requestParams );
  }, [
    values.sortOrder,
    values.sortBy,
    values.searchKey,
    values.assetNames,
    values.gameNames,
    values.minQxPoint,
    values.maxQxPoint,
    values.startDate,
    values.endDate,
  ] );

  const [ showFilter, setShowFilter ] = useState( false );

  return (
    <div className="main-wrapper">
      <div className="main-right-wrapper">
        <ExchangeTransactionHeader
          gamerDetail={gamerDetail}
          gamerId={gamerId}
          handleChange={handleChange}
          isFilterApplied={isFilterApplied}
          setShowFilter={setShowFilter}
          showFilter={showFilter}
          values={values}
          location={location}
        />
        <ExchangeTransactionFilters
          assetNames={assetNames}
          filteredData={filteredData}
          handleChange={handleChange}
          gameNames={gameNames}
          addQuery={addQuery}
          qxPointRange={qxPointsRange}
          showFilter={showFilter}
          values={values}
          location={location}
        />
        <ExchangeTransactionList
          exchangeTransactionList={filteredData}
          showFilter={showFilter}
          memoizedTableConfig={memoizedTableConfig}
          memoizedHandleClick={memoizedHandleClick}
        />
      </div>
    </div>
  );
};

export default PartnerGamerExchangeTransaction;
