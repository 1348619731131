import constant from "../../util/constant";
import {
  EMAIL_VERIFY_API_REQUEST,
  EMAIL_VERIFY_API_END,
  EMAIL_VERIFY_API_SUCCESS,
  EMAIL_VERIFY_API_FAILURE
} from "../actionTypes";

const { API_STATUS } = constant;

const DEFAULT_STATE = {
  loading: false,
  message: "",
  status: "",
  apiType: "",
  emailData: {},
};

const emailVerify = ( state = DEFAULT_STATE, action = "" ) => {
  switch ( action.type ) {
    case EMAIL_VERIFY_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
      };
    case EMAIL_VERIFY_API_SUCCESS:
      return {
        ...state,
        emailData: action.payload,
        status: API_STATUS.SUCCESS,
      };
    case EMAIL_VERIFY_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case EMAIL_VERIFY_API_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        emailData: {},
      };
    default:
      return state;
  }
};

export default emailVerify;
