import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getPartnerTotalGamerAction } from "../../../../store/actions/partnerGame";
import analyticsConstant from "../../../../util/analyticsConstant";
import constant from "../../../../util/constant";
import { formatNumberAsPerLocale } from "../../../../util/helpers";
import Graph from "./graph";
import GraphFilter from "./graphFilter";

const { ANALYTICS_TAB } = analyticsConstant;
const { API_STATUS, GRAPH_TYPE } = constant;

const Games = ( { redirectToAll } ) => {
  const { analyticsGraphData, totalGames, initialDate, status } = useSelector( state => ( {
    analyticsGraphData: state.partnerGame.analyticsGraphData,
    totalGames: state.partnerGame.totalGames,
    initialDate: state.partnerGame.initialDate,
    status: state.partnerGame.status,
  } ) );

  const [ startDate, setStartDate ] = useState();
  const [ endDate, setEndDate ] = useState();

  useEffect( () => {
    if( ( !isEmpty( analyticsGraphData ) ) && ( status === API_STATUS.SUCCESS ) ) {
      setStartDate( analyticsGraphData.data[ 0 ]?.dataPoints[ 0 ].x );
      setEndDate( analyticsGraphData.data[ 0 ]?.dataPoints[ analyticsGraphData.data[ 0 ]?.dataPoints.length - 1 ].x );
    }
  }, [ status ] );

  return (
    <>
      <Col sm={6}>
        <div className="tag-box">
          <div className="tag-box-inner">
            <div className="tag-content">
              <div className="tag-title">{formatNumberAsPerLocale( totalGames )}</div>
              <div className="tag-text">Total No. of Games</div>
              <span onClick={() => redirectToAll( ANALYTICS_TAB.GAMES )} title='Show All' className='show-link'>Show All</span>
            </div>
            <GraphFilter
              startDate={startDate }
              endDate={endDate }
              initialDate={initialDate}
              dispatchAction={getPartnerTotalGamerAction}
              graphType={GRAPH_TYPE.GAMES_LINE_GRAPH}
            />
          </div>
          <Graph options={analyticsGraphData} />
        </div>
      </Col>
    </>
  );
};

Games.propTypes = {
  redirectToAll: PropTypes.func,
};

export default Games;
