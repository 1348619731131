import PropTypes from "prop-types";
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import FilterIcon from "../FilterIcon/FilterIcon";

const SearchHeader = ( {
  headerTitle,
  searchHandle,
  searchKey,
  showFilter,
  showFilterScreen,
  isFilterApplied,
  dropdownItems,
  searchTitle,
  searchPlaceholder,
} ) => (
  <div className="page-head-wrapper">
    <h4 className="page-heading">{headerTitle}</h4>
    <div className="page-head-right">
      <Form.Group className="custom-search">
        <span className="icon-search"></span>
        <Form.Control type="text"
          placeholder={searchPlaceholder}
          onChange={searchHandle}
          name='searchKey'
          value={searchKey}
          title={searchTitle}
          data-testid='partnerSearchKey'
        />
      </Form.Group>
      <Dropdown className="custom-dropdown filter-dropdown">
        <Dropdown.Toggle variant="primary" className="small-btn">
          <span className="icon-circle-plus-green icon"></span>
          Add New
        </Dropdown.Toggle>
        <Dropdown.Menu alignRight={true}>
          {dropdownItems()}
        </Dropdown.Menu>
      </Dropdown>
      <FilterIcon
        showFilter={showFilter}
        showFilterScreen={showFilterScreen}
        isFilterApplied={isFilterApplied} />
    </div>
  </div>
);

SearchHeader.propTypes = {
  searchHandle: PropTypes.func,
  searchKey: PropTypes.string,
  showFilter: PropTypes.bool,
  showFilterScreen: PropTypes.func,
  isFilterApplied: PropTypes.bool,
  dropdownItems: PropTypes.func,
  headerTitle: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  searchTitle: PropTypes.string,
};

export default SearchHeader;
