import constant from "../../util/constant";
import offerConstant from "../../util/offerConstant";
import {
  ADMIN_OFFER_API_END,
  ADMIN_OFFER_API_REQUEST,
  GET_ADMIN_OFFERS_SUCCESS,
  GET_ADMIN_OFFERS_FAILURE,
  GET_ADMIN_OFFER_DETAILS_SUCCESS,
  GET_ADMIN_OFFER_DETAILS_FAILURE,
  ADD_ADMIN_NEW_OFFER_SUCCESS,
  ADD_ADMIN_NEW_OFFER_FAILURE,
  REJECT_OFFER_REQUEST_FAILURE,
  REJECT_OFFER_REQUEST_SUCCESS,
  APPROVE_OFFER_REQUEST_SUCCESS,
  APPROVE_OFFER_REQUEST_FAILURE,
  ENABLE_DISABLE_PARTNER_OFFER_SUCCESS,
  ENABLE_DISABLE_PARTNER_OFFER_FAILURE,
  UPDATE_ADMIN_OFFER_SUCCESS,
  UPDATE_ADMIN_OFFER_FAILURE,
  UPDATE_PARTNER_OFFER_BY_ADMIN_SUCCESS,
  UPDATE_PARTNER_OFFER_BY_ADMIN_FAILURE,
  DELETE_ADMIN_OFFER_SUCCESS,
  DELETE_ADMIN_OFFER_FAILURE
} from "../actionTypes";

const { API_STATUS } = constant;
const { OFFER_API_TYPES } = offerConstant;

const DEFAULT_STATE = {
  loading: false,
  message: "",
  status: "",
  apiType: "",
  adminOffers: { offers: [], meta: "" },
  offerDetails: {},
};

// TODO: refactor to avoid complexity
// eslint-disable-next-line complexity
const adminOffer = ( state = DEFAULT_STATE, action = "" ) => {
  switch ( action.type ) {
    case ADMIN_OFFER_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
        offerDetails: action.payload === OFFER_API_TYPES.GET_ADMIN_OFFER_DETAILS ? {} : state.offerDetails,
      };
    case ADMIN_OFFER_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case GET_ADMIN_OFFERS_SUCCESS:
      return {
        ...state,
        adminOffers: {
          offers:
            action.payload.meta.page === 1
              ? action.payload.dataList
              : [ ...state.adminOffers.offers, ...action.payload.dataList ],
          meta: action.payload.meta,
        },
        status: API_STATUS.SUCCESS,
      };
    case GET_ADMIN_OFFER_DETAILS_SUCCESS:
    case REJECT_OFFER_REQUEST_SUCCESS:
    case APPROVE_OFFER_REQUEST_SUCCESS:
      return {
        ...state,
        offerDetails: action.payload,
        status: API_STATUS.SUCCESS,
      };
    case ADD_ADMIN_NEW_OFFER_SUCCESS:
    case ENABLE_DISABLE_PARTNER_OFFER_SUCCESS:
    case UPDATE_ADMIN_OFFER_SUCCESS:
    case UPDATE_PARTNER_OFFER_BY_ADMIN_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        message: action.payload,
      };
    case DELETE_ADMIN_OFFER_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        adminOffers: {
          ...state.adminOffers,
          offers: state.adminOffers.offers.filter( offer => offer.offerId !== action.payload.offerId ),
        },
      };
    case GET_ADMIN_OFFERS_FAILURE:
    case GET_ADMIN_OFFER_DETAILS_FAILURE:
    case ADD_ADMIN_NEW_OFFER_FAILURE:
    case REJECT_OFFER_REQUEST_FAILURE:
    case APPROVE_OFFER_REQUEST_FAILURE:
    case ENABLE_DISABLE_PARTNER_OFFER_FAILURE:
    case UPDATE_ADMIN_OFFER_FAILURE:
    case UPDATE_PARTNER_OFFER_BY_ADMIN_FAILURE:
    case DELETE_ADMIN_OFFER_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
      };

    default:
      return state;
  }
};

export default adminOffer;
