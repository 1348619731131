import PropTypes from "prop-types";
import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useHistory } from "react-router-dom";
import { useForm } from "../../../hooks";
import eventConstant from "../../../util/eventConstant";
import SelectBox from "../../shared/selectBox";
import FieldTitleWithInfoIcon from "../fieldTitle";

const CreateEvent = ( { onSave, isEditEvent, eventDetail } ) => {
  const history = useHistory();
  const { values, errors, isError, handleChange, showAllErrorMessage } =
    useForm(
      {
        eventName: "",
        units: eventConstant.UNITS[ 0 ],
        description: "",
      },
      {
        eventName: false,
        description: false,
      }
    );

  useEffect( () => {
    if( isEditEvent && eventDetail ) {
      handleChange( "eventName", eventDetail.eventName );
      handleChange( "units", eventDetail.units );
      handleChange( "description", eventDetail.description );
    }
  }, [ eventDetail ] );

  const handleInputChange = ( e ) => {
    const { name, value } = e.target;

    handleChange( name, value );
  };

  const handleSelectChange = ( selectedValue ) => {
    handleChange( "units", selectedValue );
  };

  const handleSave = () => {
    showAllErrorMessage();

    if( !isError ) {
      onSave( values );
    }
  };

  return (
    <>
      <div className='main-wrapper'>
        <div className='main-right-wrapper'>
          <div className='page-head-wrapper'>
            <h4 className='page-heading'>
              {isEditEvent ? "Edit Event" : "Add Event"}
            </h4>
            <div className='page-head-right'>
              <Button
                variant='outline-secondary mr-4'
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
              {( eventDetail?.isActive || !isEditEvent ) && ( <Button variant='primary' onClick={handleSave}>
                {isEditEvent ? "Update" : "Save"}
              </Button> )}
            </div>
          </div>
          <div className='page-inner-box profile-steps-box'>
            <Form>
              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <FieldTitleWithInfoIcon title='Event Name' description='The name must be unique across all your games. Only you will be able to use the event.' />
                    <Form.Control
                      type='text'
                      placeholder='Enter Event Name'
                      name='eventName'
                      onChange={handleInputChange}
                      value={values.eventName}
                      maxLength={50}
                      data-testid='eventName'
                    />
                    <div className='invalid-feedback d-block'>
                      {errors.eventName}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Units</Form.Label>
                    <SelectBox
                      options={eventConstant.UNITS}
                      value={values.units}
                      onChange={handleSelectChange}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as='textarea'
                      placeholder='Write Description'
                      name='description'
                      onChange={handleInputChange}
                      value={values.description}
                      maxLength={500}
                      data-testid='description'
                    />
                    <div className='invalid-feedback d-block'>
                      {errors.description}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

CreateEvent.propTypes = {
  onSave: PropTypes.func,
  isEditEvent: PropTypes.string,
  eventDetail: PropTypes.object,
};

export default CreateEvent;
