import PropTypes from "prop-types";
import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import constant from "../../../util/constant";
import OptInFields from "./optInFields";

const { OPT_IN_STATUS } = constant;

const OptIn = ( {
  values,
  optInSelect = [ { value: "Yes", label: OPT_IN_STATUS.YES }, { value: "No", label: OPT_IN_STATUS.NO } ],
  handleInputChange,
  onBlur,
  errors,
  membershipTypes,
  isTournament = false,
} ) => {
  return (
    <>
      <Col sm={12}>
        <Form.Group>
          <Form.Label className='label-md'>
            Opt-in required?
          </Form.Label>
          <Row>
            {optInSelect?.map( item => (
              <Col sm={3} key={item?.id}>
                <div className='custom-radio'>
                  <label>
                    <input
                      type='radio'
                      name='optInType'
                      value={item?.value}
                      checked={item?.value === values.optInType}
                      onChange={handleInputChange}
                    />
                    <span>
                      {item?.label}
                    </span>
                  </label>
                </div>
              </Col>
            ) )}
          </Row>
        </Form.Group>
      </Col>
      <OptInFields
        values={values}
        handleInputChange={handleInputChange}
        onBlur={onBlur}
        errors={errors}
        membershipTypes={membershipTypes}
        isTournament={isTournament}
      />
    </>
  );
};

OptIn.propTypes = {
  optInSelect: PropTypes.array,
  handleInputChange: PropTypes.func,
  values: PropTypes.object,
  onBlur: PropTypes.func,
  membershipTypes: PropTypes.array,
  errors: PropTypes.object,
  isTournament: PropTypes.bool,
};

export default OptIn;
