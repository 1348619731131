import constant from "../util/constant";
import { convertDate } from "../util/helpers";
import offerConstant from "../util/offerConstant";

const { OPT_IN_STATUS } = constant;
const { OFFER_STATUS } = offerConstant;

const handleNullUndefinedValue = ( data, name ) => data[ name ] || "";

export class PartnerGamerFilterList {
  getLocalDate = ( obj, dateType ) => new Date( obj[ dateType ] ).toLocaleDateString( "fr-CA" );

  constructor( obj ) {
    this.myPlace = obj?.myPlace ?? 0;
    this.tournamentName = obj?.offerName ?? "";
    this.tournamentId = obj?.offerId ?? "";
    this.tournamentDescription = obj?.offerDescription ?? "";
    this.gameName = obj?.gameName ?? "";
    this.startDate = obj?.startDate
      ? convertDate( obj?.startDate )
      : null;
    this.endDate = obj?.endDate
      ? convertDate( obj?.endDate )
      : null;
    this.startDateOriginal = obj?.startDate
      ? this.getLocalDate( obj, "startDate" )
      : "";
    this.endDateOriginal = obj?.endDate
      ? this.getLocalDate( obj, "endDate" )
      : "";
    this.partnerLogo = obj?.partnerLogo ?? "";
    this.gameIcon = obj?.iconUrl ?? "";
    this.rewards = obj?.reward ?? [];
    this.optInDetail = {
      optIn: obj?.optIn || OPT_IN_STATUS.NO,
      membershipId: obj?.membershipId,
      entryFee: obj?.entryFee || "",
      minParticipants: obj?.minParticipants,
      maxParticipants: obj?.maxParticipants,
    };
    this.optInType = handleNullUndefinedValue( obj, "optIn" );
    this.statusTitle = OFFER_STATUS.find(
      offer => offer.status === obj.status
    );
  }
}

export class OfferNameFilterList {
  constructor( list ) {
    this.offerNameList = list?.map( s => ( {
      label: s.offerName.trim(),
      value: s.offerId }
    ) )
      .sort( ( a, b ) => a.label.localeCompare( b.label ) ) ?? [];
  }
}

export class GamesNameFilterList {
  constructor( list ) {
    this.gameNameList = list?.map( s => ( {
      label: s.gameName.trim(),
      value: s.gameName === "N/A" ? "" : s.gameName.trim() }
    ) )
      .sort( ( a, b ) => a.label.localeCompare( b.label ) ) ?? [];
  }
}
