import ClearIcon from "@material-ui/icons/Clear";
import PropTypes from "prop-types";
import React, { memo } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

// Local-imports
import { useForm } from "../../../hooks";
import { customFilterOption } from "../../../util/helpers";

const customStyles = {
  control: base => ( {
    ...base,
    "&:hover": { borderColor: "" }, // border style on hover
    border: "1px solid lightgray", // default border color
    boxShadow: "none", // no box-shadow

  } ),
};

const MultiSelectCheckbox = ( {
  multiOptionsList,
  handleSelectChange,
  multiSelectedItems,
  handleClearMultiSelect,
  name,
} ) => {
  const { values, handleChange } =
    useForm(
      {
        inputValue: "",
      },
      {}
    );

  const handleClear = () => {
    handleChange( "inputValue", "" );
    handleClearMultiSelect( name );
  };

  return (
    <>
      <ReactMultiSelectCheckboxes
        styles={customStyles}
        placeholder={"Search"}
        placeholderButtonLabel={"Select"}
        options={multiOptionsList}
        onChange={obj => handleSelectChange( obj, name ) }
        value={multiSelectedItems}
        inputValue={values.inputValue}
        filterOption={customFilterOption}
        onInputChange={( value, action ) => {
          if( action.action === "input-change" ) {
            handleChange( "inputValue", value );
          }
        }}
      />
      { multiSelectedItems?.length > 0 && ( <div onClick={ handleClear } title="Clear all" className="clear-icon" ><ClearIcon /></div> ) }
    </>
  );
};

MultiSelectCheckbox.propTypes = {
  multiOptionsList: PropTypes.array,
  handleSelectChange: PropTypes.func,
  multiSelectedItems: PropTypes.array,
  handleClearMultiSelect: PropTypes.func,
  name: PropTypes.string,
};

export default memo( MultiSelectCheckbox );
