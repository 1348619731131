import PropTypes from "prop-types";
import React from "react";

const RedeemSection = ( { redeemTransactionDetail: data } ) => {
  return (
    <>
      { data.reasonForRejection &&
       <div className='white-container pink-border'>
         <h4 className='page-inner-heading'>Reason for Rejection <span className='badge badge-rejected'>Rejected</span></h4>
         <div className='content-des'>
           { data.reasonForRejection }
         </div>
       </div>
      }
      <div className="content-section">
        <div className="content-label">Cashback Method</div>
        <div className="content-des">{data.cashbackName}</div>
      </div>
      <div className="content-section">
        <div className="content-label">Transaction ID</div>
        <div className="content-des">{data.transactionId}</div>
      </div>
      <div className="content-section">
        <div className="content-label">Status</div>
        <div className="content-des">{data.status}</div>
      </div>
      <div className="content-section">
        <div className="content-label">Gamer</div>
        <div className="content-des">{data.fullName}</div>
      </div>
      <div className="content-section">
        <div className="content-label">Points Redeemed</div>
        <div className="content-des">{data.acQyrCashSold}</div>
      </div>
      <div className="content-section">
        <div className="content-label">Amount Requested</div>
        <div className="content-des">{data.netRedeemAmount}</div>
      </div>
      {data.redeemRequestId && <div className="content-section">
        <div className="content-label">User PayPal ID or Phone</div>
        <div className="content-des">{data.emailIdOrPhone}</div>
      </div>}
    </> );
};

RedeemSection.propTypes = {
  redeemTransactionDetail: PropTypes.object,
};

export default RedeemSection;
