import { toastr } from "react-redux-toastr";
import { AdminExchangeTransactionListDetail } from "../../entities/adminExchangeTransactionListDetail";
import { AdminRedeemTransactionDetail } from "../../entities/adminRedeemTransactionDetail";
import { AdminRedeemTransactionListDetail } from "../../entities/adminRedeemTransactionListDetail";
import {
  getExchangeTransaction,
  getRedeemTransaction,
  getRedeemTransactionDetails,
  rejectRedeemTransactionRequest,
  approveRedeemTransactionRequest,
  getRedeemFilterList,
  getExchangeFilterList
} from "../../services/adminTransactionServices";
import adminTransactionConstant from "../../util/adminTransactionConstant";
import constant from "../../util/constant";
import {
  START_LOADER,
  STOP_LOADER,
  REDEEM_TRANSACTION_API_REQUEST,
  GET_REDEEM_TRANSACTION_SUCCESS,
  GET_REDEEM_TRANSACTION_FAILURE,
  ADMIN_TRANSACTION_API_END,
  GET_REDEEM_TRANSACTION_DETAILS_SUCCESS,
  GET_REDEEM_TRANSACTION_DETAILS_FAILURE,
  REJECT_REDEEM_TRANSACTION_SUCCESS,
  REJECT_REDEEM_TRANSACTION_FAILURE,
  APPROVE_REDEEM_TRANSACTION_SUCCESS,
  APPROVE_REDEEM_TRANSACTION_FAILURE,
  ADMIN_REDEEM_FILTER_LIST_SUCCESS,
  ADMIN_REDEEM_FILTER_LIST_FAILURE,
  EXCHANGE_TRANSACTION_API_REQUEST,
  GET_EXCHANGE_TRANSACTION_SUCCESS,
  GET_EXCHANGE_TRANSACTION_FAILURE,
  ADMIN_EXCHANGE_FILTER_LIST_SUCCESS,
  ADMIN_EXCHANGE_FILTER_LIST_FAILURE,
  RESET_EXCHANGE_TRANSACTION_REQUEST
} from "../actionTypes";

const { ADMIN_TRANSACTION_API_TYPES } = adminTransactionConstant;
const { ERROR_MESSAGES } = constant;

export const getRedeemTransactionAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: REDEEM_TRANSACTION_API_REQUEST,
      payload: ADMIN_TRANSACTION_API_TYPES.GET_REDEEM_TRANSACTION,
    } );

    const response = await getRedeemTransaction( param );
    const transactionList = response.data.redeemTransactionsList.map(
      item => new AdminRedeemTransactionListDetail( item )
    );

    dispatch( {
      type: GET_REDEEM_TRANSACTION_SUCCESS,
      payload: { transactionList, meta: response.data.meta },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GET_REDEEM_TRANSACTION_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_TRANSACTION_API_END,
    } );
  }
};

export const getExchangeTransactionAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: EXCHANGE_TRANSACTION_API_REQUEST,
      payload: ADMIN_TRANSACTION_API_TYPES.GET_EXCHANGE_TRANSACTION,
    } );

    const response = await getExchangeTransaction( param );
    const transactionList = response.data.exchangeTransactions.map(
      item => new AdminExchangeTransactionListDetail( item )
    );

    dispatch( {
      type: GET_EXCHANGE_TRANSACTION_SUCCESS,
      payload: { transactionList, meta: response.data.meta },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GET_EXCHANGE_TRANSACTION_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_TRANSACTION_API_END,
    } );
  }
};

export const getRedeemTransactionDetailsAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: REDEEM_TRANSACTION_API_REQUEST,
      payload: ADMIN_TRANSACTION_API_TYPES.GET_REDEEM_TRANSACTION_DETAILS,
    } );

    const response = await getRedeemTransactionDetails( param );
    const newResponse = new AdminRedeemTransactionDetail( response );

    dispatch( {
      type: GET_REDEEM_TRANSACTION_DETAILS_SUCCESS,
      payload: newResponse,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GET_REDEEM_TRANSACTION_DETAILS_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_TRANSACTION_API_END,
    } );
  }
};

export const rejectRedeemRequestAction = requestObj => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: REDEEM_TRANSACTION_API_REQUEST,
      payload: ADMIN_TRANSACTION_API_TYPES.REJECT_REDEEM_TRANSACTION,
    } );

    const response = await rejectRedeemTransactionRequest( requestObj );

    dispatch( { type: REJECT_REDEEM_TRANSACTION_SUCCESS, payload: response } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: REJECT_REDEEM_TRANSACTION_FAILURE, payload: error } );
    dispatch( { type: STOP_LOADER } );
    toastr.error( "Error", error?.error || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( { type: ADMIN_TRANSACTION_API_END } );
  }
};

export const approveRedeemRequestAction = requestObj => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: REDEEM_TRANSACTION_API_REQUEST,
      payload: ADMIN_TRANSACTION_API_TYPES.APPROVE_REDEEM_TRANSACTION,
    } );

    const response = await approveRedeemTransactionRequest( requestObj );

    dispatch( { type: APPROVE_REDEEM_TRANSACTION_SUCCESS, payload: response } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: APPROVE_REDEEM_TRANSACTION_FAILURE, payload: error } );
    dispatch( { type: STOP_LOADER } );
    toastr.error( "Error", error?.error || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( { type: ADMIN_TRANSACTION_API_END } );
  }
};

export const getRedeemFilterListAction = () => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: REDEEM_TRANSACTION_API_REQUEST,
      payload: ADMIN_TRANSACTION_API_TYPES.GET_REDEEM_FILTER_LIST,
    } );

    let response = await getRedeemFilterList();

    if( response ) {
      response = response.data;

      const gamerNames = response.gamerNames.map( name => ( { label: name, value: name } ) );
      const redeemRequestIds = response.redeemRequestIds.map( reqId => ( { label: reqId, value: reqId } ) );
      const paymentVendors = response.paymentVendors.map( data => ( { label: data.name, value: data.name } ) );
      const transactionStatus = Object.values( response.transactionStatus ).map( status => ( { label: status, value: status } ) );

      dispatch( {
        type: ADMIN_REDEEM_FILTER_LIST_SUCCESS,
        payload: { gamerNames, redeemRequestIds, paymentVendors, transactionRanges: response.transactionRanges, transactionStatus },
      } );
    }
    else {
      dispatch( { type: ADMIN_REDEEM_FILTER_LIST_FAILURE, payload: response.message || "" } );
    }

    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: ADMIN_REDEEM_FILTER_LIST_FAILURE, payload: error } );
    dispatch( { type: STOP_LOADER } );
  }
  finally {
    dispatch( { type: ADMIN_TRANSACTION_API_END } );
  }
};

export const getExchangeFilterListAction = ( gamerId = null ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: EXCHANGE_TRANSACTION_API_REQUEST,
      payload: ADMIN_TRANSACTION_API_TYPES.GET_EXCHANGE_FILTER_LIST,
    } );

    let response = await getExchangeFilterList( gamerId );

    if( response ) {
      response = response.data;

      const gamerNames = response.gamerNames.map( name => ( { label: name, value: name } ) );
      const gameNames = response.gameNames.map( name => ( { label: name, value: name } ) );
      const assetNames = response.assetNames.map( asset => ( { label: asset, value: asset } ) );

      dispatch( {
        type: ADMIN_EXCHANGE_FILTER_LIST_SUCCESS,
        payload: { gamerNames, gameNames, assetNames, transactionRanges: response.QXPointsRange },
      } );
    }
    else {
      dispatch( { type: ADMIN_EXCHANGE_FILTER_LIST_SUCCESS, payload: response.message || "" } );
    }

    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: ADMIN_EXCHANGE_FILTER_LIST_FAILURE, payload: error } );
    dispatch( { type: STOP_LOADER } );
  }
  finally {
    dispatch( { type: ADMIN_TRANSACTION_API_END } );
  }
};

export const resetExchangeTransactionFilter = () => async ( dispatch ) => {
  dispatch( {
    type: RESET_EXCHANGE_TRANSACTION_REQUEST,
    payload: ADMIN_TRANSACTION_API_TYPES.RESET_EXCHANGE_FILTER_LIST,
  } );
};
