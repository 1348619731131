import constant from "../util/constant";
import { createRelationExpression, getOptInType } from "../util/helpers";
import offerConstant from "../util/offerConstant";

const { OPT_IN_STATUS } = constant;
const { RANKING_OBJECTIVE } = offerConstant;

export class DraftTournamentRequest {
  constructor( obj ) {
    /* eslint-disable camelcase */
    this.name = ( obj.isAdminAddEditOffer ? obj.offerName : obj.tournamentName ).trim();
    this.description = obj.rawDescription;
    this.description_text = obj.descriptionText;
    this.template = obj.tournamentTemplate;
    this.template_id = "";
    this.offerId = obj.offerId || "";
    this.isTournament = obj.isTournament;
    this.partnerId = obj.partnerId || "";
    this.relation_expression = createRelationExpression( obj.achievements );
    this.achievements = obj.achievements.map( ( achievement ) => {
      return {
        name: achievement.name.trim(),
        description: achievement.rawDescription,
        description_text: achievement.descriptionText,
        event: achievement.event,
        function: achievement.function,
        eventUnit: achievement.eventUnit,
        eventDescription: achievement.eventDescription,
        ranking_objective: achievement.ranking?.value || RANKING_OBJECTIVE[ 0 ].value,
      };
    } );
    this.reward = obj.rewards.map( ( reward, index ) => {
      return {
        qx_points: reward.qxPoint,
        partner_cost: reward.qxPoint * obj.partnerCostUsdPerQxPoint,
        place: index + 1,
      };
    } );
    this.cloneOfferName = obj.cloneOfferName || "";
    this.cloneOfferId = obj.cloneOfferId || "";
    this.advertiseToGamer = obj.advertiseToGamer;
    this.advertise_to_gamer = obj.advertiseToGamer?.value || "";
    this.advertise_date = obj.advertiseDate || false;
    this.noOfDays = obj.noOfDays || 0;
    /* eslint-enable camelcase */

    if( obj.optInType === OPT_IN_STATUS.YES ) {
      this.optIn = {
        optInType: getOptInType( obj.optInType, Number( obj.entryFee ) ),
        membershipId: obj.membershipId,
        entryFee: obj.entryFee || null,
        minParticipants: obj.minParticipants || null,
        maxParticipants: obj.maxParticipants || null,
      };
    }
    else {
      this.optIn = {
        optInType: obj.optInType,
        membershipId: null,
        entryFee: null,
        minParticipants: null,
        maxParticipants: null,
      };
    }

    if( obj.partnerTournament ) {
      /* eslint-disable camelcase */
      this.offerSchedule = {
        game: obj.game,
        gamers: obj.gamers,
        game_id: obj.game.value,
        audience_id: obj.gamers?.value || "",
        start_date: obj.startDate,
        end_date: obj.endDate,
        max_reward_count_per_user: obj.offerCanBeAchievedCount || null,
        template_id: obj?.offerTemplate?.value || "",
        start_time: obj.startTime,
        end_time: obj.endTime,
        timeZone: obj.timeZone,
      };
      /* eslint-enable camelcase */
    }
  }
}
