import constant from "../../util/constant";
import {
  ADMIN_OFFER_DRAFT_API_REQUEST,
  ADD_ADMIN_NEW_OFFER_DRAFT_FAILURE,
  ADD_ADMIN_NEW_OFFER_DRAFT_SUCCESS,
  ADMIN_OFFER_DRAFT_API_END,
  UPDATE_ADMIN_OFFER_DRAFT_SUCCESS,
  UPDATE_ADMIN_OFFER_DRAFT_FAILURE,
  GET_ADMIN_DRAFT_OFFER_DETAIL_SUCCESS,
  GET_ADMIN_DRAFT_OFFER_DETAIL_FAILURE
} from "../actionTypes";

const { API_STATUS } = constant;

const DEFAULT_STATE = {
  loading: false,
  message: "",
  status: "",
  apiType: "",
  adminOffers: { offers: [], meta: "" },
  offerDetails: {},
};

const adminOfferDraft = ( state = DEFAULT_STATE, action = "" ) => {
  switch ( action.type ) {
    case ADMIN_OFFER_DRAFT_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
      };
    case ADMIN_OFFER_DRAFT_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case ADD_ADMIN_NEW_OFFER_DRAFT_FAILURE:
    case UPDATE_ADMIN_OFFER_DRAFT_FAILURE:
    case GET_ADMIN_DRAFT_OFFER_DETAIL_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
      };
    case ADD_ADMIN_NEW_OFFER_DRAFT_SUCCESS:
    case UPDATE_ADMIN_OFFER_DRAFT_SUCCESS:
    case GET_ADMIN_DRAFT_OFFER_DETAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        message: action.payload,
      };
    default:
      return state;
  }
};

export default adminOfferDraft;

