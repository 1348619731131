import HelpIcon from "@material-ui/icons/Help";
import PropTypes from "prop-types";
import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Row from "react-bootstrap/Row";
import Tooltip from "react-bootstrap/Tooltip";
import SelectBox from "../../shared/selectBox";

const ChooseTemplate = ( {
  isClone,
  clickOnCloned,
  clonedName,
  templates,
  onChangeTemplate,
  selectedTemplate,
  type,
} ) => (
  <>
    <h4 className='page-inner-heading'>1. Create {( type === "Offer" ) ? "an" : "a"} {type}</h4>
    {isClone && (
      <Row>
        <Col sm={6}>
          <Form.Label>Cloned from {type}</Form.Label><br />
          <span className='offer-link' onClick={clickOnCloned}>
            {clonedName}
          </span>
        </Col>
      </Row>
    )}
    {!isClone && (
      <Row>
        <Col sm={6}>
          <Form.Group>
            <Form.Label className='label-with-icon'>
              Choose Template (Optional)
              <OverlayTrigger placement='right' overlay={<Tooltip>You can still start from a template, or just leave this blank.</Tooltip>}>
                <HelpIcon />
              </OverlayTrigger>
            </Form.Label>
            <SelectBox
              placeholder="Choose Template"
              options={templates}
              isClearable={true}
              onChange={onChangeTemplate}
              value={selectedTemplate}
            />
          </Form.Group>
        </Col>
      </Row>
    )}
  </>
);

ChooseTemplate.propTypes = {
  isClone: PropTypes.bool,
  clickOnCloned: PropTypes.func,
  clonedName: PropTypes.string,
  templates: PropTypes.array,
  onChangeTemplate: PropTypes.func,
  selectedTemplate: PropTypes.object,
  type: PropTypes.string,
};

export default ChooseTemplate;
