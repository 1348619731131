import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useHistory, useParams } from "react-router-dom";
import { useModal, useForm } from "../../../hooks";
import {
  addAdminUserAction,
  getAdminUserDetailAction,
  getAdminUserPermissionAction,
  enableDisableAdminUserAction } from "../../../store/actions/adminUser";
import adminUserConstant from "../../../util/adminUserConstant";
import constant from "../../../util/constant";
import ModalPopup from "../modal";
import AdminUserForm from "./adminUserForm";

const { API_STATUS } = constant;
const { ADMIN_PERMISSIONS, ADMIN_USERS_API_TYPES } = adminUserConstant;

const initialDirty = {
  fullName: false,
  username: false,
  email: false,
};

const AddEditAdminUser = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userId } = useParams();
  const { status, apiType, adminDetail, activeAdminDetail } = useSelector( state => ( {
    status: state.adminUser.status,
    apiType: state.adminUser.apiType,
    adminDetail: state.adminUser.adminUserDetail,
    activeAdminDetail: state.adminDetail.data,
  } ) );
  const [ isEditPermission, setEditPermissionStatus ] = useState( false );
  const [ errorMessage, setErrorMessage ] = useState( "" );
  const { isOpened, closeModal, openModal, modalType, setModalType } = useModal();
  const { values, errors, isError, handleChange, showAllErrorMessage } =
    useForm(
      {
        fullName: "",
        username: "",
        email: "",
        isActive: false,
        permissions: [],
      },
      initialDirty
    );

  const handleInputChange = ( e ) => {
    const { name, value } = e.target;

    handleChange( name, value );
  };

  const handleSubmit = () => {
    showAllErrorMessage();

    if( !isError ) {
      if( isEditPermission ) {
        const requestObj = {
          // eslint-disable-next-line camelcase
          admin_id: adminDetail.adminId,
          // eslint-disable-next-line camelcase
          admin_permissions: values.permissions,
        };

        dispatch( getAdminUserPermissionAction( requestObj, activeAdminDetail.adminId ) );
      }
      else {
        const requestObj = {
          // eslint-disable-next-line camelcase
          full_name: values.fullName,
          username: values.username,
          email: values.email,
          isActive: values.isActive,
          // eslint-disable-next-line camelcase
          admin_permissions: values.permissions,
        };

        dispatch( addAdminUserAction( requestObj ) );
      }
    }
  };

  const handleChangePermission = ( e ) => {
    const { name } = e.target;
    let newPermission = values.permissions;

    if( values.permissions.includes( ADMIN_PERMISSIONS[ name ] ) ) {
      newPermission = values.permissions.filter( permission => permission !== ADMIN_PERMISSIONS[ name ] );
    }
    else {
      newPermission.push( ADMIN_PERMISSIONS[ name ] );
    }

    handleChange( "permissions", newPermission );
  };

  const isPermissionActive = permission => values.permissions.includes( permission );

  useEffect( () => {
    if( status === API_STATUS.SUCCESS && ( apiType === ADMIN_USERS_API_TYPES.ADD_ADMIN_USER || apiType === ADMIN_USERS_API_TYPES.UPDATE_ADMIN_USER_PERMISSION ) ) {
      toastr.success( apiType === ADMIN_USERS_API_TYPES.ADD_ADMIN_USER ? "Admin user added successfully." : "Admin user updated successfully." );
      history.goBack();
    }
    else if( status === API_STATUS.SUCCESS && apiType === ADMIN_USERS_API_TYPES.GET_ADMIN_USER_DETAIL ) {
      handleChange( "fullName", adminDetail.fullName );
      handleChange( "username", adminDetail.username );
      handleChange( "email", adminDetail.email );
      handleChange( "isActive", adminDetail.isActive );
      handleChange( "permissions", adminDetail.permissions );
    }
    else if( ( status === API_STATUS.FAILURE ) && ( apiType === ADMIN_USERS_API_TYPES.ENABLE_DISABLE_ADMIN_USER ) ) {
      handleChange( "isActive", !values.isActive );
    }
  }, [ apiType, status, adminDetail ] );

  useEffect( () => {
    if( userId !== "0" ) {
      setEditPermissionStatus( true );
      dispatch( getAdminUserDetailAction( userId ) );
    }
  }, [ userId ] );

  const handleOpenModal = ( mType ) => {
    setModalType( mType );
    openModal();
  };

  const handleCloseModal = () => {
    closeModal();
    setErrorMessage();
  };

  const handleEnableDisable = () => {
    const requestObj = {
      adminId: adminDetail.adminId,
      setEnabled: !adminDetail.isActive,
    };

    closeModal();
    handleChange( "isActive", !values.isActive );
    dispatch( enableDisableAdminUserAction( requestObj ) );
  };

  return (
    <>
      <AdminUserForm
        values={values}
        errors={errors}
        isEditPermission={isEditPermission}
        handleInputChange={handleInputChange}
        handleChangePermission={handleChangePermission}
        onSubmit={handleSubmit}
        handleOpenModal={handleOpenModal}
        isPermissionActive={isPermissionActive}
        onCancel={() => history.goBack()}
      />
      <ModalPopup
        isOpened={isOpened}
        onClose={handleCloseModal}
        type={modalType}
        values={values}
        onChangeInput={handleInputChange}
        errorMessage={errorMessage}
        onConfirm={handleEnableDisable}
      />
    </>
  );
};

export default AddEditAdminUser;
