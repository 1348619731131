import { formatNumberAsPerLocale } from "../util/helpers";

export class GamerAsset {
  constructor( obj ) {
    this.QXPoints = obj.unitPriceQxPoints || null;
    this.assetId = obj.assetId || "--";
    this.assetName = obj.assetName || "--";
    this.gameName = obj.gameName || "--";
    this.quantity = obj.quantity || null;
    this.asset = ( obj.assetName !== null ) ? `${ formatNumberAsPerLocale( obj.quantity ) } ${ obj.assetName }` : "--";
  }
}
