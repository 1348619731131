import PropTypes from "prop-types";
import React from "react";
import { Form, Col } from "react-bootstrap";
import constant from "../../../util/constant";
import { calendarIcon } from "../../../util/icons";
import CustomDatePicker from "../../shared/customDatePicker";
import SelectBox from "../../shared/selectBox";

const { ADVERTISE_OPTIONS, ADVERTISE_STATUS } = constant;

const AdvertiseToGamer = ( {
  values,
  onChange,
  errors,
  onSelect,
  onBlur,
  advertiseDateChange,
  isTournament = false,
} ) => {
  const getTournamentAdvertiseOptions = ( offerOptions ) => {
    let updatedOptions = [];

    if( offerOptions.length ) {
      updatedOptions = offerOptions.map( ( obj ) => {
        if( obj?.label.includes( "offer" ) ) {
          return { ...obj, label: obj?.label.replace( "offer", "tournament" ) };
        }

        return obj;
      } );
    }

    return updatedOptions;
  };

  return (
    <>
      <Col sm={6}>
        <Form.Group>
          <Form.Label>When should {`${ isTournament ? "tournament" : "offer" }`} be visible to gamers?</Form.Label>
          <SelectBox
            options={isTournament ? getTournamentAdvertiseOptions( ADVERTISE_OPTIONS ) : ADVERTISE_OPTIONS }
            onChange={value => onSelect( "advertiseToGamer", value )}
            value={values.advertiseToGamer}
            onBlur={onBlur}
            data-testid='selectAdvertiseToGamer'
          />
        </Form.Group>
      </Col>
      {values.advertiseToGamer?.value === ADVERTISE_STATUS.SOME_SPECIFIC_DATE && (
        <Col sm={6}>
          <Form.Group className='custom-datepicker with-icon'>
            <Form.Label>Advertise Date for Gamer</Form.Label>
            <CustomDatePicker
              value={values.advertiseDate}
              handleChange={advertiseDateChange}
              onBlur={onBlur}
              minDate={new Date()}
              maxDate={new Date( values.startDate ) || new Date()}
            />
            <div className='invalid-feedback d-block'>
              {errors.advertiseDate}
            </div>
            <img src={calendarIcon} alt="calendar" className='calendar-icon more-top' />
          </Form.Group>
        </Col>
      )}
      {values.advertiseToGamer?.value === ADVERTISE_STATUS.NO_OF_DAYS && (
        <Col sm={6}>
          <Form.Group>
            <Form.Label>Number of Days</Form.Label>
            <Form.Control type='number' placeholder='Enter No of Days'
              value={values.noOfDays}
              name='noOfDays'
              onChange={onChange}
              data-testid='noOfDays'
              min="0"
            />
            <div className='invalid-feedback d-block'>
              {errors.noOfDays}
            </div>
          </Form.Group>
        </Col>
      )}
    </>
  );
};

AdvertiseToGamer.propTypes = {
  values: PropTypes.object,
  onChange: PropTypes.func,
  errors: PropTypes.object,
  onSelect: PropTypes.func,
  onBlur: PropTypes.func,
  advertiseDateChange: PropTypes.func,
  isTournament: PropTypes.bool,
};

export default AdvertiseToGamer;
