import PropTypes from "prop-types";
import React from "react";
import { useParams } from "react-router-dom";

import GameAssets from "../../../shared/games/gameAssets";
import GameInfo from "../../../shared/games/gameInfo";
import GameOffers from "../../../shared/games/gameOffers";

const ViewLinkedGameDetail = ( {
  viewType,
  gameDetails,
  usdPerQxPoint,
  partnerCostUsdPerQxPoint } ) => {
  const { gamerId: urlParamString } = useParams();
  const { 0: gamerId } = String( urlParamString ).split( "?" );

  return (
    <>
      {viewType && (
        <div>
          <GameInfo
            gamerId={gamerId}
            gameDetails={gameDetails}
          />
          <GameAssets
            exchangeableAssets={gameDetails?.exchangeableAssets}
            usdPerQxPoint={usdPerQxPoint}
            partnerCostUsdPerQxPoint={gameDetails?.partnerCostUsdPerQxPoint || partnerCostUsdPerQxPoint}
          />
          <GameOffers
            associatedOffers={gameDetails?.associatedOffers}
            gameIcon={gameDetails?.gameIcon}
            gameName={gameDetails?.gameName}
            isPartnerGamerLinkedGame={true}
          />
        </div>
      )}
    </>
  );
};

ViewLinkedGameDetail.propTypes = {
  viewType: PropTypes.bool,
  gameDetails: PropTypes.object,
  usdPerQxPoint: PropTypes.number,
  partnerCostUsdPerQxPoint: PropTypes.number,
};

export default ViewLinkedGameDetail;
