import PropTypes from "prop-types";
import React from "react";
import ViewPartnerGameDetail from "./viewPartnerGameDetail";

const PartnerGameDetail = ( { viewType, gameId, gameDetails, usdPerQxPoint, setTab,
  partnerCostUsdPerQxPoint, entityType } ) => {
  return (
    <>
      {viewType && (
        <ViewPartnerGameDetail
          viewType = {viewType}
          gameDetails = {gameDetails}
          gameId = {gameId}
          gamerId = {0}
          usdPerQxPoint = {usdPerQxPoint}
          partnerCostUsdPerQxPoint = { partnerCostUsdPerQxPoint }
          setTab = {setTab}
          entityType = {entityType}
        />
      )}
    </>
  );
};

PartnerGameDetail.propTypes = {
  viewType: PropTypes.bool,
  gameDetails: PropTypes.object,
  gameId: PropTypes.string,
  usdPerQxPoint: PropTypes.number,
  setTab: PropTypes.func,
  entityType: PropTypes.string,
  partnerCostUsdPerQxPoint: PropTypes.number,
};

export default PartnerGameDetail;
