import PropTypes from "prop-types";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import {
  enableDisableEventAction
} from "../../../store/actions/adminEvent";
import adminConstant from "../../../util/adminConstant";
import constant from "../../../util/constant";
import EventDisableModal from "./eventDisableModal";

const { MODAL_TYPE_DETAIL } = adminConstant;
const { DEFAULT_UUID } = constant;

const ViewEvent = ( { eventDetail, type } ) => {
  const dispatch = useDispatch();
  const [ isOpened, setIsOpened ] = useState( false );
  const [ modalData, setModalData ] = useState( false );
  const [ eventData, setEventData ] = useState();

  const handleOpenModal = ( eventType ) => {
    setIsOpened( true );
    setModalData( eventType );
    setEventData( eventDetail );
  };

  const handleCloseModal = () => {
    setIsOpened( false );
  };

  const handleConfirm = ( eventType ) => {
    if( eventType === MODAL_TYPE_DETAIL.ACTIVE_EVENT.type || eventType === MODAL_TYPE_DETAIL.INACTIVE_EVENT.type ) {
      dispatch( enableDisableEventAction( { eventId: eventDetail.eventId, setEnabled: eventType === MODAL_TYPE_DETAIL.ACTIVE_EVENT.type
        ? false
        : true } ) );
      setIsOpened( false );
    }
    else {
      setIsOpened( false );
    }
  };

  return (
    <>
      <div className='main-wrapper'>
        <div className='main-right-wrapper'>
          <div className='page-head-wrapper'>
            <h4 className='page-heading'>
              Event
            </h4>
            {( ( ( eventDetail?.isActivePartner === true ) || ( eventDetail?.partnerId === DEFAULT_UUID ) ) && ( type === "admin" ) && ( <div className='page-head-right'>
              <div className='btn-switch large'>
                <Form.Check
                  type='switch'
                  id='custom-switch'
                  label='Enabled'
                  checked={eventDetail?.isActive || false}
                  onChange={() =>
                    handleOpenModal(
                      eventDetail?.isActive
                        ? MODAL_TYPE_DETAIL.ACTIVE_EVENT.type
                        : MODAL_TYPE_DETAIL.INACTIVE_EVENT.type
                    )
                  }
                />
              </div>
            </div> ) )}
          </div>
          <div className='page-inner-box profile-steps-box'>
            <div className="content-section">
              <div className="content-label">Event ID</div>
              <div className="content-des">{eventDetail.eventId}</div>
            </div>
            <div className="content-section">
              <div className="content-label">Event Name</div>
              <div className="content-des">{eventDetail.eventName}</div>
            </div>
            <div className="content-section">
              <div className="content-label">Units</div>
              <div className="content-des">{eventDetail.unitName}</div>
            </div>
            { eventDetail.partnerName && (
              <div className="content-section">
                <div className="content-label">Partner Name</div>
                <div className="content-des">{eventDetail.partnerName}</div>
              </div>
            )}
            <div className="content-section">
              <div className="content-label">Description</div>
              <div className="content-des">{eventDetail.description}</div>
            </div>
          </div>
        </div>
      </div>
      <EventDisableModal
        isOpened={isOpened}
        onClose={handleCloseModal}
        modalData={modalData}
        eventData={eventData}
        onConfirm={handleConfirm}
      />
    </>
  );
};

ViewEvent.propTypes = {
  eventDetail: PropTypes.object,
  type: PropTypes.string,
};

export default ViewEvent;
