import adminConstant from "../../util/adminConstant";
import constant from "../../util/constant";
import {
  ADMIN_FILTER_LIST_SUCCESS,
  ADMIN_FILTER_LIST_FAILURE,
  ADMIN_FILTER_LIST_API_REQUEST,
  ADMIN_FILTER_LIST_API_END
} from "../actionTypes";

const { API_STATUS } = constant;

const DEFAULT_STATE = {
  message: "",
  filterData: { eventNameList: [], eventUnitList: [], partnerList: [], eventIdList: [], offerList: [], gameList: [], tournamentList: []
    , gameOfferList: [], gameTournamentList: [] },
  status: "",
  apiType: "",
};

const moveObjToTop = ( arrayOfObj, key, val ) => {
  const index = arrayOfObj.findIndex( obj => obj.value === val );

  if( index > 0 ) {
    return [ arrayOfObj[ index ], ...arrayOfObj.filter( x => x[ key ] !== val ) ];
  }

  return arrayOfObj;
};

export const adminFilterList = ( state = DEFAULT_STATE, action = "" ) => {
  switch ( action.type ) {
    case ADMIN_FILTER_LIST_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
      };
    case ADMIN_FILTER_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
      };
    case ADMIN_FILTER_LIST_SUCCESS:
      return {
        ...state,
        filterData: {
          eventIdList: action.payload.eventIdList,
          eventNameList: action.payload.eventNameList,
          eventUnitList: action.payload.eventUnitList,
          partnerList: moveObjToTop( action.payload.partnerList, "value", adminConstant.QX_ADMIN_ID ),
          gameList: action.payload.gameList,
          offerList: action.payload.offerList,
          tournamentList: action.payload.tournamentList,
          gameOfferList: action.payload.gameOfferList,
          gameTournamentList: action.payload.gameTournamentList },
        status: API_STATUS.SUCCESS,
      };
    case ADMIN_FILTER_LIST_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    default:
      return state;
  }
};

export default adminFilterList;
