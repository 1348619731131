import { API_URL } from "../util/apiPaths";
import { request } from "./api.js";

/* Add event */
export function addEvent( params ) {
  return request( "POST", API_URL.PARTNER_EVENTS, params );
}

/* Get partner's event list */
export function getPartnerEvents( params ) {
  return request( "GET", API_URL.PARTNER_EVENTS, params );
}

/* Update partner's event */
export function updatePartnerEvent( params ) {
  return request( "PUT", API_URL.PARTNER_EVENTS, params );
}

/* Get partner's event detail */
export function getPartnerEventDetail( params ) {
  return request( "GET", `${ API_URL.PARTNER_EVENTS }/${ params }` );
}

export const partnerGameServices = {
  addEvent,
  getPartnerEvents,
  updatePartnerEvent,
  getPartnerEventDetail,
};
