import { convertDate, formatNumberAsPerLocale, currencyFormat } from "../util/helpers";

export class AdminRedeemTransactionDetail {
  constructor( obj ) {
    this.transactionId = ( obj.redeem_request_id ) ? ( obj.tx_id ) : "N/A";
    this.redeemRequestId = obj.redeem_request_id;
    this.fullName = obj.User.fullName || "N/A";
    this.acQyrCashSold = formatNumberAsPerLocale( obj.acQyrCash_points_sold, 0 );
    this.redeemedFee = obj.redeemedFee || 0;
    this.amount = obj.amount || 0;
    this.status = obj.payment_vendor_tx_status;
    this.whenRequested = convertDate( obj.whenRequested ) || "";
    this.cashbackName = obj.cashbackMethod || "";
    this.iconUrl = obj.iconUrl || "";
    this.netRedeemAmount = currencyFormat( { data: obj.net_amount_USD || 0 } );
    this.emailIdOrPhone = ( obj.redeem_request_id ) ? ( obj.emailIdOrPhone ) : "N/A";
    this.reasonForRejection = obj.reason_for_rejection;
  }
}
