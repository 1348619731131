import PropTypes from "prop-types";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import adminConstant from "../../../util/adminConstant";

const { MODAL_TYPE_DETAIL } = adminConstant;

function EventDisableModal( props ) {
  let msg = "Enable";

  if( props?.modalData === MODAL_TYPE_DETAIL.ACTIVE_EVENT.type ) {
    msg = "Disable";
  }

  const eventData = props?.eventData;
  const [ offerListToggle, setOfferListToggle ] = useState( { offerList: false, action: "show" } );

  const toggle = ( type ) => {
    if( type === "offers" ) {
      if( offerListToggle.offerList === false ) {
        setOfferListToggle( { offerList: "show-all", action: "hide" } );
      }
      else {
        setOfferListToggle( { offerList: false, action: "show" } );
      }
    }
  };

  return (
    <>
      <Modal
        show={props?.isOpened}
        centered
        className='event-disable-modal'
      >
        <Modal.Header>
          <h2 className='modal-heading'>{msg} an Event?</h2>
        </Modal.Header>
        <Modal.Body className='event-modal'>
          <p>Once you {msg.toLocaleLowerCase()} this event the associated offers will also be {msg.toLocaleLowerCase()}d.</p>

          <div className="heading-with-button">
            <h4>Offers ({eventData?.offerListCount})</h4>
            <a href="javascript:void(0)" title={offerListToggle?.action}
              className="link-text" onClick={() => toggle( "offers" )}>
              {eventData?.offerListCount > 0 && offerListToggle.action}</a>
          </div>
          <ul className={`game-list scrollbar small ${ offerListToggle?.offerList }`}>
            {eventData?.offerList?.map( ( offer, i ) => (
              <li key={i}>
                <span className="game-name">{offer.offerName} ({offer.gameName}) </span>
              </li>
            ) )}
          </ul>

        </Modal.Body>
        <Modal.Footer>

          <Button
            variant='outline-primary'
            className='btn-md'
            onClick={() =>
              props.onConfirm( props.modalData )
            }
          >
            Confirm
          </Button>
          <Button
            variant='primary'
            className='btn-md'
            onClick={() => props.onClose( )}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

EventDisableModal.propTypes = {
  eventData: PropTypes.object,
  modalData: PropTypes.string,
  isOpened: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default EventDisableModal;
