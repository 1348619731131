import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useAddQuery, useFilter, useForm, useScroll } from "../../../hooks";
import { getPartnerGamerListAction } from "../../../store/actions/partnerGamer";
import constant from "../../../util/constant";
import { useSetUrlParams, checkFilterApplied } from "../../../util/helpers";
import partnerGamerConstant from "../../../util/partnerGamerConstant";
import { partnerGamerTableConfigs } from "../../../util/tableConstant";
import FilterIcon from "../../shared/FilterIcon/FilterIcon";
import CustomSlider from "../../shared/customSlider";
import GamerListSection from "../../shared/gamer/gamerListSection";
import MultiSelectCheckbox from "../../shared/multiSelectCheckbox";

const { API_STATUS, ORDER_BY, FILTERS_NAME, BRANDING: { APP_SHORT_NAME } } = constant;
const { API_TYPES } = partnerGamerConstant;

const PartnerGamerList = () => {
  const fromPartner = true;
  const { addQuery } = useAddQuery();
  const dispatch = useDispatch();
  const { scrollEvent } = useScroll();
  const { filteredData, filterData, setPureData } = useFilter();
  const { apiType, gamerLists, gamerNames, linkedGamesRange, status, qxPointsRange } = useSelector( state => ( {
    apiType: state.partnerGamer.apiType,
    gamerLists: state.partnerGamer.gamerLists,
    linkedGamesRange: state.partnerGamer.linkedGamesRange,
    status: state.partnerGamer.status,
    qxPointsRange: state.partnerGamer.qxPointsRange,
    gamerNames: state.partnerGamer.gamerNames,
  } ) );
  const { values, handleChange } = useForm(
    {
      sortBy: "fullNames",
      sortOrder: ORDER_BY.ASC,
      searchKey: "",
      fullNames: [],
      searchCol: [ "fullNames" ],
      compareKeyArray: [ "acqyrCashBalance", "linkedGamesCount", "fullNames" ],
      linkedGames: [],
      showModal: false,
      minQxPoint: 0,
      maxQxPoint: 0,
      minLinkedGames: 0,
      maxLinkedGames: 0,
    },
    {}
  );
  const requestParams = Object.assign(
    {
      isSingleDate: true,
      searchCol: values.searchCol,
      sortOrder: values.sortOrder,
      sortBy: values.sortBy,
      searchKey: values.searchKey,
      fullNames: values.fullNames?.map( value => value.value ) || [],
      compareKeyArray: values.compareKeyArray,
      minQxPoint: values.minQxPoint,
      maxQxPoint: values.maxQxPoint,
      minLinkedGames: values.minLinkedGames,
      maxLinkedGames: values.maxLinkedGames,
    }
  );

  useEffect( () => {
    dispatch( getPartnerGamerListAction( requestParams ) );
  }, [] );
  useEffect( () => {
    if(
      ( status === API_STATUS.SUCCESS ) &&
      ( apiType === API_TYPES.GET_GAMER_LISTS )
    ) {
      dispatch( getPartnerGamerListAction( requestParams ) );
      handleChange( "minQxPoint", qxPointsRange.min );
      handleChange( "maxQxPoint", qxPointsRange.max );
      handleChange( "minLinkedGames", linkedGamesRange.min );
      handleChange( "maxLinkedGames", linkedGamesRange.max );
      setPureData( gamerLists );
      updateStateFromUrl();
      filterData( requestParams );
    }
  }, [ status, apiType ] );

  const updateStateFromUrl = () => {
    const paramsQuery = new URLSearchParams( window.location.search );

    if( paramsQuery.get( "sortColumn" ) && paramsQuery.get( "sortOrder" ) ) {
      handleChange( "sortBy", paramsQuery.get( "sortColumn" ) );
      handleChange( "sortOrder", paramsQuery.get( "sortOrder" ) );
    }

    if( paramsQuery.get( "searchKey" ) ) {
      handleChange( "searchKey", paramsQuery.get( "searchKey" ) );
    }

    if( paramsQuery.get( "fullNames" ) ) {
      handleChange( "fullNames", JSON.parse( paramsQuery.get( "fullNames" ) ) );
    }

    if( paramsQuery.get( "minQxPoint" ) ) {
      handleChange( "minQxPoint", JSON.parse( paramsQuery.get( "minQxPoint" ) ) );
    }

    if( paramsQuery.get( "maxQxPoint" ) ) {
      handleChange( "maxQxPoint", JSON.parse( paramsQuery.get( "maxQxPoint" ) ) );
    }

    if( paramsQuery.get( "minLinkedGames" ) ) {
      handleChange( "minLinkedGames", JSON.parse( paramsQuery.get( "minLinkedGames" ) ) );
    }

    if( paramsQuery.get( "maxLinkedGames" ) ) {
      handleChange( "maxLinkedGames", JSON.parse( paramsQuery.get( "maxLinkedGames" ) ) );
    }
  };

  useEffect( () => {
    filterData( requestParams );
  }, [
    values.sortOrder,
    values.sortBy,
    values.searchKey,
    values.minQxPoint,
    values.maxQxPoint,
    values.minLinkedGames,
    values.maxLinkedGames,
    values.fullNames,
  ] );

  const sliderMinMaxObj = {
    minLinkedGames: linkedGamesRange.min,
    maxLinkedGames: linkedGamesRange.max,
    minQxPoint: qxPointsRange.min,
    maxQxPoint: qxPointsRange.max,
  };
  const isFilterApplied = checkFilterApplied( window.location.search, FILTERS_NAME.ADMIN_GAMERS, sliderMinMaxObj );

  const searchHandle = ( e ) => {
    addQuery( useSetUrlParams( "searchKey", e.target.value ) );
    handleChange( "searchKey", e.target.value );
  };

  const handleSorting = ( sortBy, sortingType ) => {
    handleChange( "sortBy", sortBy );
    handleChange( "sortOrder", sortingType );
    addQuery( useSetUrlParams( "sortColumn", sortBy ) );
    addQuery( useSetUrlParams( "sortOrder", sortingType ) );
  };

  const onScroll = ( e ) => {
    scrollEvent( e, gamerLists, values, handleChange, "page" );
  };

  const memoizedTableConfig = useMemo( () => {
    return partnerGamerTableConfigs;
  }, [] );
  const memoizedHandleClick = useCallback(
    ( sortBy, orderType ) => handleSorting( sortBy, orderType ),
    []
  );

  // @param {obj} is the array of selected filter items
  const handleList = ( obj, listName ) => {
    if( obj?.length ) {
      handleChange( listName, obj );
      addQuery( useSetUrlParams( listName, JSON.stringify( obj ) ) );
    }
    else {
      addQuery( useSetUrlParams( listName, "" ) );
      handleChange( listName );
    }
  };

  const handleClear = ( parmas ) => {
    if( parmas ) {
      addQuery( useSetUrlParams( parmas, "" ) );
      handleChange( parmas, [] );
    }
  };

  const multiSelectedItems = useMemo( () => [ values.fullNames ] );
  const handleSelectChange = useCallback(
    [ obj => handleList( obj, "fullNames" ) ],
    []
  );
  const handleClearMultiSelect = useCallback(
    parmas => handleClear( parmas ), []
  );

  const closeModal = () => {
    handleChange( "showModal", false );
  };

  const viewAllGames = ( linkedGames ) => {
    handleChange( "linkedGames", linkedGames );
    handleChange( "showModal", true );
  };

  const handleLinkedGames = ( e, newValue ) => {
    addQuery( useSetUrlParams( "minLinkedGames", newValue[ 0 ] ) );
    addQuery( useSetUrlParams( "maxLinkedGames", newValue[ 1 ] ) );
    handleChange( "minLinkedGames", newValue[ 0 ] );
    handleChange( "maxLinkedGames", newValue[ 1 ] );
  };

  const handleQxPoints = ( e, newValue ) => {
    addQuery( useSetUrlParams( "minQxPoint", newValue[ 0 ] ) );
    addQuery( useSetUrlParams( "maxQxPoint", newValue[ 1 ] ) );
    handleChange( "minQxPoint", newValue[ 0 ] );
    handleChange( "maxQxPoint", newValue[ 1 ] );
  };

  const [ showFilter, setShowFilter ] = useState( false );
  const showFilterScreen = () => setShowFilter( !showFilter );

  return (
    <>
      <div className="main-wrapper">
        <div className="main-right-wrapper">
          <div className="page-head-wrapper">
            <h4 className="page-heading">Gamers</h4>
            <div className="page-head-right">
              <Form.Group className="custom-search w-300 mr-0">
                <span className="icon-search"></span>
                <Form.Control
                  type="text"
                  placeholder="Search by Name"
                  data-testid='searchKey'
                  onChange={searchHandle}
                  value={values.searchKey} />
              </Form.Group>
              <FilterIcon
                showFilter={showFilter}
                showFilterScreen={showFilterScreen}
                isFilterApplied={isFilterApplied} />
            </div>
          </div>
          <div className='filter-tabs-wrapper'>
            <div className='filter-boxes offer-filter mr-0'>
              <div className={`filter-boxes mr-0 ${ !showFilter ? "" : "remove-filter" }`}>
                <Row>
                  <Col md={4}>
                    <Form.Group className='custom-multiselect mb-0' data-testid="full-name">
                      <Form.Label>Name</Form.Label>
                      <MultiSelectCheckbox
                        multiOptionsList={gamerNames}
                        handleSelectChange={handleSelectChange[ 0 ]}
                        multiSelectedItems={multiSelectedItems[ 0 ]}
                        handleClearMultiSelect={() => handleClearMultiSelect( "fullNames" )} />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className='custom-multiselect mb-0'>
                      <CustomSlider
                        values={values}
                        pointsHandler={handleLinkedGames}
                        handleChange={handleChange}
                        min={linkedGamesRange.min}
                        max={linkedGamesRange.max}
                        minName={"minLinkedGames"}
                        maxName={"maxLinkedGames"}
                        label={"No. of Linked Games"}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className='custom-multiselect mb-0'>
                      <CustomSlider
                        values={values}
                        pointsHandler={handleQxPoints}
                        handleChange={handleChange}
                        min={qxPointsRange.min}
                        max={qxPointsRange.max}
                        minName={"minQxPoint"}
                        maxName={"maxQxPoint"}
                        label={`${ APP_SHORT_NAME } Points`}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <GamerListSection
            values={values}
            memoizedTableConfig={memoizedTableConfig}
            memoizedHandleClick={memoizedHandleClick}
            gamers={filteredData}
            onScroll={onScroll}
            viewAllGames={viewAllGames}
            closeModal={closeModal}
            showFilter={showFilter}
            fromPartner={fromPartner}
          />
        </div>
      </div>
    </>
  );
};

export default PartnerGamerList;
