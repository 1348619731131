import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Modal,
  Row,
  Form
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useForm } from "../../../hooks";
import {
  updateEmailAddressAction
} from "../../../store/actions/emailUpdate";
import constant from "../../../util/constant";
import emailUpdateConstant from "../../../util/emailUpdateConstant";

const { API_STATUS } = constant;
const { EMAIL_UPDATE_API_TYPES } = emailUpdateConstant;

const initialState = {
  verificationCode: "",
  oldEmail: "",
  email: "",
  showModal: false,
  showModalVerification: false,
};
const initialDirty = {
  email: false,
  verificationCode: false,
};

// New email reset option address component.
const EmailReset = ( props ) => {
  const dispatch = useDispatch();
  const { apiType, status } = useSelector( state => ( {
    apiType: state?.emailUpdate?.apiType,
    status: state?.emailUpdate?.status,
  } ) );
  const [ dummyEmail, setdummyEmail ] = useState( null );
  const { values, errors, isError, handleChange, showAllErrorMessage, reset } = useForm( initialState, initialDirty );

  const handleInputChange = ( e ) => {
    const { name, value } = e.target;

    handleChange( name, value.replace( /\s/g, "" ) );
  };

  const handleUpdateEmail = () => {
    if( props.email ) {
      handleChange( "verificationCode", 12345 );
      handleChange( "showModal", true );
    }
  };

  const closeModal = ( param ) => {
    reset();
    handleChange( param, false );
    handleChange( "oldEmail", values.oldEmail ? values.oldEmail : props.email );
  };

  // After confirm old email otp will be sent to new email address.
  const confirmEmail = () => {
    showAllErrorMessage();

    if( values.email && !isError ) {
      handleChange( "showModal", false );
      dispatch( { type: "START_LOADER" } );
      Auth.currentAuthenticatedUser().then( ( user ) => {
        return Auth.updateUserAttributes( user, { email: values.email } );
      } )
        .then( () => {
          setdummyEmail( values.email );
          reset();
          handleChange( "email", "dummy@yopmail.com" );
          dispatch( { type: "STOP_LOADER" } );
          handleChange( "showModalVerification", true );
          handleChange( "oldEmail", values.oldEmail ? values.oldEmail : props.email );
        } )
        .catch( ( err ) => {
          reset();
          toastr.error( err.message );
          dispatch( { type: "STOP_LOADER" } );
        } );
    }
  };

  useEffect( () => {
    if( ( status === API_STATUS.SUCCESS ) &&
      ( apiType === EMAIL_UPDATE_API_TYPES.EMAIL_UPDATE ) ) {
      handleChange( "oldEmail", dummyEmail );
      dispatch( { type: "STOP_LOADER" } );
      toastr.success( "Your email address changed successfully" );
    }
  }, [ apiType, status ] );

  //  Verifiying otp
  const confirmVerification = () => {
    showAllErrorMessage();

    const verificationCode = values.verificationCode.trim();

    if( verificationCode && !isError ) {
      dispatch( { type: "START_LOADER" } );
      Auth.verifyCurrentUserAttributeSubmit( "email", verificationCode ).then( () => {
        reset();
        handleChange( "showModalVerification", false );
        // eslint-disable-next-line camelcase
        dispatch( updateEmailAddressAction( { email_address: dummyEmail, roleType: props.roleType } ) );
      } )
        .catch( ( err ) => {
          toastr.error( err.message );
          dispatch( { type: "STOP_LOADER" } );
        } );
    }
  };

  return (
    <>
      <Form>
        <Row>
          <Col sm={12}>
            <div className="content-section mb-20">
              <div className="content-label bold-small-font">Account Details</div>
              <div className="divider m-0"></div>
            </div>
          </Col>
          <Col sm={8}>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <div className='form-with-button'>
                <Form.Control
                  data-testid='emailupdate'
                  type='text'
                  name='oldEmail'
                  value={values.oldEmail ? values.oldEmail : props.email}
                  disabled="true"
                  maxLength={45}/>
                <Button variant="primary btn-password-toggle" onClick={() => handleUpdateEmail()}>
                Change
                </Button>
              </div>
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <Modal
        show={values.showModal}
        onHide={() => closeModal}
        centered
        className='delete-modal'
      >
        <Modal.Header className='pb-0'>
          <h2 className='modal-new-title'>Change Email Address</h2>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form>
              <Row>
                <Col sm={12}>
                  <Form.Group className="mb-0">
                    <Form.Label>New Email Address</Form.Label>
                    <Form.Control
                      data-testid='newEmail'
                      type='text'
                      name='email'
                      onChange={handleInputChange}
                      value={values.email}
                      maxLength={45}/>
                    <div className='invalid-feedback d-block'>
                      {errors.email}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </Form>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='outline-secondary'
            className='btn-md'
            onClick={() => closeModal( "showModal" )}
          >
            Cancel
          </Button>
          <Button variant="primary" className='btn-md' onClick={confirmEmail}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={values.showModalVerification}
        onHide={closeModal}
        centered
        className='delete-modal'
      >
        <Modal.Header className='pb-0'>
          <h2 className='modal-new-title'>Verification Code</h2>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p className='mb-4'>We have sent a verification code by email to
              <span className="text-green">{dummyEmail}</span>.
               Enter it below to confirm your updated email address.
            </p>
            <Form>
              <Row>
                <Col sm={12}>
                  <Form.Group className="mb-0">
                    <Form.Label>Verification Code</Form.Label>
                    <Form.Control
                      data-testid='verification'
                      type='text'
                      name='verificationCode'
                      onChange={handleInputChange}
                      value={values.verificationCode}
                      maxLength={8}
                      placeholder='Verification Code'
                    />
                    <div className='invalid-feedback d-block'>
                      {errors.verificationCode}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='outline-secondary'
            className='btn-md'
            onClick={() => closeModal( "showModalVerification" )}
          >
            Cancel
          </Button>
          <Button variant="primary" className='btn-md' onClick={confirmVerification}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

EmailReset.propTypes = {
  email: PropTypes.string,
  roleType: PropTypes.string,
};

export default EmailReset;
