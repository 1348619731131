import VisibilityIcon from "@material-ui/icons/Visibility";
import PropTypes from "prop-types";
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { linkIcon } from "../../../util/icons";
import { ScrollPage } from "../../shared/customScrollBar";
import ToolTipError from "./toolTipError";

const GamerGameList = ( {
  values,
  gamerDetail,
  gamerGameList,
  handleViewPage,
} ) => {
  return (
    <>
      <Scrollbars renderThumbVertical={ScrollPage} className={`track-horizontal small-height ${ !values.viewType ? "track-horizontal-list-small" : "" }`}>
        { gamerGameList?.length > 0
          ? (
            <div className={`games-grid-wrapper no-scrollbar ${ !values.viewType ? "games-list-wrapper" : "" }`}
            >
              {gamerGameList.map( game => (
                <div className="col-games-grid-box fixed-width" key={game.gameId} >
                  <div className="games-grid-box gamer-box grid-only cursor-pointer" onClick={() => handleViewPage( game.gameId, "gameTab" )}>
                    <div className="games-image-box">
                      <img src={game.iconUrl} alt="Game Icon"/>
                    </div>
                    <div className="games-grid-box-body">
                      <strong>{game.name}</strong>
                      <span className="games-anchor">
                        <img src={linkIcon} alt='link-icon' />
                      </span>
                    </div>
                  </div>
                  <div className="table-body gamer-game-body list-only">
                    <div className="table-row">
                      <div className="td">
                        <img src={game.iconUrl} alt="Game Icon" className='game-image' />
                        <strong>{game.name}</strong>
                      </div>
                      <div className="td">
                        {game.dateLinked}
                      </div>
                      <div className="td">
                        <span className="link cursor-pointer" title={game.offersCount} onClick={() => handleViewPage( game.gameId, "offersTab" )}>{game.offersCount} Offers</span>
                      </div>
                      <div className="td">
                        {!gamerDetail.serverErrorsGameName && (
                          <span className="link cursor-pointer" title={game.assetsCount} onClick={() => handleViewPage( game.gameId, "assetsTab" )}>{game.assetsCount} Assets</span>
                        )}
                        {gamerDetail.serverErrorsGameName && (
                          <span className="big-text-common ml-3">{"--"}</span>
                        )}
                        {gamerDetail.serverErrorsGameName && (
                          <ToolTipError message='Error in fetching assets for this game.'/>
                        )}
                      </div>
                      <div className="td">
                        <div
                          className='icon cursor-pointer'
                        >
                          <VisibilityIcon onClick={() => handleViewPage( game.gameId, "gameTab" )}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) )}
            </div> )
          : (
            <div className='no-record' data-testid='no-record-found'>No Linked Games.</div>
          )}
      </Scrollbars>
    </>
  );
};

GamerGameList.propTypes = {
  values: PropTypes.object,
  gamerDetail: PropTypes.object,
  gamerGameList: PropTypes.array,
  handleViewPage: PropTypes.func,
};

export default GamerGameList;
