import { convertDate } from "../util/helpers";

export class RewardTxn {
  constructor( obj ) {
    this.gameName = obj?.gameName;
    this.offerName = obj?.offerName;
    this.partnerName = obj?.partnerName;
    this.QXPoints = obj.qxPoints;
    this.transactionDate = convertDate( obj.transactionDate ) || "";
    this.date = obj.transactionDate;
    this.myPlace = obj?.remarks ? parseInt( obj?.remarks, 10 ) : 0;
  }
}
