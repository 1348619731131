import PropTypes from "prop-types";
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";

// Local-imports.
import { ScrollPage } from "../../shared/customScrollBar";
import ViewAllGamesModal from "../../shared/games/viewAllGamesModal";
import TableHeaderRow from "../../shared/tableHeadingRow";
import Gamer from "./gamer";

const GamerListSection = ( {
  values,
  memoizedTableConfig,
  memoizedHandleClick,
  gamers,
  onScroll,
  viewAllGames,
  closeModal,
  showFilter,
} ) => {
  return (
    <>
      <div className="table-container four-column">
        <div className="table-head">
          <div className="table-row">
            <TableHeaderRow
              configData={memoizedTableConfig}
              onAscOrder={memoizedHandleClick}
              onDescOrder={memoizedHandleClick}
            />
          </div>
        </div>
        <Scrollbars renderThumbVertical={ScrollPage}
          className={`custom-scroll-height ${ !showFilter ? "" : "full-height" }`}
          onScroll={onScroll}
        >
          <div className="table-body no-scrollbar" >
            {Boolean( gamers?.length ) && gamers?.map( ( gamer, index ) => (
              <div className="table-row" key={index}>
                <Gamer gamer={gamer} viewAllGames={viewAllGames} />
              </div>
            ) )}
            {!gamers?.length && (
              <div className='no-record' data-testid='no-record-found'>
                No Gamer Found
              </div>
            )}
          </div>
        </Scrollbars>
      </div>
      <ViewAllGamesModal
        values={values}
        closeModal={closeModal}
      />
    </>
  );
};

GamerListSection.propTypes = {
  values: PropTypes.object,
  memoizedTableConfig: PropTypes.object,
  memoizedHandleClick: PropTypes.func,
  gamers: PropTypes.array,
  onScroll: PropTypes.func,
  viewAllGames: PropTypes.func,
  closeModal: PropTypes.func,
  id: PropTypes.string,
  action: PropTypes.string,
  showFilter: PropTypes.string,
  fromPartner: PropTypes.bool,
};

export default GamerListSection;
