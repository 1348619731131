import moment from "moment";
import Enums from "../util/Enums";
import constant from "../util/constant";
import {
  convertDate,
  convertActualTime,
  convertMarkDownToDraft,
  convertTimeStampToTime,
  convertHtmlToEditorHtml
} from "../util/helpers";
import offerConstant from "../util/offerConstant";

const { ADVERTISE_OPTIONS, OPT_IN_STATUS } = constant;
const { FREEMIUM_MEMBERSHIP_ID } = Enums;
const { RANKING_OBJECTIVE } = offerConstant;

// Used to get the offer opt-in type when edit offer.
// If the offer have opt-in 'Fee' then it will return the 'Yes' because the 'Fee' is the same as 'Yes' with entry fee.
const getOptInType = ( optInType ) => {
  if( optInType === OPT_IN_STATUS.FEE ) {
    return OPT_IN_STATUS.YES;
  }

  return optInType;
};


// Format start and end time of scheduled offer/tournament in edit and view operation.
const formatScheduledTimeOnViewEdit = ( scheduledTime, isViewing ) => {
  let formattedTime = "";

  if( scheduledTime ) {
    formattedTime = isViewing ? convertTimeStampToTime( scheduledTime ) : new Date( scheduledTime );
  }

  return formattedTime;
};

export class OfferDraftDetail {
  // TODO: refactor to avoid complexity
  // eslint-disable-next-line complexity
  constructor( obj ) {
    this.entityName = obj.entityName;
    this.offerTemplate = obj.template || "";
    this.offerName = obj.name || "";
    this.qxPoint = obj.reward.qx_points;
    this.descriptionText = obj.description_text || "";
    this.rawDescription = obj.description || "";
    this.isOffer = obj.isOffer;
    this.partnerId = obj.partnerId;
    this.entityId = obj.entityId || "";
    this.description = convertHtmlToEditorHtml( convertMarkDownToDraft( obj.description ) );
    this.isAvailableOfferSchedule = obj.offerSchedule ? true : false;
    this.achievements = obj.achievements?.map( ( achievement ) => {
      const { fieldTime: { d, h, m, s } } = convertActualTime( { seconds: achievement.time_limit_as_seconds } );

      return {
        event: achievement.event,
        name: achievement.name,
        descriptionText: achievement.description_text,
        rawDescription: achievement.description,
        description: convertHtmlToEditorHtml( convertMarkDownToDraft( achievement.description ) ),
        value: achievement.value,
        ranking: RANKING_OBJECTIVE.find( rank => rank.value === achievement?.ranking_objective ),
        rankingObjective: achievement?.ranking_objective,
        function: achievement.function,
        comparison: achievement.comparison,
        days: d,
        hours: h,
        minutes: m,
        seconds: s,
        eventUnit: achievement.eventUnit,
        eventDescription: achievement.eventDescription,
      };
    } ) || [ {
      event: { label: "", value: "" },
      name: "",
      descriptionText: "",
      rawDescription: "",
      description: "",
      value: "",
      function: "",
      comparison: "",
      days: "",
      hours: "",
      minutes: "",
      seconds: "",
      eventUnit: "",
      eventDescription: "" } ];
    this.isActive = obj.is_active || "";
    this.rejectionReason = obj.rejection_reason || "";
    this.approvalStatus = obj.approval_status || "";
    this.statusTitle = obj.approval_status;
    this.offerSchedule = obj.offerSchedule
      ? {
        maxRewardCount: obj.offerSchedule?.max_reward_count_per_user,
        startDateForValue: obj.offerSchedule?.start_date,
        endDateForValue: obj.offerSchedule?.end_date,
        selectedGame: obj.offerSchedule?.game,
        gameName: obj.offerSchedule?.game?.label,
        gameUrl: obj.offerSchedule?.game?.gameIconUrl || "",
        selectedAudience: obj.offerSchedule?.gamers,
        targetAudience: obj.offerSchedule?.gamers?.label || "",
        startDate: obj.offerSchedule?.start_date ? convertDate( obj.offerSchedule?.start_date ) : "",
        endDate: obj.offerSchedule?.end_date ? convertDate( obj.offerSchedule?.end_date ) : "",
        startTime: formatScheduledTimeOnViewEdit( obj.offerSchedule?.start_time, obj?.isViewDraft ),
        endTime: formatScheduledTimeOnViewEdit( obj.offerSchedule?.end_time, obj?.isViewDraft ),
        timeZone: obj.offerSchedule?.timeZone ?? {},
      }
      : null;
    this.cloneOfferName = obj.cloneOfferName || "";
    this.cloneOfferId = obj.cloneOfferId || "";

    if( obj.isViewEditTournament ) {
      this.rewards = obj.reward.map( ( reward, index ) => {
        return {
          qxPoint: reward.qx_points,
          place: index + 1,
        };
      } );
      this.isTournament = obj.isTournament;
    }

    this.hideOfferSchedule = obj?.offerSchedule ? !Object.values( obj.offerSchedule ).filter( item => item ).length : false;
    this.advertiseToGamer = obj?.advertise_to_gamer ? ADVERTISE_OPTIONS.find( option => option.value === obj.advertise_to_gamer ) : ADVERTISE_OPTIONS[ 0 ];
    this.advertiseStatus = obj.advertise_to_gamer;
    this.selectedAdvertiseDate = obj.advertise_date ? convertDate( obj.advertise_date ) : "";
    this.advertiseDate = obj.advertise_date ? moment( obj.advertise_date ).format( "YYYY-MM-DD" ) : false;
    this.selectedAdvertise = ADVERTISE_OPTIONS.find( option => option.value === obj.advertise_to_gamer )?.label || "";
    this.noOfDays = obj.noOfDays;
    this.draftContent = obj;
    this.optIn = {
      optInType: getOptInType( obj.optIn?.optInType ),
      membershipId: obj.optIn?.membershipId || FREEMIUM_MEMBERSHIP_ID,
      entryFee: Number( obj.optIn?.entryFee ),
      minParticipants: obj.optIn?.minParticipants,
      maxParticipants: obj.optIn?.maxParticipants,
      minParticipantsNoLimit: obj.optIn?.minParticipants === null,
      maxParticipantsNoLimit: obj.optIn?.maxParticipants === null,
    };
  }
}
