import PropTypes from "prop-types";
import React from "react";
import { Form } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { getEligibleSubscriptionPlans } from "../../../util/helpers";
import offerConstant from "../../../util/offerConstant";
import tournamentConstant from "../../../util/tournamentConstant";
import Leaderboard from "./leaderboard";
import OfferData from "./offerData";

const { TABS } = tournamentConstant;
const { OFFER_DETAIL_STATUS, OFFER_TYPE_STATUS } = offerConstant;

const OfferDetail = ( {
  offerDetail,
  configuration,
  partnerCostUsdPerQxPoint,
  isTournament = false,
  activeTab,
  onChangeTab,
  offerId,
  gamerId,
  isAdmin,
  entityType,
  offerType,
} ) => {
  return (
    <>
      {offerDetail.approvalStatus === offerConstant.OFFER_DETAIL_STATUS.REJECTED && (
        <>
          <div className='white-container pink-border'>
            <h4 className='page-inner-heading'>Reason for Rejection <span className='badge badge-rejected'>{offerDetail.statusTitle.title}</span></h4>
            <div className='content-des'>
              {offerDetail.rejectionReason}
            </div>
          </div>
        </>
      )}
      <div className="page-inner-box profile-steps-box">
        <Form>
          {!isTournament && <h4 className="page-inner-heading">Offer Details</h4>}
          {isTournament && <Nav
            variant="tabs"
            defaultActiveKey="link-1"
            className="bold-tabs"
          >
            <Nav.Item>
              <Nav.Link
                className="pt-0"
                active={activeTab === TABS.TOURNAMENT_DETAIL }
                onClick={() => onChangeTab( TABS.TOURNAMENT_DETAIL )}
              >
                  Tournament Details
              </Nav.Link>
            </Nav.Item>
            { ( offerType !== OFFER_TYPE_STATUS.DRAFT ) && !( [
              OFFER_DETAIL_STATUS.REJECTED,
              OFFER_DETAIL_STATUS.IN_REVIEW,
              OFFER_DETAIL_STATUS.OFFER_TEMPLATE,
            ].includes( offerDetail.approvalStatus ) ) && (
              <Nav.Item>
                <Nav.Link
                  className="pt-0"
                  active={activeTab === TABS.LEADER_BOARD}
                  onClick={() => onChangeTab( TABS.LEADER_BOARD )}
                >
                  Leader Board
                </Nav.Link>
              </Nav.Item>
            )}
          </Nav>}
          {( ( activeTab === TABS.TOURNAMENT_DETAIL ) || ( !isTournament ) ) && (
            <OfferData
              offerDetail={offerDetail}
              configuration={configuration}
              partnerCostUsdPerQxPoint={partnerCostUsdPerQxPoint}
              isTournament={isTournament}
              eligibleSubPlans={getEligibleSubscriptionPlans( offerDetail?.optIn?.membershipId, configuration.membershipTypes )}
              isAdmin={isAdmin}
            />
          )}
          {activeTab === TABS.LEADER_BOARD &&
            <Leaderboard
              tournamentId={offerId}
              isAdmin={isAdmin}
              entityType={entityType}
              gamerId={gamerId}
            />
          }
        </Form>
      </div>
    </>
  );
};

OfferDetail.propTypes = {
  offerDetail: PropTypes.object,
  configuration: PropTypes.object,
  partnerCostUsdPerQxPoint: PropTypes.number,
  isTournament: PropTypes.bool,
  activeTab: PropTypes.string,
  onChangeTab: PropTypes.func,
  offerId: PropTypes.string,
  isAdmin: PropTypes.bool,
  entityType: PropTypes.string,
  gamerId: PropTypes.string,
  offerType: PropTypes.string,
};

export default OfferDetail;
