import PropTypes from "prop-types";
import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import SelectBox from "../selectBox";

const ContactInformation = ( { values, errors, handleInputChange, onBlur, handleSelectInput } ) => {
  return (
    <>
      <div className='page-inner-box profile-steps-box'>
        <Form>
          <Row>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>DBA (Optional)</Form.Label>
                <Form.Control
                  type='text'
                  value={values?.dba}
                  name='dba'
                  onChange={handleInputChange}
                  maxLength={45}
                  data-testid='dba'
                />
              </Form.Group>
            </Col>
          </Row>
          <h4 className='page-inner-heading'>Business Location Address</h4>
          <Row>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Address Line 1</Form.Label>
                <Form.Control
                  type='text'
                  value={values?.address1}
                  name='address1'
                  onChange={handleInputChange}
                  maxLength={255}
                  data-testid='address1'
                />
                <div className='invalid-feedback d-block'>
                  {errors?.address1}
                </div>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Address Line 2 (Optional)</Form.Label>
                <Form.Control
                  type='text'
                  value={values?.address2}
                  name='address2'
                  onChange={handleInputChange}
                  maxLength={255}
                  data-testid='address2'
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Country</Form.Label>
                <SelectBox
                  options={values.countryList}
                  placeholder='Select Country'
                  onChange={value => handleSelectInput( "country", value )}
                  value={values.country}
                />
                <div className='invalid-feedback d-block'>
                  {errors?.country}
                </div>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>State</Form.Label>
                <SelectBox
                  options={values.stateList}
                  placeholder='Select State'
                  onChange={value => handleSelectInput( "state", value )}
                  value={values.state}
                />
                <div className='invalid-feedback d-block'>
                  {errors?.state}
                </div>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>City</Form.Label>
                <SelectBox
                  options={values.cityList}
                  placeholder='Select City'
                  onChange={value => handleSelectInput( "city", value )}
                  value={values.city}
                />
                <div className='invalid-feedback d-block'>
                  {errors?.city}
                </div>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Zipcode</Form.Label>
                <Form.Control
                  type='text'
                  value={values?.zip}
                  name='zip'
                  onChange={handleInputChange}
                  maxLength={45}
                  data-testid='zip'
                />
                <div className='invalid-feedback d-block'>{errors?.zip}</div>
              </Form.Group>
            </Col>
          </Row>
          <h4 className='page-inner-heading'>
                Primary Contact Information
          </h4>
          <Row>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type='text'
                  value={values?.primaryName}
                  name='primaryName'
                  onChange={handleInputChange}
                  maxLength={50}
                />
                <div className='invalid-feedback d-block'>
                  {errors?.primaryName}
                </div>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type='text'
                  value={values?.primaryTitle}
                  name='primaryTitle'
                  onChange={handleInputChange}
                  maxLength={50}
                />
                <div className='invalid-feedback d-block'>
                  {errors?.primaryTitle}
                </div>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type='text'
                  value={values?.primaryPhoneNumber}
                  name='primaryPhoneNumber'
                  onChange={handleInputChange}
                  maxLength={14}
                />
                <div className='invalid-feedback d-block'>
                  {errors?.primaryPhoneNumber}
                </div>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Alternative Phone Number (Optional)</Form.Label>
                <Form.Control
                  type='text'
                  value={values?.primaryAlternativePhoneNumber}
                  name='primaryAlternativePhoneNumber'
                  onChange={handleInputChange}
                  maxLength={14}
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type='email'
                  value={values?.primaryEmail}
                  name='primaryEmail'
                  onChange={handleInputChange}
                  onBlur={onBlur}
                />
                <div className='invalid-feedback d-block'>
                  {errors?.primaryEmail}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <h4 className='page-inner-heading'>
                Secondary Contact Information
          </h4>
          <Row>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type='text'
                  value={values?.secondaryName}
                  name='secondaryName'
                  onChange={handleInputChange}
                  maxLength={50}
                />
                <div className='invalid-feedback d-block'>
                  {errors?.secondaryName}
                </div>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type='text'
                  value={values?.secondaryTitle}
                  name='secondaryTitle'
                  onChange={handleInputChange}
                  maxLength={50}
                />
                <div className='invalid-feedback d-block'>
                  {errors?.secondaryTitle}
                </div>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type='text'
                  value={values?.secondaryPhoneNumber}
                  name='secondaryPhoneNumber'
                  onChange={handleInputChange}
                  maxLength={14}
                />
                <div className='invalid-feedback d-block'>
                  {errors?.secondaryPhoneNumber}
                </div>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Alternative Phone Number (Optional)</Form.Label>
                <Form.Control
                  type='text'
                  value={values?.secondaryAlternativePhoneNumber}
                  name='secondaryAlternativePhoneNumber'
                  onChange={handleInputChange}
                  maxLength={14}
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type='email'
                  value={values?.secondaryEmail}
                  name='secondaryEmail'
                  onChange={handleInputChange}
                  onBlur={onBlur}
                />
                <div className='invalid-feedback d-block'>
                  {errors?.secondaryEmail}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <h4 className='page-inner-heading'>Authorized Signatory</h4>
          <Row>
            <Col sm={6}>
              <Form.Group className='last-form-group'>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  disabled
                  type='text'
                  value={values?.authorizedName}
                  name='authorizedName'
                  onChange={handleInputChange}
                  maxLength={45}
                />
                <div className='invalid-feedback d-block'>
                  {errors?.authorizedName}
                </div>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group className='last-form-group'>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  disabled
                  type='text'
                  value={values?.authorizedTitle}
                  name='authorizedTitle'
                  onChange={handleInputChange}
                  maxLength={45}
                />
                <div className='invalid-feedback d-block'>
                  {errors?.authorizedTitle}
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

ContactInformation.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  handleInputChange: PropTypes.func,
  onBlur: PropTypes.func,
  handleSelectInput: PropTypes.func,
};

export default ContactInformation;
