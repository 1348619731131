import { sortBy } from "lodash";
import constant from "../util/constant";
import { convertDate } from "../util/helpers";
import offerConstant from "../util/offerConstant";

const handleNullUndefinedValue = ( data, name ) => data[ name ] || "";
const handleDraftNullUndefinedValue = data => data?.Draft?.draft_content || {};

const { OFFER_STATUS, TYPE_TO_BE_EDITED } = offerConstant;
const { OPT_IN_STATUS } = constant;

export class DraftToOffer {
  rewardArray = obj => obj?.reward?.map( ( reward ) => {
    return {
      place: reward.place,
      rewardValue: reward.qx_points,
    };
  } ) || [];

  constructor( obj ) {
    this.createdAt = obj.createdAt
      ? convertDate( obj.createdAt )
      : null;
    this.updatedAt = obj.updatedAt
      ? convertDate( obj.updatedAt )
      : null;
    this.description = handleNullUndefinedValue( obj, "description" );
    this.offerId = handleNullUndefinedValue( obj, "offer_id" );
    this.offerName = handleNullUndefinedValue( obj, "name" );
    this.partnerId = handleNullUndefinedValue( obj, "partner_id" );
    this.descriptionText = handleNullUndefinedValue( obj, "description_text" );
    this.parentTemplateId = handleNullUndefinedValue( obj, "parent_template_id" );
    this.rejectionReason = handleNullUndefinedValue( obj, "rejection_reason" );
    this.approvalStatus = handleNullUndefinedValue( obj, "approval_status" );
    this.offerType = handleNullUndefinedValue( obj, "offer_type" );
    this.isActive = handleNullUndefinedValue( obj, "is_active" );

    this.gameName = handleDraftNullUndefinedValue( obj )?.offerSchedule?.game?.label || "";
    this.gameId = handleDraftNullUndefinedValue( obj )?.offerSchedule?.game_id || "";
    this.statusTitle = OFFER_STATUS.find(
      offer => offer.status === obj.approval_status
    );
    this.startDate = handleDraftNullUndefinedValue( obj )?.offerSchedule?.start_date
      ? convertDate( obj.Draft.draft_content.offerSchedule.start_date )
      : null;
    this.endDate = handleDraftNullUndefinedValue( obj )?.offerSchedule?.end_date
      ? convertDate( obj.Draft.draft_content.offerSchedule.end_date )
      : null;
    this.startDateOriginal = handleDraftNullUndefinedValue( obj )?.offerSchedule?.start_date
      ? new Date( obj.Draft.draft_content.offerSchedule.start_date ).toLocaleDateString( "fr-CA" )
      : "";
    this.endDateOriginal = handleDraftNullUndefinedValue( obj )?.offerSchedule?.end_date
      ? new Date( obj.Draft.draft_content.offerSchedule.end_date ).toLocaleDateString( "fr-CA" )
      : "";
    this.gameUrl = handleDraftNullUndefinedValue( obj )?.offerSchedule?.game?.gameIconUrl || "";
    this.maxRewardCount = handleDraftNullUndefinedValue( obj )?.max_reward_count_per_user || "0";
    this.reward = handleDraftNullUndefinedValue( obj )?.reward?.qx_points || "";
    this.draftContent = handleDraftNullUndefinedValue( obj );
    this.entityName = obj?.Draft?.entity_name || "";
    this.entityId = obj?.Draft?.entity_id || "";
    this.partnerName = obj?.Partner?.legal_entity_name || "";
    this.optIn = obj?.Draft?.draft_content?.optIn;
    this.optInDetail = {
      optIn: this.optIn?.optInType || OPT_IN_STATUS.NO,
      membershipId: this.optIn?.membershipId,
      entryFee: this.optIn?.entryFee || "",
      minParticipants: this.optIn?.minParticipants,
      maxParticipants: this.optIn?.maxParticipants,
    };
    this.optInType = this.optIn?.optInType || OPT_IN_STATUS.NO;

    if( obj.offer_type === TYPE_TO_BE_EDITED.TOURNAMENT_DRAFT ) {
      this.rewards = sortBy( this.rewardArray( obj?.Draft?.draft_content ), reward => reward?.place );
    }
  }
}
