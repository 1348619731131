import PropTypes from "prop-types";
import React from "react";

const ScrollPage = ( { style, ...props } ) => {
  const thumbStyle = {
    backgroundColor: `rgba(${ Math.round( 97 ) }, ${ Math.round( 163 ) }, ${ Math.round( 81 ) }, 0.5)`,
    borderRadius: "inherit",
  };

  return (
    <div
      style={{ ...style, ...thumbStyle }}
      {...props}/>
  );
};

ScrollPage.propTypes = {
  style: PropTypes.func,
};

export default ScrollPage;
