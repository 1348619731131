import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { useAddQuery, useForm, useFilter, getUrlData } from "../../../../hooks";
import { getAnalyticsLinkedGamersAction } from "../../../../store/actions/partnerAnalytics";
import analyticsConstant from "../../../../util/analyticsConstant";
import constant from "../../../../util/constant";
import { analyticsLinkedGamersTableConfigs } from "../../../../util/tableConstant";
import { ScrollPage } from "../../../shared/customScrollBar";
import ViewAllGamesModal from "../../../shared/games/viewAllGamesModal";
import TableHeaderRow from "../../../shared/tableHeadingRow";
import LinkedGamersFilters from "./linkedGamersFilters";
import LinkedGamersList from "./linkedGamersList";

const { API_STATUS } = constant;
const { API_TYPES } = analyticsConstant;

const LinkedGamers = ( {
  showFilter,
  searchKey = "",
  setPreviousData,
} ) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { addQuery } = useAddQuery();
  const {
    filterData,
    setPureData,
    filteredData,
    handleSorting,
    handleFilterChange,
    clearSelectedFilter } = useFilter();
  const {
    linkedGamersList,
    gamerNames,
    gamerPlans,
    linkedGamerGamesRange,
    apiType,
    status,
  } = useSelector( state => ( {
    linkedGamersList: state.partnerAnalytics.linkedGamersList,
    gamerNames: state.partnerAnalytics.gamerNames,
    gamerPlans: state.partnerAnalytics.gamerPlans,
    linkedGamerGamesRange: state.partnerAnalytics.linkedGamerGamesRange,
    apiType: state.partnerAnalytics.apiType,
    status: state.partnerAnalytics.status,
  } ) );
  const { values, handleChange } = useForm(
    {
      searchKey: "",
      sortBy: "fullNames",
      sortOrder: "ASC",
      gamerNames: [],
      minLinkedGames: 0,
      maxLinkedGames: 0,
      gamerPlans: [],
      searchCol: [ "fullNames" ],
      compareKeyArray: [ "fullNames", "linkedGamesCount" ],
      linkedGames: [],
      showModal: false,
    },
    {}
  );

  const requestParams = Object.assign(
    {
      searchCol: values.searchCol,
      sortOrder: values.sortOrder,
      sortBy: values.sortBy,
      searchKey: values.searchKey,
      gamerNames: values.gamerNames?.map( value => value.value ) || [],
      minLinkedGames: values.minLinkedGames,
      maxLinkedGames: values.maxLinkedGames,
      gamerPlans: values.gamerPlans?.map( value => value.value ) || [],
      compareKeyArray: values.compareKeyArray,
    }
  );

  const memoizedTableConfig = useMemo( () => {
    return analyticsLinkedGamersTableConfigs;
  }, [] );

  const memoizedHandleClick = useCallback(
    ( sortBy, orderType ) => handleSorting( sortBy, orderType, handleChange, addQuery, location.state ),
    []
  );

  const viewAllGames = ( linkedGames ) => {
    handleChange( "linkedGames", linkedGames );
    handleChange( "showModal", true );
  };

  const closeModal = () => {
    handleChange( "showModal", false );
  };

  useEffect( () => {
    dispatch( getAnalyticsLinkedGamersAction( requestParams ) );
  }, [] );

  useEffect( () => {
    handleChange( "searchKey", searchKey );
  }, [ searchKey ] );

  useEffect( () => {
    if(
      status === API_STATUS.SUCCESS &&
      apiType === API_TYPES.GET_ANALYTICS_LINKED_GAMERS
    ) {
      handleChange( "minLinkedGames", linkedGamerGamesRange.min );
      handleChange( "maxLinkedGames", linkedGamerGamesRange.max );
      setPureData( linkedGamersList );
      getUrlData( handleChange );
      setPreviousData( false );
    }
  }, [ status, apiType ] );

  useEffect( () => {
    filterData( requestParams );
  }, [
    values.sortOrder,
    values.sortBy,
    values.searchKey,
    values.gamerNames,
    values.minLinkedGames,
    values.maxLinkedGames,
    values.gamerPlans,
  ] );

  return (
    <>
      <div className='filter-tabs-wrapper mb-0'>
        <LinkedGamersFilters
          values={values}
          handleChange={handleChange}
          addQuery={addQuery}
          gamerNames={gamerNames}
          showFilter={showFilter}
          locationState={location.state}
          gamerPlans={gamerPlans}
          linkedGamerGamesRange={linkedGamerGamesRange}
          handleFilterChange={handleFilterChange}
          clearSelectedFilter={clearSelectedFilter}
        />
      </div>
      <div className="table-container partner-analytics-gamers">
        <div className="table-head">
          <div className="table-row">
            <TableHeaderRow
              configData={memoizedTableConfig}
              onAscOrder={memoizedHandleClick}
              onDescOrder={memoizedHandleClick}
            />
          </div>
        </div>
        <Scrollbars renderThumbVertical={ScrollPage}
          className={`custom-scroll-height medium ${ !showFilter ? "" : "full-height" }`}
        >
          <LinkedGamersList
            linkedGamersList={filteredData}
            location={location}
            viewAllGames={viewAllGames}
          />
        </Scrollbars>
      </div>
      <ViewAllGamesModal
        values={values}
        closeModal={closeModal}
      />
    </>
  );
};

LinkedGamers.propTypes = {
  searchKey: PropTypes.string,
  setPreviousData: PropTypes.func,
  showFilter: PropTypes.string,
};

export default LinkedGamers;
