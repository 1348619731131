import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { useModal, useForm } from "../../../hooks";
import { getAdminOfferDraftDetailAction } from "../../../store/actions/adminOfferDraft";
import {
  getAdminOfferDetailsAction,
  rejectOfferRequestAction,
  approvalOfferRequestAction,
  enableDisableOfferAction
} from "../../../store/actions/adminOffers";
import adminConstant from "../../../util/adminConstant";
import constant from "../../../util/constant";
import { getBackNavigationRoute } from "../../../util/helpers";
import offerConstant from "../../../util/offerConstant";
import BackButton from "../../shared/backButton";
import { OfferDetail } from "../../shared/offers";
import OfferViewActions from "../../shared/offers/offerViewActions";
import ModalPopup from "../modal";

const { API_STATUS, ROUTES, BUSINESS_ENTITY } = constant;
const { MODAL_TYPE_DETAIL } = adminConstant;
const { OFFER_API_TYPES, OFFER_TYPE_STATUS } = offerConstant;

const initialState = {
  rejectionReason: "",
};

const AdminTournamentDetail = () => {
  const { gamerId } = useParams();
  const { tournamentId: selectedTournamentId } = useParams();
  const { 0: tournamentId } = String( selectedTournamentId ).split( "?" );
  const dispatch = useDispatch();
  const location = useLocation();
  const backScreens = [ "tournaments", "gamerTournamentList" ];
  const fromScreen = new URLSearchParams( window.location.search ).get( "from" );
  const { offerDetails, apiType, status, msg, configuration, adminDetail } = useSelector( state => ( {
    offerDetails: state.adminOffer.offerDetails,
    apiType: state.adminOffer.apiType,
    status: state.adminOffer.status,
    msg: state.adminOffer.message,
    adminDetail: state.adminDetail.data,
    configuration: state.adminDetail.data?.configuration || {},
  } ) );
  const history = useHistory();
  const [ errorMessage, setErrorMessage ] = useState( "" );
  const [ activeTab, setTab ] = useState( "" );
  const { isOpened, closeModal, openModal, modalType, setModalType } =
    useModal();
  const { values, handleChange } = useForm( initialState, {} );
  const paramsQuery = new URLSearchParams( window.location.search );
  const offerType = paramsQuery.get( "status" );
  const tab = paramsQuery.get( "tab" );
  // We fetch entityType from 2 different routes called from 2 different components.
  const [ , , entityType ] = location.pathname.split( "/" );

  useEffect( () => {
    setTab( tab );
  }, [ tab ] );

  /**
   * @description This function is used to handle tab change event.
   * @param {string} selectedTab - selected tab name on tournament and leaderboard.
   * @param {string} entityType - Entity type used to identify the page used for tournament detail or gamer tournament detail.
   * Component used for tournament detail and gamer tournament detail differentiate by entity type.
   * @returns {void}
   **/
  const handleChangeTab = ( selectedTab ) => {
    if( entityType === "tournamentDetail" ) {
      history.replace( {
        pathname: `${
          ROUTES.ADMIN_TOURNAMENT_DETAILS.replace( ":tournamentId", tournamentId )
        }?tab=${ selectedTab }&from=tournaments`,
        state: location.state,
      } );
    }
    // For entityType - gamer(call from gamer tournaments).
    else {
      history.replace( {
        pathname: `${
          ROUTES.ADMIN_GAMER_TOURNAMENT_DETAILS
            .replace( ":tournamentId", tournamentId )
            .replace( ":gamerId", gamerId )
        }?tab=${ selectedTab }`,
        state: location.state,
      } );
    }
  };

  const handleOpenModal = ( mType ) => {
    setModalType( mType );
    openModal();
  };

  const handleCloseModal = () => {
    closeModal();
    setErrorMessage();
    handleChange( "rejectionReason", "" );
  };

  const handleInputChange = ( e ) => {
    const { name, value } = e.target;

    handleChange( name, value );

    if( errorMessage ) {
      setErrorMessage( "" );
    }
  };

  const handleReject = () => {
    const { rejectionReason } = values;
    const requestObj = {
      offerId: tournamentId,
    };

    if( modalType === MODAL_TYPE_DETAIL.REASON_REJECTION.type ) {
      if( rejectionReason ) {
        handleCloseModal();
        // eslint-disable-next-line camelcase
        requestObj.rejection_reason = values.rejectionReason;
        dispatch( rejectOfferRequestAction( requestObj ) );
      }
      else {
        setErrorMessage( "Please enter reason for rejection" );
      }
    }
    else if( modalType === MODAL_TYPE_DETAIL.APPROVE_TOURNAMENT.type ) {
      closeModal();
      dispatch( approvalOfferRequestAction( requestObj ) );
    }
    else if( ( modalType === MODAL_TYPE_DETAIL.ACTIVE_OFFER.type ) || ( modalType === MODAL_TYPE_DETAIL.INACTIVE_OFFER.type ) ) {
      dispatch( enableDisableOfferAction( { ...requestObj, ...{ setEnabled: modalType === MODAL_TYPE_DETAIL.ACTIVE_OFFER.type } } ) );
    }
    else if( ( modalType === MODAL_TYPE_DETAIL.ACTIVE_TOURNAMENT.type ) || ( modalType === MODAL_TYPE_DETAIL.INACTIVE_TOURNAMENT.type ) ) {
      dispatch( enableDisableOfferAction( { ...requestObj, ...{ setEnabled: modalType === MODAL_TYPE_DETAIL.ACTIVE_TOURNAMENT.type } } ) );
    }
    else {
      closeModal();
    }
  };

  useEffect( () => {
    if( tournamentId ) {
      if( offerType === OFFER_TYPE_STATUS.DRAFT ) {
        dispatch( getAdminOfferDraftDetailAction( tournamentId, true ) );
      }
      else {
        dispatch( getAdminOfferDetailsAction( tournamentId ) );
      }
    }
  }, [ tournamentId, msg ] );
  useEffect( () => {
    if(
      status === API_STATUS.SUCCESS &&
      apiType === OFFER_API_TYPES.REJECT_OFFER_REQUEST
    ) {
      toastr.success( "Tournament rejected successfully" );
      history.goBack();
    }
    else if(
      status === API_STATUS.SUCCESS &&
      apiType === OFFER_API_TYPES.APPROVE_OFFER_REQUEST
    ) {
      toastr.success( "Tournament approved successfully" );
      history.goBack();
    }
    else if(
      status === API_STATUS.SUCCESS &&
      apiType === OFFER_API_TYPES.ENABLE_DISABLE_PARTNER_OFFER
    ) {
      handleCloseModal();
      toastr.success( msg.message.replace( "Offer", "Tournament" ) );
    }
  }, [ status, apiType, msg ] );

  const handleBackNavigation = () => {
    return getBackNavigationRoute( ROUTES.ADMIN_TOURNAMENTS_LIST, location, backScreens );
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="main-right-wrapper">
          <BackButton
            backLink={handleBackNavigation()}
            state={fromScreen === "tournaments" ? "" : location.state}
          />
          <OfferViewActions
            adminDetail={adminDetail}
            offerDetails={offerDetails}
            handleOpenModal={handleOpenModal}
            businessEntity={BUSINESS_ENTITY.TOURNAMENT}
          />
          <OfferDetail
            offerDetail={offerDetails}
            configuration={configuration}
            partnerCostUsdPerQxPoint={offerDetails.partnerCostUsdPerQxPoint}
            isTournament={true}
            onChangeTab={handleChangeTab}
            activeTab={activeTab}
            offerId={tournamentId}
            entityType={entityType}
            gamerId={gamerId}
            offerType={offerType}
            isAdmin
          />
        </div>
      </div>
      <ModalPopup
        isOpened={isOpened}
        onClose={handleCloseModal}
        type={modalType}
        values={values}
        onChangeInput={handleInputChange}
        errorMessage={errorMessage}
        onConfirm={handleReject}
      />
    </>
  );
};

export default AdminTournamentDetail;
