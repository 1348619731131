export default {
  NUMERIC_VALUE: {
    ZERO: 0,
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    SIX: 6,
    SEVEN: 7,
    ELEVEN: 11,
    HUNDRED: 100,
    MAX_DAYS: 365,
    MAX_HOURS: 100,
    MAX_MINUTES: 1000,
    MAX_SECONDS: 1000,
    MAX_QX_POINTS: 100000,
  },
  LEADER_BOARD_TOP_PLACES: {
    FIRST_PLACE: 1,
    SECOND_PLACE: 2,
    THIRD_PLACE: 3,
    FIRST_ROW_MIN_PLACE: 3,
    FIRST_ROW_MAX_PLACE: 7,
    SECOND_ROW_MIN_PLACE: 6,
    SECOND_ROW_MAX_PLACE: 11,
  },
  SERVER_ERROR: 500,
  DEBOUNCE_WAIT_TIME_MS: 1000,
  LINKED_GAMES_COUNT: {
    MIN: 0,
    MAX: 10,
  },
  ACQYR_CASH_BALANCE: {
    MIN: 0,
    MAX: 100,
  },
  PER_PAGE_RECORDS_LIMIT: {
    MIN: 10,
    MAX: 1000,
  },
  TIME_PICKER_INTERVAL_MINUTES: 60,
  DEFAULT_RADIX: 10,
  DATA_VIEW_TYPE: {
    LIST: 0,
    GRID: 1,
  },
  FREEMIUM_MEMBERSHIP_ID: "1",
  OPT_IN_PARTICIPANTS_MIN_VALUE: 1,
  OPT_IN_PARTICIPANTS_MAX_VALUE: 1000000,
  OPT_IN_ENTRY_FEE_MIN_VALUE: 0,
  OPT_IN_ENTRY_FEE_MAX_VALUE: 100000,
  ACHIEVEMENT_DESCRIPTION_MAX_LENGTH: 500,
  MAX_TIMES_EARNED: 100,
  DEFAULT_USD_PER_QX_POINT: 0.01,
  DEFAULT_PARTNER_COST_USD_PER_QX_POINT: 0.012,
};
