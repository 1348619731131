import { SET_CURRENT_USER } from "../actionTypes";

const DEFAULT_STATE = {
  user: {},
};

export default ( state = DEFAULT_STATE, action = "" ) => {
  if( action && ( action.type === SET_CURRENT_USER ) ) {
    return {
      user: action.user,
    };
  }

  return state;
};

