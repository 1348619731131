import HelpIcon from "@material-ui/icons/Help";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useHistory, useLocation, Link } from "react-router-dom";

//local-imports
import { useAddQuery, useForm } from "../../../hooks";
import analyticsConstant from "../../../util/analyticsConstant";
import constant from "../../../util/constant";
import { formatNumberAsPerLocale, useSetUrlParams, getRemainingDays } from "../../../util/helpers";
import offerConstant from "../../../util/offerConstant";
import AssetValueInfo from "../../shared/asset/assetValueInfo";
import Markdown from "../markDown";

const { ROUTES, BRANDING: { APP_SHORT_NAME }, BUSINESS_ENTITY } = constant;
const { ANALYTICS_TAB: { EXCHANGE_TRANSACTION, OFFER_REWARD_TXN, TOURNAMENT_REWARD_TXN } } = analyticsConstant;
const { TOURNAMENT_STATUS_DROPDOWN, OFFER_DETAIL_STATUS } = offerConstant;

const ViewPartnerGameDetail = ( { viewType, gameDetails, gamerId, usdPerQxPoint, setTab,
  entityType, partnerCostUsdPerQxPoint } ) => {
  const history = useHistory();
  const location = useLocation();
  const { addQuery } = useAddQuery();
  const paramsQuery = new URLSearchParams( window.location.search );
  const activeTab = paramsQuery.get( "subTab" ) ? paramsQuery.get( "subTab" ) : entityType;
  const { values, handleChange } = useForm(
    {
      showModal: false,
      rewards: [],
    }, {}
  );

  const closeModal = () => {
    handleChange( "showModal", false );
  };

  useEffect( () => {
    selectedTab( activeTab );
  }, [ activeTab ] );

  const redirectToView = ( value ) => {
    const route = `${ ROUTES.ANALYTIC_VIEW_GAME.replace( ":gameId", gameDetails?.gameId ) }`;

    history.push( {
      pathname: `${ route }?tab=${ value }`,
      state: location.state,
    } );
  };

  const selectedTab = ( val ) => {
    addQuery( useSetUrlParams( "subTab", val ), location.state );
    setTab( val );
  };

  const viewAllRewards = ( e, rewards ) => {
    e.stopPropagation();
    handleChange( "rewards", rewards );
    handleChange( "showModal", true );
  };

  const isOfferExpired = ( offerStatusTitle ) => {
    return offerStatusTitle === TOURNAMENT_STATUS_DROPDOWN.find( x => x.value.toString() === OFFER_DETAIL_STATUS.EXPIRED )?.label;
  };

  return (
    <>
      {viewType && (
        <div>
          <div className="image-with-content">
            <div className="image-section">
              <img src={gameDetails.gameIcon} alt="Game icon" />
            </div>
            <div className="content-section mb-0">
              <div className="content-top">
                <div className="content-title">Game Name</div>
                <div className="content-text">{gameDetails.gameName}</div>
              </div>
              {Boolean( gamerId ) && <div className="content-right game-content-right">
                <div className="content-title">
                  { APP_SHORT_NAME } Game ID
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        Game id to be used by your game server when
                        communicating with the { APP_SHORT_NAME } Server.
                      </Tooltip>
                    }
                  >
                    <HelpIcon className="help-icon" />
                  </OverlayTrigger>
                </div>
                <div className="content-text">{gameDetails.gameId}</div>
              </div>}
              <div className="content-bottom">
                <div className="content-title">Download URL</div>
                {gameDetails.downloadUrls?.map( url => (
                  <a
                    key={url}
                    className="content-text url-link mb-0"
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {url}
                  </a>
                ) )}
              </div>
            </div>
          </div>
          {Boolean( gameDetails?.apiUrls?.length ) && <div className="content-bottom mb-4">
            <div className="content-title">
              Game Server URLs{" "}
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-disabled">
                    The urls that the { APP_SHORT_NAME } Server will use to contact the game
                    server for this game.
                  </Tooltip>
                }
              >
                <HelpIcon className="help-icon" />
              </OverlayTrigger>
            </div>
            {gameDetails?.apiUrls?.map( url => (
              <span key={url} className="content-text">
                {url}
              </span>
            ) )}
          </div>}
          <div className="content-title">Description</div>
          <div className="content-text pb-3 inner-content">
            <Markdown text={gameDetails.description}></Markdown>
          </div>
          {Boolean( gameDetails.exchangeableAssets?.length ) && (
            <>
              <div className="content-title">Exchangeable Assets</div>
              {gameDetails.exchangeableAssets?.map( item => (
                <Row key={item.name}>
                  <Col sm={2}>
                    <div className="content-section">
                      <div className="content-label">Asset Name</div>
                      <div className="content-des">{item.name}</div>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content-section">
                      <div className="content-label">Price</div>
                      <div className="content-des">
                        {formatNumberAsPerLocale( item.price )} { APP_SHORT_NAME } Points
                      </div>
                      <AssetValueInfo
                        qxPoints={formatNumberAsPerLocale( item.price )}
                        usdPerQxPoint={usdPerQxPoint}
                        partnerCostUsdPerQxPoint={partnerCostUsdPerQxPoint}
                      />
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="content-section">
                      <div className="content-label">{ APP_SHORT_NAME } Asset ID</div>
                      <div className="content-des">{item.gameAssetId}</div>
                    </div>
                  </Col>
                </Row>
              ) )}
            </>
          )}
          <>
            <Row className="game-tour-detail">
              <Col sm={4}>
                <div className="content-section">
                  <div className="content-label">{gameDetails.gameStatistics?.LinkedGamers}</div>
                  <div className="content-des">Linked Gamers</div>
                </div>
              </Col>
              <Col sm={8}>
                <div className="content-section">
                  <div className="content-label">{gameDetails.gameStatistics?.PremiumGamers}</div>
                  <div className="content-des">Premium Gamers</div>
                </div>
              </Col>
              <Col sm={4}>
                <div className="content-section">
                  <div className="content-label">{formatNumberAsPerLocale( gameDetails.gameStatistics?.OfferRewardValue )}
                    <span> { APP_SHORT_NAME } Points</span>
                  </div>
                  <div className="content-des">
                    <Link
                      onClick={() => redirectToView( OFFER_REWARD_TXN )}
                      className="link-text underline"
                      title="Rewarded Value (Offers)"
                    >
                      Rewarded Value (Offers)
                    </Link>
                  </div>
                </div>
              </Col>
              <Col sm={4}>
                <div className="content-section">
                  <div className="content-label">{formatNumberAsPerLocale( gameDetails.gameStatistics?.TournamentRewardValue )}
                    <span> { APP_SHORT_NAME } Points</span>
                  </div>
                  <div className="content-des">
                    <Link
                      onClick={() => redirectToView( TOURNAMENT_REWARD_TXN )}
                      className="link-text underline"
                      title="Rewarded Value (Tournaments)"
                    >
                      Rewarded Value (Tournaments)
                    </Link>
                  </div>
                </div>
              </Col>
              <Col sm={4}>
                <div className="content-section">
                  <div className="content-label">{formatNumberAsPerLocale( gameDetails.gameStatistics?.AssetExchangedValue )}
                    <span> { APP_SHORT_NAME } Points</span>
                  </div>
                  <div className="content-des">
                    <Link
                      onClick={() => redirectToView( EXCHANGE_TRANSACTION )}
                      className="link-text underline"
                      title="Assets Exchanged Value"
                    >
                      Assets Exchanged Value
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </>
          <Nav
            variant="tabs"
            defaultActiveKey="link-1"
            className="bold-tabs"
          >
            <Nav.Item>
              <Nav.Link
                className="pt-0"
                active={activeTab === BUSINESS_ENTITY.OFFER }
                onClick={() => selectedTab( BUSINESS_ENTITY.OFFER )}
              >
                  Offers
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className="pt-0"
                active={activeTab === BUSINESS_ENTITY.TOURNAMENT }
                onClick={() => selectedTab( BUSINESS_ENTITY.TOURNAMENT )}
              >
                  Tournaments
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <div className="games-grid-wrapper large no-scrollbar">
            {/* repeat this div */}
            {Boolean( gameDetails?.associatedOffers?.length ) &&
                gameDetails.associatedOffers.map( ( offer, index ) => (
                  <a
                    onClick={() => {
                      history.push( {
                        pathname: ROUTES.PARTNER_OFFER_DETAIL.replace( ":offerId", offer.offerId ),
                        state: [ ...location.state,
                          ...[ { from: "analyticsGameDetail", path: location.pathname, state: location.search } ],
                        ],
                      } );
                    } }
                    className="col-games-grid-box width-50"
                    key={index}
                  >
                    <div className="offer-detail-box border-no-shadow">
                      <div className="offer-detail-box-top">
                        <div className="image-box">
                          <img src={gameDetails.gameIcon} alt="games" />
                        </div>
                        <div className="offer-detail-box-top-body">
                          <div className="left-side">
                            <strong>{offer.offerName}</strong>
                            {offer?.statusTitle?.title === TOURNAMENT_STATUS_DROPDOWN.find( x => x.value.toString() === OFFER_DETAIL_STATUS.CURRENT )?.label
                              ? (
                                <p className='text-green'>
                                  Expires {getRemainingDays( offer.endDate ) === 0 ? "today" : `in ${ getRemainingDays( offer.endDate ) } days`}
                                </p>
                              )
                              : (
                                <p>
                                  {offer.startDate ?? "N/A"} - {offer.endDate ?? "N/A"}
                                </p>
                              )}
                            <span
                              className={`badge ${ offer?.statusTitle?.class }`}
                            >
                              {offer?.statusTitle?.title}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-4 mb-3">
                        <div className="games-tags-list-wrapper my-0">
                          <span className="game-name">Games: </span>
                          {gameDetails?.gameName
                            ? (
                              <div className="games-tags-list">
                                <div className="games-tags">
                                  <div className="image-box">
                                    {gameDetails.gameIcon && (
                                      <img
                                        src={gameDetails.gameIcon}
                                        alt="User Icon"
                                      />
                                    )}
                                  </div>
                                  <span>{gameDetails.gameName}</span>
                                </div>
                              </div>
                            )
                            : (
                              <div className="games-desc-text">
                                {" "}
                                <p>No game assigned</p>{" "}
                              </div>
                            )}
                        </div>
                        {/* TODO: remove the use nested ternary, which are difficult to follow */}
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {activeTab === BUSINESS_ENTITY.TOURNAMENT && offer?.rewards
                          ? (
                            <div className="list-content">
                              {[ ...offer.rewards ].splice( 0, 2 ).map( ( reward, i ) => (
                                <div className="text-block" key={i}>
                                  <span>{reward.place}</span>
                                  <span>{formatNumberAsPerLocale( reward.qxPoint )}</span>
                                  <span>{APP_SHORT_NAME} points</span>
                                </div>
                              ) )}
                              {offer.rewards.length > 2 && (
                                <div className="text-block text-right">
                                  <Link
                                    className="link"
                                    title="Show All"
                                    onClick={e => viewAllRewards( e, offer.rewards )}
                                  >
                                Show All
                                  </Link>
                                </div>
                              )}
                            </div>
                          )
                          : offer.rewardValue
                            ? (
                              <div className="games-desc-text text-right">
                                <strong className="mb-0">Reward:</strong>
                                <p>{offer.rewardValue} {APP_SHORT_NAME} points</p>
                              </div>
                            )
                            : "N/A"}
                      </div>
                      <div className="games-desc-text mb-3">
                        <strong>Description:</strong>
                        <p className="text-truncate">{offer.descriptionText}</p>
                      </div>
                      <Row className="game-tour-detail pb-0">
                        <Col sm={4}>
                          <div className="content-section mb-0">
                            <div className="content-label">{formatNumberAsPerLocale( offer.offerStatistics?.participants )}
                            </div>
                            <div className="content-des">
                              <Link
                                className="link-text underline"
                                title="Participants"
                                onClick={e => e.stopPropagation()}
                              >
                          Participants
                              </Link>
                            </div>
                          </div>

                        </Col>
                        <Col sm={4}>
                          <div className={`content-section mb-0 ${ !isOfferExpired( offer?.statusTitle?.title ) ? "disabled" : "" }` }>
                            <div className="content-label">
                              { !isOfferExpired( offer?.statusTitle?.title )
                                ? "--"
                                : formatNumberAsPerLocale( offer?.offerStatistics?.winners )}
                            </div>
                            <div className="content-des">
                              Winners
                            </div>
                          </div>
                        </Col>
                        <Col sm={4}>
                          <div className="content-section mb-0">
                            <div className="content-label">{formatNumberAsPerLocale( offer.offerStatistics?.rewardValueQxPoints )}
                            </div>
                            <div className="content-des">
                          Rewarded {APP_SHORT_NAME} points
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </a>
                ) )}
            {!gameDetails?.associatedOffers?.length && (
              <div className='no-record' data-testid='no-record-found'>
                  No {entityType} Found
              </div>
            )}
          </div>
        </div>
      )}
      <Modal
        show={values.showModal}
        onHide={closeModal}
        centered
        className='gamer-cashback-modal'
      >
        <Modal.Header>
          <h2 className='modal-heading'>Rewards</h2>
        </Modal.Header>
        <Modal.Body>
          <div className='gamer-game-list scrollbar'>
            {( Boolean( values?.rewards?.length ) ) && values.rewards?.map( ( data, index ) => (
              <div key={index} className='list-items rewards-list-items'>
                <span className='game-count'>{index + 1}</span>
                <span className='game-name'>{data.qxPoint} { APP_SHORT_NAME } Points</span>
              </div>
            ) )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className='btn-md' onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ViewPartnerGameDetail.propTypes = {
  match: PropTypes.string,
  props: PropTypes.object,
  viewType: PropTypes.bool,
  gamerId: PropTypes.string,
  gameId: PropTypes.string,
  gameDetails: PropTypes.object,
  usdPerQxPoint: PropTypes.number,
  tab: PropTypes.bool,
  partnerCostUsdPerQxPoint: PropTypes.number,
  setTab: PropTypes.func,
  entityType: PropTypes.string,
};

export default ViewPartnerGameDetail;
