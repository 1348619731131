const handleNullUndefined = ( data, name ) => data[ name ] || "";

export class PartnerDetail {
  constructor( obj ) {
    this.joinReasonId = handleNullUndefined( obj, "join_reason" );
    this.categoryId = handleNullUndefined( obj, "category_id" );
    this.logo = handleNullUndefined( obj, "icon_url" );
    this.legalEntityName = handleNullUndefined( obj, "legal_entity_name" );
    this.dba = handleNullUndefined( obj, "dba" );
    this.address1 = handleNullUndefined( obj, "business_location_add1" );
    this.address2 = handleNullUndefined( obj, "business_location_add2" );
    this.country = handleNullUndefined( obj, "country_code" );
    this.state = handleNullUndefined( obj, "state_code" );
    this.city = handleNullUndefined( obj, "business_location_city" );
    this.zip = handleNullUndefined( obj, "business_location_zip_code" );
    this.primaryName = handleNullUndefined( obj, "primary_name" );
    this.primaryTitle = handleNullUndefined( obj, "primary_title" );
    this.primaryPhoneNumber = handleNullUndefined( obj, "primary_phone" );
    this.primaryAlternativePhoneNumber = handleNullUndefined(
      obj,
      "primary_alternative_phone"
    );
    this.primaryEmail = handleNullUndefined( obj, "primary_email" );
    this.secondaryName = handleNullUndefined( obj, "sec_name" );
    this.secondaryTitle = handleNullUndefined( obj, "sec_title" );
    this.secondaryPhoneNumber = handleNullUndefined( obj, "sec_phone" );
    this.secondaryAlternativePhoneNumber = handleNullUndefined(
      obj,
      "sec_alternative_phone"
    );
    this.secondaryEmail = handleNullUndefined( obj, "sec_email" );
    this.authorizedName = handleNullUndefined( obj, "authorized_signature_name" );
    this.authorizedTitle = handleNullUndefined(
      obj,
      "authorized_signature_title"
    );
    this.estimatedOfGames = handleNullUndefined( obj, "no_games_estimates" );
    this.estimatedOfGamers = handleNullUndefined(
      obj,
      "no_games_estimates_across"
    );
    this.gameGrowth = handleNullUndefined( obj, "game_growth" );
    this.gamersGrowth = handleNullUndefined( obj, "gamer_growth" );
    this.softwareDevelopmentAbility = handleNullUndefined(
      obj,
      "software_development_ability"
    );
    this.suggestion = handleNullUndefined( obj, "suggested_changes" );
    this.allowTermsConditions = obj.agreed_to_terms || false;
    this.username = handleNullUndefined( obj, "username" );
    this.applicationStatus = handleNullUndefined( obj, "application_status" );
    this.email = handleNullUndefined( obj, "email" );
    this.isActive = handleNullUndefined( obj, "is_active" );
    this.reasonForRejection = handleNullUndefined( obj, "reason_for_rejection" );
    this.partnerAmendmentDetails = obj.PartnerAmendmentDetails || [];
    this.partnerId = handleNullUndefined( obj, "partner_id" );
    this.unreadNotificationsCount = obj.unreadNotificationsCount || 0;
    this.serverVersion = obj?.configuration?.serverVersion || "";
    this.configuration = handleNullUndefined( obj, "configuration" );
    this.membershipTypes = obj?.configuration?.membershipTypes?.sort(
      ( a, b ) => ( Number( a.monthly_price ) - Number( b.monthly_price ) )
    );
    this.signoutWithoutConfirmation = obj?.signout_without_confirmation;
  }
}
