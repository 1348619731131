const partnerGamerConstant = {
  API_TYPES: {
    GET_GAMER_LISTS: "GET_GAMER_LISTS",
    GET_GAMER_DETAILS: "GET_GAMER_DETAILS",
    GET_GAMER_TOURNAMENTS: "GET_GAMER_TOURNAMENTS",
    GET_LEADER_BOARD: "GET_LEADER_BOARD",
    GET_GAMER_ASSETS: "GET_GAMER_ASSETS",
    GET_GAMER_LINKED_GAMES: "GET_GAMER_LINKED_GAMES",
    GET_GAMER_LINKED_GAMES_DETAIL: "GET_GAMER_LINKED_GAMES_DETAIL",
    GET_GAMER_LINKED_GAMES_ASSETS: "GET_GAMER_LINKED_GAMES_ASSETS",
    GET_GAMER_LINKED_GAMES_OFFERS: "GET_GAMER_LINKED_GAMES_OFFERS",
    GET_EXCHANGE_TRANSACTION_LISTS: "GET_EXCHANGE_TRANSACTION_LISTS",
  },
};

export default partnerGamerConstant;
