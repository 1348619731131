import { trim } from "lodash";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import { useModal, useForm } from "../../../hooks";
import {
  getRedeemTransactionDetailsAction,
  rejectRedeemRequestAction,
  approveRedeemRequestAction
} from "../../../store/actions/adminTransaction";
import adminConstant from "../../../util/adminConstant";
import adminTransactionConstant from "../../../util/adminTransactionConstant";
import adminUserConstant from "../../../util/adminUserConstant";
import constant from "../../../util/constant";
import ModalPopup from "../modal";
import RedeemSection from "./redeemSection";

const { API_STATUS } = constant;
const { ADMIN_PERMISSIONS } = adminUserConstant;
const { MODAL_TYPE_DETAIL } = adminConstant;
const { ADMIN_TRANSACTION_API_TYPES, STATUS } = adminTransactionConstant;
const initialState = {
  rejectionReason: "",
};

const RedeemTxDetail = ( props ) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { redeemTransactionDetail, apiType, status, adminDetail } = useSelector( state => ( {
    redeemTransactionDetail: state.adminTransaction.redeemTransactionDetail,
    apiType: state.adminTransaction.apiType,
    status: state.adminTransaction.status,
    adminDetail: state.adminDetail.data,
  } ) );
  const [ errorMessage, setErrorMessage ] = useState( "" );
  const { values, handleChange } = useForm( initialState, {} );
  const { isOpened, closeModal, openModal, modalType, setModalType } =
    useModal();
  const transactionId = props.match?.params?.transactionId || "";

  useEffect( () => {
    if( transactionId ) {
      dispatch( getRedeemTransactionDetailsAction( transactionId ) );
    }
  }, [ transactionId ] );

  useEffect( () => {
    if(
      status === API_STATUS.FAILURE &&
      apiType === ADMIN_TRANSACTION_API_TYPES.GET_REDEEM_TRANSACTION_DETAILS_SUCCESS
    ) {
      history.goBack();
    }
  }, [ apiType, status ] );

  useEffect( () => {
    if(
      status === API_STATUS.SUCCESS &&
      apiType === ADMIN_TRANSACTION_API_TYPES.REJECT_REDEEM_TRANSACTION
    ) {
      toastr.success( "Rejected successfully" );
      history.goBack();
    }
    else if(
      status === API_STATUS.SUCCESS &&
      apiType === ADMIN_TRANSACTION_API_TYPES.APPROVE_REDEEM_TRANSACTION
    ) {
      toastr.success( "Accepted successfully" );
      history.goBack();
    }
  }, [ status, apiType ] );

  const handleCloseModal = () => {
    closeModal();
    setErrorMessage();
    handleChange( "rejectionReason", "" );
  };

  const handleConfirm = () => {
    const requestObj = { transactionId: transactionId, isAccepted: false, rejectionReason: values.rejectionReason };

    if( modalType === MODAL_TYPE_DETAIL.REASON_REJECTION.type ) {
      if( trim( values.rejectionReason, " " ) ) {
        handleCloseModal();
        dispatch( rejectRedeemRequestAction( requestObj ) );
      }
      else {
        setErrorMessage( "Please enter reason for rejection" );
      }
    }
    else if( modalType === MODAL_TYPE_DETAIL.ACCEPT_REDEEM_REQUEST.type ) {
      handleCloseModal();
      requestObj.isAccepted = true;
      delete requestObj.rejectionReason;
      dispatch( approveRedeemRequestAction( requestObj ) );
    }
  };

  const handleOpenModal = ( mType ) => {
    setModalType( mType );
    openModal();
  };

  const handleInputChange = ( e ) => {
    const { name, value } = e.target;

    handleChange( name, value );

    if( errorMessage ) {
      setErrorMessage( "" );
    }
  };

  let viewPermission = true;

  if( !adminDetail?.permissions?.includes( ADMIN_PERMISSIONS.MANAGE_REDEEM_REQUESTS ) ) {
    viewPermission = false;
  }

  return (
    <div className='main-wrapper'>
      <div className='main-right-wrapper'>
        <div className='page-head-wrapper'>
          <h4 className='page-heading' data-testid='transactionNameTitle'>
            Transaction Details
          </h4>
          {( ( props?.mockData?.isEnable ) || ( viewPermission && redeemTransactionDetail.status === STATUS.REQUEST_PENDING ) ) && ( <div className='page-head-right'>
            <div className="reject-approve">
              <Button
                data-testid='reject'
                variant='outline-danger'
                className='btn-md mr-4'
                onClick={() =>
                  handleOpenModal( MODAL_TYPE_DETAIL.REASON_REJECTION.type )
                }
              >
                  Reject
              </Button>
              <Button
                data-testid='accept'
                variant='primary'
                className='btn-lg'
                onClick={() =>
                  handleOpenModal( MODAL_TYPE_DETAIL.ACCEPT_REDEEM_REQUEST.type )
                }
              >
                  Accept
              </Button>
            </div>
          </div>
          ) }
        </div>
        <div className='page-inner-box profile-steps-box'>
          <RedeemSection
            redeemTransactionDetail={redeemTransactionDetail}
          />
        </div>
      </div>
      <ModalPopup
        isOpened={isOpened}
        onClose={handleCloseModal}
        type={modalType}
        values={values}
        onChangeInput={handleInputChange}
        errorMessage={errorMessage}
        onConfirm={handleConfirm}
      />
    </div>
  );
};

RedeemTxDetail.propTypes = {
  mockData: PropTypes.bool,
  match: PropTypes.string,
};

export default RedeemTxDetail;
