import constant from "../../util/constant";
import {
  GET_PARTNER_DRAFT_DETAIL_SUCCESS,
  GET_PARTNER_DRAFT_DETAIL_FAILURE,
  GET_PARTNER_DRAFT_DETAIL_REQUEST,
  ADD_PARTNER_DRAFT_DETAIL_SUCCESS,
  UPDATE_PARTNER_DRAFT_DETAIL_SUCCESS,
  PARTNER_DRAFT_API_REQUEST,
  PARTNER_DRAFT_API_END,
  SET_PARTNER_DRAFT_DETAIL_SUCCESS,
  TRUNCATE_PARTNER_DRAFT_DETAIL_SUCCESS,
  UPLOAD_LOGO_SUCCESS
} from "../actionTypes";

const { API_STATUS } = constant;

const DEFAULT_STATE = {
  loading: false,
  message: "",
  status: "",
  apiType: "",
  partnerDraftDetail: { data: "", meta: "", finalData: "" },
};

const partnerDraft = ( state = DEFAULT_STATE, action = "" ) => {
  switch ( action.type ) {
    case GET_PARTNER_DRAFT_DETAIL_REQUEST:
    case PARTNER_DRAFT_API_REQUEST:
      return {
        ...state,
        loading: true,
        apiType: action.payload,
      };
    case ADD_PARTNER_DRAFT_DETAIL_SUCCESS:
    case GET_PARTNER_DRAFT_DETAIL_SUCCESS:
    case UPDATE_PARTNER_DRAFT_DETAIL_SUCCESS:
      return {
        ...state,
        partnerDraftDetail: { data: action.payload.data, meta: action.payload.meta, finalData: action.payload.finalData },
        loading: true,
        status: API_STATUS.SUCCESS,
      };
    case GET_PARTNER_DRAFT_DETAIL_FAILURE:
      return {
        ...state,
        partnerDraftDetail: { data: "", meta: "" },
        loading: false,
      };
    case PARTNER_DRAFT_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case SET_PARTNER_DRAFT_DETAIL_SUCCESS:
      return {
        ...state,
        partnerDraftDetail: { data: action.payload, meta: "", finalData: "" },
        status: API_STATUS.END,
        apiType: "",
      };
    case TRUNCATE_PARTNER_DRAFT_DETAIL_SUCCESS:
      return {
        ...state,
        partnerDraftDetail: { data: state.partnerDraftDetail.data, meta: "", finalData: "" },
        status: API_STATUS.END,
        apiType: "",
      };
    case UPLOAD_LOGO_SUCCESS:
      return {
        ...state,
        partnerDraftDetail: {
          ...state.partnerDraftDetail,
          data: {
            ...state.partnerDraftDetail.data,
            logo: action.payload.data.path,
          },
        },
      };
    default:
      return state;
  }
};

export default partnerDraft;
