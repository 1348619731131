import { toastr } from "react-redux-toastr";
import { AdminTournamentDetails } from "../../entities/adminTournamentDetails";
import { AdminTournamentDraftDetails } from "../../entities/adminTournamentDraftDetails";
import {
  getAdminTournaments
} from "../../services/adminTournamentService";
import constant from "../../util/constant";
import tournamentConstant from "../../util/tournamentConstant";
import {
  START_LOADER,
  STOP_LOADER,
  ADMIN_TOURNAMENT_API_REQUEST,
  GET_ADMIN_TOURNAMENTS_FAILURE,
  GET_ADMIN_TOURNAMENTS_SUCCESS,
  ADMIN_TOURNAMENTS_API_END
} from "../actionTypes";

const { TOURNAMENTS_API_TYPES, TOURNAMENT_TYPE_STATUS } = tournamentConstant;
const { ERROR_MESSAGES } = constant;

export const getTournamentListAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_TOURNAMENT_API_REQUEST,
      payload: TOURNAMENTS_API_TYPES.GET_ADMIN_TOURNAMENTS,
    } );

    const response = await getAdminTournaments( param );
    const dataList = response.data.offerList.map(
      ( item ) => {
        if( item.offer_type === TOURNAMENT_TYPE_STATUS.DRAFT ) {
          return new AdminTournamentDraftDetails( item );
        }

        return new AdminTournamentDetails( item );
      }
    );

    dispatch( {
      type: GET_ADMIN_TOURNAMENTS_SUCCESS,
      payload: { dataList, meta: response.data.meta },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GET_ADMIN_TOURNAMENTS_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_TOURNAMENTS_API_END,
    } );
  }
};
