import HelpIcon from "@material-ui/icons/Help";
import PropTypes from "prop-types";
import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Row from "react-bootstrap/Row";
import Tooltip from "react-bootstrap/Tooltip";
// Local-imports
import Enums from "../../../util/Enums";
import constant from "../../../util/constant";
import { checkNonNumericDecimalChars } from "../../../util/helpers";

const { OPT_IN_STATUS, BRANDING: { APP_SHORT_NAME } } = constant;
const {
  OPT_IN_PARTICIPANTS_MIN_VALUE,
  OPT_IN_PARTICIPANTS_MAX_VALUE,
  OPT_IN_ENTRY_FEE_MIN_VALUE,
  OPT_IN_ENTRY_FEE_MAX_VALUE,
} = Enums;

const OptInFields = ( {
  values,
  handleInputChange,
  onBlur,
  errors,
  membershipTypes,
  isTournament = false,
} ) => {
  return (
    <>
      <Col sm={12}>
        <Form.Group>
          <Form.Label className='label-md'>Minimum Eligible Subscription Plan</Form.Label>
          <OverlayTrigger
            placement='right'
            overlay={
              <Tooltip>
                In order to participate, a gamer must be at this membership level or above.
              </Tooltip>
            }>
            <HelpIcon className="help-icon" />
          </OverlayTrigger>
          <Row>
            {membershipTypes?.map( ( item, index ) => (
              <Col sm={6} key={item.id}>
                <div className='custom-radio'>
                  <label>
                    <input
                      type='radio'
                      value={item.id}
                      name='membershipId'
                      checked={item.id === Number( values.membershipId )}
                      onChange={handleInputChange}
                      disabled={values.optInType === OPT_IN_STATUS.NO}
                    />
                    <span>
                      {item.name}
                    </span>
                    { ( ( index + 1 ) !== membershipTypes.length )
                      ? ( <div className="subscription-detail">For {item.name} and above gamers</div> )
                      : ( <div className="subscription-detail">Only for {item.name} gamers</div> )
                    }
                  </label>
                </div>
              </Col>
            ) )}
          </Row>
        </Form.Group>
      </Col>
      <Col sm={12}>
        <Form.Label className='label-md'>Entry Fee (Optional)</Form.Label>
      </Col>
      <Col sm={12}>
        <Row>
          <Col sm={3}>
            <Form.Group>
              <Form.Label className='label-with-icon'>
                { APP_SHORT_NAME } Points
                <OverlayTrigger
                  placement='right'
                  overlay={
                    <Tooltip>
                      If no entry fee required, then 0,
                       else the number of { APP_SHORT_NAME } Points a gamer must pay to enter the { isTournament ? "Tournament" : "Offer" }.
                    </Tooltip>}>
                  <HelpIcon />
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                type='number'
                value={values.entryFee}
                name='entryFee'
                onChange={handleInputChange}
                data-testid='qxPoint'
                min={OPT_IN_ENTRY_FEE_MIN_VALUE.toString()}
                max={OPT_IN_ENTRY_FEE_MAX_VALUE.toString()}
                onBlur={onBlur}
                onKeyDown={checkNonNumericDecimalChars}
                onPaste={e => e.preventDefault()}
                disabled={values.optInType === OPT_IN_STATUS.NO}
                maxLength={6}
              />
              <div className='invalid-feedback d-block'>
                { errors.entryFee }
              </div>
            </Form.Group>
          </Col>
        </Row>
      </Col>
      <Col sm={12}>
        <Form.Label className='label-md'>Participants</Form.Label>
      </Col>
      <Col sm={12}>
        <Row>
          <Col sm={3}>
            <Form.Group>
              <Form.Label className='label-with-icon'>
                Minimum No. of Participants
                <OverlayTrigger
                  placement='right'
                  overlay={
                    <Tooltip>
                      If this is an opt-in { isTournament ? "Tournament" : "Offer" }, then this number is the minimum number
                      of participants (those who have opted in) for the Offer to start or take effect.
                      This value can be 0.
                    </Tooltip>
                  }>
                  <HelpIcon />
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                type='number'
                value={values.minParticipants}
                name='minParticipants'
                onChange={handleInputChange}
                data-testid='qxPoint'
                min={OPT_IN_PARTICIPANTS_MIN_VALUE.toString()}
                max={OPT_IN_PARTICIPANTS_MAX_VALUE.toString()}
                onBlur={onBlur}
                onKeyDown={checkNonNumericDecimalChars}
                onPaste={e => e.preventDefault()}
                disabled={( values.optInType === OPT_IN_STATUS.NO ) || ( values.minParticipantsNoLimit )}
                maxLength={6}
              />
              <div className='invalid-feedback d-block'>
                { errors.minParticipants }
              </div>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <div className='divider-space'>
              <div className='or-divider'>OR</div>
              <div className='custom-checkbox'>
                <label>
                  <input
                    type='checkbox'
                    name='minParticipantsNoLimit'
                    checked={values.minParticipantsNoLimit}
                    disabled={values.optInType === OPT_IN_STATUS.NO}
                    onChange={handleInputChange}
                  />
                  <span>
                    No Minimum
                  </span>
                </label>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <Form.Group>
              <Form.Label className='label-with-icon'>
              Maximum No. of Participants
                <OverlayTrigger
                  placement='right'
                  overlay={
                    <Tooltip>
                      If this is an opt-in { isTournament ? "Tournament" : "Offer" }, then this number is the maximum
                      number of participants (those who have opted in) for the { isTournament ? "Tournament" : "Offer" }.
                      After the maximum is reached, no more participants will be allowed.
                      This value can be 0. This value must be equal to, or larger than, the Minimum number.
                    </Tooltip>
                  }>
                  <HelpIcon />
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                type='number'
                value={values.maxParticipants}
                name='maxParticipants'
                onChange={handleInputChange}
                disabled={( values.optInType === OPT_IN_STATUS.NO ) || ( values.maxParticipantsNoLimit )}
                data-testid='qxPoint'
                min={OPT_IN_PARTICIPANTS_MIN_VALUE.toString()}
                max={OPT_IN_PARTICIPANTS_MAX_VALUE.toString()}
                onBlur={onBlur}
                onKeyDown={checkNonNumericDecimalChars}
                onPaste={e => e.preventDefault()}
                maxLength={6}
              />
              <div className='invalid-feedback d-block'>
                { errors.maxParticipants }
              </div>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <div className='divider-space'>
              <div className='or-divider'>OR</div>
              <div className='custom-checkbox'>
                <label>
                  <input
                    type='checkbox'
                    name='maxParticipantsNoLimit'
                    checked={values.maxParticipantsNoLimit}
                    disabled={values.optInType === OPT_IN_STATUS.NO}
                    onChange={handleInputChange}
                  />
                  <span>
                    No Maximum
                  </span>
                </label>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </>
  );
};

OptInFields.propTypes = {
  handleInputChange: PropTypes.func,
  values: PropTypes.object,
  onBlur: PropTypes.func,
  membershipTypes: PropTypes.array,
  errors: PropTypes.object,
  isTournament: PropTypes.bool,
};

export default OptInFields;
