import PropTypes from "prop-types";
import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { clearSummaryDashboardGraphState } from "../../../store/actions/partnerAnalytics";
import { addActiveClass } from "../../../util/helpers";


const CommonNavbar = ( {
  toggleDrawer,
  activeNav,
  navTitle,
  activeTab,
  route,
  icon,
  type,
} ) => {
  const dispatch = useDispatch();

  const clearPartnerAnalyticsGraphData = () => {
    // Remove graph data from localStorage in case of different active tabs except analytics tab.
    if( activeNav !== "analytics" ) {
      localStorage.removeItem( "graphData" );
      dispatch( clearSummaryDashboardGraphState() );
    }
  };


  return (
    <>
      {!toggleDrawer && (
        <OverlayTrigger
          placement="right"
          overlay={<Tooltip className="event-tooltip">{navTitle}</Tooltip>}
        >
          <Link onClick={() => clearPartnerAnalyticsGraphData} to={route} className={addActiveClass( activeTab, activeNav )}>
            {type === "admin" && icon}
            {type === "partner" && <span className={icon}></span>}
            <span className="nav-text">{navTitle}</span>
          </Link>
        </OverlayTrigger>
      )}
      {toggleDrawer && (
        <Link onClick={clearPartnerAnalyticsGraphData} to={route} className={addActiveClass( activeTab, activeNav )}>
          {type === "admin" && icon}
          {type === "partner" && <span className={icon}></span>}
          <span className="nav-text">{navTitle}</span>
        </Link>
      )}
    </>
  );
};

CommonNavbar.propTypes = {
  toggleDrawer: PropTypes.bool,
  activeNav: PropTypes.string,
  navTitle: PropTypes.string,
  activeTab: PropTypes.string,
  route: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string,
};

export default CommonNavbar;
