import React, { useEffect, useMemo, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";

import { useForm, useAddQuery, useToggle } from "../../../hooks";
import { getGamerDetailAction, getGamerListAction, getGamerAllListAction } from "../../../store/actions/adminGamer";
import adminGamerConstant from "../../../util/adminGamerConstant";
import constant from "../../../util/constant";
import { useSetUrlParams, concatStrings, checkFilterApplied, getBackNavigationRoute } from "../../../util/helpers";
import { gamerGameTableConfigs } from "../../../util/tableConstant";
import FilterIcon from "../../shared/FilterIcon/FilterIcon";
import BackButton from "../../shared/backButton";
import ProfileImage from "../../shared/profileImage";
import SortAndViewToggleList from "../../shared/sortAndViewToggleList";
import TableHeaderRow from "../../shared/tableHeadingRow";
import GamerGameList from "./gamerGameList";
import SubHeader from "./subHeader";

const { GAMER_USERS_API_TYPES, GAME_LIST_SORT } = adminGamerConstant;
const { API_STATUS, ROUTES, ORDER_BY, FILTERS_NAME } = constant;

const GamerDetail = () => {
  const { addQuery } = useAddQuery();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { gridViewToggle } = useToggle();
  const isFilterApplied = checkFilterApplied( location.search, FILTERS_NAME.GAMER_DETAIL );
  const backScreens = [ "adminGamerDetail" ];
  const { apiType, status, gamerDetail, gamerGameList, gamerAllGameList } = useSelector( state => ( {
    apiType: state.adminGamer.apiType,
    status: state.adminGamer.status,
    gamerDetail: state.adminGamer.gamerDetail,
    gamerGameList: state.adminGamer.gamerGameList,
    gamerAllGameList: state.adminGamer.gamerAllGameList,
  } ) );
  const { gamerId: urlParamString } = useParams();
  const { 0: gamerId } = String( urlParamString ).split( "?" );
  const queryParamsOriginal = new URLSearchParams( window.location.search );

  const getQueryStringValue = ( queryStringKey, defaultValue ) => {
    if( [ "sortBy", "sortOrder", "viewType" ].includes( queryStringKey ) ) {
      return queryParamsOriginal.get( queryStringKey ) === null ? defaultValue : queryParamsOriginal.get( queryStringKey );
    }
    else if( queryStringKey === "gameIds" ) {
      return queryParamsOriginal.get( queryStringKey ) === "" ? [] : JSON.parse( queryParamsOriginal.get( queryStringKey ) );
    }
    else if( [ "startDate", "endDate" ].includes( queryStringKey ) ) {
      return ( queryParamsOriginal.get( queryStringKey ) === null ) ? false : queryParamsOriginal.get( queryStringKey );
    }

    return null;
  };

  const { values, handleChange } = useForm(
    {
      viewType: parseInt( getQueryStringValue( "viewType", 1 ), 10 ),
      sortBy: getQueryStringValue( "sortBy", "gameName" ),
      sortOrder: getQueryStringValue( "sortOrder", ORDER_BY.ASC ),
      page: 1,
      gameIds: getQueryStringValue( "gameIds", "" ),
      startDate: getQueryStringValue( "startDate" ),
      endDate: getQueryStringValue( "endDate" ),
      initialStartDate: ( queryParamsOriginal.get( "startDate" ) === null ) ? null : queryParamsOriginal.get( "startDate" ),
      initialEndDate: ( queryParamsOriginal.get( "endDate" ) === null ) ? null : queryParamsOriginal.get( "endDate" ),
    },
    {}
  );

  const requestParams = Object.assign(
    {
      sortOrder: values.sortOrder,
      perPage: 10,
      page: values.page,
      sortBy: values.sortBy,
      // TODO: removing this will require changes to qx-api
      // eslint-disable-next-line no-undefined
      gameIds: concatStrings( values.gameIds, "," ) || undefined,
      startDate: values.initialStartDate,
      endDate: values.initialEndDate,
    }
  );

  useEffect( () => {
    if( gamerId !== "0" ) {
      handleChange( "viewType", values.viewType );
      dispatch( getGamerDetailAction( gamerId ) );

      if( queryParamsOriginal.get( "sortColumn" ) && queryParamsOriginal.get( "sortOrder" ) ) {
        toggleClass( queryParamsOriginal.get( "sortColumn" ), queryParamsOriginal.get( "sortOrder" ), true );
      }
    }
  }, [ gamerId ] );

  useEffect( () => {
    if( apiType === GAMER_USERS_API_TYPES.GET_GAMER_DETAIL && status === API_STATUS.FAILURE ) {
      history.goBack();
    }
    else if( apiType === GAMER_USERS_API_TYPES.GET_ALL_GAMERS_LIST && status === API_STATUS.SUCCESS ) {
      dispatch( getGamerAllListAction( gamerId, { fetchAllRecords: true } ) );
    }
  }, [ apiType, status ] );

  useEffect( () => {
    dispatch( getGamerListAction( gamerId, requestParams ) );
  }, [
    values.gameIds,
    values.initialStartDate,
    values.initialEndDate,
    values.sortBy,
    values.sortOrder,
  ] );

  const handleViewPage = ( gameId, pageType ) => {
    const url = `${ ROUTES.GAMER_GAME_DETAILS.replace( ":gameId", gameId ).replace( ":gamerId", gamerId ) }`;

    history.push( {
      pathname: `${ url }?type=${ pageType }`,
      state: [ ...location.state,
        ...[ { from: "gamerGameDetail", path: location.pathname, state: location.search } ],
      ],
    } );
  };

  const handleSorting = ( sortBy, sortingType ) => {
    handleChange( "page", 1 );
    handleChange( "sortBy", sortBy );
    handleChange( "sortOrder", sortingType );
    addQuery( useSetUrlParams( "sortBy", sortBy ), location.state );
    addQuery( useSetUrlParams( "sortOrder", sortingType ), location.state );
    toggleClass( sortBy, sortingType, true );
  };

  const memoizedHandleClick = useCallback(
    ( sortBy, orderType ) => handleSorting( sortBy, orderType ),
    []
  );

  const memoizedTableConfig = useMemo( () => {
    return [ gamerGameTableConfigs ];
  }, [] );

  const [ showFilter, setShowFilter ] = useState( false );
  const showFilterScreen = () => setShowFilter( !showFilter );
  const [ sortColumns, setSortColumns ] = useState( [
    {
      col: "gameName",
      value: "Game Name",
      status: true,
      order: ORDER_BY.ASC,
    },
    {
      col: "dateLinked",
      value: GAME_LIST_SORT.DATE_LINKED,
      status: false,
    },
  ] );

  const toggleClass = ( params, order, isQueryParamsUpdated = false ) => {
    gridViewToggle( sortColumns, handleChange, params, setSortColumns, addQuery, useSetUrlParams,
      order, location.state, isQueryParamsUpdated, true );
  };

  const handleBackNavigation = () => {
    return getBackNavigationRoute( ROUTES.GAMER_SECTION, location, backScreens );
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="main-right-wrapper">
          <BackButton
            backLink={handleBackNavigation()}
            state={location.state}
          />
          <div className="page-head-wrapper">
            <div className='d-flex align-items-center'>
              <ProfileImage profileImage={gamerDetail?.profileImage} />
              <div className='profile-title mb-0'>{gamerDetail.gamerFullName}</div>
            </div>
          </div>
          <div className="page-head-wrapper">
            <h4 className="page-heading">Games</h4>
            <FilterIcon
              showFilter={showFilter}
              showFilterScreen={showFilterScreen}
              isFilterApplied={isFilterApplied} />
          </div>
          <div className='filter-tabs-wrapper align-items-end'>
            <SubHeader
              values={values}
              handleChange={handleChange}
              gameList={gamerAllGameList}
              addQuery={addQuery}
              state={location.state}
              showFilter={showFilter}
            />
            <SortAndViewToggleList
              values={values}
              addQuery={addQuery}
              sortColumns={sortColumns}
              toggleClass={toggleClass}
              handleChange={handleChange}
              locationState={location.state}
            />
          </div>
          <div
            className={`games-list-table-head gamer-game-head ${ !values.viewType ? "active" : "" }`}
          >
            <div className='table-head-body'>
              <TableHeaderRow
                configData={memoizedTableConfig[ 0 ]}
                onAscOrder={memoizedHandleClick}
                onDescOrder={memoizedHandleClick}
              />
            </div>
          </div>
          <GamerGameList
            values={values}
            gamerDetail={gamerDetail}
            gamerGameList={gamerGameList}
            handleViewPage={handleViewPage} />
        </div>
      </div>
    </>
  );
};

export default GamerDetail;
