import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Local-imports
import { useAddQuery, useForm, useScroll, useFilter } from "../../../hooks";
import { getAdminGamesLinkedGamerAction, getGamesLinkedGamerFilterAction } from "../../../store/actions/adminGames";
import constant from "../../../util/constant";
import { concatStrings, getUrlParameter } from "../../../util/helpers";
import Gamers from "../../shared/gamer/gamers";

const { ORDER_BY } = constant;

const GameGamerListing = ( { gameId, previousState } ) => {
  const dispatch = useDispatch();
  const { addQuery } = useAddQuery();
  const { scrollEvent } = useScroll();
  const { handleFilterChange, clearSelectedFilter } = useFilter();
  const { gamers, gamersMetaData, gamersFilterList, maxLinkedGames, maxAcqyrCashBalance } = useSelector( state => ( {
    gamers: state.adminGame.partnerLinkedGamers.gamers,
    gamersMetaData: state.adminGame.partnerLinkedGamers.meta,
    gamersFilterList: state.adminGame.adminLinkedGamerFilter.gamerNames,
    maxLinkedGames: state.adminGame.adminLinkedGamerFilter.linkedGameRange,
    maxAcqyrCashBalance: state.adminGame.adminLinkedGamerFilter.acqyrCashBalance,
  } ) );
  const { values, handleChange } = useForm(
    {
      searchDummyText: getUrlParameter( "searchKey", null ), searchKey: getUrlParameter( "searchKey", null ),
      sortBy: getUrlParameter( "sortBy", "fullName" ), sortOrder: getUrlParameter( "sortOrder", ORDER_BY.ASC ),
      page: 1, fullNames: getUrlParameter( "fullNames", "" ), minAcqyrCashBalance: getUrlParameter( "minAcqyrCashBalance" ),
      maxAcqyrCashBalance: getUrlParameter( "maxAcqyrCashBalance" ), minLinkedGames: getUrlParameter( "minLinkedGames" ),
      maxLinkedGames: getUrlParameter( "maxLinkedGames" ), dummyMinAcqyrCashBalance: getUrlParameter( "minAcqyrCashBalance" ),
      dummyMaxAcqyrCashBalance: getUrlParameter( "maxAcqyrCashBalance" ), dummyMinLinkedGames: getUrlParameter( "minLinkedGames" ),
      dummyMaxLinkedGames: getUrlParameter( "maxLinkedGames" ),
      showModal: false,
      linkedGames: [],
    },
    {}
  );
  const requestParams = Object.assign(
    {
      sortOrder: values.sortOrder,
      sortBy: values.sortBy,
      perPage: 10,
      page: values.page,
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      searchKey: values.searchKey || undefined,
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      gamerNames: concatStrings( values.fullNames, "," ) || undefined,
      minAcqyrCashBalance: values.minAcqyrCashBalance,
      maxAcqyrCashBalance: values.maxAcqyrCashBalance,
      maxLinkedGames: values.maxLinkedGames,
      minLinkedGames: values.minLinkedGames,
      fetchAllRecords: false,
    }
  );

  useEffect( () => {
    dispatch( getGamesLinkedGamerFilterAction( gameId ) );
    dispatch( getAdminGamesLinkedGamerAction( gameId, requestParams ) );
  }, [] );

  useEffect( () => {
    if( !values.dummyMaxAcqyrCashBalance ) {
      handleChange( "dummyMinAcqyrCashBalance", maxAcqyrCashBalance.min );
      handleChange( "dummyMaxAcqyrCashBalance", maxAcqyrCashBalance.max );
    }

    if( !values.dummyMaxLinkedGames ) {
      handleChange( "dummyMinLinkedGames", maxLinkedGames.min );
      handleChange( "dummyMaxLinkedGames", maxLinkedGames.max );
    }
  }, [ maxAcqyrCashBalance, maxLinkedGames ] );

  useEffect( () => {
    if( ( values.maxAcqyrCashBalance && values.minAcqyrCashBalance > -1 ) ||
      ( values.maxLinkedGames && values.minLinkedGames > -1 ) ) {
      dispatch( getAdminGamesLinkedGamerAction( gameId, requestParams ) );
    }
  }, [
    values.minAcqyrCashBalance, values.minLinkedGames,
    values.maxAcqyrCashBalance, values.maxLinkedGames,
  ] );

  useEffect( () => {
    dispatch( getAdminGamesLinkedGamerAction( gameId, requestParams ) );
  }, [
    values.sortOrder, values.page, values.sortBy, values.searchKey, values.fullNames,
  ] );

  return (
    <>
      <Gamers
        id={gameId}
        action={"games"}
        values={values}
        addQuery={addQuery}
        gamers={gamers}
        handleChange={handleChange}
        scrollEvent={scrollEvent}
        gamersFilterList={gamersFilterList}
        previousState={previousState}
        maxLinkedGames={maxLinkedGames}
        gamersMetaData={gamersMetaData}
        maxAcqyrCashBalance={maxAcqyrCashBalance}
        handleFilterChange={handleFilterChange}
        clearSelectedFilter={clearSelectedFilter}
      />
    </>
  );
};

GameGamerListing.propTypes = {
  gameId: PropTypes.string,
  previousState: PropTypes.string,
};

export default GameGamerListing;
