import HelpIcon from "@material-ui/icons/Help";
import PropTypes from "prop-types";
import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Row from "react-bootstrap/Row";
import Tooltip from "react-bootstrap/Tooltip";
import { calendarIcon } from "../../../util/icons";
import CustomDatePicker from "../../shared/customDatePicker";
import SelectBox from "../../shared/selectBox";
import AdvertiseToGamer from "./advertiseToGamer";
import ChooseGame from "./chooseGame";

const ScheduleOffer = ( { onChange, values, games, errors, onSelect, gamers, onBlur, startDateChange, endDateChange, advertiseDateChange } ) => (
  <>
    <h4 className='page-inner-heading'>
      3. Schedule the Offer
      <OverlayTrigger placement='right' overlay={<Tooltip>Enter start and end dates for when the offer will be active</Tooltip>}>
        <HelpIcon className="help-icon" />
      </OverlayTrigger>
    </h4>
    <Row>
      <Col sm={6}>
        <ChooseGame
          values={values}
          games={games}
          errors={errors}
          onSelect={onSelect}
          onBlur={onBlur}
        />
      </Col>
    </Row>
    <Row>
      <Col sm={6}>
        <Form.Group>
          <Form.Label>Choose Target Gamers</Form.Label>
          <SelectBox options={gamers} placeholder='Choose Audience' onChange={value => onSelect( "gamers", value )} value={values.gamers} onBlur={onBlur} />
          <div className='invalid-feedback d-block'>
            {errors.gamers}
          </div>
        </Form.Group>
      </Col>
    </Row>
    <Row>
      <Col sm={6}>
        <Form.Group className='custom-datepicker with-icon'>
          <Form.Label className='label-with-icon'>Start Date
            <OverlayTrigger placement='right' overlay={<Tooltip>All items starting on or after the date you choose.</Tooltip>}>
              <HelpIcon />
            </OverlayTrigger>
          </Form.Label>
          <CustomDatePicker
            value={values.startDate}
            handleChange={startDateChange}
            onBlur={onBlur}
            minDate={new Date()}
          />
          <div className='invalid-feedback d-block'>
            {errors.startDate}
          </div>
          <img src={calendarIcon} alt="calendar" className='calendar-icon more-top' />
        </Form.Group>
      </Col>
      <Col sm={6}>
        <Form.Group className='custom-datepicker with-icon'>
          <Form.Label className='label-with-icon'>End Date
            <OverlayTrigger placement='left' overlay={<Tooltip>All items ending on or before the date you choose.</Tooltip>}>
              <HelpIcon />
            </OverlayTrigger>
          </Form.Label>
          <CustomDatePicker
            value={values.endDate}
            handleChange={endDateChange}
            onBlur={onBlur}
            minDate={ new Date( values.startDate ) || new Date()}
          />
          <div className='invalid-feedback d-block'>
            {errors.endDate}
          </div>
          <img src={calendarIcon} alt="calendar" className='calendar-icon more-top' />
        </Form.Group>
      </Col>
    </Row>
    <Row>
      <AdvertiseToGamer
        values={values}
        onChange={onChange}
        errors={errors}
        onSelect={onSelect}
        onBlur={onBlur}
        advertiseDateChange={advertiseDateChange}
      />
    </Row>
  </>
);

ScheduleOffer.propTypes = {
  onChange: PropTypes.func,
  values: PropTypes.object,
  games: PropTypes.array,
  errors: PropTypes.object,
  onSelect: PropTypes.func,
  gamers: PropTypes.array,
  onBlur: PropTypes.func,
  startDateChange: PropTypes.func,
  endDateChange: PropTypes.func,
  advertiseDateChange: PropTypes.func,
};

export default ScheduleOffer;
