import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSetUrlParams } from "../../../util/helpers";
import FilterIcon from "../../shared/FilterIcon/FilterIcon";
import CustomDatePicker from "../../shared/customDatePicker";
import MultiSelectCheckbox from "../../shared/multiSelectCheckbox";

const SubHeader = ( {
  values,
  onSearch,
  handleChange,
  addQuery,
  gameList,
  partnerList,
  statusList,
  isFilterApplied,
} ) => {
  const listHandle = ( obj, listName ) => {
    if( obj.length > 0 ) {
      handleChange( "page", 1 );
      handleChange( listName, obj );
      addQuery( useSetUrlParams( listName, JSON.stringify( obj ) ) );
    }
    else {
      addQuery( useSetUrlParams( listName, "" ) );
      handleChange( listName );
    }
  };

  const clearHandle = ( parmas ) => {
    if( parmas ) {
      addQuery( useSetUrlParams( parmas, "" ) );
      handleChange( parmas, [] );
    }
  };

  const multiSelectedItems = useMemo( () => [ values.gameList, values.partnerList, values.statusList ] );
  const handleSelectChange = useCallback(
    [ obj => listHandle( obj, "gameList" ),
      obj => listHandle( obj, "partnerList" ),
      obj => listHandle( obj, "statusList" ),
    ],
    []
  );
  const handleClearMultiSelect = useCallback(
    parmas => clearHandle( parmas ), []
  );
  const [ showFilter, setShowFilter ] = useState( false );
  const showFilterScreen = () => setShowFilter( !showFilter );

  return (
    <>
      <div className='page-head-wrapper'>
        <h4 className='page-heading'>Games</h4>
        <div className='page-head-right'>
          <Form.Group className='custom-search w-300 mr-0'>
            <span className='icon-search'></span>
            <Form.Control
              type='text'
              placeholder='Search by Game Name, Description...'
              onChange={onSearch}
              name='searchKey'
              value={values.searchDummyText}
              data-testid='searchKey'
              title='Search by Game Name, Description and Partner'
            />
          </Form.Group>
          <FilterIcon
            showFilter={showFilter}
            showFilterScreen={showFilterScreen}
            isFilterApplied={isFilterApplied} />
        </div>
      </div>
      <div className={`filter-boxes mr-0 bottom-margin ${ !showFilter ? "" : "remove-filter" }`}>
        <Row>
          <Col md={3}>
            <Form.Group className='custom-multiselect mb-0'>
              <Form.Label>Game Name</Form.Label>
              <MultiSelectCheckbox
                multiOptionsList={gameList}
                handleSelectChange={handleSelectChange[ 0 ]}
                multiSelectedItems={multiSelectedItems[ 0 ]}
                handleClearMultiSelect={() => handleClearMultiSelect( "gameList" )} />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className='custom-multiselect mb-0'>
              <Form.Label>Partner</Form.Label>
              <MultiSelectCheckbox
                multiOptionsList={partnerList}
                handleSelectChange={handleSelectChange[ 1 ]}
                multiSelectedItems={multiSelectedItems[ 1 ]}
                handleClearMultiSelect={() => handleClearMultiSelect( "partnerList" )} />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className='custom-datepicker mb-0'>
              <Form.Label>Published On</Form.Label>
              <CustomDatePicker
                value={values.publishedOn}
                handleChange={( e ) => {
                  if( e ) {
                    handleChange( "page", 1 );
                    addQuery( useSetUrlParams( "publishedOn", new Date( e ).toLocaleDateString( "fr-CA" ) ) );
                    handleChange( "publishedOn", new Date( e ).toLocaleDateString( "fr-CA" ) );
                  }
                  else {
                    addQuery( useSetUrlParams( "publishedOn", "" ) );
                    handleChange( "publishedOn", false );
                  }
                }
                }
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className='custom-multiselect mb-0'>
              <Form.Label>Status</Form.Label>
              {<MultiSelectCheckbox
                multiOptionsList={statusList}
                handleSelectChange={handleSelectChange[ 2 ]}
                multiSelectedItems={multiSelectedItems[ 2 ]}
                handleClearMultiSelect={() => handleClearMultiSelect( "statusList" )} />}
            </Form.Group>
          </Col>
        </Row>
      </div>
    </>
  );
};

SubHeader.propTypes = {
  values: PropTypes.object,
  onSearch: PropTypes.func,
  handleChange: PropTypes.func,
  addQuery: PropTypes.func,
  gameList: PropTypes.array,
  partnerList: PropTypes.array,
  statusList: PropTypes.array,
  onCancel: PropTypes.func,
  isFilterApplied: PropTypes.func,
};

export default SubHeader;
