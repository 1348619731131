import adminGamerConstant from "../util/adminGamerConstant";
import constant from "../util/constant";
import { convertDate } from "../util/helpers";

const { BRANDING: { APP_SHORT_NAME } } = constant;
const { TXN_STATUS } = adminGamerConstant;

export class AdminGamerTxnHistory {
  constructor( obj ) {
    this.transactionDate = convertDate( obj?.current_period_start );
    this.item = obj?.description ? obj?.description.replace( "QX", APP_SHORT_NAME ) : "";
    this.amount = Number( obj?.amount );
    this.mode = obj?.payment_mode || "";
    this.status = obj?.status || "";
    this.statusTitle = TXN_STATUS.find(
      status => status.title === obj?.status
    );
    this.date = obj.current_period_start;
    this.validFromDate = obj?.is_recurring ? convertDate( obj?.current_period_start ) : "";
    this.validToDate = obj?.is_recurring ? convertDate( obj?.current_period_end ) : "";
    this.isRecurring = obj?.is_recurring;
  }
}
