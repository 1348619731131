import { getEligibleSubscriptionPlans } from "../util/helpers";

// Access the name property of the first element in the array
const planName = eligiblePlans => ( eligiblePlans.length ? eligiblePlans[ 0 ].name : "" );

export class AnalyticsGamerList {
  constructor( obj, membershipTypes ) {
    this.fullName = obj.fullName || "N/A";
    this.userId = obj.userId || "";
    this.gameName = obj.gameName || "N/A";
    this.gameId = obj.gameId;
    this.linkedOn = obj.linkedOn;
    this.gamerPlan = planName( getEligibleSubscriptionPlans( obj.membershipId, membershipTypes ) );
  }
}
