import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
// Local-import.
import { useAddQuery, useForm, useScroll, gamerExchangeEvent } from "../../../hooks";
import constant from "../../../util/constant";
import { useSetUrlParams, concatStrings, getUrlParameter, checkFilterApplied } from "../../../util/helpers";
import { ScrollPage } from "../../shared/customScrollBar";
import TableHeaderRow from "../../shared/tableHeadingRow";
import ExchangeFilterList from "./exchangeFilterList";
import TransactionHeader from "./transactionHeader";

const { ORDER_BY, FILTERS_NAME, BRANDING: { APP_SHORT_NAME } } = constant;

const ExchangeList = ( { memoizedTableConfig, tab } ) => {
  const { addQuery } = useAddQuery();
  const { scrollEvent } = useScroll();
  const location = useLocation();
  const searchBoxPlaceholder = "Search by Game and Gamer Name";

  const {
    exchangeTransaction,
    usersMetaData,
    exchangeFilterList,
  } = useSelector( state => ( {
    exchangeTransaction: state.adminTransaction.exchangeTransaction.transaction,
    usersMetaData: state.adminTransaction.exchangeTransaction.meta,
    apiType: state.adminTransaction.apiType,
    status: state.adminTransaction.status,
    exchangeFilterList: state.adminTransaction.exchangeFilterList,
  } ) );


  const qxPointsRange = exchangeFilterList.transactionRanges;

  const { values, handleChange } = useForm(
    {
      searchTempText: getUrlParameter( "searchKey", null ),
      searchKey: getUrlParameter( "searchKey", null ),
      sortBy: getUrlParameter( "sortBy", "transactionDate" ),
      sortOrder: getUrlParameter( "sortOrder", ORDER_BY.DESC ),
      page: 1,
      exchGamerNames: getUrlParameter( "exchGamerNames" ),
      gameNames: getUrlParameter( "gameNames" ),
      assetNames: getUrlParameter( "assetNames" ),
      startDateTransaction: null,
      endDateTransaction: null,
      initialStartDateTransaction: getUrlParameter( "startDateTransaction" ),
      initialEndDateTransaction: getUrlParameter( "endDateTransaction" ),
      maxRedeemPoints: getUrlParameter( "maxExRedeemPoints" ),
      initialMinRedeemPoints: getUrlParameter( "minExRedeemPoints" ),
      minRedeemPoints: getUrlParameter( "minExRedeemPoints" ),
      initialMaxRedeemPoints: getUrlParameter( "maxExRedeemPoints" ),
    },
    {}
  );

  const sliderMinMaxObj = {
    minExRedeemPoints: qxPointsRange.minQxPoints,
    maxExRedeemPoints: qxPointsRange.maxQxPoints,
  };
  const isFilterApplied = checkFilterApplied( location.search, FILTERS_NAME.EXCHANGE_TRANSACTION, sliderMinMaxObj );

  const exchangeScrollHandle = ( e ) => {
    scrollEvent( e, exchangeTransaction, values, handleChange, usersMetaData, "page" );
  };

  const requestParams = Object.assign(
    {
      sortOrder: values.sortOrder,
      perPage: 10,
      page: values.page,
      sortBy: values.sortBy,
      searchKey: values.searchKey,
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      gamerNames: concatStrings( values.exchGamerNames, "," ) || undefined,
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      gameNames: concatStrings( values.gameNames, "," ) || undefined,
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      assetNames: concatStrings( values.assetNames, "," ) || undefined,
      minQXPoints: values.minRedeemPoints,
      maxQXPoints: values.maxRedeemPoints,
      startDate: values.initialStartDateTransaction,
      endDate: values.initialEndDateTransaction,
    }
  );

  const handleSorting = ( sortBy, sortingType ) => {
    handleChange( "page", 1 );
    handleChange( "sortBy", sortBy );
    handleChange( "sortOrder", sortingType );
    addQuery( useSetUrlParams( "sortBy", sortBy ) );
    addQuery( useSetUrlParams( "sortOrder", sortingType ) );
  };

  const memoizedHandleClick = useCallback(
    ( sortBy, orderType ) => handleSorting( sortBy, orderType ),
    []
  );

  gamerExchangeEvent( values, handleChange, exchangeFilterList, qxPointsRange, null, requestParams );

  const [ showFilter, setShowFilter ] = useState( false );
  const showFilterScreen = () => setShowFilter( !showFilter );

  return ( <>
    <TransactionHeader
      tab={tab}
      addQuery={addQuery}
      values={values}
      searchBoxPlaceholder={searchBoxPlaceholder}
      searchBoxTitle={searchBoxPlaceholder}
      handleChange={handleChange}
      showFilter={showFilter}
      showFilterScreen={showFilterScreen}
      isFilterApplied={isFilterApplied} />
    <div className={`filter-tabs-wrapper mb-0 ${ !showFilter ? "" : "remove-filter" }`}>
      <ExchangeFilterList
        values={values}
        handleChange={handleChange}
        addQuery={addQuery}
        gamerNames={exchangeFilterList.gamerNames}
        gameNames={exchangeFilterList.gameNames}
        assetNames={exchangeFilterList.assetNames}
        transactionRanges={exchangeFilterList.transactionRanges}
      />
    </div>
    <div className="table-container trans-exchange-table">
      <div className="table-head">
        <div className="table-row">
          <TableHeaderRow
            configData={memoizedTableConfig}
            onAscOrder={memoizedHandleClick}
            onDescOrder={memoizedHandleClick}
          />
        </div>
      </div>
      <Scrollbars
        renderThumbVertical={ScrollPage}
        className={`custom-scroll-height max ${ !showFilter ? "" : "full-height" }`}
        onScroll={exchangeScrollHandle}
      >
        <div className="table-body no-scrollbar" >
          {Boolean( exchangeTransaction.length ) && exchangeTransaction.map( ( txn, index ) => (
            <div className="table-row" key={index}>
              <div className="td">
                <span className='big-text'>
                  <img src={txn.iconUrl} alt="games" className='logo' /> {txn.name}
                </span>
              </div>
              <div className="td"><span className='text-green font-medium'>{txn.qxPoints}</span></div>
              <div className="td">{txn.soldAssetQuantity}</div>
              <div className="td multiline-text">
                <span className='d-block'> {txn.assetName} ({txn.unitPriceQxPoints} { APP_SHORT_NAME } Points each)</span>
              </div>
              <div className="td">{txn.transactionDate}</div>
              <div className="td">{txn.gamerName}</div>
            </div>
          ) )}
          {!exchangeTransaction.length && (
            <div className='no-record' data-testid='no-record-found'>
              No Exchange Transaction Found
            </div>
          )}
        </div>
      </Scrollbars>
    </div>
  </>
  );
};

ExchangeList.propTypes = {
  memoizedTableConfig: PropTypes.array,
  tab: PropTypes.string,
};

export default ExchangeList;
