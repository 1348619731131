import { toastr } from "react-redux-toastr";
import { AdminGameDetails } from "../../entities/adminGameDetails";
import { AdminPartnerLinkedGamerDetails } from "../../entities/adminPartnerLinkedGamerDetails";
import { GameDetail } from "../../entities/gameDetail";
import {
  getAdminGames,
  getAdminGameDetails,
  addAdminModification,
  updatePartnerGameStatus,
  addAdminComment,
  enableDisableGameRequest,
  getAdminGamesLinkedGamerList,
  getAdminGamesLinkedGamerFilterList
} from "../../services/adminGamesServices";
import adminGameConstant from "../../util/adminGameConstant";
import constant from "../../util/constant";
import { prepareLabelValueFormatData } from "../../util/helpers";
import {
  START_LOADER,
  STOP_LOADER,
  ADMIN_GAME_API_END,
  ADMIN_GAME_API_REQUEST,
  GET_ADMIN_GAMES_SUCCESS,
  GET_ADMIN_GAMES_FAILURE,
  GET_ADMIN_GAME_DETAILS_SUCCESS,
  GET_ADMIN_GAME_DETAILS_FAILURE,
  ADD_MODIFICATION_SUCCESS,
  ADD_MODIFICATION_FAILURE,
  UPDATE_PARTNER_GAME_STATUS_SUCCESS,
  UPDATE_PARTNER_GAME_STATUS_FAILURE,
  ADD_ADMIN_COMMENT_SUCCESS,
  ADD_ADMIN_COMMENT_FAILURE,
  ENABLE_DISABLE_PARTNER_GAME_SUCCESS,
  ENABLE_DISABLE_PARTNER_GAME_FAILURE,
  ADMIN_GAMES_LINKED_GAMER_LIST_REQUEST,
  ADMIN_GAMES_LINKED_GAMERS_LIST_SUCCESS,
  ADMIN_GAMES_LINKED_GAMERS_LIST_FAILURE,
  ADMIN_GAMES_LINKED_GAMER_FILTER_LIST_API_REQUEST,
  ADMIN_GAMES_LINKED_GAMERS_FILTER_LIST_SUCCESS,
  ADMIN_GAMES_LINKED_GAMERS_FILTER_LIST_FAILURE
} from "../actionTypes";

const { ADMIN_GAME_API_TYPES } = adminGameConstant;
const { ERROR_MESSAGES } = constant;

export const getAdminGamesAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_GAME_API_REQUEST,
      payload: ADMIN_GAME_API_TYPES.GET_ADMIN_GAMES,
    } );

    const response = await getAdminGames( param );
    const dataList = response.data.game_data.map(
      item => new AdminGameDetails( item )
    );

    dispatch( {
      type: GET_ADMIN_GAMES_SUCCESS,
      payload: { dataList, meta: response.data.meta },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GET_ADMIN_GAMES_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_GAME_API_END,
    } );
  }
};

export const getAdminGameDetailsAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_GAME_API_REQUEST,
      payload: ADMIN_GAME_API_TYPES.GET_ADMIN_GAME_DETAILS,
    } );

    const response = await getAdminGameDetails( param );
    const newResponse = new GameDetail( response.data );

    dispatch( {
      type: GET_ADMIN_GAME_DETAILS_SUCCESS,
      payload: newResponse,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GET_ADMIN_GAME_DETAILS_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_GAME_API_END,
    } );
  }
};

export const addAdminModificationAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_GAME_API_REQUEST,
      payload: ADMIN_GAME_API_TYPES.ADMIN_ADD_MODIFICATION,
    } );

    const response = await addAdminModification( param );

    dispatch( { type: ADD_MODIFICATION_SUCCESS, payload: response.data } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    const err = Object.values( error?.data?.error || {} ) || "";

    dispatch( { type: STOP_LOADER } );
    dispatch( { type: ADD_MODIFICATION_FAILURE, payload: error } );

    if( err[ 0 ] ) {
      toastr.error( err[ 0 ] );
    }
    else {
      toastr.error( error.data.message );
    }
  }
  finally {
    dispatch( {
      type: ADMIN_GAME_API_END,
    } );
  }
};

export const updatePartnerGameStatusAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_GAME_API_REQUEST,
      payload: ADMIN_GAME_API_TYPES.UPDATE_PARTNER_GAME_STATUS,
    } );

    const response = await updatePartnerGameStatus( param );

    dispatch( {
      type: UPDATE_PARTNER_GAME_STATUS_SUCCESS,
      payload: response.data,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: UPDATE_PARTNER_GAME_STATUS_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_GAME_API_END,
    } );
  }
};


export const addAdminCommentAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_GAME_API_REQUEST,
      payload: ADMIN_GAME_API_TYPES.ADD_ADMIN_COMMENT,
    } );

    const response = await addAdminComment( param );

    dispatch( { type: ADD_ADMIN_COMMENT_SUCCESS, payload: response.data } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    const err = Object.values( error?.data?.error || {} ) || "";

    dispatch( { type: STOP_LOADER } );
    dispatch( { type: ADD_ADMIN_COMMENT_FAILURE, payload: error } );

    if( err[ 0 ] ) {
      toastr.error( err[ 0 ] );
    }
    else {
      toastr.error( error.data.message );
    }
  }
  finally {
    dispatch( {
      type: ADMIN_GAME_API_END,
    } );
  }
};

export const enableDisableGameAction = requestObj => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_GAME_API_REQUEST,
      payload: ADMIN_GAME_API_TYPES.ENABLE_DISABLE_PARTNER_GAME,
    } );

    const response = await enableDisableGameRequest( requestObj );

    dispatch( { type: ENABLE_DISABLE_PARTNER_GAME_SUCCESS, payload: response } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: ENABLE_DISABLE_PARTNER_GAME_FAILURE, payload: error } );
    dispatch( { type: STOP_LOADER } );
    toastr.error( error?.error || constant.ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( { type: ADMIN_GAME_API_END } );
  }
};

export const getAdminGamesLinkedGamerAction = ( gameId, params ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_GAMES_LINKED_GAMER_LIST_REQUEST,
      payload: ADMIN_GAME_API_TYPES.ADMIN_GAMES_LINKED_GAMERS_LIST,
    } );

    const response = await getAdminGamesLinkedGamerList( gameId, params );
    const dataList = response.data.gamerList.map(
      item => new AdminPartnerLinkedGamerDetails( item )
    );

    dispatch( {
      type: ADMIN_GAMES_LINKED_GAMERS_LIST_SUCCESS,
      payload: { dataList, meta: response.data.meta },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: ADMIN_GAMES_LINKED_GAMERS_LIST_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_GAME_API_END,
    } );
  }
};

export const getGamesLinkedGamerFilterAction = gameId => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_GAMES_LINKED_GAMER_FILTER_LIST_API_REQUEST,
      payload: ADMIN_GAME_API_TYPES.ADMIN_GAMES_LINKED_GAMERS_FILTER_LIST,
    } );

    const response = await getAdminGamesLinkedGamerFilterList( gameId );
    const gamerNames = prepareLabelValueFormatData( response.data.gamerNames );
    const acqyrCashBalance = {
      min: response.data.acqyrCashBalanceRange.minAcqyrCashBalance,
      max: response.data.acqyrCashBalanceRange.maxAcqyrCashBalance,
    };
    const linkedGameRange = {
      min: response.data.linkedGameRange.minLinkedGame,
      max: response.data.linkedGameRange.maxLinkedGame,
    };

    dispatch( {
      type: ADMIN_GAMES_LINKED_GAMERS_FILTER_LIST_SUCCESS,
      payload: { acqyrCashBalance, gamerNames, linkedGameRange },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: ADMIN_GAMES_LINKED_GAMERS_FILTER_LIST_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_GAME_API_END,
    } );
  }
};
