import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { getPartnerGamerDetailAction } from "../../../store/actions/partnerGamer";
import constant from "../../../util/constant";
import { formatNumberAsPerLocale, currencyFormat, getBackNavigationRoute } from "../../../util/helpers";
import {
  gamesIcon,
  transactionsIcon,
  offerIcon,
  eventsIcon,
  tournamentIcon
} from "../../../util/icons";
import ToolTipError from "../../admin/gamer/toolTipError";
import BackButton from "../../shared/backButton";
import ProfileImage from "../../shared/profileImage";

const { ROUTES, BRANDING: { APP_SHORT_NAME } } = constant;

const PartnerGamerSection = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { gamerId: URLString } = useParams();
  const { 0: gamerId } = String( URLString ).split( "?" );
  const paramsQuery = new URLSearchParams( window.location.search );
  const fromScreen = paramsQuery.get( "from" );
  const backScreens = [ "partnerGamers" ];
  const { gamerDetail, usdPerQxPoint } = useSelector( state => ( {
    gamerDetail: state.partnerGamer.gamerDetail,
    usdPerQxPoint: state.partner.partnerDetail.configuration?.usdPerQxPoint || 0,
  } ) );

  useEffect( () => {
    if( gamerId !== "0" ) {
      dispatch( getPartnerGamerDetailAction( gamerId ) );
    }
  }, [ gamerId ] );

  const handleBackNavigation = () => {
    return getBackNavigationRoute( ROUTES.PARTNER_GAMER_LIST, location, backScreens );
  };

  const storePageState = () => {
    return [ ...location.state, ...[ { from: "partnerGamerDetail", path: location.pathname, state: location.search } ] ];
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="main-right-wrapper">
          <BackButton
            backLink={handleBackNavigation()}
            state={fromScreen === "partnerGamers" ? "" : location.state}
          />
          <div className="page-head-wrapper">
            <div className="d-flex align-items-center">
              <ProfileImage
                profileImage={gamerDetail?.profileImage}
                isGamerDetail={true}
              />
              <div className="d-flex flex-column">
                <div className="profile-title">
                  {gamerDetail?.gamerFullName}
                </div>
                <div className="text-gamer-bold">
                  {`${ formatNumberAsPerLocale(
                    gamerDetail?.acqyrCashBalance || 0
                  ) } ${ APP_SHORT_NAME } Points (${ currencyFormat( {
                    data: gamerDetail?.acqyrCashBalance * usdPerQxPoint || 0,
                  } ) })`}
                  <ToolTipError gameNames={gamerDetail?.serverErrorsGameName} />
                </div>
                <div className="profile-text"></div>
                <div className="profile-text mb-0"></div>
              </div>
            </div>
            <div className="right-more-content">

            </div>
          </div>
          <Row>
            <Col sm={4}>
              <div className="gamer-card">
                <div className="card-content">
                  <div className="card-title">
                    {`${ formatNumberAsPerLocale( gamerDetail?.linkedGamesCount || 0 ) }`}
                  </div>
                  <div className="card-text">Linked Games</div>
                  <div
                    className="link cursor-pointer"
                    title="Show All"
                    onClick={() => {
                      history.push( {
                        pathname: `${ ROUTES.PARTNER_GAMER_LINKED_GAMES.replace(
                          ":gamerId",
                          gamerId
                        ) }`,
                        state: storePageState(),
                      } );
                    }}
                  >
                    Show All
                  </div>
                  <img src={gamesIcon} alt="link-icon" className="card-icon" />
                </div>
              </div>
            </Col>
            <Col sm={4}>
              <div className="gamer-card">
                <div className="card-content">
                  <div className="card-title">
                    {`${ formatNumberAsPerLocale( gamerDetail?.exchangeTransactionsCount || 0 ) }`}
                  </div>
                  <div className="card-text">Exchange Transactions</div>
                  <div
                    className="link cursor-pointer"
                    title="Show All"
                    onClick={() => {
                      history.push( {
                        pathname: `${ ROUTES.PARTNER_GAMER_EXCHANGE_TRANSACTION.replace(
                          ":gamerId",
                          gamerId
                        ) }`,
                        state: storePageState(),
                      } );
                    }}
                  >
                    Show All
                  </div>
                  <img src={transactionsIcon} alt="link-icon" className="card-icon"/>
                </div>
              </div>
            </Col>
            <Col sm={4}>
              <div className="gamer-card">
                <div className="card-content">
                  <div className="card-title">
                    {`${ formatNumberAsPerLocale( gamerDetail?.linkedOffersCount || 0 ) }`}
                  </div>
                  <div className="card-text">Offers</div>
                  <div
                    className="link cursor-pointer"
                    title="Show All"
                    onClick={() => {
                      history.push( {
                        pathname: `${ ROUTES.PARTNER_GAMER_LINKED_OFFERS.replace(
                          ":gamerId",
                          gamerId
                        ) }`,
                        state: storePageState(),
                      } );
                    }}
                  >
                    Show All
                  </div>
                  <img src={offerIcon} alt="link-icon" className="card-icon" />
                </div>
              </div>
            </Col>
            <Col sm={4}>
              <div className="gamer-card">
                <div className="card-content">
                  <div className="card-title">
                    {`${ formatNumberAsPerLocale( gamerDetail?.assetsCount || 0 ) }`}
                  </div>
                  <div className="card-text">
                    Assets
                    <ToolTipError gameNames={gamerDetail?.serverErrorsGameName} />
                  </div>
                  <span
                    className="link cursor-pointer"
                    title="Show All"
                    onClick={() => {
                      history.push( {
                        pathname: `${ ROUTES.PARTNER_GAMER_ASSETS.replace(
                          ":gamerId",
                          gamerId
                        ) }`,
                        state: storePageState(),
                      } );
                    }}
                  >
                    Show All
                  </span>
                  <img src={eventsIcon} alt="link-icon" className="card-icon" />
                </div>
              </div>
            </Col>
            <Col sm={4}>
              <div className="gamer-card">
                <div className="card-content">
                  <div className="card-title">
                    {`${ formatNumberAsPerLocale( gamerDetail?.linkedTournamentsCount || 0 ) }`}
                  </div>
                  <div className="card-text">
                    Tournaments
                  </div>
                  <span
                    className="link cursor-pointer"
                    title="Show All"
                    onClick={() => {
                      history.push( {
                        pathname: `${ ROUTES.PARTNER_GAMER_TOURNAMENTS.replace(
                          ":gamerId",
                          gamerId
                        ) }`,
                        state: storePageState(),
                      } );
                    }}
                  >
                    Show All
                  </span>
                  <img src={tournamentIcon} alt="link-icon" className="card-icon" />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>

    </>
  );
};

export default PartnerGamerSection;
