const SUBMIT_FOR_APPROVAL = "Submit for Approval";
const partnerGameConstant = {
  GAME_STATUS: [
    "All",
    "In-Development",
    SUBMIT_FOR_APPROVAL,
    "Approved",
    "Rejected",
    "Disabled",
  ],
  GAME_LIST_SORT: {
    GAME_NAME: "name",
    STATUS: "status",
    UPDATED_AT: "updated_date",
    SUBMITTED_AT: "submitted_date",
    APPROVED_AT: "approved_date",
    MODIFIED_AT: "updatedAt",
    LINKED_GAMERS: "LinkedGamers",
    PREMIUM_GAMERS: "PremiumGamers",
    ASSET_EXCHANGE_VALUE: "AssetExchangedValue",
    OFFER_REWARD_VALUE: "OfferRewardValue",
    TOURNAMENT_REWARD_VALUE: "TournamentRewardValue",
  },
  PARTNER_GAME_API_TYPES: {
    PARTNER_GAME_LIST_REQUEST: "PARTNER_GAME_LIST_REQUEST",
    UPLOAD_GAME_ICON: "UPLOAD_GAME_ICON",
    ADD_GAME: "ADD_GAME",
    PARTNER_ALL_GAME_LIST_REQUEST: "PARTNER_ALL_GAME_LIST_REQUEST",
    GET_GAME_DETAIL: "GET_GAME_DETAIL",
    UPDATE_GAME: "UPDATE_GAME",
    ADD_PARTNER_COMMENT: "ADD_PARTNER_COMMENT",
  },
  GAME_STATUS_WITH_KEY: [
    {
      label: "In Development",
      id: "1",
    },
    {
      label: SUBMIT_FOR_APPROVAL,
      id: "2",
    },
  ],
  GAME_STATUS_ID: {
    IN_DEVELOPMENT: "1",
    READY_FOR_TESTING: "2",
  },
  GAME_STATUS_DROPDOWN: [
    { label: "In-Development", value: 1 },
    { label: SUBMIT_FOR_APPROVAL, value: 2 },
    { label: "Approved", value: 3 },
    { label: "Rejected", value: 4 },
    { label: "Disabled", value: 5 },
  ],
  DATE_BY:
    {
      DATE_SUBMITTED: "Date Submitted",
      DATE_MODIFIED: "Date Modified",
      DATE_APPROVED: "Date Approved",
    },
};

export default partnerGameConstant;
