import axios from "axios";
import { arrayToObject } from "./helpers";

export const configureAxios = () => {
  //CONFIGURE AXIOS HERE
  axios.defaults.baseURL = CONFIG_SETTINGS.apiUrl;
  axios.defaults.headers.common.Authorization = null;
  axios.defaults.headers.patch[ "Content-Type" ] = "application/json";
  axios.defaults.transformResponse = [
    ( data ) => {
      if( typeof data === "string" ) {
        try {
          // TODO: refactor to not reassign data
          // eslint-disable-next-line no-param-reassign
          data = JSON.parse( data );

          if( Array.isArray( data ) ) {
            // TODO: refactor to not reassign data
            // eslint-disable-next-line no-param-reassign
            data = arrayToObject( data );
          }
        }
        catch ( e ) { /* Ignore */
          // TODO: Consider better logging than just console
          // eslint-disable-next-line no-console
          console.warn( e );
        }
      }

      return data;
    },
  ];
};
