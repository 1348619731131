import PropTypes from "prop-types";
import React from "react";
import { Form } from "react-bootstrap";

import { customFilterOption } from "../../../util/helpers";
import SelectBox from "../selectBox";

const ChooseGame = ( { values, games, errors, onSelect, onBlur } ) => (
  <>
    <Form.Group>
      <Form.Label>Choose Game</Form.Label>
      <SelectBox
        options={games}
        placeholder='Choose Game'
        onChange={value => onSelect( "game", value )}
        value={values.game}
        onBlur={onBlur}
        data-testid='selectGame'
        filterOption={customFilterOption}
      />
      <div className='invalid-feedback d-block'>
        {errors.game}
      </div>
    </Form.Group>
  </>
);

ChooseGame.propTypes = {
  values: PropTypes.object,
  games: PropTypes.array,
  errors: PropTypes.object,
  onSelect: PropTypes.func,
  onBlur: PropTypes.func,
};

export default ChooseGame;
