import PropTypes from "prop-types";
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";

import { useScroll } from "../../../hooks";
import { defaultUser } from "../../../util/icons";
import { ScrollPage } from "../customScrollBar";
import TableHeaderRow from "../tableHeadingRow";

const ParticipantList = ( {
  memoizedTableConfig,
  memoizedHandleClick,
  participantsList = [],
  values,
  handleChange,
} ) => {
  const { scrollEvent } = useScroll();

  const onScroll = ( e ) => {
    scrollEvent( e, participantsList, values, handleChange, "page" );
  };

  return (
    <>
      <div className="table-container tournament-participants-table">
        <div className="table-head">
          <div className='table-row'>
            <TableHeaderRow
              configData={memoizedTableConfig}
              onAscOrder={memoizedHandleClick}
              onDescOrder={memoizedHandleClick}
            />
          </div>
        </div>
        <Scrollbars renderThumbVertical={ScrollPage}
          className={"custom-scroll-height medium full-height }"}
          onScroll={onScroll}
        >
          <div className='table-body no-scrollbar'>
            {Boolean( participantsList.length ) && participantsList.map( ( participant, index ) => (
              <div className='table-row' key={index}>
                <div className="td">
                  <div className="participants-image-box">
                    <img
                      src={participant.gamer_profile}
                      alt="card"
                      onError={ e => ( e.target.onerror = null, e.target.src = defaultUser ) }
                      className='participants-image'
                    />
                    <img src={defaultUser} alt="default user" className="participants-image default" />
                  </div>
                </div>
                <div className="td">{ participant.nickname ?? "N/A" }</div>
                <div className="td">{ participant.rank ?? "N/A" }</div>
              </div>
            ) )}
            {!participantsList.length && (
              <div className='no-record' data-testid='no-record-found'>
                No Participant Found
              </div>
            )}
          </div>
        </Scrollbars>
      </div>
    </>
  );
};

ParticipantList.propTypes = {
  memoizedTableConfig: PropTypes.array,
  memoizedHandleClick: PropTypes.func,
  participantsList: PropTypes.array,
  values: PropTypes.object,
  handleChange: PropTypes.func,
};

export default ParticipantList;
