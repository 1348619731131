import { Error as ErrorIcon } from "@material-ui/icons";
import PropTypes from "prop-types";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";


const ToolTipError = ( {
  gameNames,
  message,
} ) => {
  return (
    ( ( Boolean( gameNames ) ) || ( Boolean( message ) ) ) && (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip-disabled">
            {message ? message : `Error in fetching assets from the game(s): ${ gameNames }.`}
          </Tooltip>
        }
      >
        <ErrorIcon className="help-icon text-orange mr-1" />
      </OverlayTrigger>
    )
  );
};

ToolTipError.propTypes = {
  gameNames: PropTypes.string,
  message: PropTypes.string,
};

export default ToolTipError;
