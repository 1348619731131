import PropTypes from "prop-types";
import React from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

const ReasonRejection = ( { onChange, rejectionReason, errorMessage } ) => (
  <Modal.Body className='pb-0'>
    <Form.Group className='mb-0'>
      <Form.Control
        as='textarea'
        type='text'
        name='rejectionReason'
        placeholder='Enter reason for rejection'
        value={rejectionReason}
        onChange={onChange}
      />
      <div className='invalid-feedback d-block'>{errorMessage}</div>
    </Form.Group>
  </Modal.Body>
);

ReasonRejection.propTypes = {
  onChange: PropTypes.func,
  rejectionReason: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default ReasonRejection;
