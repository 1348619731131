import eventConstant from "../util/eventConstant";
import { convertHtmlToEditorHtml, convertActualTime, convertMarkDownToDraft } from "../util/helpers";
import offerConstant from "../util/offerConstant";

const { FUNCTIONS, COMPARISONS, RANKING_OBJECTIVE } = offerConstant;
const { UNITS } = eventConstant;

export class OfferDetail {
  constructor( obj ) {
    this.offerName = obj.name || "";
    this.qxPoint = obj?.OfferRewards?.length ? Number( obj.OfferRewards[ 0 ].reward_value ) : 0;
    this.descriptionText = obj.description_text || "";
    this.rawDescription = obj.description || "";
    this.description = convertHtmlToEditorHtml( convertMarkDownToDraft( obj.description ) );
    this.isAvailableOfferSchedule = obj.OfferSchedule ? true : false;
    this.partnerId = obj.partner_id;
    this.offerId = obj.offer_id;
    this.achievements = obj.OfferAchievements?.map( ( achievement ) => {
      const {
        fieldTime: { d, h, m, s },
      } = convertActualTime( { seconds: achievement.time_limit_as_seconds } );
      const events = achievement.Events.map( ( event ) => {
        return {
          label: event.event_name,
          value: event.event_id,
        };
      } );
      const eventDescriptions = achievement.Events.map( ( event ) => {
        return event.description;
      } );

      return {
        rankingObjective: achievement.ranking_objective,
        // This property is provided for backward compatibility for editing offers/tournaments
        // as the editing functionality still only works against a single events
        event: events[ 0 ],
        events,
        name: achievement.name,
        descriptionText: achievement.description_text,
        rawDescription: achievement.description,
        description: convertHtmlToEditorHtml( convertMarkDownToDraft( achievement.description ) ),
        value: achievement.value,
        function: FUNCTIONS.find( fun => fun.value === achievement.function ),
        comparison: COMPARISONS.find( comp => comp.value === achievement.operator ),
        ranking: RANKING_OBJECTIVE.find( rank => rank.value === achievement.ranking_objective ),
        days: d,
        hours: h,
        minutes: m,
        seconds: s,
        eventUnit: UNITS
          .find( unit => unit.value === String( achievement.Events[ 0 ].EventUnit.event_unit_id ) )
          .label,
        // This property is provided for backward compatibility for editing offers/tournaments
        // as the editing functionality still only works against a single events
        eventDescription: eventDescriptions[ 0 ],
        eventDescriptions,
      };
    } ) || {};
    this.rewards = obj?.OfferRewards?.map( ( reward ) => {
      return {
        qxPoint: reward.reward_value,
        place: reward.place,
      };
    } ) || [];
  }
}
