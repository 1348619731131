import Enums from "../util/Enums";

const { SERVER_ERROR } = Enums;

const getAssetWithoutError = assets => assets.filter( asset => asset.status !== SERVER_ERROR );

export class AnalyticsAssetsList {
  constructor( obj ) {
    this.QxPoints = obj?.gameAssets?.[ 0 ]?.QxPoints;
    this.fullName = obj?.gamerInfo.fullName;
    this.gameName = obj?.gamerInfo.gameName;
    this.assets = getAssetWithoutError( obj?.gameAssets?.[ 0 ]?.assets || [] ).map( ( asset ) => {
      return {
        assetId: asset.assetId,
        gameId: asset.gameId,
        name: asset.name,
        quantity: asset.quantity,
        unitPriceLocalCurrency: asset.unitPriceLocalCurrency,
        unitPriceQxPoints: asset.unitPriceQxPoints,
        totalQxPoints: ( asset.quantity * asset.unitPriceQxPoints ),
      };
    } ) || [];
    this.isGameServerError = obj?.gameAssets?.[ 0 ]?.assets?.some( asset => asset.status === SERVER_ERROR ) || false;
  }
}
