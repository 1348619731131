import PropTypes from "prop-types";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useForm, useFilter, getUrlData } from "../../../../hooks";
import { getGamerDetailAction, getGamerOfferRewardTxn } from "../../../../store/actions/adminGamer";
import adminGamerConstant from "../../../../util/adminGamerConstant";
import OfferRewardTxnFilters from "./offerRewardTxnFilters";
import OfferRewardTxnList from "./offerRewardTxnList";

const { GAMER_USERS_API_TYPES } = adminGamerConstant;

const OfferRewardTxn = ( {
  location,
  showFilter,
  addQuery,
  dispatch,
  searchKey = "",
  apiStatus,
  route,
  orderBy,
  memoizedTableConfig,
  setPreviousData,
  isGamerTournaments,
} ) => {
  const { gamerId: URLString } = useParams();
  const { 0: gamerId } = String( URLString ).split( "?" );
  const {
    setPureData, filteredData, filterData, handleSorting, clearSelectedFilter, handleFilterChange,
  } = useFilter();
  const {
    status,
    apiType,
    rewardTxnList,
    rewardTxnFilters,
    usdPerQxPoint,
  } = useSelector( state => ( {
    status: state.adminGamer.status,
    apiType: state.adminGamer.apiType,
    rewardTxnList: state.adminGamer.gamerRewardTxn.rewardTxnList,
    rewardTxnFilters: state.adminGamer.gamerRewardTxn.rewardTxnFilters,
    usdPerQxPoint: state.adminDetail.data.usdPerQxPoint,
  } ) );
  const { gameNames, offerNames, partnerNames, qxPointMinMax, gamerPlaceMinMax } = rewardTxnFilters;

  const { values, handleChange } = useForm(
    {
      searchKey: "",
      sortBy: "offerName",
      sortOrder: orderBy.ASC,
      gameNames: [],
      partnerNames: [],
      offerNames: [],
      startDate: null,
      endDate: null,
      minQxPoint: 0,
      maxQxPoint: 0,
      minGamerPlace: 0,
      maxGamerPlace: 0,
      searchCol: [ "offerName", "gameName", "partnerName" ],
      compareKeyArray: [ "QXPoints" ],
    },
    {}
  );

  const memoizedHandleClick = useCallback(
    ( sortBy, orderType ) => handleSorting( sortBy, orderType, handleChange, addQuery, location.state ),
    []
  );

  const requestPayload = Object.assign(
    {
      isSingleDate: true,
      searchKey: values.searchKey,
      sortOrder: values.sortOrder,
      sortBy: values.sortBy,
      gameNames: values.gameNames?.map( value => value.value ) || [],
      partnerNames: values.partnerNames?.map( value => value.value ) || [],
      offerNames: values.offerNames?.map( value => value.value ) || [],
      optInTypes: values.optInTypes?.map( value => value.value ) || [],
      startDate: values.startDate,
      endDate: values.endDate,
      searchCol: values.searchCol,
      minQxPoint: values.minQxPoint,
      maxQxPoint: values.maxQxPoint,
      minGamerPlace: values.minGamerPlace,
      maxGamerPlace: values.maxGamerPlace,
      compareKeyArray: values.compareKeyArray,
    }
  );

  useEffect( () => {
    handleChange( "searchKey", searchKey );
  }, [ searchKey ] );

  useEffect( () => {
    dispatch(
      getGamerOfferRewardTxn( gamerId, { type: isGamerTournaments ? "tournamentRewardTxn" : "offerRewardTxn" } )
    );
  }, [] );

  useEffect( () => {
    if(
      ( status === apiStatus ) &&
      ( apiType === GAMER_USERS_API_TYPES.GET_GAMER_OFFER_REWARD_TXN )
    ) {
      dispatch( getGamerDetailAction( gamerId ) );
      handleChange( "minQxPoint", qxPointMinMax.min );
      handleChange( "maxQxPoint", qxPointMinMax.max );
      handleChange( "minGamerPlace", gamerPlaceMinMax.min );
      handleChange( "maxGamerPlace", gamerPlaceMinMax.max );
      setPureData( rewardTxnList );
      getUrlData( handleChange );
      filterData( requestPayload );
      setPreviousData();
    }
  }, [ status, apiType ] );

  useEffect( () => {
    filterData( requestPayload );
  }, [
    values.startDate,
    values.endDate,
    values.offerNames,
    values.partnerNames,
    values.gameNames,
    values.sortBy,
    values.sortOrder,
    values.searchKey,
    values.optInTypes,
    values.minQxPoint,
    values.maxQxPoint,
    values.minGamerPlace,
    values.maxGamerPlace,
  ] );

  return (
    <>
      <div className={`filter-tabs-wrapper mb-0 ${ !showFilter ? "" : "remove-filter" }`}>
        <OfferRewardTxnFilters
          showFilter={showFilter}
          gameList={gameNames}
          partnerList={partnerNames}
          offerList={offerNames}
          handleChange={handleChange}
          values={values}
          locationState={location.state}
          isGamerTournaments={isGamerTournaments}
          addQuery={addQuery}
          qxPointMinMax={qxPointMinMax}
          handleFilterChange={handleFilterChange}
          clearSelectedFilter={clearSelectedFilter}
          gamerPlaceMinMax={gamerPlaceMinMax}
        />
      </div>
      <OfferRewardTxnList
        rewardTxns={filteredData}
        route={route}
        gamerId={gamerId}
        location={location}
        showFilter={showFilter}
        usdPerQxPoint={usdPerQxPoint}
        isGamerTournaments={isGamerTournaments}
        memoizedTableConfig={memoizedTableConfig}
        memoizedHandleClick={memoizedHandleClick}
      />
    </>
  );
};

OfferRewardTxn.propTypes = {
  location: PropTypes.object,
  showFilter: PropTypes.bool,
  addQuery: PropTypes.func,
  dispatch: PropTypes.func,
  route: PropTypes.string,
  orderBy: PropTypes.object,
  searchKey: PropTypes.string,
  apiStatus: PropTypes.string,
  memoizedTableConfig: PropTypes.func,
  setPreviousData: PropTypes.func,
  isGamerTournaments: PropTypes.bool,
};

export default OfferRewardTxn;
