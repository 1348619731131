import React, { useMemo, useCallback, useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { useAddQuery, useFilter, useForm } from "../../../hooks";
import { getGamerAssetAction, getGamerDetailAction } from "../../../store/actions/adminGamer";
import adminGamerConstant from "../../../util/adminGamerConstant";
import constant from "../../../util/constant";
import { useSetUrlParams, checkFilterApplied, handleParseJSON } from "../../../util/helpers";
import { gamerAssetsTableConfigs } from "../../../util/tableConstant";
import CustomSlider from "../../shared/customSlider";
import MultiSelectCheckbox from "../../shared/multiSelectCheckbox";
import AdminAssetsHeader from "./adminAssetsHeader";
import AssetList from "./assetList";

const { GAMER_USERS_API_TYPES } = adminGamerConstant;
const { API_STATUS, FILTERS_NAME, ORDER_BY, BRANDING: { APP_SHORT_NAME } } = constant;

const Assets = () => {
  const { addQuery } = useAddQuery();
  const dispatch = useDispatch();
  const location = useLocation();
  const { gamerId } = useParams();
  const paramsQuery = new URLSearchParams( window.location.search );
  const { setPureData, filteredData, filterData } = useFilter();
  const { apiType,
    status,
    assets,
    gameNames,
    gamerDetail,
    assetNames,
    assetIds,
    qxPointMinMax,
  } = useSelector( state => ( {
    apiType: state.adminGamer.apiType,
    status: state.adminGamer.status,
    assets: state.adminGamer.assets.assets,
    gamerDetail: state.adminGamer.gamerDetail,
    gameNames: state.adminGamer.assets.gameNames,
    assetNames: state.adminGamer.assets.assetNames,
    assetIds: state.adminGamer.assets.assetIds,
    qxPointMinMax: state.adminGamer.assets.qxPointMinMax,
  } ) );
  const { values, handleChange } = useForm(
    {
      searchKey: "",
      sortBy: "assetName",
      sortOrder: ORDER_BY.ASC,
      assetId: [],
      assetName: [],
      gameId: [],
      minQxPoint: 0,
      maxQxPoint: 0,
      compareKeyArray: [ "QXPoints" ],
    },
    {}
  );
  const sliderMinMaxObj = {
    minQxPoint: qxPointMinMax.min,
    maxQxPoint: qxPointMinMax.min,
  };
  const isFilterApplied = checkFilterApplied( window.location.search, FILTERS_NAME.GAMER_ASSETS, sliderMinMaxObj );
  const requestParams = Object.assign(
    {
      sortOrder: values.sortOrder,
      perPage: 200,
      searchCol: [ "assetName", "gameName" ],
      assetId: values.assetId?.map( value => value.value ) || [],
      assetName: values.assetName?.map( value => value.value ) || [],
      gameIds: values.gameIds?.map( value => value.value ) || [],
      searchKey: values.searchKey,
      sortBy: values.sortBy,
      minQxPoint: values.minQxPoint,
      maxQxPoint: values.maxQxPoint,
      compareKeyArray: values.compareKeyArray,
    }
  );

  const memoizedTableConfig = useMemo( () => gamerAssetsTableConfigs, [] );

  useEffect( () => {
    dispatch( getGamerAssetAction( gamerId ) );
  }, [] );

  const updateStateFromUrl = () => {
    if( paramsQuery.get( "sortBy" ) && paramsQuery.get( "sortOrder" ) ) {
      handleChange( "sortBy", paramsQuery.get( "sortBy" ) );
      handleChange( "sortOrder", paramsQuery.get( "sortOrder" ) );
    }

    if( paramsQuery.get( "searchKey" ) ) {
      handleChange( "searchKey", paramsQuery.get( "searchKey" ) );
    }

    if( paramsQuery.get( "assetName" ) ) {
      handleChange( "assetName", handleParseJSON( paramsQuery.get( "assetName" ) ) );
    }

    if( paramsQuery.get( "assetId" ) ) {
      handleChange( "assetId", handleParseJSON( paramsQuery.get( "assetId" ) ) );
    }

    if( paramsQuery.get( "gameIds" ) ) {
      handleChange( "gameIds", handleParseJSON( paramsQuery.get( "gameIds" ) ) );
    }

    if( paramsQuery.get( "minQxPoint" ) ) {
      handleChange( "minQxPoint", handleParseJSON( paramsQuery.get( "minQxPoint" ) ) );
    }

    if( paramsQuery.get( "maxQxPoint" ) ) {
      handleChange( "maxQxPoint", handleParseJSON( paramsQuery.get( "maxQxPoint" ) ) );
    }
  };

  useEffect( () => {
    if(
      status === API_STATUS.SUCCESS &&
      apiType === GAMER_USERS_API_TYPES.GET_GAMER_ASSETS
    ) {
      dispatch( getGamerDetailAction( gamerId ) );
      handleChange( "minQxPoint", qxPointMinMax.min );
      handleChange( "maxQxPoint", qxPointMinMax.max );
      setPureData( assets );
      updateStateFromUrl();
      filterData( requestParams );
    }
  }, [ status, apiType ] );

  useEffect( () => {
    filterData( requestParams );
  }, [
    values.sortOrder,
    values.sortBy,
    values.searchKey,
    values.assetId,
    values.assetName,
    values.gameIds,
    values.minQxPoint,
    values.maxQxPoint,
  ] );

  const handleSorting = ( sortBy, sortingType ) => {
    handleChange( "sortBy", sortBy );
    handleChange( "sortOrder", sortingType );
    addQuery( useSetUrlParams( "sortBy", sortBy ), location.state );
    addQuery( useSetUrlParams( "sortOrder", sortingType ), location.state );
  };

  const handleSearch = ( e ) => {
    const { name, value } = e.target;

    handleChange( name, value );
    addQuery( useSetUrlParams( name, value ), location.state );
  };

  const memoizedHandleClick = useCallback( ( sortBy, orderType ) => handleSorting( sortBy, orderType ), [] );

  const pointsHandler = ( e, newValue ) => {
    addQuery( useSetUrlParams( "minQxPoint", newValue[ 0 ] ), location.state );
    addQuery( useSetUrlParams( "maxQxPoint", newValue[ 1 ] ), location.state );
    handleChange( "minQxPoint", newValue[ 0 ] );
    handleChange( "maxQxPoint", newValue[ 1 ] );
  };

  const assetsArray = useMemo( () => [ values.assetName, values.assetId, values.gameIds ] );

  const assetclearHandle = ( parmas ) => {
    if( parmas ) {
      addQuery( useSetUrlParams( parmas, "" ), location.state );
      handleChange( parmas, [] );
    }
  };

  const assetlistHandle = ( obj, listName ) => {
    if( obj.length > 0 ) {
      handleChange( listName, obj );
      addQuery( useSetUrlParams( listName, JSON.stringify( obj ) ), location.state );
    }
    else {
      addQuery( useSetUrlParams( listName, "" ), location.state );
      handleChange( listName );
    }
  };

  const handleAssetChange = useCallback(
    [ obj => assetlistHandle( obj, "assetName" ),
      obj => assetlistHandle( obj, "assetId" ),
      obj => assetlistHandle( obj, "gameIds" ) ],
    []
  );
  const assetHandleClear = useCallback(
    parmas => assetclearHandle( parmas ), []
  );

  const [ showFilter, setShowFilter ] = useState( false );
  const showFilterScreen = () => setShowFilter( !showFilter );

  return (
    <div className="main-wrapper">
      <div className="main-right-wrapper">
        <AdminAssetsHeader
          gamerId={gamerId}
          searchText={values.searchKey}
          searchHandle={handleSearch}
          showFilter={showFilter}
          showFilterScreen={showFilterScreen}
          isFilterApplied={isFilterApplied}
          gamerDetail={gamerDetail}
        />
        <div className='filter-tabs-wrapper mb-0'>
          <div className='filter-boxes offer-filter mr-0'>
            <div className={`filter-boxes mr-0 bottom-margin ${ !showFilter ? "" : "remove-filter" }`}>
              <Row>
                <Col md={3}>
                  <Form.Group className='custom-multiselect mb-0' data-testid="full-name">
                    <Form.Label>Asset Name</Form.Label>
                    <MultiSelectCheckbox
                      multiOptionsList={assetNames}
                      handleSelectChange={handleAssetChange[ 0 ]}
                      multiSelectedItems={assetsArray[ 0 ]}
                      handleClearMultiSelect={() => assetHandleClear( "assetName" )}
                      name='assetName'
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className='custom-multiselect mb-0' data-testid="full-name">
                    <Form.Label>Asset ID</Form.Label>
                    <MultiSelectCheckbox
                      multiOptionsList={assetIds}
                      handleSelectChange={handleAssetChange[ 1 ]}
                      multiSelectedItems={assetsArray[ 1 ]}
                      handleClearMultiSelect={() => assetHandleClear( "assetId" )}
                      name='assetId'
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className='custom-multiselect mb-0' data-testid="full-name">
                    <Form.Label>Game</Form.Label>
                    <MultiSelectCheckbox
                      multiOptionsList={gameNames}
                      handleSelectChange={handleAssetChange[ 2 ]}
                      multiSelectedItems={assetsArray[ 2 ]}
                      handleClearMultiSelect={() => assetHandleClear( "gameIds" )}
                      name='gameIds'
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className='custom-multiselect mb-0' data-testid="full-name">
                    <CustomSlider
                      values={values}
                      pointsHandler={pointsHandler}
                      handleChange={handleChange}
                      min={qxPointMinMax.min}
                      max={qxPointMinMax.max}
                      minName={"minQxPoint"}
                      maxName={"maxQxPoint"}
                      label={`${ APP_SHORT_NAME } Points`}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <AssetList
          gamerDetail={gamerDetail}
          assets={filteredData}
          memoizedTableConfig={memoizedTableConfig}
          memoizedHandleClick={memoizedHandleClick}
          showFilter={showFilter}
        />
      </div>
    </div>
  );
};

export default Assets;
