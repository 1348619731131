export class AdminPartnerLinkedGamerDetails {
  constructor( obj ) {
    this.acqyrCashBalance = obj.acqyrCashBalance;
    this.fullNames = obj.fullName || "N/A";
    this.userId = obj.userId || "";
    this.linkedGamesCount = obj.LinkedGamesCount;
    this.linkedGames = obj.LinkedGames?.length
      ? obj.LinkedGames.map( ( val ) => {
        return {
          gameName: val.name || "",
          iconUrl: val.icon_url || "",
        };
      } ).sort( ( a, b ) => a.gameName.localeCompare( b.gameName ) )
      : [];
  }
}
