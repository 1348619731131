import HelpIcon from "@material-ui/icons/Help";
import { EditorState } from "draft-js";
import * as moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useSelector, useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useHistory, useParams, useLocation } from "react-router-dom";

// local-imports
import { DraftTournamentRequest } from "../../../entities/draftTournamentRequest";
import { OfferTemplateRequest } from "../../../entities/newOfferTemplateRequest";
import { PartnerOfferFromScratchRequest } from "../../../entities/partnerOfferFromScratchRequest";
import { useForm, useModal, useInputChange } from "../../../hooks";
import { getPartnerEventDetailAction } from "../../../store/actions/partnerEvent";
import { getPartnerLookupAction, getOfferListAction } from "../../../store/actions/partnerLookup";
import {
  clearOfferDetail,
  getPartnerOfferAction,
  addNewOfferTemplateAction,
  updatePartnerOfferTemplateAction,
  addOfferTemplateFromScratchAction,
  getPartnerOfferTemplateDetailAction
} from "../../../store/actions/partnerOffer";
import {
  addNewPartnerOfferDraftAction,
  getPartnerOfferDraftDetailAction,
  updatePartnerOfferDraftAction
} from "../../../store/actions/partnerOfferDraft";
import Enums from "../../../util/Enums";
import adminConstant from "../../../util/adminConstant";
import constant from "../../../util/constant";
import eventConstant from "../../../util/eventConstant";
import {
  doAchievementsHaveNoError,
  doRewardsHaveNoError,
  getTournamentHeaderData,
  getBackNavigationRoute,
  getUniqueName,
  randomString
} from "../../../util/helpers";
import offerConstant from "../../../util/offerConstant";
import tournamentConstant from "../../../util/tournamentConstant";
import { validateAchievements, validateRewards } from "../../../util/validateMultipleFields";
import BackButton from "../../shared/backButton";
import { OfferTemplate, ScheduleTournament, ChooseTemplate } from "../../shared/offers";
import ModalPopup from "../modal";

const { EVENT_API_TYPES } = eventConstant;
const { PER_PAGE_RECORDS_LIMIT } = Enums;
const { MODAL_TYPE_DETAIL } = adminConstant;
const { TOURNAMENT_TYPE_STATUS } = tournamentConstant;
const { API_STATUS, API_TYPES, ADVERTISE_OPTIONS, OPT_IN_STATUS, ROUTES } = constant;
const { DRAFT_ENTITY_NAME, REQUEST_TYPE, OFFER_API_TYPES, TYPE_TO_BE_EDITED, OFFER_TYPE_STATUS } = offerConstant;

const initialState = {
  description: EditorState.createEmpty(),
  descriptionText: "",
  tournamentName: "",
  startDate: false,
  endDate: false,
  rawDescription: "",
  achievementErrors: [],
  tournamentTemplate: "",
  game: "",
  isEditOffer: false,
  isEditDraft: false,
  isTournament: false,
  startTime: "",
  endTime: "",
  rewardsErrors: [],
  timeZone: {},
  optInType: OPT_IN_STATUS.NO,
  membershipId: "",
  entryFee: "",
  minParticipants: "",
  maxParticipants: "",
  minParticipantsNoLimit: true,
  maxParticipantsNoLimit: true,
  advertiseToGamer: { ...ADVERTISE_OPTIONS[ 0 ], label: ADVERTISE_OPTIONS[ 0 ]?.label.replace( "offer", "tournament" ) },
  noOfDays: 0,
  advertiseDate: false,
};
const initialDirty = {
  descriptionText: false,
  tournamentName: false,
};
const templateInitialDirty = {
  descriptionText: false,
  tournamentName: false,
  startDate: false,
  endDate: false,
  game: false,
  startTime: false,
  endTime: false,
  timeZone: false,
  minParticipants: true,
  maxParticipants: true,
  entryFee: true,
  noOfDays: true,
  advertiseDate: true,
};

const CreateAndEditTournament = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { handleInputChanges } = useInputChange();
  const backScreens = [ "partnerTournaments" ];
  const isAdminAddEditOffer = false;
  const {
    apiType,
    status,
    configuration,
    lookup,
    eventApiType,
    eventStatus,
    partnerEventDetail,
    offerDetail,
    membershipTypes,
    lookupStatus,
    lookupApiType,
    offerNames,
    draftApiType,
    draftStatus,
    offers,
    partnerId,
  } = useSelector( state => ( {
    apiType: state.partnerOffer.apiType,
    status: state.partnerOffer.status,
    lookupStatus: state.partnerLookup.status,
    lookupApiType: state.partnerLookup.apiType,
    lookup: state.partnerLookup.lookup,
    configuration: state.partner.partnerDetail?.configuration || {},
    offerNamesStatus: state.partnerOfferNames.status,
    offerNamesApiType: state.partnerOfferNames.apiType,
    eventApiType: state.partnerEvent.apiType,
    eventStatus: state.partnerEvent.status,
    partnerEventDetail: state.partnerEvent.partnerEventDetail,
    offerDetail: state.partnerOffer.offerDetail,
    membershipTypes: state.partner.partnerDetail?.membershipTypes || [],
    offerNames: state.partnerOfferNames.offerNames,
    draftApiType: state.partnerOfferDraft.apiType,
    draftStatus: state.partnerOfferDraft.status,
    offers: state.partnerOffer.offers,
    partnerId: state.partner.partnerDetail.partnerId,
  } ) );
  const paramsQuery = new URLSearchParams( window.location.search );
  const tournamentType = paramsQuery.get( "status" );
  const isClone = paramsQuery.get( "clone" );
  const fromScreen = paramsQuery.get( "from" );
  const isTournamentType = true;
  const isTournamentFromTemplate = paramsQuery.get( "fromTemplate" );
  const statusType = paramsQuery.get( "statusType" );
  const [ errorMessage, setErrorMessage ] = useState( "" );
  const [ isUpdatedOffers, getUpdatedOffers ] = useState( false );
  const { tournamentId: urlParamString } = useParams();
  const { 0: tournamentId } = String( urlParamString ).split( "?" );
  const { isOpened, closeModal, openModal, modalType, setModalType } = useModal();
  const { values, errors, isError, handleChange, showAllErrorMessage, changeDirtyObj } =
    useForm(
      { ...initialState,
        achievements: [ {
          name: "",
          description: EditorState.createEmpty(),
          descriptionText: "",
          rawDescription: "",
          event: "",
          function: "",
          ranking: "",
          eventDescription: "",
          eventUnit: "",
        } ],
        rewards: [
          { qxPoint: 0, place: 1 },
        ],
      },
      tournamentType === TOURNAMENT_TYPE_STATUS.TOURNAMENT_TEMPLATE ? initialDirty : templateInitialDirty
    );
  const headerDetail = getTournamentHeaderData( statusType ?? tournamentType, offerDetail?.entityName, isClone );

  useEffect( () => {
    dispatch( getPartnerLookupAction( { entities: "TournamentTemplate,ApprovedGame,EnabledEvent" } ) );

    if( tournamentType === TOURNAMENT_TYPE_STATUS.TOURNAMENT ) {
      handleChange( "isTournament", true );
    }
  }, [] );

  useEffect( () => {
    if( isClone && ( status === API_STATUS.SUCCESS ) && ( apiType === OFFER_API_TYPES.GET_PARTNER_OFFER_TEMPLATE_DETAIL ) ) {
      dispatch( getPartnerOfferAction( {
        perPage: PER_PAGE_RECORDS_LIMIT.MAX,
        partnerId: partnerId,
        currentTimeZone: new Date().toUTCString(),
        offerType: "tournament",
      } ) );
      getUpdatedOffers( true );
    }
  }, [ status, apiType ] );

  useEffect( () => {
    if( isClone && isUpdatedOffers ) {
      updateTournamentName();
    }
  }, [ offers ] );

  // TODO: refactor to avoid complexity
  // eslint-disable-next-line complexity
  useEffect( () => {
    if( ( status === API_STATUS.SUCCESS ) && ( ( apiType === OFFER_API_TYPES.ADD_PARTNER_NEW_OFFER_TEMPLATE ) || ( apiType === OFFER_API_TYPES.UPDATE_PARTNER_OFFER_TEMPLATE ) ) ) {
      toastr.success( `Tournament template ${ values.isEditOffer ? "updated" : "added" } successfully` );
      navigateBackToListing();
    }
    else if( ( status === API_STATUS.SUCCESS ) && ( apiType === OFFER_API_TYPES.ADD_PARTNER_OFFER_FROM_SCRATCH ) ) {
      toastr.success( "Tournament added successfully" );
      navigateBackToListing();
    }
    else if( ( draftStatus === API_STATUS.SUCCESS ) &&
    ( draftApiType === OFFER_API_TYPES.ADD_ADMIN_NEW_DRAFT_OFFER ) && ( !values.isEditDraft ) ) {
      toastr.success( ( tournamentType === TOURNAMENT_TYPE_STATUS.TOURNAMENT ) ? "Tournament draft added successfully" : "Tournament template draft added successfully" );
      navigateBackToListing();
    }
    else if( ( draftStatus === API_STATUS.SUCCESS ) &&
    ( draftApiType === OFFER_API_TYPES.UPDATE_PARTNER_OFFER_DRAFT_TEMPLATE ) && values.isEditDraft ) {
      toastr.success( ( offerDetail.entityName !== DRAFT_ENTITY_NAME.TOURNAMENT_TEMPLATE_DRAFT ) ? "Tournament draft updated successfully" : "Tournament template draft updated successfully" );
      navigateBackToListing();
    }
    else if( eventStatus === API_STATUS.SUCCESS && eventApiType === EVENT_API_TYPES.GET_PARTNER_EVENT_DETAIL ) {
      const { achievements } = values;
      const updatedAchievement = achievements.map( ( achievement ) => {
        if( achievement.event.value === partnerEventDetail.eventId ) {
          return {
            ...achievement,
            eventDescription: partnerEventDetail.description,
            eventUnit: partnerEventDetail.unitName,
          };
        }

        return achievement;
      } );

      handleChange( "achievements", updatedAchievement );
    }
    else if( ( ( status === API_STATUS.SUCCESS ) && ( apiType === OFFER_API_TYPES.GET_PARTNER_OFFER_TEMPLATE_DETAIL ) )
    || ( ( draftStatus === API_STATUS.SUCCESS ) && ( draftApiType === OFFER_API_TYPES.GET_PARTNER_DRAFT_OFFER_TEMPLATE_DETAIL ) ) ) {
      handleChange( "achievements", offerDetail.achievements );
      handleChange( "description", offerDetail.description );
      handleChange( "descriptionText", offerDetail.descriptionText );
      handleChange( "rawDescription", offerDetail.rawDescription );
      handleChange( "rewards", offerDetail.rewards );
      handleChange( "ranking", offerDetail.ranking );
      handleChange( "achievementErrors", validateAchievements( offerDetail.achievements, values.achievementErrors, isTournamentType ) );
      handleChange( "rewardsErrors", validateRewards( offerDetail.rewards, values.isTournament ) );

      if( offerDetail?.offerTemplate ) {
        handleChange( "tournamentTemplate", offerDetail.offerTemplate );
        handleChange( "isTournament", true );
      }

      handleChange( "optInType", offerDetail.optIn.optInType );
      handleChange( "membershipId", offerDetail.optIn.membershipId );
      handleChange( "entryFee", offerDetail.optIn.entryFee );
      handleChange( "minParticipants", offerDetail.optIn.minParticipants );
      handleChange( "maxParticipants", offerDetail.optIn.maxParticipants );
      handleChange( "minParticipantsNoLimit", offerDetail.optIn.minParticipantsNoLimit );
      handleChange( "maxParticipantsNoLimit", offerDetail.optIn.maxParticipantsNoLimit );

      if( offerDetail?.offerTemplate && values.isEditDraft ) {
        handleChange( "offerTemplate", offerDetail.offerTemplate );
        handleChange( "isTournament", true );
      }

      if( values.offerTemplate || offerDetail.isTournament || ( offerDetail.offerType === TYPE_TO_BE_EDITED.OFFER ) ) {
        handleChange( "isTournament", true );
      }

      if( values.isEditOffer || values.isEditDraft || isTournamentFromTemplate ) {
        if( !isClone && !values.offerName && !isTournamentFromTemplate ) {
          handleChange( "tournamentName", offerDetail.offerName );
        }

        if( isTournamentFromTemplate && values.isEditDraft ) {
          handleChange( "tournamentName", offerDetail.offerName );
        }

        if( isClone && values.isEditDraft ) {
          handleChange( "tournamentName", offerDetail.offerName );
        }
      }

      if( isClone && offerDetail.isAvailableOfferSchedule && ( !values.isEditDraft ) ) {
        handleChange( "isTournament", true );
      }

      if( isClone && ( !values.tournamentName ) && ( !values.isEditDraft ) ) {
        updateTournamentName();
      }

      if( offerDetail.isAvailableOfferSchedule ) {
        handleChange( "game", offerDetail.offerSchedule.selectedGame );
        handleChange( "offerCanBeAchievedCount", offerDetail.offerSchedule.maxRewardCount );
        handleChange( "startDate", offerDetail.offerSchedule.startDateForValue );
        handleChange( "endDate", offerDetail.offerSchedule.endDateForValue );
        handleChange( "advertiseToGamer", { ...offerDetail.advertiseToGamer, label: offerDetail.advertiseToGamer?.label.replace( "offer", "tournament" ) } );
        handleChange( "noOfDays", offerDetail.noOfDays );
        handleChange( "advertiseDate", offerDetail.advertiseDate );
        handleChange( "startTime", offerDetail.offerSchedule.startTime );
        handleChange( "endTime", offerDetail.offerSchedule.endTime );
        handleChange( "timeZone", offerDetail.offerSchedule.timeZone );
      }

      if( ( !offerDetail?.offerTemplate ) && ( !values.tournamentTemplate ) && ( !offerDetail.isOffer ) && ( !isClone ) && ( !isTournamentFromTemplate ) ) {
        changeDirtyObj( initialDirty );
      }
    }
  }, [ eventStatus, eventApiType, status, apiType, draftApiType, draftStatus ] );

  const handleInputChange = ( e ) => {
    handleInputChanges( e, handleChange );
  };

  useEffect( () => {
    if( values.membershipId === "" ) {
      handleChange( "membershipId", membershipTypes[ 0 ].id );
    }
  }, [] );

  useEffect( () => {
    if( tournamentId === "0" ) {
      dispatch( clearOfferDetail() );
    }

    dispatch( getOfferListAction( { entities: "Tournament" } ) );

    return () => dispatch( clearOfferDetail() );
  }, [] );

  useEffect( () => {
    if( ( lookupStatus === API_STATUS.SUCCESS ) && ( lookupApiType === API_TYPES.GET_PARTNER_LOOKUP ) ) {
      if( tournamentType === OFFER_TYPE_STATUS.DRAFT ) {
        handleChange( "isEditDraft", true );
        dispatch( getPartnerOfferDraftDetailAction( tournamentId, true ) );
      }
      else if( tournamentId !== "0" ) {
        dispatch( getPartnerOfferTemplateDetailAction( tournamentId, true ) );

        if( !isTournamentFromTemplate ) {
          handleChange( "isEditOffer", true );
        }

        if( ( !isClone ) && ( !isTournamentFromTemplate ) ) {
          changeDirtyObj( initialDirty );
        }
      }
    }
  }, [ lookupStatus ] );

  const onSelectedEvent = eventId => dispatch( getPartnerEventDetailAction( eventId ) );

  const handleScheduleInputChange = ( e ) => {
    const { name, value } = e.target;

    handleChange( name, value );
  };

  const handleDateChange = ( name, e ) => {
    if( e ) {
      handleChange( [ name ], new Date( e ) );
    }
    else {
      handleChange( [ name ], false );

      if( name === "startDate" ) {
        handleChange( "advertiseDate", false );
        handleChange( "noOfDays", 0 );
      }
    }
  };

  const handleSelectInput = ( name, value = "" ) => {
    handleChange( name, value );

    if( name === "advertiseToGamer" ) {
      handleChange( "noOfDays", 0 );
      handleChange( "advertiseDate", false );
    }

    if( name === "tournamentTemplate" ) {
      if( value?.value ) {
        dispatch( getPartnerOfferTemplateDetailAction( value.value ) );
      }
      else {
        handleChange( "achievements", [ {
          name: "",
          description: EditorState.createEmpty(),
          descriptionText: "",
          rawDescription: "",
          event: "",
          function: "",
          ranking: "",
          eventDescription: "",
          eventUnit: "",
        } ] );
        handleChange( "description", EditorState.createEmpty() );
        handleChange( "descriptionText", "" );
        handleChange( "rawDescription", "" );
        handleChange( "qxPoint", 0 );
      }
    }
  };

  const handleSubmit = ( params ) => {
    if( params === "draft" ) {
      handleOpenModal( MODAL_TYPE_DETAIL.DRAFT_SAVE_ALERT.type );
    }
    else {
      showAllErrorMessage();
      handleChange( "achievementErrors", validateAchievements( values.achievements, values.achievementErrors, null, isTournamentType ) );

      const noErrorInAchievements = doAchievementsHaveNoError( values.achievements, values.achievementErrors, isTournamentType );
      let noErrorInRewards = true;

      if( ( tournamentType === TOURNAMENT_TYPE_STATUS.TOURNAMENT ) || ( tournamentType === OFFER_TYPE_STATUS.DRAFT ) ) {
        handleChange( "rewardsErrors", validateRewards( values.rewards, values.isTournament ) );
        noErrorInRewards = doRewardsHaveNoError( values.rewards, values.isTournament );
      }

      if( ( !isError ) && noErrorInAchievements && noErrorInRewards ) {
        const { partnerCostUsdPerQxPoint } = configuration;

        if( ( ( tournamentType === TOURNAMENT_TYPE_STATUS.TOURNAMENT_TEMPLATE ) || ( !values.isTournament ) ) && ( !values.isEditOffer ) ) {
          let requestObj = new OfferTemplateRequest( { ...values, partnerCostUsdPerQxPoint, requestType: REQUEST_TYPE.TOURNAMENT_TEMPLATE } );

          requestObj = ( ( !isClone ) && tournamentId && ( tournamentId !== "0" ) ) ? { ...requestObj, offerId: tournamentId } : requestObj;
          dispatch( addNewOfferTemplateAction( requestObj ) );
        }
        else if( ( tournamentType === TOURNAMENT_TYPE_STATUS.TOURNAMENT_TEMPLATE ) || values.isTournament ) {
          let requestObj = new PartnerOfferFromScratchRequest( { ...values, partnerCostUsdPerQxPoint, requestType: REQUEST_TYPE.TOURNAMENT } );

          requestObj = ( ( !isClone ) && tournamentId && ( tournamentId !== "0" ) ) ? { ...requestObj, offerId: tournamentId } : requestObj;

          const timeZoneBasedStartDate = moment( requestObj.start_date ).tz( requestObj.timeZone )
            .format();
          const timeZoneBasedCurrentDate = moment().tz( requestObj.timeZone )
            .format();

          // moment.unix(getUnixTimeStamp)-function is used to get the number of seconds.
          if( moment( timeZoneBasedStartDate ).unix() >= moment( timeZoneBasedCurrentDate ).unix() ) {
            dispatch( addOfferTemplateFromScratchAction( requestObj ) );
          }
          else {
            toastr.error( "Tournament cannot be created with start date and time prior to now." );
          }
        }
        else if( values.isEditOffer ) {
          const requestObj = new OfferTemplateRequest( { ...values, partnerCostUsdPerQxPoint, offerId: tournamentId, requestType: REQUEST_TYPE.TOURNAMENT_TEMPLATE } );

          dispatch( updatePartnerOfferTemplateAction( requestObj ) );
        }
      }
    }
  };

  const handleOpenModal = ( mType ) => {
    setModalType( mType );
    openModal();
  };

  const handleCloseModal = () => {
    closeModal();
    setErrorMessage();
    handleChange( "rejectionReason", "" );
  };

  const handleReject = () => {
    if( modalType === MODAL_TYPE_DETAIL.DRAFT_SAVE_ALERT.type ) {
      closeModal();
      handleDraft( true );
    }
    else {
      closeModal();
    }
  };

  const getSelectedOfferTemplate = () => {
    const { OfferTemplate: offerTemplates = [] } = lookup;
    const key = offerDetail?.offerName || offerDetail.name;

    return offerTemplates.find( offer => offer.label === key );
  };

  const handleDraft = ( isLoader ) => {
    const offerNameForBlank = `Draft -${ randomString( 7 ) }`;

    if( !values.tournamentName ) {
      const templateName = values.offerTemplate?.label || "My Offer";
      const defaultOfferName = getUniqueName( templateName, offerNames );

      values.offerName = defaultOfferName;
      handleChange( "offerName", defaultOfferName );
    }

    if( !values.descriptionText ) {
      values.descriptionText = offerNameForBlank;
      values.rawDescription = offerNameForBlank;
    }

    if( isTournamentFromTemplate && !values?.offerTemplate ) {
      const offerTemplate = getSelectedOfferTemplate();

      values.offerTemplate = offerTemplate;
      handleChange( "offerTemplate", offerTemplate );
    }

    if( !values.isEditDraft && !offerDetail.entityId ) {
      if( tournamentType === OFFER_TYPE_STATUS.OFFER_TEMPLATE ) {
        const requestObj = new DraftTournamentRequest( { ...values, tournamentType, partnerTournament: false, isAdminAddEditOffer } );

        dispatch( addNewPartnerOfferDraftAction( requestObj, DRAFT_ENTITY_NAME.TOURNAMENT_TEMPLATE_DRAFT, isLoader ) );
      }
      else if( ( tournamentType === OFFER_TYPE_STATUS.OFFER_FROM_SCRATCH ) || ( tournamentType === OFFER_TYPE_STATUS.OFFER_FROM_TEMPLATE ) || isClone ) {
        const draftRequest = isClone ? { ...values, cloneOfferName: offerDetail.offerName, cloneOfferId: offerDetail.offerId } : values;
        const requestObj = new DraftTournamentRequest( { ...draftRequest, tournamentType, partnerTournament: true, isAdminAddEditOffer } );

        dispatch( addNewPartnerOfferDraftAction( requestObj, DRAFT_ENTITY_NAME.PARTNER_TOURNAMENT_DRAFT, isLoader ) );
      }
    }
    else {
      const id = ( ( tournamentId === "0" || isClone ) && offerDetail.entityId ) ? offerDetail.entityId : tournamentId;
      const draftRequest = isClone ? { ...values, cloneOfferName: offerDetail.cloneOfferName, cloneOfferId: offerDetail.cloneOfferId } : values;
      const requestObj = new DraftTournamentRequest( { ...draftRequest, offerId: id, partnerTournament: ( offerDetail.entityName !== DRAFT_ENTITY_NAME.TOURNAMENT_TEMPLATE_DRAFT ), isAdminAddEditOffer } );

      dispatch( updatePartnerOfferDraftAction( requestObj, id, isLoader ) );
    }
  };

  /**
   * @description function use to add rewards in the tournament.
   * Handling the rewards array.
   */
  const addReward = () => {
    handleChange( "rewardsErrors", validateRewards( values.rewards, true ) );

    const notFoundRewardError = doRewardsHaveNoError( values.rewards, true );

    if( notFoundRewardError ) {
      handleChange( "rewards", [ ...values.rewards, { qxPoint: 0, place: values.rewards.length + 1 } ] );
    }
  };

  const handleTimeZone = e => handleChange( "timeZone", e );

  const handleDeleteReward = ( place ) => {
    const rewards = values.rewards.filter( reward => reward.place !== place ).map( ( reward, i ) => {
      return { qxPoint: reward.qxPoint, place: i + 1 };
    } );

    handleChange( "rewards", rewards );
    handleChange( "rewardsErrors", validateRewards( rewards, values.isTournament ) );
  };

  const handleBackNavigation = () => {
    return getBackNavigationRoute( ROUTES.PARTNER_TOURNAMENTS, location, backScreens );
  };

  const handleAdvertiseDate = ( e ) => {
    if( e ) {
      handleChange( "advertiseDate", new Date( e ) );
    }
    else {
      handleChange( "advertiseDate", false );
    }
  };

  const navigateBackToListing = () => {
    // apply setTimeout to manage multiple api response state manage before redirection
    setTimeout( () => {
      history.push(
        ( fromScreen === "partnerTournaments" )
          ? handleBackNavigation()
          : {
            pathname: handleBackNavigation(),
            state: location.state,
          }
      );
    }, 300 );
  };

  const updateTournamentName = () => {
    const existingOfferNames = offers?.map( item => item.offerName ) || [];
    const cloneOfferName = getUniqueName( `Clone - ${ offerDetail.offerName }`, existingOfferNames );

    handleChange( "tournamentName", cloneOfferName );
  };

  return (
    <>
      <div className='main-wrapper'>
        <div className='main-right-wrapper'>
          <BackButton
            backLink={handleBackNavigation()}
            state={fromScreen === "partnerTournaments" ? "" : location.state}
          />
          <div className='page-head-wrapper'>
            <h4 className='page-heading small-icon'>
              {headerDetail.title}
              <OverlayTrigger placement='right' overlay={<Tooltip>{headerDetail.description}</Tooltip>}>
                <HelpIcon />
              </OverlayTrigger>
            </h4>
            <div className='page-head-right'>
              <Button variant='outline-secondary btn-md mr-4' onClick={() => navigateBackToListing()}>Cancel</Button>
              <Button variant='primary btn-md mr-4' onClick={() => handleSubmit( "" )}>
                {( !values.isEditOffer || isClone || values.isEditDraft ) ? "Submit" : "Update"}
              </Button>
              {( !values.isEditOffer || isClone || isTournamentFromTemplate ) && (
                <Button variant='primary btn-md' onClick={() => handleSubmit( "draft" )}>
                  {values.isEditDraft ? "Update as Draft" : "Save as Draft"}
                </Button>
              )}
            </div>
          </div>
          <div className='page-inner-box profile-steps-box'>
            <Form>
              { values.isTournament && (
                <ChooseTemplate
                  isClone={isClone}
                  clickOnCloned={() => {
                    history.push( {
                      pathname: `${ ROUTES.PARTNER_TOURNAMENT_DETAIL
                        .replace( ":offerId", offerDetail.cloneOfferId ? offerDetail.cloneOfferId : tournamentId ) }?tab=tournament-detail&from=clonedTournament`,
                      state: [ ...location.state,
                        ...[ { from: "partnerClonedTournament", path: location.pathname, state: location.search } ],
                      ],
                    } );
                  } }
                  templates={lookup?.TournamentTemplate || []}
                  onChangeTemplate={value => handleSelectInput( "tournamentTemplate", value )}
                  selectedTemplate={values.tournamentTemplate || ""}
                  type='Tournament'
                  clonedName={offerDetail.cloneOfferName ?? offerDetail.offerName}
                />
              )}
              <OfferTemplate
                isTemplate={!values.isTournament}
                values={values}
                errors={errors}
                lookup={lookup}
                handleInputChange={handleInputChange}
                handleChange={handleChange}
                onSelectedEvent={onSelectedEvent}
                onBlur={() => {}}
                editorOnBlur={() => {}}
                editorOnFocus={() => {}}
                configuration={configuration}
                type='Tournament'
                typeName='tournamentName'
                addReward={addReward}
                onClickDeleteReward={handleDeleteReward}
                membershipTypes={membershipTypes}
                isTournament={true}
              />
              { values.isTournament && (
                <ScheduleTournament
                  onChange={handleScheduleInputChange}
                  values={values}
                  games={lookup?.ApprovedGame || []}
                  errors={errors}
                  onSelect={handleSelectInput}
                  onBlur={() => {}}
                  startDateChange={e => handleDateChange( "startDate", e )}
                  endDateChange={e => handleDateChange( "endDate", e )}
                  onChangeTimeZone={handleTimeZone}
                  selectTime={( name, e ) => handleChange( name, e )}
                  advertiseDateChange={handleAdvertiseDate}
                  isTournament={true}
                />
              )}
            </Form>
          </div>
        </div>
      </div>
      <ModalPopup
        isOpened={isOpened}
        onClose={handleCloseModal}
        type={modalType}
        values={values}
        onChangeInput={handleInputChange}
        errorMessage={errorMessage}
        onConfirm={handleReject}
      />
    </>
  );
};

export default CreateAndEditTournament;
