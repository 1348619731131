import constant from "../../util/constant";
import {
  ADMIN_TRANSACTION_API_END,
  REDEEM_TRANSACTION_API_REQUEST,
  EXCHANGE_TRANSACTION_API_REQUEST,
  GET_REDEEM_TRANSACTION_SUCCESS,
  GET_REDEEM_TRANSACTION_FAILURE,
  GET_REDEEM_TRANSACTION_DETAILS_SUCCESS,
  GET_REDEEM_TRANSACTION_DETAILS_FAILURE,
  REJECT_REDEEM_TRANSACTION_SUCCESS,
  REJECT_REDEEM_TRANSACTION_FAILURE,
  APPROVE_REDEEM_TRANSACTION_SUCCESS,
  APPROVE_REDEEM_TRANSACTION_FAILURE,
  ADMIN_REDEEM_FILTER_LIST_SUCCESS,
  ADMIN_REDEEM_FILTER_LIST_FAILURE,
  GET_EXCHANGE_TRANSACTION_SUCCESS,
  GET_EXCHANGE_TRANSACTION_FAILURE,
  ADMIN_EXCHANGE_FILTER_LIST_SUCCESS,
  ADMIN_EXCHANGE_FILTER_LIST_FAILURE,
  RESET_EXCHANGE_TRANSACTION_REQUEST
} from "../actionTypes";

const { API_STATUS } = constant;

const DEFAULT_STATE = {
  loading: false,
  message: "",
  status: "",
  apiType: "",
  exchangeTransaction: { transaction: [], meta: "" },
  redeemTransaction: { transaction: [], meta: "" },
  redeemTransactionDetail: {},
  redeemFilterList: {
    gamerNames: [],
    redeemRequestIds: [],
    paymentVendors: [],
    transactionRanges: {
      qxPointsRange: { minQxPoints: 0, maxQxPoints: 0 },
      netRedeemAmountRange: { minNetAmount: 0, maxNetAmount: 0 },
    },
    transactionStatus: [] },
  exchangeFilterList: {
    gamerNames: [],
    gameNames: [],
    assetNames: [],
    transactionRanges: {
      qxPointsRange: { minQxPoints: 0, maxQxPoints: 0 },
    },
  },
};

// TODO: refactor to avoid complexity
// eslint-disable-next-line complexity
const adminTransaction = ( state = DEFAULT_STATE, action = "" ) => {
  switch ( action.type ) {
    case REDEEM_TRANSACTION_API_REQUEST:
    case EXCHANGE_TRANSACTION_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
      };
    case RESET_EXCHANGE_TRANSACTION_REQUEST:
      return {
        ...state,
        apiType: action.payload,
        exchangeFilterList: {
          gamerNames: [],
          gameNames: [],
          assetNames: [],
          transactionRanges: {
            qxPointsRange: { minQxPoints: 0, maxQxPoints: 0 },
          },
        },
      };
    case ADMIN_TRANSACTION_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case GET_EXCHANGE_TRANSACTION_SUCCESS:
      return {
        ...state,
        exchangeTransaction: {
          transaction:
                action.payload.meta.page === 1
                  ? action.payload.transactionList
                  : [ ...state.exchangeTransaction.transaction, ...action.payload.transactionList ],
          meta: action.payload.meta,
        },
        status: API_STATUS.SUCCESS,
      };
    case GET_REDEEM_TRANSACTION_SUCCESS:
      return {
        ...state,
        redeemTransaction: {
          transaction:
              action.payload.meta.page === 1
                ? action.payload.transactionList
                : [ ...state.redeemTransaction.transaction, ...action.payload.transactionList ],
          meta: action.payload.meta,
        },
        status: API_STATUS.SUCCESS,
      };
    case GET_REDEEM_TRANSACTION_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        redeemTransaction: { transaction: [], meta: "" },
      };

    case GET_EXCHANGE_TRANSACTION_FAILURE: {
      return {
        ...state,
        status: API_STATUS.FAILURE,
        exchangeTransaction: { transaction: [], meta: "" },
      };
    }

    case GET_REDEEM_TRANSACTION_DETAILS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        redeemTransactionDetail: action.payload,
      };
    case GET_REDEEM_TRANSACTION_DETAILS_FAILURE:
    case REJECT_REDEEM_TRANSACTION_FAILURE:
    case APPROVE_REDEEM_TRANSACTION_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        loading: false,
      };
    case REJECT_REDEEM_TRANSACTION_SUCCESS:
    case APPROVE_REDEEM_TRANSACTION_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
      };
    case ADMIN_REDEEM_FILTER_LIST_SUCCESS:
      return {
        ...state,
        redeemFilterList: {
          gamerNames: action.payload.gamerNames,
          redeemRequestIds: action.payload.redeemRequestIds,
          paymentVendors: action.payload.paymentVendors,
          transactionRanges: action.payload.transactionRanges,
          transactionStatus: action.payload.transactionStatus,
        },
        status: API_STATUS.SUCCESS,
      };
    case ADMIN_EXCHANGE_FILTER_LIST_SUCCESS:
      return {
        ...state,
        exchangeFilterList: {
          gamerNames: action.payload.gamerNames,
          gameNames: action.payload.gameNames,
          assetNames: action.payload.assetNames,
          transactionRanges: action.payload.transactionRanges,
        },
        status: API_STATUS.SUCCESS,
      };
    case ADMIN_EXCHANGE_FILTER_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        loading: false,
        exchangeFilterList: {
          gamerNames: [],
          gameNames: [],
          assetNames: [],
          transactionRanges: {},
        },
      };
    case ADMIN_REDEEM_FILTER_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        loading: false,
        redeemFilterList: {
          gamerNames: [],
          redeemRequestIds: [],
          paymentVendors: [],
          transactionRanges: {},
          transactionStatus: [],
        },
      };
    default:
      return state;
  }
};

export default adminTransaction;
