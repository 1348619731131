import PropTypes from "prop-types";
import React from "react";
import { Form } from "react-bootstrap";
import { useAddQuery } from "../../../../hooks";
import constant from "../../../../util/constant";
import { useSetUrlParams, getBackNavigationRoute } from "../../../../util/helpers";
import FilterIcon from "../../../shared/FilterIcon/FilterIcon";
import BackButton from "../../../shared/backButton";
import ProfileImage from "../../../shared/profileImage";

const { ROUTES } = constant;

const AssetsHeader = ( {
  gamerDetail,
  gamerId,
  handleChange,
  isFilterApplied,
  showFilter,
  setShowFilter,
  values,
  location,
} ) => {
  const { addQuery } = useAddQuery();
  const backScreens = [ "partnerGamerDetail" ];

  const handleSearch = ( e ) => {
    const { name, value } = e.target;

    handleChange( name, value );
    addQuery( useSetUrlParams( name, value ) );
  };

  const showFilterScreen = () => setShowFilter( !showFilter );

  const handleBackNavigation = () => {
    return getBackNavigationRoute( ROUTES.PARTNER_GAMER_SECTION.replace( ":gamerId", gamerId ), location, backScreens );
  };

  return (
    <>
      <BackButton
        backLink={handleBackNavigation()}
        state={location.state}
      />
      <div className="page-head-wrapper">
        <div className='d-flex align-items-center'>
          <ProfileImage profileImage={gamerDetail?.profileImage} />
          <div className='profile-title mb-0'>{gamerDetail.gamerFullName}</div>
        </div>
        <div className="page-head-right">
          <Form.Group className="custom-search w-300">
            <span className="icon-search"></span>
            <Form.Control
              type="text"
              placeholder='Search by Asset and Game Name'
              data-testid='searchKeyTest'
              onChange={handleSearch}
              value={values.searchKey}
              name='searchKey'
            />
          </Form.Group>
          <FilterIcon
            showFilter={showFilter}
            showFilterScreen={showFilterScreen}
            isFilterApplied={isFilterApplied} />
        </div>
      </div>
      <div className="page-head-wrapper">
        <h4 className="page-heading">Assets</h4>
      </div>
    </>
  );
};

AssetsHeader.propTypes = {
  gamerDetail: PropTypes.string,
  gamerId: PropTypes.string,
  handleChange: PropTypes.func,
  isFilterApplied: PropTypes.bool,
  showFilter: PropTypes.string,
  setShowFilter: PropTypes.func,
  values: PropTypes.object,
  location: PropTypes.object,
};

export default AssetsHeader;
