import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getTotalExchangeTxnAmountAction } from "../../../../store/actions/partnerAnalytics";
import analyticsConstant from "../../../../util/analyticsConstant";
import constant from "../../../../util/constant";
import { currencyFormat } from "../../../../util/helpers";
import Graph from "./graph";
import GraphFilter from "./graphFilter";

const { ANALYTICS_TAB } = analyticsConstant;
const { API_STATUS, GRAPH_TYPE } = constant;

const ExchangeTxn = ( { redirectToAll } ) => {
  const { analyticsGraphData, totalExchangeTxnAmount, status, initialDate } = useSelector( state => ( {
    analyticsGraphData: state.partnerAnalytics.exchangeTransaction.analyticsGraphData,
    totalExchangeTxnAmount: state.partnerAnalytics.exchangeTransaction.totalExchangeTxnAmount,
    initialDate: state.partnerAnalytics.exchangeTransaction.initialDate,
    status: state.partnerAnalytics.status,
  } ) );
  const [ startDate, setStartDate ] = useState();
  const [ endDate, setEndDate ] = useState();

  useEffect( () => {
    if( !isEmpty( analyticsGraphData ) && ( status === API_STATUS.SUCCESS ) ) {
      setStartDate( analyticsGraphData.data[ 0 ]?.dataPoints[ 0 ].x );
      setEndDate( analyticsGraphData.data[ 0 ]?.dataPoints[ analyticsGraphData.data[ 0 ]?.dataPoints.length - 1 ].x );
    }
  }, [ status ] );

  return (
    <>
      <Col sm={6}>
        <div className="tag-box">
          <div className="tag-box-inner">
            <div className="tag-content">
              <div className="tag-title">{currencyFormat( { data: totalExchangeTxnAmount } )}</div>
              <div className="tag-text">Total $ Amount Exchanged</div>
              <span onClick={() => redirectToAll( ANALYTICS_TAB.EXCHANGE_TRANSACTION )} title='Show All' className='show-link'>Show All</span>
            </div>
            <GraphFilter
              startDate={startDate }
              endDate={endDate }
              initialDate={initialDate}
              dispatchAction={getTotalExchangeTxnAmountAction}
              graphType={GRAPH_TYPE.EXCHANGE_TXN_LINE_GRAPH}
            />
          </div>
          <Graph options={analyticsGraphData} />
        </div>
      </Col>
    </>
  );
};

ExchangeTxn.propTypes = {
  redirectToAll: PropTypes.func,
};

export default ExchangeTxn;
