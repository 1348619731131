import PropTypes from "prop-types";
import React, { useCallback, useMemo, useEffect, useState } from "react";
import { Form, Col, Row } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useAddQuery, useForm, useFilter, getUrlData } from "../../../hooks";
import { getAssetsListAction } from "../../../store/actions/partnerAnalytics";
import analyticsConstant from "../../../util/analyticsConstant";
import constant from "../../../util/constant";
import { useSetUrlParams, checkFilterApplied, getBackNavigationRoute } from "../../../util/helpers";
import { analyticsAssetsTableConfigs } from "../../../util/tableConstant";
import ToolTipError from "../../admin/gamer/toolTipError";
import FilterIcon from "../../shared/FilterIcon/FilterIcon";
import BackButton from "../../shared/backButton";
import { ScrollPage } from "../../shared/customScrollBar";
import TableHeaderRow from "../../shared/tableHeadingRow";
import AnalyticsAssetsFilterList from "./analyticsAssetsFilterList";

const { ROUTES } = constant;
const { API_STATUS, FILTERS_NAME } = constant;
const { API_TYPES } = analyticsConstant;

const AssetsList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { gameId } = useParams();
  const { gamerId: URLString } = useParams();
  const { 0: gamerId } = String( URLString ).split( "?" );
  const { addQuery } = useAddQuery();
  const { filteredData, filterData, setPureData } = useFilter();
  const backScreens = [ "analyticsLinkedGamers" ];
  const paramsQuery = new URLSearchParams( window.location.search );
  const fromScreen = paramsQuery.get( "from" );
  const {
    assetsLists,
    assetsFilterList,
    apiType,
    status,
  } = useSelector( state => ( {
    assetsLists: state.partnerAnalytics.assetsLists,
    assetsFilterList: state.partnerAnalytics.assetsFilterList,
    apiType: state.partnerAnalytics.apiType,
    status: state.partnerAnalytics.status,
  } ) );

  const isGameId = Boolean( gameId );

  const { values, handleChange } = useForm(
    {
      searchKey: "",
      sortBy: "assetName",
      sortOrder: "",
      assetId: [],
      assetName: [],
      minUnitPrice: 0,
      maxUnitPrice: 0,
      minQuantity: 0,
      maxQuantity: 0,
      searchCol: [ "name", "assetId" ],
      compareKeyArray: [ "unitPriceQxPoints", "quantity" ],
    },
    {}
  );
  const sliderMinMaxObj = {
    minQuantity: assetsFilterList.quantityMinMax.min,
    maxQuantity: assetsFilterList.quantityMinMax.max,
    minUnitPrice: assetsFilterList.priceMinMax.min,
    maxUnitPrice: assetsFilterList.priceMinMax.max,
  };
  const isFilterApplied = checkFilterApplied( location.search, FILTERS_NAME.ANALYTICS_ASSETS, sliderMinMaxObj );
  const requestParams = Object.assign(
    {
      isSingleDate: true,
      searchCol: values.searchCol,
      sortOrder: values.sortOrder,
      sortBy: values.sortBy,
      searchKey: values.searchKey,
      assetId: values.assetId?.map( value => value.value ) || [],
      assetName: values.assetName?.map( value => value.value ) || [],
      minUnitPrice: values.minUnitPrice,
      maxUnitPrice: values.maxUnitPrice,
      minQuantity: values.minQuantity,
      maxQuantity: values.maxQuantity,
      compareKeyArray: values.compareKeyArray,
    }
  );

  const handleSorting = ( sortBy, orderType ) => {
    handleChange( "sortBy", sortBy );
    handleChange( "sortOrder", orderType );
    addQuery( useSetUrlParams( "sortBy", sortBy ), location.state, isGameId );
    addQuery( useSetUrlParams( "sortOrder", orderType ), location.state, isGameId );
  };

  const memoizedHandleClick = useCallback(
    ( sortBy, orderType ) => handleSorting( sortBy, orderType ),
    []
  );

  useEffect( () => {
    dispatch( getAssetsListAction( requestParams, { gameId: gameId, gamerId: gamerId } ) );
  }, [] );

  const memoizedTableConfig = useMemo( () => {
    return analyticsAssetsTableConfigs;
  }, [] );

  useEffect( () => {
    filterData( requestParams );
  }, [
    values.sortOrder,
    values.sortBy,
    values.searchKey,
    values.name,
    values.assetId,
    values.gameName,
    values.assetName,
    values.minQuantity,
    values.maxQuantity,
    values.minUnitPrice,
    values.maxUnitPrice,
  ] );

  useEffect( () => {
    if(
      status === API_STATUS.SUCCESS &&
      apiType === API_TYPES.GET_ASSETS_LISTS
    ) {
      setPureData( assetsLists?.assets );
      setTimeout( () => {
        getUrlData( handleChange );
      }, 2000 );
    }
  }, [ status, apiType ] );

  const [ showFilter, setShowFilter ] = useState( false );
  const showFilterScreen = () => setShowFilter( !showFilter );

  const searchHandle = ( e ) => {
    addQuery( useSetUrlParams( "searchKey", e.target.value ), location.state, isGameId );
    handleChange( "searchKey", e.target.value );
  };

  useEffect( () => {
    handleChange( "minQuantity", assetsFilterList.quantityMinMax.min );
    handleChange( "maxQuantity", assetsFilterList.quantityMinMax.max );
    handleChange( "minUnitPrice", assetsFilterList.priceMinMax.min );
    handleChange( "maxUnitPrice", assetsFilterList.priceMinMax.max );
  }, [ assetsFilterList ] );

  const handleBackNavigation = () => {
    return getBackNavigationRoute( ROUTES.PARTNER_ANALYTICS, location, backScreens );
  };

  return ( <>

    <div className='main-wrapper'>
      <div className='main-right-wrapper'>
        <BackButton
          backLink={handleBackNavigation()}
          state={fromScreen === "analytics" ? "" : location.state}
        />
        <div className='page-head-wrapper mb-20'>
          <h4 className='page-heading'>
                  Assets
          </h4>
          <div className='page-head-right'>
            <Form.Group className='custom-search w-300 mr-0'>
              <span className='icon-search'></span>
              <Form.Control
                type='text'
                placeholder='Search by Asset Id and Name'
                onChange={searchHandle}
                value={values.searchKey}
              />
            </Form.Group>
          </div>
        </div>
        <div className='filter-boxes mr-0 mb-20'>
          <Row>
            <Col md={2}>
              <div className='content-section mb-20'>
                <div className='content-label font-18'>Game
                  {assetsLists.isGameServerError && ( <ToolTipError message='Error in fetching assets for this game.'/> )}
                </div>
                <div className='content-des inner-content'>
                  <p> {assetsLists.gameName}</p>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className='content-section mb-20'>
                <div className='content-label font-18'>Gamer Name</div>
                <div className='content-des inner-content'>
                  <p>{assetsLists.fullName}</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className='filter-tabs-wrapper mb-0 pos-relative'>
          <AnalyticsAssetsFilterList
            values={values}
            handleChange={handleChange}
            addQuery={addQuery}
            assetId={assetsFilterList.assetIdList}
            assetName={assetsFilterList.assetNameList}
            isGameId={isGameId}
            showFilter={showFilter}
            locationState={location.state}
            assetsFilterList={assetsFilterList}
          />
          <div className='top-filter-buttons'>
            <FilterIcon
              showFilter={showFilter}
              showFilterScreen={showFilterScreen}
              isFilterApplied={isFilterApplied} />
          </div>
        </div>
        <div className="table-container partner-analytics-assets">
          <div className="table-head">
            <div className="table-row">
              <TableHeaderRow
                configData={memoizedTableConfig}
                onAscOrder={memoizedHandleClick}
                onDescOrder={memoizedHandleClick}
              />
            </div>
          </div>
          <Scrollbars
            renderThumbVertical={ScrollPage}
            className={`custom-scroll-height max ${ !showFilter ? "" : "full-height" }`}
          >
            <div className="table-body no-scrollbar" >
              {Boolean( filteredData?.length ) && filteredData.map( ( asset, index ) => (
                <div className="table-row" key={index}>
                  <div className="td">{asset.assetId ?? "-"}</div>
                  <div className="td">
                    <span className='text-green font-medium'>
                      {asset.name ?? "-"}
                    </span>
                  </div>
                  <div className="td">{asset.quantity ?? "-"}</div>
                  <div className="td">{asset.unitPriceQxPoints ?? "-"}/ <span className='text-green font-medium'>{asset.unitPriceQxPoints ? asset.totalQxPoints : "-"}</span></div>
                </div>
              ) )}
              {!filteredData?.length && (
                <div className='no-record' data-testid='no-record-found'>
                        No Asset Found
                </div>
              )}
            </div>
          </Scrollbars>
        </div>
      </div>
    </div>
  </>
  );
};

AssetsList.propTypes = {
  searchKey: PropTypes.string,
  gameId: PropTypes.string,
  setPreviousData: PropTypes.func,
  fullName: PropTypes.string,
  name: PropTypes.string,
  assetId: PropTypes.string,
};

export default AssetsList;
