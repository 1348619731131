import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";
// Local-import.
import adminTransactionConstant from "../../../util/adminTransactionConstant";
import constant from "../../../util/constant";
import FilterIcon from "../../shared/FilterIcon/FilterIcon";
import SearchBox from "../../shared/searchBox/searchBox";

const { ROUTES } = constant;
const { TABS } = adminTransactionConstant;

const TransactionHeader = ( {
  tab,
  addQuery,
  values,
  searchBoxPlaceholder,
  searchBoxTitle,
  handleChange,
  showFilter,
  showFilterScreen,
  isFilterApplied,
} ) => {
  const history = useHistory();

  const setTab = ( val ) => {
    history.replace( `${ ROUTES.TRANSACTION_LIST }?tab=${ val }` );
  };

  return (
    <>
      <div className="page-head-wrapper">
        <h4 className="page-heading">Transactions</h4>
        <div className="page-head-right">
          <SearchBox
            values={values}
            handleChange={handleChange}
            addQuery={addQuery}
            placeholder={searchBoxPlaceholder}
            title={searchBoxTitle} />
          <FilterIcon
            showFilter={showFilter}
            showFilterScreen={showFilterScreen}
            isFilterApplied={isFilterApplied} />
        </div>
      </div>
      <div className='custom-tabs trans-tabs'>
        <span onClick={() => setTab( TABS.EXCHANGE )} className={tab === TABS.EXCHANGE ? "active" : ""}>Exchange</span>
        <span onClick={() => setTab( TABS.REDEEM )} className={tab === TABS.REDEEM ? "active" : ""}>Redeem</span>
      </div>
    </>
  );
};

TransactionHeader.propTypes = {
  tab: PropTypes.string,
  addQuery: PropTypes.func,
  values: PropTypes.object,
  searchBoxPlaceholder: PropTypes.string,
  searchBoxTitle: PropTypes.string,
  handleChange: PropTypes.func,
  showFilter: PropTypes.bool,
  showFilterScreen: PropTypes.func,
  isFilterApplied: PropTypes.bool,
};

export default TransactionHeader;
