import { capitalize } from "lodash";
import { convertDate } from "../util/helpers";

export class RedeemTransactions {
  constructor( obj ) {
    this.acQyrCashSold = obj.acQyrCashSold;
    this.amount = obj.amount;
    this.cashbackName = obj.cashbackName;
    this.iconUrl = obj.iconUrl;
    this.netRedeemAmount = obj.netRedeemAmount;
    this.redeemRequestId = obj.redeemRequestId;
    this.redeemedFee = obj.redeemedFee;
    this.status = capitalize( obj.status );
    this.transactionId = obj.transactionId;
    this.whenRequested = obj.whenRequested;
    this.redeemedDate = convertDate( this.whenRequested );
  }
}
