import PropTypes from "prop-types";
import React from "react";

import GameOffer from "../offers/gameOffer";

const GameOffers = ( {
  associatedOffers,
  gameIcon,
  gameName,
  isPartnerGamerLinkedGame = false,
} ) => {
  return (
    <>
      <div className="games-grid-wrapper no-scrollbar">
        {Boolean( associatedOffers?.length ) &&
                associatedOffers.map( ( offer, index ) => (
                  <div
                    className="col-games-grid-box width-50"
                    key={index}
                  >
                    <GameOffer
                      offer={offer}
                      gameIcon={gameIcon}
                      gameName={gameName}
                      isPartnerGamerLinkedGame={isPartnerGamerLinkedGame}
                    />
                  </div>
                ) )}
      </div>
    </>
  );
};

GameOffers.propTypes = {
  associatedOffers: PropTypes.array,
  gameIcon: PropTypes.string,
  gameName: PropTypes.string,
  isPartnerGamerLinkedGame: PropTypes.bool,
};

export default GameOffers;
