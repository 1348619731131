import { API_URL } from "../util/apiPaths";
import { request } from "./api.js";

export function addEvent( params ) {
  return request( "POST", API_URL.ADMIN_EVENTS, params );
}

export function getAdminEvents( params ) {
  return request( "GET", API_URL.ADMIN_EVENTS, params );
}

export function updateAdminEvent( params ) {
  return request( "PUT", API_URL.ADMIN_EVENTS, params );
}

export function getAdminEventDetail( params ) {
  return request( "GET", `${ API_URL.ADMIN_EVENTS }/${ params }` );
}

export function enableDisableEventRequest( params ) {
  return request( "PUT", API_URL.ENABLE_DISABLE_EVENT_ADMIN, params );
}

export const adminEventServices = {
  addEvent,
  getAdminEvents,
  updateAdminEvent,
  getAdminEventDetail,
};
