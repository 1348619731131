import constant from "../util/constant";

const { OPT_IN_STATUS } = constant;

const useInputChange = () => {
  const handleInputChanges = ( e, handleChange ) => {
    const { name } = e.target;

    if( ( name === "minParticipantsNoLimit" ) || ( name === "maxParticipantsNoLimit" ) ) {
      const { checked } = e.target;

      if( checked && ( name === "minParticipantsNoLimit" ) ) {
        handleChange( "minParticipants", "" );
      }
      else if( checked && ( name === "maxParticipantsNoLimit" ) ) {
        handleChange( "maxParticipants", "" );
      }

      handleChange( name, checked );
    }
    else if( name === "optInType" ) {
      const { value } = e.target;

      if( value === OPT_IN_STATUS.NO ) {
        handleChange( "entryFee", "" );
        handleChange( "minParticipants", "" );
        handleChange( "maxParticipants", "" );
      }

      handleChange( name, value );
    }
    else {
      const { value } = e.target;

      handleChange( name, value );
    }
  };

  return {
    handleInputChanges,
  };
};

export default useInputChange;
