import constant from "./constant";

const OFFER_NAME = "Offer Name";
const GAMER_NAME = "Gamer Name";
const DATE_OF_TRANSACTION = "Date of Transaction";
const START_DATE = "Start Date";
const NO_OF_LINKED_GAMES = "No. of Linked Games";
const ASSET_NAME = "Asset Name";
const TOURNAMENT_NAME = "Tournament Name";
const { BRANDING: { APP_SHORT_NAME } } = constant;

export const adminGameTableConfigs = [
  {
    title: "Game Icon",
    sorter: false,
  },
  {
    title: "Game Name",
    sorter: true,
    sorterName: "name",
  },
  {
    title: "Partner",
    sorter: true,
    sorterName: "legal_entity_name",
  },
  {
    title: "Description",
    sorter: false,
  },
  {
    title: "Published On",
    sorter: true,
    sorterName: "approved_date",
  },
  {
    title: "Status",
    sorter: false,
  },
  {
    title: "View",
    sorter: false,
  },
];
export const eventsTableConfigs = [
  {
    title: "Event ID",
    sorter: true,
    sorterName: "eventId",
  },
  {
    title: "Event Name",
    sorter: true,
    sorterName: "eventName",
  },
  {
    title: "Units",
    sorter: true,
    sorterName: "unitName",
  },
  {
    title: "Partner",
    sorter: true,
    sorterName: "toSortPartner",
  },
  {
    title: "Description",
    sorter: false,
  },
  {
    title: "Status",
    sorter: false,
  },
  {
    title: "Action",
    sorter: false,
  },
];
export const eventsPartnerTableConfigs = [
  {
    title: "Event ID",
    sorter: true,
    sorterName: "event_id",
  },
  {
    title: "Event Name",
    sorter: true,
    sorterName: "event_name",
  },
  {
    title: "Units",
    sorter: true,
    sorterName: "unit",
  },
  {
    title: "Partner",
    sorter: true,
    sorterName: "partner",
  },
  {
    title: "Description",
    sorter: false,
  },
  {
    title: "Status",
    sorter: false,
  },
  {
    title: "Action",
    sorter: false,
  },
];
export const adminUserTableConfigs = [
  {
    title: "Full Name",
    sorter: true,
    sorterName: "full_name",
  },
  {
    title: "Username",
    sorter: true,
    sorterName: "username",
  },
  {
    title: "Email",
    sorter: true,
    sorterName: "email",
  },
  {
    title: "Status",
    sorter: false,
    sorterName: "is_active",
  },
  {
    title: "Action",
    sorter: false,
  },
];
export const adminOfferTableConfigs = [
  {
    title: OFFER_NAME,
    sorter: true,
    sorterName: "name",
  },
  {
    title: "Description",
    sorter: false,
    sorterName: "username",
  },
  {
    title: "Game",
    sorter: true,
    sorterName: "game",
  },
  {
    title: "Partner",
    sorter: true,
    sorterName: "partner",
  },
  {
    title: "Opt In",
    sorter: true,
    sorterName: "optInType",
  },
  {
    title: "Status",
    sorter: false,
  },
  {
    title: "Action",
    sorter: false,
  },
];
export const partnerOfferTableConfigs = [
  {
    title: OFFER_NAME,
    sorter: true,
    sorterName: "offerName",
  },
  {
    title: "Description",
    sorter: false,
    sorterName: "descriptionText",
  },
  {
    title: "Created By",
    sorter: false,
  },
  {
    title: "Game",
    sorter: true,
    sorterName: "gameName",
  },
  {
    title: START_DATE,
    sorter: true,
    sorterName: "startDateOriginal",
  },
  {
    title: "End Date",
    sorter: true,
    sorterName: "endDateOriginal",
  },
  {
    title: "Opt In",
    sorter: true,
    sorterName: "optInType",
  },
  {
    title: "Status",
    sorter: false,
    sorterName: "is_active",
  },
  {
    title: "Action",
    sorter: false,
  },
];
export const adminPartnerOfferTableConfigs = [
  {
    title: OFFER_NAME,
    sorter: true,
    sorterName: "name",
  },
  {
    title: "Description",
    sorter: false,
    sorterName: "description",
  },
  {
    title: "Game",
    sorter: true,
    sorterName: "game",
  },
  {
    title: "Status",
    sorter: false,
  },
  {
    title: "View",
    sorter: false,
  },
];
export const adminPartnerGameTableConfigs = [
  {
    title: "Game Name",
    sorter: true,
    sorterName: "name",
  },
  {
    title: "No of Linked Users",
    sorter: true,
    sorterName: "linkedUsers",
  },
  {
    title: "Added Offers",
    sorter: true,
    sorterName: "linkedOffers",
  },
  {
    title: "Status",
    sorter: false,
  },
  {
    title: "View",
    sorter: false,
  },
];
export const adminGamerTableConfigs = [
  {
    title: "Name",
    sorter: true,
    sorterName: "fullName",
  },
  {
    title: NO_OF_LINKED_GAMES,
    sorter: true,
    sorterName: "linkedGameCount",
  },
  {
    title: `${ APP_SHORT_NAME } Points`,
    sorter: true,
    sorterName: "acqyrCashBalance",
  },
  {
    title: "Plan",
    sorter: true,
    sorterName: "planName",
  },
  {
    title: "View",
    sorter: false,
  },
];
export const redeemTableConfigs = [
  {
    title: "Cashback Vendor",
    sorter: true,
    sorterName: "cashbackVendor",
  },
  {
    title: "Request ID",
    sorter: true,
    sorterName: "transactionId",
  },
  {
    title: `${ APP_SHORT_NAME } Points Redeemed`,
    sorter: true,
    sorterName: "acQyrCashSold",
  },
  {
    title: "Status",
    sorter: true,
    sorterName: "status",
  },
  {
    title: "Net Redeem Amount",
    sorter: true,
    sorterName: "netRedeemAmount",
  },
  {
    title: "Date Redeemed",
    sorter: true,
    sorterName: "whenRedeemed",
  },
  {
    title: GAMER_NAME,
    sorter: true,
    sorterName: "gamerName",
  },
];

export const exchangeTableConfigs = [
  {
    title: "Game Name",
    sorter: true,
    sorterName: "name",
  },
  {
    title: `${ APP_SHORT_NAME } Points`,
    sorter: true,
    sorterName: "QXPoints",
  },
  {
    title: "Asset Qty",
    sorter: true,
    sorterName: "soldAssetQuantity",
  },
  {
    title: "Assets",
    sorter: false,
  },
  {
    title: DATE_OF_TRANSACTION,
    sorter: true,
    sorterName: "transactionDate",
  },
  {
    title: GAMER_NAME,
    sorter: true,
    sorterName: "gamerName",
  },
];
export const analyticsGamersTableConfigs = [
  {
    title: GAMER_NAME,
    sorter: true,
    sorterName: "fullName",
  },
  {
    title: "Linked Game",
    sorter: true,
    sorterName: "gameName",
  },
  {
    title: "Gamer Plan",
    sorter: true,
    sorterName: "gamerPlan",
  },
  {
    title: "View",
    sorter: false,
    sorterName: "view",
  },
];
export const gamerGameTableConfigs = [
  {
    title: "Game Icon",
    sorter: false,
  },
  {
    title: "Game Name",
    sorter: true,
    sorterName: "gameName",
  },
  {
    title: "Date Linked",
    sorter: true,
    sorterName: "dateLinked",
  },
  {
    title: "Offers",
    sorter: false,
  },
  {
    title: "Assets",
    sorter: false,
  },
  {
    title: "Action",
    sorter: false,
  },
];
export const gamerOfferLinkedConfigs = [
  {
    title: OFFER_NAME,
    sorter: true,
    sorterName: "offerName",
  },
  {
    title: "Description",
  },
  {
    title: "Game",
    sorter: true,
    sorterName: "gameName",
  },
  {
    title: START_DATE,
    sorterName: "startDate",
    sorter: true,
  },
  {
    title: "End Date",
    sorterName: "endDate",
    sorter: true,
  },
  {
    title: "Opt In",
    sorter: true,
    sorterName: "optInType",
  },
  {
    title: "Status",
    sorter: false,
  },
  {
    title: "Progress",
    sorter: true,
    sorterName: "progress",
  },
  {
    title: "View",
    sorter: false,
  },
];

export const exchangeTransactionTableConfigs = [
  {
    title: "Game Name",
    sorter: true,
    sorterName: "name",
  },
  {
    title: `${ APP_SHORT_NAME } Points`,
    sorter: true,
    sorterName: "QXPoints",
  },
  {
    title: "Asset Qty",
    sorter: true,
    sorterName: "soldAssetQuantity",
  },
  {
    title: "Assets",
    sorter: false,
  },
  {
    title: DATE_OF_TRANSACTION,
    sorter: true,
    sorterName: "transactionDate",
  },
];

export const redeemTxTableConfigs = [
  {
    title: "Cashback Vendor",
    sorter: true,
    sorterName: "cashbackVendor",
  },
  {
    title: `${ APP_SHORT_NAME } Points Redeemed`,
    sorter: true,
    sorterName: "acQyrCashSold",
  },
  {
    title: "Status",
    sorter: true,
  },
  {
    title: "Net Redeem Amount",
    sorter: true,
    sorterName: "netRedeemAmount",
  },
  {
    title: "Date Redeemed",
    sorter: true,
    sorterName: "whenRedeemed",
  },
];

export const gamerAssetsTableConfigs = [
  {
    title: "Asset ID",
    sorterName: "assetId",
  },
  {
    title: ASSET_NAME,
    sorter: true,
    sorterName: "assetName",
  },
  {
    title: "Qty",
    sorter: true,
    sorterName: "quantity",
  },
  {
    title: "Game",
    sorter: true,
    sorterName: "gameName",
  },
  {
    title: `${ APP_SHORT_NAME } Points/Unit`,
    sorter: true,
    sorterName: "QXPoints",
  },
];

export const analyticsExchangeTableConfigs = [
  {
    title: "Game Name",
    sorter: true,
    sorterName: "name",
  },
  {
    title: `${ APP_SHORT_NAME } Points`,
    sorter: true,
    sorterName: "QXPoints",
  },
  {
    title: "Asset Qty",
    sorter: true,
    sorterName: "soldAssetQuantity",
  },
  {
    title: "Assets",
    sorter: true,
    sorterName: "assetName",
  },
  {
    title: DATE_OF_TRANSACTION,
    sorter: true,
    sorterName: "date",
  },
  {
    title: GAMER_NAME,
    sorter: true,
    sorterName: "gamerName",
  },
];
export const adminPartnerLinkedGamersTableConfigs = [
  {
    title: "Name",
    sorter: true,
    sorterName: "fullName",
  },
  {
    title: NO_OF_LINKED_GAMES,
    sorter: true,
    sorterName: "linkedGameCount",
  },
  {
    title: `${ APP_SHORT_NAME } Points (avail. to redeem)`,
    sorter: true,
    sorterName: "acqyrCashBalance",
  },
  {
    title: "View",
    sorter: false,
  },
];
export const adminTournamentsTableConfigs = [
  {
    title: TOURNAMENT_NAME,
    sorter: true,
    sorterName: "name",
  },
  {
    title: "Description",
    sorter: false,
    sorterName: "description",
  },
  {
    title: "Partner",
    sorter: true,
    sorterName: "partner",
  },
  {
    title: "Game",
    sorter: true,
    sorterName: "game",
  },
  {
    title: START_DATE,
    sorter: true,
    sorterName: "startDate",
  },
  {
    title: "End Date",
    sorter: true,
    sorterName: "endDate",
  },
  {
    title: "Opt In",
    sorter: true,
    sorterName: "optInType",
  },
  {
    title: "Status",
    sorter: false,
  },
  {
    title: "Action",
    sorter: false,
  },
];
export const analyticsAssetsTableConfigs = [
  {
    title: "Asset ID",
    sorter: true,
    sorterName: "assetId",
  },
  {
    title: ASSET_NAME,
    sorter: true,
    sorterName: "name",
  },
  {
    title: "Quantity",
    sorter: true,
    sorterName: "quantity",
  },
  {
    title: `Unit / Total (${ APP_SHORT_NAME } Points)`,
    sorter: true,
    sorterName: "unitPriceQxPoints",
  },
];
export const partnerTournamentTableConfigs = [
  {
    title: TOURNAMENT_NAME,
    sorter: true,
    sorterName: "offerName",
  },
  {
    title: "Description",
    sorter: false,
    sorterName: "descriptionText",
  },
  {
    title: "Created By",
    sorter: false,
  },
  {
    title: "Game",
    sorter: true,
    sorterName: "gameName",
  },
  {
    title: START_DATE,
    sorter: true,
    sorterName: "startDateOriginal",
  },
  {
    title: "End Date",
    sorter: true,
    sorterName: "endDateOriginal",
  },
  {
    title: "Opt In",
    sorter: true,
    sorterName: "optInType",
  },
  {
    title: "Status",
    sorter: false,
    sorterName: "is_active",
  },
  {
    title: "Action",
    sorter: false,
  },
];

export const adminPartnerTableConfigs = [
  {
    title: "Partner",
    sorter: true,
    sorterName: "legal_entity_name",
    ascTestId: "partner-name-sort-asc",
    descTestId: "partner-name-sort-desc",
  },
  {
    title: "Email",
    sorter: true,
    sorterName: "email",
    ascTestId: "partner-email-sort-asc",
    descTestId: "partner-email-sort-desc",
  },
  {
    title: "Primary Contact",
    sorter: true,
    sorterName: "primary_name",
    ascTestId: "partner-primary-sort-asc",
    descTestId: "partner-primary-sort-desc",
  },
  {
    title: "Primary Phone",
    sorter: true,
    sorterName: "primary_phone",
    ascTestId: "partner-phone-sort-asc",
    descTestId: "partner-phone-sort-desc",
  },
  {
    title: "Status",
    sorter: false,
  },
  {
    title: "View",
    sorter: false,
  },
];
export const partnerGamerTableConfigs = [
  {
    title: "Name",
    sorter: true,
    sorterName: "fullNames",
  },
  {
    title: NO_OF_LINKED_GAMES,
    sorter: true,
    sorterName: "linkedGamesCount",
  },
  {
    title: `${ APP_SHORT_NAME } Points<br>(avail. to redeem)`,
    html: true,
    sorter: true,
    sorterName: "acqyrCashBalance",
  },
  {
    title: "View",
    sorter: false,
  },
];
export const partnerGamerTournamentTableConfigs = [
  {
    title: TOURNAMENT_NAME,
    sorter: true,
    sorterName: "tournamentName",
  },
  {
    title: "Description",
    sorter: false,
    sorterName: "tournamentDescription",
  },
  {
    title: "Game",
    sorter: true,
    sorterName: "gameName",
  },
  {
    title: START_DATE,
    sorter: true,
    sorterName: "startDateOriginal",
  },
  {
    title: "End Date",
    sorter: true,
    sorterName: "endDateOriginal",
  },
  {
    title: "Opt In",
    sorter: true,
    sorterName: "optInType",
  },
  {
    title: "Status",
    sorter: false,
  },
  {
    title: "Place",
    sorter: true,
    sorterName: "place",
  },
  {
    title: "View",
    sorter: false,
  },
];
export const adminGamerTournamentTableConfigs = [
  {
    title: TOURNAMENT_NAME,
    sorter: true,
    sorterName: "offerName",
  },
  {
    title: "Description",
    sorter: false,
    sorterName: "tournamentDescription",
  },
  {
    title: "Game",
    sorter: true,
    sorterName: "gameName",
  },
  {
    title: START_DATE,
    sorter: true,
    sorterName: "startDate",
  },
  {
    title: "End Date",
    sorter: true,
    sorterName: "endDate",
  },
  {
    title: "Opt In",
    sorter: true,
    sorterName: "optInType",
  },
  {
    title: "Status",
    sorter: false,
  },
  {
    title: "Place",
    sorter: true,
    sorterName: "place",
  },
  {
    title: "View",
    sorter: false,
  },
];
export const leaderboardParticipantsTableConfigs = [
  {
    title: "Participant Image",
    sorter: false,
    sorterName: "participantImage",
  },
  {
    title: "Participant Name",
    sorter: true,
    sorterName: "nickname",
  },
  {
    title: "Place",
    sorter: true,
    sorterName: "rank",
  },
];
export const partnerGamerAssetsTableConfigs = [
  {
    title: "Asset ID",
    sorter: false,
    sorterName: "assetId",
  },
  {
    title: ASSET_NAME,
    sorter: true,
    sorterName: "assetName",
  },
  {
    title: "Qty",
    sorter: true,
    sorterName: "quantity",
  },
  {
    title: "Game",
    sorter: true,
    sorterName: "gameName",
  },
  {
    title: `${ APP_SHORT_NAME } Points/Unit`,
    sorter: true,
    sorterName: "QXPoints",
  },
];
export const partnerGamerGameTableConfigs = [
  {
    title: "Game Icon",
    sorter: false,
  },
  {
    title: "Game Name",
    sorter: true,
    sorterName: "name",
  },
  {
    title: "Date Linked",
    sorter: true,
    sorterName: "startDateOriginal",
  },
  {
    title: "Offers",
    sorter: false,
  },
  {
    title: "Assets",
    sorter: false,
  },
  {
    title: "Action",
    sorter: false,
  },
];
export const partnerGamerExchangeTransactionTableConfigs = [
  {
    title: "Game Name",
    sorter: true,
    sorterName: "name",
  },
  {
    title: `${ APP_SHORT_NAME } Points`,
    sorter: true,
    sorterName: "QXPoints",
  },
  {
    title: "Asset Qty",
    sorter: true,
    sorterName: "soldAssetQuantity",
  },
  {
    title: "Assets",
    sorter: false,
  },
  {
    title: DATE_OF_TRANSACTION,
    sorter: true,
    sorterName: "date",
  },
];
export const partnerGamerLinkedOfferTableConfigs = [
  {
    title: OFFER_NAME,
    sorter: true,
    sorterName: "offerName",
  },
  {
    title: "Description",
  },
  {
    title: "Game",
    sorter: true,
    sorterName: "gameName",
  },
  {
    title: START_DATE,
    sorterName: "startDateOriginal",
    sorter: true,
  },
  {
    title: "End Date",
    sorterName: "endDateOriginal",
    sorter: true,
  },
  {
    title: "Opt In",
    sorterName: "optInType",
    sorter: true,
  },
  {
    title: "Status",
    sorter: false,
  },
  {
    title: "Progress",
    sorter: true,
    sorterName: "progress",
  },
  {
    title: "View",
    sorter: false,
  },
];
export const analyticsOfferRewardTxnTableConfigs = [
  {
    title: "Game Name",
    sorter: true,
    sorterName: "name",
  },
  {
    title: "Offer Name",
    sorter: true,
    sorterName: "offerName",
  },
  {
    title: `${ APP_SHORT_NAME } Points`,
    sorter: true,
    sorterName: "QXPoints",
  },
  {
    title: "Date of Achievement",
    sorter: true,
    sorterName: "date",
  },
  {
    title: GAMER_NAME,
    sorter: true,
    sorterName: "gamerName",
  },
];
export const analyticsTournamentRewardTxnTableConfigs = [
  {
    title: "Game Name",
    sorter: true,
    sorterName: "name",
  },
  {
    title: "Tournament Name",
    sorter: true,
    sorterName: "offerName",
  },
  {
    title: `${ APP_SHORT_NAME } Points`,
    sorter: true,
    sorterName: "QXPoints",
  },
  {
    title: "Place",
    sorter: true,
    sorterName: "myPlace",
  },
  {
    title: "Date of Achievement",
    sorter: true,
    sorterName: "date",
  },
  {
    title: GAMER_NAME,
    sorter: true,
    sorterName: "gamerName",
  },
];
export const adminGamerTxnHistoryTableConfigs = [
  {
    title: DATE_OF_TRANSACTION,
    sorter: true,
    sorterName: "date",
  },
  {
    title: "Item",
    sorter: true,
    sorterName: "item",
  },
  {
    title: "Amount",
    sorter: true,
    sorterName: "amount",
  },
  {
    title: "Mode",
    sorter: true,
    sorterName: "mode",
  },
  {
    title: "Status",
    sorter: true,
    sorterName: "status",
  },
  {
    title: "Validity",
    sorter: false,
  },
];
export const analyticsOptInTxnTableConfigs = [
  {
    title: "Game Name",
    sorter: true,
    sorterName: "gameName",
  },
  {
    title: "Transaction For",
    sorter: true,
    sorterName: "transactionFor",
  },
  {
    title: "Offer/Tournament Name",
    sorter: true,
    sorterName: "offerName",
  },
  {
    title: `Entry Fee (${ APP_SHORT_NAME } Points)`,
    sorter: true,
    sorterName: "QXPoints",
  },
  {
    title: "Date of Transaction",
    sorter: true,
    sorterName: "date",
  },
  {
    title: GAMER_NAME,
    sorter: true,
    sorterName: "gamerName",
  },
];
export const analyticsLinkedGamersTableConfigs = [
  {
    title: GAMER_NAME,
    sorter: true,
    sorterName: "fullNames",
  },
  {
    title: "Linked Games",
    sorter: true,
    sorterName: "linkedGamesCount",
  },

  {
    title: "Gamer Plan",
    sorter: true,
    sorterName: "gamerPlan",
  },
  {
    title: "View",
    sorter: false,
    sorterName: "view",
  },
];
export const gamersOfferRewardTxnTable = [
  {
    title: "Game Name",
    sorter: true,
    sorterName: "gameName",
  },
  {
    title: OFFER_NAME,
    sorter: true,
    sorterName: "offerName",
  },
  {
    title: "Partner",
    sorter: true,
    sorterName: "partner",
  },
  {
    title: `${ APP_SHORT_NAME } Points`,
    sorter: true,
    sorterName: "QXPoints",
  },
  {
    title: "Date of Achievement",
    sorter: true,
    sorterName: "date",
  },
];
export const gamersTournamentRewardTxnTable = [
  {
    title: "Game Name",
    sorter: true,
    sorterName: "gameName",
  },
  {
    title: TOURNAMENT_NAME,
    sorter: true,
    sorterName: "offerName",
  },
  {
    title: "Partner",
    sorter: true,
    sorterName: "partner",
  },
  {
    title: `${ APP_SHORT_NAME } Points`,
    sorter: true,
    sorterName: "QXPoints",
  },
  {
    title: "Place",
    sorter: true,
    sorterName: "myPlace",
  },
  {
    title: "Date of Achievement",
    sorter: true,
    sorterName: "date",
  },
];
