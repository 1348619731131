import constant from "../../util/constant";
import {
  ADMIN_LOOKUP_API_REQUEST,
  ADMIN_LOOKUP_API_END,
  GET_ADMIN_LOOKUP_SUCCESS,
  GET_ADMIN_LOOKUP_FAILURE
} from "../actionTypes";

const { API_STATUS } = constant;

const DEFAULT_STATE = {
  loading: false,
  message: "",
  status: "",
  apiType: "",
  lookup: {},
};

const adminLookup = ( state = DEFAULT_STATE, action = "" ) => {
  switch ( action.type ) {
    case ADMIN_LOOKUP_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
      };
    case ADMIN_LOOKUP_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case GET_ADMIN_LOOKUP_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        lookup: action.payload,
      };
    case GET_ADMIN_LOOKUP_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        lookup: {},
      };
    default:
      return state;
  }
};

export default adminLookup;
