import { translations } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify";
import { EN_TRANSLATION } from "./translation";

I18n.putVocabularies( translations );
I18n.setLanguage( "en" );

export const translate = () => {
  I18n.putVocabularies( {
    en: EN_TRANSLATION,
  } );
};
