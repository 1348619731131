import PropTypes from "prop-types";
import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

const SecondaryContactInfo = ( {
  values,
  errors,
  handleInputChange,
  secondaryNameRef,
  secondaryTitleRef,
  secondaryPhoneNumberRef,
  secondaryEmailRef,
  onBlur,
} ) => (
  <>
    <h4 className='page-inner-heading'>
      Secondary Contact Information
    </h4>
    <Row>
      <Col sm={6}>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type='text'
            value={values.secondaryName}
            name='secondaryName'
            onChange={handleInputChange}
            maxLength={50}
            ref={secondaryNameRef}
            onBlur={onBlur}
          />
          <div className='invalid-feedback d-block'>
            {errors.secondaryName}
          </div>
        </Form.Group>
      </Col>
      <Col sm={6}>
        <Form.Group>
          <Form.Label>Title</Form.Label>
          <Form.Control
            type='text'
            value={values.secondaryTitle}
            name='secondaryTitle'
            onChange={handleInputChange}
            maxLength={50}
            ref={secondaryTitleRef}
            onBlur={onBlur}
          />
          <div className='invalid-feedback d-block'>
            {errors.secondaryTitle}
          </div>
        </Form.Group>
      </Col>
      <Col sm={6}>
        <Form.Group>
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type='text'
            value={values.secondaryPhoneNumber}
            name='secondaryPhoneNumber'
            onChange={handleInputChange}
            maxLength={14}
            ref={secondaryPhoneNumberRef}
            onBlur={onBlur}
          />
          <div className='invalid-feedback d-block'>
            {errors.secondaryPhoneNumber}
          </div>
        </Form.Group>
      </Col>
      <Col sm={6}>
        <Form.Group>
          <Form.Label>Alternative Phone Number (Optional)</Form.Label>
          <Form.Control
            type='text'
            value={values.secondaryAlternativePhoneNumber}
            name='secondaryAlternativePhoneNumber'
            onChange={handleInputChange}
            maxLength={14}
            onBlur={onBlur}
          />
        </Form.Group>
      </Col>
      <Col sm={6}>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control
            type='email'
            value={values.secondaryEmail}
            name='secondaryEmail'
            onChange={handleInputChange}
            ref={secondaryEmailRef}
            onBlur={onBlur}
          />
          <div className='invalid-feedback d-block'>
            {errors.secondaryEmail}
          </div>
        </Form.Group>
      </Col>
    </Row>
  </>
);

SecondaryContactInfo.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  handleInputChange: PropTypes.func,
  handleSelectInput: PropTypes.func,
  secondaryNameRef: PropTypes.func,
  secondaryTitleRef: PropTypes.func,
  secondaryPhoneNumberRef: PropTypes.func,
  secondaryEmailRef: PropTypes.func,
  onBlur: PropTypes.func,
};

export default SecondaryContactInfo;
