import React from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

// eslint-disable-next-line react/prop-types
const DocuSign = ( { onChange, envelopeId, errorMessage } ) => (
  <Modal.Body className='pb-0'>
    <Form.Group className='mb-0'>
      <Form.Control
        type='text'
        name='envelopeId'
        placeholder='Enter envelope ID'
        value={envelopeId}
        onChange={onChange}
      />
      <div className='invalid-feedback d-block'>{errorMessage}</div>
    </Form.Group>
  </Modal.Body>
);

export default DocuSign;
