import InfoIcon from "@material-ui/icons/Info";
import PropTypes from "prop-types";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";

import constant from "../../../../util/constant";
import { currencyFormat, formatNumberAsPerLocale } from "../../../../util/helpers";
import { ScrollPage } from "../../../shared/customScrollBar";
import TableHeaderRow from "../../../shared/tableHeadingRow";

const { BRANDING: { APP_SHORT_NAME } } = constant;

const OfferRewardTxnList = ( {
  rewardTxns,
  showFilter,
  usdPerQxPoint,
  memoizedTableConfig,
  memoizedHandleClick,
  isGamerTournaments = false,
} ) => {
  return (
    <>
      <div className={`table-container ${ isGamerTournaments ? "tournament-reward-txn-table" : "gamer-offer-reward-txn-table" }`}>
        <div className="table-head">
          <div className="table-row">
            <TableHeaderRow
              configData={memoizedTableConfig}
              onAscOrder={memoizedHandleClick}
              onDescOrder={memoizedHandleClick}
            />
          </div>
        </div>
        <Scrollbars
          renderThumbVertical={ScrollPage}
          className={`custom-scroll-height max ${ !showFilter ? "" : "full-height" }`}
        >
          <div className="table-body no-scrollbar" >
            {Boolean( rewardTxns.length ) && rewardTxns.map( ( offer, index ) => (
              <div className="table-row" key={index}>
                <div className="td">
                  <span className='text-green font-medium'>
                    {offer.gameName}
                  </span>
                </div>
                <div className="td">{offer.offerName}</div>
                <div className="td">{offer.partnerName}</div>
                <div className="td">{formatNumberAsPerLocale( offer.QXPoints )}
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip>
                        {formatNumberAsPerLocale( offer.QXPoints )} { APP_SHORT_NAME } Points = {currencyFormat( { data: ( offer.QXPoints * usdPerQxPoint ) } )}
                      </Tooltip>
                    }>
                    <InfoIcon className="d-inline-block ml-1 text-green"/>
                  </OverlayTrigger>
                </div>
                { isGamerTournaments && <div className="td">{offer.myPlace}</div> }
                <div className="td">{offer.transactionDate}</div>
              </div>
            ) )}
            {!rewardTxns.length && (
              <div className='no-record' data-testid='no-record-found'>
                No Offer Reward Txn Found
              </div>
            )}
          </div>
        </Scrollbars>
      </div>
    </>
  );
};

OfferRewardTxnList.propTypes = {
  usdPerQxPoint: PropTypes.number,
  rewardTxns: PropTypes.array,
  route: PropTypes.string,
  gamerId: PropTypes.string,
  showFilter: PropTypes.bool,
  handleSorting: PropTypes.func,
  membershipTypes: PropTypes.array,
  memoizedTableConfig: PropTypes.func,
  memoizedHandleClick: PropTypes.func,
  isGamerTournaments: PropTypes.bool,
  location: PropTypes.object,
};

export default OfferRewardTxnList;
