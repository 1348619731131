import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSetUrlParams } from "../../../../util/helpers";
import offerConstant from "../../../../util/offerConstant";
import CustomSlider from "../../../shared/customSlider";
import RangeDatePicker from "../../../shared/datePicker/rangeDatePicker";
import MultiSelectCheckbox from "../../../shared/multiSelectCheckbox";

const { OPT_IN_STATUS_DROPDOWN } = offerConstant;

const OffersFilters = ( {
  values,
  handleChange,
  isGamerTournaments,
  addQuery,
  offerList,
  offerStatus,
  gameList,
  locationState,
  handleFilterChange,
  clearSelectedFilter,
  progressStatus = [],
  gamerPlaceRange = { min: 0, max: 0 },
} ) => {
  const clearAllSelectedOptions = useCallback(
    params => clearSelectedFilter( params, addQuery, handleChange, locationState ), []
  );

  const handleSelect = ( filterName, obj ) => {
    handleFilterChange( filterName, obj, handleChange, addQuery, locationState );
  };

  const multiSelectedItems = useMemo( () => [
    values.offerNames,
    values.gameNames,
    values.optInTypes,
    values.offerStatus,
    progressStatus,
  ] );

  const offerSelectChange = useCallback(
    [ obj => handleSelect( "offerNames", obj ),
      obj => handleSelect( "gameNames", obj ),
      obj => handleSelect( "optInTypes", obj ),
      obj => handleSelect( "offerStatus", obj ),
      obj => handleSelect( "offerProgress", obj ),
    ],
    []
  );

  const handlePlace = ( e, newValue ) => {
    addQuery( useSetUrlParams( "minGamerPlace", newValue[ 0 ] ), locationState );
    addQuery( useSetUrlParams( "maxGamerPlace", newValue[ 1 ] ), locationState );
    handleChange( "minGamerPlace", newValue[ 0 ] );
    handleChange( "maxGamerPlace", newValue[ 1 ] );
  };

  return (
    <>
      <div className="filter-boxes offer-filter mr-0 mb-20">
        <Row>
          <Col sm={3}>
            <Form.Group className='custom-multiselect'>
              <Form.Label>{isGamerTournaments ? "Tournament" : "Offer"} Name</Form.Label>
              <MultiSelectCheckbox
                multiOptionsList={offerList}
                handleSelectChange={offerSelectChange[ 0 ]}
                multiSelectedItems={multiSelectedItems[ 0 ]}
                handleClearMultiSelect={() => clearAllSelectedOptions( "offerNames" )}/>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group className='custom-multiselect'>
              <Form.Label>Game</Form.Label>
              {<MultiSelectCheckbox
                multiOptionsList={gameList}
                handleSelectChange={offerSelectChange[ 1 ]}
                multiSelectedItems={multiSelectedItems[ 1 ]}
                handleClearMultiSelect={() => clearAllSelectedOptions( "gameNames" )}/>}
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group className='custom-datepicker range-picker'>
              <RangeDatePicker
                label='Date Range'
                values={values}
                addQuery={addQuery}
                handleChange={handleChange}
                param={{ key1: "startDate", key2: "endDate", key3: "", key4: "", page: "page" }}
                locationState={locationState}
                isExtraParam={true}
              />
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group className='custom-multiselect'>
              <Form.Label>Opt In</Form.Label>
              <MultiSelectCheckbox
                multiOptionsList={ OPT_IN_STATUS_DROPDOWN }
                handleSelectChange={offerSelectChange[ 2 ]}
                multiSelectedItems={multiSelectedItems[ 2 ]}
                handleClearMultiSelect={() => clearAllSelectedOptions( "optInTypes" )}
              />
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group className='custom-multiselect mb-0 mt-3'>
              <Form.Label>Status</Form.Label>
              <MultiSelectCheckbox
                multiOptionsList={ offerStatus }
                handleSelectChange={offerSelectChange[ 3 ]}
                multiSelectedItems={multiSelectedItems[ 3 ]}
                handleClearMultiSelect={() => clearAllSelectedOptions( "offerStatus" )}
              />
            </Form.Group>
          </Col>
          { !isGamerTournaments && (
            <Col sm={3}>
              <Form.Group className='custom-multiselect mb-0 mt-3'>
                <Form.Label>Progress</Form.Label>
                {<MultiSelectCheckbox
                  multiOptionsList={progressStatus}
                  handleSelectChange={offerSelectChange[ 4 ]}
                  multiSelectedItems={multiSelectedItems[ 4 ]}
                  handleClearMultiSelect={() => clearAllSelectedOptions( "offerProgress" )}/>}
              </Form.Group>
            </Col>
          ) }
          { isGamerTournaments && (
            <Col sm={3}>
              <div className='mb-0 mt-3'>
                <CustomSlider
                  values={values}
                  pointsHandler={handlePlace}
                  handleChange={handleChange}
                  min={gamerPlaceRange.min}
                  max={gamerPlaceRange.max}
                  minName={"minGamerPlace"}
                  maxName={"maxGamerPlace"}
                  label={"Place"}
                />
              </div>
            </Col>
          ) }
        </Row>
      </div>
    </>
  );
};

OffersFilters.propTypes = {
  values: PropTypes.object,
  onSearch: PropTypes.func,
  handleChange: PropTypes.func,
  addQuery: PropTypes.func,
  offerList: PropTypes.array,
  offerStatus: PropTypes.array,
  gameList: PropTypes.array,
  onCancel: PropTypes.func,
  locationState: PropTypes.string,
  handleFilterChange: PropTypes.func,
  clearSelectedFilter: PropTypes.func,
  progressStatus: PropTypes.array,
  isGamerTournaments: PropTypes.bool,
  gamerPlaceRange: PropTypes.object,
};

export default OffersFilters;
