import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  updatePartnerDraftDetailAction,
  addPartnerDraftDetailAction
} from "../../src/store/actions/partnerDraft";
import constant from "../../src/util/constant";
import partnerProfileConstant from "../../src/util/partnerProfileConstant";

const { API_TYPES } = partnerProfileConstant;
const { ENTITY_NAME, API_STATUS } = constant;

function usePartnerDraft( getRequestValue, partnerDraftDetail, apiType, apiStatus, tab, loader ) {
  const dispatch = useDispatch();

  useEffect( () => {
    if(
      ( apiType === API_TYPES.SET_GENERAL_INFO || apiType === API_TYPES.SET_CONTACT_INFO || apiType === API_TYPES.SET_OTHER_INFO || apiType === API_TYPES.SET_TERMS_INFO ) &&
            apiStatus === API_STATUS.SUCCESS
    ) {
      const agreedToTerms = getRequestValue( "allowTermsConditions" );
      const request = {
        username: getRequestValue( "username" ),
        email: getRequestValue( "email" ),
        /* eslint-disable camelcase */
        agreed_to_terms: agreedToTerms ? agreedToTerms : false,
        suggested_changes: getRequestValue( "suggestion" ),
        legal_entity_name: getRequestValue( "legalEntityName" ),
        category_id: getRequestValue( "categoryId" ),
        join_reason: getRequestValue( "joinReasonId" ),
        dba: getRequestValue( "dba" ),
        business_location_add1: getRequestValue( "address1" ),
        business_location_add2: getRequestValue( "address2" ),
        business_location_city: getRequestValue( "city" ),
        business_location_state: getRequestValue( "selectedState" ),
        business_location_zip_code: getRequestValue( "zip" ),
        business_location_country: getRequestValue( "selectedCountry" ),
        country_code: getRequestValue( "country" ),
        state_code: getRequestValue( "state" ),
        primary_name: getRequestValue( "primaryName" ),
        primary_title: getRequestValue( "primaryTitle" ),
        primary_email: getRequestValue( "primaryEmail" ),
        primary_phone: getRequestValue( "primaryPhoneNumber" ),
        primary_alternative_phone: getRequestValue(
          "primaryAlternativePhoneNumber"
        ),
        sec_name: getRequestValue( "secondaryName" ),
        sec_title: getRequestValue( "secondaryTitle" ),
        sec_email: getRequestValue( "secondaryEmail" ),
        sec_phone: getRequestValue( "secondaryPhoneNumber" ),
        sec_alternative_phone: getRequestValue(
          "secondaryAlternativePhoneNumber"
        ),
        authorized_signature_name: getRequestValue( "authorizedName" ),
        authorized_signature_title:
        getRequestValue( "authorizedTitle" ),
        no_games_estimates: getRequestValue( "estimatedOfGames" ),
        no_games_estimates_across:
        getRequestValue( "estimatedOfGamers" ),
        game_growth: getRequestValue( "gameGrowth" ),
        gamer_growth: getRequestValue( "gamersGrowth" ),
        software_development_ability: getRequestValue(
          "softwareDevelopmentAbility"
        ),
        icon_url: getRequestValue( "logo" ),
        partner_id: getRequestValue( "partnerId" ),
      };
      const dataObj = {
        entity_name: ENTITY_NAME.PARTNER_DRAFT,
        draft_content: request,
      };
      /* eslint-enable camelcase */

      if( partnerDraftDetail?.meta ) {
        dataObj.entityId = partnerDraftDetail?.meta.entity_id;
        dispatch( updatePartnerDraftDetailAction( dataObj, tab, loader ) );
      }
      else {
        dispatch( addPartnerDraftDetailAction( dataObj, tab, loader ) );
      }
    }
  }, [ apiType, apiStatus ] );

  return { apiStatus };
}

export default usePartnerDraft;
