import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import constant from "../../../util/constant";
import { useSetUrlParams } from "../../../util/helpers";
import AnalyticsTxnFilters from "../../shared/analytics/analyticsTxnFilters";
import CustomSlider from "../../shared/customSlider";
import MultiSelectCheckbox from "../../shared/multiSelectCheckbox";

const { BRANDING: { APP_SHORT_NAME } } = constant;

const AnalyticsExchangeFilterList = ( {
  values,
  handleChange,
  addQuery,
  gamerNames,
  gameNames,
  assetNames,
  transactionRanges,
  isGameId,
  locationState,
  tab,
} ) => {
  const analyticsExchangeListHandle = ( obj, listName ) => {
    if( obj.length > 0 ) {
      handleChange( "page", 1 );
      handleChange( listName, obj );
      addQuery( useSetUrlParams( listName, JSON.stringify( obj ) ), locationState, isGameId );
    }
    else {
      addQuery( useSetUrlParams( listName, "" ), locationState, isGameId );
      handleChange( listName );
    }
  };

  const analyticsExchangeClearHandle = ( params ) => {
    if( params ) {
      addQuery( useSetUrlParams( params, "" ), locationState, isGameId );
      handleChange( params, [] );
    }
  };

  const analyticsMultiSelected = useMemo( () => [ values.gameIds, values.assetName, values.gamerName ] );
  const analyticsExchangehandleSelect = useCallback(
    [ obj => analyticsExchangeListHandle( obj, "gameIds" ),
      obj => analyticsExchangeListHandle( obj, "assetName" ),
      obj => analyticsExchangeListHandle( obj, "gamerName" ),
    ],
    []
  );
  const analyticsExchangeHandle = useCallback(
    params => analyticsExchangeClearHandle( params ), []
  );

  const analyticsQxPointsHandler = ( e, newValue ) => {
    addQuery( useSetUrlParams( "minQxPoint", newValue[ 0 ] ), locationState, isGameId );
    addQuery( useSetUrlParams( "maxQxPoint", newValue[ 1 ] ), locationState, isGameId );
    handleChange( "minQxPoint", newValue[ 0 ] );
    handleChange( "maxQxPoint", newValue[ 1 ] );
  };

  return (
    <>
      <div className="filter-boxes trans-exchange-filter">
        <Row>
          <Col>
            <Form.Group className='custom-multiselect'>
              <Form.Label>Game Name</Form.Label>
              <MultiSelectCheckbox
                multiOptionsList={gameNames}
                handleSelectChange={analyticsExchangehandleSelect[ 0 ]}
                multiSelectedItems={analyticsMultiSelected[ 0 ]}
                handleClearMultiSelect={() => analyticsExchangeHandle( "gameIds" )}/>
            </Form.Group>
          </Col>
          <Col>
            <CustomSlider
              values={values}
              pointsHandler={analyticsQxPointsHandler}
              handleChange={handleChange}
              min={transactionRanges.min}
              max={transactionRanges.max}
              minName={"minQxPoint"}
              maxName={"maxQxPoint"}
              label={`${ APP_SHORT_NAME } Points`}
            />
          </Col>
          <Col>
            <Form.Group className='custom-multiselect'>
              <Form.Label>Assets</Form.Label>
              {<MultiSelectCheckbox
                multiOptionsList={assetNames}
                handleSelectChange={analyticsExchangehandleSelect[ 1 ]}
                multiSelectedItems={analyticsMultiSelected[ 1 ]}
                handleClearMultiSelect={() => analyticsExchangeHandle( "assetName" )}/>}
            </Form.Group>
          </Col>
          <AnalyticsTxnFilters
            tab={tab}
            values={values}
            handleChange={handleChange}
            addQuery={addQuery}
            gamerNames={gamerNames}
            isGameId={isGameId}
            locationState={locationState}
            onSelectFilterOptions={analyticsExchangehandleSelect}
            multiSelectedItems={analyticsMultiSelected}
            clearAllSelectedOptions={analyticsExchangeHandle}
          />
        </Row>
      </div>
    </>
  );
};

AnalyticsExchangeFilterList.propTypes = {
  values: PropTypes.object,
  onSearch: PropTypes.func,
  handleChange: PropTypes.func,
  addQuery: PropTypes.func,
  gamerNames: PropTypes.array,
  gameNames: PropTypes.array,
  transactionRanges: PropTypes.object,
  assetNames: PropTypes.array,
  onCancel: PropTypes.func,
  isGamerNames: PropTypes.bool,
  isGameId: PropTypes.bool,
  locationState: PropTypes.string,
  tab: PropTypes.string,
};

export default AnalyticsExchangeFilterList;
