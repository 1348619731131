import PropTypes from "prop-types";
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useScroll } from "../../../hooks";
import { formatNumberAsPerLocale, currencyFormat } from "../../../util/helpers";
import { ScrollPage } from "../../shared/customScrollBar";
import TableHeaderRow from "../../shared/tableHeadingRow";

const RedeemTransactionList = ( {
  values,
  transactions,
  handleChange,
  memoizedTableConfig,
  memoizedHandleClick,
  listMetaData,
  showFilter,
} ) => {
  const { scrollEvent } = useScroll();

  const onScroll = ( e ) => {
    scrollEvent( e, transactions, values, handleChange, listMetaData, "page" );
  };

  return (
    <div className="table-container gamer-redeem-transaction">
      <div className="table-head">
        <div className="table-row">
          <TableHeaderRow
            configData={memoizedTableConfig}
            onAscOrder={memoizedHandleClick}
            onDescOrder={memoizedHandleClick}
          />
        </div>
      </div>
      <Scrollbars
        renderThumbVertical={ScrollPage}
        onScroll={onScroll}
        className={`custom-scroll-height max ${ !showFilter ? "" : "full-height" }`}
      >
        <div className='table-body no-scrollbar'>
          { transactions?.length > 0
            ? transactions.map( ( tx, index ) => (
              <div className="table-row" key={index}>
                <div className="td"><span className='large-text'>
                  <img src={tx.iconUrl} alt="games" className='logo' />{tx.cashbackName}
                </span></div>
                <div className="td"><span className='big-text'>{formatNumberAsPerLocale( tx.acQyrCashSold )}</span></div>
                <div className="td">{tx.status}</div>
                <div className="td"><span className='link'>{currencyFormat( { data: tx.netRedeemAmount } )}</span></div>
                <div className="td">{tx.redeemedDate}</div>
              </div>
            ) )
            : (
              <div className='no-record' data-testid='no-record-found'>No Redeem Transaction Found</div>
            )}
        </div>
      </Scrollbars>
    </div>
  );
};

RedeemTransactionList.propTypes = {
  values: PropTypes.object,
  transactions: PropTypes.array,
  handleChange: PropTypes.func,
  memoizedTableConfig: PropTypes.object,
  memoizedHandleClick: PropTypes.func,
  listMetaData: PropTypes.object,
  showFilter: PropTypes.bool,
};

export default RedeemTransactionList;
