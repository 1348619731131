import InfoIcon from "@material-ui/icons/Info";
import PropTypes from "prop-types";
import React from "react";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Row from "react-bootstrap/Row";
import constant from "../../../util/constant";
import { formatNumberAsPerLocale } from "../../../util/helpers";

const { OPT_IN_STATUS, BRANDING: { APP_SHORT_NAME } } = constant;

const OptInDetails = ( {
  optInDetail,
  eligibleSubPlans,
} ) => {
  return (
    <>
      { ( optInDetail?.optIn !== OPT_IN_STATUS.NO )
        ? (
          <>
            <strong className='font-13'> {optInDetail?.optIn}</strong>
            <OverlayTrigger placement='auto'
              overlay={
                <Popover className='opt-in-popover'>
                  <Popover.Content>
                    <div className='pop-title'>Eligible Subscription Plan</div>
                    { eligibleSubPlans?.map( plan => (
                      <>
                        <span className='badge badge-primary mr-1'>{plan.name}</span>
                      </>
                    ) ) }
                    { optInDetail?.entryFee && (
                      <div className='pop-title'>
                        Entry Fee
                      </div> )
                    }
                    { optInDetail?.entryFee && (
                      <span className='fee-text'>
                        <strong className='text-green'>
                          {formatNumberAsPerLocale( optInDetail?.entryFee )}
                        </strong> { APP_SHORT_NAME } Points
                      </span> )
                    }
                    <Row>
                      <Col sm={6}>
                        <div className='pop-title'>Min No. of Participants</div>
                        <strong className='text-green'>
                          { formatNumberAsPerLocale( optInDetail.minParticipants, "No Minimum" )}
                        </strong>
                      </Col>
                      <Col sm={6}>
                        <div className='pop-title'>Max No. of Participants</div>
                        <strong className='text-green'>
                          { formatNumberAsPerLocale( optInDetail.maxParticipants, "No Maximum" )}
                        </strong>
                      </Col>
                    </Row>
                  </Popover.Content>
                </Popover>
              }
            >
              <InfoIcon />
            </OverlayTrigger>
          </>
        )
        : (
          <p className="mb-0 ml-1 font-13">{optInDetail?.optIn}</p>
        )
      }
    </>
  );
};

OptInDetails.propTypes = {
  optInDetail: PropTypes.object,
  eligibleSubPlans: PropTypes.array,
};

export default OptInDetails;
