const adminGamerConstant = {
  GAMER_USERS_API_TYPES: {
    GET_GAMERS_LIST: "GET_GAMERS_LIST",
    GET_GAMERS_FILTER_LIST: "GET_GAMERS_FILTER_LIST",
    GET_GAMER_DETAIL: "GET_GAMER_DETAIL",
    GET_GAMER_ASSET_LIST: "GET_GAMER_ASSET_LIST",
    GET_GAMER_OFFER_LIST: "GET_GAMER_OFFER_LIST",
    GET_ALL_GAMERS_LIST: "GET_ALL_GAMERS_LIST",
    GET_GAMER_LINKED_OFFERS: "GET_GAMER_LINKED_OFFERS",
    GET_EXCHANGE_TRANSACTION: "GET_EXCHANGE_TRANSACTION",
    GET_GAMER_REDEEM_TRANSACTION: "GET_GAMER_REDEEM_TRANSACTION",
    GET_GAMER_REDEEM_TX_FILTER: "GET_GAMER_REDEEM_TX_FILTER",
    GET_GAMER_ASSETS: "GET_GAMER_ASSETS",
    GET_GAMER_OFFER_FILTER: "GET_GAMER_OFFER_FILTER",
    GET_GAMER_TOURNAMENT_LIST: "GET_GAMER_TOURNAMENT_LIST",
    GET_GAMER_TOURNAMENT_FILTER: "GET_GAMER_TOURNAMENT_FILTER",
    GET_GAMER_TXN_HISTORY_LIST: "GET_GAMER_TXN_HISTORY_LIST",
    GET_GAMER_OFFER_REWARD_TXN: "GET_GAMER_OFFER_REWARD_TXN",
  },
  GAME_LIST_SORT: {
    GAME_NAME: "name",
    START_DATE: "Start Date",
    END_DATE: "End Date",
    DATE_LINKED: "Date Linked",
  },
  DATE_BY: {
    START_DATE: "Start Date",
    END_DATE: "End Date",
  },
  TXN_STATUS: [
    {
      title: "Pending",
      class: "draft",
    },
    {
      title: "Failed",
      class: "badge-warning",
    },
    {
      title: "Succeeded",
      class: "badge-primary",
    },
  ],
};

export default adminGamerConstant;
