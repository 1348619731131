import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSetUrlParams } from "../../../util/helpers";
import CustomSlider from "../../shared/customSlider";
import MultiSelectCheckbox from "../../shared/multiSelectCheckbox";

const AnalyticsAssetsFilterList = ( {
  values,
  handleChange,
  addQuery,
  assetId,
  assetName,
  isGameId,
  showFilter,
  locationState,
  assetsFilterList,
} ) => {
  const analyticsAssetsListHandle = ( obj, listName ) => {
    if( obj.length > 0 ) {
      handleChange( "page", 1 );
      handleChange( listName, obj );
      addQuery( useSetUrlParams( listName, JSON.stringify( obj ) ), locationState, isGameId );
    }
    else {
      addQuery( useSetUrlParams( listName, "" ), locationState, isGameId );
      handleChange( listName );
    }
  };

  const analyticsAssetsClearHandle = ( params ) => {
    if( params ) {
      addQuery( useSetUrlParams( params, "" ), locationState, isGameId );
      handleChange( params, [] );
    }
  };

  const analyticsMultiSelected = useMemo( () => [ values.assetId, values.assetName ] );
  const analyticsAssetshandleSelect = useCallback(
    [
      obj => analyticsAssetsListHandle( obj, "assetId" ),
      obj => analyticsAssetsListHandle( obj, "assetName" ),
    ],
    []
  );
  const analyticsAssetsHandle = useCallback(
    params => analyticsAssetsClearHandle( params ), []
  );

  const debouncedUnitChangeHandler = ( e, newValue ) => {
    addQuery( useSetUrlParams( "minUnitPrice", newValue[ 0 ] ), locationState, isGameId );
    addQuery( useSetUrlParams( "maxUnitPrice", newValue[ 1 ] ), locationState, isGameId );
    handleChange( "minUnitPrice", newValue[ 0 ] );
    handleChange( "maxUnitPrice", newValue[ 1 ] );
  };

  const debouncedQuantityChangeHandler = ( e, newValue ) => {
    addQuery( useSetUrlParams( "minQuantity", newValue[ 0 ] ), locationState, isGameId );
    addQuery( useSetUrlParams( "maxQuantity", newValue[ 1 ] ), locationState, isGameId );
    handleChange( "minQuantity", newValue[ 0 ] );
    handleChange( "maxQuantity", newValue[ 1 ] );
  };

  return (
    <>
      <div className={`filter-boxes mr-0 bottom-margin ${ !showFilter ? "" : "remove-filter" }`}>
        <Row>
          <Col md={3}>
            <Form.Group className='custom-multiselect'>
              <Form.Label>Asset ID</Form.Label>
              <MultiSelectCheckbox
                multiOptionsList={assetId}
                handleSelectChange={analyticsAssetshandleSelect[ 0 ]}
                multiSelectedItems={analyticsMultiSelected[ 0 ]}
                handleClearMultiSelect={() => analyticsAssetsHandle( "assetId" )} />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className='custom-multiselect'>
              <Form.Label>Asset Name</Form.Label>
              {<MultiSelectCheckbox
                multiOptionsList={assetName}
                handleSelectChange={analyticsAssetshandleSelect[ 1 ]}
                multiSelectedItems={analyticsMultiSelected[ 1 ]}
                handleClearMultiSelect={() => analyticsAssetsHandle( "assetName" )} />}
            </Form.Group>
          </Col>
          <Col>
            <CustomSlider
              values={values}
              pointsHandler={debouncedQuantityChangeHandler}
              handleChange={handleChange}
              min={assetsFilterList.quantityMinMax.min}
              max={assetsFilterList.quantityMinMax.max}
              minName={"minQuantity"}
              maxName={"maxQuantity"}
              label={"Quantity"}
            />
          </Col>
          <Col>
            <CustomSlider
              values={values}
              pointsHandler={debouncedUnitChangeHandler}
              handleChange={handleChange}
              min={assetsFilterList.priceMinMax.min}
              max={assetsFilterList.priceMinMax.max}
              minName={"minUnitPrice"}
              maxName={"maxUnitPrice"}
              label={"Unit Price"}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

AnalyticsAssetsFilterList.propTypes = {
  values: PropTypes.object,
  onSearch: PropTypes.func,
  handleChange: PropTypes.func,
  addQuery: PropTypes.func,
  assetId: PropTypes.func,
  assetName: PropTypes.array,
  onCancel: PropTypes.func,
  isGameId: PropTypes.bool,
  locationState: PropTypes.string,
  showFilter: PropTypes.string,
  assetsFilterList: PropTypes.object,
};

export default AnalyticsAssetsFilterList;
