import { convertDate } from "../util/helpers";

export class GamerGameList {
  constructor( obj ) {
    this.userGames = obj?.gamerGames?.map( ( games ) => {
      return {
        dateLinked: games.dateLinked ? convertDate( games.dateLinked ) : "",
        gameId: games.gameId,
        name: games.name,
        iconUrl: games.iconUrl,
        assetsCount: games.assetsCount,
        offersCount: games.offersCount,
        // sortable date formats for start and end dates
        startDateOriginal: games.dateLinked ? convertDate( games.dateLinked, true ) : "",
        endDateOriginal: games.dateLinked ? convertDate( games.dateLinked, true ) : "",
      };
    } ) || [];
  }
}
