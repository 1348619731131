import PropTypes from "prop-types";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useForm, useFilter, getUrlData } from "../../../../hooks";
import { getGamerDetailAction, getGamerLinkedOfferAction } from "../../../../store/actions/adminGamer";
import adminGamerConstant from "../../../../util/adminGamerConstant";
import OffersFilters from "./offersFilters";
import OffersList from "./offersList";

const { GAMER_USERS_API_TYPES } = adminGamerConstant;

const Offers = ( {
  location,
  showFilter,
  addQuery,
  dispatch,
  searchKey = "",
  apiStatus,
  route,
  orderBy,
  memoizedTableConfig,
  setPreviousData,
  isGamerTournaments,
  history,
} ) => {
  const { gamerId: URLString } = useParams();
  const { 0: gamerId } = String( URLString ).split( "?" );
  const {
    setPureData, filteredData, filterData, handleSorting, clearSelectedFilter, handleFilterChange,
  } = useFilter();
  const {
    status,
    apiType,
    gamerLinkedOffers,
    gameNames,
    offerNames,
    offerStatus,
    configuration,
  } = useSelector( state => ( {
    status: state.adminGamer.status,
    apiType: state.adminGamer.apiType,
    gamerLinkedOffers: state.adminGamer.gamerLinkedOffers.linkedOffers,
    gameNames: state.adminGamer.gamerLinkedOffers.gameNames,
    offerNames: state.adminGamer.gamerLinkedOffers.offerNames,
    offerStatus: state.adminGamer.gamerLinkedOffers.offerStatus,
    configuration: state.adminDetail.data?.configuration || {},
  } ) );
  const { values, handleChange } = useForm(
    {
      searchKey: "",
      sortBy: "offerName",
      sortOrder: orderBy.ASC,
      gameNames: [],
      offerNames: [],
      offerStatus: [],
      optInTypes: [],
      startDate: null,
      endDate: null,
      minGamerPlace: 0,
      maxGamerPlace: 0,
      searchCol: [ "offerName", "gameName", "descriptionText" ],
    },
    {}
  );

  const memoizedHandleClick = useCallback(
    ( sortBy, orderType ) => handleSorting( sortBy, orderType, handleChange, addQuery, location.state ),
    []
  );

  const requestPayload = Object.assign(
    {
      searchKey: values.searchKey,
      sortOrder: values.sortOrder,
      sortBy: values.sortBy,
      gameNames: values.gameNames?.map( value => value.value ) || [],
      offerNames: values.offerNames?.map( value => value.value ) || [],
      offerStatus: values.offerStatus?.map( value => value.value ) || [],
      optInTypes: values.optInTypes?.map( value => value.value ) || [],
      startDate: values.startDate,
      endDate: values.endDate,
      searchCol: values.searchCol,
    }
  );

  useEffect( () => {
    handleChange( "searchKey", searchKey );
  }, [ searchKey ] );

  useEffect( () => {
    dispatch(
      getGamerLinkedOfferAction(
        gamerId,
        {
          sortBy: "offerName",
          sortOrder: orderBy.ASC,
          fetchAllRecords: true,
          offerType: isGamerTournaments ? "Tournament" : "Offer",
          offer: "currentOrExpired",
        }
      )
    );
  }, [] );

  useEffect( () => {
    if(
      ( status === apiStatus ) &&
      ( apiType === GAMER_USERS_API_TYPES.GET_GAMER_LINKED_OFFERS )
    ) {
      dispatch( getGamerDetailAction( gamerId ) );
      setPureData( gamerLinkedOffers );
      getUrlData( handleChange );
      filterData( requestPayload );
      setPreviousData();
    }
  }, [ status, apiType ] );

  useEffect( () => {
    filterData( requestPayload );
  }, [
    values.startDate,
    values.endDate,
    values.offerNames,
    values.offerStatus,
    values.gameNames,
    values.sortBy,
    values.sortOrder,
    values.searchKey,
    values.optInTypes,
  ] );

  return (
    <>
      <div className={`filter-tabs-wrapper mb-0 ${ !showFilter ? "" : "remove-filter" }`}>
        <OffersFilters
          showFilter={showFilter}
          gameList={gameNames}
          offerList={offerNames}
          offerStatus={offerStatus}
          handleChange={handleChange}
          addQuery={addQuery}
          values={values}
          isGamerTournaments={isGamerTournaments}
          locationState={location.state}
          handleFilterChange={handleFilterChange}
          clearSelectedFilter={clearSelectedFilter}
        />
      </div>
      <OffersList
        offers={filteredData}
        route={route}
        gamerId={gamerId}
        location={location}
        showFilter={showFilter}
        history={history}
        isGamerTournaments={isGamerTournaments}
        membershipTypes={configuration.membershipTypes}
        memoizedTableConfig={memoizedTableConfig}
        memoizedHandleClick={memoizedHandleClick}
      />

    </>
  );
};

Offers.propTypes = {
  location: PropTypes.object,
  showFilter: PropTypes.bool,
  addQuery: PropTypes.func,
  dispatch: PropTypes.func,
  route: PropTypes.string,
  orderBy: PropTypes.object,
  searchKey: PropTypes.string,
  apiStatus: PropTypes.string,
  memoizedTableConfig: PropTypes.func,
  setPreviousData: PropTypes.func,
  isGamerTournaments: PropTypes.bool,
  history: PropTypes.func,
};

export default Offers;
