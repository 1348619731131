import React from "react";
import { Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import constant from "../../../../util/constant";
import ExchangeTxn from "./exchangeTxn";
import Gamers from "./gamers";
import Games from "./games";
import QxMembers from "./qxMembers";

const { ROUTES } = constant;

const Dashboard = () => {
  const history = useHistory();

  const redirectToAll = ( val ) => {
    history.replace( `${ ROUTES.PARTNER_ANALYTICS }?tab=${ val }` );
  };

  return (
    <>
      <div className="analytics-bottom-box">
        <Row>
          <Gamers></Gamers>
          <QxMembers redirectToAll={redirectToAll} />
        </Row>
        <Row>
          <Games redirectToAll={redirectToAll} />
          <ExchangeTxn redirectToAll={redirectToAll} />
        </Row>
      </div>
    </>
  );
};

export default Dashboard;
