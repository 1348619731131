import { API_URL } from "../util/apiPaths";
import { request } from "./api.js";

export function getAdminTournaments( params ) {
  return request( "GET", API_URL.ADMIN_OFFERS, params );
}

export const adminTournamentService = {
  getAdminTournaments,
};
