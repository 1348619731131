import React from "react";
import { useSelector } from "react-redux";
import { EmailReset } from "../../shared/emailReset";
import { PasswordReset } from "../../shared/passwordReset";

const AdminSettings = ( ) => {
  const { email } = useSelector( state => ( {
    email: state?.adminDetail?.data?.email,
  } ) );

  return (
    <div className="main-wrapper">

      <div className="main-right-wrapper">

        <div className="page-head-wrapper">
          <h4 className="page-heading">Settings</h4>
        </div>

        <div className="page-inner-box profile-steps-box setting-box">
          <EmailReset email={email} roleType='admin'/>
          <PasswordReset/>
        </div>
      </div>
    </div>
  );
};

export default AdminSettings;
