import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import HelpIcon from "@material-ui/icons/Help";
import { EditorState } from "draft-js";
import React, { useEffect, useState } from "react";
import { Form, Col, Button, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { DraftOfferRequest } from "../../../entities/draftOfferRequest";
import { DraftTournamentRequest } from "../../../entities/draftTournamentRequest";
import { OfferTemplateRequest } from "../../../entities/newOfferTemplateRequest";
import { PartnerOfferFromScratchRequest } from "../../../entities/partnerOfferFromScratchRequest";
import { useForm, useModal, useInputChange } from "../../../hooks";
import { getAdminEventDetailAction } from "../../../store/actions/adminEvent";
import { getAdminLookupAction } from "../../../store/actions/adminLookup";
import {
  addNewAdminOfferDraftAction,
  updateAdminOfferDraftAction,
  getAdminOfferDraftDetailAction
} from "../../../store/actions/adminOfferDraft";
import {
  getAdminOfferDetailsAction,
  addNewAdminOfferAction,
  updateAdminOfferAction,
  updatePartnerOfferByAdminAction
} from "../../../store/actions/adminOffers";
import adminConstant from "../../../util/adminConstant";
import constant from "../../../util/constant";
import eventConstant from "../../../util/eventConstant";
import {
  doAchievementsHaveNoError,
  randomString,
  getOfferHeaderData,
  doRewardsHaveNoError
} from "../../../util/helpers";
import offerConstant from "../../../util/offerConstant";
import { validateAchievements, validateRewards } from "../../../util/validateMultipleFields";
import { OfferTemplate, ScheduleOffer } from "../../shared/offers";
import ModalPopup from "../modal";


const { API_STATUS, DEFAULT_UUID, ROUTES, ADVERTISE_OPTIONS, OPT_IN_STATUS } = constant;
const { EVENT_API_TYPES } = eventConstant;
const { OFFER_API_TYPES, OFFER_TYPE_STATUS, OFFER_DETAIL_STATUS, REQUEST_TYPE } = offerConstant;
const { MODAL_TYPE_DETAIL } = adminConstant;

const initialState = {
  description: EditorState.createEmpty(),
  descriptionText: "",
  offerName: "",
  qxPoint: 0,
  relationExpression: "",
  rawDescription: "",
  achievementErrors: [],
  offerTemplate: "",
  game: "",
  gamers: "",
  offerCanBeAchievedCount: "",
  startDate: false,
  endDate: false,
  advertiseToGamer: ADVERTISE_OPTIONS[ 0 ],
  noOfDays: 0,
  advertiseDate: false,
  optInType: OPT_IN_STATUS.NO,
  membershipId: "",
  entryFee: "",
  minParticipants: "",
  maxParticipants: "",
  minParticipantsNoLimit: true,
  maxParticipantsNoLimit: true,
  rewardsErrors: [],
};
const initialDirty = {
  descriptionText: false,
  offerName: false,
};

const templateInitialDirty = {
  descriptionText: false,
  offerName: false,
  qxPoint: false,
  startDate: false,
  endDate: false,
  game: false,
  gamers: false,
  offerCanBeAchievedCount: false,
  noOfDays: false,
  advertiseDate: false,
  minParticipants: true,
  maxParticipants: true,
  entryFee: true,
};

const achievementState = {
  name: "",
  description: EditorState.createEmpty(),
  descriptionText: "",
  rawDescription: "",
  event: "",
  function: "",
  eventDescription: "",
  eventUnit: "",
};

// TODO: refactor to avoid complexity
// eslint-disable-next-line complexity
const AddEditAdminOffer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { handleInputChanges } = useInputChange();
  const isAdminAddEditOffer = true;
  const {
    apiType,
    status,
    eventApiType,
    eventStatus,
    lookup,
    adminEventDetail,
    offerDetail,
    draftApiType,
    configuration,
    draftStatus,
    membershipTypes } = useSelector( state => ( {
    apiType: state.adminOffer.apiType,
    status: state.adminOffer.status,
    offerDetail: state.adminOffer.offerDetails,
    eventApiType: state.adminEvent.apiType,
    eventStatus: state.adminEvent.status,
    adminEventDetail: state.adminEvent.adminEventDetail,
    lookup: state.adminLookup.lookup,
    draftApiType: state.adminOfferDraft.apiType,
    draftStatus: state.adminOfferDraft.status,
    configuration: state.adminDetail.data?.configuration || {},
    membershipTypes: state.adminDetail.data?.membershipTypes || [],
  } ) );
  const paramsQuery = new URLSearchParams( window.location.search );
  const offerType = paramsQuery.get( "status" );

  const [ , , entityType ] = location.pathname.split( "/" );
  const isOfferScreen = entityType === "offer";

  const { partnerCostUsdPerQxPoint } = configuration;

  const { offerId: urlParamString } = useParams();
  const { 0: offerId } = String( urlParamString ).split( "?" );
  const [ isEditOffer, setEditOfferStatus ] = useState( false );
  const [ errorMessage, setErrorMessage ] = useState( "" );
  const { isOpened, closeModal, openModal, modalType, setModalType } =
    useModal();
  const { values, errors, isError, handleChange, showAllErrorMessage, changeDirtyObj } =
    useForm(
      { ...initialState,
        achievements: [ {
          ...achievementState,
          ...( isOfferScreen ? { comparison: "", value: "", days: 0, hours: 0, minutes: 0, seconds: 0 } : { ranking: "" } ),
        } ],
        ...( isOfferScreen ? {} : { rewards: [ { qxPoint: 0, place: 1 } ] } ),
      },
      initialDirty
    );

  const isPartnerOffer = offerDetail?.partnerId !== DEFAULT_UUID && offerId !== "0" && offerType !== OFFER_TYPE_STATUS.DRAFT;

  const handleInputChange = ( e ) => {
    handleInputChanges( e, handleChange );
  };

  const handleDateChange = ( name, e ) => {
    if( e ) {
      handleChange( [ name ], new Date( e ).toLocaleDateString( "fr-CA" ) );
    }
    else {
      handleChange( [ name ], false );

      if( name === "startDate" ) {
        handleChange( "advertiseDate", false );
        handleChange( "noOfDays", 0 );
      }
    }
  };

  const handleAdvertiseDate = ( e ) => {
    if( e ) {
      handleChange( "advertiseDate", new Date( e ).toLocaleDateString( "fr-CA" ) );
    }
    else {
      handleChange( "advertiseDate", false );
    }
  };

  const handleSubmit = ( params ) => {
    if( params === "draft" ) {
      handleOpenModal( MODAL_TYPE_DETAIL.DRAFT_SAVE_ALERT.type );
    }
    else {
      showAllErrorMessage();
      handleChange( "achievementErrors", validateAchievements( values.achievements, values.achievementErrors ) );

      const noErrorInAchievements = doAchievementsHaveNoError( values.achievements, values.achievementErrors, isOfferScreen );

      if( ( !isError ) && ( noErrorInAchievements ) ) {
        if( isPartnerOffer ) {
          const requestType = isOfferScreen ? REQUEST_TYPE.OFFER : REQUEST_TYPE.TOURNAMENT;
          const requestObj = new PartnerOfferFromScratchRequest( { ...values, offerId, partnerCostUsdPerQxPoint, requestType } );

          dispatch( updatePartnerOfferByAdminAction( requestObj ) );
        }
        else if( ( !isEditOffer ) && ( !isPartnerOffer ) ) {
          const requestType = isOfferScreen ? REQUEST_TYPE.OFFER_TEMPLATE : REQUEST_TYPE.TOURNAMENT_TEMPLATE;
          const requestObj = new OfferTemplateRequest( { ...values, partnerCostUsdPerQxPoint, requestType } );

          dispatch( addNewAdminOfferAction( requestObj ) );
        }
        else {
          const requestType = isOfferScreen ? REQUEST_TYPE.OFFER_TEMPLATE : REQUEST_TYPE.TOURNAMENT_TEMPLATE;
          const requestObj = new OfferTemplateRequest( { ...values, offerId, partnerCostUsdPerQxPoint, requestType } );

          dispatch( updateAdminOfferAction( requestObj ) );
        }
      }
    }
  };

  const handleOpenModal = ( mType ) => {
    setModalType( mType );
    openModal();
  };

  const handleCloseModal = () => {
    closeModal();
    setErrorMessage();
    handleChange( "rejectionReason", "" );
  };

  const handleReject = () => {
    if( modalType === MODAL_TYPE_DETAIL.DRAFT_SAVE_ALERT.type ) {
      closeModal();

      const dummy = `Draft -${ randomString( 7 ) }`;

      if( !values.offerName ) {
        values.offerName = dummy;
      }

      if( !values.descriptionText ) {
        values.descriptionText = dummy;
        values.rawDescription = dummy;
      }

      if( !isEditOffer ) {
        saveDraftOffer();
      }
      else {
        updateDraftOffer();
      }
    }
    else {
      closeModal();
    }
  };

  const saveDraftOffer = () => {
    const requestObj = isOfferScreen
      ? new DraftOfferRequest( values )
      : new DraftTournamentRequest( { ...values, partnerCostUsdPerQxPoint, partnerTournament: false, isAdminAddEditOffer } );
    const entityName = isOfferScreen ? "offer_template_draft" : "tournament_template_draft";

    dispatch( addNewAdminOfferDraftAction( entityName, { ...requestObj, partnerId: DEFAULT_UUID } ) );
  };

  const updateDraftOffer = () => {
    const requestObj = isOfferScreen
      ? new DraftOfferRequest( { ...values, offerId, partnerId: DEFAULT_UUID } )
      : new DraftTournamentRequest( { ...values, partnerCostUsdPerQxPoint, offerId, partnerId: DEFAULT_UUID, partnerTournament: false, isAdminAddEditOffer } );

    dispatch( updateAdminOfferDraftAction( requestObj, offerId ) );
  };

  const handleSelectInput = ( name, value = "" ) => {
    handleChange( name, value );

    if( name === "advertiseToGamer" ) {
      handleChange( "noOfDays", 0 );
      handleChange( "advertiseDate", false );
    }
  };

  let offerText = "";

  if( offerType === OFFER_TYPE_STATUS.DRAFT ) {
    offerText = " Draft";
  }
  else if( offerType === OFFER_TYPE_STATUS.OFFER_TEMPLATE ) {
    offerText = " Template";
  }

  const headerDetail = getOfferHeaderData( offerType, OFFER_TYPE_STATUS.OFFER_TEMPLATE );

  const onSelectedEvent = eventId => dispatch( getAdminEventDetailAction( eventId ) );

  const displaySuccessToast = ( message ) => {
    let toastMessage = message;

    if( !isOfferScreen ) {
      toastMessage = message.replace( "Offer", "Tournament" );
    }

    toastr.success( toastMessage );
    history.goBack();
  };

  // Use to add rewards in the tournament.
  // Handling the rewards array.
  const addReward = () => {
    handleChange( "rewardsErrors", validateRewards( values.rewards, true ) );

    const notFoundRewardError = doRewardsHaveNoError( values.rewards, true );

    if( notFoundRewardError ) {
      handleChange( "rewards", [ ...values.rewards, { qxPoint: 0, place: values.rewards.length + 1 } ] );
    }
  };

  const handleDeleteReward = ( place ) => {
    const rewards = values.rewards.filter( reward => reward.place !== place ).map( ( reward, i ) => {
      return { qxPoint: reward.qxPoint, place: i + 1 };
    } );

    handleChange( "rewards", rewards );
    handleChange( "rewardsErrors", validateRewards( rewards, values.isTournament ) );
  };

  useEffect( () => {
    if( offerType === OFFER_TYPE_STATUS.DRAFT ) {
      setEditOfferStatus( true );
      dispatch( getAdminOfferDraftDetailAction( offerId, !isOfferScreen ) );
    }

    if( !offerType ) {
      if( offerId !== "0" ) {
        dispatch( getAdminOfferDetailsAction( offerId ) );
        setEditOfferStatus( true );
      }
      else {
        dispatch( getAdminLookupAction( { entities: "EnabledEvent" } ) );
      }
    }
  }, [ offerId ] );

  // TODO: refactor to avoid complexity
  // eslint-disable-next-line complexity
  useEffect( () => {
    if( ( status === API_STATUS.SUCCESS ) &&
    ( apiType === OFFER_API_TYPES.ADD_ADMIN_NEW_OFFER || apiType === OFFER_API_TYPES.UPDATE_ADMIN_OFFER ) ) {
      displaySuccessToast( isEditOffer ? "Offer template updated successfully" : "Offer template added successfully" );
    }
    else if( ( status === API_STATUS.SUCCESS ) &&
    ( apiType === OFFER_API_TYPES.UPDATE_PARTNER_OFFER_BY_ADMIN ) ) {
      displaySuccessToast( "Offer updated successfully" );
    }
    else if( ( draftStatus === API_STATUS.SUCCESS ) &&
    ( draftApiType === OFFER_API_TYPES.ADD_ADMIN_NEW_DRAFT_OFFER || draftApiType === OFFER_API_TYPES.UPDATE_ADMIN_OFFER_DRAFT_TEMPLATE ) ) {
      displaySuccessToast( isEditOffer ? "Offer template draft updated successfully" : "Offer template draft added successfully" );
    }
    else if( ( eventStatus === API_STATUS.SUCCESS ) && ( eventApiType === EVENT_API_TYPES.GET_ADMIN_EVENT_DETAIL ) ) {
      const { achievements } = values;
      const updatedAchievement = achievements.map( ( achievement ) => {
        if( achievement.event.value === adminEventDetail.eventId ) {
          return { ...achievement, eventDescription: adminEventDetail.description, eventUnit: adminEventDetail.unitName };
        }

        return achievement;
      } );

      handleChange( "achievements", updatedAchievement );
    }
    else if( ( ( status === API_STATUS.SUCCESS ) && ( apiType === OFFER_API_TYPES.GET_ADMIN_OFFER_DETAILS ) )
    || ( ( draftStatus === API_STATUS.SUCCESS ) && ( draftApiType === OFFER_API_TYPES.GET_PARTNER_DRAFT_OFFER_TEMPLATE_DETAIL ) ) ) {
      dispatch(
        getAdminLookupAction(
          {
            entities: offerDetail.isAvailableOfferSchedule ?
              "TargetAudience,OfferTemplate,EnabledEvent,ApprovedGame"
              : "EnabledEvent",
          },
          offerDetail?.partnerId
        )
      );
      handleChange( isOfferScreen ? "offerName" : "tournamentName", offerDetail.offerName );
      handleChange( "description", offerDetail.description );
      handleChange( "qxPoint", offerDetail.qxPoint );
      handleChange( "descriptionText", offerDetail.descriptionText );
      handleChange( "rawDescription", offerDetail.rawDescription );
      handleChange( "achievements", offerDetail.achievements );
      handleChange( "optInType", offerDetail.optIn.optInType );
      handleChange( "membershipId", offerDetail.optIn.membershipId );
      handleChange( "entryFee", offerDetail.optIn.entryFee );
      handleChange( "minParticipants", offerDetail.optIn.minParticipants );
      handleChange( "maxParticipants", offerDetail.optIn.maxParticipants );
      handleChange( "minParticipantsNoLimit", offerDetail.optIn.minParticipantsNoLimit );
      handleChange( "maxParticipantsNoLimit", offerDetail.optIn.maxParticipantsNoLimit );

      if( offerDetail.isAvailableOfferSchedule && isPartnerOffer ) {
        changeDirtyObj( templateInitialDirty );
        handleChange( "game", offerDetail.offerSchedule.selectedGame );
        handleChange( "gamers", offerDetail.offerSchedule.selectedAudience );
        handleChange( "offerCanBeAchievedCount", offerDetail.offerSchedule.maxRewardCount );
        handleChange( "startDate", offerDetail.offerSchedule.startDateForValue );
        handleChange( "endDate", offerDetail.offerSchedule.endDateForValue );
        handleChange( "advertiseToGamer", offerDetail.advertiseToGamer );
        handleChange( "noOfDays", offerDetail.noOfDays );
        handleChange( "advertiseDate", offerDetail.advertiseDate );
      }
    }
  }, [ eventApiType, eventStatus, status, apiType, draftApiType, draftStatus ] );

  // membershipTypes already in sorted order by monthly price,
  // so we can use the first one as the default base membership.
  useEffect( () => {
    if( values.membershipId === "" ) {
      handleChange( "membershipId", membershipTypes[ 0 ].id );
    }
  }, [] );

  return (
    <>
      <div className='main-wrapper'>
        <div className='main-right-wrapper'>
          <div className='main-head-wrapper'>
            <Button variant='link-secondary' className="p-0" onClick={() => history.goBack()}>
              <ArrowBackIosIcon />
                Back
            </Button>
          </div>
          <div className='page-head-wrapper'>
            <h4 className='page-heading small-icon head-capital'>
              { isEditOffer ?
                `Edit ${ entityType } ${ offerDetail.approvalStatus === OFFER_DETAIL_STATUS.OFFER_TEMPLATE ? "Template " : offerText }`
                : `Add ${ entityType } Template`
              }
              <OverlayTrigger placement='right' overlay={<Tooltip>{headerDetail.description}</Tooltip>}>
                <HelpIcon />
              </OverlayTrigger>
            </h4>
            <div className='page-head-right'>
              <Button variant='outline-secondary btn-md mr-4' onClick={() => history.goBack()}>Cancel</Button>
              <Button variant='primary btn-md mr-4' onClick={() => handleSubmit( "" )}>
                {( isEditOffer && ( offerType !== OFFER_TYPE_STATUS.DRAFT ) ) ? "Update" : "Submit"}
              </Button>
              { ( offerType === OFFER_TYPE_STATUS.DRAFT || !isEditOffer ) && (
                <Button variant='primary btn-md' onClick={() => handleSubmit( "draft" )}>
                  {isEditOffer ? "Update as Draft" : "Save as Draft"}
                </Button>
              )}
            </div>
          </div>
          <div className='page-inner-box profile-steps-box'>
            <Form>
              {isPartnerOffer && isEditOffer && (
                <>
                  <h4 className='page-inner-heading'>1. Create an Offer</h4>
                  <Row>
                    <Col sm={6}>
                      <Form.Label>Cloned from template</Form.Label><br />
                      <span className='offer-link' onClick={() =>
                        history.push(
                          ROUTES.ADMIN_OFFER_DETAILS.replace( ":offerId", offerId )
                        )
                      }> {offerDetail.offerName}</span>
                    </Col>
                  </Row>
                </>
              )}
              <OfferTemplate
                isTemplate={!offerDetail?.isAvailableOfferSchedule || !isPartnerOffer}
                values={values}
                errors={errors}
                lookup={lookup}
                handleInputChange={handleInputChange}
                handleChange={handleChange}
                onSelectedEvent={onSelectedEvent}
                configuration={configuration}
                type={isOfferScreen ? "Offer" : "Tournament" }
                typeName='offerName'
                displayTimeLimit={ isOfferScreen ? true : false}
                membershipTypes={membershipTypes}
                addReward={addReward}
                onClickDeleteReward={handleDeleteReward}
                isTournament={!isOfferScreen}
              />
              {offerDetail?.isAvailableOfferSchedule && isPartnerOffer && (
                <ScheduleOffer
                  onChange={handleInputChange}
                  values={values}
                  games={lookup?.ApprovedGame || []}
                  gamers={lookup?.TargetAudience || []}
                  errors={errors}
                  onSelect={handleSelectInput}
                  startDateChange={e => handleDateChange( "startDate", e )}
                  endDateChange={e => handleDateChange( "endDate", e )}
                  advertiseDateChange={handleAdvertiseDate}
                />
              )}
            </Form>
          </div>
        </div>
      </div>
      <ModalPopup
        isOpened={isOpened}
        onClose={handleCloseModal}
        type={modalType}
        values={values}
        onChangeInput={handleInputChange}
        errorMessage={errorMessage}
        onConfirm={handleReject}
      />
    </>
  );
};


export default AddEditAdminOffer;
