import { Auth, Hub } from "aws-amplify";
import PropTypes from "prop-types";
import React, { useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { PartnerProfileDetail } from "../../../entities/partnerProfileDetail";
import { useModal } from "../../../hooks";
import { updateAdmin } from "../../../store/actions/adminUser";
import { clearReduxStoreAction } from "../../../store/actions/auth";
import { updatePartnerProfileAction } from "../../../store/actions/partner";
import constant from "../../../util/constant";
import { disableOnLoadEvent } from "../../../util/helpers";
import { logo, logoSmall, showMenu, hideMenu } from "../../../util/icons";
import AppVersion from "../../shared/appVersion/";
import Logout from "../logout";
import AdminNavbarInner from "./AdminNavbarInner";
import PartnerNavbar from "./partnerNavbar";
import ProfileNav from "./profileNav";

const { ROLE, ROUTES } = constant;

const Navbar = ( { toggleDrawerAction, toggleDrawer } ) => {
  const { isOpened, closeModal, openModal } = useModal();
  const [ isChecked, setIsChecked ] = useState( false );
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const role = localStorage.getItem( "role" );
  const profileNavAllowedPath = [
    ROUTES.PARTNER_GENERAL_INFO,
    ROUTES.PARTNER_CONTACT_INFO,
    ROUTES.PARTNER_OTHER_INFO,
    ROUTES.PARTNER_TERMS_CONDITIONS,
  ];
  const partnerNavNotAllowedPath = [
    ROUTES.PARTNER_GENERAL_INFO,
    ROUTES.PARTNER_CONTACT_INFO,
    ROUTES.PARTNER_OTHER_INFO,
    ROUTES.PARTNER_TERMS_CONDITIONS,
    ROUTES.PARTNER_DOCUSIGN,
    ROUTES.PARTNER_DOCUSIGN_PENDING,
    ROUTES.PARTNER_PROFILE_REJECTED,
    ROUTES.PARTNER_PROFILE_PENDING,
    ROUTES.PARTNER_PROFILE_DISABLED,
  ];

  const handleSignOut = async () => {
    try {
      if( isChecked ) {
        if( role === ROLE.ADMIN ) {
          dispatch( updateAdmin( { signOutWithoutConfirmation: isChecked } ) );
        }
        else {
          partnerDetail.signoutWithoutConfirmation = isChecked;

          const requestPayload = new PartnerProfileDetail( partnerDetail );

          dispatch( updatePartnerProfileAction( requestPayload ) );
        }
      }

      await Auth.signOut();
      localStorage.removeItem( "role" );
      localStorage.removeItem( "graphData" );
      dispatch( clearReduxStoreAction() );
      history.push( "/" );
      Hub.dispatch( "UI Auth", {
        event: "AuthStateChange",
        message: "signedout",
      } );
      closeModal();
      disableOnLoadEvent();
    }
    catch ( error ) {
      closeModal();
      // TODO: Consider better logging than just console
      // eslint-disable-next-line no-console
      console.log( "error signing out: ", error );
    }
  };

  const {
    partner: { partnerDetail },
    adminDetail: { data },
  } = useSelector( state => state );

  const closeLogOutModal = () => {
    closeModal();
    setIsChecked( false );
  };

  const handleSignOutCondition = () => {
    if( ( ( role === ROLE.ADMIN ) && data?.signoutWithoutConfirmation ) ||
    ( ( role === ROLE.PARTNER ) && partnerDetail?.signoutWithoutConfirmation ) ) {
      handleSignOut();
    }
    else {
      openModal();
    }
  };

  return (
    <>
      <div className='slider-toggle'>
        <div className="ring" onClick={ toggleDrawerAction }>
          <OverlayTrigger
            placement='right'
            overlay={
              <Tooltip className="event-tooltip"> { toggleDrawer ? "Collapse" : "Expand"}</Tooltip>
            }
          >
            <img src={ toggleDrawer ? hideMenu : showMenu} alt='Show/Hide Menu' className='menu-icons' />
          </OverlayTrigger>
        </div>
      </div>
      <div
        className={
          !partnerNavNotAllowedPath.includes( location.pathname )
            ? "left-nav-wrapper left-nav-inner-wrapper scrollbar"
            : "left-nav-wrapper scrollbar"
        }
      >
        <div className='logo-container'>
          <div className='logo-box'>
            <img src={logo} alt='logo' className='main-logo' />
            <img src={logoSmall} alt='logo' className='small-logo' />
          </div>
          {!partnerNavNotAllowedPath.includes( location.pathname ) &&
          role !== ROLE.ADMIN &&
          ( <div className="logo-box user-logo-box">
            <img src={ partnerDetail ? partnerDetail.logo : logo } alt="logo" />
          </div> )}
        </div>
        {profileNavAllowedPath.includes( location.pathname ) &&
          role !== ROLE.ADMIN && <ProfileNav toggleDrawer={toggleDrawer} />}
        {role === ROLE.ADMIN && <AdminNavbarInner toggleDrawer={toggleDrawer}/>}
        {!partnerNavNotAllowedPath.includes( location.pathname ) &&
          role !== ROLE.ADMIN && <PartnerNavbar toggleDrawer={toggleDrawer}/>}
        <div className='signout-btn-wrapper'>
          {!toggleDrawer
            ? <OverlayTrigger
              placement='right'
              overlay={
                <Tooltip className="event-tooltip nav-tooltip">Sign Out</Tooltip>
              }
            >
              <a href="#" className='signout-btn' onClick={handleSignOutCondition}>
                <span className='icon-signout'></span>
                <span className='signout-text'>Sign Out</span>
              </a>
            </OverlayTrigger>
            : <a href="#" className='signout-btn' onClick={handleSignOutCondition}>
              <span className='icon-signout'></span>
              <span className='signout-text'>Sign Out</span>
            </a>}
          <AppVersion serverVersion = { partnerDetail?.serverVersion ?? data?.serverVersion }/>
        </div>
        <Logout
          message={profileNavAllowedPath.includes( location.pathname )
            ? `Are you sure you want to Sign Out? Changes that you made may not be saved.
          To save your data, "Cancel" this popup, then click on the "next" button on
          the screen.`
            : ""}
          isOpened={isOpened}
          onCancel={closeLogOutModal}
          onConfirm={handleSignOut}
          setIsChecked={setIsChecked}
          isChecked={isChecked}
          isPartnerApplicationSubmitted={!profileNavAllowedPath.includes( location.pathname )}
        />
      </div>
    </>
  );
};

Navbar.propTypes = {
  toggleDrawerAction: PropTypes.func,
  toggleDrawer: PropTypes.bool,
};

export default Navbar;
