import { capitalize } from "lodash";
import { convertDate, cashbackName } from "../util/helpers";

export class GamerDetail {
  // TODO: refactor to avoid complexity
  // eslint-disable-next-line complexity
  constructor( obj ) {
    const gamesName = obj?.gameServerErrors?.map( game => game.name ) || [];

    if( gamesName.length > 1 ) {
      gamesName[ gamesName.length - 1 ] = `and ${ gamesName[ gamesName.length - 1 ] }`;
    }

    const defaultCashbackMethod = obj?.userCashbackMethods.find( o => o.isDefault === true && o.status === true ) || obj?.userCashbackMethods.find( o => o.status === true );

    this.activeCashbackMethod = defaultCashbackMethod;
    this.activeCashbackMethodList = obj?.userCashbackMethods.filter( o => o.status === true );
    this.assetsCount = obj?.assetsCount || 0;
    this.exchangeTransactionsCount = obj?.exchangeTransactionsCount || 0;
    this.linkedGamesCount = obj?.linkedGamesCount || 0;
    this.linkedOffersCount = obj?.linkedOffersCount || 0;
    this.redeemTransactionsCount = obj?.redeemTransactionsCount || 0;
    this.defaultCashbackName = defaultCashbackMethod ? cashbackName( defaultCashbackMethod ) : "";
    this.userCashbackMethods = this.activeCashbackMethodList?.map( ( data ) => {
      return {
        accountNickname: data.cashBackMethod.accountNickname,
        iconUrl: data.cashBackMethod.iconUrl,
        methodId: data.cashBackMethod.methodId,
        name: data.cashBackMethod.name,
        paymentVendorCustomerId: data.cashBackMethod.paymentVendorCustomerId,
        userPaymentMethodId: data.cashBackMethod.userPaymentMethodId,
        fundingDetailStatus: data.fundingDetailStatus,
        gamersNameForMethod: data.gamersNameForMethod,
        isDefault: data.isDefault,
        status: data.status,
        cashbackName: defaultCashbackMethod ? cashbackName( data ) : "",
      };
    } ) || [];
    this.gamerId = obj?.gamerInfo?.user_id || "";
    this.firstName = obj?.gamerInfo?.first_name || "";
    this.gamerFullName = `${ capitalize( obj?.gamerInfo?.first_name || "" ) } ${ capitalize( obj?.gamerInfo?.last_name || "" ) }`;
    this.acqyrCashBalance = obj?.gamerInfo?.acqyr_cash_balance || 0;
    this.profileImage = obj?.gamerInfo?.profile_image || "";
    this.phone = obj?.gamerInfo?.phone || "";
    this.email = obj?.gamerInfo?.email || "";
    this.userGames = obj?.gamerGames?.map( ( games ) => {
      return {
        dateLinked: games.dateLinked ? convertDate( games.dateLinked ) : "",
        gameId: games.gameId,
        name: games.name,
        iconUrl: games.iconUrl,
        assetsCount: games.assetsCount,
        offersCount: games.offersCount,
      };
    } ) || [];
    this.serverErrorsGameName = gamesName.join( ", " );
    this.serverErrorsGameNameList = obj?.gameServerErrors?.map( game => game.name ) || [];
    this.linkedTournamentsCount = obj?.linkedTournamentsCount || 0;
    this.plan = obj?.gamerInfo?.MembershipType?.name || "";
    this.transactionHistoryCount = obj?.userPaymentTransactions || 0;
    this.subscriptionRenewalDate = obj?.gamerInfo?.subscriptionRenewalDate ? convertDate( obj?.gamerInfo?.subscriptionRenewalDate ) : "";
  }
}
