const adminTransactionConstant = {
  TABS: {
    EXCHANGE: "Exchange",
    REDEEM: "Redeem",
  },
  ADMIN_TRANSACTION_API_TYPES: {
    GET_REDEEM_TRANSACTION: "GET_REDEEM_TRANSACTION",
    GET_REDEEM_TRANSACTION_DETAILS: "GET_REDEEM_TRANSACTION_DETAILS",
    REJECT_REDEEM_TRANSACTION: "REJECT_REDEEM_TRANSACTION",
    APPROVE_REDEEM_TRANSACTION: "APPROVE_REDEEM_TRANSACTION",
    GET_REDEEM_FILTER_LIST: "GET_REDEEM_FILTER_LIST",
    GET_EXCHANGE_TRANSACTION: "GET_EXCHANGE_TRANSACTION",
    GET_EXCHANGE_FILTER_LIST: "GET_EXCHANGE_FILTER_LIST",
    RESET_EXCHANGE_FILTER_LIST: "RESET_EXCHANGE_FILTER_LIST",
  },
  STATUS: {
    REQUEST_PENDING: "pending",
  },
};

export default adminTransactionConstant;
