import PropTypes from "prop-types";
import React from "react";
import Form from "react-bootstrap/Form";

import {
  useSetUrlParams,
  getBackNavigationRoute
} from "../../../../util/helpers";
import offerConstant from "../../../../util/offerConstant";
import FilterIcon from "../../../shared/FilterIcon/FilterIcon";
import BackButton from "../../../shared/backButton";
import ProfileImage from "../../../shared/profileImage";

const { OFFERS_TABS } = offerConstant;

const OffersHeader = ( {
  tab,
  addQuery,
  handleChange,
  gamerName,
  gamerProfileImage,
  location,
  showFilter,
  setShowFilter,
  values,
  setTab,
  isFilterApplied,
  route,
  isGamerTournaments,
} ) => {
  const backScreens = [ "adminGamerDetail" ];
  const showFilterScreen = () => setShowFilter( !showFilter );

  const handleSearch = ( e ) => {
    const { name, value } = e.target;

    handleChange( name, value );
    addQuery( useSetUrlParams( name, value ), location.state );
  };

  const handleBackNavigation = () => {
    return getBackNavigationRoute( route, location, backScreens );
  };

  return (
    <>
      <BackButton
        backLink={handleBackNavigation()}
        state={location.state} />
      <div className="page-head-wrapper">
        <div className="d-flex align-items-center">
          <ProfileImage profileImage={gamerProfileImage} />
          <div className="d-flex flex-column">
            <div className="profile-title">
              {gamerName}{" "}
            </div>
          </div>
        </div>
        <div className='page-head-right'>
          <Form.Group className="custom-search w-300 mr-0">
            <span className="icon-search"></span>
            <Form.Control
              type="text"
              data-testid='searchKey'
              onChange={handleSearch}
              value={values.searchKey}
              name='searchKey'
              title={values.placeholder}
              placeholder={values.placeholder}
            />
          </Form.Group>
          <FilterIcon
            showFilter={showFilter}
            showFilterScreen={showFilterScreen}
            isFilterApplied={isFilterApplied} />
        </div>
      </div>
      <div className="page-head-wrapper">
        <h4 className="page-heading">{isGamerTournaments ? "Tournaments" : "Offers"}</h4>
      </div>
      <div className='custom-tabs trans-tabs'>
        <span
          onClick={() => setTab( isGamerTournaments ? OFFERS_TABS.TOURNAMENTS : OFFERS_TABS.OFFERS )}
          className={( ( tab === OFFERS_TABS.OFFERS ) || ( tab === OFFERS_TABS.TOURNAMENTS ) )
            ? "active"
            : ""
          }>{isGamerTournaments ? "Tournaments" : "Offers"}
        </span>
        <span
          onClick={() => setTab( isGamerTournaments ? OFFERS_TABS.TOURNAMENT_REWARD_TXN : OFFERS_TABS.OFFER_REWARD_TXN )}
          className={( ( tab === OFFERS_TABS.OFFER_REWARD_TXN ) || ( tab === OFFERS_TABS.TOURNAMENT_REWARD_TXN ) )
            ? "active"
            : ""
          }>{isGamerTournaments ? "Tournament" : "Offer"} Reward TXN
        </span>
      </div>
    </>
  );
};

OffersHeader.propTypes = {
  addQuery: PropTypes.func,
  handleChange: PropTypes.func,
  gamerName: PropTypes.string,
  gamerProfileImage: PropTypes.string,
  location: PropTypes.object,
  showFilter: PropTypes.bool,
  isFilterApplied: PropTypes.bool,
  setShowFilter: PropTypes.func,
  tab: PropTypes.string,
  values: PropTypes.object,
  setTab: PropTypes.func,
  route: PropTypes.object,
  isGamerTournaments: PropTypes.bool,
};

export default OffersHeader;
