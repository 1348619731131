
import VisibilityIcon from "@material-ui/icons/Visibility";
import PropTypes from "prop-types";
import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { clearGamerDetailAction } from "../../../store/actions/adminGamer";
import constant from "../../../util/constant";
import { formatNumberAsPerLocale } from "../../../util/helpers";
import { ScrollPage } from "../../shared/customScrollBar";
import CustomSlider from "../../shared/customSlider";
import ViewAllGamesModal from "../../shared/games/viewAllGamesModal";
import MultiSelectCheckbox from "../../shared/multiSelectCheckbox";
import TableHeaderRow from "../../shared/tableHeadingRow";

const { ROUTES, BRANDING: { APP_SHORT_NAME } } = constant;

const GamerListHeader = ( {
  values,
  gamersFilterList,
  handleSelectChange,
  multiSelectedItems,
  handleClearMultiSelect,
  debouncedGameChangeHandler,
  handleChange,
  min,
  maxLinkedGames,
  debouncedCashChangeHandler,
  maxAcqyrCashBalance,
  memoizedTableConfig,
  memoizedHandleClick,
  gamers,
  onScroll,
  viewAllGames,
  closeModal,
  linkedGamer = false,
  minAcqyrCashBalance = 0,
  minLinkedGames = 0,
  showFilter,
  gamersPlan,
} ) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  return (
    <>
      <div className='filter-tabs-wrapper'>
        <div className='filter-boxes offer-filter mr-0'>
          <div className={`filter-boxes mr-0 ${ !showFilter ? "" : "remove-filter" }`}>
            <Row>
              <Col md={3}>
                <Form.Group className='custom-multiselect mb-0' data-testid="full-name">
                  <Form.Label>Name</Form.Label>
                  <MultiSelectCheckbox
                    multiOptionsList={gamersFilterList}
                    handleSelectChange={handleSelectChange[ 0 ]}
                    multiSelectedItems={multiSelectedItems[ 0 ]}
                    handleClearMultiSelect={() => handleClearMultiSelect( "fullNames" )} />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className='custom-multiselect mb-0'>
                  <CustomSlider
                    values={values}
                    pointsHandler={debouncedGameChangeHandler}
                    handleChange={handleChange}
                    min={linkedGamer ? minLinkedGames : min}
                    max={maxLinkedGames}
                    minName={"dummyMinLinkedGames"}
                    maxName={"dummyMaxLinkedGames"}
                    label={"No. of Linked Games"}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className='custom-multiselect mb-0'>
                  <CustomSlider
                    values={values}
                    pointsHandler={debouncedCashChangeHandler}
                    handleChange={handleChange}
                    min={linkedGamer ? minAcqyrCashBalance : min}
                    max={maxAcqyrCashBalance}
                    minName={"dummyMinAcqyrCashBalance"}
                    maxName={"dummyMaxAcqyrCashBalance"}
                    label={`${ APP_SHORT_NAME } Points`}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className='custom-multiselect mb-0' data-testid="full-name">
                  <Form.Label>Plan</Form.Label>
                  <MultiSelectCheckbox
                    multiOptionsList={gamersPlan}
                    handleSelectChange={handleSelectChange[ 1 ]}
                    multiSelectedItems={multiSelectedItems[ 1 ]}
                    handleClearMultiSelect={() => handleClearMultiSelect( "planNames" )} />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      {/* Table start from here */}
      <div className="table-container admin-gamers-table">
        <div className="table-head">
          <div className="table-row">
            <TableHeaderRow
              configData={memoizedTableConfig}
              onAscOrder={memoizedHandleClick}
              onDescOrder={memoizedHandleClick}
            />
          </div>
        </div>
        <Scrollbars renderThumbVertical={ScrollPage}
          className={`custom-scroll-height ${ !showFilter ? "" : "full-height" }`}
          onScroll={onScroll}
        >
          <div className="table-body no-scrollbar" >
            {/* repeat this div */}
            {Boolean( gamers?.length ) && gamers?.map( ( gamersData, index ) => (
              <div className="table-row" key={index}>
                <div className="td">
                  <span className="strong">{gamersData.fullNames}</span>
                </div>
                <div className="td">
                  {gamersData.linkedGamesCount}
                  {Boolean( gamersData.linkedGames.length ) && ( <div onClick={() => viewAllGames( gamersData.linkedGames )}
                    className="link cursor-pointer" title="View All">
                    View All
                  </div> )}
                </div>
                <div className="td">{formatNumberAsPerLocale( gamersData.acqyrCashBalance )}</div>
                <div className="td">{gamersData.gamerPlan || "N/A"}</div>
                <div className="td">
                  <span className='icon cursor-pointer' onClick={() => {
                    dispatch( clearGamerDetailAction() );
                    history.push( {
                      pathname: `${ ROUTES.GAMER_SECTION.replace(
                        ":gamerId",
                        gamersData.userId
                      ) }?from=gamers`,
                      state: [
                        { from: "gamers", path: location.pathname, state: location.search },
                      ],
                    } );
                  }}>
                    <VisibilityIcon />
                  </span>
                </div>
              </div>
            ) )}
            {!gamers?.length && (
              <div className='no-record' data-testid='no-record-found'>
                No Gamer Found
              </div>
            )}
          </div>
        </Scrollbars>
      </div>
      {/* end table */}
      <ViewAllGamesModal
        values={values}
        closeModal={closeModal}
      />
    </>
  );
};

GamerListHeader.propTypes = {
  values: PropTypes.object,
  gamersFilterList: PropTypes.array,
  handleSelectChange: PropTypes.func,
  multiSelectedItems: PropTypes.func,
  handleClearMultiSelect: PropTypes.func,
  debouncedGameChangeHandler: PropTypes.func,
  handleChange: PropTypes.func,
  min: PropTypes.number,
  maxLinkedGames: PropTypes.number,
  debouncedCashChangeHandler: PropTypes.func,
  maxAcqyrCashBalance: PropTypes.number,
  memoizedTableConfig: PropTypes.object,
  memoizedHandleClick: PropTypes.func,
  gamers: PropTypes.array,
  onScroll: PropTypes.func,
  viewAllGames: PropTypes.func,
  closeModal: PropTypes.func,
  linkedGamer: PropTypes.bool,
  minAcqyrCashBalance: PropTypes.number,
  minLinkedGames: PropTypes.number,
  id: PropTypes.string,
  action: PropTypes.string,
  showFilter: PropTypes.string,
  gamersPlan: PropTypes.array,
};

export default GamerListHeader;
