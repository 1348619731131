import { API_URL } from "../util/apiPaths";
import { request } from "./api.js";

export const getAdminGamerList = ( param ) => {
  return request( "GET", API_URL.ADMIN_GAMERS, param );
};

export const getAdminGamerDetail = ( param ) => {
  return request( "GET", `${ API_URL.ADMIN_GAMERS }/${ param }` );
};

export const getGamerAllGames = ( gamerId, param ) => {
  return request( "GET", `${ API_URL.ADMIN_GAMERS }/${ gamerId }/games`, param );
};

export const getAdminGamerAsset = ( param ) => {
  return request( "GET", `${ API_URL.ADMIN_GAMERS_ASSETS }`.replace( ":gamerId", param.gamerId ), { gameId: param.gameId } );
};

export const getAdminGamerOffer = ( param ) => {
  return request( "GET", `${ API_URL.ADMIN_GAMERS_OFFERS }`.replace( ":gamerId", param.gamerId ), { gameId: param.gameId } );
};

export const getGamerLinkedOffer = ( gamerId, param ) => {
  return request( "GET", `${ API_URL.ADMIN_GAMERS }/${ gamerId }/offers`, param );
};

export const getExchangeTransaction = ( gamerId, param ) => {
  return request( "GET", `${ API_URL.ADMIN_GAMERS }/${ gamerId }/exchange/tx`, param );
};

export const getGamerRedeemTransaction = ( gamerId, param ) => {
  return request( "GET", `${ API_URL.ADMIN_GAMERS }/${ gamerId }/redeem/tx`, param );
};

export const getGamerRedeemTxFilter = ( gamerId ) => {
  return request( "GET", API_URL.ADMIN_REDEEM_FILTER_LIST, gamerId );
};

export const getGamerAssets = ( gamerId, param ) => {
  return request( "GET", `${ API_URL.ADMIN_GAMERS }/${ gamerId }/exchange/assets`, param );
};

export const getGamerOfferFilter = ( gamerId, param ) => {
  return request( "GET", `${ API_URL.ADMIN_GAMERS }/${ gamerId }/offers/filterLists`, param );
};

export const getGamerTxnHistory = ( gamerId ) => {
  return request( "GET", `${ API_URL.ADMIN_GAMERS }/${ gamerId }/paymentHistory` );
};

export const getGamerRewardTxn = ( gamerId, param ) => {
  return request( "GET", `${ API_URL.ADMIN_GAMERS }/${ gamerId }/tx`, param );
};
