import PropTypes from "prop-types";
import React from "react";

import constant from "../../../util/constant";
import ToolTipError from "../../admin/gamer/toolTipError";

const { BRANDING: { APP_SHORT_NAME } } = constant;

const AssetList = ( {
  assets,
  totalQxPoints,
} ) => {
  return (
    <>
      {Boolean( assets?.length ) && ( <>
        <div className='gamer-list-container pt-0'>
          <div className='text-bold'>Assets</div>
          <div className='text-bold'>{ APP_SHORT_NAME } Points</div>
        </div>
        { assets.map( ( data, index ) => (
          <div className='gamer-list-container' key={index}>
            <div className='text-points'>
              {!data.gameServerError && (
                <span>
                  {data.quantity} {data.gameName} ({data.unitPriceQxPoints} { APP_SHORT_NAME } Points each)
                </span>
              )}
              {data.gameServerError && (
                <span className="big-text-common ml-3">{"--"}</span>
              )}
              {data.gameServerError && (
                <ToolTipError message='Error in fetching assets for this game.'/>
              )}
            </div>
            <div className='text-points text-green'>{data.qxPoints}</div>
          </div>
        ) )}
        <div className='gamer-list-container'>
          <div className='text-total'>Total</div>
          <div className='text-total text-green'>{totalQxPoints}</div>
        </div>
      </> )}
      {!assets?.length && (
        <div className='no-record' data-testid='no-record-found'>
                  No Asset Found
        </div>
      )}
    </>
  );
};

AssetList.propTypes = {
  assets: PropTypes.array,
  totalQxPoints: PropTypes.number,
};

export default AssetList;
