import { orderBy } from "lodash";
import moment from "moment";
import { toastr } from "react-redux-toastr";
import { PartnerGameDetails } from "../../entities/partnerGameDetails";
import {
  getPartnerGameList,
  addGame,
  getPartnerAllGameList,
  getGameDetails,
  updateGame,
  addPartnerComment
} from "../../services/partnerGameServices";
import { uploadLogo } from "../../services/profileServices";
import constant from "../../util/constant";
import { getGraphData } from "../../util/graphHelper";
import partnerGameConstant from "../../util/partnerGameConstant";
import {
  START_LOADER,
  STOP_LOADER,
  GET_PARTNER_GAME_LIST_SUCCESS,
  GET_PARTNER_GAME_LIST_FAILURE,
  PARTNER_GAME_API_END,
  PARTNER_GAME_API_REQUEST,
  UPLOAD_GAME_ICON_SUCCESS,
  UPLOAD_GAME_ICON_FAILURE,
  ADD_GAME_SUCCESS,
  ADD_GAME_FAILURE,
  PARTNER_ALL_GAME_LIST_SUCCESS,
  PARTNER_ALL_GAME_LIST_FAILURE,
  GET_GAME_DETAIL_SUCCESS,
  GET_GAME_DETAIL_FAILURE,
  UPDATE_GAME_SUCCESS,
  UPDATE_GAME_FAILURE,
  ADD_PARTNER_COMMENT_SUCCESS,
  ADD_PARTNER_COMMENT_FAILURE,
  GET_PARTNER_ANALYTICS_GAME_LIST_SUCCESS
} from "../actionTypes";

const { ERROR_MESSAGES, INTERVAL_TYPE, GRAPH_TYPE } = constant;

const { PARTNER_GAME_API_TYPES } = partnerGameConstant;

const requestPartnerGameList = async ( params ) => {
  const response = await getPartnerGameList( params );

  response?.data?.partner_data?.forEach( ( val ) => {
    if( !val.submitted_date ) {
      // eslint-disable-next-line camelcase
      val.submitted_date = "";
    }

    if( !val.approved_date ) {
      // eslint-disable-next-line camelcase
      val.approved_date = "";
    }

    if( !val.updatedAt ) {
      val.updatedAt = "";
    }
  } );

  return response?.data?.partner_data;
};

export const getPartnersGameListAction = ( param, areAnalyticGames = false ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_GAME_API_REQUEST,
      payload: PARTNER_GAME_API_TYPES.PARTNER_GAME_LIST_REQUEST,
    } );

    const partnerAnalyticsGames = await requestPartnerGameList( param );

    if( areAnalyticGames ) {
      partnerAnalyticsGames.forEach( ( games ) => {
        if( Object.prototype.hasOwnProperty.call( games, "gameStatistics" ) ) {
          Object.assign( games, games.gameStatistics );
          delete games.gameStatistics;
        }
      } );
    }

    dispatch( {
      type: GET_PARTNER_GAME_LIST_SUCCESS,
      payload: {
        gamesList: partnerAnalyticsGames,
      },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GET_PARTNER_GAME_LIST_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: PARTNER_GAME_API_END,
      payload: PARTNER_GAME_API_TYPES.PARTNER_GAME_LIST_REQUEST,
    } );
  }
};

export const uploadGameIconAction = data => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_GAME_API_REQUEST,
      payload: PARTNER_GAME_API_TYPES.UPLOAD_GAME_ICON,
    } );

    const response = await uploadLogo( data );

    dispatch( { type: STOP_LOADER } );
    dispatch( { type: UPLOAD_GAME_ICON_SUCCESS, payload: response } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( { type: UPLOAD_GAME_ICON_FAILURE, payload: error } );
  }
  finally {
    dispatch( { type: PARTNER_GAME_API_END } );
  }
};

export const addGameAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_GAME_API_REQUEST,
      payload: PARTNER_GAME_API_TYPES.ADD_GAME,
    } );

    const response = await addGame( param );

    dispatch( { type: ADD_GAME_SUCCESS, payload: response.data } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    const err = Object.values( error?.data?.error || {} ) || "";

    dispatch( { type: STOP_LOADER } );
    dispatch( { type: ADD_GAME_FAILURE, payload: error } );

    if( err[ 0 ] ) {
      toastr.error( err[ 0 ] );
    }
    else {
      toastr.error( error.data.message );
    }
  }
  finally {
    dispatch( {
      type: PARTNER_GAME_API_END,
    } );
  }
};

export const getPartnersAllGameListAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_GAME_API_REQUEST,
      payload: PARTNER_GAME_API_TYPES.PARTNER_ALL_GAME_LIST_REQUEST,
    } );

    const response = await getPartnerAllGameList( param );

    if( response && response.data.length > 0 ) {
      const data = response.data.map( s => ( { label: s.name, value: s.game_id } ) );

      dispatch( { type: PARTNER_ALL_GAME_LIST_SUCCESS, payload: data } );
    }

    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: PARTNER_ALL_GAME_LIST_FAILURE, payload: error } );
    toastr.error( error );
  }
  finally {
    dispatch( {
      type: PARTNER_GAME_API_END,
      payload: PARTNER_GAME_API_TYPES.PARTNER_ALL_GAME_LIST_REQUEST,
    } );
  }
};

export const getGameDetailsAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_GAME_API_REQUEST,
      payload: PARTNER_GAME_API_TYPES.GET_GAME_DETAIL,
    } );

    const response = await getGameDetails( param );
    const gameDetail = new PartnerGameDetails( response.data );

    dispatch( { type: GET_GAME_DETAIL_SUCCESS, payload: gameDetail } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( { type: GET_GAME_DETAIL_FAILURE, payload: error } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: PARTNER_GAME_API_END,
    } );
  }
};

export const updateGameAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_GAME_API_REQUEST,
      payload: PARTNER_GAME_API_TYPES.UPDATE_GAME,
    } );

    const response = await updateGame( param );
    const gameDetail = new PartnerGameDetails( response.data );

    dispatch( { type: UPDATE_GAME_SUCCESS, payload: gameDetail } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    const err = Object.values( error?.data?.error || {} ) || "";

    dispatch( { type: STOP_LOADER } );
    dispatch( { type: UPDATE_GAME_FAILURE, payload: error } );

    if( err[ 0 ] ) {
      toastr.error( err[ 0 ] );
    }
    else {
      toastr.error( error.data.message );
    }
  }
  finally {
    dispatch( {
      type: PARTNER_GAME_API_END,
    } );
  }
};

export const addPartnerCommentAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_GAME_API_REQUEST,
      payload: PARTNER_GAME_API_TYPES.ADD_PARTNER_COMMENT,
    } );

    const response = await addPartnerComment( param );

    dispatch( { type: ADD_PARTNER_COMMENT_SUCCESS, payload: response.data } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    const err = Object.values( error?.data?.error || {} ) || "";

    dispatch( { type: STOP_LOADER } );
    dispatch( { type: ADD_PARTNER_COMMENT_FAILURE, payload: error } );

    if( err[ 0 ] ) {
      toastr.error( err[ 0 ] );
    }
    else {
      toastr.error( error.data.message );
    }
  }
  finally {
    dispatch( {
      type: PARTNER_GAME_API_END,
    } );
  }
};


export const getPartnerTotalGamerAction = ( data = {} ) => async ( dispatch, getState ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_GAME_API_REQUEST,
      payload: PARTNER_GAME_API_TYPES.PARTNER_GAME_LIST_REQUEST,
    } );

    let { analyticsGraphData } = await getState().partnerGame;
    let initialDate;
    let partnerAnalyticsGames = [];

    if( analyticsGraphData && data?.graphType ) {
      partnerAnalyticsGames = await getState().partnerGame?.partnerAnalyticsGames;
    }
    else {
      partnerAnalyticsGames = await requestPartnerGameList( { games: "approved", fetchAllRecords: true } );
    }

    let totalGames = partnerAnalyticsGames.length;

    if( data?.graphType === GRAPH_TYPE.GAMES_LINE_GRAPH ) {
      totalGames = partnerAnalyticsGames.length;
      partnerAnalyticsGames = orderBy( partnerAnalyticsGames, [ a => moment( a.approved_date ) ], [ "asc" ] );
      initialDate = partnerAnalyticsGames.length ? partnerAnalyticsGames[ 0 ].approved_date : "";

      const { filterType, startDate, endDate } = data;
      const graphData = [
        { graph: GRAPH_TYPE.GAMES_LINE_GRAPH,
          filterValue: filterType,
          range: { startDate, endDate }, initialDate },
      ];

      if( filterType === INTERVAL_TYPE.ALL ) {
        analyticsGraphData = getGraphData( partnerAnalyticsGames,
          "approved_date",
          moment( partnerAnalyticsGames.length ? partnerAnalyticsGames[ 0 ].approved_date : "" ).endOf( "year" ),
          moment().endOf( "year" ),
          graphData,
          "years", 1, "YYYY" );
      }
      else if( filterType === INTERVAL_TYPE.YEARLY ) {
        analyticsGraphData = getGraphData( partnerAnalyticsGames, "approved_date", startDate, endDate, graphData, "months", 1, "MMM" );
      }
      else if( filterType === INTERVAL_TYPE.MONTHLY ) {
        analyticsGraphData = getGraphData( partnerAnalyticsGames, "approved_date", startDate, endDate, graphData, "days", 1, "D" );
      }
      else if( filterType === INTERVAL_TYPE.WEEKLY ) {
        analyticsGraphData = getGraphData( partnerAnalyticsGames, "approved_date", startDate, endDate, graphData, "days", 1, "DDD" );
      }
    }

    dispatch( {
      type: GET_PARTNER_ANALYTICS_GAME_LIST_SUCCESS,
      payload: {
        gamesList: partnerAnalyticsGames,
        totalGames,
        initialDate,
        analyticsGraphData,
      },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GET_PARTNER_GAME_LIST_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: PARTNER_GAME_API_END,
      payload: PARTNER_GAME_API_TYPES.PARTNER_GAME_LIST_REQUEST,
    } );
  }
};
