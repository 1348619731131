import { orderBy, uniqBy } from "lodash";
import moment from "moment";
import { toastr } from "react-redux-toastr";

import { AnalyticsAssetsList } from "../../entities/analyticsAssetsList";
import { AnalyticsGamerList } from "../../entities/analyticsGamerList";
import { GameDetail } from "../../entities/gameDetail";
import { PartnerGamerDetails } from "../../entities/partnerGamerList";
import { PartnerOptInTxnList } from "../../entities/partnerOptInTxnList";
import { PartnerTxnList } from "../../entities/partnerTxnList";
import {
  getExchangeTransaction,
  getGameDetails,
  getGamerLists,
  getAssetsList
} from "../../services/analyticsServices";
import analyticsConstant from "../../util/analyticsConstant";
import constant from "../../util/constant";
import { getGraphData } from "../../util/graphHelper";
import { prepareLabelValueFormatData, extremum } from "../../util/helpers";
import {
  START_LOADER,
  STOP_LOADER,
  EXCHANGE_TRANSACTION_API_REQUEST,
  EXCHANGE_TRANSACTION_FAILURE,
  EXCHANGE_TRANSACTION_SUCCESS,
  ANALYTICS_API_END,
  PARTNER_GAME_DETAIL_REQUEST,
  GET_GAME_DETAILS_SUCCESS,
  GET_GAME_DETAILS_FAILURE,
  PARTNER_ANALYTICS_GAMER_LIST_REQUEST,
  PARTNER_ANALYTICS_GAMER_LIST_SUCCESS,
  PARTNER_ANALYTICS_GAMER_LIST_FAILURE,
  PARTNER_ANALYTICS_ASSETS_LIST_REQUEST,
  PARTNER_ANALYTICS_ASSETS_LIST_SUCCESS,
  PARTNER_ANALYTICS_ASSETS_LIST_FAILURE,
  CLEAR_EXCHANGE_TRANSACTION_GRAPH_DATA,
  CLEAR_QX_MEMBERS_GRAPH_DATA,
  CLEAR_GAMES_GRAPH_DATA,
  CLEAR_ANALYTICS_GAME_DETAIL,
  TRANSACTION_LIST_SUCCESS,
  TRANSACTION_LIST_FAILURE,
  ANALYTICS_LINKED_GAMER_LIST_REQUEST,
  ANALYTICS_LINKED_GAMER_LIST_SUCCESS,
  ANALYTICS_LINKED_GAMER_LIST_FAILURE
} from "../actionTypes";

const { API_TYPES } = analyticsConstant;
const { ERROR_MESSAGES, MIN_MAX, INTERVAL_TYPE, GRAPH_TYPE, BUSINESS_ENTITY } = constant;

export const getExchangeTransactionAction = ( data = {} ) => async ( dispatch, getState ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: EXCHANGE_TRANSACTION_API_REQUEST,
      payload: API_TYPES.GET_EXCHANGE_TRANSACTION,
    } );

    let { analyticsGraphData } = await getState().partnerAnalytics.exchangeTransaction;
    let transactionList;

    if( analyticsGraphData && data?.graphType ) {
      transactionList = await getState().partnerAnalytics.exchangeTransaction.transaction;
    }
    else {
      const response = await getExchangeTransaction( data );

      transactionList = response.data.transactionList.map(
        item => new PartnerTxnList( item )
      );
    }

    let totalExchangeTxnAmount;
    let initialDate;

    // Filter List
    const gameNames = prepareLabelValueFormatData( transactionList, "name" );
    const assetNames = prepareLabelValueFormatData( transactionList, "assetName" );
    const gamerNames = prepareLabelValueFormatData( transactionList, "gamerName" );
    const offerNames = prepareLabelValueFormatData( transactionList, "offerName" );
    let qxPointMinMax = { min: 0, max: 0 };

    if( transactionList.length ) {
      const minQxPoint = extremum( transactionList, "QXPoints", MIN_MAX.MIN );
      const maxQxPoint = extremum( transactionList, "QXPoints", MIN_MAX.MAX );

      qxPointMinMax = { min: minQxPoint.QXPoints, max: maxQxPoint.QXPoints };
    }

    let gamerPlaceMinMax = { min: 0, max: 0 };

    if( transactionList.length ) {
      const minGamerPlace = extremum( transactionList, "myPlace", MIN_MAX.MIN );
      const maxGamerPlace = extremum( transactionList, "myPlace", MIN_MAX.MAX );

      gamerPlaceMinMax = { min: minGamerPlace.myPlace, max: maxGamerPlace.myPlace };
    }

    if( data?.graphType === GRAPH_TYPE.EXCHANGE_TXN_LINE_GRAPH ) {
      transactionList = orderBy( transactionList, [ a => moment( a.date ) ], [ "asc" ] );
      transactionList = transactionList.map( txn => ( { ...txn, amountInUSD: txn.QXPoints * data.partnerCostUsdPerQxPoint } ) );
      initialDate = transactionList.length ? transactionList[ 0 ].date : "";
      totalExchangeTxnAmount = transactionList.reduce( ( count, currentItem ) => {
        // TODO: refactor to not reassign count
        // eslint-disable-next-line no-param-reassign
        return count += ( currentItem.QXPoints * data.partnerCostUsdPerQxPoint );
      }, 0 );

      const { filterType, startDate, endDate } = data;
      const graphData = [
        { graph: GRAPH_TYPE.EXCHANGE_TXN_LINE_GRAPH,
          filterValue: filterType,
          range: { startDate, endDate }, initialDate },
      ];

      if( filterType === INTERVAL_TYPE.ALL ) {
        analyticsGraphData = getGraphData( transactionList,
          "date",
          moment( transactionList.length ? transactionList[ 0 ].date : "" ).endOf( "year" ),
          moment().endOf( "year" ),
          graphData,
          "years", 1, "YYYY" );
      }
      else if( filterType === INTERVAL_TYPE.YEARLY ) {
        analyticsGraphData = getGraphData( transactionList, "date", startDate, endDate, graphData, "months", 1, "MMM" );
      }
      else if( filterType === INTERVAL_TYPE.MONTHLY ) {
        analyticsGraphData = getGraphData( transactionList, "date", startDate, endDate, graphData, "days", 1, "D" );
      }
      else if( filterType === INTERVAL_TYPE.WEEKLY ) {
        analyticsGraphData = getGraphData( transactionList, "date", startDate, endDate, graphData, "days", 1, "DDD" );
      }
    }

    dispatch( {
      type: EXCHANGE_TRANSACTION_SUCCESS,
      payload: {
        transactionList: transactionList,
        gameNames: gameNames,
        assetNames: assetNames,
        gamerNames: gamerNames,
        offerNames: offerNames,
        qxPointMinMax: qxPointMinMax,
        gamerPlaceMinMax: gamerPlaceMinMax,
        totalExchangeTxnAmount: parseInt( totalExchangeTxnAmount, 10 ),
        initialDate,
        analyticsGraphData,
      },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: EXCHANGE_TRANSACTION_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ANALYTICS_API_END,
    } );
  }
};

export const getPartnerGameDetailsAction = ( param, getOffers, entityType = BUSINESS_ENTITY.OFFER ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_GAME_DETAIL_REQUEST,
      payload: API_TYPES.GET_GAME_DETAILS,
    } );

    const response = await getGameDetails( param, getOffers, entityType );
    const newResponse = new GameDetail( { ...response.data, entityType } );

    dispatch( {
      type: GET_GAME_DETAILS_SUCCESS,
      payload: newResponse,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GET_GAME_DETAILS_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ANALYTICS_API_END,
    } );
  }
};

export const getGamerListsAction = data => async ( dispatch, getState ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_ANALYTICS_GAMER_LIST_REQUEST,
      payload: API_TYPES.GET_GAMER_LISTS,
    } );

    let { analyticsGraphData } = await getState().partnerAnalytics;
    let initialDate;
    let AnalyticsGamerLists;
    const membershipTypes = await getState().partner.partnerDetail?.configuration?.membershipTypes;

    if( analyticsGraphData && data?.graphType ) {
      AnalyticsGamerLists = await getState().partnerAnalytics.gamerLists;
    }
    else {
      const response = await getGamerLists( data );

      AnalyticsGamerLists = response.data.map(
        item => new AnalyticsGamerList( item, membershipTypes )
      )
        .sort( ( a, b ) => a.fullName.localeCompare( b.fullName ) );
    }

    let totalGamers = AnalyticsGamerLists.length;
    const gameName = prepareLabelValueFormatData( AnalyticsGamerLists, "gameName" );
    const gamerNames = prepareLabelValueFormatData( AnalyticsGamerLists, "fullName" );
    const gamerPlans = prepareLabelValueFormatData( membershipTypes, "name" );

    if( data?.graphType === GRAPH_TYPE.QX_MEMBERS_LINE_GRAPH ) {
      AnalyticsGamerLists = orderBy( AnalyticsGamerLists, [ a => moment( a.linkedOn ) ], [ "asc" ] );
      AnalyticsGamerLists = uniqBy( AnalyticsGamerLists, "userId" ); // unique qx members based on userId
      totalGamers = AnalyticsGamerLists.length;
      initialDate = AnalyticsGamerLists.length ? AnalyticsGamerLists[ 0 ].linkedOn : "";

      const { filterType, startDate, endDate } = data;
      const graphData = [
        { graph: GRAPH_TYPE.QX_MEMBERS_LINE_GRAPH,
          filterValue: filterType,
          range: { startDate, endDate }, initialDate },
      ];

      if( filterType === INTERVAL_TYPE.ALL ) {
        analyticsGraphData = getGraphData( AnalyticsGamerLists,
          "linkedOn",
          moment( AnalyticsGamerLists.length ? AnalyticsGamerLists[ 0 ].linkedOn : "" ).endOf( "year" ),
          moment().endOf( "year" ),
          graphData,
          "years", 1, "YYYY" );
      }
      else if( filterType === INTERVAL_TYPE.YEARLY ) {
        analyticsGraphData = getGraphData( AnalyticsGamerLists, "linkedOn", startDate, endDate, graphData, "months", 1, "MMM" );
      }
      else if( filterType === INTERVAL_TYPE.MONTHLY ) {
        analyticsGraphData = getGraphData( AnalyticsGamerLists, "linkedOn", startDate, endDate, graphData, "days", 1, "D" );
      }
      else if( filterType === INTERVAL_TYPE.WEEKLY ) {
        analyticsGraphData = getGraphData( AnalyticsGamerLists, "linkedOn", startDate, endDate, graphData, "days", 1, "DDD" );
      }
    }

    dispatch( {
      type: PARTNER_ANALYTICS_GAMER_LIST_SUCCESS,
      payload: {
        gamerList: AnalyticsGamerLists,
        gameName: gameName,
        gamerNames: gamerNames,
        gamerPlans: gamerPlans,
        totalGamers,
        initialDate,
        analyticsGraphData,
      },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: PARTNER_ANALYTICS_GAMER_LIST_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ANALYTICS_API_END,
    } );
  }
};

export const getAssetsListAction = ( data, dataIds ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_ANALYTICS_ASSETS_LIST_REQUEST,
      payload: API_TYPES.GET_ASSETS_LISTS,
    } );

    const response = await getAssetsList( data, dataIds );
    const analyticsAssetsLists = new AnalyticsAssetsList( response.data );
    const assetIdList = prepareLabelValueFormatData( analyticsAssetsLists.assets, "assetId" );
    const assetNameList = prepareLabelValueFormatData( analyticsAssetsLists.assets, "name" );
    let quantityMinMax = { min: 0, max: 0 };

    if( analyticsAssetsLists.assets.length ) {
      const minQuantity = extremum( analyticsAssetsLists.assets, "quantity", MIN_MAX.MIN );
      const maxQuantity = extremum( analyticsAssetsLists.assets, "quantity", MIN_MAX.MAX );

      quantityMinMax = { min: minQuantity.quantity, max: maxQuantity.quantity };
    }

    let priceMinMax = { min: 0, max: 0 };

    if( analyticsAssetsLists.assets.length ) {
      const minQuantity = extremum( analyticsAssetsLists.assets, "unitPriceQxPoints", MIN_MAX.MIN );
      const maxQuantity = extremum( analyticsAssetsLists.assets, "unitPriceQxPoints", MIN_MAX.MAX );

      priceMinMax = { min: minQuantity.unitPriceQxPoints, max: maxQuantity.unitPriceQxPoints };
    }

    dispatch( {
      type: PARTNER_ANALYTICS_ASSETS_LIST_SUCCESS,
      payload: {
        assetsList: { analyticsAssetsLists, assetIdList, assetNameList, quantityMinMax, priceMinMax },
      },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: PARTNER_ANALYTICS_ASSETS_LIST_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ANALYTICS_API_END,
    } );
  }
};

export const getTotalExchangeTxnAmountAction = ( data = {} ) => async ( dispatch, getState ) => {
  try {
    data.partnerCostUsdPerQxPoint = getState().partner.partnerDetail.configuration.partnerCostUsdPerQxPoint;
    dispatch( getExchangeTransactionAction(
      data
    ) );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: EXCHANGE_TRANSACTION_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ANALYTICS_API_END,
    } );
  }
};

export const clearSummaryDashboardGraphState = () => ( dispatch ) => {
  dispatch( { type: CLEAR_QX_MEMBERS_GRAPH_DATA } );
  dispatch( { type: CLEAR_GAMES_GRAPH_DATA } );
  dispatch( { type: CLEAR_EXCHANGE_TRANSACTION_GRAPH_DATA } );
};

export const clearAnalyticsGameDetail = () => ( dispatch ) => {
  dispatch( { type: CLEAR_ANALYTICS_GAME_DETAIL } );
};

export const getOptInTransactionAction = ( data = {} ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: EXCHANGE_TRANSACTION_API_REQUEST,
      payload: API_TYPES.GET_EXCHANGE_TRANSACTION,
    } );

    const response = await getExchangeTransaction( data );
    const transactionList = response.data.transactionList.map(
      item => new PartnerOptInTxnList( item )
    );
    // Filter List
    const gameNames = prepareLabelValueFormatData( transactionList, "gameName" );
    const gamerNames = prepareLabelValueFormatData( transactionList, "gamerName" );
    const offerNames = prepareLabelValueFormatData( transactionList, "offerName" );
    const transactionFor = prepareLabelValueFormatData( transactionList, "transactionFor" );

    let qxPointMinMax = { min: 0, max: 0 };

    if( transactionList.length ) {
      const minQxPoint = extremum( transactionList, "QXPoints", MIN_MAX.MIN );
      const maxQxPoint = extremum( transactionList, "QXPoints", MIN_MAX.MAX );

      qxPointMinMax = { min: minQxPoint.QXPoints, max: maxQxPoint.QXPoints };
    }

    dispatch( {
      type: TRANSACTION_LIST_SUCCESS,
      payload: {
        transactionList: transactionList,
        gameNames: gameNames,
        gamerNames: gamerNames,
        offerNames: offerNames,
        qxPointMinMax: qxPointMinMax,
        transactionFor: transactionFor,
      },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: TRANSACTION_LIST_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ANALYTICS_API_END,
    } );
  }
};

export const getAnalyticsLinkedGamersAction = data => async ( dispatch, getState ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ANALYTICS_LINKED_GAMER_LIST_REQUEST,
      payload: API_TYPES.GET_ANALYTICS_LINKED_GAMERS,
    } );

    const membershipTypes = await getState().partner.partnerDetail?.configuration?.membershipTypes;
    const response = await getGamerLists( data );
    const analyticsLinkedGamers = new PartnerGamerDetails( response.data, membershipTypes );
    const linkedGamersList = analyticsLinkedGamers?.partnerGamerList;

    // Filters
    let linkedGamerGamesMinMax = { min: 0, max: 0 };

    if( linkedGamersList?.length ) {
      const minLinkedGames = extremum( linkedGamersList, "linkedGamesCount", MIN_MAX.MIN );
      const maxLinkedGames = extremum( linkedGamersList, "linkedGamesCount", MIN_MAX.MAX );

      linkedGamerGamesMinMax = { min: minLinkedGames.linkedGamesCount, max: maxLinkedGames.linkedGamesCount };
    }

    const gamerNames = prepareLabelValueFormatData( linkedGamersList, "fullName" );
    const gamerPlans = prepareLabelValueFormatData( membershipTypes, "name" );

    dispatch( {
      type: ANALYTICS_LINKED_GAMER_LIST_SUCCESS,
      payload: {
        linkedGamersList,
        linkedGamerGamesMinMax,
        gamerPlans: gamerPlans,
        gamerNames: gamerNames,
      },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: ANALYTICS_LINKED_GAMER_LIST_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ANALYTICS_API_END,
    } );
  }
};
