import { convertDate } from "../util/helpers";

export class PartnerTxnList {
  constructor( obj ) {
    this.gamerName = obj.gamerName || "N/A";
    this.name = obj.name || "N/A";
    this.gameId = obj.gameId;
    this.iconUrl = obj?.iconUrl || "";
    this.QXPoints = obj?.qxPoints;
    this.soldAssetQuantity = obj.soldAssetQuantity;
    this.assetName = obj.assetName || "N/A";
    this.unitPriceQxPoints = obj.unitPriceQxPoints;
    this.transactionDate = convertDate( obj.transactionDate ) || "";
    this.date = obj.transactionDate;
    this.offerName = obj?.offerName;
    this.myPlace = obj?.remarks ? parseInt( obj?.remarks, 10 ) : 0;
  }
}
