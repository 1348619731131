/* eslint max-lines: [ 'warn', { 'max': 660, 'skipBlankLines': true, 'skipComments': true } ] */
import { maxBy, uniqBy } from "lodash";
import { toastr } from "react-redux-toastr";
import { AdminGamerLinkedOffers } from "../../entities/AdminGamerLinkedOffers";
import { AdminExchangeTransactionListDetail } from "../../entities/adminExchangeTransactionListDetail";
import { AdminGamerAssets } from "../../entities/adminGamerAssets";
import { AdminGamerDetails } from "../../entities/adminGamerDetails";
import {
  AdminGamerFilterList
} from "../../entities/adminGamerFilterList";
import { AdminGamerOffers } from "../../entities/adminGamerOffers";
import { AdminGamerTxnHistory } from "../../entities/adminGamerTxnHistory";
import { GamerAsset } from "../../entities/gamerAsset";
import { GamerDetail } from "../../entities/gamerDetail";
import { GamerGameList } from "../../entities/gamerGameList";
import { GamerOfferFilters } from "../../entities/gamerOfferFilters";
import { RedeemTransactions } from "../../entities/redeemTransactions";
import { RedeemTxFilters } from "../../entities/redeemTxFilters";
import { RewardTxn } from "../../entities/rewardTxn";
import {
  getAdminGamerList,
  getAdminGamerDetail,
  getAdminGamerAsset,
  getAdminGamerOffer,
  getGamerAllGames,
  getGamerLinkedOffer,
  getExchangeTransaction,
  getGamerRedeemTransaction,
  getGamerRedeemTxFilter,
  getGamerAssets,
  getGamerOfferFilter,
  getGamerTxnHistory,
  getGamerRewardTxn
} from "../../services/adminGamerServices";
import adminGamerConstant from "../../util/adminGamerConstant";
import constant from "../../util/constant";
import { prepareLabelValueFormatData, extremum } from "../../util/helpers";
import {
  START_LOADER,
  STOP_LOADER,
  ADMIN_GAMERS_LIST_SUCCESS,
  ADMIN_GAMERS_LIST_FAILURE,
  ADMIN_GAMER_LIST_API_REQUEST,
  ADMIN_GAMERS_API_END,
  ADMIN_GAMER_FILTER_LIST_API_REQUEST,
  ADMIN_GAMERS_FILTER_LIST_FAILURE,
  ADMIN_GAMERS_FILTER_LIST_SUCCESS,
  ADMIN_GAMER_API_REQUEST,
  ADMIN_GAMER_DETAIL_FAILURE,
  ADMIN_GAMER_DETAIL_SUCCESS,
  CLEAR_ADMIN_GAMER_DETAIL,
  ADMIN_GAMER_ASSET_API_REQUEST,
  ADMIN_GAMER_ASSET_LIST_SUCCESS,
  ADMIN_GAMER_ASSET_LIST_FAILURE,
  ADMIN_GAMER_OFFER_API_REQUEST,
  ADMIN_GAMER_OFFER_LIST_SUCCESS,
  ADMIN_GAMER_OFFER_LIST_FAILURE,
  ADMIN_GAMER_LIST_SUCCESS,
  ADMIN_GAMER_LIST_FAILURE,
  ADMIN_GAMER_ALL_LIST_SUCCESS,
  ADMIN_GAMER_ALL_LIST_FAILURE,
  GAMER_LINKED_OFFERS_API_REQUEST,
  GAMER_LINKED_OFFERS_SUCCESS,
  GAMER_LINKED_OFFERS_FAILURE,
  EXCHANGE_TRANSACTION_API_REQUEST,
  GAMER_EXCHANGE_TRANSACTION_SUCCESS,
  GAMER_EXCHANGE_TRANSACTION_FAILURE,
  ADMIN_GAMER_REDEEM_TRANS_SUCCESS,
  ADMIN_GAMER_REDEEM_TRANS_FAILURE,
  ADMIN_GAMER_REDEEM_TX_FILTER_SUCCESS,
  ADMIN_GAMER_REDEEM_TX_FILTER_FAILURE,
  GAMER_ASSETS_FAILURE,
  GAMER_ASSETS_SUCCESS,
  GAMER_OFFER_FILTER_SUCCESS,
  GAMER_OFFER_FILTER_FAILURE,
  ADMIN_GAMER_TOURNAMENTS_LIST_API_REQUEST,
  ADMIN_GAMER_TOURNAMENTS_LIST_SUCCESS,
  ADMIN_GAMER_TOURNAMENTS_LIST_FAILURE,
  ADMIN_GAMER_TOURNAMENTS_FILTER_SUCCESS,
  ADMIN_GAMER_TOURNAMENTS_FILTER_FAILURE,
  CLEAR_ADMIN_REDEEM_TRANSACTION,
  ADMIN_GAMER_TXN_HISTORY_LIST_API_REQUEST,
  ADMIN_GAMER_TXN_HISTORY_LIST_SUCCESS,
  ADMIN_GAMER_TXN_HISTORY_LIST_FAILURE,
  CLEAR_ADMIN_GAMER_OFFERS,
  GAMER_OFFER_REWARD_TXN_API_REQUEST,
  GAMER_OFFER_REWARD_TXN_SUCCESS,
  GAMER_OFFER_REWARD_TXN_FAILURE
} from "../actionTypes";

const { GAMER_USERS_API_TYPES } = adminGamerConstant;
const { ERROR_MESSAGES, MIN_MAX } = constant;

export const getAdminGamerAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_GAMER_LIST_API_REQUEST,
      payload: GAMER_USERS_API_TYPES.GET_GAMERS_LIST,
    } );

    const response = await getAdminGamerList( param );
    const dataList = response.data.gamerList.map(
      item => new AdminGamerDetails( item )
    );

    dispatch( {
      type: ADMIN_GAMERS_LIST_SUCCESS,
      payload: { dataList, meta: response.data.meta, maxAcqyrCashBalance: response.data.maxAcqyrCashBalance[ 0 ].maxBalance },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: ADMIN_GAMERS_LIST_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_GAMERS_API_END,
    } );
  }
};

export const getAdminFilterAction = param => async ( dispatch, getState ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_GAMER_FILTER_LIST_API_REQUEST,
      payload: GAMER_USERS_API_TYPES.GET_GAMERS_FILTER_LIST,
    } );

    const response = await getAdminGamerList( param );
    const maxLinkedGames = maxBy( response.data.gamerList, "LinkedGamesCount" ) || { LinkedGamesCount: 0 };
    let dataList = new AdminGamerFilterList( response?.data?.gamerList );

    dataList = uniqBy( dataList.gamerList, "label" );

    const membershipTypes = await getState().adminDetail?.data?.configuration?.membershipTypes;
    const gamersPlan = prepareLabelValueFormatData( membershipTypes, "name" );

    dispatch( {
      type: ADMIN_GAMERS_FILTER_LIST_SUCCESS,
      payload: {
        dataList,
        meta: response.data.meta,
        maxLinkedGames: maxLinkedGames.LinkedGamesCount,
        maxAcqyrCashBalance: response.data.maxAcqyrCashBalance[ 0 ].maxBalance,
        gamersPlan,
      },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: ADMIN_GAMERS_FILTER_LIST_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_GAMERS_API_END,
    } );
  }
};


export const getGamerDetailAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_GAMER_API_REQUEST,
      payload: GAMER_USERS_API_TYPES.GET_GAMER_DETAIL,
    } );

    const response = await getAdminGamerDetail( param );
    const newResponse = new GamerDetail( response );

    dispatch( {
      type: ADMIN_GAMER_DETAIL_SUCCESS,
      payload: newResponse,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: ADMIN_GAMER_DETAIL_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_GAMERS_API_END,
    } );
  }
};

export const getGamerListAction = ( gamerId, param ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_GAMER_API_REQUEST,
      payload: GAMER_USERS_API_TYPES.GET_ALL_GAMERS_LIST,
    } );

    const response = await getGamerAllGames( gamerId, param );
    const newResponse = new GamerGameList( response );

    dispatch( {
      type: ADMIN_GAMER_LIST_SUCCESS,
      payload: newResponse,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: ADMIN_GAMER_LIST_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_GAMERS_API_END,
    } );
  }
};

export const getGamerAllListAction = ( gamerId, param ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_GAMER_API_REQUEST,
      payload: GAMER_USERS_API_TYPES.GET_ALL_GAMERS_LIST,
    } );

    const response = await getGamerAllGames( gamerId, param );
    const newResponse = response.gamerGames?.map( s => ( { label: s.name, value: s.gameId } ) ) || [];

    dispatch( {
      type: ADMIN_GAMER_ALL_LIST_SUCCESS,
      payload: newResponse,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: ADMIN_GAMER_ALL_LIST_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_GAMERS_API_END,
    } );
  }
};

export const clearGamerDetailAction = () => ( dispatch ) => {
  dispatch( {
    type: CLEAR_ADMIN_GAMER_DETAIL,
  } );
};

export const getGamerAssetsAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_GAMER_ASSET_API_REQUEST,
      payload: GAMER_USERS_API_TYPES.GET_GAMER_ASSET_LIST,
    } );

    const response = await getAdminGamerAsset( param );
    const dataList = new AdminGamerAssets( response.data[ 0 ] );

    dispatch( {
      type: ADMIN_GAMER_ASSET_LIST_SUCCESS,
      payload: { dataList, meta: response.data.meta },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: ADMIN_GAMER_ASSET_LIST_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_GAMERS_API_END,
    } );
  }
};

export const getGamerOfferAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_GAMER_OFFER_API_REQUEST,
      payload: GAMER_USERS_API_TYPES.GET_GAMER_OFFER_LIST,
    } );

    const response = await getAdminGamerOffer( param );
    const dataList = new AdminGamerOffers( response.data[ 0 ] );

    dispatch( {
      type: ADMIN_GAMER_OFFER_LIST_SUCCESS,
      payload: { dataList, meta: response.data.meta },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: ADMIN_GAMER_OFFER_LIST_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_GAMERS_API_END,
    } );
  }
};

export const getGamerOfferFilterAction = ( gamerId, param ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_GAMER_API_REQUEST,
      payload: GAMER_USERS_API_TYPES.GET_GAMER_OFFER_FILTER,
    } );

    const response = await getGamerOfferFilter( gamerId, param );
    const filters = new GamerOfferFilters( response.data );

    dispatch( {
      type: GAMER_OFFER_FILTER_SUCCESS,
      payload: filters,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GAMER_OFFER_FILTER_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_GAMERS_API_END,
    } );
  }
};

export const getGamerLinkedOfferAction = ( gamerId, param ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: GAMER_LINKED_OFFERS_API_REQUEST,
      payload: GAMER_USERS_API_TYPES.GET_GAMER_LINKED_OFFERS,
    } );

    const response = await getGamerLinkedOffer( gamerId, param );
    const dataList = response.data.Offers.map( obj => new AdminGamerLinkedOffers( obj ) );
    const offerNames = prepareLabelValueFormatData( dataList, "offerName" );
    const gameNames = prepareLabelValueFormatData( dataList, "gameName" );
    const offerStatus = prepareLabelValueFormatData( dataList, "offerStatus" );

    dispatch( {
      type: GAMER_LINKED_OFFERS_SUCCESS,
      payload: {
        currentOfferList: dataList,
        meta: response.data.meta,
        gameNames,
        offerNames,
        offerStatus,
      },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GAMER_LINKED_OFFERS_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_GAMERS_API_END,
    } );
  }
};

export const getGamerRedeemTxnAction = ( gamerId, param ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_GAMER_API_REQUEST,
      payload: GAMER_USERS_API_TYPES.GET_GAMER_REDEEM_TRANSACTION,
    } );

    const response = await getGamerRedeemTransaction( gamerId, param );
    const dataList = response.data.redeemTransactionsList.map( data => new RedeemTransactions( data ) );

    dispatch( {
      type: ADMIN_GAMER_REDEEM_TRANS_SUCCESS,
      payload: { dataList, meta: response.data.meta },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: ADMIN_GAMER_REDEEM_TRANS_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_GAMERS_API_END,
    } );
  }
};

export const getGamerRedeemFilterAction = gamerId => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_GAMER_API_REQUEST,
      payload: GAMER_USERS_API_TYPES.GET_GAMER_REDEEM_TX_FILTER,
    } );

    const response = await getGamerRedeemTxFilter( gamerId );
    const filters = new RedeemTxFilters( response.data );

    dispatch( {
      type: ADMIN_GAMER_REDEEM_TX_FILTER_SUCCESS,
      payload: filters,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: ADMIN_GAMER_REDEEM_TX_FILTER_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_GAMERS_API_END,
    } );
  }
};

export const getExchangeTransactionAction = ( gamerId, param ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: EXCHANGE_TRANSACTION_API_REQUEST,
      payload: GAMER_USERS_API_TYPES.GET_EXCHANGE_TRANSACTION,
    } );

    const response = await getExchangeTransaction( gamerId, param );
    const transactionList = response.data.exchangeTransactions.map(
      item => new AdminExchangeTransactionListDetail( item )
    );

    dispatch( {
      type: GAMER_EXCHANGE_TRANSACTION_SUCCESS,
      payload: { transactionList, meta: response.data.meta },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GAMER_EXCHANGE_TRANSACTION_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_GAMERS_API_END,
    } );
  }
};

export const getGamerAssetAction = ( gamerId, param ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_GAMER_API_REQUEST,
      payload: GAMER_USERS_API_TYPES.GET_GAMER_ASSETS,
    } );

    const response = await getGamerAssets( gamerId, param );
    const dataList = response.data.map( data => new GamerAsset( data ) );
    // Filter List
    const gameNames = prepareLabelValueFormatData( response.data, "gameName" );
    const assetNames = prepareLabelValueFormatData( response.data, "assetName" );
    const assetIds = prepareLabelValueFormatData( response.data, "assetId" );
    let qxPointMinMax = { min: 0, max: 0 };

    if( response.data.length ) {
      const minQxPoint = extremum( response.data, "unitPriceQxPoints", MIN_MAX.MIN );
      const maxQxPoint = extremum( response.data, "unitPriceQxPoints", MIN_MAX.MAX );

      qxPointMinMax = { min: minQxPoint.unitPriceQxPoints, max: maxQxPoint.unitPriceQxPoints };
    }

    dispatch( {
      type: GAMER_ASSETS_SUCCESS,
      payload: { dataList: dataList, gameNames: gameNames, assetNames: assetNames, assetIds: assetIds, qxPointMinMax: qxPointMinMax },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GAMER_ASSETS_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_GAMERS_API_END,
    } );
  }
};

/**
 * used to get gamer tournament list.
 * @func getGamerTournamentListAction
 * @param {string} gamerId
 * @param {Object} param
 * @returns {object} dispatch object
 **/
export const getGamerTournamentListAction = ( gamerId, param ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_GAMER_TOURNAMENTS_LIST_API_REQUEST,
      payload: GAMER_USERS_API_TYPES.GET_GAMER_TOURNAMENT_LIST,
    } );

    const response = await getGamerLinkedOffer( gamerId, param );
    const dataList = response.data.Offers.map( obj => new AdminGamerLinkedOffers( obj ) );
    const newResponse = { tournamentList: dataList, meta: response.data.meta };

    dispatch( {
      type: ADMIN_GAMER_TOURNAMENTS_LIST_SUCCESS,
      payload: newResponse,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: ADMIN_GAMER_TOURNAMENTS_LIST_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_GAMERS_API_END,
    } );
  }
};

/**
 * used to get gamer tournament filters data
 * @func getGamerTournamentFilterAction
 * @param {string} gamerId
 * @param {Object} param
 * @returns {Object} dispatch object
 **/
export const getGamerTournamentFilterAction = ( gamerId, param ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_GAMER_API_REQUEST,
      payload: GAMER_USERS_API_TYPES.GET_GAMER_TOURNAMENT_FILTER,
    } );

    const response = await getGamerOfferFilter( gamerId, param );
    const filters = new GamerOfferFilters( response.data );

    dispatch( {
      type: ADMIN_GAMER_TOURNAMENTS_FILTER_SUCCESS,
      payload: filters,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: ADMIN_GAMER_TOURNAMENTS_FILTER_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_GAMERS_API_END,
    } );
  }
};

export const clearGamerRedeemTransactionsPreviousState = () => ( dispatch ) => {
  dispatch( { type: CLEAR_ADMIN_REDEEM_TRANSACTION } );
};

export const getGamerTxnHistoryAction = gamerId => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_GAMER_TXN_HISTORY_LIST_API_REQUEST,
      payload: GAMER_USERS_API_TYPES.GET_GAMER_TXN_HISTORY_LIST,
    } );

    const response = await getGamerTxnHistory( gamerId );
    const txnHistoryList = response.data.map( obj => new AdminGamerTxnHistory( obj ) );
    const itemNames = prepareLabelValueFormatData( txnHistoryList, "item" );
    const modeNames = prepareLabelValueFormatData( txnHistoryList, "mode" );
    const statusNames = prepareLabelValueFormatData( txnHistoryList, "status" );

    dispatch( {
      type: ADMIN_GAMER_TXN_HISTORY_LIST_SUCCESS,
      payload: {
        txnHistoryList,
        itemNames,
        modeNames,
        statusNames,
      },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: ADMIN_GAMER_TXN_HISTORY_LIST_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_GAMERS_API_END,
    } );
  }
};

export const clearGamerOffersPreviousState = () => ( dispatch ) => {
  dispatch( { type: CLEAR_ADMIN_GAMER_OFFERS } );
};

export const getGamerOfferRewardTxn = ( gamerId, param ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: GAMER_OFFER_REWARD_TXN_API_REQUEST,
      payload: GAMER_USERS_API_TYPES.GET_GAMER_OFFER_REWARD_TXN,
    } );

    const response = await getGamerRewardTxn( gamerId, param );
    const dataList = response.data.map( obj => new RewardTxn( obj ) );
    const offerNames = prepareLabelValueFormatData( dataList, "offerName" );
    const gameNames = prepareLabelValueFormatData( dataList, "gameName" );
    const partnerNames = prepareLabelValueFormatData( dataList, "partnerName" );
    let qxPointMinMax = { min: 0, max: 0 };
    let gamerPlaceMinMax = { min: 0, max: 0 };

    if( dataList.length ) {
      const minQxPoint = extremum( dataList, "QXPoints", MIN_MAX.MIN );
      const maxQxPoint = extremum( dataList, "QXPoints", MIN_MAX.MAX );

      qxPointMinMax = { min: minQxPoint.QXPoints, max: maxQxPoint.QXPoints };

      const minGamerPlace = extremum( dataList, "myPlace", MIN_MAX.MIN );
      const maxGamerPlace = extremum( dataList, "myPlace", MIN_MAX.MAX );

      gamerPlaceMinMax = { min: minGamerPlace.myPlace, max: maxGamerPlace.myPlace };
    }

    dispatch( {
      type: GAMER_OFFER_REWARD_TXN_SUCCESS,
      payload: {
        rewardTxnList: dataList,
        meta: response.data.meta,
        gameNames,
        offerNames,
        partnerNames,
        qxPointMinMax,
        gamerPlaceMinMax,
      },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GAMER_OFFER_REWARD_TXN_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_GAMERS_API_END,
    } );
  }
};

