import constant from "./constant";

const { OPT_IN_STATUS, BUSINESS_ENTITY } = constant;

const offerConstant = {
  OFFER_API_TYPES: {
    GET_PARTNER_OFFER_TEMPLATES: "GET_PARTNER_OFFER_TEMPLATES",
    ADD_PARTNER_NEW_OFFER_TEMPLATE: "ADD_PARTNER_NEW_OFFER_TEMPLATE",
    ADD_PARTNER_OFFER_FROM_SCRATCH: "ADD_PARTNER_OFFER_FROM_SCRATCH",
    GET_PARTNER_OFFER_TEMPLATE_DETAIL: "GET_PARTNER_OFFER_TEMPLATE_DETAIL",
    GET_PARTNER_OFFER: "GET_PARTNER_OFFER",
    ADD_ADMIN_NEW_OFFER: "ADD_ADMIN_NEW_OFFER",
    GET_ADMIN_OFFERS: "GET_ADMIN_OFFERS",
    GET_ADMIN_OFFER_DETAILS: "GET_ADMIN_OFFER_DETAILS",
    REJECT_OFFER_REQUEST: "REJECT_OFFER_REQUEST",
    APPROVE_OFFER_REQUEST: "APPROVE_OFFER_REQUEST",
    ENABLE_DISABLE_PARTNER_OFFER: "ENABLE_DISABLE_PARTNER_OFFER",
    ADD_ADMIN_NEW_DRAFT_OFFER: "ADD_ADMIN_NEW_DRAFT_OFFER",
    UPDATE_ADMIN_OFFER: "UPDATE_ADMIN_OFFER",
    UPDATE_PARTNER_OFFER_TEMPLATE: "UPDATE_PARTNER_OFFER_TEMPLATE",
    GET_PARTNER_DRAFT_OFFER_TEMPLATE_DETAIL: "GET_PARTNER_DRAFT_OFFER_TEMPLATE_DETAIL",
    UPDATE_PARTNER_OFFER_DRAFT_TEMPLATE: "UPDATE_PARTNER_OFFER_DRAFT_TEMPLATE",
    UPDATE_ADMIN_OFFER_DRAFT_TEMPLATE: "UPDATE_ADMIN_OFFER_DRAFT_TEMPLATE",
    UPDATE_PARTNER_OFFER_BY_ADMIN: "UPDATE_PARTNER_OFFER_BY_ADMIN",
    DELETE_PARTNER_OFFER: "DELETE_PARTNER_OFFER",
    DELETE_ADMIN_OFFER: "DELETE_ADMIN_OFFER",
    ADD_PARTNER_CLONE_DRAFT_OFFER: "ADD_PARTNER_CLONE_DRAFT_OFFER",
  },
  OFFER_TYPE: {
    OFFER_FROM_TEMPLATE: "offerTemplate",
    OFFER_FROM_SCRATCH: "offerScratch",
    NEW_OFFER_TEMPLATE: "newOffer",
  },
  FUNCTIONS: [
    { value: "1", label: "SUM" },
    { value: "2", label: "MAX" },
    { value: "3", label: "MIN" },
    { value: "4", label: "AVG" },
    { value: "5", label: "COUNT" },
    { value: "6", label: "VALUE" },
  ],
  COMPARISONS: [
    { value: "1", label: "Equals (=)" },
    { value: "2", label: "Less than (<)" },
    { value: "3", label: "Less than or equal to (<=)" },
    { value: "4", label: "Greater than (>)" },
    { value: "5", label: "Greater than or equal to (>=)" },
    { value: "6", label: "Not equal to (<>)" },
  ],
  RANKING_OBJECTIVE: [
    { value: "1", label: "Higher" },
    { value: "0", label: "Lower" },
  ],
  OFFER_STATUS: [
    {
      status: "1",
      title: "In Review",
      class: "badge-secondary",
    },
    {
      status: "2",
      title: "Approved",
      class: "badge-approved",
    },
    {
      status: "3",
      title: "Rejected",
      class: "badge-rejected",
    },
    {
      status: "4",
      title: "Template",
      class: "badge-template",
    },
    {
      status: "5",
      title: "Draft",
      class: "draft",
    },
    {
      status: "7",
      title: "Expired",
      class: "badge-warning",
    },
    {
      status: "6",
      title: "Live",
      class: "badge-primary",
    },
    {
      status: "8",
      title: "Disabled",
      class: "badge-primary",
    },
  ],
  OFFER_LIST_SORT: {
    OFFER_NAME: "offerName",
    GAME_NAME: "gameName",
    STARTDATE: "startDateOriginal",
    ENDDATE: "endDateOriginal",
    STATUS: "is_active",
    PROGRESS: "progress",
    OPT_IN: "optInType",
  },
  OFFER_STATUS_DROPDOWN: [
    { label: "In Review", value: 1 },
    { label: "Approved", value: 2 },
    { label: "Rejected", value: 3 },
    { label: "Offer template", value: 4 },
    { label: "Draft", value: 5 },
    { label: "Live", value: 6 },
    { label: "Expired", value: 7 },
    { label: "Disabled", value: 8 },
  ],
  TOURNAMENT_STATUS_DROPDOWN: [
    { label: "In Review", value: 1 },
    { label: "Approved", value: 2 },
    { label: "Rejected", value: 3 },
    { label: "Tournament template", value: 4 },
    { label: "Draft", value: 5 },
    { label: "Live", value: 6 },
    { label: "Expired", value: 7 },
    { label: "Disabled", value: 8 },
  ],
  OFFER_DETAIL_STATUS: {
    IN_REVIEW: "1",
    APPROVED: "2",
    REJECTED: "3",
    OFFER_TEMPLATE: "4",
    DRAFT: "5",
    EXPIRED: "7",
    CURRENT: "6",
    DISABLED: "8",
  },
  TYPE_TO_BE_EDITED: {
    OFFER: "1",
    OFFER_TEMPLATE: "2",
    DRAFT: "3",
    TOURNAMENT_DRAFT: "6",
    TOURNAMENT_TEMPLATE: "5",
  },
  OFFER_TYPE_STATUS: {
    OFFER_TEMPLATE: "1",
    OFFER_FROM_SCRATCH: "2",
    OFFER_FROM_TEMPLATE: "3",
    DRAFT: "4",
    CLONE: "5",
  },
  DRAFT_ENTITY_NAME: {
    OFFER_TEMPLATE_DRAFT: "offer_template_draft",
    PARTNER_OFFER_DRAFT: "partner_offer_draft",
    PARTNER_TOURNAMENT_DRAFT: "partner_tournament_draft",
    TOURNAMENT_TEMPLATE_DRAFT: "tournament_template_draft",
  },
  REQUEST_TYPE: {
    OFFER: "1",
    OFFER_TEMPLATE: "2",
    DRAFT: "3",
    TOURNAMENT: "4",
    TOURNAMENT_TEMPLATE: "5",
  },
  SCREEN_TYPE: {
    OFFER: "offers",
    TOURNAMENT: "tournaments",
  },
  OFFER_API_STATUS: {
    OFFER: "1",
    OFFER_TEMPLATE: "2",
    DRAFT: "3",
  },
  OPT_IN_STATUS_DROPDOWN: [
    { label: "Yes", value: OPT_IN_STATUS.YES },
    { label: "No", value: OPT_IN_STATUS.NO },
    { label: "Fee", value: OPT_IN_STATUS.FEE },
  ],
  TRANSACTION_FOR: [
    { transactionType: "1", entityName: BUSINESS_ENTITY.OFFER },
    { transactionType: "4", entityName: BUSINESS_ENTITY.TOURNAMENT },
  ],
  OFFERS_TABS: {
    OFFERS: "offers",
    OFFER_REWARD_TXN: "offerRewardTxn",
    TOURNAMENTS: "tournaments",
    TOURNAMENT_REWARD_TXN: "tournamentRewardTxn",
  },
  SEARCH_PLACEHOLDER: {
    offers: "Search by Offer Name, Game and Description",
    offerRewardTxn: "Search by Game Name, Offer Name and Partner",
    tournaments: "Search by Tournament Name, Game and Description",
    tournamentRewardTxn: "Search by Game Name, Tournament Name and Partner",
  },
};

export default offerConstant;
