export const API_URL = {
  UPLOAD_LOGO: "partner/uploadLogo",
  PARTNER: "partner",
  GET_PARTNERS_LIST: "admin/partners",
  GET_PARTNER_DETAIL: "admin/partners/",
  REJECT_PARTNER: "admin/partners/reject",
  SET_AMMENDMENTS_REQUEST: "admin/partners/amendmentSuggestions",
  CONFIRM_FOR_DOCUSIGN: "admin/partners/confirmForDocuSign",
  RESOLVE_AMENDMENT: "admin/partners/resolveAmendment",
  UPDATE_PARTNER_STATUS: "admin/partners/status",
  GET_PARTNER_GAME_LIST: "partner/games",
  ADD_PARTNER_COMMENT: "partner/games/testing-comments",
  APPROVE_PARTNER_MANUAL_REQUEST: "admin/partners/approve",
  PARTNER_GAMES: "partner/games",
  PARTNER_ALL_GAMES_LIST: "partner/filters/games",
  PARTNER_COMMENT: "partner/partner-games/suggested-modification",
  ADMIN_GAMES: "admin/games",
  ADMIN_GAMES_MODIFICATION: "admin/partner-games/suggested-modification",
  UPDATE_PARTNER_GAME_STATUS: "admin/partner-games/status",
  PARTNER_EVENTS: "partner/events",
  ADMIN_EVENTS: "admin/events",
  ENABLE_DISABLE_EVENT_ADMIN: "admin/events/enable",
  ADMIN_USERS: "admin",
  PARTNER_DRAFT: "drafts",
  PARTNER_DELETE_DRAFT: "drafts/:Id",
  UPDATE_ADMIN_USER_PERMISSION: "admin/permissions",
  GET_PARTNER_OFFER_TEMPLATES: "partner/offer-template",
  ADMIN_FILTER_REQUEST: "admin/filterLists",
  GET_PARTNER_LOOKUP: "partner/filterLists",
  OFFERS: "/offers",
  ADMIN_OFFERS: "admin/offers",
  GET_PARTNER_OFFER: "partner/offers",
  GET_ADMIN_LOOKUP: "admin/filterLists",
  ADD_ADMIN_COMMENT: "admin/games/testing-comments",
  OFFER_TEMPLATE: "offers/offer-template",
  GET_ADMIN_OFFER_DETAIL: "offers",
  REJECT_OFFER: "admin/offers/reject",
  APPROVE_OFFER: "admin/offers/approve",
  ENABLE_DISABLE_GAME_ADMIN: "/admin/games/enable",
  ENABLE_DISABLE_OFFER_ADMIN: "/offers/enable",
  ADD_OFFER_TEMPLATE_DRAFT: "drafts",
  ENABLE_DISABLE_ADMIN_USER: "admin/enable",
  GET_ADMIN_PARTNER_GAMES: "admin/partner/:Id/games",
  GET_ADMIN_NOTIFICATION: "admin/notifications",
  GET_PARTNER_NOTIFICATION: "partner/notifications",
  READ_PARTNER_NOTIFICATION: "partner/markNotificationsRead",
  READ_ADMIN_NOTIFICATION: "admin/markNotificationsRead",
  PARTNER_PROFILE_UPDATE: "partner/profile",
  VERIFY_EMAIL: "/common/verifyEmail",
  UPDATE_PARTNER_EMAIL_ADDRESS: "/partner/emailAddress",
  UPDATE_ADMIN_EMAIL_ADDRESS: "/admin/emailAddress",
  ADMIN_GAMERS: "/admin/gamers",
  ADMIN_GAMERS_ASSETS: "/admin/gamers/:gamerId/assets",
  ADMIN_GAMERS_OFFERS: "/admin/gamers/:gamerId/offers",
  ADMIN_REDEEM_TRANSACTION: "/admin/redeem/tx",
  ADMIN_REDEEM_FILTER_LIST: "/admin/redeem/tx/filterLists",
  ADMIN_EXCHANGE_TRANSACTION: "/admin/exchange/tx",
  ADMIN_EXCHANGE_FILTER_LIST: "/admin/exchange/tx/filterLists",
  PARTNER_EXCHANGE_TRANSACTION: "/partner/tx",
  ADMIN_PARTNER_LINKED_GAMERS: "/admin/partners/:partnerId/gamers",
  ADMIN_PARTNER_GAMERS_FILTER_LIST: "/admin/partners/:partnerId/gamers/filterLists",
  ADMIN_GAMES_LINKED_GAMERS: "/admin/games/:gameId/gamers",
  ADMIN_GAMES_GAMERS_FILTER_LIST: "/admin/games/:gameId/gamers/filterLists",
  PARTNER_GAMERS: "partner/gamers",
  PARTNER_LEADER_BOARD: "partner/leaderboard",
  ADMIN_LEADER_BOARD: "admin/leaderboard",
  PARTNER_ASSETS: "partner/gamers/:gamerId/:gameId/assets",
  ADMIN_SIGN_OUT_CONFIRMATION: "admin/signOutConfirmation",
};
