import { forEach } from "lodash";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";

// Local-imports
import { useForm, useAddQuery, useFilter, useModal } from "../../../../hooks";
import {
  getPartnerGamerDetailAction,
  getPartnerGamerTournamentsListAction
} from "../../../../store/actions/partnerGamer";
import Enums from "../../../../util/Enums";
import adminConstant from "../../../../util/adminConstant";
import constant from "../../../../util/constant";
import { useSetUrlParams } from "../../../../util/helpers";
import partnerGamerConstant from "../../../../util/partnerGamerConstant";
import { partnerGamerTournamentTableConfigs } from "../../../../util/tableConstant";
import TableHeaderRow from "../../../shared/tableHeadingRow";
import ModalPopup from "../../modal";
import TournamentsFilters from "./tournamentsFilters";
import TournamentsHeader from "./tournamentsHeader";
import TournamentsList from "./tournamentsList";

const { API_STATUS, ORDER_BY } = constant;
const { API_TYPES } = partnerGamerConstant;
const { DEFAULT_RADIX, DATA_VIEW_TYPE } = Enums;
const { MODAL_TYPE_DETAIL } = adminConstant;

const PartnerGamerTournaments = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { gamerId: URLString } = useParams();
  const { 0: gamerId } = String( URLString ).split( "?" );
  const { addQuery } = useAddQuery();
  const paramsQuery = new URLSearchParams( window.location.search );
  const { setPureData, filteredData, filterData, handleFilterChange, clearSelectedFilter } = useFilter();
  const searchKeys = [ "tournamentName", "tournamentDescription", "gameName" ];
  const [ errorMessage, setErrorMessage ] = useState( "" );
  const { isOpened, closeModal, openModal, modalType, setModalType, modalData, setModalData } = useModal();
  const reqPayload = {
    fetchAllRecords: true,
    offerType: "Tournament",
    offer: "currentOrExpired",
  };
  const {
    status,
    apiType,
    gamerDetail,
    tournamentList,
    tournamentNameFilterList,
    gameNameFilterList,
    gamerPlaceRange,
  } = useSelector( state => ( {
    status: state.partnerGamer.status,
    apiType: state.partnerGamer.apiType,
    gamerDetail: state.partnerGamer.gamerDetail,
    tournamentList: state.partnerGamer.tournamentList,
    tournamentNameFilterList: state.partnerGamer.gamerTournamentsFilterList.tournamentNameFilterList,
    gameNameFilterList: state.partnerGamer.gamerTournamentsFilterList.gameNameFilterList,
    gamerPlaceRange: state.partnerGamer.gamerPlaceRange,
  } ) );
  const sliderMinMaxObj = {
    minGamerPlace: gamerPlaceRange.min,
    maxGamerPlace: gamerPlaceRange.max,
  };
  const { values, handleChange } = useForm(
    {
      viewType: parseInt( DATA_VIEW_TYPE.GRID, DEFAULT_RADIX ),
      searchKey: "",
      sortBy: "name",
      sortOrder: ORDER_BY.ASC,
      searchCol: searchKeys,
      tournamentIds: [],
      gameIds: [],
      startDate: false,
      endDate: false,
      minGamerPlace: 0,
      maxGamerPlace: 0,
      compareKeyArray: [ "myPlace" ],
      optInTypes: [],
    },
    {}
  );
  const requestParams = Object.assign(
    {
      sortOrder: values.sortOrder,
      sortBy: values.sortBy,
      searchCol: searchKeys,
      gameIds: values.gameIds?.map( value => value.value ) || [],
      tournamentIds: values.tournamentIds?.map( value => value.value ) || [],
      startDate: values.startDate === false ? "" : values.startDate,
      endDate: values.endDate === false ? "" : values.endDate,
      minGamerPlace: values.minGamerPlace,
      maxGamerPlace: values.maxGamerPlace,
      compareKeyArray: values.compareKeyArray,
      optInTypes: values.optInTypes?.map( value => value.value ) || [],
    },
    values.searchKey && { searchKey: values.searchKey },
    values.sortBy && { sortBy: values.sortBy }
  );

  const updateStateFromUrl = () => {
    if( paramsQuery.get( "viewType" ) ) {
      handleChange( "viewType", parseInt( paramsQuery.get( "viewType" ), DEFAULT_RADIX ) );
    }

    if( paramsQuery.get( "sortBy" ) && paramsQuery.get( "sortOrder" ) ) {
      handleChange( "sortBy", paramsQuery.get( "sortBy" ) );
      handleChange( "sortOrder", paramsQuery.get( "sortOrder" ) );
      toggleClass( paramsQuery.get( "sortBy" ), paramsQuery.get( "sortOrder" ), true );
    }

    if( paramsQuery.get( "searchKey" ) ) {
      handleChange( "searchKey", paramsQuery.get( "searchKey" ) );
    }

    if( paramsQuery.get( "gameIds" ) ) {
      handleChange( "gameIds", JSON.parse( paramsQuery.get( "gameIds" ) ) );
    }

    if( paramsQuery.get( "tournamentIds" ) ) {
      handleChange( "tournamentIds", JSON.parse( paramsQuery.get( "tournamentIds" ) ) );
    }

    if( paramsQuery.get( "startDate" ) ) {
      handleChange( "startDate", ( paramsQuery.get( "startDate" ) === null || paramsQuery.get( "startDate" ) === "" ) ? false : paramsQuery.get( "startDate" ) );
    }

    if( paramsQuery.get( "endDate" ) ) {
      handleChange( "endDate", ( paramsQuery.get( "endDate" ) === null || paramsQuery.get( "endDate" ) === "" ) ? false : paramsQuery.get( "endDate" ) );
    }

    if( paramsQuery.get( "minGamerPlace" ) ) {
      handleChange( "minGamerPlace", JSON.parse( paramsQuery.get( "minGamerPlace" ) ) );
    }

    if( paramsQuery.get( "maxGamerPlace" ) ) {
      handleChange( "maxGamerPlace", JSON.parse( paramsQuery.get( "maxGamerPlace" ) ) );
    }

    if( paramsQuery.get( "optInTypes" ) ) {
      handleChange( "optInTypes", JSON.parse( paramsQuery.get( "optInTypes" ) ) );
    }
  };

  useEffect( () => {
    dispatch(
      getPartnerGamerTournamentsListAction( gamerId, reqPayload ) );
  }, [] );
  useEffect( () => {
    if(
      ( status === API_STATUS.SUCCESS ) &&
      ( apiType === API_TYPES.GET_GAMER_TOURNAMENTS )
    ) {
      dispatch( getPartnerGamerDetailAction( gamerId ) );
      handleChange( "viewType", values.viewType );
      handleChange( "minGamerPlace", gamerPlaceRange.min );
      handleChange( "maxGamerPlace", gamerPlaceRange.max );
      setPureData( tournamentList );
      updateStateFromUrl();
      filterData( requestParams );
    }
  }, [ status, apiType ] );
  useEffect( () => {
    filterData( requestParams );
  }, [
    values.sortOrder,
    values.sortBy,
    values.searchKey,
    values.gameIds,
    values.tournamentIds,
    values.startDate,
    values.endDate,
    values.minGamerPlace,
    values.maxGamerPlace,
    values.optInTypes,
  ] );

  const handleListSorting = ( sortBy, sortingType ) => {
    handleChange( "sortBy", sortBy );
    handleChange( "sortOrder", sortingType );
    addQuery( useSetUrlParams( "sortBy", sortBy ), location.state );
    addQuery( useSetUrlParams( "sortOrder", sortingType ), location.state );
    toggleClass( sortBy, sortingType, true );
  };

  const handleMemoizedSortingClick = useCallback(
    ( sortBy, orderType ) => handleListSorting( sortBy, orderType ),
    []
  );
  const [ sortColumns, setSortColumns ] = useState( [
    { col: "tournamentName", value: "Tournament Name", status: true, order: ORDER_BY.ASC },
    { col: "gameName", value: "Game Name", status: false, order: null },
    { col: "startDateOriginal", value: "Start Date", status: false, order: null },
    { col: "endDateOriginal", value: "End Date", status: false, order: null },
    { col: "place", value: "Place", status: false, order: null },
    { col: "optInType", value: "Opt In", status: false, order: null },
  ] );

  const toggleClass = ( params, order, isQueryParamsUpdated = false ) => {
    let newOrder = ORDER_BY.ASC;

    forEach( sortColumns, ( value ) => {
      value.status = false;

      if( value.col === params ) {
        value.status = true;

        if( isQueryParamsUpdated ) {
          newOrder = order;
        }
        else {
          if( order === ORDER_BY.ASC ) {
            value.order = ORDER_BY.DESC;
          }
          else {
            value.order = ORDER_BY.ASC;
          }

          newOrder = value.order;
        }
      }
    } );
    setSortColumns( sortColumns );
    handleChange( "sortBy", params );
    handleChange( "sortOrder", newOrder );
    addQuery( useSetUrlParams( "sortBy", params ), location.state );
    addQuery( useSetUrlParams( "sortOrder", newOrder ), location.state );
  };

  const memoizedGamesTableConfig = useMemo( () => partnerGamerTournamentTableConfigs, [] );
  const [ showFilter, setShowFilter ] = useState( false );
  const showFilterScreen = () => setShowFilter( !showFilter );

  const handleConfirm = () => {
    if( modalType === MODAL_TYPE_DETAIL.SHOW_ALL_REWARD.type ) {
      closeModal();
    }
  };

  const handleShowAll = ( rewards ) => {
    setModalType( MODAL_TYPE_DETAIL.SHOW_ALL_REWARD.type );
    setModalData( { rewards } );
    openModal();
  };

  const onCloseModal = () => {
    closeModal();
    setErrorMessage();
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="main-right-wrapper">
          <TournamentsHeader
            gamerDetail={gamerDetail}
            gamerId={gamerId}
            handleChange={handleChange}
            showFilter={showFilter}
            showFilterScreen={showFilterScreen}
            values={values}
            sliderMinMaxObj={sliderMinMaxObj}
            location={location}
          />
          <TournamentsFilters
            showFilter={showFilter}
            tournamentNameFilterList={tournamentNameFilterList}
            gameNameFilterList={gameNameFilterList}
            handleChange={handleChange}
            values={values}
            sortColumns={sortColumns}
            toggleClass={toggleClass}
            location={location}
            gamerPlaceRange={gamerPlaceRange}
            handleFilterChange={handleFilterChange}
            clearSelectedFilter={clearSelectedFilter}
          />
          <div className={`games-list-table-head gamer-offer-head ${ ( !values.viewType ) ? "active" : "" }`}>
            <div className='table-head-body'>
              <TableHeaderRow
                configData={memoizedGamesTableConfig}
                onAscOrder={handleMemoizedSortingClick}
                onDescOrder={handleMemoizedSortingClick}
              />
            </div>
          </div>
          <TournamentsList
            gamerId={gamerId}
            tournamentsList={filteredData}
            values={values}
            location={location}
            handleShowAll={handleShowAll}
          />
        </div>
      </div>
      <ModalPopup
        isOpened={isOpened}
        onClose={onCloseModal}
        type={modalType}
        onConfirm={handleConfirm}
        errorMessage={errorMessage}
        offerName={modalData.offerName}
        dataList={modalData.rewards || []}
      />
    </>
  );
};

export default PartnerGamerTournaments;
