import PropTypes from "prop-types";
import React from "react";
import Form from "react-bootstrap/Form";
import { useSetUrlParams } from "../../../util/helpers";
import CustomDatePicker from "../customDatePicker";

const RangeDatePicker = ( {
  label,
  values,
  addQuery,
  handleChange,
  param,
  isExtraParam = false,
  locationState = "",
} ) => {
  return (
    <>
      <Form.Label>{label}</Form.Label>
      <CustomDatePicker
        value={values[ param.key1 ]}
        startDate={values[ param.key1 ]}
        endDate={values[ param.key2 ]}
        placeholder={"dd mmm yyyy - dd mmm yyyy"}
        handleChange={( e ) => {
          if( e[ 0 ] === null ) {
            handleChange( param.key1, null );
            handleChange( param.key2, null );
            addQuery( useSetUrlParams( param.key2, "" ), locationState, isExtraParam );
            addQuery( useSetUrlParams( param.key1, "" ), locationState, isExtraParam );
            handleChange( param.key3, null );
            handleChange( param.key4, null );
          }
          else {
            handleChange( param.key1, e[ 0 ] );

            if( values[ param.key2 ] ) {
              handleChange( param.key2, null );
            }

            if( e[ 1 ] ) {
              handleChange( param.page, 1 );
              addQuery( useSetUrlParams( param.key1, new Date( e[ 0 ] ).toLocaleDateString( "fr-CA" ) ), locationState, isExtraParam );
              handleChange( param.key3, new Date( e[ 0 ] ).toLocaleDateString( "fr-CA" ) );
              handleChange( param.key4, new Date( e[ 1 ] ).toLocaleDateString( "fr-CA" ) );
              addQuery( useSetUrlParams( param.key2, new Date( e[ 1 ] ).toLocaleDateString( "fr-CA" ) ), locationState, isExtraParam );
              handleChange( param.key2, e[ 1 ] );
            }
          }
        }
        }
        selectsRange={true}
      />
    </>
  );
};

RangeDatePicker.propTypes = {
  label: PropTypes.string,
  values: PropTypes.object,
  addQuery: PropTypes.func,
  handleChange: PropTypes.func,
  param: PropTypes.object,
  isExtraParam: PropTypes.bool,
  locationState: PropTypes.string,
};

export default RangeDatePicker;
