import { uniqBy } from "lodash";
import { toastr } from "react-redux-toastr";
import { AdminGamerAssets } from "../../entities/adminGamerAssets";
import { GameDetail } from "../../entities/gameDetail";
import { GamerAsset } from "../../entities/gamerAsset";
import { GamerDetail } from "../../entities/gamerDetail";
import { GamerGameList } from "../../entities/gamerGameList";
import { PartnerGamerFilterList, OfferNameFilterList, GamesNameFilterList } from "../../entities/partnerGamerFilterList";
import { PartnerGamerLinkedGamesOffers } from "../../entities/partnerGamerLinkedGamesOffers";
import { PartnerGamerDetails } from "../../entities/partnerGamerList";
import { PartnerTxnList } from "../../entities/partnerTxnList";
import { getGamerLists } from "../../services/analyticsServices";
import {
  getPartnerGamerDetail,
  getPartnerGamerAssets,
  getPartnerGamerAllLinkedGames,
  getPartnerGamerLinkedGameDetail,
  getPartnerGamerLinkedGameAssets,
  getPartnerGamerLinkedGameOffers,
  getPartnerGamerExchangeTransaction
} from "../../services/partnerGamerServices";
import constant from "../../util/constant";
import { prepareLabelValueFormatData, extremum } from "../../util/helpers";
import partnerGamerConstant from "../../util/partnerGamerConstant";
import {
  START_LOADER,
  STOP_LOADER,
  PARTNER_GAMERS_API_END,
  PARTNER_GAMER_LIST_API_REQUEST,
  PARTNER_GAMERS_LIST_SUCCESS,
  PARTNER_GAMERS_LIST_FAILURE,
  PARTNER_GAMER_DETAIL_API_REQUEST,
  PARTNER_GAMER_DETAIL_SUCCESS,
  PARTNER_GAMER_DETAIL_FAILURE,
  PARTNER_GAMER_TOURNAMENTS_LIST_API_REQUEST,
  PARTNER_GAMER_TOURNAMENTS_LIST_SUCCESS,
  PARTNER_GAMER_TOURNAMENTS_LIST_FAILURE,
  PARTNER_GAMER_ASSETS_LIST_API_REQUEST,
  PARTNER_GAMER_ASSETS_LIST_SUCCESS,
  PARTNER_GAMER_ASSETS_LIST_FAILURE,
  PARTNER_GAMER_LINKED_GAMES_API_REQUEST,
  PARTNER_GAMER_LINKED_GAMES_SUCCESS,
  PARTNER_GAMER_LINKED_GAMES_FAILURE,
  PARTNER_GAMER_LINKED_GAMES_DETAIL_API_REQUEST,
  PARTNER_GAMER_LINKED_GAMES_DETAIL_SUCCESS,
  PARTNER_GAMER_LINKED_GAMES_DETAIL_FAILURE,
  PARTNER_GAMER_LINKED_GAMES_ASSETS_API_REQUEST,
  PARTNER_GAMER_LINKED_GAMES_ASSETS_SUCCESS,
  PARTNER_GAMER_LINKED_GAMES_ASSETS_FAILURE,
  PARTNER_GAMER_LINKED_GAMES_OFFERS_API_REQUEST,
  PARTNER_GAMER_LINKED_GAMES_OFFERS_SUCCESS,
  PARTNER_GAMER_LINKED_GAMES_OFFERS_FAILURE,
  PARTNER_GAMER_EXCHANGE_TRANSACTION_API_REQUEST,
  PARTNER_GAMER_EXCHANGE_TRANSACTION_SUCCESS,
  PARTNER_GAMER_EXCHANGE_TRANSACTION_FAILURE
} from "../actionTypes";

const { API_TYPES } = partnerGamerConstant;
const { ERROR_MESSAGES, MIN_MAX } = constant;

export const getPartnerGamerListAction = data => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_GAMER_LIST_API_REQUEST,
      payload: API_TYPES.GET_GAMER_LISTS,
    } );

    const response = await getGamerLists( data );
    const partnerGamerLists = new PartnerGamerDetails( response.data );
    const dataList = partnerGamerLists?.partnerGamerList;

    // filters
    let gamerLinkedGamesMinMax = { min: 0, max: 0 };
    let qxPointsMinMax = { min: 0, max: 0 };

    if( dataList?.length ) {
      const minLinkedGames = extremum( dataList, "linkedGamesCount", MIN_MAX.MIN );
      const maxLinkedGames = extremum( dataList, "linkedGamesCount", MIN_MAX.MAX );

      gamerLinkedGamesMinMax = { min: minLinkedGames.linkedGamesCount, max: maxLinkedGames.linkedGamesCount };

      const minQxPoint = extremum( dataList, "acqyrCashBalance", MIN_MAX.MIN );
      const maxQxPoint = extremum( dataList, "acqyrCashBalance", MIN_MAX.MAX );

      qxPointsMinMax = { min: minQxPoint.acqyrCashBalance, max: maxQxPoint.acqyrCashBalance };
    }

    const gamerNames = prepareLabelValueFormatData( partnerGamerLists.partnerGamerList, "fullName" );

    dispatch( {
      type: PARTNER_GAMERS_LIST_SUCCESS,
      payload: {
        gamerList: partnerGamerLists.partnerGamerList,
        gamerNames: gamerNames,
        gamerLinkedGamesMinMax: gamerLinkedGamesMinMax,
        qxPointsMinMax: qxPointsMinMax,
      },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: PARTNER_GAMERS_LIST_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: PARTNER_GAMERS_API_END,
    } );
  }
};

export const getPartnerGamerDetailAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_GAMER_DETAIL_API_REQUEST,
      payload: API_TYPES.GET_GAMER_DETAILS,
    } );

    const response = await getPartnerGamerDetail( param );
    const newResponse = new GamerDetail( response );

    dispatch( {
      type: PARTNER_GAMER_DETAIL_SUCCESS,
      payload: newResponse,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: PARTNER_GAMER_DETAIL_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: PARTNER_GAMERS_API_END,
    } );
  }
};

export const getPartnerGamerTournamentsListAction = ( gamerId, param ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_GAMER_TOURNAMENTS_LIST_API_REQUEST,
      payload: API_TYPES.GET_GAMER_TOURNAMENTS,
    } );

    const response = await getPartnerGamerLinkedGameOffers( gamerId, param );
    const dataList = response?.data?.Offers.map(
      ( item ) => {
        return new PartnerGamerFilterList( item );
      }
    ).sort( ( a, b ) => a.tournamentName.localeCompare( b.tournamentName ) ) ?? [];

    // Tournament list filters data
    let gamerPlaceMinMax = { min: 0, max: 0 };

    if( dataList.length ) {
      const minGamerPlace = extremum( dataList, "myPlace", MIN_MAX.MIN );
      const maxGamerPlace = extremum( dataList, "myPlace", MIN_MAX.MAX );

      gamerPlaceMinMax = { min: minGamerPlace.myPlace, max: maxGamerPlace.myPlace };
    }

    let tournamentNameFilterList = new OfferNameFilterList( response?.data?.Offers );

    tournamentNameFilterList = uniqBy( tournamentNameFilterList.offerNameList, "label" );

    let gameNameFilterList = new GamesNameFilterList( response?.data?.Offers );

    gameNameFilterList = uniqBy( gameNameFilterList.gameNameList, "label" );

    dispatch( {
      type: PARTNER_GAMER_TOURNAMENTS_LIST_SUCCESS,
      payload: {
        tournamentList: dataList,
        gamerPlaceMinMax: gamerPlaceMinMax,
        tournamentNameFilterList: tournamentNameFilterList,
        gameNameFilterList: gameNameFilterList,
      },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: PARTNER_GAMER_TOURNAMENTS_LIST_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: PARTNER_GAMERS_API_END,
    } );
  }
};

export const getPartnerGamerAssetsAction = gamerId => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_GAMER_ASSETS_LIST_API_REQUEST,
      payload: API_TYPES.GET_GAMER_ASSETS,
    } );

    const response = await getPartnerGamerAssets( gamerId );
    const dataList = response.data.map( data => new GamerAsset( data ) );
    // Filter List
    const gameNames = prepareLabelValueFormatData( response.data, "gameName" );
    const assetNames = prepareLabelValueFormatData( response.data, "assetName" );
    const assetIds = prepareLabelValueFormatData( response.data, "assetId" );
    let qxPointMinMax = { min: 0, max: 0 };

    if( response.data.length ) {
      const minQxPoint = extremum( response.data, "unitPriceQxPoints", MIN_MAX.MIN );
      const maxQxPoint = extremum( response.data, "unitPriceQxPoints", MIN_MAX.MAX );

      qxPointMinMax = { min: minQxPoint.unitPriceQxPoints, max: maxQxPoint.unitPriceQxPoints };
    }

    dispatch( {
      type: PARTNER_GAMER_ASSETS_LIST_SUCCESS,
      payload: {
        dataList: dataList,
        gameNames: gameNames,
        assetNames: assetNames,
        assetIds: assetIds,
        qxPointMinMax: qxPointMinMax },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: PARTNER_GAMER_ASSETS_LIST_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: PARTNER_GAMERS_API_END,
    } );
  }
};

export const getPartnerGamerLinkedGamesAction = ( gamerId, param ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_GAMER_LINKED_GAMES_API_REQUEST,
      payload: API_TYPES.GET_GAMER_LINKED_GAMES,
    } );

    const response = await getPartnerGamerAllLinkedGames( gamerId, param );
    const linkedGames = new GamerGameList( response );
    const filterLinkedGames = linkedGames.userGames?.map( game => ( { label: game.name, value: game.gameId } ) ) || [];

    dispatch( {
      type: PARTNER_GAMER_LINKED_GAMES_SUCCESS,
      payload: {
        linkedGames: linkedGames,
        filterLinkedGames: filterLinkedGames,
      },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: PARTNER_GAMER_LINKED_GAMES_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: PARTNER_GAMERS_API_END,
    } );
  }
};

export const getPartnerGamerLinkedGameDetailAction = ( gameId, queryParam ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_GAMER_LINKED_GAMES_DETAIL_API_REQUEST,
      payload: API_TYPES.GET_GAMER_LINKED_GAMES_DETAIL,
    } );

    const response = await getPartnerGamerLinkedGameDetail( gameId, queryParam );
    const linkedGameDetails = new GameDetail( response.data );

    dispatch( {
      type: PARTNER_GAMER_LINKED_GAMES_DETAIL_SUCCESS,
      payload: { linkedGameDetails: linkedGameDetails },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: PARTNER_GAMER_LINKED_GAMES_DETAIL_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: PARTNER_GAMERS_API_END,
    } );
  }
};

export const getGamerLinkedGamesAssetsAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_GAMER_LINKED_GAMES_ASSETS_API_REQUEST,
      payload: API_TYPES.GET_GAMER_LINKED_GAMES_OFFERS,
    } );

    const response = await getPartnerGamerLinkedGameAssets( param );
    const linkedGamesAssets = new AdminGamerAssets( response.data?.gameAssets[ 0 ] );

    dispatch( {
      type: PARTNER_GAMER_LINKED_GAMES_ASSETS_SUCCESS,
      payload: linkedGamesAssets,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: PARTNER_GAMER_LINKED_GAMES_ASSETS_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: PARTNER_GAMERS_API_END,
    } );
  }
};

export const getPartnerGamerLinkedGameOffersAction = ( gamerId, param ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_GAMER_LINKED_GAMES_OFFERS_API_REQUEST,
      payload: API_TYPES.GET_GAMER_LINKED_GAMES_ASSETS,
    } );

    const response = await getPartnerGamerLinkedGameOffers( gamerId, param );
    const gamerLinkedGameOffers = response.data?.Offers.map( ( item ) => {
      return new PartnerGamerLinkedGamesOffers( item );
    } )
      .sort( ( a, b ) => a.offerName.localeCompare( b.offerName ) ) ?? [];
      // Filter List
    const offerNames = prepareLabelValueFormatData( gamerLinkedGameOffers, "offerName" );
    const gameNames = prepareLabelValueFormatData( gamerLinkedGameOffers, "gameName" );

    dispatch( {
      type: PARTNER_GAMER_LINKED_GAMES_OFFERS_SUCCESS,
      payload: {
        gamerLinkedGameOffers,
        offerNames,
        gameNames,
      },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: PARTNER_GAMER_LINKED_GAMES_OFFERS_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: PARTNER_GAMERS_API_END,
    } );
  }
};

export const getExchangeTransactionAction = ( gamerId, param ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_GAMER_EXCHANGE_TRANSACTION_API_REQUEST,
      payload: API_TYPES.GET_EXCHANGE_TRANSACTION_LISTS,
    } );

    const response = await getPartnerGamerExchangeTransaction( gamerId, param );
    const transactionList = response.data.exchangeAssets.map(
      item => new PartnerTxnList( item )
    );
    // Filter gameNames and assetNames from the transactionList
    const gameNames = prepareLabelValueFormatData( transactionList, "name" );
    const assetNames = prepareLabelValueFormatData( transactionList, "assetName" );
    let qxPointMinMax = { min: 0, max: 0 };

    if( transactionList.length ) {
      // filter min and max QXPoints from the transactionList
      const minQxPoint = extremum( transactionList, "QXPoints", MIN_MAX.MIN );
      const maxQxPoint = extremum( transactionList, "QXPoints", MIN_MAX.MAX );

      qxPointMinMax = { min: minQxPoint.QXPoints, max: maxQxPoint.QXPoints };
    }

    dispatch( {
      type: PARTNER_GAMER_EXCHANGE_TRANSACTION_SUCCESS,
      payload: {
        transactionList,
        gameNames,
        assetNames,
        qxPointMinMax,
      },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: PARTNER_GAMER_EXCHANGE_TRANSACTION_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: PARTNER_GAMERS_API_END,
    } );
  }
};
