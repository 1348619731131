import { API_URL } from "../util/apiPaths";
import { request } from "./api.js";

export function updateAdminEmailRequest( params ) {
  return request( "PUT", API_URL.UPDATE_ADMIN_EMAIL_ADDRESS, params );
}

export function updatePartnerEmailRequest( params ) {
  return request( "PUT", API_URL.UPDATE_PARTNER_EMAIL_ADDRESS, params );
}

export const emailUpdateServices = {
  updateAdminEmailRequest,
  updatePartnerEmailRequest,
};
