import HelpIcon from "@material-ui/icons/Help";
import PropTypes from "prop-types";
import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
// local-imports
import constant from "../../../util/constant";

const { NUMBER_FORMATTING, BRANDING: { APP_SHORT_NAME } } = constant;

const Reward = ( {
  qxPoint,
  handleInputChange,
  onBlur,
  errorsQxPoint,
  usdPerQxPoint,
  partnerCostUsdPerQxPoint,
  sm,
} ) => (
  <>
    <Col sm={sm}>
      <Form.Group>
        <Form.Label className='label-with-icon'>
          { APP_SHORT_NAME } Points
          <OverlayTrigger placement='right' overlay={<Tooltip>What your gamer will be rewarded if they make all the achievements.</Tooltip>}>
            <HelpIcon />
          </OverlayTrigger>
        </Form.Label>
        <Form.Control
          type='number'
          value={qxPoint}
          name='qxPoint'
          onChange={handleInputChange}
          data-testid='qxPoint'
          min="0"
          onBlur={onBlur}
          maxLength={6}
        />
        <span className='form-text'>1 { APP_SHORT_NAME } Point = ${usdPerQxPoint}</span>
        <div className='invalid-feedback d-block'>
          {errorsQxPoint}
        </div>
      </Form.Group>
    </Col>
    <Col sm={sm}>
      <Form.Group>
        <Form.Label className='label-with-icon'>
                          Partner Cost ($)
          <OverlayTrigger placement='right' overlay={<Tooltip>What it costs you when the gamer earns the reward.</Tooltip>}>
            <HelpIcon />
          </OverlayTrigger>
        </Form.Label>
        <Form.Control type='number' disabled value={( qxPoint * partnerCostUsdPerQxPoint ).toFixed( NUMBER_FORMATTING.PARTNER_COST_DECIMAL_PLACES )} />
        <span className='form-text'>1 { APP_SHORT_NAME } Point = ${partnerCostUsdPerQxPoint}</span>
      </Form.Group>
    </Col>
  </>
);

Reward.propTypes = {
  qxPoint: PropTypes.number,
  handleInputChange: PropTypes.func,
  onBlur: PropTypes.func,
  errorsQxPoint: PropTypes.string,
  usdPerQxPoint: PropTypes.number,
  partnerCostUsdPerQxPoint: PropTypes.number,
  sm: PropTypes.number,
};

export default Reward;
