import constant from "../util/constant";
import { getOptInType } from "../util/helpers";
import { OfferAchievements } from "./offerAchievements";

const { OPT_IN_STATUS } = constant;

export class DraftOfferRequest extends OfferAchievements {
  constructor( obj ) {
    super( obj );
    /* eslint-disable camelcase */
    this.name = obj.offerName.trim();
    this.description = obj.rawDescription;
    this.description_text = obj.descriptionText;
    this.template = obj.offerTemplate;
    this.template_id = "";
    this.offerId = obj.offerId || "";
    this.isOffer = obj.isOffer;
    this.partnerId = obj.partnerId || "";
    this.offerSchedule = {
      game: obj.game,
      gamers: obj.gamers,
      game_id: obj.game.value,
      audience_id: obj.gamers?.value || "",
      start_date: obj.startDate,
      end_date: obj.endDate,
      max_reward_count_per_user: obj.offerCanBeAchievedCount || null,
      template_id: obj?.offerTemplate?.value || "",
    };
    this.reward = {
      qx_points: obj.qxPoint,
      partner_cost: obj.qxPoint * 0.012,
    };
    this.cloneOfferName = obj.cloneOfferName || "";
    this.cloneOfferId = obj.cloneOfferId || "";
    this.advertiseToGamer = obj.advertiseToGamer;
    this.advertise_to_gamer = obj.advertiseToGamer?.value || "";
    this.advertise_date = obj.advertiseDate || false;
    this.noOfDays = obj.noOfDays || 0;
    /* eslint-enable camelcase */

    if( obj.optInType === OPT_IN_STATUS.YES ) {
      this.optIn = {
        optInType: getOptInType( obj.optInType, Number( obj.entryFee ) ),
        membershipId: obj.membershipId,
        entryFee: obj.entryFee || null,
        minParticipants: obj.minParticipants || null,
        maxParticipants: obj.maxParticipants || null,
      };
    }
    else {
      this.optIn = {
        optInType: obj.optInType,
        membershipId: null,
        entryFee: null,
        minParticipants: null,
        maxParticipants: null,
      };
    }
  }
}
