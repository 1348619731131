import { debounce } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import Form from "react-bootstrap/Form";
import { useSetUrlParams } from "../../../util/helpers";

const SearchBox = ( {
  values,
  handleChange,
  addQuery,
  placeholder,
  title,
  locationState,
} ) => {
  const debouncedSave = useCallback(
    debounce( nextValue => handleChange( "searchKey", nextValue ), 1000 ),
    []
  );

  const searchHandle = ( e ) => {
    const { value: nextValue } = e.target;

    addQuery( useSetUrlParams( "searchKey", nextValue ), locationState );
    handleChange( "searchTempText", nextValue );
    debouncedSave( nextValue );
    handleChange( "page", 1 );
  };

  return (
    <>
      <Form.Group className="custom-search w-300 mr-0">
        <span className="icon-search"></span>
        <Form.Control
          type="text"
          placeholder={placeholder}
          data-testid='searchKey'
          onChange={searchHandle}
          value={values.searchTempText}
          name='searchKey'
          title={title}
        />
      </Form.Group>
    </>
  );
};

SearchBox.propTypes = {
  values: PropTypes.object,
  onSearch: PropTypes.func,
  handleChange: PropTypes.func,
  addQuery: PropTypes.func,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  locationState: PropTypes.string,
};

export default SearchBox;
