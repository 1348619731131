import PropTypes from "prop-types";
import React from "react";

import constant from "../../../util/constant";
import { useSetUrlParams } from "../../../util/helpers";
import partnerGameConstant from "../../../util/partnerGameConstant";

// Deconstructing location from globalThis. In the past,
// this would be window, but globalThis has been supported
// for several years as a replacement to window
const { location } = globalThis;

const { ORDER_BY, BRANDING: { APP_SHORT_NAME } } = constant;
const { GAME_LIST_SORT } = partnerGameConstant;

const AnalyticsGamesFilter = ( {
  addQuery,
  handleChange,
} ) => {
  return (
    <>
      <div className='th'>
        Game Icon
      </div>
      <div className='th with-sort'>
            Game Name
        <div className='sort-btn-box'>
          <span
            className='icon-up-arrow'
            onClick={() => {
              addQuery( useSetUrlParams( "sortBy", GAME_LIST_SORT.GAME_NAME ), location.state );
              addQuery( useSetUrlParams( "sortOrder", ORDER_BY.ASC ), location.state );
              handleChange( "sortBy", GAME_LIST_SORT.GAME_NAME );
              handleChange( "sortOrder", ORDER_BY.ASC );
            }}
            data-testid='game-name-sort-asc'
          ></span>
          <span
            className='icon-down-arrow'
            onClick={() => {
              addQuery( useSetUrlParams( "sortBy", GAME_LIST_SORT.GAME_NAME ), location.state );
              addQuery( useSetUrlParams( "sortOrder", ORDER_BY.DESC ), location.state );
              handleChange( "sortBy", GAME_LIST_SORT.GAME_NAME );
              handleChange( "sortOrder", ORDER_BY.DESC );
            }}
            data-testid='game-name-sort-desc'
          ></span>
        </div>
      </div>
      <div className='th with-sort'>
        Linked Gamers
        <div className='sort-btn-box'>
          <span
            className='icon-up-arrow'
            onClick={() => {
              addQuery( useSetUrlParams( "sortBy", GAME_LIST_SORT.LINKED_GAMERS ), location.state );
              addQuery( useSetUrlParams( "sortOrder", ORDER_BY.ASC ), location.state );
              handleChange( "sortBy", GAME_LIST_SORT.LINKED_GAMERS );
              handleChange( "sortOrder", ORDER_BY.ASC );
            }}
            data-testid='game-name-sort-asc'
          ></span>
          <span
            className='icon-down-arrow'
            onClick={() => {
              addQuery( useSetUrlParams( "sortBy", GAME_LIST_SORT.LINKED_GAMERS ), location.state );
              addQuery( useSetUrlParams( "sortOrder", ORDER_BY.DESC ), location.state );
              handleChange( "sortBy", GAME_LIST_SORT.LINKED_GAMERS );
              handleChange( "sortOrder", ORDER_BY.DESC );
            }}
            data-testid='game-name-sort-desc'
          ></span>
        </div>
      </div>
      <div className='th with-sort'>
        Premium Gamers
        <div className='sort-btn-box'>
          <span
            className='icon-up-arrow'
            onClick={() => {
              addQuery( useSetUrlParams( "sortBy", GAME_LIST_SORT.PREMIUM_GAMERS ), location.state );
              addQuery( useSetUrlParams( "sortOrder", ORDER_BY.ASC ), location.state );
              handleChange( "sortBy", GAME_LIST_SORT.PREMIUM_GAMERS );
              handleChange( "sortOrder", ORDER_BY.ASC );
            }}
            data-testid='game-name-sort-asc'
          ></span>
          <span
            className='icon-down-arrow'
            onClick={() => {
              addQuery( useSetUrlParams( "sortBy", GAME_LIST_SORT.PREMIUM_GAMERS ), location.state );
              addQuery( useSetUrlParams( "sortOrder", ORDER_BY.DESC ), location.state );
              handleChange( "sortBy", GAME_LIST_SORT.PREMIUM_GAMERS );
              handleChange( "sortOrder", ORDER_BY.DESC );
            }}
            data-testid='game-name-sort-desc'
          ></span>
        </div>
      </div>
      <div className='th with-sort'>
        Rewarded Value { APP_SHORT_NAME } Points (Offers)
        <div className='sort-btn-box'>
          <span
            className='icon-up-arrow'
            onClick={() => {
              addQuery( useSetUrlParams( "sortBy", GAME_LIST_SORT.OFFER_REWARD_VALUE ), location.state );
              addQuery( useSetUrlParams( "sortOrder", ORDER_BY.ASC ), location.state );
              handleChange( "sortBy", GAME_LIST_SORT.OFFER_REWARD_VALUE );
              handleChange( "sortOrder", ORDER_BY.ASC );
            }}
            data-testid='game-name-sort-asc'
          ></span>
          <span
            className='icon-down-arrow'
            onClick={() => {
              addQuery( useSetUrlParams( "sortBy", GAME_LIST_SORT.OFFER_REWARD_VALUE ), location.state );
              addQuery( useSetUrlParams( "sortOrder", ORDER_BY.DESC ), location.state );
              handleChange( "sortBy", GAME_LIST_SORT.OFFER_REWARD_VALUE );
              handleChange( "sortOrder", ORDER_BY.DESC );
            }}
            data-testid='game-name-sort-desc'
          ></span>
        </div>
      </div>
      <div className='th with-sort'>
        Rewarded Value { APP_SHORT_NAME } Points (Tournaments)
        <div className='sort-btn-box'>
          <span
            className='icon-up-arrow'
            onClick={() => {
              addQuery( useSetUrlParams( "sortBy", GAME_LIST_SORT.TOURNAMENT_REWARD_VALUE ), location.state );
              addQuery( useSetUrlParams( "sortOrder", ORDER_BY.ASC ), location.state );
              handleChange( "sortBy", GAME_LIST_SORT.TOURNAMENT_REWARD_VALUE );
              handleChange( "sortOrder", ORDER_BY.ASC );
            }}
            data-testid='game-name-sort-asc'
          ></span>
          <span
            className='icon-down-arrow'
            onClick={() => {
              addQuery( useSetUrlParams( "sortBy", GAME_LIST_SORT.TOURNAMENT_REWARD_VALUE ), location.state );
              addQuery( useSetUrlParams( "sortOrder", ORDER_BY.DESC ), location.state );
              handleChange( "sortBy", GAME_LIST_SORT.TOURNAMENT_REWARD_VALUE );
              handleChange( "sortOrder", ORDER_BY.DESC );
            }}
            data-testid='game-name-sort-desc'
          ></span>
        </div>
      </div>
      <div className='th with-sort'>
        Assets Exchanged Value { APP_SHORT_NAME } Points
        <div className='sort-btn-box'>
          <span
            className='icon-up-arrow'
            onClick={() => {
              addQuery( useSetUrlParams( "sortBy", GAME_LIST_SORT.ASSET_EXCHANGE_VALUE ), location.state );
              addQuery( useSetUrlParams( "sortOrder", ORDER_BY.ASC ), location.state );
              handleChange( "sortBy", GAME_LIST_SORT.ASSET_EXCHANGE_VALUE );
              handleChange( "sortOrder", ORDER_BY.ASC );
            }}
            data-testid='game-name-sort-asc'
          ></span>
          <span
            className='icon-down-arrow'
            onClick={() => {
              addQuery( useSetUrlParams( "sortBy", GAME_LIST_SORT.ASSET_EXCHANGE_VALUE ), location.state );
              addQuery( useSetUrlParams( "sortOrder", ORDER_BY.DESC ), location.state );
              handleChange( "sortBy", GAME_LIST_SORT.ASSET_EXCHANGE_VALUE );
              handleChange( "sortOrder", ORDER_BY.DESC );
            }}
            data-testid='game-name-sort-desc'
          ></span>
        </div>
      </div>
      <div className='th'>
        View
      </div>
    </>
  );
};

AnalyticsGamesFilter.propTypes = {
  addQuery: PropTypes.func,
  handleChange: PropTypes.func,
};

export default AnalyticsGamesFilter;
