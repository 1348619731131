export class AdminUserDetails {
  constructor( obj ) {
    this.adminId = obj.admin_id || "";
    this.fullName = obj.full_name || "";
    this.username = obj.username || "";
    this.email = obj.email || "";
    this.isActive = obj.is_active || false;
    this.createdAt = obj.createdAt || "";
    this.updatedAt = obj.updatedAt || "";
    this.permissions = obj.AdminPermissionMappings?.map( permission => permission.adminPermissionPermissionId ) || [];
    this.unreadNotificationsCount = obj.unreadNotificationsCount || 0;
    this.serverVersion = obj?.configuration?.serverVersion || "";
    this.usdPerQxPoint = obj?.configuration?.usdPerQxPoint;
    this.configuration = obj?.configuration || {};
    this.membershipTypes = obj?.configuration?.membershipTypes?.sort(
      ( a, b ) => ( Number( a.monthly_price ) - Number( b.monthly_price ) )
    );
    this.signoutWithoutConfirmation = obj?.signout_without_confirmation;
  }
}
