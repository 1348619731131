import React from "react";
import { Col } from "react-bootstrap";
import constant from "../../../../util/constant";

const { BRANDING: { APP_SHORT_NAME } } = constant;

const Gamers = () => {
  return (
    <>
      <Col sm={6}>
        <div className="tag-box">
          <div className="tag-box-inner">
            <div className="tag-content">
              <label className='label'>Total No. of Gamers</label>
              <div className='input-box'>20,000</div>
            </div>
          </div>
          <div className="tag-image">
          </div>
          <div className="tag-footer">
            <div className="tag-content">
              <div className="tag-title">7,650</div>
              <div className="tag-text">
                <span className='circle blue'></span>
                Non-{ APP_SHORT_NAME } members
              </div>
            </div>
            <div className="tag-content">
              <div className="tag-title">7,650</div>
              <div className="tag-text">
                <span className='circle green'></span>
                { APP_SHORT_NAME } Members
              </div>
            </div>
          </div>
        </div>
      </Col>
    </>
  );
};

export default Gamers;
