import PropTypes from "prop-types";
import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
// local-imports.
import { useForm } from "../../../hooks";
import constant from "../../../util/constant";
import { convertActualTime, formatNumberAsPerLocale } from "../../../util/helpers";
import offerConstant from "../../../util/offerConstant";
import Markdown from "../markDown";
import OfferScheduleDetail from "./offerScheduleDetail";

const { RANKING_OBJECTIVE } = offerConstant;
const { OPT_IN_STATUS, NUMBER_FORMATTING, BRANDING: { APP_SHORT_NAME } } = constant;

const OfferData = ( {
  offerDetail,
  configuration,
  partnerCostUsdPerQxPoint,
  isTournament = false,
  eligibleSubPlans,
} ) => {
  const { partnerCostUsdPerQxPoint: partnerCostUsdPerQxPointFromConfig = 0, usdPerQxPoint = 0 } = configuration;
  const partnerCost = partnerCostUsdPerQxPoint ?? partnerCostUsdPerQxPointFromConfig;
  const title = isTournament ? "Tournament" : "Offer";
  const { values, handleChange } = useForm(
    {
      showModal: false,
      rewards: [],
    }, {}
  );

  const closeModal = () => {
    handleChange( "showModal", false );
  };

  const viewAllRewards = ( rewards ) => {
    handleChange( "rewards", rewards );
    handleChange( "showModal", true );
  };

  return (
    <>
      <Row>
        <Col sm={6}>
          <div className="content-section">
            <div className="content-label">{title} Name</div>
            <div className="content-des">{offerDetail.offerName}</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <div className="content-section">
            <div className="content-label">{title} Description to Promote to Players</div>
            <div className="content-des inner-content"><Markdown text={offerDetail.rawDescription}></Markdown></div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <div className="content-section mb-20">
            <div className="content-label font-medium ">Achievement</div>
            <div className="divider m-0"></div>
          </div>
        </Col>
      </Row>
      {offerDetail.achievements?.map( ( achievement, index ) => (
        <Row key={achievement.offerId}>
          {index !== 0 && (
            <Col sm={12} className='pb-3 mb-2'>
              <Button variant='secondary btn-and' disabled>AND</Button>
            </Col>
          )}
          <Col sm={12}>
            <div className="border-container pb-3">
              <Col sm={12}>
                <div className="content-section">
                  <div className="content-label">Achievement Name</div>
                  <div className="content-des">{achievement.name}</div>
                </div>
              </Col>
              <Col sm={12}>
                <div className="content-section">
                  <div className="content-label">Description</div>
                  <div className="content-des inner-content"><Markdown text={achievement.rawDescription}></Markdown></div>
                </div>
              </Col>
              <Col sm={12}>
                <div className="content-section mb-20">
                  <div className="content-label font-medium ">Events</div>
                  <div className="divider m-0"></div>
                </div>
              </Col>
              {achievement.events?.map( ( event, eventIndex ) => (
                <div key={event.value} className="container">
                  <Col className="border-container mb-3">
                    <Row>
                      <Col sm={12}>
                        <div className="content-section pl-2">
                          <div className="content-label">Event Name</div>
                          <div className="content-des">{event.label}</div>
                        </div>
                      </Col>
                      <Col sm={12}>
                        <div className="content-section pl-2">
                          <div className="content-label">Description</div>
                          <div className="content-des">{achievement.eventDescriptions[ eventIndex ]}</div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </div>
              ) )}
              {!isTournament && <Col sm={12}>
                <div className="content-section">
                  <div className="content-label">Event Units</div>
                  <div className="content-des">{achievement.eventUnit}</div>
                </div>
              </Col>}
              <Col sm={12}>
                <Row>
                  <Col sm={4}>
                    <div className="content-section">
                      <div className="content-label">Function</div>
                      <div className="content-des">{achievement.function.label}</div>
                    </div>
                  </Col>
                  {!isTournament && <><Col sm={4}>
                    <div className="content-section">
                      <div className="content-label">Comparison</div>
                      <div className="content-des">{achievement.comparison.label}</div>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content-section">
                      <div className="content-label">Value</div>
                      <div className="content-des">{achievement.value}</div>
                    </div>
                  </Col></>}
                  {isTournament && <Col sm={4}>
                    <div className="content-section">
                      <div className="content-label">Ranking Objective</div>
                      <div className="content-des">
                        { RANKING_OBJECTIVE.find( obj => obj.value === achievement.rankingObjective )?.label ?? "N/A" }
                      </div>
                    </div>
                  </Col>}
                </Row>
              </Col>
              {!isTournament && <Col sm={12} className="hide-tooltip">
                <Row>
                  <Col sm={3}>
                    <div className="content-section">
                      <div className="content-label">Days</div>
                      <div className="content-des">{formatNumberAsPerLocale( achievement.days )}</div>
                    </div>
                  </Col>
                  <Col sm={3}>
                    <div className="content-section">
                      <div className="content-label">Hours</div>
                      <div className="content-des">{achievement.hours}</div>
                    </div>
                  </Col>
                  <Col sm={3}>
                    <div className="content-section">
                      <div className="content-label">Minutes</div>
                      <div className="content-des">{achievement.minutes}</div>
                    </div>
                  </Col>
                  <Col sm={3}>
                    <div className="content-section">
                      <div className="content-label">Seconds</div>
                      <div className="content-des">{achievement.seconds}</div>
                    </div>
                  </Col>
                  <Col sm={12}>
                    <div className="content-section">
                      <div className="content-des">{convertActualTime( {
                        days: achievement.days,
                        hours: achievement.hours,
                        minutes: achievement.minutes,
                        seconds: achievement.seconds,
                      } ).actualTime}</div>
                    </div>
                  </Col>
                </Row>
              </Col>}
            </div>
          </Col>
        </Row>
      ) )}
      {!isTournament && <Row>
        <Col sm={12}>
          <div className="content-section mb-0">
            <div className="content-label font-medium">Rewards</div>
          </div>
        </Col>
        <Col sm={4}>
          <div className="content-section">
            <div className="content-label">{ APP_SHORT_NAME } Points ($)</div>
            <div className="content-des">{formatNumberAsPerLocale( offerDetail.qxPoint )}</div>
            <div className="help-text">1 { APP_SHORT_NAME } Point = ${usdPerQxPoint }</div>
          </div>
        </Col>
        <Col sm={4}>
          <div className="content-section">
            <div className="content-label">Partner Cost ($)</div>
            <div className="content-des">{formatNumberAsPerLocale( ( offerDetail.qxPoint * partnerCost ).toFixed( NUMBER_FORMATTING.PARTNER_COST_DECIMAL_PLACES ) )}</div>
            <div className="help-text">1 { APP_SHORT_NAME } Point = ${partnerCost}</div>
          </div>
        </Col>
      </Row>
      }
      {isTournament && <Row>
        <Col sm={12}>
          <div className="content-section mb-0">
            <div className="content-label font-medium">Rewards</div>
          </div>
        </Col>
        {
          ( offerDetail?.rewards?.length > 0 ) &&
          // TODO: refactor so all exits of this function return something
          // This might simply be returning null at the end, but needs validation
          // eslint-disable-next-line consistent-return
              ( offerDetail.rewards.forEach( ( rewards, index ) => {
                if( index < 5 ) {
                  return <Col sm={2} key={index}><div className="content-section">
                    <div className="content-label">{rewards.place}</div>
                    <div className="content-des">{formatNumberAsPerLocale( rewards.qxPoint )}</div>
                    <div className="help-text">{ APP_SHORT_NAME } Point</div>
                  </div>
                  </Col>;
                }
              } )
              )
        }
        {offerDetail?.rewards?.length > 5 && <div onClick={() => viewAllRewards( offerDetail.rewards )} className="show-link" title="Show All">
                    Show All
        </div>}
      </Row>
      }
      <Row>
        <Col sm={12}>
          <div className="content-section mb-4">
            <div className="content-label font-medium page-inner-heading">
              Opt In
            </div>
          </div>
        </Col>
        <Col sm={12}>
          <div className="content-section">
            <div className="content-label font-medium">Opt-in required?</div>
            <div className="content-des">{offerDetail?.optIn?.optInType}</div>
          </div>
        </Col>
        { ( offerDetail?.optIn?.optInType === OPT_IN_STATUS.YES ) && (
          <>
            <Col sm={12}>
              <div className="content-section">
                <div className="content-label font-medium">Eligible Subscription Plan</div>
                <div>
                  { eligibleSubPlans?.map( ( plan, index ) => (
                    <span className="badge badge-primary mr-1" key={index}>
                      {plan.name}
                    </span>
                  ) ) }
                </div>
              </div>
            </Col>
            { ( offerDetail?.optIn?.entryFee > 0 ) && (
              <Col sm={12}>
                <div className="content-section">
                  <div className="content-label">Entry Fee</div>
                  <div className="content-des mb-2">{ APP_SHORT_NAME } Points</div>
                  <div className="content-des">{formatNumberAsPerLocale( offerDetail?.optIn?.entryFee )}</div>
                </div>
              </Col>
            ) }
          </>
        ) }
      </Row>
      { ( offerDetail?.optIn?.optInType === OPT_IN_STATUS.YES ) && (
        <Row>
          <Col sm={4}>
            <div className="content-section">
              <div className="content-label font-medium">Minimum No. of Participants</div>
              <div className="content-des">
                {
                  formatNumberAsPerLocale( offerDetail?.optIn?.minParticipants, "No Minimum" )
                }
              </div>
            </div>
          </Col>
          <Col sm={4}>
            <div className="content-section">
              <div className="content-label font-medium">Maximum No. of Participants</div>
              <div className="content-des">
                {
                  formatNumberAsPerLocale( offerDetail?.optIn?.maxParticipants, "No Maximum" )
                }
              </div>
            </div>
          </Col>
        </Row>
      ) }
      {offerDetail.offerSchedule && !offerDetail.hideOfferSchedule && (
        <OfferScheduleDetail offerScheduleDetail={offerDetail.offerSchedule} offerDetail={offerDetail}
          isTournament={isTournament}
          title={title}
        />
      )}
      <Modal
        show={values.showModal}
        onHide={closeModal}
        centered
        className='gamer-cashback-modal'
      >
        <Modal.Header>
          <h2 className='modal-heading'>Rewards</h2>
        </Modal.Header>
        <Modal.Body>
          <div className='gamer-game-list scrollbar'>
            {Boolean( values?.rewards?.length ) && values.rewards?.map( ( data, index ) => (
              <div key={index} className='list-items rewards-list-items'>
                <span className='game-count'>{index + 1}</span>
                <span className='game-name'>{data.qxPoint} { APP_SHORT_NAME } Points</span>
              </div>
            ) )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className='btn-md' onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

OfferData.propTypes = {
  offerDetail: PropTypes.object,
  configuration: PropTypes.object,
  partnerCostUsdPerQxPoint: PropTypes.number,
  isTournament: PropTypes.bool,
  activeTab: PropTypes.string,
  onChangeTab: PropTypes.func,
  isAdmin: PropTypes.bool,
  eligibleSubPlans: PropTypes.array,
};

export default OfferData;
