import { API_URL } from "../util/apiPaths";
import { request } from "./api.js";

export const getExchangeTransaction = ( params ) => {
  return request( "GET", API_URL.PARTNER_EXCHANGE_TRANSACTION, params );
};

export const getGamerLists = ( params ) => {
  return request( "GET", API_URL.PARTNER_GAMERS, params );
};

export function getGameDetails( params, getOffers, entityType ) {
  const queryParams = { ...getOffers, offerType: entityType };

  return request( "GET", `${ API_URL.PARTNER_GAMES }/${ params }`, queryParams );
}

export const getAssetsList = ( params, dataIds ) => {
  return request( "GET", API_URL.PARTNER_ASSETS.replace( ":gamerId", dataIds.gamerId ).replace( ":gameId", dataIds.gameId ), params );
};

