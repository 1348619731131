import InfoIcon from "@material-ui/icons/Info";
import PropTypes from "prop-types";
import React from "react";
import { Col, OverlayTrigger, Popover, Row } from "react-bootstrap";

import constant from "../../../util/constant";
import { currencyFormat } from "../../../util/helpers";

const { NUMBER_FORMATTING } = constant;

const RewardPointsCostTooltip = ( {
  rewardPoints,
  usdPerQxPoint,
  partnerCostUsdPerQxPoint,
} ) => {
  return (
    <>
      <OverlayTrigger placement='auto'
        overlay={
          <Popover className='opt-in-popover'>
            <Popover.Content>
              <Row>
                <Col sm={6}>
                  <div className='pop-title'>$ to the Gamer</div>
                  <strong className='text-green'>
                    {currencyFormat( { data: usdPerQxPoint * rewardPoints } )}
                  </strong>
                </Col>
                <Col sm={6}>
                  <div className='pop-title'>$ cost to the Partner</div>
                  <strong className='text-green'>
                    {currencyFormat( {
                      data: partnerCostUsdPerQxPoint * rewardPoints,
                      minimumFractionDigits: NUMBER_FORMATTING.PARTNER_COST_DECIMAL_PLACES,
                    } )}
                  </strong>
                </Col>
              </Row>
            </Popover.Content>
          </Popover>
        }
      >
        <InfoIcon className='ml-1' />
      </OverlayTrigger>
    </>
  );
};

RewardPointsCostTooltip.propTypes = {
  rewardPoints: PropTypes.number,
  usdPerQxPoint: PropTypes.number,
  partnerCostUsdPerQxPoint: PropTypes.number,
};

export default RewardPointsCostTooltip;
