import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import constant from "../../../../util/constant";
import { useSetUrlParams } from "../../../../util/helpers";
import AnalyticsTxnFilters from "../../../shared/analytics/analyticsTxnFilters";
import CustomSlider from "../../../shared/customSlider";
import MultiSelectCheckbox from "../../../shared/multiSelectCheckbox";

const { BRANDING: { APP_SHORT_NAME } } = constant;

const OptInTxnFilters = ( {
  values,
  handleChange,
  addQuery,
  gamerNames,
  gameNames,
  offerNames,
  transactionFor,
  transactionRanges,
  isGameId,
  locationState,
  tab,
  handleFilterChange,
  clearSelectedFilter,
} ) => {
  const handleSelect = ( filterName, obj ) => {
    handleFilterChange( filterName, obj, handleChange, addQuery, locationState );
  };

  const multiSelectedItems = useMemo( () => [ values.gameIds, values.transactionFor, values.gamerName, values.offerNames ] );

  const onSelectFilterOptions = useCallback(
    [ obj => handleSelect( "gameIds", obj ),
      obj => handleSelect( "transactionFor", obj ),
      obj => handleSelect( "gamerName", obj ),
      obj => handleSelect( "offerNames", obj ),
    ],
    []
  );

  const clearAllSelectedOptions = useCallback(
    params => clearSelectedFilter( params, addQuery, handleChange, locationState ), []
  );

  const rewardTransactionPointsHandler = ( e, newValue ) => {
    addQuery( useSetUrlParams( "minQxPoint", newValue[ 0 ] ), locationState, isGameId );
    addQuery( useSetUrlParams( "maxQxPoint", newValue[ 1 ] ), locationState, isGameId );
    handleChange( "minQxPoint", newValue[ 0 ] );
    handleChange( "maxQxPoint", newValue[ 1 ] );
  };

  return (
    <>
      <div className="filter-boxes trans-exchange-filter">
        <Row>
          <Col>
            <Form.Group className='custom-multiselect'>
              <Form.Label>Game Name</Form.Label>
              <MultiSelectCheckbox
                multiOptionsList={gameNames}
                handleSelectChange={onSelectFilterOptions[ 0 ]}
                multiSelectedItems={multiSelectedItems[ 0 ]}
                handleClearMultiSelect={() => clearAllSelectedOptions( "gameIds" )}/>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='custom-multiselect'>
              <Form.Label>Transaction For</Form.Label>
              <MultiSelectCheckbox
                multiOptionsList={transactionFor}
                handleSelectChange={onSelectFilterOptions[ 1 ]}
                multiSelectedItems={multiSelectedItems[ 1 ]}
                handleClearMultiSelect={() => clearAllSelectedOptions( "transactionFor" )}/>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='custom-multiselect'>
              <Form.Label>Offer/Tournament Name</Form.Label>
              {<MultiSelectCheckbox
                multiOptionsList={offerNames}
                handleSelectChange={onSelectFilterOptions[ 3 ]}
                multiSelectedItems={multiSelectedItems[ 3 ]}
                handleClearMultiSelect={() => clearAllSelectedOptions( "offerNames" )}/>}
            </Form.Group>
          </Col>
          <Col>
            <CustomSlider
              values={values}
              pointsHandler={rewardTransactionPointsHandler}
              handleChange={handleChange}
              min={transactionRanges.min}
              max={transactionRanges.max}
              minName={"minQxPoint"}
              maxName={"maxQxPoint"}
              label={`${ APP_SHORT_NAME } Points`}
            />
          </Col>
          <AnalyticsTxnFilters
            tab={tab}
            values={values}
            handleChange={handleChange}
            addQuery={addQuery}
            gamerNames={gamerNames}
            isGameId={isGameId}
            locationState={locationState}
            onSelectFilterOptions={onSelectFilterOptions}
            multiSelectedItems={multiSelectedItems}
            clearAllSelectedOptions={clearAllSelectedOptions}
          />
        </Row>
      </div>
    </>
  );
};

OptInTxnFilters.propTypes = {
  values: PropTypes.object,
  onSearch: PropTypes.func,
  handleChange: PropTypes.func,
  addQuery: PropTypes.func,
  gamerNames: PropTypes.array,
  gameNames: PropTypes.array,
  transactionRanges: PropTypes.object,
  offerNames: PropTypes.array,
  transactionFor: PropTypes.array,
  onCancel: PropTypes.func,
  isGamerNames: PropTypes.bool,
  isGameId: PropTypes.bool,
  locationState: PropTypes.string,
  tab: PropTypes.string,
  handleFilterChange: PropTypes.func,
  clearSelectedFilter: PropTypes.func,
};

export default OptInTxnFilters;
