import { Slider } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { memo } from "react";
import Form from "react-bootstrap/Form";

const CustomSlider = ( {
  values,
  pointsHandler,
  handleChange,
  min,
  max,
  minName,
  maxName,
  label,
  step = 1,
} ) => {
  const handleInputChange = ( e ) => {
    const { name, value } = e.target;

    if( value ) {
      const key = e.target.getAttribute( "data-attr" );

      if( e.target.getAttribute( "extremum" ) === "min" ) {
        pointsHandler( e, [ value, values[ key ] ] );
      }
      else {
        pointsHandler( e, [ values[ key ], value ] );
      }
    }

    handleChange( name, value );
  };

  return (
    <>
      <Form.Label>{label}</Form.Label>
      <div className='slider-container slider-with-input'>
        <Form.Control
          type='number'
          value={ values[ minName ] }
          name={minName}
          maxLength={45}
          onChange={handleInputChange}
          extremum={"min"}
          data-attr={maxName}
          placeholder={"Min"}
          title={Number( values[ minName ] )}
          min={0}
        />
        <Slider
          value={[ Number( values[ minName ] ), Number( values[ maxName ] ) ]}
          onChange={pointsHandler}
          min={min}
          max={max}
          valueLabelDisplay="auto"
          step={step}
        />
        <Form.Control
          type='number'
          value={ values[ maxName ] }
          name={maxName}
          maxLength={45}
          onChange={handleInputChange}
          extremum={"max"}
          data-attr={minName}
          placeholder={"Max"}
          title={Number( values[ maxName ] )}
          min={0}
        />
      </div>
    </>
  );
};

CustomSlider.propTypes = {
  values: PropTypes.object,
  pointsHandler: PropTypes.func,
  handleChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  minName: PropTypes.string,
  maxName: PropTypes.string,
  label: PropTypes.string,
  step: PropTypes.number,
};

export default memo( CustomSlider );
