import PropTypes from "prop-types";
import React, { memo } from "react";
import { Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import constant from "../../../util/constant";
import { getBackNavigationRoute } from "../../../util/helpers";
import FilterIcon from "../../shared/FilterIcon/FilterIcon";
import BackButton from "../../shared/backButton";
import ProfileImage from "../../shared/profileImage";

const { ROUTES } = constant;

const AdminAssetsHeader = ( {
  searchHandle,
  searchText,
  showFilter,
  showFilterScreen,
  isFilterApplied,
  gamerDetail,
} ) => {
  const location = useLocation();
  const backScreens = [ "adminGamerDetail" ];

  const handleBackNavigation = () => {
    return getBackNavigationRoute( ROUTES.GAMER_SECTION, location, backScreens );
  };

  return (
    <>
      <BackButton
        backLink={handleBackNavigation()}
        state={location.state} />
      <div className="page-head-wrapper">
        <div className="d-flex align-items-center">
          <ProfileImage profileImage={gamerDetail?.profileImage} />
          <div className="profile-title mb-0">{gamerDetail.gamerFullName}</div>
        </div>
        <div className="page-head-right">
          <Form.Group className="custom-search w-300">
            <span className="icon-search"></span>
            <Form.Control
              type="text"
              placeholder="Search by Asset and Game Name"
              data-testid="searchKeyTest"
              onChange={searchHandle}
              value={searchText}
              name="searchKey"
            />
          </Form.Group>
          <FilterIcon
            showFilter={showFilter}
            showFilterScreen={showFilterScreen}
            isFilterApplied={isFilterApplied} />
        </div>
      </div>
      <div className="page-head-wrapper">
        <h4 className="page-heading">Assets</h4>
      </div>
    </>
  );
};

AdminAssetsHeader.propTypes = {
  searchHandle: PropTypes.func,
  searchText: PropTypes.string,
  isFilterApplied: PropTypes.func,
  showFilterScreen: PropTypes.func,
  showFilter: PropTypes.string,
  gamerDetail: PropTypes.object,
};

export default memo( AdminAssetsHeader );
