import PropTypes from "prop-types";
import React from "react";
import Form from "react-bootstrap/Form";
import FilterIcon from "../../shared/FilterIcon/FilterIcon";

const SearchHeader = ( { values, searchHandle, className, showFilter, showFilterScreen, isFilterApplied } ) => {
  return (
    <>
      <div className='d-flex justify-content-end mb-3 pos-relative'>
        <Form.Group className={`custom-search ${ className }`}>
          <span className='icon-search'></span>
          <Form.Control
            type='text'
            placeholder={"Search by Gamer's Name"}
            onChange={searchHandle}
            value={values.searchDummyText}
            data-testid='searchKey'
          />
        </Form.Group>
        <FilterIcon
          showFilter={showFilter}
          showFilterScreen={showFilterScreen}
          isFilterApplied={isFilterApplied} />
      </div>
    </>
  );
};

SearchHeader.propTypes = {
  values: PropTypes.object,
  searchHandle: PropTypes.func,
  className: PropTypes.string,
  showFilter: PropTypes.string,
  showFilterScreen: PropTypes.func,
  isFilterApplied: PropTypes.func,
};

export default SearchHeader;
