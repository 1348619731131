import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useCallback, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Row from "react-bootstrap/Row";
import Tooltip from "react-bootstrap/Tooltip";
import { Scrollbars } from "react-custom-scrollbars";
import { useSelector, useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useHistory, useLocation } from "react-router-dom";
import { useForm, useAddQuery, useModal, useScroll } from "../../../hooks";
import { getAdminFilterListAction } from "../../../store/actions/adminFilterList";
import { getAdminOffersAction, deleteAdminOfferAction } from "../../../store/actions/adminOffers";
import adminConstant from "../../../util/adminConstant";
import constant from "../../../util/constant";
import { useSetUrlParams, concatStrings, checkFilterApplied, handleParseJSON, getEligibleSubscriptionPlans } from "../../../util/helpers";
import offerConstant from "../../../util/offerConstant";
import { adminOfferTableConfigs } from "../../../util/tableConstant";
import ModalPopup from "../../partner/modal";
import OptInDetails from "../../partner/offers/optInDetails";
import FilterIcon from "../../shared/FilterIcon/FilterIcon";
import { ScrollPage } from "../../shared/customScrollBar";
import MultiSelectCheckbox from "../../shared/multiSelectCheckbox";
import TableHeaderRow from "../../shared/tableHeadingRow";

const {
  ROUTES,
  ORDER_BY,
  API_STATUS,
  FILTERS_NAME,
  MESSAGE: { REMOVE_OFFER_DRAFT_SUCCESS_MESSAGE, REMOVE_OFFER_TEMPLATE_SUCCESS_MESSAGE },
} = constant;
const { OFFER_DETAIL_STATUS, OFFER_TYPE_STATUS, OFFER_STATUS_DROPDOWN, OFFER_API_TYPES, TYPE_TO_BE_EDITED, OFFER_API_STATUS, OPT_IN_STATUS_DROPDOWN } = offerConstant;
const { MODAL_TYPE_DETAIL } = adminConstant;

// TODO: refactor to avoid complexity
// eslint-disable-next-line complexity
const AdminOfferList = () => {
  const ref = useRef( null );
  const history = useHistory();
  const { scrollEvent } = useScroll();
  const dispatch = useDispatch();
  const location = useLocation();
  const isFilterApplied = checkFilterApplied( window.location.search, FILTERS_NAME.OFFERS );
  const { offers, offersMetaData, apiType, status, offerList, partnerList, gameOfferList, configuration } = useSelector( state => (
    {
      offers: state.adminOffer.adminOffers.offers,
      offersMetaData: state.adminOffer.adminOffers.meta,
      apiType: state.adminOffer.apiType,
      status: state.adminOffer.status,
      offerList: state.adminFilterList.filterData.offerList,
      partnerList: state.adminFilterList.filterData.partnerList,
      gameOfferList: state.adminFilterList.filterData.gameOfferList,
      configuration: state.adminDetail.data?.configuration || {},
    } ) );
  const { addQuery } = useAddQuery();
  const paramsQuery = new URLSearchParams( window.location.search );
  const { values, handleChange } = useForm(
    {
      perPage: 10,
      searchDummyText: paramsQuery.get( "searchKey" ) === "" ? "" : paramsQuery.get( "searchKey" ),
      searchKey: paramsQuery.get( "searchKey" ) === "" ? "" : paramsQuery.get( "searchKey" ),
      sortBy: paramsQuery.get( "sortColumn" ) === "" ? "name" : paramsQuery.get( "sortColumn" ),
      sortOrder: paramsQuery.get( "sortOrder" ) === "" ? ORDER_BY.ASC : paramsQuery.get( "sortOrder" ),
      page: 1,
      offerList: paramsQuery.get( "offerList" ) === "" ? [] : handleParseJSON( paramsQuery.get( "offerList" ) ),
      partnerList: paramsQuery.get( "partnerList" ) === "" ? [] : handleParseJSON( paramsQuery.get( "partnerList" ) ),
      statusList: paramsQuery.get( "statusList" ) === "" ? [] : handleParseJSON( paramsQuery.get( "statusList" ) ),
      gameOfferList: paramsQuery.get( "gameOfferList" ) === "" ? [] : handleParseJSON( paramsQuery.get( "gameOfferList" ) ),
      optInTypes: paramsQuery.get( "optInTypes" ) === "" ? [] : handleParseJSON( paramsQuery.get( "optInTypes" ) ),
    },
    {}
  );
  const { isOpened, closeModal, openModal, modalType, setModalType, modalData, setModalData } = useModal();
  const requestParams = Object.assign(
    {
      sortOrder: values.sortOrder,
      perPage: values.perPage,
      page: values.page,
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      offerIds: concatStrings( values.offerList, "," ) || undefined,
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      gameIds: concatStrings( values.gameOfferList, "," ) || undefined,
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      partnerIds: concatStrings( values.partnerList, "," ) || undefined,
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      status: concatStrings( values.statusList, "," ) || undefined,
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      optInTypes: concatStrings( values.optInTypes, "," ) || undefined,
      currentTimeZone: new Date().toUTCString(),
      offerTypes: [ OFFER_API_STATUS.OFFER, OFFER_API_STATUS.OFFER_TEMPLATE, OFFER_API_STATUS.DRAFT ].join( "," ),
    },
    values.searchKey && { searchKey: values.searchKey },
    values.sortBy && { sortBy: values.sortBy }
  );

  useEffect( () => {
    if(
      status === API_STATUS.SUCCESS &&
      apiType === OFFER_API_TYPES.GET_ADMIN_OFFERS
    ) {
      dispatch( getAdminFilterListAction( { entities: "Offer,Partner,GameOffer" } ) );
    }

    if( ( status === API_STATUS.SUCCESS ) &&
      ( apiType === OFFER_API_TYPES.DELETE_ADMIN_OFFER )
    ) {
      toastr.success( modalType === MODAL_TYPE_DETAIL.REMOVE_DRAFT.type ? REMOVE_OFFER_DRAFT_SUCCESS_MESSAGE : REMOVE_OFFER_TEMPLATE_SUCCESS_MESSAGE );
    }
  }, [ status, apiType ] );

  useEffect( () => {
    dispatch( getAdminOffersAction( requestParams ) );
  }, [
    values.sortOrder,
    values.page,
    values.sortBy,
    values.searchKey,
    values.offerList,
    values.gameOfferList,
    values.partnerList,
    values.statusList,
    values.optInTypes,
  ] );

  const onScroll = ( e ) => {
    scrollEvent( e, offers, values, handleChange, offersMetaData, "page" );
  };

  const handleSorting = ( sortBy, sortingType ) => {
    handleChange( "sortBy", sortBy );
    handleChange( "sortOrder", sortingType );
    addQuery( useSetUrlParams( "sortColumn", sortBy ) );
    addQuery( useSetUrlParams( "sortOrder", sortingType ) );
    handleChange( "page", 1 );
  };

  const searchHandle = ( e ) => {
    const { value: nextValue } = e.target;

    addQuery( useSetUrlParams( "searchKey", nextValue ) );
    handleChange( "searchDummyText", nextValue );
    debouncedSave( nextValue );
    handleChange( "page", 1 );
  };

  const debouncedSave = useCallback(
    debounce( nextValue => handleChange( "searchKey", nextValue ), 1000 ),
    []
  );

  const memoizedTableConfig = useMemo( () => {
    return adminOfferTableConfigs;
  }, [] );


  const memoizedHandleClick = useCallback(
    ( sortBy, orderType ) => handleSorting( sortBy, orderType ),
    []
  );

  const onClickViewAndEdit = ( offer, route ) => {
    let statusParams = "";

    if( offer.approvalStatus === OFFER_DETAIL_STATUS.DRAFT ) {
      statusParams = `?status=${ OFFER_TYPE_STATUS.DRAFT }&from=offers`;
    }
    else {
      statusParams = "?from=offers";
    }

    history.push( {
      pathname: `${ route.replace( ":offerId", offer.offerId ) }${ statusParams }`,
      state: [
        { from: "offers", path: location.pathname, state: location.search },
      ],
    } );
  };

  const multiSelectedItems = useMemo( () => [ values.offerList, values.gameOfferList, values.partnerList, values.statusList, values.optInTypes ] );

  const handleSelectChange = useCallback(
    [
      obj => listHandle( obj, "offerList" ),
      obj => listHandle( obj, "gameOfferList" ),
      obj => listHandle( obj, "partnerList" ),
      obj => listHandle( obj, "statusList" ),
      obj => listHandle( obj, "optInTypes" ),
    ],
    []
  );
  const handleClearMultiSelect = useCallback(
    parmas => clearHandle( parmas ), []
  );

  const listHandle = ( obj, listName ) => {
    if( obj.length > 0 ) {
      handleChange( "page", 1 );
      handleChange( listName, obj );
      addQuery( useSetUrlParams( listName, JSON.stringify( obj ) ) );
    }
    else {
      addQuery( useSetUrlParams( listName, "" ) );
      handleChange( listName );
    }
  };

  const clearHandle = ( parmas ) => {
    if( parmas ) {
      addQuery( useSetUrlParams( parmas, "" ) );
      handleChange( parmas, [] );
    }
  };

  const handleOpenModal = ( mType, data ) => {
    setModalType( mType );
    setModalData( data );
    openModal();
  };

  const handleDeleteOfferTemplate = () => {
    dispatch( deleteAdminOfferAction( modalData ) );
    closeModal();
  };

  const [ showFilter, setShowFilter ] = useState( false );
  const showFilterScreen = () => setShowFilter( !showFilter );

  return (
    <>
      <div className='main-wrapper'>
        <div className='main-right-wrapper'>
          <div className='page-head-wrapper'>
            <h4 className='page-heading'>Offers</h4>
            <div className='page-head-right'>
              <Form.Group className='custom-search w-300'>
                <span className='icon-search'></span>
                <Form.Control
                  type='text'
                  placeholder='Search by Offer Name, Game...'
                  onChange={searchHandle}
                  name='searchKey'
                  data-testid='searchKey'
                  value={values.searchDummyText}
                  title='Search by Offer Name, Description, Game, and Partner'
                />
              </Form.Group>
              <Button
                variant='primary'
                className='small-btn'
                onClick={() => history.push( ROUTES.ADMIN_ADD_EDIT_OFFER.replace( ":offerId", 0 ) )}
                data-testid='addOfferKey'
              >
                <span className='icon-circle-plus-green icon'></span>
                Add Offer Template
              </Button>
              <FilterIcon
                showFilter={showFilter}
                showFilterScreen={showFilterScreen}
                isFilterApplied={isFilterApplied} />
            </div>
          </div>
          <div className={`filter-boxes mr-0 bottom-margin ${ !showFilter ? "" : "remove-filter" }`}>
            <Row>
              <Col>
                <Form.Group className='custom-multiselect mb-0'>
                  <Form.Label>Offer Name</Form.Label>
                  <MultiSelectCheckbox
                    multiOptionsList={offerList}
                    handleSelectChange={handleSelectChange[ 0 ]}
                    multiSelectedItems={multiSelectedItems[ 0 ]}
                    handleClearMultiSelect={() => handleClearMultiSelect( "offerList" )} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='custom-multiselect mb-0'>
                  <Form.Label>Game</Form.Label>
                  <MultiSelectCheckbox
                    multiOptionsList={gameOfferList}
                    handleSelectChange={handleSelectChange[ 1 ]}
                    multiSelectedItems={multiSelectedItems[ 1 ]}
                    handleClearMultiSelect={() => handleClearMultiSelect( "gameOfferList" )} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='custom-multiselect mb-0'>
                  <Form.Label>Partner</Form.Label>
                  <MultiSelectCheckbox
                    multiOptionsList={partnerList}
                    handleSelectChange={handleSelectChange[ 2 ]}
                    multiSelectedItems={multiSelectedItems[ 2 ]}
                    handleClearMultiSelect={() => handleClearMultiSelect( "partnerList" )} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='custom-multiselect mb-0'>
                  <Form.Label>Status</Form.Label>
                  <MultiSelectCheckbox
                    multiOptionsList={OFFER_STATUS_DROPDOWN}
                    handleSelectChange={handleSelectChange[ 3 ]}
                    multiSelectedItems={multiSelectedItems[ 3 ]}
                    handleClearMultiSelect={() => handleClearMultiSelect( "statusList" )} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='custom-multiselect mb-0'>
                  <Form.Label>Opt In</Form.Label>
                  <MultiSelectCheckbox
                    multiOptionsList={ OPT_IN_STATUS_DROPDOWN }
                    handleSelectChange={handleSelectChange[ 4 ]}
                    multiSelectedItems={multiSelectedItems[ 4 ]}
                    handleClearMultiSelect={() => handleClearMultiSelect( "optInTypes" )}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div className='table-container offer-partner-table'>
            <div className='table-head'>
              <div className='table-row'>
                <TableHeaderRow
                  configData={memoizedTableConfig}
                  onAscOrder={memoizedHandleClick}
                  onDescOrder={memoizedHandleClick}
                />
              </div>
            </div>
            <Scrollbars
              renderThumbVertical={ScrollPage}
              className={`custom-scroll-height ${ !showFilter ? "" : "full-height" }`}
              onScroll={onScroll}>
              <div className='table-body no-scrollbar' >
                {Boolean( offers.length ) && offers.map( ( obj, index ) => (
                  <div className='table-row' key={index}>
                    <div className='td'>
                      <span className="strong text-break">{obj.offerName}</span>
                    </div>
                    <div className='td' ref={ref}>
                      <OverlayTrigger
                        container={ref}
                        placement='auto'
                        overlay={<Tooltip className="event-tooltip">{obj.descriptionText}</Tooltip>}
                      >
                        <span className='text-truncate'>{obj.descriptionText}</span>
                      </OverlayTrigger>
                    </div>
                    <div className='td'>
                      {obj.gameName
                        ? <span className='link cursor-pointer' onClick={() =>
                          history.push( {
                            pathname: `${ ROUTES.ADMIN_GAME_DETAILS.replace(
                              ":gameId",
                              obj.gameId
                            ) }?tab=GENERAL_INFORMATION&from=offers`,
                            state: [
                              { from: "offers", path: location.pathname, state: location.search },
                            ],
                            backScreenRoute: ROUTES.ADMIN_OFFER_LIST,
                          } )
                        }> {obj.gameName}</span>
                        : "N/A"}
                    </div>
                    <div className='td'>{obj.partnerName ?? "N/A"}</div>
                    <div className='td opt-in-text'>
                      <OptInDetails
                        optInDetail={obj.optInDetail}
                        eligibleSubPlans={getEligibleSubscriptionPlans( obj.optInDetail.membershipId, configuration.membershipTypes )}
                      />
                    </div>
                    <div className='td'><span className={`badge ${ obj?.statusTitle?.class }`}>{obj?.statusTitle?.title}</span></div>

                    <div className='td'>
                      <Dropdown className='custom-dropdown more-dropdown'>
                        <Dropdown.Toggle variant='success' id='' className='m-0'>
                          <MoreVertIcon />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="two-dropdown-item">
                          <Dropdown.Item ><span onClick={() => onClickViewAndEdit( obj, ROUTES.ADMIN_OFFER_DETAILS )}><VisibilityIcon /> View</span></Dropdown.Item>

                          <div className={ ( !obj.isAdmin ) && !(
                            ( obj.approvalStatus === OFFER_DETAIL_STATUS.APPROVED ) ||
                            ( obj.approvalStatus === OFFER_DETAIL_STATUS.CURRENT ) ||
                            ( obj.approvalStatus === OFFER_DETAIL_STATUS.EXPIRED ) ||
                            ( obj.approvalStatus === OFFER_DETAIL_STATUS.DRAFT ) )
                            ? "disabled"
                            : ""}>
                            <Dropdown.Item onClick={() => onClickViewAndEdit( obj, ROUTES.ADMIN_ADD_EDIT_OFFER )}>
                              <span className='rounded'>
                                <EditIcon />
                              </span>
                              <span>Edit</span>
                            </Dropdown.Item>
                          </div>
                          <div className={obj.offerType === TYPE_TO_BE_EDITED.OFFER ? "disabled" : ""}>
                            <Dropdown.Item onClick={() => handleOpenModal( obj.offerType === TYPE_TO_BE_EDITED.DRAFT
                              ? MODAL_TYPE_DETAIL.REMOVE_DRAFT.type
                              : MODAL_TYPE_DETAIL.REMOVE_OFFER.type,
                            { offerId: obj.offerId, offerType: obj.offerType } )} ><DeleteIcon /> <span>Delete</span></Dropdown.Item>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                ) )}
                {!offers.length && (
                  <div className='no-record' data-testid='no-record-found'>
                    No Offer Found
                  </div>
                )}
              </div>
            </Scrollbars>
          </div>
        </div>
      </div>
      <ModalPopup
        isOpened={isOpened}
        onClose={closeModal}
        type={modalType}
        onConfirm={handleDeleteOfferTemplate}
      />
    </>
  );
};

export default AdminOfferList;
