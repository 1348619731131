import InfoIcon from "@material-ui/icons/Info";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Scrollbars } from "react-custom-scrollbars";
import analyticsConstant from "../../../../util/analyticsConstant";
import constant from "../../../../util/constant";
import { currencyFormat, formatNumberAsPerLocale } from "../../../../util/helpers";
import {
  analyticsOfferRewardTxnTableConfigs,
  analyticsTournamentRewardTxnTableConfigs
} from "../../../../util/tableConstant";
import { ScrollPage } from "../../../shared/customScrollBar";
import TableHeaderRow from "../../../shared/tableHeadingRow";

const { ANALYTICS_TAB } = analyticsConstant;
const { BRANDING: { APP_SHORT_NAME } } = constant;

const offerRewardTxnList = ( {
  showFilter,
  handleSorting,
  dataList,
  tab,
  usdPerQxPoint,
} ) => {
  const memoizedTableConfig = useMemo( () => {
    return ( tab === ANALYTICS_TAB.OFFER_REWARD_TXN ) ? analyticsOfferRewardTxnTableConfigs : analyticsTournamentRewardTxnTableConfigs;
  }, [] );

  const memoizedHandleClick = useCallback(
    ( sortBy, orderType ) => handleSorting( sortBy, orderType ),
    []
  );

  return (
    <>
      <div className="table-head">
        <div className="table-row">
          <TableHeaderRow
            configData={memoizedTableConfig}
            onAscOrder={memoizedHandleClick}
            onDescOrder={memoizedHandleClick}
          />
        </div>
      </div>
      <Scrollbars renderThumbVertical={ScrollPage}
        className={`custom-scroll-height max ${ ( !showFilter ) ? "" : "full-height" }`}
      >
        <div className="table-body no-scrollbar" >
          { ( Boolean( dataList.length ) ) && dataList.map( ( txn, index ) => (
            <div className="table-row" key={index}>
              <div className="td">
                <span className='text-green font-medium'>
                  {txn.name}
                </span>
              </div>
              <div className="td">{txn.offerName}</div>
              <div className="td">{formatNumberAsPerLocale( txn.QXPoints )}
                <OverlayTrigger
                  placement='top'
                  overlay={
                    <Tooltip>
                      {formatNumberAsPerLocale( txn.QXPoints )} { APP_SHORT_NAME } Points = {currencyFormat( { data: ( txn.QXPoints * usdPerQxPoint ) } )}
                    </Tooltip>
                  }>
                  <InfoIcon className="d-inline-block ml-1 text-green"/>
                </OverlayTrigger>
              </div>
              { ( tab === ANALYTICS_TAB.TOURNAMENT_REWARD_TXN ) && (
                <div className="td">{txn.myPlace}</div>
              ) }
              <div className="td">{txn.transactionDate}</div>
              <div className="td">{txn.gamerName}</div>
            </div>
          ) )}
          { ( !dataList.length ) && (
            <div className='no-record' data-testid='no-record-found'>
              No {( tab === ANALYTICS_TAB.TOURNAMENT_REWARD_TXN ) ? "Tournament" : "Offer"} Reward Transaction Found
            </div>
          )}
        </div>
      </Scrollbars>
    </>
  );
};

offerRewardTxnList.propTypes = {
  dataList: PropTypes.array,
  showFilter: PropTypes.bool,
  handleSorting: PropTypes.func,
  tab: PropTypes.string,
  usdPerQxPoint: PropTypes.number,
};

export default offerRewardTxnList;
