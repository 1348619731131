import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPartnerGamerLinkedGameDetailAction } from "../../../../store/actions/partnerGamer";
import ViewLinkedGameDetail from "./viewLinkedGameDetail";

const PartnerGamerGameDetail = ( props, viewType ) => {
  const paramsQuery = new URLSearchParams( window.location.search );
  const tab = paramsQuery.get( "type" );
  const dispatch = useDispatch();
  const gameId = props.props.match?.params?.gameId || "";
  const {
    linkedGameDetails,
    usdPerQxPoint,
    partnerCostUsdPerQxPoint,
  } = useSelector( state => ( {
    linkedGameDetails: state.partnerGamer.LinkedGameDetails,
    usdPerQxPoint: state.partner.partnerDetail.configuration?.usdPerQxPoint || 0,
    partnerCostUsdPerQxPoint: state.partner.partnerDetail.configuration?.partnerCostUsdPerQxPoint || 0,
  } ) );

  useEffect( () => {
    if( gameId ) {
      dispatch( getPartnerGamerLinkedGameDetailAction( gameId, { getOffers: true } ) );
    }
  }, [ gameId ] );

  return (
    <>
      {viewType && (
        <ViewLinkedGameDetail
          viewType = {viewType}
          gameDetails = {linkedGameDetails}
          tab = {tab}
          usdPerQxPoint = {usdPerQxPoint}
          partnerCostUsdPerQxPoint = {partnerCostUsdPerQxPoint}
        />
      )}
    </>
  );
};

PartnerGamerGameDetail.propTypes = {
  match: PropTypes.string,
  props: PropTypes.object,
  viewType: PropTypes.bool,
  gamerId: PropTypes.string,
  gameId: PropTypes.string,
};

export default PartnerGamerGameDetail;
