import { toastr } from "react-redux-toastr";
import { AdminOfferDetails } from "../../entities/adminOfferDetails";
import { DraftToOffer } from "../../entities/draftToOffer";
import { OfferTemplateDetail } from "../../entities/offerTemplateDetail";
import {
  getAdminOffers,
  getAdminOfferDetails,
  rejectOfferRequest,
  approveOfferRequest,
  enableDisableOfferRequest,
  addAdminOffer,
  updateAdminOffer,
  updatePartnerOfferByAdmin,
  deleteAdminOfferTemplate
} from "../../services/adminOffersService";
import constant from "../../util/constant";
import offerConstant from "../../util/offerConstant";
import {
  START_LOADER,
  STOP_LOADER,
  ADMIN_OFFER_API_END,
  ADMIN_OFFER_API_REQUEST,
  GET_ADMIN_OFFERS_SUCCESS,
  GET_ADMIN_OFFERS_FAILURE,
  GET_ADMIN_OFFER_DETAILS_SUCCESS,
  GET_ADMIN_OFFER_DETAILS_FAILURE,
  REJECT_OFFER_REQUEST_SUCCESS,
  REJECT_OFFER_REQUEST_FAILURE,
  APPROVE_OFFER_REQUEST_SUCCESS,
  APPROVE_OFFER_REQUEST_FAILURE,
  ENABLE_DISABLE_PARTNER_OFFER_SUCCESS,
  ENABLE_DISABLE_PARTNER_OFFER_FAILURE,
  ADD_ADMIN_NEW_OFFER_SUCCESS,
  ADD_ADMIN_NEW_OFFER_FAILURE,
  UPDATE_ADMIN_OFFER_SUCCESS,
  UPDATE_ADMIN_OFFER_FAILURE,
  UPDATE_PARTNER_OFFER_BY_ADMIN_SUCCESS,
  UPDATE_PARTNER_OFFER_BY_ADMIN_FAILURE,
  DELETE_ADMIN_OFFER_SUCCESS,
  DELETE_ADMIN_OFFER_FAILURE
} from "../actionTypes";

const { OFFER_API_TYPES, TYPE_TO_BE_EDITED } = offerConstant;
const { ERROR_MESSAGES } = constant;

export const getAdminOffersAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_OFFER_API_REQUEST,
      payload: OFFER_API_TYPES.GET_ADMIN_OFFERS,
    } );

    const response = await getAdminOffers( param );
    const dataList = response.data.offerList.map(
      ( item ) => {
        if( item.offer_type === TYPE_TO_BE_EDITED.DRAFT ) {
          return new DraftToOffer( item );
        }

        return new AdminOfferDetails( item );
      }
    );

    dispatch( {
      type: GET_ADMIN_OFFERS_SUCCESS,
      payload: { dataList, meta: response.data.meta },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GET_ADMIN_OFFERS_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_OFFER_API_END,
    } );
  }
};

export const getAdminOfferDetailsAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_OFFER_API_REQUEST,
      payload: OFFER_API_TYPES.GET_ADMIN_OFFER_DETAILS,
    } );

    const response = await getAdminOfferDetails( param );
    const newResponse = new OfferTemplateDetail( response.data );

    dispatch( {
      type: GET_ADMIN_OFFER_DETAILS_SUCCESS,
      payload: newResponse,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GET_ADMIN_OFFER_DETAILS_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_OFFER_API_END,
    } );
  }
};

export const rejectOfferRequestAction = requestObj => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_OFFER_API_REQUEST,
      payload: OFFER_API_TYPES.REJECT_OFFER_REQUEST,
    } );

    const response = await rejectOfferRequest( requestObj );

    dispatch( { type: REJECT_OFFER_REQUEST_SUCCESS, payload: response } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: REJECT_OFFER_REQUEST_FAILURE, payload: error } );
    dispatch( { type: STOP_LOADER } );
    toastr.error( "Error", error?.error || constant.ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( { type: ADMIN_OFFER_API_END } );
  }
};

export const approvalOfferRequestAction = requestObj => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_OFFER_API_REQUEST,
      payload: OFFER_API_TYPES.APPROVE_OFFER_REQUEST,
    } );

    const response = await approveOfferRequest( requestObj );

    dispatch( { type: APPROVE_OFFER_REQUEST_SUCCESS, payload: response } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: APPROVE_OFFER_REQUEST_FAILURE, payload: error } );
    dispatch( { type: STOP_LOADER } );
    toastr.error( "Error", error?.error || constant.ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( { type: ADMIN_OFFER_API_END } );
  }
};

export const enableDisableOfferAction = requestObj => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_OFFER_API_REQUEST,
      payload: OFFER_API_TYPES.ENABLE_DISABLE_PARTNER_OFFER,
    } );

    const response = await enableDisableOfferRequest( requestObj );

    dispatch( { type: ENABLE_DISABLE_PARTNER_OFFER_SUCCESS, payload: response } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: ENABLE_DISABLE_PARTNER_OFFER_FAILURE, payload: error } );
    dispatch( { type: STOP_LOADER } );
    toastr.error( error?.error || constant.ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( { type: ADMIN_OFFER_API_END } );
  }
};

export const addNewAdminOfferAction = params => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_OFFER_API_REQUEST,
      payload: OFFER_API_TYPES.ADD_ADMIN_NEW_OFFER,
    } );

    const response = await addAdminOffer( params );

    dispatch( {
      type: ADD_ADMIN_NEW_OFFER_SUCCESS,
      payload: response.data,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: ADD_ADMIN_NEW_OFFER_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_OFFER_API_END,
    } );
  }
};

export const updateAdminOfferAction = params => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_OFFER_API_REQUEST,
      payload: OFFER_API_TYPES.UPDATE_ADMIN_OFFER,
    } );

    const response = await updateAdminOffer( params );

    dispatch( {
      type: UPDATE_ADMIN_OFFER_SUCCESS,
      payload: response.data,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: UPDATE_ADMIN_OFFER_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_OFFER_API_END,
    } );
  }
};

export const updatePartnerOfferByAdminAction = params => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_OFFER_API_REQUEST,
      payload: OFFER_API_TYPES.UPDATE_PARTNER_OFFER_BY_ADMIN,
    } );

    const response = await updatePartnerOfferByAdmin( params );

    dispatch( {
      type: UPDATE_PARTNER_OFFER_BY_ADMIN_SUCCESS,
      payload: response.data,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: UPDATE_PARTNER_OFFER_BY_ADMIN_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_OFFER_API_END,
    } );
  }
};

export const deleteAdminOfferAction = params => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_OFFER_API_REQUEST,
      payload: OFFER_API_TYPES.DELETE_ADMIN_OFFER,
    } );

    const response = await deleteAdminOfferTemplate( params );

    dispatch( {
      type: DELETE_ADMIN_OFFER_SUCCESS,
      payload: { ...response.data, offerId: params.offerId },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: DELETE_ADMIN_OFFER_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_OFFER_API_END,
    } );
  }
};
