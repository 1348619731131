import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSetUrlParams } from "../../../util/helpers";
import MultiSelectCheckbox from "../../shared/multiSelectCheckbox";

const AnalyticsGamerFilterList = ( {
  values,
  handleChange,
  addQuery,
  gamerNames,
  gameNames,
  isGameId,
  showFilter,
  locationState,
  gamerPlans,
} ) => {
  const analyticsGamerListHandle = ( obj, listName ) => {
    if( obj.length > 0 ) {
      handleChange( "page", 1 );
      handleChange( listName, obj );
      addQuery( useSetUrlParams( listName, JSON.stringify( obj ) ), locationState, isGameId );
    }
    else {
      addQuery( useSetUrlParams( listName, "" ), locationState, isGameId );
      handleChange( listName );
    }
  };

  const analyticsGamerClearHandle = ( params ) => {
    if( params ) {
      addQuery( useSetUrlParams( params, "" ), locationState, isGameId );
      handleChange( params, [] );
    }
  };

  const analyticsMultiSelected = useMemo( () => [ values.gameName, values.gamerName, values.gamerPlans ] );
  const analyticsGamerhandleSelect = useCallback(
    [
      obj => analyticsGamerListHandle( obj, "gameName" ),
      obj => analyticsGamerListHandle( obj, "gamerName" ),
      obj => analyticsGamerListHandle( obj, "gamerPlans" ),
    ],
    []
  );
  const analyticsGamerHandle = useCallback(
    params => analyticsGamerClearHandle( params ), []
  );

  return (
    <>
      <div className="filter-boxes trans-exchange-filter">
        <div className={`filter-boxes mr-0 ${ !showFilter ? "" : "remove-filter" }`}>
          <Row>
            <Col>
              <Form.Group className='custom-multiselect'>
                <Form.Label>Gamer Name</Form.Label>
                {<MultiSelectCheckbox
                  multiOptionsList={gamerNames}
                  handleSelectChange={analyticsGamerhandleSelect[ 1 ]}
                  multiSelectedItems={analyticsMultiSelected[ 1 ]}
                  handleClearMultiSelect={() => analyticsGamerHandle( "gamerName" )} />}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='custom-multiselect'>
                <Form.Label>Linked Game</Form.Label>
                <MultiSelectCheckbox
                  multiOptionsList={gameNames}
                  handleSelectChange={analyticsGamerhandleSelect[ 0 ]}
                  multiSelectedItems={analyticsMultiSelected[ 0 ]}
                  handleClearMultiSelect={() => analyticsGamerHandle( "gameName" )} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='custom-multiselect'>
                <Form.Label>Gamer Plan</Form.Label>
                {<MultiSelectCheckbox
                  multiOptionsList={gamerPlans}
                  handleSelectChange={analyticsGamerhandleSelect[ 2 ]}
                  multiSelectedItems={analyticsMultiSelected[ 2 ]}
                  handleClearMultiSelect={() => analyticsGamerHandle( "gamerPlans" )} />}
              </Form.Group>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

AnalyticsGamerFilterList.propTypes = {
  values: PropTypes.object,
  onSearch: PropTypes.func,
  handleChange: PropTypes.func,
  addQuery: PropTypes.func,
  gamerNames: PropTypes.array,
  gameNames: PropTypes.array,
  onCancel: PropTypes.func,
  isGamerNames: PropTypes.bool,
  isGameId: PropTypes.bool,
  locationState: PropTypes.string,
  showFilter: PropTypes.string,
  gamerPlans: PropTypes.array,
};

export default AnalyticsGamerFilterList;
