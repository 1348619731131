import PropTypes from "prop-types";
import React from "react";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const FieldTitleWithInfoIcon = ( { title, description } ) => {
  return (
    <Form.Label className='label-with-icon'>
      {title}
      <OverlayTrigger
        placement='right'
        overlay={<Tooltip id='tooltip-disabled'>{description}</Tooltip>}
      >
        <span className='icon-circle-help'></span>
      </OverlayTrigger>
    </Form.Label>
  );
};

FieldTitleWithInfoIcon.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default FieldTitleWithInfoIcon;
