import React from "react";
import Button from "react-bootstrap/Button";
import docusignRequired from "../../../assets/images/docusign-required.png";

const Docusign = () => {
  return (
    <>
      <div className="main-wrapper">
        <div className="main-right-wrapper">
          <div className="profile-pending-box">
            <div className="left-side">
              <h2>Docusign Required!</h2>
              <p>Your application has been reviewed from admin and to complete please sign the docusign document.</p>
              <Button variant="primary"><a href="https://demo.docusign.net/" target="_blank" rel="noreferrer">Sign Docusign</a> </Button>
              {" "} </div>
            <div className="right-side">
              <img src={docusignRequired}
                alt="Application Pending"/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Docusign;
