export const START_LOADER = "START_LOADER";
export const STOP_LOADER = "STOP_LOADER";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_OTHER_INFO = "SET_OTHER_INFO";
export const CLEAR_STORE = "CLEAR_STORE";
export const SET_PARTNER_INFO_EMPTY = "SET_PARTNER_INFO_EMPTY";

export const UPLOAD_LOGO_SUCCESS = "UPLOAD_LOGO_SUCCESS";
export const UPLOAD_LOGO_FAILURE = "UPLOAD_LOGO_FAILURE";
export const GET_PARTNER_DETAIL_REQUEST = "GET_PARTNER_DETAIL_REQUEST";
export const GET_PARTNER_DETAIL_SUCCESS = "GET_PARTNER_DETAIL_SUCCESS";
export const GET_PARTNER_DETAIL_FAILURE = "GET_PARTNER_DETAIL_FAILURE";

export const PARTNER_API_REQUEST = "PARTNER_API_REQUEST";
export const PARTNER_API_END = "PARTNER_API_END";
export const ADMIN_API_REQUEST = "ADMIN_API_REQUEST";
export const ADMIN_API_END = "ADMIN_API_END";
export const UPLOAD_PARTNER_DETAIL_SUCCESS = "UPLOAD_PARTNER_DETAIL_SUCCESS";
export const UPLOAD_PARTNER_DETAIL_FAILURE = "UPLOAD_PARTNER_DETAIL_FAILURE";
export const UPDATE_PARTNER_DETAIL_SUCCESS = "UPDATE_PARTNER_DETAIL_SUCCESS";
export const UPDATE_PARTNER_DETAIL_FAILURE = "UPDATE_PARTNER_DETAIL_FAILURE";

export const PARTNER_LIST_SUCCESS = "PARTNER_LIST_SUCCESS";
export const PARTNER_LIST_FAILURE = "PARTNER_LIST_FAILURE";
export const PARTNER_DETAIL_SUCCESS = "PARTNER_DETAIL_SUCCESS";
export const PARTNER_DETAIL_FAILURE = "PARTNER_DETAIL_FAILURE";
export const REJECT_PARTNER_REQUEST_SUCCESS = "REJECT_PARTNER_REQUEST_SUCCESS";
export const REJECT_PARTNER_REQUEST_FAILURE = "REJECT_PARTNER_REQUEST_FAILURE";
export const PARTNER_FILTER_LIST_SUCCESS = "PARTNER_FILTER_LIST_SUCCESS";
export const PARTNER_FILTER_LIST_FAILURE = "PARTNER_FILTER_LIST_FAILURE";
export const ADMIN_PARTNER_LIST_API_REQUEST = "ADMIN_PARTNER_LIST_API_REQUEST";
export const PARTNER_LIST_API_END = "PARTNER_LIST_API_END";

export const AMMENDMENTS_SUGGESTED_SUCCESS = "AMMENDMENTS_SUGGESTED_SUCCESS";
export const AMMENDMENTS_SUGGESTED_FAILURE = "AMMENDMENTS_SUGGESTED_FAILURE";
export const CONFIRM_FOR_DOCUSIGN_SUCCESS = "CONFIRM_FOR_DOCUSIGN_SUCCESS";
export const CONFIRM_FOR_DOCUSIGN_FAILURE = "CONFIRM_FOR_DOCUSIGN_FAILURE";
export const RESOLVE_AMENDMENT_SUCCESS = "RESOLVE_AMENDMENT_SUCCESS";
export const RESOLVE_AMENDMENT_FAILURE = "RESOLVE_AMENDMENT_FAILURE";

export const UPDATE_PARTNER_STATUS_SUCCESS = "UPDATE_PARTNER_STATUS_SUCCESS";
export const UPDATE_PARTNER_STATUS_FAILURE = "UPDATE_PARTNER_STATUS_FAILURE";
export const APPROVE_PARTNER_MANUAL_SUCCESS = "APPROVE_PARTNER_MANUAL_SUCCESS";
export const APPROVE_PARTNER_MANUAL_FAILURE = "APPROVE_PARTNER_MANUAL_FAILURE";

export const GET_PARTNER_GAME_LIST_SUCCESS = "GET_PARTNER_GAME_LIST_SUCCESS";
export const GET_PARTNER_GAME_LIST_FAILURE = "GET_PARTNER_GAME_LIST_FAILURE";
export const GET_PARTNER_GAME_DETAIL_REQUEST =
  "GET_PARTNER_GAME_DETAIL_REQUEST";
export const PARTNER_GAME_API_REQUEST = "PARTNER_GAME_API_REQUEST";
export const PARTNER_GAME_API_END = "PARTNER_GAME_API_END";
export const SCROLL_SUCCESS = "SCROLL_SUCCESS";
export const SCROLL_FAILURE = "SCROLL_FAILURE";
export const UPLOAD_GAME_ICON_SUCCESS = "UPLOAD_GAME_ICON_SUCCESS";
export const UPLOAD_GAME_ICON_FAILURE = "UPLOAD_GAME_ICON_FAILURE";
export const ADD_GAME_SUCCESS = "ADD_GAME_SUCCESS";
export const ADD_GAME_FAILURE = "ADD_GAME_FAILURE";
export const PARTNER_ALL_GAME_LIST_SUCCESS = "PARTNER_ALL_GAME_LIST_SUCCESS";
export const PARTNER_ALL_GAME_LIST_FAILURE = "PARTNER_ALL_GAME_LIST_FAILURE";
export const GET_GAME_DETAIL_SUCCESS = "GET_GAME_DETAIL_SUCCESS";
export const GET_GAME_DETAIL_FAILURE = "GET_GAME_DETAIL_FAILURE";
export const UPDATE_GAME_SUCCESS = "UPDATE_GAME_SUCCESS";
export const UPDATE_GAME_FAILURE = "UPDATE_GAME_FAILURE";
export const ADD_PARTNER_COMMENT_SUCCESS = "ADD_PARTNER_COMMENT_SUCCESS";
export const ADD_PARTNER_COMMENT_FAILURE = "ADD_PARTNER_COMMENT_FAILURE";
export const ADMIN_GAME_API_END = "ADMIN_GAME_API_END";
export const ADMIN_GAME_API_REQUEST = "ADMIN_GAME_API_REQUEST";
export const GET_ADMIN_GAMES_SUCCESS = "GET_ADMIN_GAMES_SUCCESS";
export const GET_ADMIN_GAMES_FAILURE = "GET_ADMIN_GAMES_FAILURE";
export const GET_ADMIN_GAME_DETAILS_SUCCESS = "GET_ADMIN_GAME_DETAILS_SUCCESS";
export const GET_ADMIN_GAME_DETAILS_FAILURE = "GET_ADMIN_GAME_DETAILS_FAILURE";
export const ADD_MODIFICATION_SUCCESS = "ADD_MODIFICATION_SUCCESS";
export const ADD_MODIFICATION_FAILURE = "ADD_MODIFICATION_FAILURE";
export const UPDATE_PARTNER_GAME_STATUS_SUCCESS =
  "UPDATE_PARTNER_GAME_STATUS_SUCCESS";
export const UPDATE_PARTNER_GAME_STATUS_FAILURE =
  "UPDATE_PARTNER_GAME_STATUS_FAILURE";
export const PARTNER_EVENT_API_REQUEST = "PARTNER_EVENT_API_REQUEST";
export const PARTNER_EVENT_API_END = "PARTNER_EVENT_API_END";
export const ADMIN_EVENT_API_REQUEST = "ADMIN_EVENT_API_REQUEST";
export const ADMIN_EVENT_API_END = "ADMIN_EVENT_API_END";

export const ADD_EVENT_SUCCESS = "ADD_EVENT_SUCCESS";
export const ADD_EVENT_FAILURE = "ADD_EVENT_FAILURE";
export const GET_PARTNER_EVENTS_SUCCESS = "GET_PARTNER_EVENTS_SUCCESS";
export const GET_PARTNER_EVENTS_FAILURE = "GET_PARTNER_EVENTS_FAILURE";
export const GET_ADMIN_EVENTS_SUCCESS = "GET_ADMIN_EVENTS_SUCCESS";
export const GET_ADMIN_EVENTS_FAILURE = "GET_ADMIN_EVENTS_FAILURE";

export const ADMIN_USER_API_REQUEST = "ADMIN_USER_API_REQUEST";
export const GET_ADMIN_USER_SUCCESS = "GET_ADMIN_USER_SUCCESS";
export const GET_ADMIN_USER_FAILURE = "GET_ADMIN_USER_FAILURE";
export const ADMIN_USER_API_END = "ADMIN_USER_API_END";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_FAILURE = "UPDATE_EVENT_FAILURE";
export const GET_PARTNER_EVENT_DETAIL_SUCCESS = "GET_PARTNER_EVENT_DETAIL_SUCCESS";
export const GET_PARTNER_EVENT_DETAIL_FAILURE = "GET_PARTNER_EVENT_DETAIL_FAILURE";

export const ADD_ADMIN_EVENT_SUCCESS = "ADD_ADMIN_EVENT_SUCCESS";
export const ADD_ADMIN_EVENT_FAILURE = "ADD_ADMIN_EVENT_FAILURE";
export const UPDATE_ADMIN_EVENT_SUCCESS = "UPDATE_ADMIN_EVENT_SUCCESS";
export const UPDATE_ADMIN_EVENT_FAILURE = "UPDATE_ADMIN_EVENT_FAILURE";
export const GET_ADMIN_EVENT_DETAIL_SUCCESS = "GET_ADMIN_EVENT_DETAIL_SUCCESS";
export const GET_ADMIN_EVENT_DETAIL_FAILURE = "GET_ADMIN_EVENT_DETAIL_FAILURE";

export const GET_PARTNER_DRAFT_DETAIL_SUCCESS = "GET_PARTNER_DRAFT_DETAIL_SUCCESS";
export const GET_PARTNER_DRAFT_DETAIL_FAILURE = "GET_PARTNER_DRAFT_DETAIL_FAILURE";
export const GET_PARTNER_DRAFT_DETAIL_REQUEST = "GET_PARTNER_DRAFT_DETAIL_REQUEST";
export const PARTNER_DRAFT_API_REQUEST = "PARTNER_DRAFT_API_REQUEST";
export const PARTNER_DRAFT_API_END = "PARTNER_DRAFT_API_END";
export const ADD_PARTNER_DRAFT_DETAIL_SUCCESS = "ADD_PARTNER_DRAFT_DETAIL_SUCCESS";
export const ADD_PARTNER_DRAFT_DETAIL_FAILURE = "ADD_PARTNER_DRAFT_DETAIL_FAILURE";
export const UPDATE_PARTNER_DRAFT_DETAIL_SUCCESS = "UPDATE_PARTNER_DRAFT_DETAIL_SUCCESS";
export const UPDATE_PARTNER_DRAFT_DETAIL_FAILURE = "UPDATE_PARTNER_DRAFT_DETAIL_FAILURE";

export const SET_GENERAL_INFO_API_REQUEST = "SET_GENERAL_INFO_API_REQUEST";
export const SET_GENERAL_INFO_SUCCESS = "SET_GENERAL_INFO_SUCCESS";
export const SET_GENERAL_INFO_FAILURE = "SET_GENERAL_INFO_FAILURE";
export const SET_GENERAL_INFO_API_END = "SET_GENERAL_INFO_API_END";

export const SET_CONTACT_INFO_API_REQUEST = "SET_CONTACT_INFO_API_REQUEST";
export const SET_CONTACT_INFO_SUCCESS = "SET_CONTACT_INFO_SUCCESS";
export const SET_CONTACT_INFO_FAILURE = "SET_CONTACT_INFO_FAILURE";

export const SET_OTHER_INFO_API_REQUEST = "SET_OTHER_INFO_API_REQUEST";
export const SET_OTHER_INFO_SUCCESS = "SET_OTHER_INFO_SUCCESS";
export const SET_OTHER_INFO_FAILURE = "SET_OTHER_INFO_FAILURE";

export const SET_TERMS_INFO_API_REQUEST = "SET_TERMS_INFO_API_REQUEST";
export const SET_TERMS_INFO_SUCCESS = "SET_TERMS_INFO_FAILURE";
export const SET_TERMS_INFO_FAILURE = "SET_TERMS_INFO_FAILURE";
export const SET_PARTNER_DRAFT_DETAIL_SUCCESS = "SET_PARTNER_DRAFT_DETAIL_SUCCESS";
export const TRUNCATE_PARTNER_DRAFT_DETAIL_SUCCESS = "TRUNCATE_PARTNER_DRAFT_DETAIL_SUCCESS";

export const ADD_ADMIN_USER_SUCCESS = "ADD_ADMIN_USER_SUCCESS";
export const ADD_ADMIN_USER_FAILURE = "ADD_ADMIN_USER_FAILURE";
export const GET_ADMIN_USER_DETAIL_SUCCESS = "GET_ADMIN_USER_DETAIL_SUCCESS";
export const GET_ADMIN_USER_DETAIL_FAILURE = "GET_ADMIN_USER_DETAIL_FAILURE";
export const UPDATE_ADMIN_USER_PERMISSION_SUCCESS = "UPDATE_ADMIN_USER_PERMISSION_SUCCESS";
export const UPDATE_ADMIN_USER_PERMISSION_FAILURE = "UPDATE_ADMIN_USER_PERMISSION_FAILURE";
export const ENABLE_DISABLE_ADMIN_USER_SUCCESS = "ENABLE_DISABLE_ADMIN_USER_SUCCESS";
export const ENABLE_DISABLE_ADMIN_USER_FAILURE = "ENABLE_DISABLE_ADMIN_USER_FAILURE";
export const PARTNER_OFFER_API_REQUEST = "PARTNER_OFFER_API_REQUEST";
export const PARTNER_OFFER_API_END = "PARTNER_OFFER_API_END";
export const GET_OFFER_TEMPLATES_SUCCESS = "GET_OFFER_TEMPLATES_SUCCESS";
export const GET_OFFER_TEMPLATES_FAILURE = "GET_OFFER_TEMPLATES_FAILURE";
export const ADD_PARTNER_NEW_OFFER_TEMPLATE_SUCCESS = "ADD_PARTNER_NEW_OFFER_TEMPLATE_SUCCESS";
export const ADD_PARTNER_NEW_OFFER_TEMPLATE_FAILURE = "ADD_PARTNER_NEW_OFFER_TEMPLATE_FAILURE";

export const ADMIN_FILTER_LIST_API_REQUEST = "ADMIN_FILTER_LIST_API_REQUEST";
export const ADMIN_FILTER_LIST_API_END = "ADMIN_FILTER_LIST_API_END";
export const ADMIN_FILTER_LIST_SUCCESS = "ADMIN_FILTER_LIST_SUCCESS";
export const ADMIN_FILTER_LIST_FAILURE = "ADMIN_FILTER_LIST_FAILURE";
export const PARTNER_LOOKUP_API_REQUEST = "PARTNER_LOOKUP_API_REQUEST";
export const PARTNER_LOOKUP_API_END = "PARTNER_LOOKUP_API_END";
export const GET_PARTNER_LOOKUP_SUCCESS = "GET_PARTNER_LOOKUP_SUCCESS";
export const GET_PARTNER_LOOKUP_FAILURE = "GET_PARTNER_LOOKUP_FAILURE";
export const GET_PARTNER_OFFER_TEMPLATE_DETAIL_SUCCESS = "GET_PARTNER_OFFER_TEMPLATE_DETAIL_SUCCESS";
export const GET_PARTNER_OFFER_TEMPLATE_DETAIL_FAILURE = "GET_PARTNER_OFFER_TEMPLATE_DETAIL_FAILURE";
export const ADD_PARTNER_OFFER_FROM_SCRATCH_SUCCESS = "ADD_PARTNER_OFFER_FROM_SCRATCH_SUCCESS";
export const ADD_PARTNER_OFFER_FROM_SCRATCH_FAILURE = "ADD_PARTNER_OFFER_FROM_SCRATCH_FAILURE";

export const GET_PARTNER_OFFER_SUCCESS = "GET_PARTNER_OFFER_SUCCESS";
export const GET_PARTNER_OFFER_FAILURE = "GET_PARTNER_OFFER_FAILURE";

export const GET_ADMIN_OFFERS_SUCCESS = "GET_ADMIN_OFFERS_SUCCESS";
export const GET_ADMIN_OFFERS_FAILURE = "GET_ADMIN_OFFERS_FAILURE";
export const ADMIN_OFFER_API_REQUEST = "ADMIN_OFFER_API_REQUEST";
export const ADMIN_OFFER_API_END = "ADMIN_OFFER_API_END";
export const GET_ADMIN_OFFER_DETAILS_SUCCESS = "GET_ADMIN_OFFER_DETAILS_SUCCESS";
export const GET_ADMIN_OFFER_DETAILS_FAILURE = "GET_ADMIN_OFFER_DETAILS_FAILURE";
export const ADD_ADMIN_NEW_OFFER_SUCCESS = "ADD_ADMIN_NEW_OFFER_SUCCESS";
export const ADD_ADMIN_NEW_OFFER_FAILURE = "ADD_ADMIN_NEW_OFFER_FAILURE";
export const ADMIN_LOOKUP_API_REQUEST = "ADMIN_LOOKUP_API_REQUEST";
export const ADMIN_LOOKUP_API_END = "ADMIN_LOOKUP_API_END";
export const GET_ADMIN_LOOKUP_SUCCESS = "GET_ADMIN_LOOKUP_SUCCESS";
export const GET_ADMIN_LOOKUP_FAILURE = "GET_ADMIN_LOOKUP_FAILURE";

export const REJECT_OFFER_REQUEST_SUCCESS = "REJECT_OFFER_REQUEST_SUCCESS";
export const REJECT_OFFER_REQUEST_FAILURE = "REJECT_OFFER_REQUEST_FAILURE";
export const APPROVE_OFFER_REQUEST_SUCCESS = "APPROVE_OFFER_REQUEST_SUCCESS";
export const APPROVE_OFFER_REQUEST_FAILURE = "APPROVE_OFFER_REQUEST_FAILURE";

export const GET_PARTNER_COMMENTS_SUCCESS = "GET_PARTNER_COMMENTS_SUCCESS";
export const GET_PARTNER_COMMENTS_FAILURE = "GET_PARTNER_COMMENTS_FAILURE";
export const ADD_ADMIN_COMMENT_SUCCESS = "ADD_ADMIN_COMMENT_SUCCESS";
export const ADD_ADMIN_COMMENT_FAILURE = "ADD_ADMIN_COMMENT_FAILURE";

export const ENABLE_DISABLE_PARTNER_GAME_SUCCESS = "ENABLE_DISABLE_PARTNER_GAME_SUCCESS";
export const ENABLE_DISABLE_PARTNER_GAME_FAILURE = "ENABLE_DISABLE_PARTNER_GAME_FAILURE";

export const ENABLE_DISABLE_PARTNER_OFFER_SUCCESS = "ENABLE_DISABLE_PARTNER_OFFER_SUCCESS";
export const ENABLE_DISABLE_PARTNER_OFFER_FAILURE = "ENABLE_DISABLE_PARTNER_OFFER_FAILURE";

export const ADMIN_OFFER_DRAFT_API_REQUEST = "ADMIN_OFFER_DRAFT_API_REQUEST";
export const ADMIN_OFFER_DRAFT_API_END = "ADMIN_OFFER_DRAFT_API_END";
export const ADD_ADMIN_NEW_OFFER_DRAFT_SUCCESS = "ADD_ADMIN_NEW_OFFER_DRAFT_SUCCESS";
export const ADD_ADMIN_NEW_OFFER_DRAFT_FAILURE = "ADD_ADMIN_NEW_OFFER_DRAFT_FAILURE";
export const UPDATE_ADMIN_OFFER_SUCCESS = "UPDATE_ADMIN_OFFER_SUCCESS";
export const UPDATE_ADMIN_OFFER_FAILURE = "UPDATE_ADMIN_OFFER_FAILURE";
export const UPDATE_ADMIN_OFFER_DRAFT_SUCCESS = "UPDATE_ADMIN_OFFER_DRAFT_SUCCESS";
export const UPDATE_ADMIN_OFFER_DRAFT_FAILURE = "UPDATE_ADMIN_OFFER_DRAFT_FAILURE";
export const GET_ADMIN_DRAFT_OFFER_DETAIL_SUCCESS = "GET_ADMIN_DRAFT_OFFER_DETAIL_SUCCESS";
export const GET_ADMIN_DRAFT_OFFER_DETAIL_FAILURE = "GET_ADMIN_DRAFT_OFFER_DETAIL_FAILURE";

export const PARTNER_OFFER_DRAFT_API_REQUEST = "PARTNER_OFFER_DRAFT_API_REQUEST";
export const PARTNER_OFFER_DRAFT_API_END = "PARTNER_OFFER_DRAFT_API_END";
export const ADD_PARTNER_NEW_OFFER_DRAFT_SUCCESS = "ADD_PARTNER_NEW_OFFER_DRAFT_SUCCESS";
export const ADD_PARTNER_CLONE_OFFER_DRAFT_SUCCESS = "ADD_PARTNER_CLONE_OFFER_DRAFT_SUCCESS";
export const ADD_PARTNER_NEW_OFFER_DRAFT_FAILURE = "ADD_PARTNER_NEW_OFFER_DRAFT_FAILURE";
export const UPDATE_PARTNER_OFFER_TEMPLATE_SUCCESS = "UPDATE_PARTNER_OFFER_TEMPLATE_SUCCESS";
export const UPDATE_PARTNER_OFFER_TEMPLATE_FAILURE = "UPDATE_PARTNER_OFFER_TEMPLATE_FAILURE";
export const GET_PARTNER_DRAFT_OFFER_DETAIL_SUCCESS = "GET_PARTNER_DRAFT_OFFER_DETAIL_SUCCESS";
export const GET_PARTNER_DRAFT_OFFER_DETAIL_FAILURE = "GET_PARTNER_DRAFT_OFFER_DETAIL_FAILURE";
export const UPDATE_PARTNER_OFFER_DRAFT_SUCCESS = "UPDATE_PARTNER_OFFER_DRAFT_SUCCESS";
export const UPDATE_PARTNER_OFFER_DRAFT_FAILURE = "UPDATE_PARTNER_OFFER_DRAFT_FAILURE";

export const GET_ADMIN_DETAIL_SUCCESS = "GET_ADMIN_DETAIL_SUCCESS";
export const GET_ADMIN_DETAIL_FAILURE = "GET_ADMIN_DETAIL_FAILURE";
export const ADMIN_DETAIL_API_REQUEST = "ADMIN_DETAIL_API_REQUEST";
export const ADMIN_DETAIL_API_END = "ADMIN_DETAIL_API_END";
export const UPDATE_ADMIN_DETAIL_SUCCESS = "UPDATE_ADMIN_DETAIL_SUCCESS";

export const ADMIN_USERS_FILTER_LIST_SUCCESS = "ADMIN_USERS_FILTER_LIST_SUCCESS";
export const ADMIN_USERS_FILTER_LIST_FAILURE = "ADMIN_USERS_FILTER_LIST_FAILURE";

export const GET_ADMIN_PARTNER_GAMES_SUCCESS = "GET_ADMIN_PARTNER_GAMES_SUCCESS";
export const GET_ADMIN_PARTNER_GAMES_FAILURE = "GET_ADMIN_PARTNER_GAMES_FAILURE";
export const GET_ADMIN_PARTNER_OFFERS_SUCCESS = "GET_ADMIN_PARTNER_OFFERS_SUCCESS";
export const GET_ADMIN_PARTNER_OFFERS_FAILURE = "GET_ADMIN_PARTNER_OFFERS_FAILURE";

export const NOTIFICATIONS_RESET = "NOTIFICATIONS_RESET";
export const NOTIFICATIONS_API_REQUEST = "NOTIFICATIONS_API_REQUEST";
export const NOTIFICATIONS_SUCCESS = "NOTIFICATIONS_SUCCESS";
export const NOTIFICATIONS_FAILURE = "NOTIFICATIONS_FAILURE";
export const NOTIFICATIONS_UPDATE = "NOTIFICATIONS_UPDATE";
export const READ_NOTIFICATIONS_RESET = "READ_NOTIFICATIONS_RESET";
export const READ_NOTIFICATIONS_REQUEST = "READ_NOTIFICATIONS_REQUEST";
export const READ_NOTIFICATIONS_SUCCESS = "READ_NOTIFICATIONS_SUCCESS";
export const READ_NOTIFICATIONS_FAILURE = "READ_NOTIFICATIONS_FAILURE";
export const ADMIN_PARTNER_FILTER_LIST_API_REQUEST = "ADMIN_PARTNER_FILTER_LIST_API_REQUEST";

export const UPDATE_PARTNER_PROFILE_SUCCESS = "UPDATE_PARTNER_PROFILE_SUCCESS";
export const UPDATE_PARTNER_PROFILE_FAILURE = "UPDATE_PARTNER_PROFILE_FAILURE";

export const ENABLE_DISABLE_EVENT_SUCCESS = "ENABLE_DISABLE_EVENT_SUCCESS";
export const ENABLE_DISABLE_EVENT_FAILURE = "ENABLE_DISABLE_EVENT_FAILURE";
export const UPDATE_PARTNER_OFFER_BY_ADMIN_SUCCESS = "UPDATE_PARTNER_OFFER_BY_ADMIN_SUCCESS";
export const UPDATE_PARTNER_OFFER_BY_ADMIN_FAILURE = "UPDATE_PARTNER_OFFER_BY_ADMIN_FAILURE";
export const SET_PARTNER_OFFER_TEMPLATE_DETAIL = "SET_PARTNER_OFFER_TEMPLATE_DETAIL";

export const DELETE_PARTNER_OFFER_SUCCESS = "DELETE_PARTNER_OFFER_SUCCESS";
export const DELETE_PARTNER_OFFER_FAILURE = "DELETE_PARTNER_OFFER_FAILURE";
export const DELETE_ADMIN_OFFER_SUCCESS = "DELETE_ADMIN_OFFER_SUCCESS";
export const DELETE_ADMIN_OFFER_FAILURE = "DELETE_ADMIN_OFFER_FAILURE";

export const EMAIL_VERIFY_API_REQUEST = "EMAIL_VERIFY_API_REQUEST";
export const EMAIL_VERIFY_API_END = "EMAIL_VERIFY_API_END";
export const EMAIL_VERIFY_API_SUCCESS = "EMAIL_VERIFY_API_SUCCESS";
export const EMAIL_VERIFY_API_FAILURE = "EMAIL_VERIFY_API_FAILURE";

export const UPDATE_EMAIL_SUCCESS = "UPDATE_ADMIN_EMAIL_SUCCESS";
export const UPDATE_EMAIL_FAILURE = "UPDATE_ADMIN_EMAIL_FAILURE";
export const EMAIL_UPDATE_API_REQUEST = "EMAIL_UPDATE_API_REQUEST";
export const EMAIL_UPDATE_API_END = "EMAIL_UPDATE_API_END";

export const ADMIN_GAMERS_LIST_SUCCESS = "ADMIN_GAMERS_LIST_SUCCESS";
export const ADMIN_GAMERS_LIST_FAILURE = "ADMIN_GAMERS_LIST_FAILURE";
export const ADMIN_GAMER_LIST_API_REQUEST = "ADMIN_GAMER_LIST_API_REQUEST";
export const ADMIN_GAMERS_API_END = "ADMIN_GAMERS_API_END";
export const ADMIN_GAMER_FILTER_LIST_API_REQUEST = "ADMIN_GAMER_FILTER_LIST_API_REQUEST";
export const ADMIN_GAMERS_FILTER_LIST_SUCCESS = "ADMIN_GAMERS_FILTER_LIST_SUCCESS";
export const ADMIN_GAMERS_FILTER_LIST_FAILURE = "ADMIN_GAMERS_FILTER_LIST_FAILURE";
export const ADMIN_GAMER_API_REQUEST = "ADMIN_GAMER_API_REQUEST";
export const ADMIN_GAMER_DETAIL_SUCCESS = "ADMIN_GAMER_DETAIL_SUCCESS";
export const ADMIN_GAMER_DETAIL_FAILURE = "ADMIN_GAMER_DETAIL_FAILURE";
export const CLEAR_ADMIN_GAMER_DETAIL = "CLEAR_ADMIN_GAMER_DETAIL";
export const ADMIN_GAMER_ASSET_API_REQUEST = "ADMIN_GAMER_ASSET_API_REQUEST";
export const ADMIN_GAMER_ASSET_LIST_SUCCESS = "ADMIN_GAMER_ASSET_LIST_SUCCESS";
export const ADMIN_GAMER_ASSET_LIST_FAILURE = "ADMIN_GAMER_ASSET_LIST_FAILURE";
export const ADMIN_GAMER_OFFER_API_REQUEST = "ADMIN_GAMER_OFFER_API_REQUEST";
export const ADMIN_GAMER_OFFER_LIST_SUCCESS = "ADMIN_GAMER_OFFER_LIST_SUCCESS";
export const ADMIN_GAMER_OFFER_LIST_FAILURE = "ADMIN_GAMER_OFFER_LIST_FAILURE";
export const ADMIN_GAMER_LIST_SUCCESS = "ADMIN_GAMER_LIST_SUCCESS";
export const ADMIN_GAMER_LIST_FAILURE = "ADMIN_GAMER_LIST_FAILURE";
export const ADMIN_GAMER_ALL_LIST_SUCCESS = "ADMIN_GAMER_ALL_LIST_SUCCESS";
export const ADMIN_GAMER_ALL_LIST_FAILURE = "ADMIN_GAMER_ALL_LIST_FAILURE";

export const REDEEM_TRANSACTION_API_REQUEST = "REDEEM_TRANSACTION_API_REQUEST";
export const GET_REDEEM_TRANSACTION_SUCCESS = "GET_REDEEM_TRANSACTION_SUCCESS";
export const GET_REDEEM_TRANSACTION_FAILURE = "GET_REDEEM_TRANSACTION_FAILURE";
export const ADMIN_TRANSACTION_API_END = "ADMIN_TRANSACTION_API_END";
export const GET_REDEEM_TRANSACTION_DETAILS_SUCCESS = "GET_REDEEM_TRANSACTION_DETAILS_SUCCESS";
export const GET_REDEEM_TRANSACTION_DETAILS_FAILURE = "GET_REDEEM_TRANSACTION_DETAILS_FAILURE";
export const REJECT_REDEEM_TRANSACTION_SUCCESS = "REJECT_REDEEM_TRANSACTION_SUCCESS";
export const REJECT_REDEEM_TRANSACTION_FAILURE = "REJECT_REDEEM_TRANSACTION_FAILURE";
export const APPROVE_REDEEM_TRANSACTION_SUCCESS = "APPROVE_REDEEM_TRANSACTION_SUCCESS";
export const APPROVE_REDEEM_TRANSACTION_FAILURE = "APPROVE_REDEEM_TRANSACTION_FAILURE";
export const ADMIN_REDEEM_FILTER_LIST_SUCCESS = "ADMIN_REDEEM_FILTER_LIST_SUCCESS";
export const ADMIN_REDEEM_FILTER_LIST_FAILURE = "ADMIN_REDEEM_FILTER_LIST_FAILURE";
export const CLEAR_OFFER_DETAIL = "CLEAR_OFFER_DETAIL";

export const EXCHANGE_TRANSACTION_API_REQUEST = "EXCHANGE_TRANSACTION_API_REQUEST";
export const GET_EXCHANGE_TRANSACTION_SUCCESS = "GET_EXCHANGE_TRANSACTION_SUCCESS";
export const GET_EXCHANGE_TRANSACTION_FAILURE = "GET_EXCHANGE_TRANSACTION_FAILURE";
export const ADMIN_EXCHANGE_FILTER_LIST_SUCCESS = "ADMIN_EXCHANGE_FILTER_LIST_SUCCESS";
export const ADMIN_EXCHANGE_FILTER_LIST_FAILURE = "ADMIN_EXCHANGE_FILTER_LIST_FAILURE";

export const GAMER_LINKED_OFFERS_API_REQUEST = "ADMIN_GAMER_LINKED_OFFERS_API_REQUEST";
export const GAMER_LINKED_OFFERS_SUCCESS = "GAMER_LINKED_OFFERS_SUCCESS";
export const GAMER_LINKED_OFFERS_FAILURE = "GAMER_LINKED_OFFERS_FAILURE";
export const GAMER_EXCHANGE_TRANSACTION_SUCCESS = "GAMER_EXCHANGE_TRANSACTION_SUCCESS";
export const GAMER_EXCHANGE_TRANSACTION_FAILURE = "GAMER_EXCHANGE_TRANSACTION_FAILURE";
export const ADMIN_GAMER_REDEEM_TRANS_SUCCESS = "ADMIN_GAMER_REDEEM_TRANS_SUCCESS";
export const ADMIN_GAMER_REDEEM_TRANS_FAILURE = "ADMIN_GAMER_REDEEM_TRANS_FAILURE";
export const ADMIN_GAMER_REDEEM_TX_FILTER_SUCCESS = "ADMIN_GAMER_REDEEM_TX_FILTER_SUCCESS";
export const ADMIN_GAMER_REDEEM_TX_FILTER_FAILURE = "ADMIN_GAMER_REDEEM_TX_FILTER_FAILURE";
export const GAMER_ASSETS_SUCCESS = "GAMER_ASSETS_SUCCESS";
export const GAMER_ASSETS_FAILURE = "GAMER_ASSETS_FAILURE";

export const GAMER_OFFER_FILTER_SUCCESS = "GAMER_OFFER_FILTER_SUCCESS";
export const GAMER_OFFER_FILTER_FAILURE = "GAMER_OFFER_FILTER_FAILURE";

export const ANALYTICS_API_END = "ANALYTICS_API_END";
export const EXCHANGE_TRANSACTION_FAILURE = "EXCHANGE_TRANSACTION_FAILURE";
export const EXCHANGE_TRANSACTION_SUCCESS = "EXCHANGE_TRANSACTION_SUCCESS";
export const GAMER_LISTS_FAILURE = "GAMER_LISTS_FAILURE";
export const GAMER_LISTS_SUCCESS = "GAMER_LISTS_SUCCESS";

export const PARTNER_GAME_DETAIL_REQUEST = "PARTNER_GAME_DETAIL_REQUEST";
export const GET_GAME_DETAILS_SUCCESS = "GET_GAME_DETAILS_SUCCESS";
export const GET_GAME_DETAILS_FAILURE = "GET_GAME_DETAILS_FAILURE";
export const GET_OFFER_LIST_SUCCESS = "GET_OFFER_LIST_SUCCESS";
export const GET_OFFER_LIST_FAILURE = "GET_OFFER_LIST_FAILURE";

export const PARTNER_OFFER_NAMES_API_REQUEST = "PARTNER_OFFER_NAMES_API_REQUEST";
export const PARTNER_OFFER_NAMES_API_END = "PARTNER_OFFER_NAMES_API_END";
export const CLONE_OFFER_TEMPLATE_SUCCESS = "CLONE_OFFER_TEMPLATE_SUCCESS";

export const ADMIN_LINKED_GAMER_LIST_API_REQUEST = "ADMIN_LINKED_GAMER_LIST_API_REQUEST";
export const ADMIN_LINKED_GAMERS_LIST_SUCCESS = "ADMIN_LINKED_GAMERS_LIST_SUCCESS";
export const ADMIN_LINKED_GAMERS_LIST_FAILURE = "ADMIN_LINKED_GAMERS_LIST_FAILURE";
export const ADMIN_LINKED_GAMER_FILTER_LIST_API_REQUEST = "ADMIN_LINKED_GAMER_FILTER_LIST_API_REQUEST";
export const ADMIN_LINKED_GAMERS_FILTER_LIST_SUCCESS = "ADMIN_LINKED_GAMERS_FILTER_LIST_SUCCESS";
export const ADMIN_LINKED_GAMERS_FILTER_LIST_FAILURE = "ADMIN_LINKED_GAMERS_FILTER_LIST_FAILURE";

export const ADMIN_GAMES_LINKED_GAMER_LIST_REQUEST = "ADMIN_GAMES_LINKED_GAMER_LIST_REQUEST";
export const ADMIN_GAMES_LINKED_GAMERS_LIST_SUCCESS = "ADMIN_GAMES_LINKED_GAMERS_LIST_SUCCESS";
export const ADMIN_GAMES_LINKED_GAMERS_LIST_FAILURE = "ADMIN_GAMES_LINKED_GAMERS_LIST_FAILURE";
export const ADMIN_GAMES_LINKED_GAMERS_FILTER_LIST_SUCCESS = "ADMIN_GAMES_LINKED_GAMERS_FILTER_LIST_SUCCESS";
export const ADMIN_GAMES_LINKED_GAMERS_FILTER_LIST_FAILURE = "ADMIN_GAMES_LINKED_GAMERS_FILTER_LIST_FAILURE";
export const ADMIN_GAMES_LINKED_GAMER_FILTER_LIST_API_REQUEST = "ADMIN_GAMES_LINKED_GAMER_FILTER_LIST_API_REQUEST";

export const PARTNER_ANALYTICS_GAMER_LIST_REQUEST = "PARTNER_ANALYTICS_GAMER_LIST_REQUEST";
export const PARTNER_ANALYTICS_GAMER_LIST_SUCCESS = "PARTNER_ANALYTICS_GAMER_LIST_SUCCESS";
export const PARTNER_ANALYTICS_GAMER_LIST_FAILURE = "PARTNER_ANALYTICS_GAMER_LIST_FAILURE";

export const ADMIN_TOURNAMENT_API_REQUEST = "ADMIN_TOURNAMENT_API_REQUEST";
export const ADMIN_TOURNAMENTS_API_END = "ADMIN_TOURNAMENTS_API_END";
export const GET_ADMIN_TOURNAMENTS_SUCCESS = "GET_ADMIN_TOURNAMENTS_SUCCESS";
export const GET_ADMIN_TOURNAMENTS_FAILURE = "GET_ADMIN_TOURNAMENTS_FAILURE";
export const LEADER_BOARD_API_REQUEST = "PARTNER_OFFER_API_REQUEST";
export const LEADER_BOARD_API_END = "LEADER_BOARD_API_END";
export const GET_LEADER_BOARD_SUCCESS = "GET_LEADER_BOARD_SUCCESS";
export const GET_LEADER_BOARD_FAILURE = "GET_LEADER_BOARD_FAILURE";

export const PARTNER_ANALYTICS_ASSETS_LIST_REQUEST = "PARTNER_ANALYTICS_ASSETS_LIST_REQUEST";
export const PARTNER_ANALYTICS_ASSETS_LIST_SUCCESS = "PARTNER_ANALYTICS_ASSETS_LIST_SUCCESS";
export const PARTNER_ANALYTICS_ASSETS_LIST_FAILURE = "PARTNER_ANALYTICS_ASSETS_LIST_FAILURE";

export const RESET_EXCHANGE_TRANSACTION_REQUEST = "RESET_EXCHANGE_TRANSACTION_REQUEST";

export const PARTNER_GAMERS_LIST_SUCCESS = "PARTNER_GAMERS_LIST_SUCCESS";
export const PARTNER_GAMERS_LIST_FAILURE = "PARTNER_GAMERS_LIST_FAILURE";
export const PARTNER_GAMER_LIST_API_REQUEST = "PARTNER_GAMER_LIST_API_REQUEST";
export const PARTNER_GAMERS_API_END = "PARTNER_GAMERS_API_END";
export const PARTNER_GAMER_FILTER_LIST_API_REQUEST = "PARTNER_GAMER_FILTER_LIST_API_REQUEST";
export const PARTNER_GAMERS_FILTER_LIST_SUCCESS = "PARTNER_GAMERS_FILTER_LIST_SUCCESS";
export const PARTNER_GAMERS_FILTER_LIST_FAILURE = "PARTNER_GAMERS_FILTER_LIST_FAILURE";

export const PARTNER_GAMER_DETAIL_API_REQUEST = "PARTNER_GAMER_DETAIL_API_REQUEST";
export const PARTNER_GAMER_DETAIL_SUCCESS = "PARTNER_GAMER_DETAIL_SUCCESS";
export const PARTNER_GAMER_DETAIL_FAILURE = "PARTNER_GAMER_DETAIL_FAILURE";

export const PARTNER_GAMER_TOURNAMENTS_LIST_API_REQUEST = "PARTNER_GAMER_TOURNAMENTS_LIST_API_REQUEST";
export const PARTNER_GAMER_TOURNAMENTS_LIST_SUCCESS = "PARTNER_GAMER_TOURNAMENTS_LIST_SUCCESS";
export const PARTNER_GAMER_TOURNAMENTS_LIST_FAILURE = "PARTNER_GAMER_TOURNAMENTS_LIST_FAILURE";

export const ADMIN_GAMER_TOURNAMENTS_LIST_API_REQUEST = "ADMIN_GAMER_TOURNAMENTS_LIST_API_REQUEST";
export const ADMIN_GAMER_TOURNAMENTS_LIST_SUCCESS = "ADMIN_GAMER_TOURNAMENTS_LIST_SUCCESS";
export const ADMIN_GAMER_TOURNAMENTS_LIST_FAILURE = "ADMIN_GAMER_TOURNAMENTS_LIST_FAILURE";
export const ADMIN_GAMER_TOURNAMENTS_FILTER_SUCCESS = "ADMIN_GAMER_TOURNAMENTS_FILTER_SUCCESS";
export const ADMIN_GAMER_TOURNAMENTS_FILTER_FAILURE = "ADMIN_GAMER_TOURNAMENTS_FILTER_FAILURE";

export const CLEAR_ADMIN_REDEEM_TRANSACTION = "CLEAR_ADMIN_REDEEM_TRANSACTION";

export const PARTNER_GAMER_ASSETS_LIST_API_REQUEST = "PARTNER_GAMER_ASSETS_LIST_API_REQUEST";
export const PARTNER_GAMER_ASSETS_LIST_SUCCESS = "PARTNER_GAMER_ASSETS_LIST_SUCCESS";
export const PARTNER_GAMER_ASSETS_LIST_FAILURE = "PARTNER_GAMER_ASSETS_LIST_FAILURE";

export const PARTNER_GAMER_LINKED_GAMES_API_REQUEST = "PARTNER_GAMER_LINKED_GAMES_API_REQUEST";
export const PARTNER_GAMER_LINKED_GAMES_SUCCESS = "PARTNER_GAMER_LINKED_GAMES_SUCCESS";
export const PARTNER_GAMER_LINKED_GAMES_FAILURE = "PARTNER_GAMER_LINKED_GAMES_FAILURE";

export const PARTNER_GAMER_LINKED_GAMES_DETAIL_API_REQUEST = "PARTNER_GAMER_LINKED_GAMES_DETAIL_API_REQUEST";
export const PARTNER_GAMER_LINKED_GAMES_DETAIL_SUCCESS = "PARTNER_GAMER_LINKED_GAMES_DETAIL_SUCCESS";
export const PARTNER_GAMER_LINKED_GAMES_DETAIL_FAILURE = "PARTNER_GAMER_LINKED_GAMES_DETAIL_FAILURE";

export const PARTNER_GAMER_LINKED_GAMES_ASSETS_API_REQUEST = "PARTNER_GAMER_LINKED_GAMES_ASSETS_API_REQUEST";
export const PARTNER_GAMER_LINKED_GAMES_ASSETS_SUCCESS = "PARTNER_GAMER_LINKED_GAMES_ASSETS_SUCCESS";
export const PARTNER_GAMER_LINKED_GAMES_ASSETS_FAILURE = "PARTNER_GAMER_LINKED_GAMES_ASSETS_FAILURE";

export const PARTNER_GAMER_LINKED_GAMES_OFFERS_API_REQUEST = "PARTNER_GAMER_LINKED_GAMES_OFFERS_API_REQUEST";
export const PARTNER_GAMER_LINKED_GAMES_OFFERS_SUCCESS = "PARTNER_GAMER_LINKED_GAMES_OFFERS_SUCCESS";
export const PARTNER_GAMER_LINKED_GAMES_OFFERS_FAILURE = "PARTNER_GAMER_LINKED_GAMES_OFFERS_FAILURE";

export const PARTNER_GAMER_EXCHANGE_TRANSACTION_API_REQUEST = "PARTNER_GAMER_EXCHANGE_TRANSACTION_API_REQUEST";
export const PARTNER_GAMER_EXCHANGE_TRANSACTION_SUCCESS = "PARTNER_GAMER_EXCHANGE_TRANSACTION_SUCCESS";
export const PARTNER_GAMER_EXCHANGE_TRANSACTION_FAILURE = "PARTNER_GAMER_EXCHANGE_TRANSACTION_FAILURE";

export const ADMIN_SIGN_OUT_CONFIRMATION_SUCCESS = "ADMIN_SIGN_OUT_CONFIRMATION_SUCCESS";
export const ADMIN_SIGN_OUT_CONFIRMATION_FAILURE = "ADMIN_SIGN_OUT_CONFIRMATION_FAILURE";

export const GET_PARTNER_ANALYTICS_GAME_LIST_SUCCESS = "GET_PARTNER_ANALYTICS_GAME_LIST_SUCCESS";

export const CLEAR_EXCHANGE_TRANSACTION_GRAPH_DATA = "CLEAR_EXCHANGE_TRANSACTION_GRAPH_DATA";
export const CLEAR_QX_MEMBERS_GRAPH_DATA = "CLEAR_QX_MEMBERS_GRAPH_DATA";
export const CLEAR_GAMES_GRAPH_DATA = "CLEAR_GAMES_GRAPH_DATA";

export const CLEAR_ANALYTICS_GAME_DETAIL = "CLEAR_ANALYTICS_GAME_DETAIL";

export const ADMIN_GAMER_TXN_HISTORY_LIST_API_REQUEST = "ADMIN_GAMER_TXN_HISTORY_LIST_API_REQUEST";
export const ADMIN_GAMER_TXN_HISTORY_LIST_SUCCESS = "ADMIN_GAMER_TXN_HISTORY_LIST_SUCCESS";
export const ADMIN_GAMER_TXN_HISTORY_LIST_FAILURE = "ADMIN_GAMER_TXN_HISTORY_LIST_FAILURE";

export const TRANSACTION_LIST_FAILURE = "TRANSACTION_LIST_FAILURE";
export const TRANSACTION_LIST_SUCCESS = "TRANSACTION_LIST_SUCCESS";

export const ANALYTICS_LINKED_GAMER_LIST_REQUEST = "ANALYTICS_LINKED_GAMER_LIST_REQUEST";
export const ANALYTICS_LINKED_GAMER_LIST_SUCCESS = "ANALYTICS_LINKED_GAMER_LIST_SUCCESS";
export const ANALYTICS_LINKED_GAMER_LIST_FAILURE = "ANALYTICS_LINKED_GAMER_LIST_FAILURE";

export const CLEAR_ADMIN_GAMER_OFFERS = "CLEAR_ADMIN_GAMER_OFFERS";

export const GAMER_OFFER_REWARD_TXN_API_REQUEST = "GAMER_OFFER_REWARD_TXN_API_REQUEST";
export const GAMER_OFFER_REWARD_TXN_SUCCESS = "GAMER_OFFER_REWARD_TXN_SUCCESS";
export const GAMER_OFFER_REWARD_TXN_FAILURE = "GAMER_OFFER_REWARD_TXN_FAILURE";
