import constant from "../util/constant";
import { getOfferStatus, convertDate } from "../util/helpers";
import offerConstant from "../util/offerConstant";
import { PartnerGameDetails } from "./partnerGameDetails";

const { OFFER_STATUS } = offerConstant;
const { OPT_IN_STATUS } = constant;

export class GameDetail extends PartnerGameDetails {
  constructor( obj ) {
    super( obj );
    this.description = obj?.description;
    this.isPartnerActive = obj?.Partner?.is_active || false;
    this.partnerName = obj?.Partner?.legal_entity_name || "";
    this.partnerCostUsdPerQxPoint = obj?.Partner?.partner_cost_usd_per_qx_point || 0;
    this.exchangeableAssets = obj?.GameAssetDetails?.length
      ? obj?.GameAssetDetails?.map( ( gameAsset ) => {
        return {
          gameAssetId: gameAsset.game_asset_id,
          name: gameAsset.name,
          price: gameAsset.unit_price_qx_points,
        };
      } )
      : [];
    this.associatedOffers = obj?.OfferSchedules?.length
      ? obj?.OfferSchedules?.map( ( val ) => {
        return {
          offerId: val?.offer_id || "",
          startDate: convertDate( val?.start_date ) || "",
          endDate: convertDate( val?.end_date ) || "",
          offerName: val?.Offer?.name || "",
          statusTitle: OFFER_STATUS.find(
            offer => offer.status === getOfferStatus( val )
          ),
          maxRewardCountPerUser: val?.max_reward_count_per_user || "",
          descriptionText: val?.Offer?.description_text || "",
          rewardUnit: val?.Offer?.OfferRewards?.reward_unit || "",
          rewardValue: val?.Offer?.OfferRewards?.length ? val.Offer.OfferRewards[ 0 ].reward_value : "",
          optIn: obj?.optIn || OPT_IN_STATUS.NO,
          rewards: ( obj?.entityType && val?.Offer?.OfferRewards.length )
            ? val?.Offer?.OfferRewards.map( reward => ( {
              qxPoint: reward.reward_value,
              place: reward.place,
            } ) )
            : [],
          offerStatistics: val?.offerStatistics || {},
        };
      } )
      : [];
    this.gameStatistics = obj?.gameStatistics || {};
  }
}
