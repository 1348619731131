import { debounce } from "lodash";
import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm, useAddQuery, useScroll } from "../../../hooks";
import { getAdminEventsAction } from "../../../store/actions/adminEvent";
import { getAdminFilterListAction } from "../../../store/actions/adminFilterList";
import constant from "../../../util/constant";
import eventConstant from "../../../util/eventConstant";
import { concatStrings, useSetUrlParams, checkFilterApplied } from "../../../util/helpers";
import { Events, SubHeader } from "../../shared/events";

const { ADMIN_TAB, EVENT_API_TYPES } = eventConstant;
const { ROUTES, ORDER_BY, API_STATUS, FILTERS_NAME } = constant;

const AdminEventList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { addQuery } = useAddQuery();
  const { scrollEvent } = useScroll();
  const isFilterApplied = checkFilterApplied( window.location.search, FILTERS_NAME.EVENTS );
  const paramsQuery = new URLSearchParams( window.location.search );
  const { events, eventsMetaData, status, apiType, eventNameList, eventIdsList, eventUnitList, partnerList } = useSelector( state => ( {
    events: state.adminEvent.adminEvents.events,
    eventsMetaData: state.adminEvent.adminEvents.meta,
    status: state.adminEvent.status,
    apiType: state.adminEvent.apiType,
    eventNameList: state.adminFilterList.filterData.eventNameList,
    eventUnitList: state.adminFilterList.filterData.eventUnitList,
    eventIdsList: state.adminFilterList.filterData.eventIdList,
    partnerList: state.adminFilterList.filterData.partnerList,
  } ) );
  const [ showFilter, setShowFilter ] = useState( false );

  function getUrlParameter( key, col ) {
    let paramsQuerys;

    if( ( key === "searchKey" ) || ( key === "sortColumn" ) || ( key === "sortOrder" ) ) {
      paramsQuerys = paramsQuery.get( key ) === "" ? col : paramsQuery.get( key );
    }

    if( ( key === "eventNameList" ) || ( key === "unitList" ) || ( key === "partnerList" ) || ( key === "eventIdsList" ) ) {
      paramsQuerys = paramsQuery.get( key ) === "" ? [] : JSON.parse( paramsQuery.get( key ) );
    }

    return paramsQuerys;
  }

  const { values, handleChange } = useForm(
    {
      page: 1,
      showPartnerEvents: false,
      isAdmin: true,
      activeTab: ADMIN_TAB.TAB1.tab,
      searchDummyText: getUrlParameter( "searchKey", "" ),
      searchKey: getUrlParameter( "searchKey", "" ),
      sortBy: getUrlParameter( "sortColumn", "" ),
      sortOrder: getUrlParameter( "sortOrder", ORDER_BY.ASC ),
      eventNameList: getUrlParameter( "eventNameList", "" ),
      unitList: getUrlParameter( "unitList", "" ),
      partnerList: getUrlParameter( "partnerList", "" ),
      eventIdsList: getUrlParameter( "eventIdsList", "" ),
    },
    {}
  );

  const handleTabClick = ( selectedTab, eventType ) => {
    handleChange( "showPartnerEvents", eventType );
    handleChange( "activeTab", selectedTab );
  };

  useEffect( () => {
    const requestParams = Object.assign(
      {
        page: values.page,
        showPartnerEvents: values.showPartnerEvents,
        sortOrder: values.sortOrder,
        perPage: 10,
        // TODO: refactor to remove use of undefined
        // May require a change to API
        // eslint-disable-next-line no-undefined
        eventNameList: concatStrings( values.eventNameList, "," ) || undefined,
        // TODO: refactor to remove use of undefined
        // May require a change to API
        // eslint-disable-next-line no-undefined
        unitList: concatStrings( values.unitList, "," ) || undefined,
        // TODO: refactor to remove use of undefined
        // May require a change to API
        // eslint-disable-next-line no-undefined
        partnerList: concatStrings( values.partnerList, "," ) || undefined,
        // TODO: refactor to remove use of undefined
        // May require a change to API
        // eslint-disable-next-line no-undefined
        eventIdsList: concatStrings( values.eventIdsList, "," ) || undefined,
      },
      values.searchKey && { searchKey: values.searchKey },
      values.sortBy && { sortBy: values.sortBy }
    );

    dispatch( getAdminEventsAction( requestParams ) );
  }, [
    values.sortBy,
    values.searchKey,
    values.showPartnerEvents,
    values.activeTab,
    values.sortOrder,
    values.page,
    values.eventNameList,
    values.unitList,
    values.partnerList,
    values.eventIdsList,
  ] );

  useEffect( () => {
    if(
      status === API_STATUS.SUCCESS &&
      apiType === EVENT_API_TYPES.GET_ADMIN_EVENTS
    ) {
      dispatch( getAdminFilterListAction( { entities: "Event,Partner,EventUnit" } ) );
    }
  }, [ status, apiType ] );

  const handleSorting = ( sortBy, sortingType ) => {
    handleChange( "page", 1 );
    handleChange( "sortOrder", sortingType );
    handleChange( "sortBy", sortBy );
    addQuery( useSetUrlParams( "sortColumn", sortBy ) );
    addQuery( useSetUrlParams( "sortOrder", sortingType ) );
  };

  const onScrolls = ( e ) => {
    scrollEvent( e, events, values, handleChange, eventsMetaData, "page" );
  };

  const debouncedSave = useCallback(
    debounce( nextValue => handleChange( "searchKey", nextValue ), 1000 ),
    []
  );

  const searchHandle = ( e ) => {
    const { value: nextValue } = e.target;

    addQuery( useSetUrlParams( "searchKey", nextValue ) );
    handleChange( "searchDummyText", nextValue );
    debouncedSave( nextValue );
    handleChange( "page", 1 );
  };

  return (
    <>
      <div className='main-wrapper'>
        <div className='main-right-wrapper'>
          <SubHeader
            values={values}
            tabConfig={ADMIN_TAB}
            onClickTab={handleTabClick}
            onAddNew={() => history.push(
              ROUTES.ADMIN_ADD_EDIT_EVENT.replace( ":eventId", 0 )
            )}
            onSearch={searchHandle}
            handleChange={handleChange}
            eventNameList={eventNameList}
            eventUnitList={eventUnitList}
            partnerList={partnerList}
            eventIdsList={eventIdsList}
            addQuery={addQuery}
            isFilterApplied={isFilterApplied}
            updateShowFilterStatus={setShowFilter}
          />
          <Events
            events={events}
            onSorting={handleSorting}
            onScroll={onScrolls}
            eventEditUrl={ROUTES.ADMIN_ADD_EDIT_EVENT}
            role='admin'
            showFilter={showFilter}
          />
        </div>
      </div>
    </>
  );
};

export default AdminEventList;
