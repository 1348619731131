import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { useAddQuery } from "../../../hooks";
import { getAdminGameDetailsAction } from "../../../store/actions/adminGames";
import adminGameConstant from "../../../util/adminGameConstant";
import constant from "../../../util/constant";
import { getBackNavigationRoute } from "../../../util/helpers";
import BackButton from "../../shared/backButton";
import LinkedGameDetails from "../../shared/gamer/linkedGameDetails";
import { GameDetail, GamerAssets, GamerOffers } from "../../shared/games";

const { API_STATUS, ROUTES } = constant;
const { ADMIN_GAME_API_TYPES } = adminGameConstant;

const GamerGameDetail = ( props ) => {
  const dispatch = useDispatch();
  const { addQuery } = useAddQuery();
  const history = useHistory();
  const location = useLocation();
  const paramsQuery = new URLSearchParams( window.location.search );
  const type = paramsQuery.get( "type" );
  const backScreens = [ "gamerGameDetail" ];
  const { gameDetails, apiType, status } = useSelector( state => ( {
    gameDetails: state.adminGame.gameDetails,
    apiType: state.adminGame.apiType,
    status: state.adminGame.status,
  } ) );
  const gameId = props.match?.params?.gameId || "";
  const gamerIdString = props.match?.params?.gamerId || "";
  const { 0: gamerId } = gamerIdString.toString().split( "?" );
  const [ tab, setTab ] = useState( [
    { tabKey: "gameTab",
      value: true,
      className: "pt-0",
      testId: "setView",
      fun: "gameTab",
      tabName: "Game Details",
      component: GameDetail,
    },
    { tabKey: "assetsTab",
      value: false,
      className: "pt-0",
      testId: "assetsTabKey",
      fun: "assetsTab",
      tabName: "Gamer's Assets",
      component: GamerAssets,
    },
    { tabKey: "offersTab",
      value: false,
      className: "pt-0",
      testId: "offersTabKey",
      fun: "offersTab",
      tabName: "Gamer's Offers",
      component: GamerOffers,
    },
  ]
  );

  const setTabsData = ( value ) => {
    setTab(
      tab.map( item =>
        ( item.tabKey === value
          ? { ...item, value: true }
          : { ...item, value: false } )
      )
    );
  };

  useEffect( () => {
    if( gameId ) {
      dispatch( getAdminGameDetailsAction( gameId ) );
      setTabsData( type );
    }
  }, [ gameId ] );
  useEffect( () => {
    if(
      status === API_STATUS.FAILURE &&
      apiType === ADMIN_GAME_API_TYPES.GET_ADMIN_GAME_DETAILS
    ) {
      history.goBack();
    }
  }, [ apiType, status ] );

  const handleBackNavigation = () => {
    return getBackNavigationRoute( ROUTES.GAMER_DETAIL, location, backScreens );
  };

  return (
    <div className='main-wrapper'>
      <div className='main-right-wrapper'>
        <BackButton
          backLink={handleBackNavigation()}
          state={location.state}
        />
        <LinkedGameDetails
          tab={tab}
          props={props}
          gameId={gameId}
          gamerId={gamerId}
          addQuery={addQuery}
          gameName={gameDetails.gameName}
          setTabsData={setTabsData}
          locationState={location.state}
        />
      </div>
    </div>
  );
};

GamerGameDetail.propTypes = {
  match: PropTypes.string,
};

export default GamerGameDetail;
