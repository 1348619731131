import EditIcon from "@material-ui/icons/Edit";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useCallback, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Scrollbars } from "react-custom-scrollbars";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAddQuery, useForm, useScroll } from "../../../hooks";
import { getAdminUserAction, getAdminUserFilterListAction } from "../../../store/actions/adminUser";
import adminUserConstant from "../../../util/adminUserConstant";
import ButtonMailto from "../../../util/buttonMailto";
import constant from "../../../util/constant";
import { useSetUrlParams, concatStrings, checkFilterApplied } from "../../../util/helpers";
import { adminUserTableConfigs } from "../../../util/tableConstant";
import FilterIcon from "../../shared/FilterIcon/FilterIcon";
import { ScrollPage } from "../../shared/customScrollBar";
import MultiSelectCheckbox from "../../shared/multiSelectCheckbox";
import TableHeaderRow from "../../shared/tableHeadingRow";

const { ROUTES, ORDER_BY, API_STATUS, FILTERS_NAME } = constant;
const { ADMIN_USERS_API_TYPES, ADMIN_USER_FILTER_LIST } = adminUserConstant;

const AdminUserList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { addQuery } = useAddQuery();
  const { scrollEvent } = useScroll();
  const isFilterApplied = checkFilterApplied( window.location.search, FILTERS_NAME.ADMIN_USERS );
  const { users, usersMetaData, status, apiType, adminFilterDetail } = useSelector( state => ( {
    users: state.adminUser.adminUsers.users,
    usersMetaData: state.adminUser.adminUsers.meta,
    status: state.adminUser.status,
    apiType: state.adminUser.apiType,
    adminFilterDetail: state.adminUser.adminFilterDetail,
  } ) );
  const paramsQuery = new URLSearchParams( window.location.search );
  const { values, handleChange } = useForm(
    {
      searchDummyText: paramsQuery.get( "searchKey" ) === "" ? "" : paramsQuery.get( "searchKey" ),
      searchKey: paramsQuery.get( "searchKey" ) === "" ? "" : paramsQuery.get( "searchKey" ),
      sortBy: paramsQuery.get( "sortBy" ) === "" ? "full_name" : paramsQuery.get( "sortBy" ),
      sortOrder: paramsQuery.get( "sortOrder" ) === "" ? ORDER_BY.ASC : paramsQuery.get( "sortOrder" ),
      page: 1,
      fullNames: paramsQuery.get( "fullNames" ) === "" ? [] : JSON.parse( paramsQuery.get( "fullNames" ) ),
      usernames: paramsQuery.get( "usernames" ) === "" ? [] : JSON.parse( paramsQuery.get( "usernames" ) ),
      adminEmails: paramsQuery.get( "adminEmails" ) === "" ? [] : JSON.parse( paramsQuery.get( "adminEmails" ) ),
      adminStatus: paramsQuery.get( "adminStatus" ) === "" ? [] : JSON.parse( paramsQuery.get( "adminStatus" ) ),
    },
    {}
  );
  const requestParams = Object.assign(
    {
      sortOrder: values.sortOrder,
      perPage: 10,
      page: values.page,
    },
    values.searchKey && { searchKey: values.searchKey },
    values.sortBy && { sortBy: values.sortBy },
    {
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      fullNames: concatStrings( values.fullNames, "," ) || undefined,
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      usernames: concatStrings( values.usernames, "," ) || undefined,
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      adminEmails: concatStrings( values.adminEmails, "," ) || undefined,
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      adminStatus: concatStrings( values.adminStatus, "," ) || undefined,
    }
  );

  const handleSorting = ( sortBy, sortingType ) => {
    handleChange( "page", 1 );
    handleChange( "sortBy", sortBy );
    handleChange( "sortOrder", sortingType );
    addQuery( useSetUrlParams( "sortBy", sortBy ) );
    addQuery( useSetUrlParams( "sortOrder", sortingType ) );
  };

  const onScroll = ( e ) => {
    scrollEvent( e, users, values, handleChange, usersMetaData, "page" );
  };

  useEffect( () => {
    if(
      status === API_STATUS.SUCCESS &&
      apiType === ADMIN_USERS_API_TYPES.GET_ADMIN_USERS
    ) {
      dispatch( getAdminUserFilterListAction(
        {
          fetchAllRecords: true,
          fieldsToBeFetched: "full_name,email,username",
        } ) );
    }
  }, [ status, apiType ] );

  useEffect( () => {
    dispatch( getAdminUserAction( requestParams ) );
  }, [
    values.sortOrder,
    values.page,
    values.sortBy,
    values.searchKey,
    values.fullNames,
    values.usernames,
    values.adminEmails,
    values.adminStatus,
  ] );

  const debouncedSave = useCallback(
    debounce( nextValue => handleChange( "searchKey", nextValue ), 1000 ),
    []
  );

  const searchHandle = ( e ) => {
    const { value: nextValue } = e.target;

    addQuery( useSetUrlParams( "searchKey", nextValue ) );
    handleChange( "searchDummyText", nextValue );
    debouncedSave( nextValue );
    handleChange( "page", 1 );
  };

  const memoizedTableConfig = useMemo( () => {
    return adminUserTableConfigs;
  }, [] );

  const memoizedHandleClick = useCallback(
    ( sortBy, orderType ) => handleSorting( sortBy, orderType ),
    []
  );

  const multiSelectedItems = useMemo( () => [ values.fullNames, values.usernames, values.adminEmails, values.adminStatus ] );
  const handleSelectChange = useCallback(
    [ obj => listHandle( obj, "fullNames" ),
      obj => listHandle( obj, "usernames" ),
      obj => listHandle( obj, "adminEmails" ),
      obj => listHandle( obj, "adminStatus" ),
    ],
    []
  );
  const handleClearMultiSelect = useCallback(
    parmas => clearHandle( parmas ), []
  );

  const listHandle = ( obj, listName ) => {
    if( obj.length > 0 ) {
      handleChange( "page", 1 );
      handleChange( listName, obj );
      addQuery( useSetUrlParams( listName, JSON.stringify( obj ) ) );
    }
    else {
      addQuery( useSetUrlParams( listName, "" ) );
      handleChange( listName );
    }
  };

  const clearHandle = ( parmas ) => {
    if( parmas ) {
      addQuery( useSetUrlParams( parmas, "" ) );
      handleChange( parmas, [] );
    }
  };

  const [ showFilter, setShowFilter ] = useState( false );
  const showFilterScreen = () => setShowFilter( !showFilter );

  return (
    <>
      <div className="main-right-wrapper">
        <div className="page-head-wrapper">
          <h4 className="page-heading">Admin Users</h4>
          <div className="page-head-right">
            <Form.Group className="custom-search w-300">
              <span className="icon-search"></span>
              <Form.Control
                type="text"
                placeholder="Search by Full Name, Username..."
                data-testid='searchKey'
                onChange={searchHandle}
                value={values.searchDummyText}
                name='searchKey'
                title='Search by Full Name, Username, and Email'
              />
            </Form.Group>
            <Button variant="primary" className="small-btn" onClick={() => history.push(
              ROUTES.ADMIN_ADD_EDIT_USER.replace( ":userId", 0 )
            )}>
              <span className="icon-circle-plus-green icon"></span>
              Add New
            </Button>
            <FilterIcon
              showFilter={showFilter}
              showFilterScreen={showFilterScreen}
              isFilterApplied={isFilterApplied} />
          </div>
        </div>

        <div className={`filter-tabs-wrapper ${ !showFilter ? "" : "remove-filter" }`}>

          <div className='filter-boxes offer-filter mr-0'>
            <Row>
              <Col md={3}>
                <Form.Group className='custom-multiselect mb-0' data-testid="full-name">
                  <Form.Label>Full Name</Form.Label>
                  <MultiSelectCheckbox
                    multiOptionsList={adminFilterDetail?.fullNames}
                    handleSelectChange={handleSelectChange[ 0 ]}
                    multiSelectedItems={multiSelectedItems[ 0 ]}
                    handleClearMultiSelect={() => handleClearMultiSelect( "fullNames" )} />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className='custom-multiselect mb-0' data-testid="username">
                  <Form.Label>Username</Form.Label>
                  <MultiSelectCheckbox
                    multiOptionsList={adminFilterDetail?.usernames}
                    handleSelectChange={handleSelectChange[ 1 ]}
                    multiSelectedItems={multiSelectedItems[ 1 ]}
                    handleClearMultiSelect={() => handleClearMultiSelect( "usernames" )} />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className='custom-multiselect mb-0' data-testid="useremail">
                  <Form.Label>Email</Form.Label>
                  <MultiSelectCheckbox
                    multiOptionsList={adminFilterDetail?.adminEmails}
                    handleSelectChange={handleSelectChange[ 2 ]}
                    multiSelectedItems={multiSelectedItems[ 2 ]}
                    handleClearMultiSelect={() => handleClearMultiSelect( "adminEmails" )} />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className='custom-multiselect mb-0' data-testid="status">
                  <Form.Label>Status</Form.Label>
                  <MultiSelectCheckbox
                    multiOptionsList={ADMIN_USER_FILTER_LIST}
                    handleSelectChange={handleSelectChange[ 3 ]}
                    multiSelectedItems={multiSelectedItems[ 3 ]}
                    handleClearMultiSelect={() => handleClearMultiSelect( "adminStatus" )} />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </div>


        {/* Table start from here */}
        <div className="table-container admin-user-table">
          <div className="table-head">
            <div className="table-row">
              <TableHeaderRow
                configData={memoizedTableConfig}
                onAscOrder={memoizedHandleClick}
                onDescOrder={memoizedHandleClick}
              />
            </div>
          </div>
          <Scrollbars
            renderThumbVertical={ScrollPage}
            className={`custom-scroll-height ${ !showFilter ? "" : "full-height" }`}
            onScroll={onScroll}>
            <div className="table-body no-scrollbar" >
              {Boolean( users.length ) && users.map( ( user, index ) => (
                <div className="table-row" key={index}>
                  <div className="td">{user.fullName}</div>
                  <div className="td">{user.username}</div>
                  <div className="td">
                    <ButtonMailto mailto={`mailto:${ user.email }`} label={user.email}></ButtonMailto>
                  </div>
                  <div className="td">{user.isActive ? "Enabled" : "Disabled"}</div>
                  <div className="td">
                    <span className="icon cursor-pointer" onClick={() => history.push(
                      ROUTES.ADMIN_ADD_EDIT_USER.replace( ":userId", user.adminId )
                    )}><EditIcon /></span>
                  </div>
                </div>
              ) )}
              {!users.length && (
                <div className='no-record' data-testid='no-record-found'>
                  No Admin User Found
                </div>
              )}
            </div>
          </Scrollbars>
        </div>
        {/* end table */}
      </div>
    </>
  );
};

export default AdminUserList;
