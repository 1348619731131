import PropTypes from "prop-types";
import React from "react";

import GameAssets from "./gameAssets";
import GameInfo from "./gameInfo";
import GameOffers from "./gameOffers";

const ViewGameDetail = ( { viewType, gameDetails, gamerId, usdPerQxPoint, partnerCostUsdPerQxPoint } ) => {
  return (
    <>
      {viewType && (
        <div>
          <GameInfo
            gamerId={gamerId}
            gameDetails={gameDetails}
          />
          <GameAssets
            usdPerQxPoint={usdPerQxPoint}
            exchangeableAssets={gameDetails?.exchangeableAssets}
            partnerCostUsdPerQxPoint={gameDetails.partnerCostUsdPerQxPoint || partnerCostUsdPerQxPoint}
          />
          <GameOffers
            gameIcon={gameDetails?.gameIcon}
            gameName={gameDetails?.gameName}
            associatedOffers={gameDetails?.associatedOffers}
          />
        </div>
      )}
    </>
  );
};

ViewGameDetail.propTypes = {
  viewType: PropTypes.bool,
  gamerId: PropTypes.string,
  gameDetails: PropTypes.object,
  usdPerQxPoint: PropTypes.number,
  partnerCostUsdPerQxPoint: PropTypes.number,
};

export default ViewGameDetail;
