import { reducer as toastrReducer } from "react-redux-toastr";
import { combineReducers } from "redux";
import { CLEAR_STORE } from "../actionTypes";
import { partnerList, partnerDetail, admin, adminDetail } from "./admin";
import adminEvent from "./adminEvent";
import { adminFilterList } from "./adminFilterList";
import adminGame from "./adminGame";
import adminGamer from "./adminGamer";
import adminLookup from "./adminLookup";
import adminOffer from "./adminOffer";
import adminOfferDraft from "./adminOfferDraft";
import adminTournament from "./adminTournament";
import adminTransaction from "./adminTransaction";
import adminUser from "./adminUser";
import user from "./currentUser";
import emailUpdate from "./emailUpdate";
import emailVerify from "./emailVerify";
import leaderboard from "./leaderboard";
import { loader } from "./loader";
import notification from "./notification";
import partner from "./partner";
import partnerAnalytics from "./partnerAnalytics";
import partnerDraft from "./partnerDraft";
import partnerEvent from "./partnerEvent";
import partnerGame from "./partnerGame";
import partnerGamer from "./partnerGamer";
import { partnerLookup, partnerOfferNames } from "./partnerLookup";
import partnerOffer from "./partnerOffer";
import partnerOfferDraft from "./partnerOfferDraft";
import profileInfo from "./profileInfo";

const reducer = combineReducers( {
  loader,
  user,
  toastr: toastrReducer,
  profileInfo,
  partner,
  partnerList,
  partnerDetail,
  admin,
  partnerGame,
  adminGame,
  partnerEvent,
  adminEvent,
  adminUser,
  partnerDraft,
  partnerOffer,
  adminFilterList,
  partnerLookup,
  adminOffer,
  adminLookup,
  adminOfferDraft,
  adminDetail,
  partnerOfferDraft,
  notification,
  emailVerify,
  emailUpdate,
  adminGamer,
  adminTransaction,
  partnerAnalytics,
  partnerOfferNames,
  adminTournament,
  leaderboard,
  partnerGamer,
} );

const rootReducer = ( state, action ) => {
  if( action.type === CLEAR_STORE ) {
    // TODO: refactor to not reassign data
    // TODO: refactor to remove use of undefined
    // eslint-disable-next-line no-param-reassign, no-undefined
    state = undefined;
  }

  return reducer( state, action );
};

export default rootReducer;
