import HelpIcon from "@material-ui/icons/Help";
import PropTypes from "prop-types";
import React from "react";
import { Form, Col, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import TimezoneSelect from "react-timezone-select";

// Local-imports
import Enums from "../../../util/Enums";
import { calendarIcon, timeIcon } from "../../../util/icons";
import CustomDatePicker from "../customDatePicker";
import CustomTimePicker from "../customTimePicker";
import AdvertiseToGamer from "./advertiseToGamer";
import ChooseGame from "./chooseGame";

const { TIME_PICKER_INTERVAL_MINUTES } = Enums;

const ScheduleTournament = ( {
  values,
  games,
  errors,
  onSelect,
  onBlur,
  startDateChange,
  endDateChange,
  selectTime,
  onChangeTimeZone,
  onChange,
  advertiseDateChange,
  isTournament = false,
} ) => (
  <>
    <h4 className='page-inner-heading'>
      3. Schedule the Tournament
      <OverlayTrigger placement='right' overlay={<Tooltip>Enter start and end dates for when the tournament will be active</Tooltip>}>
        <HelpIcon className="help-icon" />
      </OverlayTrigger>
    </h4>
    <Row>
      <Col sm={6}>
        <ChooseGame
          values={values}
          games={games}
          errors={errors}
          onSelect={onSelect}
          onBlur={onBlur}
        />
      </Col>
      <Col sm={6}>
        <Form.Group className='custom-timezone'>
          <Form.Label>Choose Time Zone</Form.Label>
          <TimezoneSelect
            placeholder='Choose Time Zone'
            value={values.timeZone}
            onChange={onChangeTimeZone}
          />
          <div className='invalid-feedback d-block'>
            {errors.timeZone}
          </div>
        </Form.Group>
      </Col>
    </Row>
    <Row>
      <Col sm={6}>
        <Form.Group className='custom-datepicker with-icon'>
          <Form.Label className='label-with-icon'>Start Date</Form.Label>
          <CustomDatePicker
            value={values.startDate}
            handleChange={startDateChange}
            onBlur={onBlur}
            minDate={new Date()}
          />
          <div className='invalid-feedback d-block'>
            {errors.startDate}
          </div>
          <img src={calendarIcon} alt="calendar" className='calendar-icon' />
        </Form.Group>
      </Col>
      <Col sm={6}>
        <Form.Group className='custom-datepicker with-icon'>
          <Form.Label className='label-with-icon'>Start Time</Form.Label>
          <CustomTimePicker
            value={values.startTime}
            onChange={e => selectTime( "startTime", e )}
            placeholder='Choose Start Time'
            timeIntervals={TIME_PICKER_INTERVAL_MINUTES}
          />
          <div className='invalid-feedback d-block'>
            {errors.startTime}
          </div>
          <img src={timeIcon} alt="calendar" className='calendar-icon' />
        </Form.Group>
      </Col>
      <Col sm={6}>
        <Form.Group className='custom-datepicker with-icon'>
          <Form.Label className='label-with-icon'>End Date</Form.Label>
          <CustomDatePicker
            value={values.endDate}
            handleChange={endDateChange}
            onBlur={onBlur}
            minDate={ new Date( values.startDate ) || new Date()}
          />
          <div className='invalid-feedback d-block'>
            {errors.endDate}
          </div>
          <img src={calendarIcon} alt="calendar" className='calendar-icon' />
        </Form.Group>
      </Col>
      <Col sm={6}>
        <Form.Group className='custom-datepicker with-icon'>
          <Form.Label className='label-with-icon'>End Time</Form.Label>
          <CustomTimePicker
            value={values.endTime}
            onChange={e => selectTime( "endTime", e )}
            placeholder='Choose End Time'
            timeIntervals={TIME_PICKER_INTERVAL_MINUTES}
          />
          <div className='invalid-feedback d-block'>
            {errors.endTime}
          </div>
          <img src={timeIcon} alt="calendar" className='calendar-icon' />
        </Form.Group>
      </Col>
    </Row>
    <Row>
      <AdvertiseToGamer
        values={values}
        onChange={onChange}
        errors={errors}
        onSelect={onSelect}
        onBlur={onBlur}
        advertiseDateChange={advertiseDateChange}
        isTournament={isTournament}
      />
    </Row>
  </>
);

ScheduleTournament.propTypes = {
  onChange: PropTypes.func,
  values: PropTypes.object,
  games: PropTypes.array,
  errors: PropTypes.object,
  onSelect: PropTypes.func,
  gamers: PropTypes.array,
  onBlur: PropTypes.func,
  startDateChange: PropTypes.func,
  endDateChange: PropTypes.func,
  advertiseDateChange: PropTypes.func,
  selectTime: PropTypes.func,
  onChangeTimeZone: PropTypes.func,
  isTournament: PropTypes.bool,
};

export default ScheduleTournament;
