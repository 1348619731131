import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { useAddQuery } from "../../../../hooks";
import { getPartnerGamerLinkedGameDetailAction } from "../../../../store/actions/partnerGamer";
import constant from "../../../../util/constant";
import { getBackNavigationRoute } from "../../../../util/helpers";
import partnerGamerConstant from "../../../../util/partnerGamerConstant";
import BackButton from "../../../shared/backButton";
import LinkedGameDetails from "../../../shared/gamer/linkedGameDetails";
import PartnerGamerGameAssets from "./partnerGamerGameAssets";
import PartnerGamerGameDetail from "./partnerGamerGameDetail";
import PartnerGamerGameOffers from "./partnerGamerGameOffers";

const { API_STATUS, ROUTES } = constant;
const { API_TYPES } = partnerGamerConstant;

const GamerLinkedGameDetail = ( props ) => {
  const dispatch = useDispatch();
  const { addQuery } = useAddQuery();
  const history = useHistory();
  const location = useLocation();
  const backScreens = [ "gamerLinkedGameList" ];
  const paramsQuery = new URLSearchParams( window.location.search );
  const type = paramsQuery.get( "type" );
  const { linkedGameDetails, apiType, status } = useSelector( state => ( {
    linkedGameDetails: state.partnerGamer.LinkedGameDetails,
    status: state.partnerGamer.status,
    apiType: state.partnerGamer.apiType,
  } ) );
  const gameId = props.match?.params?.gameId || "";
  const gamerIdString = props.match?.params?.gamerId || "";
  const { 0: gamerId } = gamerIdString.toString().split( "?" );
  const [ tab, setTab ] = useState( [
    { tabKey: "gameTab",
      value: true,
      className: "pt-0",
      testId: "setView",
      fun: "gameTab",
      tabName: "Game Details",
      component: PartnerGamerGameDetail,
    },
    { tabKey: "assetsTab",
      value: false,
      className: "pt-0",
      testId: "assetsTabKey",
      fun: "assetsTab",
      tabName: "Gamer's Assets",
      component: PartnerGamerGameAssets,
    },
    { tabKey: "offersTab",
      value: false,
      className: "pt-0",
      testId: "offersTabKey",
      fun: "offersTab",
      tabName: "Gamer's Offers",
      component: PartnerGamerGameOffers,
    },
  ]
  );

  const setTabsData = ( value ) => {
    setTab(
      tab.map( item =>
        ( item.tabKey === value
          ? { ...item, value: true }
          : { ...item, value: false } )
      )
    );
  };

  useEffect( () => {
    if( gameId ) {
      dispatch( getPartnerGamerLinkedGameDetailAction( gameId, { getOffers: true } ) );
      setTabsData( type );
    }
  }, [ gameId ] );

  useEffect( () => {
    if(
      status === API_STATUS.FAILURE &&
      apiType === API_TYPES.GET_GAMER_LINKED_GAMES_DETAIL
    ) {
      history.goBack();
    }
  }, [ apiType, status ] );

  const handleBackNavigation = () => {
    return getBackNavigationRoute( ROUTES.PARTNER_GAMER_LINKED_GAMES.replace( ":gamerId", gamerId ), location, backScreens );
  };

  return (
    <div className='main-wrapper'>
      <div className='main-right-wrapper'>
        <BackButton
          backLink={handleBackNavigation()}
          state={location.state}
        />
        <LinkedGameDetails
          tab={tab}
          props={props}
          gameId={gameId}
          gamerId={gamerId}
          addQuery={addQuery}
          gameName={linkedGameDetails.gameName}
          setTabsData={setTabsData}
          locationState={location.state}
        />
      </div>
    </div>
  );
};

GamerLinkedGameDetail.propTypes = {
  match: PropTypes.string,
};

export default GamerLinkedGameDetail;
