import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { Col, Row, Form } from "react-bootstrap";

import constant from "../../../../util/constant";
import { useSetUrlParams } from "../../../../util/helpers";
import CustomSlider from "../../../shared/customSlider";
import RangeDatePicker from "../../../shared/datePicker/rangeDatePicker";
import MultiSelectCheckbox from "../../../shared/multiSelectCheckbox";

const { BRANDING: { APP_SHORT_NAME } } = constant;

const ExchangeTransactionFilters = ( {
  gameNames,
  assetNames,
  handleChange,
  showFilter,
  qxPointRange,
  values,
  addQuery,
  location,
} ) => {
  const qxPointsHandler = ( e, newValue ) => {
    addQuery( useSetUrlParams( "minQxPoint", newValue[ 0 ] ), location.state );
    addQuery( useSetUrlParams( "maxQxPoint", newValue[ 1 ] ), location.state );
    handleChange( "minQxPoint", newValue[ 0 ] );
    handleChange( "maxQxPoint", newValue[ 1 ] );
  };

  const handleFilterChange = ( listName, obj ) => {
    if( obj.length ) {
      handleChange( listName, obj );
      addQuery( useSetUrlParams( listName, JSON.stringify( obj ) ), location.state );
    }
    else {
      addQuery( useSetUrlParams( listName, "" ), location.state );
      handleChange( listName );
    }
  };

  const multiSelectedItems = useMemo( () => [ values.gameNames, values.assetNames ] );
  const onSelectFilterItems = useCallback(
    [ obj => handleFilterChange( "gameNames", obj ),
      obj => handleFilterChange( "assetNames", obj ),
    ],
    []
  );

  const clearSelectedFilter = ( params ) => {
    if( params ) {
      addQuery( useSetUrlParams( params, "" ), location.state );
      handleChange( params, [] );
    }
  };

  const clearAllSelectedOptions = useCallback(
    params => clearSelectedFilter( params ), []
  );

  return (
    <>
      <div className={`filter-boxes mr-0 bottom-margin ${ !showFilter ? "" : "remove-filter" }`}>
        <Row>
          <Col>
            <Form.Group className='custom-multiselect'>
              <Form.Label>Game Name</Form.Label>
              <MultiSelectCheckbox
                multiOptionsList={gameNames}
                handleSelectChange={onSelectFilterItems[ 0 ]}
                multiSelectedItems={multiSelectedItems[ 0 ]}
                handleClearMultiSelect={() => clearAllSelectedOptions( "gameNames" )}/>
            </Form.Group>
          </Col>
          <Col>
            <CustomSlider
              values={values}
              pointsHandler={qxPointsHandler}
              handleChange={handleChange}
              min={qxPointRange.min}
              max={qxPointRange.max}
              minName={"minQxPoint"}
              maxName={"maxQxPoint"}
              label={`${ APP_SHORT_NAME } Points`}
            />
          </Col>
          <Col>
            <Form.Group className='custom-multiselect'>
              <Form.Label>Assets</Form.Label>
              {<MultiSelectCheckbox
                multiOptionsList={assetNames}
                handleSelectChange={onSelectFilterItems[ 1 ]}
                multiSelectedItems={multiSelectedItems[ 1 ]}
                handleClearMultiSelect={() => clearAllSelectedOptions( "assetNames" )}/>}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='custom-datepicker range-picker'>
              <RangeDatePicker
                label='Date of Transaction'
                values={values}
                addQuery={addQuery}
                handleChange={handleChange}
                param={{ key1: "startDate", key2: "endDate", key3: "", key4: "", page: "page" }}
                locationState={location.state}
              />
            </Form.Group>
          </Col>
        </Row>
      </div>
    </>
  );
};

ExchangeTransactionFilters.propTypes = {
  assetNames: PropTypes.array,
  handleChange: PropTypes.func,
  gameNames: PropTypes.array,
  showFilter: PropTypes.bool,
  qxPointRange: PropTypes.object,
  values: PropTypes.object,
  addQuery: PropTypes.func,
  locationState: PropTypes.string,
  location: PropTypes.object,
};

export default ExchangeTransactionFilters;
