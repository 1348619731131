import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import React from "react";
import Select, { components } from "react-select";


const DropdownIndicator = ( props

) => {
  return (
    <>
      <components.DropdownIndicator {...props}>
        <ArrowDropDownIcon />
      </components.DropdownIndicator>
    </>
  );
};

const SelectBox = props => (
  <Select {...props}
    components={{ DropdownIndicator, IndicatorSeparator: () => null }}
  />
);

export default SelectBox;
