import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

import NotFound from "../components/shared/404";

const PublicRoutes = ( ) => (
  <Fragment>
    <Switch>
      <Route component={NotFound} />
    </Switch>
  </Fragment>
);

export default PublicRoutes;
