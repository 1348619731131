import constant from "../util/constant";
import { convertDate } from "../util/helpers";
import offerConstant from "../util/offerConstant";

const { OPT_IN_STATUS } = constant;
const { OFFER_STATUS } = offerConstant;

export class AdminGamerLinkedOffers {
  constructor( obj ) {
    this.offerId = obj?.offerId;
    this.offerName = obj?.offerName;
    this.gameIcon = obj?.iconUrl;
    this.offerDescription = obj?.offerDescription;
    this.startDate = convertDate( obj?.startDate );
    this.endDate = convertDate( obj?.endDate );
    this.gameName = obj?.gameName;
    this.place = obj?.myPlace;
    this.offerProgress = obj?.offerProgress ?? "N/A";
    this.partnerLogo = obj?.partnerLogo ?? "";
    this.rewardValue = obj?.reward[ 0 ]?.reward_value ?? 0;
    this.startDateOriginal = obj?.startDate;
    this.endDateOriginal = obj?.endDate;
    this.optInDetail = {
      optIn: obj?.optIn || OPT_IN_STATUS.NO,
      membershipId: obj?.membershipId,
      entryFee: obj?.entryFee || "",
      minParticipants: obj?.minParticipants,
      maxParticipants: obj?.maxParticipants,
    };
    this.statusTitle = OFFER_STATUS.find(
      offer => offer.status === obj.status
    );
    this.offerStatus = this.statusTitle?.title || "";
    this.optInType = obj.optIn || "";
  }
}
