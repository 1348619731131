import VisibilityIcon from "@material-ui/icons/Visibility";
import { debounce } from "lodash";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Scrollbars } from "react-custom-scrollbars";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useAddQuery, useForm, useScroll } from "../../../hooks";
import { getPartnersListAction, getPartnersFilterListAction } from "../../../store/actions/admin";
import adminConstant from "../../../util/adminConstant";
import ButtonMailto from "../../../util/buttonMailto";
import constant from "../../../util/constant";
import { useSetUrlParams, checkFilterApplied, handleParseJSON } from "../../../util/helpers";
import { logoColor } from "../../../util/icons";
import { adminPartnerTableConfigs } from "../../../util/tableConstant";
import FilterIcon from "../../shared/FilterIcon/FilterIcon";
import { ScrollPage } from "../../shared/customScrollBar";
import MultiSelectCheckbox from "../../shared/multiSelectCheckbox";
import TableHeaderRow from "../../shared/tableHeadingRow";

const { PARTNER_FILTER_LIST, APPLICATION_STATUS, ORDER_BY, ADMIN_API_TYPES } =
  adminConstant;
const { ROUTES, API_STATUS, FILTERS_NAME } = constant;

const PartnerList = () => {
  const {
    partnerList: { partners, partnerFilterDetail, status, apiType, partnersMeta },
  } = useSelector( state => state );
  const { addQuery } = useAddQuery();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { scrollEvent } = useScroll();
  const isFilterApplied = checkFilterApplied( location.search, FILTERS_NAME.PARTNER );
  const filterNames = [ "partnerNames", "partnerEmails", "primaryContacts", "primaryPhones", "partnerStatus" ];
  const queryParams = new URLSearchParams( window.location.search );

  function getUrlParameter( key, col ) {
    let queryParam;

    if( key === "searchKey" || key === "sortColumn" || key === "sortOrder" ) {
      queryParam = queryParams.get( key ) ?? col;
    }

    if( filterNames.includes( key ) ) {
      queryParam = queryParams.get( key ) === "" ? [] : handleParseJSON( queryParams.get( key ) );
    }

    return queryParam;
  }

  const { values, handleChange } = useForm(
    {
      page: 1,
      searchKey: getUrlParameter( "searchKey", "" ),
      sortBy: getUrlParameter( "sortColumn", "" ),
      sortOrder: getUrlParameter( "sortOrder", ORDER_BY.ASC ),
      partnerNames: getUrlParameter( "partnerNames", "" ),
      partnerEmails: getUrlParameter( "partnerEmails", "" ),
      primaryContacts: getUrlParameter( "primaryContacts", "" ),
      primaryPhones: getUrlParameter( "primaryPhones", "" ),
      partnerStatus: getUrlParameter( "partnerStatus", "" ),
      searchDummyText: getUrlParameter( "searchKey", "" ),
    },
    {}
  );

  const onScroll = ( e ) => {
    scrollEvent( e, partners, values, handleChange, partnersMeta, "page" );
  };

  const searchHandle = ( event ) => {
    const { value: nextValue } = event.target;

    addQuery( useSetUrlParams( "searchKey", nextValue ) );
    handleChange( "searchDummyText", nextValue );
    debouncedSave( nextValue );
    handleChange( "page", 1 );
  };

  const debouncedSave = useCallback(
    debounce( nextValue => handleChange( "searchKey", nextValue ), 1000 ),
    []
  );

  const requestParams = {
    searchKey: values.searchKey,
    sortBy: values.sortBy,
    sortOrder: values.sortOrder,
    perPage: 10,
    page: values.page,
    ...( values.partnerNames && { partnerNames: values.partnerNames.map( v => v.value ) } ),
    ...( values.partnerEmails && { partnerEmails: values.partnerEmails.map( v => v.value ) } ),
    ...( values.primaryContacts && { primaryContacts: values.primaryContacts.map( v => v.value ) } ),
    ...( values.primaryPhones && { primaryPhones: values.primaryPhones.map( v => v.value ) } ),
    ...( values.partnerStatus && { partnerStatus: values.partnerStatus.map( v => v.value ) } ),
  };

  useEffect( () => {
    if(
      ( status === API_STATUS.SUCCESS ) &&
      ( apiType === ADMIN_API_TYPES.GET_PARTNER_LIST ) &&
      ( !partnerFilterDetail.partnerNames?.length )
    ) {
      dispatch( getPartnersFilterListAction(
        {
          fetchAllRecords: true,
          fieldsToBeFetched: "legal_entity_name,email,primary_phone,primary_name",
        } ) );
    }
  }, [ status, apiType ] );

  useEffect( () => {
    dispatch( getPartnersListAction( requestParams ) );
  }, [
    values.searchKey,
    values.page,
    values.sortOrder,
    values.sortBy,
    values.partnerNames,
    values.partnerEmails,
    values.primaryContacts,
    values.primaryPhones,
    values.partnerStatus,
  ] );

  const multiSelectedItems = useMemo( () => [ values.partnerNames, values.partnerEmails, values.primaryContacts, values.primaryPhones, values.partnerStatus ] );
  const handleSelectChange = useCallback(
    [ obj => listHandle( obj, "partnerNames" ),
      obj => listHandle( obj, "partnerEmails" ),
      obj => listHandle( obj, "primaryContacts" ),
      obj => listHandle( obj, "primaryPhones" ),
      obj => listHandle( obj, "partnerStatus" ),
    ],
    []
  );
  const handleClearMultiSelect = useCallback(
    parmas => clearHandle( parmas ), []
  );

  const listHandle = ( obj, listName ) => {
    if( obj.length > 0 ) {
      handleChange( "page", 1 );
      handleChange( listName, obj );
      addQuery( useSetUrlParams( listName, JSON.stringify( obj ) ) );
    }
    else {
      addQuery( useSetUrlParams( listName, "" ) );
      handleChange( listName );
    }
  };

  const clearHandle = ( parmas ) => {
    if( parmas ) {
      addQuery( useSetUrlParams( parmas, "" ) );
      handleChange( parmas, [] );
    }
  };

  const [ showFilter, setShowFilter ] = useState( false );
  const showFilterScreen = () => setShowFilter( !showFilter );

  const handleSorting = ( sortBy, sortingType ) => {
    addQuery( useSetUrlParams( "sortColumn", sortBy ) );
    addQuery( useSetUrlParams( "sortOrder", sortingType ) );
    handleChange( "sortBy", sortBy );
    handleChange( "sortOrder", sortingType );
    handleChange( "page", 1 );
  };

  const memoizedTableConfig = useMemo( () => adminPartnerTableConfigs, [] );
  const memoizedHandleClick = useCallback(
    ( sortBy, orderType ) => handleSorting( sortBy, orderType ),
    []
  );

  return (
    <>
      <div className='main-wrapper'>
        <div className='main-right-wrapper'>
          <div className='page-head-wrapper'>
            <h4 className='page-heading'>Partners</h4>
            <div className='page-head-right'>
              <Form.Group className='custom-search w-300 mr-0'>
                <span className='icon-search'></span>
                <Form.Control
                  type='text'
                  placeholder='Search by Partner Name, Email…'
                  value={values.searchDummyText}
                  onChange={searchHandle}
                  title='Search by Partner Name, Email, and Primary Contact'
                  data-testid='searchKey'
                />
              </Form.Group>
              <FilterIcon
                showFilter={showFilter}
                showFilterScreen={showFilterScreen}
                isFilterApplied={isFilterApplied} />
            </div>
          </div>
          <div className={`filter-tabs-wrapper ${ !showFilter ? "" : "remove-filter" }`}>
            <div className='filter-boxes offer-filter mr-0'>
              <Row>
                <Col>
                  <Form.Group className='custom-multiselect mb-0'>
                    <Form.Label>Partner Name</Form.Label>
                    <MultiSelectCheckbox
                      multiOptionsList={partnerFilterDetail.partnerNames}
                      handleSelectChange={handleSelectChange[ 0 ]}
                      multiSelectedItems={multiSelectedItems[ 0 ]}
                      handleClearMultiSelect={() => handleClearMultiSelect( "partnerNames" )} />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='custom-multiselect mb-0'>
                    <Form.Label>Email</Form.Label>
                    <MultiSelectCheckbox
                      multiOptionsList={partnerFilterDetail.partnerEmails}
                      handleSelectChange={handleSelectChange[ 1 ]}
                      multiSelectedItems={multiSelectedItems[ 1 ]}
                      handleClearMultiSelect={() => handleClearMultiSelect( "partnerEmails" )} />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='custom-multiselect mb-0'>
                    <Form.Label>Primary Contact</Form.Label>
                    <MultiSelectCheckbox
                      multiOptionsList={partnerFilterDetail.partnerContacts}
                      handleSelectChange={handleSelectChange[ 2 ]}
                      multiSelectedItems={multiSelectedItems[ 2 ]}
                      handleClearMultiSelect={() => handleClearMultiSelect( "primaryContacts" )} />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='custom-multiselect mb-0'>
                    <Form.Label>Primary Phone</Form.Label>
                    <MultiSelectCheckbox
                      multiOptionsList={partnerFilterDetail.partnerPhones}
                      handleSelectChange={handleSelectChange[ 3 ]}
                      multiSelectedItems={multiSelectedItems[ 3 ]}
                      handleClearMultiSelect={() => handleClearMultiSelect( "primaryPhones" )} />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='custom-multiselect mb-0'>
                    <Form.Label>Status</Form.Label>
                    <MultiSelectCheckbox
                      multiOptionsList={PARTNER_FILTER_LIST}
                      handleSelectChange={handleSelectChange[ 4 ]}
                      multiSelectedItems={multiSelectedItems[ 4 ]}
                      handleClearMultiSelect={() => handleClearMultiSelect( "partnerStatus" )} />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </div>
          {/* Table start from here */}
          <div className='table-container six-column'>
            <div className='table-head'>
              <div className='table-row'>
                <TableHeaderRow
                  configData={memoizedTableConfig}
                  onAscOrder={memoizedHandleClick}
                  onDescOrder={memoizedHandleClick}
                />
              </div>
            </div>
            <Scrollbars
              renderThumbVertical={ScrollPage}
              className={`custom-scroll-height ${ !showFilter ? "" : "full-height" }`}
              onScroll={onScroll}>
              <div
                className='table-body no-scrollbar' >
                {Boolean( partners.length ) &&
                  partners.map( ( data, i ) => (
                    <div className='table-row' key={i}>
                      <div className='td'>
                        <img
                          src={data.icon_url ?? logoColor}
                          alt='Game'
                          className='game-image'
                        />
                        <span className='strong'>{data.legal_entity_name}</span>
                      </div>
                      <div className='td'>
                        <ButtonMailto
                          mailto={`mailto:${ data.email }`}
                          label={data.email}
                        ></ButtonMailto>
                      </div>
                      <div className='td'>{data.primary_name}</div>
                      <div className='td'>{data.primary_phone}</div>

                      <div className='td'>
                        {APPLICATION_STATUS[ data.application_status ]}
                      </div>
                      <div className='td'>
                        <div
                          onClick={() =>
                            history.push( {
                              pathname: `${ ROUTES.PARTNER_REQUEST_DETAILS + data.partner_id }?tab=INFO`,
                              state: [
                                { from: "partners", path: location.pathname, state: location.search },
                              ],
                            } )
                          }
                          className='icon cursor-pointer'
                        >
                          <VisibilityIcon />
                        </div>
                      </div>
                    </div>
                  ) )}
              </div>
              {!partners.length && (
                <div className='no-record' data-testid='no-record-found'>
                  No Partner Found
                </div>
              )}
            </Scrollbars>
          </div>
          {/* end table */}
        </div>
      </div>
    </>
  );
};

export default PartnerList;
