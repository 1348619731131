import { debounce } from "lodash";
import React, { useEffect, useMemo, useCallback, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// local-imports
import { useAddQuery, useForm } from "../../../hooks";
import { getAdminFilterListAction } from "../../../store/actions/adminFilterList";
import { getTournamentListAction } from "../../../store/actions/adminTournament";
import constant from "../../../util/constant";
import { useSetUrlParams, checkFilterApplied, getUrlParameter, concatStrings, handleParseJSON } from "../../../util/helpers";
import { adminTournamentsTableConfigs } from "../../../util/tableConstant";
import tournamentConstant from "../../../util/tournamentConstant";
import FilterIcon from "../../shared/FilterIcon/FilterIcon";
import TounamentFilters from "./tounamentFilters";
import TournamentList from "./tournamentList";

const { ORDER_BY, API_STATUS, FILTERS_NAME, ROUTES } = constant;
const { TOURNAMENTS_API_TYPES, TOURNAMENT_TYPES } = tournamentConstant;

const AdminTournamentList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { addQuery } = useAddQuery();
  const isFilterApplied = checkFilterApplied( window.location.search, FILTERS_NAME.ADMIN_TOURNAMENTS );
  const { tournaments, usersMetaData, status, apiType, adminFilterDetail, partnerList, gameTournamentList, tournamentList, configuration } = useSelector( state => ( {
    tournaments: state.adminTournament.adminTournaments.tournamets,
    usersMetaData: state.adminTournament.adminTournaments.meta,
    status: state.adminTournament.status,
    apiType: state.adminTournament.apiType,
    tournamentList: state.adminFilterList.filterData.tournamentList,
    partnerList: state.adminFilterList.filterData.partnerList,
    gameTournamentList: state.adminFilterList.filterData.gameTournamentList,
    configuration: state.adminDetail.data?.configuration || {},
  } ) );
  const paramsQuery = new URLSearchParams( window.location.search );
  const { values, handleChange } = useForm(
    {
      searchDummyText: getUrlParameter( "searchKey", null ),
      searchKey: getUrlParameter( "searchKey", null ),
      sortBy: getUrlParameter( "sortBy", "name" ),
      sortOrder: getUrlParameter( "sortOrder", ORDER_BY.ASC ),
      page: 1,
      tournamentList: paramsQuery.get( "tournamentList" ) === "" ? [] : JSON.parse( paramsQuery.get( "tournamentList" ) ),
      partnerList: paramsQuery.get( "partnerList" ) === "" ? [] : JSON.parse( paramsQuery.get( "partnerList" ) ),
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      status: getUrlParameter( "status", undefined ),
      startDate: getUrlParameter( "startDate" ),
      endDate: getUrlParameter( "endDate" ),
      gameTournamentList: paramsQuery.get( "gameTournamentList" ) === "" ? [] : JSON.parse( paramsQuery.get( "gameTournamentList" ) ),
      optInTypes: paramsQuery.get( "optInTypes" ) === "" ? [] : handleParseJSON( paramsQuery.get( "optInTypes" ) ),
    },
    {}
  );
  const requestParams = Object.assign(
    {
      sortOrder: values.sortOrder,
      perPage: 10,
      page: values.page,
      fetchAllRecords: false,
      offerTypes: [ TOURNAMENT_TYPES.TOURNAMENT, TOURNAMENT_TYPES.TOURNAMENT_TEMPLATE, TOURNAMENT_TYPES.TOURNAMENT_TEMPLATE_DRAFT ].join( "," ),
    },
    values.searchKey && { searchKey: values.searchKey },
    values.sortBy && { sortBy: values.sortBy },
    {
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      partnerIds: concatStrings( values.partnerList, "," ) || undefined,
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      gameIds: concatStrings( values.gameTournamentList, "," ) || undefined,
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      offerIds: concatStrings( values.tournamentList, "," ) || undefined,
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      status: concatStrings( values.status, "," ) || undefined,
      startDate: values.startDate === false ? "" : values.startDate,
      endDate: values.endDate === false ? "" : values.endDate,
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      optInTypes: concatStrings( values.optInTypes, "," ) || undefined,
    }
  );

  const handleSorting = ( sortBy, sortingType ) => {
    handleChange( "page", 1 );
    handleChange( "sortBy", sortBy );
    handleChange( "sortOrder", sortingType );
    addQuery( useSetUrlParams( "sortBy", sortBy ) );
    addQuery( useSetUrlParams( "sortOrder", sortingType ) );
  };

  useEffect( () => {
    if(
      status === API_STATUS.SUCCESS &&
      apiType === TOURNAMENTS_API_TYPES.GET_ADMIN_TOURNAMENTS
    ) {
      dispatch( getAdminFilterListAction( { entities: "Tournament,Partner,GameTournament" } ) );
    }
  }, [ status, apiType ] );

  useEffect( () => {
    dispatch( getTournamentListAction( requestParams ) );
  }, [
    values.sortOrder,
    values.page,
    values.sortBy,
    values.searchKey,
    values.tournamentList,
    values.partnerList,
    values.gameTournamentList,
    values.status,
    values.startDate,
    values.endDate,
    values.optInTypes,
  ] );

  const debouncedSave = useCallback(
    debounce( nextValue => handleChange( "searchKey", nextValue ), 1000 ),
    []
  );

  const searchHandle = ( e ) => {
    const { value: nextValue } = e.target;

    addQuery( useSetUrlParams( "searchKey", nextValue ) );
    handleChange( "searchDummyText", nextValue );
    debouncedSave( nextValue );
    handleChange( "page", 1 );
  };

  const memoizedTableConfig = useMemo( () => {
    return adminTournamentsTableConfigs;
  }, [] );

  const memoizedHandleClick = useCallback(
    ( sortBy, orderType ) => handleSorting( sortBy, orderType ),
    []
  );

  const multiSelectedItems = useMemo( () => [ values.tournamentList, values.partnerList, values.gameTournamentList, values.status, values.optInTypes ] );
  const handleSelectChange = useCallback(
    [ obj => listHandle( obj, "tournamentList" ),
      obj => listHandle( obj, "partnerList" ),
      obj => listHandle( obj, "gameTournamentList" ),
      obj => listHandle( obj, "status" ),
      obj => listHandle( obj, "optInTypes" ),
    ],
    []
  );
  const handleClearMultiSelect = useCallback(
    parmas => clearHandle( parmas ), []
  );

  const listHandle = ( obj, listName ) => {
    if( obj.length > 0 ) {
      handleChange( "page", 1 );
      handleChange( listName, obj );
      addQuery( useSetUrlParams( listName, JSON.stringify( obj ) ) );
    }
    else {
      addQuery( useSetUrlParams( listName, "" ) );
      handleChange( listName );
    }
  };

  const clearHandle = ( parmas ) => {
    if( parmas ) {
      addQuery( useSetUrlParams( parmas, "" ) );
      handleChange( parmas, [] );
    }
  };

  const [ showFilter, setShowFilter ] = useState( false );
  const showFilterScreen = () => setShowFilter( !showFilter );

  return (
    <>
      <div className="main-right-wrapper">
        <div className="page-head-wrapper">
          <h4 className="page-heading">Tournaments</h4>
          <div className="page-head-right">
            <Form.Group className="custom-search w-300">
              <span className="icon-search"></span>
              <Form.Control
                type="text"
                placeholder="Search by Tournament, Description ..."
                data-testid='searchKey'
                onChange={searchHandle}
                value={values.searchDummyText}
                name='searchKey'
                title='Search by Tournament, Description, Partner and Game Name'
              />
            </Form.Group>
            <Button
              variant='primary'
              className='small-btn'
              onClick={() => history.push( ROUTES.ADMIN_ADD_EDIT_TOURNAMENT.replace( ":offerId", 0 ) )}
              data-testid='addOfferKey'
            >
              <span className='icon-circle-plus-green icon'></span>
              Add Tournament Template
            </Button>
            <FilterIcon
              showFilter={showFilter}
              showFilterScreen={showFilterScreen}
              isFilterApplied={isFilterApplied} />
          </div>
        </div>

        <TounamentFilters
          values={values}
          handleChange={handleChange}
          showFilter={showFilter}
          tournamentList={tournamentList}
          partnerList={partnerList}
          gameTournamentList={gameTournamentList}
          adminFilterDetail={adminFilterDetail}
          handleSelectChange={handleSelectChange}
          multiSelectedItems={multiSelectedItems}
          handleClearMultiSelect={handleClearMultiSelect}
        />

        <TournamentList
          values={values}
          handleChange={handleChange}
          usersMetaData={usersMetaData}
          memoizedTableConfig={memoizedTableConfig}
          memoizedHandleClick={memoizedHandleClick}
          showFilter={showFilter}
          configuration={configuration}
          tournaments={tournaments}/>
      </div>
    </>
  );
};

export default AdminTournamentList;
