import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import constant from "../../../../util/constant";
import SelectBox from "../../../shared/selectBox";

const { INTERVAL_TYPE, GRAPH_FILTERS, DATE_TIME_FORMAT, NAVIGATION_TYPE } = constant;

const GraphFilter = ( {
  startDate,
  endDate,
  initialDate,
  dispatchAction,
  graphType,
} ) => {
  const dispatch = useDispatch();
  const [ intervalType, setIntervalType ] = useState( INTERVAL_TYPE.ALL );

  const dateRange = ( selectedInterval, fromDate, toDate ) => {
    switch ( selectedInterval ) {
      case INTERVAL_TYPE.ALL:
        return <>
          {( moment( fromDate ).year() === moment( toDate ).year() )
            ? <>{moment( fromDate ).year()} </>
            : <>{moment( fromDate ).year() } - {moment( toDate ).year() } </>
          }
        </>;
      case INTERVAL_TYPE.YEARLY:
        return <>
          { ( moment( initialDate ).year() !== moment( fromDate ).year() ) && (
            <ArrowLeftIcon className='cursor-pointer'
              onClick={() => handlePrevNextClick( selectedInterval, moment( fromDate ), NAVIGATION_TYPE.PREV )}/>
          )}
          {moment( fromDate ).year()}
          { ( moment( toDate ).year() !== moment( ).year() ) && (
            <ArrowRightIcon className='cursor-pointer'
              onClick={() => handlePrevNextClick( selectedInterval, moment( toDate ), NAVIGATION_TYPE.NEXT )}/> )}
        </>;
      case INTERVAL_TYPE.MONTHLY:
        return <>
          { ( moment( initialDate ).format( "MMM YYYY" ) !== moment( fromDate ).format( "MMM YYYY" ) ) && (
            <ArrowLeftIcon className='cursor-pointer'
              onClick={() => handlePrevNextClick( selectedInterval, moment( fromDate ), NAVIGATION_TYPE.PREV )}/>
          )}
          {moment( toDate ).format( "MMM YYYY" ) }
          { ( moment( toDate ).format( "MMM YYYY" ) !== moment( ).format( "MMM YYYY" ) ) && (
            <ArrowRightIcon className='cursor-pointer'
              onClick={() => handlePrevNextClick( selectedInterval, moment( toDate ), NAVIGATION_TYPE.NEXT )}/>
          )}
        </>;
      case INTERVAL_TYPE.WEEKLY:
        return <>
          { moment( initialDate ).isSameOrBefore( moment( fromDate ) ) && (
            <ArrowLeftIcon className='cursor-pointer'
              onClick={() => handlePrevNextClick( selectedInterval, moment( fromDate ).subtract( 7, "days" ), NAVIGATION_TYPE.PREV )}/>
          )}
          {moment( fromDate ).format( "D" ) } - {moment( toDate ).format( "D MMM YYYY" ) }
          { ( moment( toDate ).isBefore( moment().startOf( "day" ) ) ) && (
            <ArrowRightIcon className='cursor-pointer'
              onClick={() => handlePrevNextClick( selectedInterval, moment( fromDate ).add( 7, "days" ), NAVIGATION_TYPE.NEXT )}/>
          )}
        </>;
      default:
        return null;
    }
  };

  const handleIntervalChange = ( selectedIntervalType ) => {
    const now = moment().utc()
      .format( DATE_TIME_FORMAT.FULL_DATE );

    if( selectedIntervalType === INTERVAL_TYPE.YEARLY ) {
      dispatch( dispatchAction( {
        graphType,
        filterType: selectedIntervalType,
        startDate: moment( now ).startOf( "year" )
          .endOf( "month" ),
        endDate: moment( now ).endOf( "month" ) }
      ) );
    }
    else if( selectedIntervalType === INTERVAL_TYPE.ALL ) {
      dispatch( dispatchAction( {
        graphType,
        filterType: selectedIntervalType }
      ) );
    }
    else if( selectedIntervalType === INTERVAL_TYPE.MONTHLY ) {
      dispatch( dispatchAction( {
        graphType,
        filterType: selectedIntervalType,
        startDate: moment( now ).startOf( "month" ),
        endDate: moment( now ).endOf( "day" ) }
      ) );
    }
    else if( selectedIntervalType === INTERVAL_TYPE.WEEKLY ) {
      dispatch( dispatchAction( {
        graphType,
        filterType: selectedIntervalType,
        startDate: moment().startOf( "week" )
          .add( 1, "day" ), // To start the week from Monday
        endDate: moment().endOf( "day" ) }
      ) );
    }

    setIntervalType( selectedIntervalType );
  };

  // selectedIntervalType type i.e All, Yearly, Monthly, etc.
  // selectedDate is either startDate or endDate based on navigation type.
  // navigation is either Next or Prev.
  // returns call the action and filter out the result based on the selected parameters.
  const handlePrevNextClick = ( selectedIntervalType, selectedDate, navigation ) => {
    if( selectedIntervalType === INTERVAL_TYPE.YEARLY ) {
      if( navigation === NAVIGATION_TYPE.NEXT ) {
        const isCurrentYear = moment( selectedDate ).add( 1, "year" )
          .startOf( "year" )
          .isSame( moment().startOf( "year" ) );

        dispatch( dispatchAction( {
          graphType,
          filterType: selectedIntervalType,
          startDate: moment( selectedDate ).add( 1, "year" )
            .startOf( "year" )
            .endOf( "month" ),
          endDate: isCurrentYear
            ? moment().endOf( "month" )
            : moment( selectedDate ).add( 1, "year" )
              .endOf( "year" ),
        } ) );
      }
      else if( navigation === NAVIGATION_TYPE.PREV ) {
        dispatch( dispatchAction( {
          graphType,
          filterType: selectedIntervalType,
          startDate: moment( selectedDate ).subtract( 1, "year" )
            .startOf( "year" )
            .endOf( "month" ),
          endDate: moment( selectedDate ).subtract( 1, "year" )
            .endOf( "year" ) } ) );
      }
    }
    else if( selectedIntervalType === INTERVAL_TYPE.MONTHLY ) {
      if( navigation === NAVIGATION_TYPE.NEXT ) {
        const isCurrentMonth = moment( selectedDate ).add( 1, "month" )
          .startOf( "month" )
          .isSame( moment().startOf( "month" ) );

        dispatch( dispatchAction( {
          graphType,
          filterType: selectedIntervalType,
          startDate: moment( selectedDate ).add( 1, "month" )
            .startOf( "month" ),
          endDate: isCurrentMonth
            ? moment().endOf( "day" )
            : moment( selectedDate ).add( 1, "month" )
              .endOf( "month" ) }
        ) );
      }
      else if( navigation === NAVIGATION_TYPE.PREV ) {
        dispatch( dispatchAction( {
          graphType,
          filterType: selectedIntervalType,
          startDate: moment( selectedDate ).subtract( 1, "month" )
            .startOf( "month" ),
          endDate: moment( selectedDate ).subtract( 1, "month" )
            .endOf( "month" ) } ) );
      }
    }
    else if( selectedIntervalType === INTERVAL_TYPE.WEEKLY ) {
      const isCurrentWeek = moment( selectedDate ).startOf( "week" )
        .isSame( moment().startOf( "week" ) );

      dispatch( dispatchAction( {
        graphType,
        filterType: selectedIntervalType,
        startDate: moment( selectedDate ),
        endDate: isCurrentWeek
          ? moment().endOf( "day" )
          : moment( selectedDate ).add( 6, "days" )
            .endOf( "day" ) }
      ) );
    }

    setIntervalType( selectedIntervalType );
  };

  useEffect( () => {
    if( localStorage.getItem( "graphData" ) ) {
      let graphData = JSON.parse( localStorage.getItem( "graphData" ) );

      graphData = graphData.find( item => item.graph === graphType );

      if( graphData ) {
        setIntervalType( graphData.filterValue );
        dispatch( dispatchAction( { graphType, filterType: graphData.filterValue,
          startDate: graphData.range.startDate,
          endDate: graphData.range.endDate } ) );
      }
      else {
        dispatch( dispatchAction( { graphType, filterType: intervalType, startDate,
          endDate } ) );
      }
    }
    else {
      dispatch( dispatchAction( { graphType, filterType: intervalType, startDate,
        endDate } ) );
    }
  }, [] );

  return (
    <>
      <div className="tag-content">
        <Form.Group>
          <SelectBox
            options={GRAPH_FILTERS}
            onChange={value => handleIntervalChange( value.label )}
            value={GRAPH_FILTERS.find( value => value.label === intervalType )}
          />
        </Form.Group>
        <div className='text-year'>
          { dateRange( intervalType, startDate, endDate )}
        </div>
      </div>
    </>
  );
};

GraphFilter.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  initialDate: PropTypes.string,
  dispatchAction: PropTypes.func,
  graphType: PropTypes.string,
};

export default GraphFilter;
