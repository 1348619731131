import { toastr } from "react-redux-toastr";
import { Leaderboard } from "../../entities/leaderboard";
import { getPartnerLeaderboard, getAdminLeaderboard } from "../../services/leaderboardService";
import constant from "../../util/constant";
import {
  START_LOADER,
  STOP_LOADER,
  LEADER_BOARD_API_END,
  GET_LEADER_BOARD_SUCCESS,
  GET_LEADER_BOARD_FAILURE,
  LEADER_BOARD_API_REQUEST
} from "../actionTypes";

const { ERROR_MESSAGES, API_TYPES } = constant;

export const getLeaderboardAction = ( params, queryParams = {} ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: LEADER_BOARD_API_REQUEST,
      payload: API_TYPES.GET_LEADER_BOARD,
    } );

    let response = {};

    if( params.isAdmin ) {
      response = await getAdminLeaderboard( params, queryParams );
    }
    else {
      response = await getPartnerLeaderboard( params, queryParams );
      // Partner API does not have query params to sort the data on basis of rank.
      // Response not contain data in sorted order on basis of rank so we are doing it here.
      response.data.leaderboardRanking.sort( ( a, b ) => a.rank - b.rank );
    }

    dispatch( {
      type: GET_LEADER_BOARD_SUCCESS,
      payload: new Leaderboard( response.data ),
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GET_LEADER_BOARD_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: LEADER_BOARD_API_END,
    } );
  }
};
