import { EmailDetail } from "../../entities/emailDetail";
import {
  checkEmailVerify
} from "../../services/emailVerifyService";
import emailVerifyConstant from "../../util/emailVerifyConstant";
import {
  START_LOADER,
  STOP_LOADER,
  EMAIL_VERIFY_API_END,
  EMAIL_VERIFY_API_SUCCESS,
  EMAIL_VERIFY_API_FAILURE,
  EMAIL_VERIFY_API_REQUEST
} from "../actionTypes";

const { API_TYPE } = emailVerifyConstant;

export const checkEmailVerifyAction = emailObj => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: EMAIL_VERIFY_API_REQUEST,
      payload: API_TYPE.EMAIL_VERIFY,
    } );

    const response = await checkEmailVerify( { email: emailObj.email } );

    if( response ) {
      const emailDetail = new EmailDetail( response );

      emailDetail.fieldname = emailObj.name;
      dispatch( { type: EMAIL_VERIFY_API_SUCCESS, payload: emailDetail } );
    }
    else {
      dispatch( {
        type: EMAIL_VERIFY_API_FAILURE,
        payload: "",
      } );
    }
  }
  catch ( error ) {
    dispatch( { type: EMAIL_VERIFY_API_FAILURE, payload: error } );
  }
  finally {
    dispatch( { type: STOP_LOADER } );
    dispatch( { type: EMAIL_VERIFY_API_END } );
  }
};
