const useScroll = () => {
  const scrollEvent = ( e, data, values, handleChange, meta, key ) => {
    const bottoms = ( e.target.scrollTop + e.target.clientHeight ) >= e.target.scrollHeight;

    if(
      bottoms &&
      ( data.length < meta.total ) &&
      ( values.page <= meta.pages )
    ) {
      handleChange( key, values.page + 1 );
    }
  };

  return {
    scrollEvent,
  };
};

export default useScroll;
