import PropTypes from "prop-types";
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const Logout = ( { isOpened, onCancel, onConfirm, message, isChecked, setIsChecked, isPartnerApplicationSubmitted = true } ) => (
  <Modal show={isOpened} centered className='signout-modal'>
    <Modal.Header>
      <h2 className='modal-heading'>Sign Out</h2>
    </Modal.Header>
    <Modal.Body>
      <p>{message ? message : "Are you sure you want to Sign Out?"}</p>
      { isPartnerApplicationSubmitted && ( <div className='custom-checkbox mt-4'>
        <label></label>
        <input
          type='checkbox'
          name='logout'
          checked={isChecked}
          onChange={() => setIsChecked( !isChecked )}
        />
        <span className='cursor-default'>
          Never ask again
        </span>
      </div> ) }
    </Modal.Body>
    <Modal.Footer>
      <Button variant='outline-primary' className='btn-md' onClick={onCancel}>
        Cancel
      </Button>
      <Button variant='primary' className='btn-md' onClick={onConfirm}>
        Confirm
      </Button>
    </Modal.Footer>
  </Modal>
);

Logout.propTypes = {
  isOpened: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  message: PropTypes.string,
  isChecked: PropTypes.bool,
  setIsChecked: PropTypes.func,
  isPartnerApplicationSubmitted: PropTypes.bool,
};

export default Logout;
