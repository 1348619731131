import NotificationsIcon from "@material-ui/icons/Notifications";
import { Auth, Hub } from "aws-amplify";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";

// local imports
import { PartnerProfileDetail } from "../../../entities/partnerProfileDetail";
import { useModal } from "../../../hooks";
import { clearReduxStoreAction } from "../../../store/actions/auth";
import { updatePartnerProfileAction } from "../../../store/actions/partner";
import constant from "../../../util/constant";
import { logo } from "../../../util/icons";
import Logout from "../../shared/logout";

const { ROLE } = constant;

// eslint-disable-next-line react/prop-types
const DashboardHeader = ( { partnerLogo, unreadNotificationsCount } ) => {
  const { ROUTES } = constant;
  const { isOpened, closeModal, openModal } = useModal();
  const dispatch = useDispatch();
  const history = useHistory();
  const role = localStorage.getItem( "role" );
  const { partner: { partnerDetail } } = useSelector( state => state );
  const [ isChecked, setIsChecked ] = useState( false );

  const handleSignOut = async () => {
    try {
      if( isChecked && ( role === ROLE.PARTNER ) ) {
        partnerDetail.signoutWithoutConfirmation = isChecked;

        const requestPayload = new PartnerProfileDetail( partnerDetail );

        dispatch( updatePartnerProfileAction( requestPayload ) );
      }

      await Auth.signOut();
      localStorage.removeItem( "role" );
      dispatch( clearReduxStoreAction() );
      history.push( "/" );
      Hub.dispatch( "UI Auth", {
        event: "AuthStateChange",
        message: "signedout",
      } );
      closeModal();
    }
    catch ( error ) {
      closeModal();
      toastr.error( error );
    }
  };

  const handleSignOutCondition = () => {
    if( ( role === ROLE.PARTNER ) && partnerDetail?.signoutWithoutConfirmation ) {
      handleSignOut();
    }
    else {
      openModal();
    }
  };

  const closeLogOutModal = () => {
    closeModal();
    setIsChecked( false );
  };

  return (
    <>
      <div className='top-nav-wrapper'>
        <div className='logo-box'>
          <img src={logo} alt='logo' />
        </div>
        <div className='logo-box user-logo-box'>
          <img src={partnerLogo || logo} alt='logo' />
        </div>
        <div className='top-nav'>
          <ul>
            <li>
              <div className='custom-dropdown notification-dropdown notification-icon-box white-icon' onClick={() => history.push( ROUTES.PARTNER_NOTIFICATIONS )}>
                <NotificationsIcon />
                {unreadNotificationsCount !== 0 && <span className='notification-count'>{unreadNotificationsCount}</span>}
              </div>
            </li>
            <li>
              <a href="#" className='signout-btn'
                title="Logout"
                onClick={handleSignOutCondition}
                data-testid='signout' >
                <span className='icon-signout'></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <Logout
        isOpened={isOpened}
        onCancel={closeLogOutModal}
        onConfirm={handleSignOut}
        setIsChecked={setIsChecked}
        isChecked={isChecked}
      />
    </>
  );
};

export default DashboardHeader;
