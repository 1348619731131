import PropTypes from "prop-types";
import React from "react";
import constant from "../../../util/constant";
import { formatNumberAsPerLocale } from "../../../util/helpers";

const { BRANDING: { APP_SHORT_NAME } } = constant;

export const ListBody = ( {
  list,
} ) => {
  return (
    <div className='gamer-game-list scrollbar'>
      {list.map( ( data, index ) => (
        <div className='list-items rewards-list-items' key={index}>
          <span className='game-count'>{data.place}</span>
          <span className='game-name'>{formatNumberAsPerLocale( data.rewardValue )} { APP_SHORT_NAME } Points</span>
        </div>
      ) )}
    </div>
  );
};

ListBody.propTypes = {
  list: PropTypes.array,
};
