import { toastr } from "react-redux-toastr";
import { PartnerDetail } from "../../entities/partnerDetail";
import {
  getPartnerDetail,
  uploadPartnerDetail,
  updatePartnerDetail,
  updatePartnerProfileDetail
} from "../../services/partnerServices";
import { getPartnerDraftDetailAction } from "../../store/actions/partnerDraft";
import constant from "../../util/constant";
import {
  START_LOADER,
  STOP_LOADER,
  GET_PARTNER_DETAIL_SUCCESS,
  GET_PARTNER_DETAIL_FAILURE,
  GET_PARTNER_DETAIL_REQUEST,
  PARTNER_API_REQUEST,
  PARTNER_API_END,
  UPLOAD_PARTNER_DETAIL_SUCCESS,
  UPLOAD_PARTNER_DETAIL_FAILURE,
  UPDATE_PARTNER_DETAIL_SUCCESS,
  UPDATE_PARTNER_DETAIL_FAILURE,
  SET_PARTNER_DRAFT_DETAIL_SUCCESS,
  UPDATE_PARTNER_PROFILE_SUCCESS,
  UPDATE_PARTNER_PROFILE_FAILURE
} from "../actionTypes";

const { ERROR_MESSAGES, APPLICATION_STATUS } = constant;

export const getPartnerDetailAction = userId => async ( dispatch ) => {
  try {
    dispatch( { type: GET_PARTNER_DETAIL_REQUEST } );

    const response = await getPartnerDetail();

    if( response.data ) {
      const partnerDetail = new PartnerDetail( response.data );

      dispatch( { type: GET_PARTNER_DETAIL_SUCCESS, payload: partnerDetail } );

      if( partnerDetail.applicationStatus === constant.APPLICATION_STATUS.AMENDMENTS_SUGGESTED && response?.data?.Draft === null ) {
        dispatch( { type: SET_PARTNER_DRAFT_DETAIL_SUCCESS, payload: partnerDetail } );
      }

      if( partnerDetail.applicationStatus === constant.APPLICATION_STATUS.AMENDMENTS_SUGGESTED && response?.data?.Draft !== null ) {
        dispatch( getPartnerDraftDetailAction( partnerDetail.partnerId ) );
      }
    }
    else {
      dispatch( getPartnerDraftDetailAction( userId ) );
      dispatch( {
        type: GET_PARTNER_DETAIL_SUCCESS,
        payload: { applicationStatus: APPLICATION_STATUS.ADD_PARTNER },
      } );
    }
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GET_PARTNER_DETAIL_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
};

export const uploadPartnerDetailAction = requestObj => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_API_REQUEST,
      payload: constant.API_TYPES.UPLOAD_PARTNER_DETAIL,
    } );

    const response = await uploadPartnerDetail( requestObj );

    dispatch( { type: UPLOAD_PARTNER_DETAIL_SUCCESS, payload: response.data } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: UPLOAD_PARTNER_DETAIL_FAILURE, payload: error } );
    dispatch( { type: STOP_LOADER } );

    const err = Object.values( error?.data?.error || {} ) || "";

    if( err[ 0 ] ) {
      toastr.error( err[ 0 ] );
    }
    else {
      toastr.error( error.data.message );
    }
  }
  finally {
    dispatch( { type: PARTNER_API_END } );
  }
};

export const updatePartnerDetailAction = requestObj => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_API_REQUEST,
      payload: constant.API_TYPES.UPDATE_PARTNER_DETAIL,
    } );

    const response = await updatePartnerDetail( requestObj );

    if( response.data ) {
      const partnerDetail = new PartnerDetail( response.data );

      dispatch( { type: UPDATE_PARTNER_DETAIL_SUCCESS, payload: { partnerDetail: partnerDetail, message: response.message } } );
    }
    else {
      dispatch( {
        type: UPDATE_PARTNER_DETAIL_FAILURE,
        payload: response.message || "",
      } );
    }

    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: UPDATE_PARTNER_DETAIL_FAILURE, payload: error } );
    dispatch( { type: STOP_LOADER } );

    const err = Object.values( error?.data?.error || {} ) || "";

    if( err[ 0 ] ) {
      toastr.error( err[ 0 ] );
    }
    else {
      toastr.error( error.data.message );
    }
  }
  finally {
    dispatch( { type: PARTNER_API_END } );
  }
};

export const updatePartnerProfileAction = requestObj => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_API_REQUEST,
      payload: constant.API_TYPES.UPDATE_PARTNER_DETAIL,
    } );

    const response = await updatePartnerProfileDetail( requestObj );

    if( response.data ) {
      const partnerDetail = new PartnerDetail( response.data );

      dispatch( { type: UPDATE_PARTNER_PROFILE_SUCCESS, payload: { partnerDetail: partnerDetail, message: response.message } } );
    }
    else {
      dispatch( {
        type: UPDATE_PARTNER_PROFILE_FAILURE,
        payload: response.message || "",
      } );
    }

    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: UPDATE_PARTNER_PROFILE_FAILURE, payload: error } );
    dispatch( { type: STOP_LOADER } );

    const err = Object.values( error?.data?.error || {} ) || "";

    if( err[ 0 ] ) {
      toastr.error( err[ 0 ] );
    }
    else {
      toastr.error( error.data.message );
    }
  }
  finally {
    dispatch( { type: PARTNER_API_END } );
  }
};
