import "react-datepicker/dist/react-datepicker.css";

import PropTypes from "prop-types";
import React from "react";
import DatePicker from "react-datepicker";

const CustomTimePicker = ( {
  value,
  onChange,
  placeholder,
  timeIntervals,
} ) => {
  return (
    <>
      <DatePicker
        placeholderText={placeholder}
        selected={value}
        onChange={onChange}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={timeIntervals}
        timeCaption="Time"
        dateFormat="h:mm aa"
      />
    </>
  );
};

CustomTimePicker.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  timeIntervals: PropTypes.number,
};

export default CustomTimePicker;
