import { toastr } from "react-redux-toastr";
import { PartnerDetail } from "../../entities/partnerDetail";
import {
  getPartnerDraftDetail,
  addPartnerDraftDetail,
  updatePartnerDraftDetail,
  deletePartnerDraftDetail
} from "../../services/partnerDraftServices";
import constant from "../../util/constant";
import partnerProfileConstant from "../../util/partnerProfileConstant";
import {
  START_LOADER,
  STOP_LOADER,
  GET_PARTNER_DRAFT_DETAIL_SUCCESS,
  GET_PARTNER_DRAFT_DETAIL_FAILURE,
  GET_PARTNER_DRAFT_DETAIL_REQUEST,
  PARTNER_DRAFT_API_END,
  PARTNER_DRAFT_API_REQUEST,
  ADD_PARTNER_DRAFT_DETAIL_SUCCESS,
  ADD_PARTNER_DRAFT_DETAIL_FAILURE,
  UPDATE_PARTNER_DRAFT_DETAIL_SUCCESS,
  UPDATE_PARTNER_DRAFT_DETAIL_FAILURE,
  GET_PARTNER_DETAIL_SUCCESS,
  TRUNCATE_PARTNER_DRAFT_DETAIL_SUCCESS
} from "../actionTypes";

const { API_TYPES, TAB } = partnerProfileConstant;
const { ERROR_MESSAGES, NOT_FOUND } = constant;

export const getPartnerDraftDetailAction = parms => async ( dispatch ) => {
  try {
    dispatch( { type: GET_PARTNER_DRAFT_DETAIL_REQUEST } );

    const response = await getPartnerDraftDetail( parms );

    if( response?.data?.draft_id ) {
      const partnerDraftDetail = new PartnerDetail( response.data.draft_content );

      delete response.data.draft_content;
      dispatch( { type: GET_PARTNER_DRAFT_DETAIL_SUCCESS, payload: { data: partnerDraftDetail, meta: response.data } } );

      if( !partnerDraftDetail?.partnerId ) {
        dispatch( { type: GET_PARTNER_DETAIL_SUCCESS, payload: partnerDraftDetail } );
      }
    }
    else {
      dispatch( {
        type: GET_PARTNER_DRAFT_DETAIL_FAILURE,
        payload: response.message || "",
      } );
    }
  }
  catch ( error ) {
    dispatch( { type: GET_PARTNER_DRAFT_DETAIL_FAILURE, payload: error } );

    if( error?.status !== NOT_FOUND ) {
      toastr.error( error?.data?.message || ERROR_MESSAGES );
    }
  }
};

// TODO: refactor so all exits of this function return something
// This might simply be returning null at the end, but needs validation
// eslint-disable-next-line consistent-return
export const addPartnerDraftDetailAction = ( requestObj, tab, loader ) => async ( dispatch ) => {
  try {
    let apiTypes = "";

    switch ( tab ) {
      case TAB.GENERAL_INFO:
        apiTypes = API_TYPES.ADD_PARTNER_DRAFT_DETAIL;

        break;
      case TAB.CONTACT_INFO:
        apiTypes = API_TYPES.ADD_CONTACT_DRAFT_DETAIL;

        break;
      case TAB.OTHER_INFO:
        apiTypes = API_TYPES.ADD_OTHER_DRAFT_DETAIL;

        break;
      case TAB.TERMS:
        apiTypes = API_TYPES.ADD_TERMS_DRAFT_DETAIL;

        break;
      default:
        return apiTypes;
    }

    if( loader ) {
      dispatch( { type: START_LOADER } );
    }

    dispatch( {
      type: PARTNER_DRAFT_API_REQUEST,
      payload: apiTypes,
    } );

    const response = await addPartnerDraftDetail( requestObj );
    const partnerDraftDetail = new PartnerDetail( response.data.draft_content );
    const draftContents = response.data.draft_content;

    delete response.data.draft_content;
    dispatch( { type: ADD_PARTNER_DRAFT_DETAIL_SUCCESS, payload: { data: partnerDraftDetail, meta: response.data, finalData: draftContents } } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    const err = Object.values( error?.error || {} );
    const errMessage = err[ 0 ] || error?.data?.message || "";

    dispatch( { type: ADD_PARTNER_DRAFT_DETAIL_FAILURE, payload: error } );
    dispatch( { type: STOP_LOADER } );

    toastr.error( "Error", errMessage );
  }
  finally {
    dispatch( { type: PARTNER_DRAFT_API_END } );
  }
};

// TODO: refactor so all exits of this function return something
// This might simply be returning null at the end, but needs validation
// eslint-disable-next-line consistent-return
export const updatePartnerDraftDetailAction = ( requestObj, tab, loader ) => async ( dispatch ) => {
  try {
    let apiTypes = "";

    switch ( tab ) {
      case TAB.GENERAL_INFO:
        apiTypes = API_TYPES.UPDATE_PARTNER_DRAFT_DETAIL;

        break;
      case TAB.CONTACT_INFO:
        apiTypes = API_TYPES.UPDATE_CONTACT_DRAFT_DETAIL;

        break;
      case TAB.OTHER_INFO:
        apiTypes = API_TYPES.UPDATE_OTHER_DRAFT_DETAIL;

        break;
      case TAB.TERMS:
        apiTypes = API_TYPES.UPATE_TERMS_DRAFT_DETAIL;

        break;
      default:
        return apiTypes;
    }

    if( loader ) {
      dispatch( { type: START_LOADER } );
    }

    dispatch( {
      type: PARTNER_DRAFT_API_REQUEST,
      payload: apiTypes,
    } );

    const response = await updatePartnerDraftDetail( requestObj );

    if( response.data ) {
      const partnerDraftDetail = new PartnerDetail( response.data.draft_content );

      dispatch( { type: UPDATE_PARTNER_DRAFT_DETAIL_SUCCESS, payload: { data: partnerDraftDetail, meta: response.data, finalData: response.data.draft_content } } );
    }
    else {
      dispatch( {
        type: UPDATE_PARTNER_DRAFT_DETAIL_FAILURE,
        payload: response.message || "",
      } );
    }

    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    const err = Object.values( error?.error || {} );
    const errMessage = err[ 0 ] || error?.data?.message || "";

    dispatch( { type: UPDATE_PARTNER_DRAFT_DETAIL_FAILURE, payload: error } );
    dispatch( { type: STOP_LOADER } );

    toastr.error( "Error", errMessage );
  }
  finally {
    dispatch( { type: PARTNER_DRAFT_API_END } );
  }
};

export const deletePartnerDraftDetailAction = requestObj => async ( dispatch ) => {
  try {
    await deletePartnerDraftDetail( requestObj );
    dispatch( { type: TRUNCATE_PARTNER_DRAFT_DETAIL_SUCCESS, payload: "" } );
  }
  catch ( error ) {
    const err = Object.values( error?.error || {} );
    const errMessage = err[ 0 ] || error?.data?.message || "";

    toastr.error( "Error", errMessage );
  }
  finally {
    dispatch( { type: PARTNER_DRAFT_API_END } );
  }
};
