import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import DeleteIcon from "@material-ui/icons/Delete";
import HelpIcon from "@material-ui/icons/Help";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { draftToMarkdown } from "markdown-draft-js";
import PropTypes from "prop-types";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Row from "react-bootstrap/Row";
import Tooltip from "react-bootstrap/Tooltip";
import { Editor } from "react-draft-wysiwyg";
import Enums from "../../../util/Enums";
import constant from "../../../util/constant";
import { stripHtml, createRelationExpression } from "../../../util/helpers";
import { validateAchievements, validateRewards } from "../../../util/validateMultipleFields";
import Achievements from "./achievements";
import OptIn from "./optIn";
import Reward from "./reward";

const { EDITOR_OPTIONS, BRANDING: { APP_SHORT_NAME } } = constant;
const { NUMERIC_VALUE: { ONE } } = Enums;

const WRAPPER_RANDOM_MAX = 10000;

const OfferTemplate = ( {
  isTemplate,
  values,
  errors,
  lookup,
  handleInputChange,
  handleChange,
  onSelectedEvent,
  onBlur,
  editorOnBlur,
  editorOnFocus,
  configuration,
  type,
  typeName,
  displayTimeLimit,
  addReward,
  onClickDeleteReward,
  membershipTypes,
  isTournament = false,
  wrapperId: propsWrapperId,
} ) => {
  // This is a workaround for snapshot testing where the Editor component
  // uses a random ID for the wrapperId. This will allow tests to specify
  // the wrapperId (making it fixed for the snapshot), but still work
  // exactly as expected during normal operations
  const wrapperId = propsWrapperId
    ? propsWrapperId
    : Math.floor( Math.random() * WRAPPER_RANDOM_MAX );
  const { partnerCostUsdPerQxPoint = 0, usdPerQxPoint = 0 } = configuration;

  const onEditorStateChange = ( editorState ) => {
    handleChange( "description", editorState );

    const rawContent = convertToRaw( editorState.getCurrentContent() );
    const htmlElement = draftToHtml( rawContent );
    const htmlText = stripHtml( htmlElement );

    handleChange( "rawDescription", draftToMarkdown( rawContent ) );
    handleChange( "descriptionText", htmlText.trim() );
  };

  const updateAchievementInput = ( name, value, i ) => {
    const { achievements, achievementErrors } = values;
    const obj = achievements[ i ];

    obj[ name ] = value;
    handleChange( "achievements", achievements );

    const newAchievementErrors = validateAchievements( achievements, achievementErrors, name, !displayTimeLimit );

    handleChange( "achievementErrors", newAchievementErrors );

    if( name === "event" ) {
      onSelectedEvent( value.value );
    }
  };

  const handleAchievementInputChange = ( e, i ) => {
    const { name, value } = e.target;

    updateAchievementInput( name, value, i );
  };

  const handleRewardInputChange = ( e, i ) => {
    const { name, value } = e.target;
    const { rewards } = values;
    const obj = rewards[ i ];

    obj[ name ] = value;
    handleChange( "rewards", rewards );

    const newRewardsErrors = validateRewards( rewards, !isTemplate );

    handleChange( "rewardsErrors", newRewardsErrors );
  };

  const handleDeleteAchievement = ( index ) => {
    const { achievements, achievementErrors } = values;

    achievements.splice( index, 1 );
    achievementErrors.splice( index, 1 );
    handleChange( "achievements", achievements );
    handleChange( "achievementErrors", achievementErrors );
    handleChange( "relationExpression", createRelationExpression( achievements ) );
  };

  const handleAchievementEditor = ( editorState, i ) => {
    updateAchievementInput( "description", editorState, i );

    const rawContent = convertToRaw( editorState.getCurrentContent() );
    const htmlElement = draftToHtml( rawContent );
    const htmlText = stripHtml( htmlElement );

    updateAchievementInput( "rawDescription", draftToMarkdown( rawContent ), i );
    updateAchievementInput( "descriptionText", htmlText.trim(), i );
  };

  return (
    <>
      <h4 className='page-inner-heading mt-3'>{isTemplate ? `Add ${ type } Details` : `2. Add ${ type } Details`}</h4>
      <Row>
        <Col sm={6}>
          <Form.Group>
            <Form.Label>{isTemplate ? "Template Name" : `${ type } Name`}
              <OverlayTrigger placement='right' overlay={<Tooltip>Add the name of the template that you are creating/updating.</Tooltip>}>
                <HelpIcon className="help-icon" />
              </OverlayTrigger>
            </Form.Label>
            <Form.Control
              type='text'
              placeholder={isTemplate ? "Template Name" : `${ type } Name`}
              value={values[ typeName ]}
              name={typeName}
              onChange={handleInputChange}
              maxLength={50}
              data-testid={typeName}
              onBlur={onBlur}
            />
            <div className='invalid-feedback d-block'>
              {errors[ typeName ]}
            </div>
          </Form.Group>
        </Col>
        <Col sm={12}>
          <Form.Group>
            <Form.Label className='label-md'>
              {type} Description to Promote to Players
              <OverlayTrigger placement='right' overlay={<Tooltip>Gamers will see this when they click on your game in the { APP_SHORT_NAME } App.</Tooltip>}>
                <HelpIcon className="help-icon" />
              </OverlayTrigger>
            </Form.Label>
            <Editor
              editorState={values.description}
              placeholder='Write Description to Promote to Players'
              toolbarClassName='toolbarClassName'
              wrapperClassName='wrapperClassName'
              wrapperId={wrapperId}
              editorClassName='editorClassName'
              onEditorStateChange={onEditorStateChange}
              onBlur={editorOnBlur}
              onFocus={editorOnFocus}
              toolbar={
                EDITOR_OPTIONS
              }
            />
            <div className='invalid-feedback d-block'>
              {errors.descriptionText}
            </div>
          </Form.Group>
        </Col>
      </Row>
      <Achievements
        values={values}
        onChange={handleAchievementInputChange}
        onDelete={handleDeleteAchievement}
        onSelect={updateAchievementInput}
        onEditorStateChange={handleAchievementEditor}
        events={lookup?.EnabledEvent || []}
        onBlur={onBlur}
        editorOnBlur={editorOnBlur}
        editorOnFocus={editorOnFocus}
        displayTimeLimit={displayTimeLimit}
      />
      <Row>
        <Col sm={12}>
          <h4 className='page-inner-heading small'>{isTemplate ? "Rewards (Optional)" : "Rewards"}</h4>
          {displayTimeLimit && (
            <Row>
              <Reward
                isTemplate={isTemplate}
                qxPoint={values.qxPoint}
                handleInputChange={handleInputChange}
                onBlur={onBlur}
                errorsQxPoint={errors.qxPoint}
                usdPerQxPoint={usdPerQxPoint}
                partnerCostUsdPerQxPoint={partnerCostUsdPerQxPoint}
                sm={3}
              />
            </Row>
          )}
          {!displayTimeLimit && (
            values.rewards.map( ( reward, index ) => (
              <Row key={index}>
                <Col sm={3}>
                  <Form.Group>
                    <Form.Label>Place</Form.Label>
                    <Form.Control
                      type='number'
                      value={reward.place}
                      name='place'
                      data-testid='place'
                      disabled={true}
                    />
                  </Form.Group>
                </Col>
                <Reward
                  isTemplate={isTemplate}
                  qxPoint={reward.qxPoint}
                  handleInputChange={e => handleRewardInputChange( e, index )}
                  onBlur={onBlur}
                  errorsQxPoint={values?.rewardsErrors[ index ]}
                  usdPerQxPoint={usdPerQxPoint}
                  partnerCostUsdPerQxPoint={partnerCostUsdPerQxPoint}
                  sm={3}
                />

                <Col sm={3}>
                  <Form.Group>
                    <Form.Label>&nbsp;</Form.Label>
                    <div className='tour-delete-icon'>
                      {/* delete icon will be display when reward will more than one. */}
                      {( values.rewards.length > ONE ) && (
                        <div className='text-danger cursor-pointer mr-3'>
                          <DeleteIcon onClick={() => onClickDeleteReward( reward.place )} />
                        </div>
                      )}
                      {( ( values.rewards.length - ONE ) === index ) && (
                        <Button variant='outline-primary' className="btn-md d-block" onClick={addReward}>
                        Add Another
                        </Button>
                      )}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            ) )
          )}
        </Col>
        <Col sm={12}>
          <h4 className='page-inner-heading small'>
            Opt In (Optional)
            <OverlayTrigger
              placement='right'
              overlay={
                <Tooltip>
                  Whether a gamer has to opt in to the { isTournament ? "Tournament" : "Offer" }.
                </Tooltip>
              }>
              <HelpIcon className="help-icon" />
            </OverlayTrigger>
          </h4>
          <Row>
            <OptIn
              values={values}
              handleInputChange={handleInputChange}
              onBlur={onBlur}
              errors={errors}
              membershipTypes={membershipTypes}
              isTournament={isTournament}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
};

OfferTemplate.propTypes = {
  isTemplate: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  lookup: PropTypes.object,
  handleInputChange: PropTypes.func,
  handleChange: PropTypes.func,
  onSelectedEvent: PropTypes.func,
  onBlur: PropTypes.func,
  editorOnBlur: PropTypes.func,
  editorOnFocus: PropTypes.func,
  configuration: PropTypes.object,
  type: PropTypes.string,
  typeName: PropTypes.string,
  displayTimeLimit: PropTypes.bool,
  addReward: PropTypes.func,
  onClickDeleteReward: PropTypes.func,
  membershipTypes: PropTypes.array,
  isTournament: PropTypes.bool,
  wrapperId: PropTypes.string,
};

export default OfferTemplate;
