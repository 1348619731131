import React, { useMemo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { useAddQuery, useForm, getUrlData } from "../../../../hooks";
import constant from "../../../../util/constant";
import { checkFilterApplied, getUrlParameter } from "../../../../util/helpers";
import offerConstant from "../../../../util/offerConstant";
import {
  gamerOfferLinkedConfigs,
  gamersOfferRewardTxnTable,
  adminGamerTournamentTableConfigs,
  gamersTournamentRewardTxnTable
} from "../../../../util/tableConstant";
import OfferRewardTxn from "./offerRewardTxn";
import Offers from "./offers";
import OffersHeader from "./offersHeader";

const { OFFERS_TABS, SEARCH_PLACEHOLDER } = offerConstant;
const { API_STATUS, FILTERS_NAME, ORDER_BY, ROUTES } = constant;

const AdminGamerOffers = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { addQuery } = useAddQuery();
  const queryParamsOriginal = new URLSearchParams( window.location.search );
  const activeTab = queryParamsOriginal.get( "tab" );
  const [ , , , entityType ] = location.pathname.split( "/" );
  const isGamerOffers = entityType === "offers";
  const { gamerId: URLString } = useParams();
  const { 0: gamerId } = String( URLString ).split( "?" );

  const {
    gamerDetail,
    qxPointMinMax,
    gamerPlaceMinMax,
  } = useSelector( state => ( {
    gamerDetail: state.adminGamer.gamerDetail,
    qxPointMinMax: state.adminGamer.gamerRewardTxn.rewardTxnFilters.qxPointMinMax,
    gamerPlaceMinMax: state.adminGamer.gamerRewardTxn.rewardTxnFilters.gamerPlaceMinMax,
  } ) );

  const sliderMinMaxObj = {
    minQxPoint: qxPointMinMax.min,
    maxQxPoint: qxPointMinMax.min,
    minGamerPlace: gamerPlaceMinMax.min,
    maxGamerPlace: gamerPlaceMinMax.max,
  };

  const { values, handleChange } = useForm(
    {
      tab: getUrlParameter( "tab", isGamerOffers ? OFFERS_TABS.OFFERS : OFFERS_TABS.TOURNAMENTS ),
      searchKey: "",
      placeholder: isGamerOffers ? SEARCH_PLACEHOLDER.offers : SEARCH_PLACEHOLDER.tournaments,
    },
    {}
  );

  const isFilterApplied = checkFilterApplied( window.location.search, FILTERS_NAME.GAMER_LINKED_OFFERS, sliderMinMaxObj );
  const [ showFilter, setShowFilter ] = useState( false );
  const memoizedTableConfig = useMemo( () => {
    if( isGamerOffers ) {
      return [ gamerOfferLinkedConfigs, gamersOfferRewardTxnTable ];
    }

    return [ adminGamerTournamentTableConfigs, gamersTournamentRewardTxnTable ];
  }, [] );

  const setTab = ( val ) => {
    handleChange( "searchKey", "" );
    setShowFilter( false );

    const route = isGamerOffers ? ROUTES.GAMER_OFFER_LIST : ROUTES.ADMIN_GAMER_TOURNAMENTS;

    history.replace( {
      pathname: `${ route.replace(
        ":gamerId",
        gamerId
      ) }?tab=${ val }`,
      state: location.state,
    } );
  };

  const setPreviousData = () => {
    getUrlData( handleChange );
    handleChange( "placeholder", SEARCH_PLACEHOLDER[ values.tab ] );
  };

  useEffect( () => {
    handleChange( "tab", activeTab );
  }, [ activeTab ] );

  return (
    <>
      <div className="main-right-wrapper">
        <OffersHeader
          tab={values.tab}
          addQuery={addQuery}
          handleChange={handleChange}
          gamerName={gamerDetail.gamerFullName}
          gamerProfileImage={gamerDetail?.profileImage}
          location={location}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          values={values}
          setTab={setTab}
          isFilterApplied={isFilterApplied}
          route={ROUTES.GAMER_SECTION}
          isGamerTournaments={!isGamerOffers}
        />
        {
          ( ( values.tab === OFFERS_TABS.OFFERS ) ||
          ( values.tab === OFFERS_TABS.TOURNAMENTS ) ) && <Offers
            location={location}
            showFilter={showFilter}
            addQuery={addQuery}
            dispatch={dispatch}
            gamerId={gamerId}
            history={history}
            searchKey={values.searchKey}
            apiStatus={API_STATUS.SUCCESS}
            route={ isGamerOffers
              ? ROUTES.ADMIN_GAMER_LINKED_OFFER_DETAILS
              : ROUTES.ADMIN_GAMER_TOURNAMENT_DETAILS
            }
            setPreviousData={setPreviousData}
            memoizedTableConfig={memoizedTableConfig[ 0 ]}
            orderBy={ORDER_BY}
            isGamerTournaments={!isGamerOffers}
          />
        }
        {
          ( ( values.tab === OFFERS_TABS.OFFER_REWARD_TXN ) ||
          ( values.tab === OFFERS_TABS.TOURNAMENT_REWARD_TXN ) ) && <OfferRewardTxn
            setPreviousData={setPreviousData}
            memoizedTableConfig={memoizedTableConfig[ 1 ]}
            location={location}
            showFilter={showFilter}
            addQuery={addQuery}
            dispatch={dispatch}
            gamerId={gamerId}
            searchKey={values.searchKey}
            apiStatus={API_STATUS.SUCCESS}
            route={ROUTES.ADMIN_GAMER_LINKED_OFFER_DETAILS}
            orderBy={ORDER_BY}
            isGamerTournaments={!isGamerOffers}
          />
        }
      </div>
    </>
  );
};

export default AdminGamerOffers;
