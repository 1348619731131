import { toastr } from "react-redux-toastr";
import { AdminUserDetails } from "../../entities/adminUserDetails";
import {
  getAdminUserList,
  getAdminUsers,
  addAdminUser,
  getAdminUserDetail,
  updateAdminUserPermission,
  enableDisableAdminUser,
  updateAdminSignOutConfirmation
} from "../../services/adminUserService";
import adminUserConstant from "../../util/adminUserConstant";
import constant from "../../util/constant";
import {
  START_LOADER,
  STOP_LOADER,
  ADMIN_USER_API_REQUEST,
  ADMIN_USER_API_END,
  GET_ADMIN_USER_SUCCESS,
  GET_ADMIN_USER_FAILURE,
  ADD_ADMIN_USER_FAILURE,
  ADD_ADMIN_USER_SUCCESS,
  GET_ADMIN_USER_DETAIL_SUCCESS,
  GET_ADMIN_USER_DETAIL_FAILURE,
  UPDATE_ADMIN_USER_PERMISSION_SUCCESS,
  UPDATE_ADMIN_USER_PERMISSION_FAILURE,
  GET_ADMIN_DETAIL_SUCCESS,
  GET_ADMIN_DETAIL_FAILURE,
  ADMIN_DETAIL_API_REQUEST,
  ADMIN_DETAIL_API_END,
  ADMIN_USERS_FILTER_LIST_SUCCESS,
  ADMIN_USERS_FILTER_LIST_FAILURE,
  ENABLE_DISABLE_ADMIN_USER_SUCCESS,
  ENABLE_DISABLE_ADMIN_USER_FAILURE,
  UPDATE_ADMIN_DETAIL_SUCCESS,
  ADMIN_SIGN_OUT_CONFIRMATION_SUCCESS,
  ADMIN_SIGN_OUT_CONFIRMATION_FAILURE
} from "../actionTypes";

const { ADMIN_USERS_API_TYPES } = adminUserConstant;
const { ERROR_MESSAGES } = constant;

export const getAdminUserAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_USER_API_REQUEST,
      payload: ADMIN_USERS_API_TYPES.GET_ADMIN_USERS,
    } );

    const response = await getAdminUsers( param );
    const dataList = response.data.adminUsers.map(
      item => new AdminUserDetails( item )
    );

    dispatch( {
      type: GET_ADMIN_USER_SUCCESS,
      payload: { dataList, meta: response.data.meta },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GET_ADMIN_USER_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_USER_API_END,
    } );
  }
};

export const addAdminUserAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_USER_API_REQUEST,
      payload: ADMIN_USERS_API_TYPES.ADD_ADMIN_USER,
    } );

    const response = await addAdminUser( param );

    dispatch( {
      type: ADD_ADMIN_USER_SUCCESS,
      payload: response.data,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    const err = Object.values( error?.data?.error || {} ) || "";

    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: ADD_ADMIN_USER_FAILURE,
      payload: error,
    } );

    if( err[ 0 ] ) {
      toastr.error( err[ 0 ] );
    }
    else {
      toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
    }
  }
  finally {
    dispatch( {
      type: ADMIN_USER_API_END,
    } );
  }
};

export const getAdminUserDetailAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_USER_API_REQUEST,
      payload: ADMIN_USERS_API_TYPES.GET_ADMIN_USER_DETAIL,
    } );

    const response = await getAdminUserDetail( param );
    const newResponse = new AdminUserDetails( response.data );

    dispatch( {
      type: GET_ADMIN_USER_DETAIL_SUCCESS,
      payload: newResponse,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GET_ADMIN_USER_DETAIL_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_USER_API_END,
    } );
  }
};

export const getAdminDetailAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_DETAIL_API_REQUEST,
      payload: ADMIN_USERS_API_TYPES.GET_ADMIN_DETAIL,
    } );

    const response = await getAdminUserDetail( param );
    const newResponse = new AdminUserDetails( response.data );

    dispatch( {
      type: GET_ADMIN_DETAIL_SUCCESS,
      payload: newResponse,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GET_ADMIN_DETAIL_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_DETAIL_API_END,
    } );
  }
};

export const getAdminUserPermissionAction = ( param, activeAdminId ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_USER_API_REQUEST,
      payload: ADMIN_USERS_API_TYPES.UPDATE_ADMIN_USER_PERMISSION,
    } );

    const response = await updateAdminUserPermission( param );
    const isActiveAdmin = ( activeAdminId === param?.admin_id );

    dispatch( {
      type: UPDATE_ADMIN_DETAIL_SUCCESS,
      payload: { ...param, isActiveAdmin },
    } );
    dispatch( {
      type: UPDATE_ADMIN_USER_PERMISSION_SUCCESS,
      payload: response.data,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: UPDATE_ADMIN_USER_PERMISSION_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_USER_API_END,
    } );
  }
};

export const getAdminUserFilterListAction = filters => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );

    const response = await getAdminUserList( filters );

    if( response ) {
      const data = response.data.adminUsers;
      const arrayUniqueByKey = [];
      const filterArr = [ "full_name", "username", "email" ];
      const filterArrNames = [ "fullNames", "usernames", "adminEmails" ];
      const filterArrData = [ ];

      filterArr.forEach( async function( element, index ) {
        filterArrData[ filterArrNames[ index ] ] = data.map( val => ( {
          label: val[ element ],
          value: val[ element ] }
        ) )
          .sort( ( a, b ) => a.label.localeCompare( b.label ) ) ?? [];
        arrayUniqueByKey[ filterArrNames[ index ] ] = [ ...new Map( filterArrData[ filterArrNames[ index ] ].map( item =>
          [ item.value, item ] ) ).values() ];
      } );

      dispatch( {
        type: ADMIN_USERS_FILTER_LIST_SUCCESS,
        payload: Object.assign( {}, arrayUniqueByKey ),
      } );
    }
    else {
      dispatch( { type: ADMIN_USERS_FILTER_LIST_FAILURE, payload: response.message || "" } );
    }

    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: ADMIN_USERS_FILTER_LIST_FAILURE, payload: error } );
    dispatch( { type: STOP_LOADER } );
  }
  finally {
    dispatch( { type: ADMIN_USER_API_END } );
  }
};

export const enableDisableAdminUserAction = requestObj => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_USER_API_REQUEST,
      payload: ADMIN_USERS_API_TYPES.ENABLE_DISABLE_ADMIN_USER,
    } );

    const response = await enableDisableAdminUser( requestObj );

    dispatch( { type: ENABLE_DISABLE_ADMIN_USER_SUCCESS, payload: response.data } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: ENABLE_DISABLE_ADMIN_USER_FAILURE, payload: error } );
    dispatch( { type: STOP_LOADER } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( { type: ADMIN_USER_API_END } );
  }
};

export const updateAdmin = requestObj => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_USER_API_REQUEST,
      payload: ADMIN_USERS_API_TYPES.SIGN_OUT_CONFIRMATION,
    } );

    const response = await updateAdminSignOutConfirmation( requestObj );

    dispatch( { type: ADMIN_SIGN_OUT_CONFIRMATION_SUCCESS, payload: response.data } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: ADMIN_SIGN_OUT_CONFIRMATION_FAILURE, payload: error } );
    dispatch( { type: STOP_LOADER } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( { type: ADMIN_USER_API_END } );
  }
};
