import VisibilityIcon from "@material-ui/icons/Visibility";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

// Local-imports.
import { clearGamerDetailAction } from "../../../store/actions/adminGamer";
import constant from "../../../util/constant";
import { formatNumberAsPerLocale } from "../../../util/helpers";

const { ROUTES } = constant;

const Gamer = ( {
  gamer,
  viewAllGames,
} ) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <>
      <div className="td">
        <span className="strong">{gamer.fullNames}</span>
      </div>
      <div className="td">
        {gamer.linkedGamesCount}
        {Boolean( gamer.linkedGames.length ) && (
          <div onClick={() => viewAllGames( gamer.linkedGames )}
            className="link cursor-pointer" title="View All">
            View All
          </div> )}
      </div>
      <div className="td">{formatNumberAsPerLocale( gamer.acqyrCashBalance )}</div>
      <div className="td">
        <span className='icon cursor-pointer' onClick={() => {
          dispatch( clearGamerDetailAction() );
          history.push( {
            pathname: `${ ROUTES.PARTNER_GAMER_SECTION.replace(
              ":gamerId",
              gamer.userId
            ) }?from=partnerGamers`,
            state: [
              { from: "partnerGamers", path: location.pathname, state: location.search },
            ],
          } );
        }}>
          <VisibilityIcon />
        </span>
      </div>
    </>
  );
};

Gamer.propTypes = {
  gamer: PropTypes.object,
  viewAllGames: PropTypes.func,
};

export default Gamer;
