import PropTypes from "prop-types";
import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import SelectBox from "../../shared/selectBox";

const BusinessLocationAddress = ( {
  values,
  errors,
  handleInputChange,
  handleSelectInput,
  address1Ref,
  zipRef,
  onBlur,
} ) => (
  <>
    <h4 className='page-inner-heading'>Business Location Address</h4>
    <Row>
      <Col sm={6}>
        <Form.Group>
          <Form.Label>Address Line 1</Form.Label>
          <Form.Control
            type='text'
            value={values.address1}
            name='address1'
            onChange={handleInputChange}
            maxLength={255}
            ref={address1Ref}
            onBlur={onBlur}
          />
          <div className='invalid-feedback d-block'>
            {errors.address1}
          </div>
        </Form.Group>
      </Col>
      <Col sm={6}>
        <Form.Group>
          <Form.Label>Address Line 2 (Optional)</Form.Label>
          <Form.Control
            type='text'
            value={values.address2}
            name='address2'
            onChange={handleInputChange}
            maxLength={255}
            onBlur={onBlur}
          />
        </Form.Group>
      </Col>
      <Col sm={6}>
        <Form.Group>
          <Form.Label>Country</Form.Label>
          <SelectBox
            options={values.countryList}
            placeholder='Select Country'
            onChange={value => handleSelectInput( "country", value )}
            value={values.country}
            onBlur={onBlur}
          />
          <div className='invalid-feedback d-block'>
            {errors.country}
          </div>
        </Form.Group>
      </Col>
      <Col sm={6}>
        <Form.Group>
          <Form.Label>State</Form.Label>
          <SelectBox
            options={values.stateList}
            placeholder='Select State'
            onChange={value => handleSelectInput( "state", value )}
            value={values.state}
            onBlur={onBlur}
          />
          <div className='invalid-feedback d-block'>
            {errors.state}
          </div>
        </Form.Group>
      </Col>
      <Col sm={6}>
        <Form.Group>
          <Form.Label>City</Form.Label>
          <SelectBox
            options={values.cityList}
            placeholder='Select City'
            onChange={value => handleSelectInput( "city", value )}
            value={values.city}
            onBlur={onBlur}
          />
          <div className='invalid-feedback d-block'>
            {errors.city}
          </div>
        </Form.Group>
      </Col>
      <Col sm={6}>
        <Form.Group>
          <Form.Label>Zipcode</Form.Label>
          <Form.Control
            type='text'
            value={values.zip}
            name='zip'
            onChange={handleInputChange}
            maxLength={45}
            data-testid='zip'
            ref={zipRef}
            onBlur={onBlur}
          />
          <div className='invalid-feedback d-block'>{errors.zip}</div>
        </Form.Group>
      </Col>
    </Row>
  </>
);

BusinessLocationAddress.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  handleInputChange: PropTypes.func,
  handleSelectInput: PropTypes.func,
  address1Ref: PropTypes.func,
  zipRef: PropTypes.func,
  onBlur: PropTypes.func,
};

export default BusinessLocationAddress;
