import constant from "../../util/constant";
import {
  ADMIN_GAME_API_END,
  ADMIN_GAME_API_REQUEST,
  GET_ADMIN_GAMES_SUCCESS,
  GET_ADMIN_GAMES_FAILURE,
  ADD_MODIFICATION_SUCCESS,
  ADD_MODIFICATION_FAILURE,
  GET_ADMIN_GAME_DETAILS_SUCCESS,
  GET_ADMIN_GAME_DETAILS_FAILURE,
  UPDATE_PARTNER_GAME_STATUS_SUCCESS,
  UPDATE_PARTNER_GAME_STATUS_FAILURE,
  ADD_ADMIN_COMMENT_SUCCESS,
  ADD_ADMIN_COMMENT_FAILURE,
  ENABLE_DISABLE_PARTNER_GAME_SUCCESS,
  ENABLE_DISABLE_PARTNER_GAME_FAILURE,
  ADMIN_GAMES_LINKED_GAMERS_LIST_SUCCESS,
  ADMIN_GAMES_LINKED_GAMERS_LIST_FAILURE,
  ADMIN_GAMES_LINKED_GAMERS_FILTER_LIST_SUCCESS,
  ADMIN_GAMES_LINKED_GAMERS_FILTER_LIST_FAILURE,
  ADMIN_GAMES_LINKED_GAMER_LIST_REQUEST,
  ADMIN_GAMES_LINKED_GAMER_FILTER_LIST_API_REQUEST
} from "../actionTypes";

const { API_STATUS } = constant;

const DEFAULT_STATE = {
  status: "",
  apiType: "",
  adminGames: { games: [], meta: "" },
  gameDetails: {},
  msg: "",
  partnerLinkedGamers: { gamers: [], meta: "" },
  adminLinkedGamerFilter: {
    acqyrCashBalance: { min: 0, max: 0 }, gamerNames: [],
    linkedGameRange: { min: 0, max: 0 },
  },
};

// TODO: refactor to avoid complexity
// eslint-disable-next-line complexity
const adminGame = ( state = DEFAULT_STATE, action = "" ) => {
  switch ( action.type ) {
    case ADMIN_GAME_API_REQUEST:
    case ADMIN_GAMES_LINKED_GAMER_LIST_REQUEST:
    case ADMIN_GAMES_LINKED_GAMER_FILTER_LIST_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
      };
    case ADMIN_GAME_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case GET_ADMIN_GAMES_SUCCESS:
      return {
        ...state,
        adminGames: {
          games:
            action.payload.meta.page === 1
              ? action.payload.dataList
              : [ ...state.adminGames.games, ...action.payload.dataList ],
          meta: action.payload.meta,
        },
        status: API_STATUS.SUCCESS,
      };
    case ADD_MODIFICATION_SUCCESS:
    case UPDATE_PARTNER_GAME_STATUS_SUCCESS:
    case ENABLE_DISABLE_PARTNER_GAME_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        msg: action.payload,
      };
    case GET_ADMIN_GAME_DETAILS_SUCCESS:
      return {
        ...state,
        gameDetails: action.payload,
        status: API_STATUS.SUCCESS,
      };
    case GET_ADMIN_GAMES_FAILURE:
    case ADD_MODIFICATION_FAILURE:
    case GET_ADMIN_GAME_DETAILS_FAILURE:
    case UPDATE_PARTNER_GAME_STATUS_FAILURE:
    case ADD_ADMIN_COMMENT_FAILURE:
    case ENABLE_DISABLE_PARTNER_GAME_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
      };
    case ADD_ADMIN_COMMENT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        gameDetails: {
          ...state.gameDetails,
          GameTestingComments: [ ...state.gameDetails.GameTestingComments, { ...action.payload, partnerId: action.payload.partner_id } ],
        },
      };
    case ADMIN_GAMES_LINKED_GAMERS_FILTER_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        adminLinkedGamerFilter: {
          acqyrCashBalance: action.payload.acqyrCashBalance,
          gamerNames: action.payload.gamerNames,
          linkedGameRange: action.payload.linkedGameRange,
        },
      };
    case ADMIN_GAMES_LINKED_GAMERS_LIST_SUCCESS:
      return {
        ...state,
        partnerLinkedGamers: {
          gamers:
                    action.payload.meta.page === 1
                      ? action.payload.dataList
                      : [ ...state.partnerLinkedGamers.gamers, ...action.payload.dataList ],
          meta: action.payload.meta,
        },
        status: API_STATUS.SUCCESS,
      };
    case ADMIN_GAMES_LINKED_GAMERS_LIST_FAILURE:
    case ADMIN_GAMES_LINKED_GAMERS_FILTER_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        partnerLinkedGamers: { gamers: [], meta: "" },
        adminLinkedGamerFilter: {
          acqyrCashBalance: { min: 0, max: 0 },
          gamerNames: [],
          linkedGameRange: { min: 0, max: 0 },
        },
      };
    default:
      return state;
  }
};

export default adminGame;
