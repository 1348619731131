import VisibilityIcon from "@material-ui/icons/Visibility";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";

import constant from "../../../../util/constant";

const { ROUTES } = constant;

const LinkedGamersList = ( {
  linkedGamersList,
  location,
  viewAllGames,
} ) => {
  const history = useHistory();

  return (
    <>
      <div className="table-body no-scrollbar" >
        {Boolean( linkedGamersList.length ) && linkedGamersList.map( ( gamer, index ) => (
          <div className="table-row" key={index}>
            <div className="td">{gamer.fullName}</div>
            <div className="td">
              {gamer.linkedGamesCount}
              {Boolean( gamer.linkedGames.length ) && ( <div onClick={() => viewAllGames( gamer.linkedGames )}
                className="link ml-1 cursor-pointer" title="View All">
                View All
              </div> )}
            </div>
            <div className="td">{ gamer.gamerPlan}</div>
            <div className="td" onClick={() =>
              history.push( {
                pathname: `${ ROUTES.PARTNER_ANALYTICS_GAMER_ASSETS.replace(
                  ":gameId",
                  gamer?.gameId
                ).replace(
                  ":gamerId",
                  gamer?.userId
                ) }?from=analytics`,
                state: [ { from: "analyticsLinkedGamers", path: location.pathname, state: location.search } ],
              } )
            }>
              <span className='cursor-pointer'><VisibilityIcon /></span>
            </div>
          </div>
        ) )}
        {!linkedGamersList.length && (
          <div className='no-record' data-testid='no-record-found'>
            No Linked Gamers Found
          </div>
        )}
      </div>
    </>
  );
};

LinkedGamersList.propTypes = {
  linkedGamersList: PropTypes.array,
  location: PropTypes.object,
  viewAllGames: PropTypes.func,
};

export default LinkedGamersList;
