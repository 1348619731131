import "react-datepicker/dist/react-datepicker.css";

import moment from "moment";
import * as momentTimezone from "moment-timezone";
import PropTypes from "prop-types";
import React, { memo } from "react";
import DatePicker from "react-datepicker";

const CustomDatePicker = ( { value, handleChange, minDate = null, maxDate = null, startDate, endDate, selectsRange = false, placeholder = "dd mmm yyyy" } ) => {
  const getSelectedDate = ( date ) => {
    const selectedDate = date.length !== 0 ? moment( date ).format( "YYYY-MM-DD" ) : moment().format( "YYYY-MM-DD" );
    const currentTime = moment().format( "HH:mm:ss" );
    const currentTimeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

    return momentTimezone.tz( `${ selectedDate } ${ currentTime }`, currentTimeZone ).format();
  };

  let selected = null;

  if( !selectsRange ) {
    selected = value === false ? false : new Date( getSelectedDate( value ) );
  }

  return (
    <>
      <DatePicker
        selected={ selected }
        onChange={( e ) => {
          if( Array.isArray( e ) ) {
            handleChange( e );
          }
          else if( e ) {
            handleChange( new Date( e ) );
          }
          else {
            handleChange( false );
          }
        }}
        dateFormat="dd MMM yyyy"
        isClearable={ value === false ? false : true}
        placeholderText= {placeholder}
        minDate={minDate}
        maxDate={maxDate}
        startDate={ startDate }
        endDate={ endDate }
        selectsRange={ selectsRange }
      />
    </>
  );
};

CustomDatePicker.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  selectsRange: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default memo( CustomDatePicker );
