import { START_LOADER, STOP_LOADER } from "../actionTypes";

const initialState = {
  isShow: false,
};

const loader = function( state = initialState, action = "" ) {
  switch ( action.type ) {
    case START_LOADER:
      return {
        isShow: true,
      };

    case STOP_LOADER:
      return {
        isShow: false,
      };
    default:
      return state;
  }
};

export {
  loader
};
