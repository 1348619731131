import { toastr } from "react-redux-toastr";
import { getPartnerLookup } from "../../services/partnerServices";
import constant from "../../util/constant";
import {
  START_LOADER,
  STOP_LOADER,
  PARTNER_LOOKUP_API_REQUEST,
  PARTNER_LOOKUP_API_END,
  GET_PARTNER_LOOKUP_SUCCESS,
  GET_PARTNER_LOOKUP_FAILURE,
  GET_OFFER_LIST_SUCCESS,
  GET_OFFER_LIST_FAILURE,
  PARTNER_OFFER_NAMES_API_REQUEST,
  PARTNER_OFFER_NAMES_API_END
} from "../actionTypes";

const { ERROR_MESSAGES, API_TYPES } = constant;

export const getPartnerLookupAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_LOOKUP_API_REQUEST,
      payload: API_TYPES.GET_PARTNER_LOOKUP,
    } );

    const response = await getPartnerLookup( param );
    const paramArr = param.entities.split( "," );
    const lookup = {};

    paramArr.forEach( ( key ) => {
      lookup[ key ] = response.data[ key ]?.map( ( item ) => {
        const keys = Object.keys( item );
        const nameIndex = keys.join( " " ).replace( /event_name/g, "name" )
          .split( " " )
          .indexOf( "name" );

        return {
          label: item[ keys[ nameIndex ] ],
          value: nameIndex === 0 ? item[ keys[ 1 ] ] : item[ keys[ 0 ] ],
          ...( item.icon_url && { gameIconUrl: item.icon_url } ),
        };
      } )
        .sort( ( a, b ) => a.label.localeCompare( b.label ) ) ?? [];
    } );
    dispatch( {
      type: GET_PARTNER_LOOKUP_SUCCESS,
      payload: lookup,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GET_PARTNER_LOOKUP_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: PARTNER_LOOKUP_API_END,
    } );
  }
};

export const getOfferListAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_OFFER_NAMES_API_REQUEST,
      payload: API_TYPES.GET_OFFER_LIST,
    } );

    const response = await getPartnerLookup( param );
    const offerList = response.data?.Offer?.map( item => item.name ) || [];

    dispatch( {
      type: GET_OFFER_LIST_SUCCESS,
      payload: offerList,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GET_OFFER_LIST_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: PARTNER_OFFER_NAMES_API_END,
    } );
  }
};

