import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// Local imports.
import { usePartnerDraft, useForm, useModal } from "../../../hooks";
import { uploadPartnerDetailAction, updatePartnerDetailAction } from "../../../store/actions/partner";
import { deletePartnerDraftDetailAction } from "../../../store/actions/partnerDraft";
import { setTermsInfoAction } from "../../../store/actions/profileInfo";
import adminConstant from "../../../util/adminConstant";
import constant from "../../../util/constant";
import { getAllCountries, getStatesOfCountry, changeErrorMessage } from "../../../util/helpers";
import partnerProfileConstant from "../../../util/partnerProfileConstant";
import ModalPopup from "../modal";
import TermsConditionsComponent from "./TermsAndConditionsComponent";

const { API_TYPES, API_STATUS, ROUTES, APPLICATION_STATUS, TIME_INTERVAL_FOR_AUTO_SAVE } = constant;
const { API_TYPES: APITYPE, TAB } = partnerProfileConstant;
const { MODAL_TYPE_DETAIL } = adminConstant;

const initialDirty = {
  logo: false,
  legalEntityName: false,
  address1: false,
  country: false,
  state: false,
  city: false,
  zip: false,
  primaryName: false,
  primaryTitle: false,
  primaryPhoneNumber: false,
  primaryEmail: false,
  secondaryName: false,
  secondaryTitle: false,
  secondaryPhoneNumber: false,
  secondaryEmail: false,
  authorizedName: false,
  authorizedTitle: false,
  estimatedOfGames: false,
  estimatedOfGamers: false,
  gameGrowth: false,
  gamersGrowth: false,
};

let saveToDraft;

const TermsConditions = () => {
  const {
    profileInfo: { data, apiType: apiTypes, status: apiStatus },
    user: { user = {} },
    partner: { apiType, status, partnerDetail = {} },
    partnerDraft: { partnerDraftDetail, status: draftApiStatus, apiType: draftApiType },
  } = useSelector( state => state );
  const dispatch = useDispatch();
  const [ isTimeInterval, setIsTimeInterval ] = useState( false );
  const [ isAutoSave, setAutoSave ] = useState( true );
  const [ allowTermsConditions, setTermsConditionsStatus ] = useState( false );
  const [ suggestion, setSuggestion ] = useState( "" );
  const [ allowTermsConditionsError, setTermsConditionsError ] = useState( "" );
  const history = useHistory();
  const { errors, isError, handleChange } = useForm( data, initialDirty );
  const { isOpened, openModal, closeModal } = useModal();

  const getRequestValue = name => data[ name ] || "";

  useEffect( () => {
    Object.keys( initialDirty ).forEach( ( key ) => {
      handleChange( key, data[ key ] || "" );
    } );
  }, [ data ] );

  const saveTermsConditionsInfo = () => {
    const selectedCountry =
        getAllCountries( getRequestValue( "country" ) )?.find(
          country => country.value === getRequestValue( "country" )
        )?.label || "";
    const selectedState =
        getStatesOfCountry( getRequestValue( "country" ) )?.find(
          state => state.value === getRequestValue( "state" )
        )?.label || "";

    const objdata = { selectedState, selectedCountry, allowTermsConditions, suggestion, username: user.username, email: user.email };

    dispatch( setTermsInfoAction( objdata ) );
  };

  const handleSubmit = () => {
    if( isError || ( !allowTermsConditions && !suggestion ) ) {
      openModal();
    }
    else {
      setAutoSave( false );
      saveTermsConditionsInfo();
    }
  };

  useEffect( () => {
    if( isTimeInterval ) {
      saveToDraft = setInterval( () => {
        saveTermsConditionsInfo();
      }, TIME_INTERVAL_FOR_AUTO_SAVE );
    }
  }, [ isTimeInterval ] );

  useEffect( () => {
    saveTermsConditionsInfo();
  }, [ allowTermsConditions ] );

  usePartnerDraft( getRequestValue, partnerDraftDetail, apiTypes, apiStatus, TAB.TERMS, !isAutoSave );

  useEffect( () => {
    if(
      ( ( draftApiType === APITYPE.ADD_TERMS_DRAFT_DETAIL ) || ( draftApiType === APITYPE.UPATE_TERMS_DRAFT_DETAIL ) ) &&
          ( draftApiStatus === API_STATUS.SUCCESS ) && !isAutoSave
    ) {
      if(
        partnerDetail?.applicationStatus ===
          APPLICATION_STATUS.AMENDMENTS_SUGGESTED ||
        partnerDetail?.applicationStatus === APPLICATION_STATUS.REJECTED
      ) {
        dispatch( updatePartnerDetailAction( partnerDraftDetail.finalData ) );
      }
      else {
        dispatch(
          uploadPartnerDetailAction(
            partnerDraftDetail?.finalData
              ? partnerDraftDetail?.finalData
              : {
                ...partnerDraftDetail.finalData,
                username: partnerDraftDetail.data.username,
                email: partnerDraftDetail.data.email,
              } )
        );
      }
    }
  }, [ draftApiType, draftApiStatus ] );

  useEffect( () => {
    if(
      status === API_STATUS.SUCCESS &&
      ( apiType === API_TYPES.UPLOAD_PARTNER_DETAIL ||
        apiType === API_TYPES.UPDATE_PARTNER_DETAIL )
    ) {
      dispatch( deletePartnerDraftDetailAction( partnerDraftDetail.meta?.entity_id ) );
      history.replace( ROUTES.PARTNER_PROFILE_PENDING );
    }

    if(
      ( partnerDetail?.suggestion || partnerDetail?.allowTermsConditions ) &&
      status !== API_STATUS.START &&
      partnerDetail?.applicationStatus !== APPLICATION_STATUS.REJECTED
    ) {
      setSuggestion( partnerDetail.suggestion );
      setTermsConditionsStatus( partnerDetail.allowTermsConditions );
    }
  }, [ status, apiType, partnerDetail ] );

  const handleCheckbox = () => {
    setTermsConditionsStatus( !allowTermsConditions );
    setTermsConditionsError( "" );
  };

  return (
    <>
      <div className='main-wrapper'>
        <div className='main-right-wrapper'>
          <div className='page-head-wrapper'>
            <h4 className='page-heading'>Terms and Conditions</h4>
            <div className='page-head-right'>
              <button
                to='#'
                className='link-btn back-btn'
                onClick={() => history.goBack()}
              >
                Back
              </button>
              <Button
                variant='primary'
                onClick={handleSubmit}
                data-testid='submit'>
                Submit
              </Button>
            </div>
          </div>
          <div className='page-inner-box profile-steps-box'>
            <Form>
              <div className='heading-with-textarea-wrapper'>
                <strong>Review Terms and Conditions</strong>
                <Form.Group>
                  <TermsConditionsComponent />
                </Form.Group>
              </div>
              <div className='custom-checkbox'>
                <label>
                  <input
                    type='checkbox'
                    checked={allowTermsConditions}
                    onChange={handleCheckbox}
                  />
                  <span>Accept all terms and conditions</span>
                  <div className='invalid-feedback d-block'>
                    {allowTermsConditionsError}
                  </div>
                </label>
              </div>
              <div className='heading-with-textarea-wrapper add-suggestion-wrapper'>
                <strong>Add Suggested Changes</strong>
                <Form.Group>
                  <Form.Control
                    data-testid='textarea'
                    as='textarea'
                    value={suggestion}
                    onChange={( e ) => {
                      setSuggestion( e.target.value );
                      setTermsConditionsError( "" );
                    }}
                    onFocus={() => setIsTimeInterval( true )}
                    onBlur={() => {
                      setIsTimeInterval( false );
                      saveTermsConditionsInfo();
                      clearInterval( saveToDraft );
                    }}
                  />
                </Form.Group>
              </div>
            </Form>
            <ModalPopup
              isOpened={isOpened}
              onClose={closeModal}
              type={MODAL_TYPE_DETAIL.ERROR_LIST.type}
              onConfirm={closeModal}
              errorList={changeErrorMessage( data, errors, allowTermsConditions, suggestion )}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsConditions;
