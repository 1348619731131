import PropTypes from "prop-types";
import React, { useState } from "react";

export const ErrorsModalBody = ( {
  errors,
} ) => {
  const [ showGeneralInfo, setShowGeneralInfo ] = useState( false );
  const [ showContactInfo, setShowContactInfo ] = useState( false );
  const [ showOtherInfo, setShowOtherInfo ] = useState( false );
  const [ showTermsConditions, setShowTermsConditions ] = useState( false );

  const validationError = errorList => (
    errorList.map( ( err, i ) => (
      <li key={i}>
        <span className="game-name">{err}</span>
      </li>
    ) )
  );

  return (
    <>
      {Boolean( errors?.generalInfo?.length ) && (
        <>
          <div className="heading-with-button">
            <h4>General Information ({errors.generalInfo.length})</h4>
            <a href="javascript:void(0)" title={!showGeneralInfo ? "show" : "hide"}
              onClick={() => setShowGeneralInfo( !showGeneralInfo )} className="link-text">{!showGeneralInfo ? "Show" : "Hide"}</a>
          </div>
          <ul className={`game-list scrollbar ${ showGeneralInfo ? "show-all" : "" }`}>
            {validationError( errors.generalInfo )}
          </ul>
        </>
      )}
      {Boolean( errors?.contactInfo?.length ) && (
        <>
          <div className="heading-with-button">
            <h4>Contact Information ({errors.contactInfo.length})</h4>
            <a href="javascript:void(0)" title={!showContactInfo ? "show" : "hide"}
              onClick={() => setShowContactInfo( !showContactInfo )} className="link-text">{!showContactInfo ? "Show" : "Hide"}</a>
          </div>
          <ul className={`game-list scrollbar ${ showContactInfo ? "show-all" : "" }`}>
            {validationError( errors.contactInfo )}
          </ul>
        </>
      )}
      {Boolean( errors?.otherInfo?.length ) && (
        <>
          <div className="heading-with-button">
            <h4>Other Information ({errors.otherInfo.length})</h4>
            <a href="javascript:void(0)" title={!showOtherInfo ? "show" : "hide"}
              onClick={() => setShowOtherInfo( !showOtherInfo )} className="link-text">{!showOtherInfo ? "Show" : "Hide"}</a>
          </div>
          <ul className={`game-list scrollbar ${ showOtherInfo ? "show-all" : "" }`}>
            {validationError( errors.otherInfo )}
          </ul>
        </>
      )}
      {Boolean( errors?.termsConditions?.length ) && (
        <>
          <div className="heading-with-button">
            <h4>Terms and Conditions ({errors.termsConditions.length})</h4>
            <a href="javascript:void(0)" title={!showTermsConditions ? "show" : "hide"}
              onClick={() => setShowTermsConditions( !showTermsConditions )} className="link-text">{!showTermsConditions ? "Show" : "Hide"}</a>
          </div>
          <ul className={`game-list scrollbar ${ showTermsConditions ? "show-all" : "" }`}>
            {validationError( errors.termsConditions )}
          </ul>
        </>
      )}
    </>
  );
};

ErrorsModalBody.propTypes = {
  errors: PropTypes.object,
};
