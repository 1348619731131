import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import NoteAdd from "@material-ui/icons/NoteAdd";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PropTypes from "prop-types";
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import offerConstant from "../../../util/offerConstant";

const { TYPE_TO_BE_EDITED } = offerConstant;

const MoreOptions = ( {
  offer,
  partnerId,
  onClickEdit,
  onClickClone,
  OnClickView,
  onClickDelete,
  marginZero,
  onClickCreateFromTemplate,
} ) => {
  const isPartnerCanEditAndDelete = ( offer.offerType === TYPE_TO_BE_EDITED.OFFER ) || ( partnerId !== offer.partnerId );

  return (
    <Dropdown className="custom-dropdown more-dropdown">
      <Dropdown.Toggle variant="success" id="" className={marginZero ? "m-0" : ""}>
        <MoreVertIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu className="five-dropdown-item" >
        <div className={ isPartnerCanEditAndDelete ? "disabled" : ""}>
          <Dropdown.Item onClick={onClickEdit}><span className="rounded"><EditIcon /></span> <span>Edit</span></Dropdown.Item>
        </div>
        <Dropdown.Item onClick={onClickClone}><FileCopyIcon /> <span>Clone</span></Dropdown.Item>
        <Dropdown.Item onClick={OnClickView}><VisibilityIcon /> <span>View</span></Dropdown.Item>
        <div className={ offer.offerType !== TYPE_TO_BE_EDITED.OFFER_TEMPLATE ? "disabled" : ""}>
          <Dropdown.Item onClick={onClickCreateFromTemplate}><NoteAdd /> <span>Create from template</span></Dropdown.Item>
        </div>
        <div className={ isPartnerCanEditAndDelete ? "disabled" : ""}>
          <Dropdown.Item onClick={onClickDelete}><DeleteIcon /> <span>Delete</span></Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

MoreOptions.propTypes = {
  offer: PropTypes.object,
  partnerId: PropTypes.string,
  onClickEdit: PropTypes.func,
  onClickClone: PropTypes.func,
  OnClickView: PropTypes.func,
  onClickDelete: PropTypes.func,
  marginZero: PropTypes.string,
  onClickCreateFromTemplate: PropTypes.func,
};

export default MoreOptions;
