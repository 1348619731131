import adminGameConstant from "../util/adminGameConstant";
import { convertDate } from "../util/helpers";

const { ADMIN_GAME_STATUS } = adminGameConstant;

export class AdminGameDetails {
  constructor( obj ) {
    this.approvedDate = obj.approved_date
      ? convertDate( obj.approved_date )
      : null;
    this.description = obj.description || "";
    this.descriptionText = obj.description_text || "";
    this.gameIcon = obj.icon_url || "";
    this.gameId = obj.game_id || "";
    this.isActive = obj.is_active || "";
    this.gameName = obj.name || "";
    this.partnerId = obj.partner_id || "";
    this.testingComments = obj.testing_comments || "";
    this.partnerComments = obj.partner_comments || "";
    this.status = obj.status || "1";
    this.partnerName = obj?.Partner?.legal_entity_name || "";
    this.statusTitle = ADMIN_GAME_STATUS.find(
      gameStatus => gameStatus.status === obj.status
    ).title;
  }
}
