import Enums from "./Enums";
import constant from "./constant";

const {
  NUMERIC_VALUE: {
    MAX_DAYS,
    MAX_HOURS,
    MAX_MINUTES,
    MAX_SECONDS,
  },
  ACHIEVEMENT_DESCRIPTION_MAX_LENGTH,
} = Enums;
const { BRANDING: { APP_SHORT_NAME } } = constant;

const urlRegex =
  /^((http(s)?:\/\/.))+?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&=]*)/;
const integerRegex = /^\d*[1-9]\d*$/;

export const validateDownloadUrl = ( values ) => {
  return values.reduce( ( acc, c ) => {
    if( !c ) {
      acc.push( "Please enter Download URL." );
    }
    else if( !urlRegex.test( c.toLowerCase() ) ) {
      acc.push( "Download URL must start with http:// or https://" );
    }
    else {
      acc.push( "" );
    }

    return acc;
  }, [] );
};

export const validateExchangeableAssets = ( values, isRequired ) => {
  return values?.reduce( ( acc, c ) => {
    const obj = {
      name: !c.name ? "Please enter name." : "",
      price: !c.price ? "Please enter a value." : "",
    };

    if( !integerRegex.test( c.price ) && c.price ) {
      obj.price = "Please enter a positive whole number for the value.";
    }

    if( !c.name && !c.price && !isRequired ) {
      obj.price = "";
      obj.name = "";
    }

    acc.push( obj );

    return acc;
  }, [] );
};

const validateForEmptyValue = ( val, msg ) => ( !val ? msg : "" );

const validateDescriptionText = ( val, required, valid ) => {
  let errorMsg;

  if( !val ) {
    errorMsg = required;
  }
  else if( val.length > ACHIEVEMENT_DESCRIPTION_MAX_LENGTH ) {
    errorMsg = valid;
  }

  return errorMsg;
};

const validateRange = ( val, msg, range ) => ( ( val && ( Number( val ) > range ) ) ? msg : "" );

export const validateAchievements = ( achievements, errors, name, isTournament ) => {
  return achievements.reduce( ( acc, c, i ) => {
    let obj = errors[ i ] || {};

    if( !name ) {
      const commonFields = {
        name: validateForEmptyValue( c.name.trim(), "Please enter achievement name." ),
        descriptionText: validateDescriptionText(
          c.descriptionText,
          "Please enter description.",
          `Description should not be greater than ${ ACHIEVEMENT_DESCRIPTION_MAX_LENGTH }.`
        ),
        event: validateForEmptyValue( c.event, "Please select event." ),
        function: validateForEmptyValue( c.function, "Please select function." ),
      };
      const offerObj = {
        ...commonFields,
        comparison: validateForEmptyValue( c.comparison, "Please select comparison." ),
        value: validateForEmptyValue( c.value, "Please enter value." ),
        days: validateRange( c.days, `Days can't be more than ${ MAX_DAYS }.`, MAX_DAYS ),
        hours: validateRange( c.hours, `Hours can't be more than ${ MAX_HOURS }.`, MAX_HOURS ),
        minutes: validateRange( c.minutes, `Minutes can't be more than ${ MAX_MINUTES }.`, MAX_MINUTES ),
        seconds: validateRange( c.seconds, `Seconds can't be more than ${ MAX_SECONDS }.`, MAX_SECONDS ),
      };
      const tournamentObj = {
        ...commonFields,
        ranking: validateForEmptyValue( c.ranking, "Please select ranking." ),
      };

      obj = isTournament ? tournamentObj : offerObj;
      acc.push( obj );

      return acc;
    }

    switch ( name ) {
      case "name":
        obj = {
          ...obj,
          name: validateForEmptyValue( c[ name ].trim(), "Please enter achievement name." ),
        };

        break;
      case "descriptionText":
        obj = {
          ...obj,
          descriptionText: validateDescriptionText(
            c[ name ],
            "Please enter description.",
            `Description should not be greater than ${ ACHIEVEMENT_DESCRIPTION_MAX_LENGTH }.`
          ),
        };

        break;
      case "event":
        obj = {
          ...obj,
          event: validateForEmptyValue( c[ name ], "Please select event." ),
        };

        break;
      case "function":
        obj = {
          ...obj,
          function: validateForEmptyValue( c[ name ], "Please select function." ),
        };

        break;
      case "comparison":
        obj = {
          ...obj,
          comparison: validateForEmptyValue( c[ name ], "Please select comparison." ),
        };

        break;
      case "value":
        obj = {
          ...obj,
          value: validateForEmptyValue( c[ name ], "Please enter value." ),
        };

        break;
      case "days":
        obj = {
          ...obj,
          days: validateRange( c[ name ], `Days can't be more than ${ MAX_DAYS }.`, MAX_DAYS ),
        };

        break;
      case "hours":
        obj = {
          ...obj,
          hours: validateRange( c[ name ], `Hours can't be more than ${ MAX_HOURS }.`, MAX_HOURS ),
        };

        break;
      case "minutes":
        obj = {
          ...obj,
          minutes: validateRange( c[ name ], `Minutes can't be more than ${ MAX_MINUTES }.`, MAX_MINUTES ),
        };

        break;
      case "seconds":
        obj = {
          ...obj,
          seconds: validateRange( c[ name ], `Seconds can't be more than ${ MAX_SECONDS }.`, MAX_SECONDS ),
        };

        break;
      case "ranking":
        obj = {
          ...obj,
          ranking: validateForEmptyValue( c[ name ], "Please select ranking." ),
        };

        break;
      default:
        obj = { ...obj };
    }

    acc.push( obj );

    return acc;
  }, [] );
};

export const validateRewards = ( values, required ) => {
  if( required ) {
    return values.reduce( ( acc, c ) => {
      if( !c.qxPoint ) {
        acc.push( `Please enter ${ APP_SHORT_NAME } point.` );
      }
      else if( !integerRegex.test( c.qxPoint ) ) {
        acc.push( `Please enter valid ${ APP_SHORT_NAME } point.` );
      }
      else {
        acc.push( "" );
      }

      return acc;
    }, [] );
  }

  return [];
};
