import PropTypes from "prop-types";
import React from "react";
import { useLocation } from "react-router-dom";
import constant from "../../../util/constant";
import CommonNavbar from "./CommonNavbar";

const { ROUTES, PARTNER_NAV_ENTITY } = constant;

const PartnerNavbar = ( { toggleDrawer } ) => {
  const location = useLocation();
  // eslint-disable-next-line prefer-destructuring
  const activeNav = location.pathname.split( "/" )[ 2 ];

  return (
    <>
      <div className="left-nav">
        <ul>
          <li>
            <CommonNavbar
              toggleDrawer={toggleDrawer}
              activeNav={activeNav}
              navTitle="Dashboard"
              activeTab={""}
              route={ROUTES.PARTNER_DASHBOARD}
              icon="icon-dashboard icon"
              type='partner'
            />
          </li>
          <li>
            <CommonNavbar
              toggleDrawer={toggleDrawer}
              activeNav={activeNav}
              navTitle="Analytics"
              activeTab={PARTNER_NAV_ENTITY.PARTNER_ANALYTICS}
              route={`${ ROUTES.PARTNER_ANALYTICS }?tab=dashboard`}
              icon="icon-analytics icon"
              type='partner'
            />
          </li>
          <li>
            <CommonNavbar
              toggleDrawer={toggleDrawer}
              activeNav={activeNav}
              navTitle="Offers"
              activeTab={PARTNER_NAV_ENTITY.OFFERS}
              route={ROUTES.PARTNER_OFFER_LIST}
              icon="icon-offer icon"
              type='partner'
            />
          </li>
          <li>
            <CommonNavbar
              toggleDrawer={toggleDrawer}
              activeNav={activeNav}
              navTitle="Tournaments"
              activeTab={PARTNER_NAV_ENTITY.TOURNAMENTS}
              route={ROUTES.PARTNER_TOURNAMENTS}
              icon="icon-tournament icon"
              type='partner'
            />
          </li>
          <li>
            <CommonNavbar
              toggleDrawer={toggleDrawer}
              activeNav={activeNav}
              navTitle="Games"
              activeTab={PARTNER_NAV_ENTITY.GAMES}
              route={ROUTES.PARTNER_GAMES}
              icon="icon-games icon"
              type='partner'
            />
          </li>
          <li>
            <CommonNavbar
              toggleDrawer={toggleDrawer}
              activeNav={activeNav}
              navTitle="Events"
              activeTab={PARTNER_NAV_ENTITY.EVENTS}
              route={ROUTES.PARTNER_EVENT_LIST}
              icon="icon-events icon"
              type='partner'
            />
          </li>
          <li>
            <CommonNavbar
              toggleDrawer={toggleDrawer}
              activeNav={activeNav}
              navTitle="Gamers"
              activeTab={PARTNER_NAV_ENTITY.GAMERS}
              route={ROUTES.PARTNER_GAMER_LIST}
              icon="icon-gamers icon"
              type='partner'
            />
          </li>
          <li>
            <CommonNavbar
              toggleDrawer={toggleDrawer}
              activeNav={activeNav}
              navTitle="Settings"
              activeTab={PARTNER_NAV_ENTITY.SETTING}
              route={ROUTES.PARTNER_SETTING}
              icon="icon-settings icon"
              type='partner'
            />
          </li>
          <li>
            <a target="_" href="https://acqyrexchange.com/gamers/#faq">
              <span className='icon-faqs icon'></span>
              <span className='nav-text'>FAQs</span>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

PartnerNavbar.propTypes = {
  toggleDrawer: PropTypes.bool,
};

export default PartnerNavbar;
