import { API_URL } from "../util/apiPaths";
import { request } from "./api.js";

export function getAdminOffers( params ) {
  return request( "GET", API_URL.ADMIN_OFFERS, params );
}

export function getAdminOfferDetails( params ) {
  return request( "GET", `${ API_URL.GET_ADMIN_OFFER_DETAIL }/${ params }` );
}

export function rejectOfferRequest( params ) {
  return request( "PUT", API_URL.REJECT_OFFER, params );
}

export function approveOfferRequest( params ) {
  return request( "PUT", API_URL.APPROVE_OFFER, params );
}

export function enableDisableOfferRequest( params ) {
  return request( "PUT", API_URL.ENABLE_DISABLE_OFFER_ADMIN, params );
}

export function addAdminOffer( params ) {
  return request( "POST", API_URL.OFFER_TEMPLATE, params );
}

export function updateAdminOffer( params ) {
  return request( "PUT", API_URL.OFFER_TEMPLATE, params );
}

export function addAdminDraftOffer( params ) {
  return request( "POST", API_URL.ADD_OFFER_TEMPLATE_DRAFT, params );
}

export function getPartnerDraftOfferTemplateDetail( params ) {
  return request( "GET", `${ API_URL.PARTNER_DRAFT }/${ params }` );
}

export function updatePartnerDraftOfferDetail( params ) {
  return request( "PUT", API_URL.PARTNER_DRAFT, params );
}

export function updateAdminDraftOfferDetail( params ) {
  return request( "PUT", API_URL.PARTNER_DRAFT, params );
}

export function updatePartnerOfferByAdmin( params ) {
  return request( "PUT", API_URL.OFFERS, params );
}

export function deleteAdminOfferTemplate( params ) {
  return request( "DELETE", `${ API_URL.OFFERS }/${ params.offerId }`, params );
}

export const adminOffersService = {
  getAdminOffers,
  getAdminOfferDetails,
  rejectOfferRequest,
  enableDisableOfferRequest,
  addAdminOffer,
  updateAdminOffer,
  addAdminDraftOffer,
  getPartnerDraftOfferTemplateDetail,
  updatePartnerDraftOfferDetail,
  updatePartnerOfferByAdmin,
  deleteAdminOfferTemplate,
};
