import { convertDate, formatNumberAsPerLocale } from "../util/helpers";

export class AdminExchangeTransactionListDetail {
  constructor( obj ) {
    this.gamerName = obj.gamerName || "N/A";
    this.name = obj.name || "N/A";
    this.gameId = obj.gameId;
    this.iconUrl = obj?.iconUrl || "";
    this.qxPoints = formatNumberAsPerLocale( obj?.qxPoints, 0 );
    this.soldAssetQuantity = formatNumberAsPerLocale( obj.soldAssetQuantity, 0 );
    this.assetName = obj.assetName || "N/A";
    this.unitPriceQxPoints = formatNumberAsPerLocale( obj.unitPriceQxPoints, 0 );
    this.transactionDate = convertDate( obj.transactionDate ) || "";
  }
}
