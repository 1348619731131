import constant from "../util/constant";
import { convertDate } from "../util/helpers";
import offerConstant from "../util/offerConstant";

const { OPT_IN_STATUS } = constant;
const { OFFER_STATUS } = offerConstant;

const handleNullUndefinedValue = ( data, name ) => data[ name ] || "";

export class PartnerGamerLinkedGamesOffers {
  constructor( obj ) {
    this.offerId = obj?.offerId ?? "";
    this.gameIcon = obj?.iconUrl ?? "";
    this.gameName = obj?.gameName ?? "";
    this.startDate = convertDate( obj?.startDate ) ?? "";
    this.endDate = convertDate( obj?.endDate ) ?? "";
    this.offerName = obj?.offerName ?? "";
    this.descriptionText = obj?.offerDescription ?? "";
    this.rewardValue = obj?.reward[ 0 ]?.reward_value ?? 0;
    this.offerProgress = obj?.offerProgress ?? "";
    this.partnerLogo = obj?.partnerLogo ?? "";
    // sortable date formats for start and end dates
    this.startDateOriginal = obj?.startDate ? convertDate( obj?.startDate, true ) : "";
    this.endDateOriginal = obj?.endDate ? convertDate( obj?.endDate, true ) : "";
    this.optInDetail = {
      optIn: obj?.optIn || OPT_IN_STATUS.NO,
      membershipId: obj?.membershipId,
      entryFee: obj?.entryFee || "",
      minParticipants: obj?.minParticipants,
      maxParticipants: obj?.maxParticipants,
    };
    this.optInType = handleNullUndefinedValue( obj, "optIn" );
    this.statusTitle = OFFER_STATUS.find(
      offer => offer.status === obj.status
    );
  }
}
