import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import analyticsConstant from "../../../../util/analyticsConstant";
import constant from "../../../../util/constant";
import { useSetUrlParams } from "../../../../util/helpers";
import AnalyticsTxnFilters from "../../../shared/analytics/analyticsTxnFilters";
import CustomSlider from "../../../shared/customSlider";
import MultiSelectCheckbox from "../../../shared/multiSelectCheckbox";

const { BRANDING: { APP_SHORT_NAME } } = constant;
const { ANALYTICS_TAB } = analyticsConstant;

const OfferRewardTxnFilter = ( {
  values,
  handleChange,
  addQuery,
  gamerNames,
  gameNames,
  offerNames,
  transactionRanges,
  isGameId,
  locationState,
  gamerPlaceRange,
  tab,
} ) => {
  const handleFilterChange = ( listName, obj ) => {
    if( obj.length > 0 ) {
      handleChange( listName, obj );
      addQuery( useSetUrlParams( listName, JSON.stringify( obj ) ), locationState, isGameId );
    }
    else {
      addQuery( useSetUrlParams( listName, "" ), locationState, isGameId );
      handleChange( listName );
    }
  };

  const multiSelectedItems = useMemo( () => [ values.gameIds, values.offerNames, values.gamerName ] );

  const onSelectFilterOptions = useCallback(
    [ obj => handleFilterChange( "gameIds", obj ),
      obj => handleFilterChange( "offerNames", obj ),
      obj => handleFilterChange( "gamerName", obj ),
    ],
    []
  );

  const clearSelectedFilter = ( params ) => {
    if( params ) {
      addQuery( useSetUrlParams( params, "" ), locationState, isGameId );
      handleChange( params, [] );
    }
  };

  const clearAllSelectedOptions = useCallback(
    params => clearSelectedFilter( params ), []
  );

  const rewardTransactionPointsHandler = ( e, newValue ) => {
    addQuery( useSetUrlParams( "minQxPoint", newValue[ 0 ] ), locationState, isGameId );
    addQuery( useSetUrlParams( "maxQxPoint", newValue[ 1 ] ), locationState, isGameId );
    handleChange( "minQxPoint", newValue[ 0 ] );
    handleChange( "maxQxPoint", newValue[ 1 ] );
  };

  const rewardTransactionPlaceHandler = ( e, newValue ) => {
    addQuery( useSetUrlParams( "minGamerPlace", newValue[ 0 ] ), locationState, isGameId );
    addQuery( useSetUrlParams( "maxGamerPlace", newValue[ 1 ] ), locationState, isGameId );
    handleChange( "minGamerPlace", newValue[ 0 ] );
    handleChange( "maxGamerPlace", newValue[ 1 ] );
  };

  return (
    <>
      <div className="filter-boxes trans-exchange-filter">
        <Row>
          <Col>
            <Form.Group className='custom-multiselect'>
              <Form.Label>Game Name</Form.Label>
              <MultiSelectCheckbox
                multiOptionsList={gameNames}
                handleSelectChange={onSelectFilterOptions[ 0 ]}
                multiSelectedItems={multiSelectedItems[ 0 ]}
                handleClearMultiSelect={() => clearAllSelectedOptions( "gameIds" )}/>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='custom-multiselect'>
              <Form.Label>{( tab === ANALYTICS_TAB.OFFER_REWARD_TXN ) ? "Offer" : "Tournament"} Name</Form.Label>
              {<MultiSelectCheckbox
                multiOptionsList={offerNames}
                handleSelectChange={onSelectFilterOptions[ 1 ]}
                multiSelectedItems={multiSelectedItems[ 1 ]}
                handleClearMultiSelect={() => clearAllSelectedOptions( "offerNames" )}/>}
            </Form.Group>
          </Col>
          <Col>
            <CustomSlider
              values={values}
              pointsHandler={rewardTransactionPointsHandler}
              handleChange={handleChange}
              min={transactionRanges.min}
              max={transactionRanges.max}
              minName={"minQxPoint"}
              maxName={"maxQxPoint"}
              label={`${ APP_SHORT_NAME } Points`}
            />
          </Col>
          { ( tab === ANALYTICS_TAB.TOURNAMENT_REWARD_TXN ) && (
            <Col>
              <CustomSlider
                values={values}
                pointsHandler={rewardTransactionPlaceHandler}
                handleChange={handleChange}
                min={gamerPlaceRange.min}
                max={gamerPlaceRange.max}
                minName={"minGamerPlace"}
                maxName={"maxGamerPlace"}
                label={"Place"}
              />
            </Col>
          ) }
          <AnalyticsTxnFilters
            tab={tab}
            values={values}
            handleChange={handleChange}
            addQuery={addQuery}
            gamerNames={gamerNames}
            isGameId={isGameId}
            locationState={locationState}
            onSelectFilterOptions={onSelectFilterOptions}
            multiSelectedItems={multiSelectedItems}
            clearAllSelectedOptions={clearAllSelectedOptions}
          />
        </Row>
      </div>
    </>
  );
};

OfferRewardTxnFilter.propTypes = {
  values: PropTypes.object,
  onSearch: PropTypes.func,
  handleChange: PropTypes.func,
  addQuery: PropTypes.func,
  gamerNames: PropTypes.array,
  gameNames: PropTypes.array,
  transactionRanges: PropTypes.object,
  offerNames: PropTypes.array,
  onCancel: PropTypes.func,
  isGamerNames: PropTypes.bool,
  isGameId: PropTypes.bool,
  locationState: PropTypes.string,
  gamerPlaceRange: PropTypes.object,
  tab: PropTypes.string,
};

export default OfferRewardTxnFilter;
