import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import PropTypes from "prop-types";
import React from "react";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";

const BackButton = ( { backLink, state = "" } ) => {
  const history = useHistory();

  return (
    <>
      <div className='main-head-wrapper'>
        <Button variant='link-secondary' className="p-0" onClick={() => {
          history.push( state
            ? {
              pathname: backLink,
              state,
            }
            : backLink );
        }}>
          <ArrowBackIosIcon />
              Back
        </Button>
      </div>
    </>
  );
};

BackButton.propTypes = {
  backLink: PropTypes.string,
  state: PropTypes.string,
};

export default BackButton;
