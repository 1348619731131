import moment from "moment";
import constant from "../util/constant";
import { convertLocalTimeInUTC } from "../util/helpers";
import offerConstant from "../util/offerConstant";
import { OfferTemplateRequest } from "./newOfferTemplateRequest";

const { REQUEST_TYPE: { OFFER, OFFER_TEMPLATE } } = offerConstant;

const { ADVERTISE_STATUS, DATE_FORMAT, TIME_FORMAT } = constant;

const advertiseDate = ( obj ) => {
  if( obj?.advertiseToGamer?.value === ADVERTISE_STATUS.SOME_SPECIFIC_DATE ) {
    return moment( obj.advertiseDate ).format( DATE_FORMAT );
  }
  else if( obj?.advertiseToGamer?.value === ADVERTISE_STATUS.NO_OF_DAYS ) {
    const date = new Date( obj.startDate );
    const newDate = new Date( date.setDate( date.getDate() + ( -obj.noOfDays ) ) );

    return moment( newDate ).format( DATE_FORMAT );
  }

  return "";
};

const dateFormat = date => moment( date ).format( DATE_FORMAT );
const timeFormat = date => moment( date ).format( TIME_FORMAT );

const formatStartOrEndDate = ( isOffer, obj, isStartDate = false ) => {
  const selectedDate = dateFormat( isStartDate ? obj.startDate : obj.endDate );
  const selectedTime = timeFormat( isStartDate ? obj.startTime : obj.endTime );
  const selectedTimeZone = isOffer
    ? new Intl.DateTimeFormat().resolvedOptions().timeZone
    : obj.timeZone;

  return convertLocalTimeInUTC( selectedDate, selectedTime, selectedTimeZone );
};

export class PartnerOfferFromScratchRequest extends OfferTemplateRequest {
  constructor( obj ) {
    super( obj );

    /* eslint-disable camelcase */
    const isOffer = ( obj.requestType === OFFER ) || ( obj.requestType === OFFER_TEMPLATE );

    this.game_id = obj.game.value;
    this.audience_id = obj.gamers?.value || "";
    this.start_date = formatStartOrEndDate( isOffer, obj, true );
    this.end_date = formatStartOrEndDate( isOffer, obj );
    this.max_reward_count_per_user = obj.offerCanBeAchievedCount || null;
    this.template_id = obj?.offerTemplate?.value || "";
    this.advertise_to_gamer = obj?.advertiseToGamer?.value || "1";
    this.advertise_date = advertiseDate( obj );
    this.timeZone = obj?.timeZone?.label ? obj?.timeZone?.value : obj.timeZone;
    /* eslint-enable camelcase */
  }
}
