import { API_URL } from "../util/apiPaths";
import { request } from "./api.js";

export function uploadLogo( data ) {
  return request( "POST", API_URL.UPLOAD_LOGO, data );
}

export const authService = {
  uploadLogo,
};
