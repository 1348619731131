import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { useModal, useForm } from "../../../hooks";
import { getAdminOfferDraftDetailAction } from "../../../store/actions/adminOfferDraft";
import {
  getAdminOfferDetailsAction,
  rejectOfferRequestAction,
  approvalOfferRequestAction,
  enableDisableOfferAction
} from "../../../store/actions/adminOffers";
import adminConstant from "../../../util/adminConstant";
import constant from "../../../util/constant";
import { getBackNavigationRoute } from "../../../util/helpers";
import offerConstant from "../../../util/offerConstant";
import BackButton from "../../shared/backButton";
import { OfferDetail } from "../../shared/offers";
import OfferViewActions from "../../shared/offers/offerViewActions";
import ModalPopup from "../modal";

const { ROUTES, API_STATUS, BUSINESS_ENTITY } = constant;
const { MODAL_TYPE_DETAIL } = adminConstant;
const { OFFER_API_TYPES, OFFER_TYPE_STATUS } = offerConstant;

const initialState = {
  rejectionReason: "",
};

const AdminOfferDetail = () => {
  const { offerId: URLString } = useParams();
  const { 0: offerId } = String( URLString ).split( "?" );
  const dispatch = useDispatch();
  const backScreens = [ "offers", "partnerOffersView", "adminGameDetail", "gamerOfferList" ];
  const { offerDetails, apiType, status, msg, adminDetail, configuration } = useSelector( state => ( {
    offerDetails: state.adminOffer.offerDetails,
    apiType: state.adminOffer.apiType,
    status: state.adminOffer.status,
    msg: state.adminOffer.message,
    adminDetail: state.adminDetail.data,
    configuration: state.adminDetail.data?.configuration || {},
  } ) );
  const history = useHistory();
  const location = useLocation();
  const [ errorMessage, setErrorMessage ] = useState( "" );
  const { isOpened, closeModal, openModal, modalType, setModalType } =
    useModal();
  const { values, handleChange } = useForm( initialState, {} );
  const offerType = new URLSearchParams( window.location.search ).get( "status" );
  const fromScreen = new URLSearchParams( window.location.search ).get( "from" );

  const handleOpenModal = ( mType ) => {
    setModalType( mType );
    openModal();
  };

  const handleCloseModal = () => {
    closeModal();
    setErrorMessage();
    handleChange( "rejectionReason", "" );
  };

  const handleInputChange = ( e ) => {
    const { name, value } = e.target;

    handleChange( name, value );

    if( errorMessage ) {
      setErrorMessage( "" );
    }
  };

  const handleReject = () => {
    const { rejectionReason } = values;
    const requestObj = {
      offerId: offerId,
    };

    if( modalType === MODAL_TYPE_DETAIL.REASON_REJECTION.type ) {
      if( rejectionReason ) {
        handleCloseModal();
        // eslint-disable-next-line camelcase
        requestObj.rejection_reason = values.rejectionReason;
        dispatch( rejectOfferRequestAction( requestObj ) );
      }
      else {
        setErrorMessage( "Please enter reason for rejection" );
      }
    }
    else if( modalType === MODAL_TYPE_DETAIL.APPROVE_OFFER.type ) {
      closeModal();
      dispatch( approvalOfferRequestAction( requestObj ) );
    }
    else if( modalType === MODAL_TYPE_DETAIL.ACTIVE_OFFER.type || modalType === MODAL_TYPE_DETAIL.INACTIVE_OFFER.type ) {
      dispatch( enableDisableOfferAction( { ...requestObj, ...{ setEnabled: modalType === MODAL_TYPE_DETAIL.ACTIVE_OFFER.type } } ) );
    }
    else {
      closeModal();
    }
  };

  useEffect( () => {
    if( offerId ) {
      if( offerType === OFFER_TYPE_STATUS.DRAFT ) {
        dispatch( getAdminOfferDraftDetailAction( offerId ) );
      }
      else {
        dispatch( getAdminOfferDetailsAction( offerId ) );
      }
    }
  }, [ offerId, msg ] );
  useEffect( () => {
    if(
      status === API_STATUS.SUCCESS &&
      apiType === OFFER_API_TYPES.REJECT_OFFER_REQUEST
    ) {
      toastr.success( "Offer rejected successfully" );
      history.goBack();
    }
    else if(
      status === API_STATUS.SUCCESS &&
      apiType === OFFER_API_TYPES.APPROVE_OFFER_REQUEST
    ) {
      toastr.success( "Offer approved successfully" );
      history.goBack();
    }
    else if(
      status === API_STATUS.SUCCESS &&
      apiType === OFFER_API_TYPES.ENABLE_DISABLE_PARTNER_OFFER
    ) {
      handleCloseModal();
      toastr.success( msg.message );
    }
  }, [ status, apiType, msg ] );

  const handleBackNavigation = () => {
    return getBackNavigationRoute( ROUTES.ADMIN_OFFER_LIST, location, backScreens );
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="main-right-wrapper">
          <BackButton
            backLink={handleBackNavigation()}
            state={fromScreen === "offers" ? "" : location.state}
          />
          <OfferViewActions
            adminDetail={adminDetail}
            offerDetails={offerDetails}
            handleOpenModal={handleOpenModal}
            businessEntity={BUSINESS_ENTITY.OFFER}
          />
          <OfferDetail
            offerDetail={offerDetails}
            configuration={configuration}
            partnerCostUsdPerQxPoint={offerDetails.partnerCostUsdPerQxPoint}
          />
        </div>
      </div>
      <ModalPopup
        isOpened={isOpened}
        onClose={handleCloseModal}
        type={modalType}
        values={values}
        onChangeInput={handleInputChange}
        errorMessage={errorMessage}
        onConfirm={handleReject}
      />
    </>
  );
};

export default AdminOfferDetail;
