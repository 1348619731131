import PropTypes from "prop-types";
import React from "react";
import constant from "../../../util/constant";
import { gamesIcon, deletedImage } from "../../../util/icons";

const { PROFILE_IMAGE_ALT_TEXT } = constant;

const ProfileImage = ( {
  profileImage,
  isGamerDetail = false,
} ) => {
  return (
    <>
      <div className={`profile-image ${ isGamerDetail ? "large" : "" }`}>
        { ( profileImage === "PII-deleted.png" )
          ? <img
            src={deletedImage}
            alt={PROFILE_IMAGE_ALT_TEXT.GAMER_IMAGE}
          />
          : <img
            src={profileImage || gamesIcon}
            alt={PROFILE_IMAGE_ALT_TEXT.GAMER_IMAGE}
            onError={ e => ( e.target.onerror = null, e.target.src = gamesIcon ) }
          /> }
      </div>
    </>
  );
};

ProfileImage.propTypes = {
  profileImage: PropTypes.string,
  isGamerDetail: PropTypes.bool,
};

export default ProfileImage;
