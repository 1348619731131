import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { forEach } from "lodash";
import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Row from "react-bootstrap/Row";
import Tooltip from "react-bootstrap/Tooltip";
import { Scrollbars } from "react-custom-scrollbars";
import { useSelector, useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import { useFilter, useAddQuery } from "../../../hooks";
import { getPartnersGameListAction, getPartnersAllGameListAction } from "../../../store/actions/partnerGame";
import adminGameConstant from "../../../util/adminGameConstant";
import constant from "../../../util/constant";
import {
  convertDate, useSetUrlParams, checkFilterApplied
} from "../../../util/helpers";
import { games } from "../../../util/icons";
import partnerGameConstant from "../../../util/partnerGameConstant";
import FilterIcon from "../../shared/FilterIcon/FilterIcon";
import { ScrollPage } from "../../shared/customScrollBar";
import MultiSelectCheckbox from "../../shared/multiSelectCheckbox";

const { ORDER_BY, ROUTES, API_STATUS, FILTERS_NAME } = constant;
const { DATE_BY, GAME_STATUS, GAME_LIST_SORT, GAME_STATUS_DROPDOWN, PARTNER_GAME_API_TYPES } = partnerGameConstant;
const { ADMIN_GAME_STATUS_TYPE } = adminGameConstant;

const PartnerGameList = () => {
  const ref = useRef( null );
  const dispatch = useDispatch();
  const history = useHistory();
  const isFilterApplied = checkFilterApplied( window.location.search, FILTERS_NAME.PARTNER_GAMES );
  const {
    partnerGame: { partnerGames, partnerAllGames, status: gameListApiStatus, apiType: gameListApiType },
  } = useSelector( state => state );
  const { setPureData, filteredData, filterData } = useFilter();
  const { addQuery } = useAddQuery();
  const [ gameIds, setGameIds ] = useState();
  const [ status, setStatus ] = useState();
  const [ viewType, setViewType ] = useState( true );
  const [ searchText, setSearchText ] = useState();
  const [ sortColumn, setSortColumn ] = useState( "name" );
  const [ sortOrder, setSortOrder ] = useState( ORDER_BY.ASC );
  const [ sortColumns, setSortColumns ] = useState( [
    { col: GAME_LIST_SORT.GAME_NAME, value: "Game Name", status: true, order: ORDER_BY.ASC },
    { col: GAME_LIST_SORT.SUBMITTED_AT, value: DATE_BY.DATE_SUBMITTED, status: false },
    { col: GAME_LIST_SORT.MODIFIED_AT, value: DATE_BY.DATE_MODIFIED, status: false },
    { col: GAME_LIST_SORT.APPROVED_AT, value: DATE_BY.DATE_APPROVED, status: false },
  ] );

  const toggleClass = ( params ) => {
    forEach( sortColumns, function( value ) {
      value.status = false;

      if( value.col === params ) {
        value.status = true;
      }
    } );
    setSortColumns( sortColumns );

    if( sortColumn !== params ) {
      setSortColumn( params );
      setSortOrder( ORDER_BY.ASC );
      addQuery( useSetUrlParams( "sortColumn", params ) );
      addQuery( useSetUrlParams( "sortOrder", ORDER_BY.ASC ) );
    }
    else {
      const newOrder = sortOrder === ORDER_BY.DESC ? ORDER_BY.ASC : ORDER_BY.DESC;

      setSortOrder( newOrder );
      addQuery( useSetUrlParams( "sortOrder", newOrder ) );
    }
  };

  const changeViewType = ( param ) => {
    if( viewType === param ) {
      setViewType( viewType );
      addQuery( useSetUrlParams( "viewType", viewType ) );
    }
    else {
      setViewType( !viewType );
      addQuery( useSetUrlParams( "viewType", !viewType ) );
    }
  };

  const searchHandle = ( e ) => {
    addQuery( useSetUrlParams( "searchKey", e.target.value ) );
    setSearchText( e.target.value );
  };

  const gameListHandle = ( obj ) => {
    if( obj.length > 0 ) {
      setGameIds( obj );
      addQuery( useSetUrlParams( "gameIds", JSON.stringify( obj ) ) );
    }
    else {
      setGameIds();
      addQuery( useSetUrlParams( "gameIds", "" ) );
    }
  };

  const statusHandle = ( obj ) => {
    if( obj.length > 0 ) {
      addQuery( useSetUrlParams( "statusIds", JSON.stringify( obj ) ) );
      setStatus( obj );
    }
    else {
      setStatus();
      addQuery( useSetUrlParams( "statusIds", "" ) );
    }
  };

  const requestParams = {
    status: status?.map( value => value.value ) || [],
    gameIds: gameIds?.map( value => value.value ) || [],
    searchKey: searchText,
    sortBy: sortColumn,
    sortOrder: sortOrder,
    searchCol: [ "name", "description" ],
    gameFilterKey: "game_id",
  };

  useEffect( () => {
    dispatch( getPartnersGameListAction( { fetchAllRecords: true } ) );
  }, [] );

  const clearHandle = ( parmas ) => {
    if( parmas === "games" ) {
      setGameIds( [] );
      addQuery( useSetUrlParams( "gameIds", "" ) );
    }

    if( parmas === "status" ) {
      setStatus( [] );
      addQuery( useSetUrlParams( "statusIds", "" ) );
    }
  };

  const multiOptionsList = useMemo( () => [ partnerAllGames, GAME_STATUS_DROPDOWN ], [ partnerAllGames ] );
  const multiSelectedItems = useMemo( () => [ gameIds, status ], [ gameIds, status ] );
  const handleSelectChange = useCallback(
    [ obj => gameListHandle( obj ),
      obj => statusHandle( obj ) ],
    []
  );
  const handleClearMultiSelect = useCallback(
    parmas => clearHandle( parmas ), []
  );

  useEffect( () => {
    if(
      gameListApiStatus === API_STATUS.SUCCESS &&
      gameListApiType === PARTNER_GAME_API_TYPES.PARTNER_GAME_LIST_REQUEST
    ) {
      dispatch( getPartnersAllGameListAction( { status: GAME_STATUS[ 0 ] } ) );
      setPureData( partnerGames?.partnerGamesList );
      updateStateFromUrl();
      filterData( requestParams );
    }

    if(
      gameListApiStatus === API_STATUS.SUCCESS &&
      gameListApiType === PARTNER_GAME_API_TYPES.DISABLE_PARTNER_GAME
    ) {
      toastr.success( "Partner game disabled sucessfully" );
    }
  }, [ gameListApiStatus, gameListApiType ] );

  useEffect( () => {
    filterData( requestParams );
  }, [ sortColumn, sortOrder, status, searchText, gameIds ] );

  function updateStateFromUrl() {
    const paramsQuery = new URLSearchParams( window.location.search );

    setSearchText( paramsQuery.get( "searchKey" ) );

    if( paramsQuery.get( "sortColumn" ) ) {
      toggleClass( paramsQuery.get( "sortColumn" ) );
      setSortColumn( paramsQuery.get( "sortColumn" ) );
    }

    if( paramsQuery.get( "gameIds" ) ) {
      setGameIds( JSON.parse( paramsQuery.get( "gameIds" ) ) );
    }

    if( paramsQuery.get( "statusIds" ) ) {
      setStatus( JSON.parse( paramsQuery.get( "statusIds" ) ) );
    }

    if( paramsQuery.get( "sortOrder" ) ) {
      setSortOrder( paramsQuery.get( "sortOrder" ) );
    }

    if( paramsQuery.get( "viewType" ) ) {
      setViewType( paramsQuery.get( "viewType" ) === "false" ? false : true );
    }
  }

  const [ showFilter, setShowFilter ] = useState( false );
  const showFilterScreen = () => setShowFilter( !showFilter );

  return (
    <>
      <div className='main-wrapper'>
        <div className='main-right-wrapper'>
          <div className='page-head-wrapper'>
            <h4 className='page-heading'>Games</h4>
            <div className='page-head-right'>
              <Form.Group className='custom-search'>
                <span className='icon-search'></span>
                <Form.Control
                  type='text'
                  placeholder='Search by Game Name...'
                  onChange={searchHandle}
                  data-testid='search'
                  value={searchText}
                  title='Search by Game Name, and Description'
                />
              </Form.Group>
              <Button
                variant='primary'
                className='small-btn'
                onClick={() =>
                  history.push(
                    ROUTES.PARTNER_ADD_EDIT_GAME.replace( ":gameId", 0 )
                  )
                }
              >
                <span className='icon-circle-plus-green icon'></span>
                Add Game
              </Button>
              <FilterIcon
                showFilter={showFilter}
                showFilterScreen={showFilterScreen}
                isFilterApplied={isFilterApplied} />
            </div>
          </div>
          <div className='filter-tabs-wrapper'>
            <div className='filter-container'>
              <form type='submit' className={`form-submit ${ !showFilter ? "" : "remove-filter" }`}>
                <Row>
                  <Col sm={4}>
                    <Form.Group className='custom-multiselect mb-0'>
                      <Form.Label>Game Name</Form.Label>
                      <MultiSelectCheckbox
                        multiOptionsList={multiOptionsList[ 0 ]}
                        handleSelectChange={handleSelectChange[ 0 ]}
                        multiSelectedItems={multiSelectedItems[ 0 ]}
                        handleClearMultiSelect={() => handleClearMultiSelect( "games" )}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group className='custom-multiselect mb-0'>
                      <Form.Label>Status</Form.Label>
                      {<MultiSelectCheckbox multiOptionsList={multiOptionsList[ 1 ]}
                        handleSelectChange={handleSelectChange[ 1 ]}
                        multiSelectedItems={multiSelectedItems[ 1 ]}
                        handleClearMultiSelect={() => handleClearMultiSelect( "status" )} />}
                    </Form.Group>
                  </Col>
                </Row>
              </form>
            </div>
            <div className='right-side'>
              {viewType && ( <Dropdown className='custom-dropdown sort-dropdown'>
                <Dropdown.Toggle variant='success' id='dropdown-basic'>
                  <span className={sortOrder === "ASC" ? "icon-sort-asc" : "icon-sort"}></span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <span className='dropdown-heading'>Sort By</span>
                  {sortColumns.map( ( data, i ) => (
                    <Dropdown.Item key={i}
                      href='#'
                      className={data.status === true ? "active" : ""}
                      onClick={() => toggleClass( data.col )}
                    >
                      <span>{data.value}</span>
                    </Dropdown.Item>
                  ) )}
                </Dropdown.Menu>
              </Dropdown> )}

              <div className='grid-buttons'>
                <span className={viewType === true ? "icon-grid-view active" : "icon-grid-view "} onClick={() => changeViewType( true )}></span>
                <span className={viewType === false ? "icon-list-view active" : "icon-list-view "} onClick={() => changeViewType( false )}></span>
              </div>
            </div>
          </div>

          {/* Add active class after games-list-table-head class to show this table head */}
          <div
            className={`games-list-table-head ${ viewType === false ? "active" : "" }`}
          >
            <div className='th pl-0'>Game Icon</div>
            <div className='table-head-body'>
              <div className='th with-sort'>
                Game Name
                <div className='sort-btn-box'>
                  <span
                    className='icon-up-arrow'
                    onClick={() => {
                      addQuery( useSetUrlParams( "sortColumn", GAME_LIST_SORT.GAME_NAME ) );
                      addQuery( useSetUrlParams( "sortOrder", ORDER_BY.ASC ) );
                      setSortColumn( GAME_LIST_SORT.GAME_NAME );
                      setSortOrder( ORDER_BY.ASC );
                      toggleClass( GAME_LIST_SORT.GAME_NAME );
                    }}
                    data-testid='game-name-sort-asc'
                  ></span>
                  <span
                    className='icon-down-arrow'
                    onClick={() => {
                      addQuery( useSetUrlParams( "sortColumn", GAME_LIST_SORT.GAME_NAME ) );
                      addQuery( useSetUrlParams( "sortOrder", ORDER_BY.DESC ) );
                      setSortColumn( GAME_LIST_SORT.GAME_NAME );
                      setSortOrder( ORDER_BY.DESC );
                      toggleClass( GAME_LIST_SORT.GAME_NAME );
                    }}
                    data-testid='game-name-sort-desc'
                  ></span>
                </div>
              </div>
              <div className='th with-sort'>Description</div>
              <div className='th with-sort'>
                Status
              </div>
              <div className='th with-sort'>
                Date Submitted
                <div className='sort-btn-box'>
                  <span
                    className='icon-up-arrow'
                    onClick={() => {
                      setSortColumn( GAME_LIST_SORT.SUBMITTED_AT );
                      setSortOrder( ORDER_BY.ASC );
                      addQuery( useSetUrlParams( "sortColumn", GAME_LIST_SORT.SUBMITTED_AT ) );
                      addQuery( useSetUrlParams( "sortOrder", ORDER_BY.ASC ) );
                      toggleClass( GAME_LIST_SORT.SUBMITTED_AT );
                    }}
                    data-testid='date-submitted-sort-asc'
                  ></span>
                  <span
                    className='icon-down-arrow'
                    onClick={() => {
                      setSortColumn( GAME_LIST_SORT.SUBMITTED_AT );
                      setSortOrder( ORDER_BY.DESC );
                      addQuery( useSetUrlParams( "sortColumn", GAME_LIST_SORT.SUBMITTED_AT ) );
                      addQuery( useSetUrlParams( "sortOrder", ORDER_BY.DESC ) );
                      toggleClass( GAME_LIST_SORT.SUBMITTED_AT );
                    }}
                    data-testid='date-submitted-sort-desc'
                  ></span>
                </div>
              </div>
              <div className='th with-sort'>
                Date Modified
                <div className='sort-btn-box'>
                  <span
                    className='icon-up-arrow'
                    onClick={() => {
                      setSortColumn( GAME_LIST_SORT.MODIFIED_AT );
                      setSortOrder( ORDER_BY.ASC );
                      addQuery( useSetUrlParams( "sortColumn", GAME_LIST_SORT.MODIFIED_AT ) );
                      addQuery( useSetUrlParams( "sortOrder", ORDER_BY.ASC ) );
                      toggleClass( GAME_LIST_SORT.MODIFIED_AT );
                    }}
                    data-testid='date-modified-sort-asc'
                  ></span>
                  <span
                    className='icon-down-arrow'
                    onClick={() => {
                      setSortColumn( GAME_LIST_SORT.MODIFIED_AT );
                      setSortOrder( ORDER_BY.DESC );
                      addQuery( useSetUrlParams( "sortColumn", GAME_LIST_SORT.MODIFIED_AT ) );
                      addQuery( useSetUrlParams( "sortOrder", ORDER_BY.DESC ) );
                      toggleClass( GAME_LIST_SORT.MODIFIED_AT );
                    }}
                    data-testid='date-modified-sort-desc'
                  ></span>
                </div>
              </div>
              <div className='th with-sort'>
                Date Approved
                <div className='sort-btn-box'>
                  <span
                    className='icon-up-arrow'
                    onClick={() => {
                      setSortColumn( GAME_LIST_SORT.APPROVED_AT );
                      setSortOrder( ORDER_BY.ASC );
                      addQuery( useSetUrlParams( "sortColumn", GAME_LIST_SORT.APPROVED_AT ) );
                      addQuery( useSetUrlParams( "sortOrder", ORDER_BY.ASC ) );
                      toggleClass( GAME_LIST_SORT.APPROVED_AT );
                    }}
                    data-testid='date-approved-sort-asc'
                  ></span>
                  <span
                    className='icon-down-arrow'
                    onClick={() => {
                      setSortColumn( GAME_LIST_SORT.APPROVED_AT );
                      setSortOrder( ORDER_BY.DESC );
                      addQuery( useSetUrlParams( "sortColumn", GAME_LIST_SORT.APPROVED_AT ) );
                      addQuery( useSetUrlParams( "sortOrder", ORDER_BY.DESC ) );
                      toggleClass( GAME_LIST_SORT.APPROVED_AT );
                    }}
                    data-testid='date-approved-sort-desc'
                  ></span>
                </div>
              </div>
              <div className='th'>Action</div>
            </div>
          </div>
          {/* Add (games-list-wrapper) class after games-grid-wrapper class for list view */}
          <Scrollbars renderThumbVertical={ScrollPage} className={`track-horizontal ${ viewType === false ? "track-horizontal-list" : "" }`}>
            {filteredData?.length > 0
              ? (

                <div className={`games-grid-wrapper no-scrollbar ${ viewType === false ? "games-list-wrapper" : "" }`}
                >

                  {filteredData?.map( ( data, i ) => (
                    <div className="col-games-grid-box" key={i}>
                      <div className="games-grid-box">
                        <div className="games-image-box">
                          <img src={data.icon_url ?? games} alt="card" />
                        </div>
                        <div className="games-grid-box-body" ref={ref}>
                          <strong>{data.name}</strong>
                          <div className="custom-dropdown more-dropdown games-anchor cursor-pointer" onClick={() =>
                            history.push(
                              ROUTES.PARTNER_ADD_EDIT_GAME.replace(
                                ":gameId",
                                data.game_id
                              )
                            )
                          }>
                            {data.status !== ADMIN_GAME_STATUS_TYPE.REJECTED
                          && data.status !== ADMIN_GAME_STATUS_TYPE.DISABLED
                          && data.status !== ADMIN_GAME_STATUS_TYPE.READY_FOR_TESTING
                              ? (
                                <>
                                  <span className="rounded"><EditIcon /></span>
                                </>
                              )
                              : <VisibilityIcon />
                            }
                          </div>
                          <span className="games-text">{GAME_STATUS[ data.status ]}</span>
                          <span className="games-text date-text">
                            <span className="grid-only">{viewType ? DATE_BY.DATE_SUBMITTED : ""}</span>
                            {data.submitted_date ? convertDate( data.submitted_date ) : "N/A"}</span>
                          <span className="games-text date-text mt-0"><span className="grid-only">
                            {viewType ? DATE_BY.DATE_MODIFIED : ""}</span>
                          {data.updatedAt ? convertDate( data.updatedAt ) : "N/A"}</span>
                          <span className="games-text date-text mt-0"><span className="grid-only">
                            {viewType ? DATE_BY.DATE_APPROVED : ""}</span>
                          {data.approved_date ? convertDate( data.approved_date ) : "N/A"}</span>
                          <OverlayTrigger
                            container={ref}
                            placement='auto'
                            overlay={<Tooltip className="event-tooltip">{data.description_text}</Tooltip>}
                          >
                            <span className='games-text des-text'
                            >{data.description_text}</span>
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div>
                  ) )}
                </div> )
              : (
                <div className='no-record' data-testid='no-record-found'>No Game Found</div>
              )}
          </Scrollbars>
        </div>
      </div>
    </>
  );
};

export default PartnerGameList;
