import constant from "../../util/constant";
import {
  NOTIFICATIONS_FAILURE,
  NOTIFICATIONS_API_REQUEST,
  NOTIFICATIONS_SUCCESS,
  READ_NOTIFICATIONS_SUCCESS
} from "../actionTypes";

const { API_STATUS } = constant;
const DEFAULT_STATE = {
  loading: false,
  notification: { notifications: [], meta: "" },
  status: "",
  apiType: "",
};

const notification = ( state = DEFAULT_STATE, action = "" ) => {
  switch ( action.type ) {
    case NOTIFICATIONS_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
      };
    case NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notification: {
          notifications: action.payload.dataList,
          meta: action.payload.meta,
        },
        status: API_STATUS.SUCCESS,
      };
    case NOTIFICATIONS_FAILURE:
      return {
        ...state,
        notification: {
          notifications: [],
          meta: "",
        },
        status: API_STATUS.FAILURE,
      };
    case READ_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
      };
    default:
      return state;
  }
};

export default notification;
