import React, { useMemo, useEffect } from "react";
import { useForm } from "../../../hooks";
import adminTransactionConstant from "../../../util/adminTransactionConstant";
import { redeemTableConfigs, exchangeTableConfigs } from "../../../util/tableConstant";
import ExchangeList from "./exchangeList";
import RedeemList from "./redeemList";

const { TABS } = adminTransactionConstant;

const initialState = {
  tab: "",
};

const TransactionList = () => {
  const queryParamsOriginal = new URLSearchParams( window.location.search );
  const activeTab = queryParamsOriginal.get( "tab" );
  const { values, handleChange } = useForm( initialState, { } );

  useEffect( () => {
    handleChange( "tab", activeTab );
  }, [ activeTab ] );

  const memoizedTableConfig = useMemo( () => {
    return [ exchangeTableConfigs, redeemTableConfigs ];
  }, [] );

  return (
    <>
      <div className="main-right-wrapper">
        {values.tab === TABS.EXCHANGE && <ExchangeList
          memoizedTableConfig={memoizedTableConfig[ 0 ]}
          tab={values.tab}
        />
        }
        {values.tab === TABS.REDEEM && <RedeemList
          memoizedTableConfig={memoizedTableConfig[ 1 ]}
          tab={values.tab}
        />
        }
      </div>
    </>
  );
};

export default TransactionList;
