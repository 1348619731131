import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getGamerAssetsAction } from "../../../store/actions/adminGamer";
import AssetList from "../asset/assetList";

const GamerAssets = ( { gameId, gamerId } ) => {
  const dispatch = useDispatch();
  const { gamerAssetList } = useSelector( state => ( {
    gamerAssetList: state.adminGamer.gamerAssetList,
  } ) );

  useEffect( () => {
    if( gameId ) {
      dispatch( getGamerAssetsAction( { gamerId: gamerId, gameId: gameId } ) );
    }
  }, [ gameId ] );

  return (
    <>
      <AssetList assets={gamerAssetList?.assets} totalQxPoints={gamerAssetList?.totalQxPoints} />
    </>
  );
};

GamerAssets.propTypes = {
  gameId: PropTypes.string,
  gamerId: PropTypes.string,
};


export default GamerAssets;
