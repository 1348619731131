import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useSetUrlParams } from "../../../../util/helpers";
import CustomSlider from "../../../shared/customSlider";
import MultiSelectCheckbox from "../../../shared/multiSelectCheckbox";

const LinkedGamersFilters = ( {
  values,
  handleChange,
  addQuery,
  gamerNames,
  showFilter,
  locationState,
  gamerPlans,
  linkedGamerGamesRange,
  handleFilterChange,
  clearSelectedFilter,
} ) => {
  const clearAllSelectedOptions = useCallback(
    params => clearSelectedFilter( params, addQuery, handleChange, locationState ), []
  );

  const handleSelect = ( filterName, obj ) => {
    handleFilterChange( filterName, obj, handleChange, addQuery, locationState );
  };

  const onSelectFilterItems = useCallback(
    [ obj => handleSelect( "gamerNames", obj ),
      obj => handleSelect( "gamerPlans", obj ),
    ],
    []
  );

  const handleLinkedGames = ( e, newValue ) => {
    addQuery( useSetUrlParams( "minLinkedGames", newValue[ 0 ] ) );
    addQuery( useSetUrlParams( "maxLinkedGames", newValue[ 1 ] ) );
    handleChange( "minLinkedGames", newValue[ 0 ] );
    handleChange( "maxLinkedGames", newValue[ 1 ] );
  };

  const multiSelectedItems = useMemo( () => [ values.gamerNames, values.gamerPlans ] );

  return (
    <>
      <div className="filter-boxes trans-exchange-filter">
        <div className={`filter-boxes mr-0 ${ !showFilter ? "" : "remove-filter" }`}>
          <Row>
            <Col md={4}>
              <Form.Group className='custom-multiselect'>
                <Form.Label>Gamer Name</Form.Label>
                {<MultiSelectCheckbox
                  multiOptionsList={gamerNames}
                  handleSelectChange={onSelectFilterItems[ 0 ]}
                  multiSelectedItems={multiSelectedItems[ 0 ]}
                  handleClearMultiSelect={() => clearAllSelectedOptions( "gamerNames" )} />}
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className='custom-multiselect mb-0'>
                <CustomSlider
                  values={values}
                  pointsHandler={handleLinkedGames}
                  handleChange={handleChange}
                  min={linkedGamerGamesRange.min}
                  max={linkedGamerGamesRange.max}
                  minName='minLinkedGames'
                  maxName='maxLinkedGames'
                  label='Linked Games'
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className='custom-multiselect'>
                <Form.Label>Gamer Plan</Form.Label>
                {<MultiSelectCheckbox
                  multiOptionsList={gamerPlans}
                  handleSelectChange={onSelectFilterItems[ 1 ]}
                  multiSelectedItems={multiSelectedItems[ 1 ]}
                  handleClearMultiSelect={() => clearAllSelectedOptions( "gamerPlans" )} />}
              </Form.Group>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

LinkedGamersFilters.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  addQuery: PropTypes.func,
  gamerNames: PropTypes.array,
  locationState: PropTypes.string,
  showFilter: PropTypes.string,
  gamerPlans: PropTypes.array,
  handleFilterChange: PropTypes.func,
  clearSelectedFilter: PropTypes.func,
  linkedGamerGamesRange: PropTypes.object,
};

export default LinkedGamersFilters;
