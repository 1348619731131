import PropTypes from "prop-types";
import React from "react";
import Nav from "react-bootstrap/Nav";
import { useSetUrlParams } from "../../../util/helpers";

const LinkedGameDetails = ( {
  tab,
  props,
  gameId,
  gamerId,
  addQuery,
  gameName,
  setTabsData,
  locationState } ) => {
  const setParams = ( value ) => {
    addQuery( useSetUrlParams( "type", value ), locationState );
  };

  return (
    <>
      <div className='page-head-wrapper'>
        <h4 className='page-heading' data-testid='gameNameTitle'>
          {gameName}
        </h4>
      </div>
      <div className='page-inner-box profile-steps-box message-main-box'>
        <>
          <Nav
            variant='tabs'
            defaultActiveKey='link-1'
          >
            { tab.map( ( tabData, index ) => (
              <Nav.Item key={index}>
                <Nav.Link
                  className='pt-0'
                  active={tabData.value}
                  onClick={() => {
                    setTabsData( tabData.tabKey );
                    setParams( tabData.tabKey );
                  } }
                  data-testid={tabData.testId}
                >
                  {tabData.tabName}
                </Nav.Link>
              </Nav.Item>
            ) )}
          </Nav>
        </>
        { tab.map( ( tabData, index ) => (
          tabData.value && <tabData.component
            key={index}
            props={props}
            viewType={true}
            gameId={gameId}
            gamerId={gamerId}
          />
        ) )}
      </div>
    </>
  );
};

LinkedGameDetails.propTypes = {
  tab: PropTypes.array,
  props: PropTypes.object,
  gameId: PropTypes.string,
  addQuery: PropTypes.func,
  gamerId: PropTypes.string,
  gameName: PropTypes.string,
  setTabsData: PropTypes.func,
  locationState: PropTypes.string,
};

export default LinkedGameDetails;
