import VisibilityIcon from "@material-ui/icons/Visibility";
import { orderBy } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { useAddQuery, useForm, useFilter, getUrlData } from "../../../hooks";
import { clearAnalyticsGameDetail } from "../../../store/actions/partnerAnalytics";
import { getPartnersGameListAction } from "../../../store/actions/partnerGame";
import analyticsConstant from "../../../util/analyticsConstant";
import constant from "../../../util/constant";
import { formatNumberAsPerLocale } from "../../../util/helpers";
import { games } from "../../../util/icons";
import partnerGameConstant from "../../../util/partnerGameConstant";
import { ScrollPage } from "../../shared/customScrollBar";
import AnalyticsGamesFilter from "./analyticsGamesFilter";
import RewardPointsCostTooltip from "./rewardPointsCostTooltip";

const { ROUTES, API_STATUS, VIEW, BRANDING: { APP_SHORT_NAME } } = constant;
const { PARTNER_GAME_API_TYPES } = partnerGameConstant;
const { ANALYTICS_TAB: {
  GAMERS,
  EXCHANGE_TRANSACTION,
  OFFER_REWARD_TXN,
  TOURNAMENT_REWARD_TXN,
} } = analyticsConstant;


const GameList = ( { viewType, sortBy, sortOrder, searchKey, setPreviousData,
  usdPerQxPoint, partnerCostUsdPerQxPoint } ) => {
  const ref = useRef( null );
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    partnerGame: { partnerGames, status: gameListApiStatus, apiType: gameListApiType },
  } = useSelector( state => state );
  const { setPureData, filteredData, filterData } = useFilter();
  const { addQuery } = useAddQuery();
  const { values, handleChange } = useForm(
    {
      searchKey: searchKey,
      sortBy: sortBy,
      sortOrder: sortOrder,
      searchCol: [ "name" ],
    },
    {}
  );
  const requestParams = Object.assign(
    {
      sortOrder: values.sortOrder,
      sortBy: values.sortBy,
      searchKey: values.searchKey,
      searchCol: values.searchCol,
    }
  );

  useEffect( () => {
    dispatch( getPartnersGameListAction( { games: "approved", fetchAllRecords: true }, true ) );
  }, [] );

  useEffect( () => {
    if(
      gameListApiStatus === API_STATUS.SUCCESS &&
      gameListApiType === PARTNER_GAME_API_TYPES.PARTNER_GAME_LIST_REQUEST
    ) {
      const sortGameList = orderBy( partnerGames?.partnerGamesList, [ "name" ], [ "asc" ] );

      setPureData( sortGameList );
      getUrlData( handleChange );
      setPreviousData( true );
    }
  }, [ gameListApiStatus, gameListApiType ] );

  useEffect( () => {
    filterData( requestParams );
  }, [ values.sortOrder, values.sortBy, values.searchKey ] );


  useEffect( () => {
    handleChange( "sortBy", sortBy );
    handleChange( "sortOrder", sortOrder );
    handleChange( "searchKey", searchKey );
  }, [ sortBy, sortOrder, searchKey ] );

  const viewGameStatisticsDetail = ( e, gameId, tab ) => {
    e.stopPropagation();
    dispatch( clearAnalyticsGameDetail() );

    const route = `${
      ROUTES.ANALYTIC_VIEW_GAME.replace( ":gameId", gameId )
    }?tab=${ tab }`;

    navigateTo( route );
  };

  const getPremiumGamerPercentage = ( gameDetail ) => {
    let premiumGamers = 0;

    if( gameDetail?.LinkedGamers && ( gameDetail?.LinkedGamers !== 0 ) ) {
      premiumGamers = gameDetail.PremiumGamers / gameDetail.LinkedGamers;
    }

    return `${ parseFloat( premiumGamers * 100 ).toFixed( 2 ) }%`;
  };

  const viewLinkedGameDetail = ( gameId ) => {
    const route = `${ ROUTES.ANALYTIC_VIEW_GAME.replace( ":gameId", gameId ) }?tab=games`;

    navigateTo( route );
  };

  const navigateTo = ( route ) => {
    history.push( {
      pathname: route,
      state: [
        { from: "analtyicsGames", path: location.pathname, state: location.search },
      ],
    } );
  };

  return (
    <>
      {/* Add active class after games-list-table-head class to show this table head */}
      <div
        className={`games-list-table-head analytics-gamer-table ${ viewType === VIEW.LIST ? "active" : "" }`}
      >
        <AnalyticsGamesFilter
          addQuery={addQuery}
          handleChange={handleChange}
        />
      </div>
      {/* Add (games-list-wrapper) class after games-grid-wrapper class for list view */}
      <Scrollbars renderThumbVertical={ScrollPage} className={`track-horizontal track-horizontal-full ${ viewType === VIEW.LIST ? "track-horizontal-list-large" : "" }`}>
        { filteredData?.length > 0
          ? (

            <div className={`analytics-wrapper games-grid-wrapper no-scrollbar ${ viewType === VIEW.LIST ? "games-list-wrapper" : "" }`}
            >

              { filteredData.map( ( data, i ) => (
                <div className="col-games-grid-box" key={i}>
                  <div className="games-grid-box cursor-pointer" onClick={() => viewLinkedGameDetail( data.game_id )}>
                    <div className="games-card-top">
                      <div className="games-image-box">
                        <img src={data.icon_url ?? games} alt="card"/>
                      </div>
                      <div className="card-top-content">
                        <div className="games-grid-box-body" ref={ref}>
                          <strong>{data.name}</strong>
                        </div>
                        <div className="content">
                          <strong className='d-block'>{data?.LinkedGamers}</strong>
                          <a className='link'
                            onClick={e => viewGameStatisticsDetail( e, data.game_id, GAMERS )}
                          >Linked Gamers</a>
                        </div>
                        <div className="content">
                          <strong>{data?.PremiumGamers}</strong>
                          <div className='loader-label'>
                            <span>Premium Gamers</span>
                            <span className='bold'>
                              {getPremiumGamerPercentage( data )}
                            </span>
                          </div>
                          <div className='progress-bar'>
                            <span
                              className='progress'
                              style={{ width: getPremiumGamerPercentage( data ) }}></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-bottom-content">
                      <div className='bottom-list'>Rewarded Value (Offers):
                        <span className='text-green'
                          onClick={e => viewGameStatisticsDetail( e, data.game_id, OFFER_REWARD_TXN )}>
                          <strong>{formatNumberAsPerLocale( data?.OfferRewardValue )}</strong> { APP_SHORT_NAME } Points
                          <RewardPointsCostTooltip
                            rewardPoints={data?.OfferRewardValue}
                            usdPerQxPoint={usdPerQxPoint}
                            partnerCostUsdPerQxPoint={partnerCostUsdPerQxPoint}
                          />
                        </span>
                      </div>
                      <div className='bottom-list'>Rewarded Value (Tournaments):
                        <span className='text-green'
                          onClick={e => viewGameStatisticsDetail( e, data.game_id, TOURNAMENT_REWARD_TXN )}>
                          <strong>{formatNumberAsPerLocale( data?.TournamentRewardValue )}</strong>  { APP_SHORT_NAME } Points
                          <RewardPointsCostTooltip
                            rewardPoints={data?.TournamentRewardValue}
                            usdPerQxPoint={usdPerQxPoint}
                            partnerCostUsdPerQxPoint={partnerCostUsdPerQxPoint}
                          />
                        </span>
                      </div>
                      <div className='bottom-list'>Assets Exchanged Value:
                        <span className='text-green'
                          onClick={e => viewGameStatisticsDetail( e, data.game_id, EXCHANGE_TRANSACTION )}>
                          <strong>{formatNumberAsPerLocale( data?.AssetExchangedValue )}</strong>  { APP_SHORT_NAME } Points
                          <RewardPointsCostTooltip
                            rewardPoints={data?.AssetExchangedValue}
                            usdPerQxPoint={usdPerQxPoint}
                            partnerCostUsdPerQxPoint={partnerCostUsdPerQxPoint}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="analytics-gamer-table analytics-gamer-body cursor-default">
                    <div className="td">
                      <div className="games-image-box">
                        <img src={data.icon_url ?? games} alt="card"/>
                      </div>
                    </div>
                    <div className="td">
                      <strong>{data.name}</strong>
                    </div>
                    <div className="td">
                      <a className='link cursor-pointer'
                        onClick={e => viewGameStatisticsDetail( e, data.game_id, GAMERS )}>
                        {data?.LinkedGamers}
                      </a>
                    </div>
                    <div className="td">{data?.PremiumGamers}</div>
                    <div className="td">
                      <span className='text-green cursor-pointer'
                        onClick={e => viewGameStatisticsDetail( e, data.game_id, OFFER_REWARD_TXN )}>
                        <strong>
                          {formatNumberAsPerLocale( data?.OfferRewardValue )}
                        </strong>
                        <RewardPointsCostTooltip
                          rewardPoints={data?.OfferRewardValue}
                          usdPerQxPoint={usdPerQxPoint}
                          partnerCostUsdPerQxPoint={partnerCostUsdPerQxPoint}
                        />
                      </span>
                    </div>
                    <div className="td">
                      <span className='text-green cursor-pointer'
                        onClick={e => viewGameStatisticsDetail( e, data.game_id, TOURNAMENT_REWARD_TXN )}>
                        <strong>
                          {formatNumberAsPerLocale( data?.TournamentRewardValue )}
                        </strong>
                        <RewardPointsCostTooltip
                          rewardPoints={data?.TournamentRewardValue}
                          usdPerQxPoint={usdPerQxPoint}
                          partnerCostUsdPerQxPoint={partnerCostUsdPerQxPoint}
                        />
                      </span>
                    </div>
                    <div className="td">
                      <span className='text-green cursor-pointer'
                        onClick={e => viewGameStatisticsDetail( e, data.game_id, EXCHANGE_TRANSACTION )}>
                        <strong>
                          {formatNumberAsPerLocale( data?.AssetExchangedValue )}
                        </strong>
                        <RewardPointsCostTooltip
                          rewardPoints={data?.AssetExchangedValue}
                          usdPerQxPoint={usdPerQxPoint}
                          partnerCostUsdPerQxPoint={partnerCostUsdPerQxPoint}
                        />
                      </span>
                    </div>
                    <div className="td">
                      <div className='icon cursor-pointer'
                        onClick={() => viewLinkedGameDetail( data.game_id )}
                      >
                        <VisibilityIcon />
                      </div>
                    </div>
                  </div>
                </div>
              ) )}
            </div> )
          : (
            <div className='no-record' data-testid='no-record-found'>No Game Found</div>
          )}
      </Scrollbars>
    </>
  );
};

GameList.propTypes = {
  viewType: PropTypes.string,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
  searchKey: PropTypes.string,
  setPreviousData: PropTypes.func,
  usdPerQxPoint: PropTypes.number,
  partnerCostUsdPerQxPoint: PropTypes.number,
};

export default GameList;
