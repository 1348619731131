import PropTypes from "prop-types";
import React from "react";
import { Row } from "react-bootstrap";

import GameAsset from "../asset/gameAsset";

const GameAssets = ( {
  exchangeableAssets,
  usdPerQxPoint,
  partnerCostUsdPerQxPoint,
} ) => {
  return (
    <>
      {Boolean( exchangeableAssets?.length ) && (
        <>
          <div className="content-title">Exchangeable Assets</div>
          {exchangeableAssets?.map( asset => (
            <Row key={asset.name}>
              <GameAsset
                asset={asset}
                usdPerQxPoint={usdPerQxPoint}
                partnerCostUsdPerQxPoint={ partnerCostUsdPerQxPoint }
              />
            </Row>
          ) )}
        </>
      )}
    </>
  );
};

GameAssets.propTypes = {
  exchangeableAssets: PropTypes.array,
  usdPerQxPoint: PropTypes.number,
  partnerCostUsdPerQxPoint: PropTypes.number,
};

export default GameAssets;
