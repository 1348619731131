import { toastr } from "react-redux-toastr";
import { getAdminLookup } from "../../services/adminServices";
import constant from "../../util/constant";
import {
  START_LOADER,
  STOP_LOADER,
  ADMIN_LOOKUP_API_REQUEST,
  ADMIN_LOOKUP_API_END,
  GET_ADMIN_LOOKUP_SUCCESS,
  GET_ADMIN_LOOKUP_FAILURE
} from "../actionTypes";

const { ERROR_MESSAGES, API_TYPES } = constant;

export const getAdminLookupAction = ( param, offerPartnerId ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_LOOKUP_API_REQUEST,
      payload: API_TYPES.GET_PARTNER_LOOKUP,
    } );

    const response = await getAdminLookup( param );
    const paramArr = param.entities.split( "," );

    // Filter the approved games while editing offer of partner.
    if( offerPartnerId ) {
      response.data.ApprovedGame = response?.data?.ApprovedGame?.filter( game => game.partner_id === offerPartnerId );
    }

    const lookup = {};

    paramArr.forEach( ( key ) => {
      lookup[ key ] = response.data[ key ]?.map( ( item ) => {
        const keys = Object.keys( item );
        const nameIndex = keys.join( " " ).replace( /event_name/g, "name" )
          .split( " " )
          .indexOf( "name" );

        return {
          label: item[ keys[ nameIndex ] ],
          value: nameIndex === 0 ? item[ keys[ 1 ] ] : item[ keys[ 0 ] ],
        };
      } ) || [];
    } );
    dispatch( {
      type: GET_ADMIN_LOOKUP_SUCCESS,
      payload: lookup,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GET_ADMIN_LOOKUP_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_LOOKUP_API_END,
    } );
  }
};


