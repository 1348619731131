const partnerProfileConstant = {
  API_TYPES: {
    ADD_PARTNER_DRAFT_DETAIL: "ADD_PARTNER_DRAFT_DETAIL",
    UPDATE_PARTNER_DRAFT_DETAIL: "UPDATE_PARTNER_DRAFT_DETAIL",
    SET_GENERAL_INFO: "SET_GENERAL_INFO",
    SET_CONTACT_INFO: "SET_CONTACT_INFO",
    SET_OTHER_INFO: "SET_OTHER_INFO",
    SET_TERMS_INFO: "SET_TERMS_INFO",
    ADD_CONTACT_DRAFT_DETAIL: "ADD_CONTACT_DRAFT_DETAIL",
    UPDATE_CONTACT_DRAFT_DETAIL: "UPDATE_CONTACT_DRAFT_DETAIL",
    ADD_OTHER_DRAFT_DETAIL: "ADD_OTHER_DRAFT_DETAIL",
    UPDATE_OTHER_DRAFT_DETAIL: "UPDATE_OTHER_DRAFT_DETAIL",
    ADD_TERMS_DRAFT_DETAIL: "ADD_TERMS_DRAFT_DETAIL",
    UPATE_TERMS_DRAFT_DETAIL: "UPATE_TERMS_DRAFT_DETAIL",
  },
  TAB: {
    GENERAL_INFO: "GENERAL_INFO",
    CONTACT_INFO: "CONTACT_INFO",
    OTHER_INFO: "OTHER_INFO",
    TERMS: "TERMS",
  },
  SETTING_TAB: {
    EDIT_PROFILE: "EDIT_PROFILE",
    PASSWORD_RESET: "PASSWORD_RESET",
  },
  GENERAL_INFO_KEYS: [ "logo", "legalEntityName" ],
  CONTACT_INFO_KEYS: [
    "address1",
    "country",
    "state",
    "city",
    "zip",
    "primaryName",
    "primaryTitle",
    "primaryPhoneNumber",
    "primaryEmail",
    "secondaryName",
    "secondaryTitle",
    "secondaryPhoneNumber",
    "secondaryEmail",
    "authorizedName",
    "authorizedTitle",
  ],
  OTHER_INFO_KEYS: [ "estimatedOfGames", "estimatedOfGamers", "gameGrowth", "gamersGrowth" ],
};

export default partnerProfileConstant;
