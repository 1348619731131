import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useHistory, useParams } from "react-router-dom";
import { addEventAction, getPartnerEventDetailAction, updatePartnerEventAction } from "../../../store/actions/partnerEvent";
import constant from "../../../util/constant";
import eventConstant from "../../../util/eventConstant";
import { CreateEvent } from "../../shared/events";

const { API_STATUS } = constant;
const { EVENT_API_TYPES } = eventConstant;

const AddEditEvent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { apiType, status, partnerEventDetail } = useSelector( state => ( {
    apiType: state.partnerEvent.apiType,
    status: state.partnerEvent.status,
    partnerEventDetail: state.partnerEvent.partnerEventDetail,
  } ) );
  const { eventId } = useParams();
  const [ isEditEvent, setEditEventStatus ] = useState( false );
  const [ eventDetail, setEventDetail ] = useState();

  useEffect( () => {
    if( eventId !== "0" ) {
      setEditEventStatus( true );
      dispatch( getPartnerEventDetailAction( eventId ) );
    }
  }, [ eventId ] );

  useEffect( () => {
    if(
      status === API_STATUS.SUCCESS &&
      ( apiType === EVENT_API_TYPES.ADD_EVENT || apiType === EVENT_API_TYPES.UPDATE_PARTNER_EVENT )
    ) {
      toastr.success( EVENT_API_TYPES.ADD_EVENT === apiType ? "Event added successfully" : "Event updated successfully" );
      history.goBack();
    }
    else if( status === API_STATUS.SUCCESS &&
      apiType === EVENT_API_TYPES.GET_PARTNER_EVENT_DETAIL ) {
      setEventDetail( partnerEventDetail );
    }
    else if( status === API_STATUS.FAILURE &&
      apiType === EVENT_API_TYPES.GET_PARTNER_EVENT_DETAIL ) {
      history.goBack();
    }
  }, [ apiType, status ] );

  const handleOnSave = ( values ) => {
    const requestObj = {
      // eslint-disable-next-line camelcase
      event_name: values.eventName,
      description: values.description,
      unit: values.units.value,
    };

    if( isEditEvent ) {
      // eslint-disable-next-line camelcase
      requestObj.event_id = eventDetail.eventId;
      dispatch( updatePartnerEventAction( requestObj ) );
    }
    else {
      dispatch( addEventAction( requestObj ) );
    }
  };

  return <CreateEvent isEditEvent={isEditEvent} eventDetail={eventDetail} onSave={handleOnSave} />;
};

export default AddEditEvent;
