import PropTypes from "prop-types";
import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const Markdown = ( { text } ) => {
  return (
    <>
      <ReactMarkdown remarkPlugins={[ remarkGfm ]}>{text}</ReactMarkdown>
    </>
  );
};

Markdown.propTypes = {
  text: PropTypes.string,
};

export default Markdown;
