import { AdminOfferDetails } from "../entities/adminOfferDetails";
import constant from "../util/constant";
import eventConstant from "../util/eventConstant";
import { convertDate, convertHtmlToEditorHtml, convertActualTime, convertMarkDownToDraft } from "../util/helpers";
import offerConstant from "../util/offerConstant";

const { TYPE_TO_BE_EDITED, FUNCTIONS, COMPARISONS, RANKING_OBJECTIVE } = offerConstant;
const { UNITS } = eventConstant;
const { OPT_IN_STATUS } = constant;

const handleNullUndefinedValue = ( data, name ) => data[ name ] || "";

export class PartnerTemplateOfferDetails extends AdminOfferDetails {
  getLocalDate = ( obj, dateType ) => new Date( obj.OfferSchedule[ dateType ] ).toLocaleDateString( "fr-CA" );

  constructor( obj ) {
    super( obj );
    this.startDate = obj?.OfferSchedule?.start_date
      ? convertDate( obj?.OfferSchedule?.start_date )
      : null;
    this.endDate = obj?.OfferSchedule?.end_date
      ? convertDate( obj?.OfferSchedule?.end_date )
      : null;
    this.startDateOriginal = obj?.OfferSchedule?.start_date
      ? this.getLocalDate( obj, "start_date" )
      : "";
    this.endDateOriginal = obj?.OfferSchedule?.end_date
      ? this.getLocalDate( obj, "end_date" )
      : "";
    this.gameUrl = obj?.OfferSchedule?.Game?.icon_url || "";
    this.maxRewardCount = obj?.OfferSchedule?.max_reward_count_per_user || "0";
    this.offerData = obj.offer_type === TYPE_TO_BE_EDITED.OFFER_TEMPLATE ? obj : null;
    this.optInDetail = {
      optIn: obj?.opt_in || OPT_IN_STATUS.NO,
      membershipId: obj?.membership_id,
      entryFee: obj?.entry_fee || "",
      minParticipants: obj?.min_participants,
      maxParticipants: obj?.max_participants,
    };
    this.optInType = handleNullUndefinedValue( obj, "opt_in" );

    if( obj?.offerType === "tournament" ) {
      this.achievements = obj.OfferAchievements?.map( ( achievement ) => {
        const {
          fieldTime: { d, h, m, s },
        } = convertActualTime( { seconds: achievement.time_limit_as_seconds } );
        const events = achievement.Events.map( ( event ) => {
          return {
            label: event.event_name,
            value: event.event_id,
          };
        } );
        const eventDescriptions = achievement.Events.map( ( event ) => {
          return event.description;
        } );

        return {
          rankingObjective: achievement.ranking_objective,
          // This property is provided for backward compatibility for editing offers/tournaments
          // as the editing functionality still only works against a single events
          event: events[ 0 ],
          events,
          name: achievement.name,
          descriptionText: achievement.description_text,
          rawDescription: achievement.description,
          description: convertHtmlToEditorHtml( convertMarkDownToDraft( achievement.description ) ),
          value: achievement.value,
          function: FUNCTIONS.find( fun => fun.value === achievement.function ),
          comparison: COMPARISONS.find( comp => comp.value === achievement.operator ),
          ranking: RANKING_OBJECTIVE.find( rank => rank.value === achievement.ranking_objective ),
          days: d,
          hours: h,
          minutes: m,
          seconds: s,
          eventUnit: UNITS
            .find( unit => unit.value === String( achievement.Events[ 0 ].EventUnit.event_unit_id ) )
            .label,
          // This property is provided for backward compatibility for editing offers/tournaments
          // as the editing functionality still only works against a single events
          eventDescription: eventDescriptions[ 0 ],
          eventDescriptions,
        };
      } ) || {};
    }
  }
}
