import constant from "../../util/constant";
import tournamentConstant from "../../util/tournamentConstant";
import {
  GET_ADMIN_TOURNAMENTS_SUCCESS,
  GET_ADMIN_TOURNAMENTS_FAILURE,
  ADMIN_TOURNAMENT_API_REQUEST,
  ADMIN_TOURNAMENTS_API_END
} from "../actionTypes";

const { API_STATUS } = constant;
const { TOURNAMENTS_API_TYPES } = tournamentConstant;

const DEFAULT_STATE = {
  loading: false,
  message: "",
  status: "",
  apiType: "",
  adminTournaments: { tournamets: [], meta: "" },
  tournamentDetails: {},
};

const adminTournament = ( state = DEFAULT_STATE, action = "" ) => {
  switch ( action.type ) {
    case ADMIN_TOURNAMENT_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
        tournamentDetails: action.payload === TOURNAMENTS_API_TYPES.GET_ADMIN_TOURNAMENT_DETAILS ? {} : state.tournamentDetails,
      };
    case ADMIN_TOURNAMENTS_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case GET_ADMIN_TOURNAMENTS_SUCCESS:
      return {
        ...state,
        adminTournaments: {
          tournamets:
            action.payload.meta.page === 1
              ? action.payload.dataList
              : [ ...state.adminTournaments.tournamets, ...action.payload.dataList ],
          meta: action.payload.meta,
        },
        status: API_STATUS.SUCCESS,
      };
    case GET_ADMIN_TOURNAMENTS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
      };
    default:
      return state;
  }
};

export default adminTournament;
