import PropTypes from "prop-types";
import React from "react";

const FilterIcon = ( { showFilter, showFilterScreen, isFilterApplied } ) => {
  return (
    <>
      <div
        className={`btn-filter ${ !showFilter ? "" : "active" }`}
        onClick={showFilterScreen}
      >
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path d="M4.213 5.61C6.243 8.2 9.994 13 9.994 13v6c0 .55.453 1 1.006 1h2.01c.553 0 1.006-.45 1.006-1v-6s3.74-4.8 5.771-7.39c.513-.66.04-1.61-.794-1.61H5.007c-.834 0-1.307.95-.794 1.61z" fill="#FFF" fillRule="nonzero" />
          </g>
        </svg>
        {( isFilterApplied && showFilter ) && <span className="badge-filter"></span>}
      </div>
    </>
  );
};

FilterIcon.propTypes = {
  showFilter: PropTypes.bool,
  showFilterScreen: PropTypes.func,
  isFilterApplied: PropTypes.bool,
};

export default FilterIcon;
