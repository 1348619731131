import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getExchangeTransactionAction } from "../store/actions/adminGamer";
import { getExchangeTransactionAction as exchangeAction, getExchangeFilterListAction } from "../store/actions/adminTransaction";

const gamerExchangeEvent = ( values, handleChange, exchangeFilterList, qxPointsRange, gamerId, requestParams ) => {
  const dispatch = useDispatch();

  useEffect( () => {
    dispatch( getExchangeFilterListAction( gamerId ) );
  }, [] );

  useEffect( () => {
    if( values.initialMinRedeemPoints ) {
      handleChange( "initialMinRedeemPoints", values.initialMinRedeemPoints );
      handleChange( "initialMaxRedeemPoints", values.initialMaxRedeemPoints );
      handleChange( "minRedeemPoints", values.initialMinRedeemPoints );
      handleChange( "maxRedeemPoints", values.initialMaxRedeemPoints );
    }
    else {
      handleChange( "initialMinRedeemPoints", qxPointsRange.minQxPoints );
      handleChange( "initialMaxRedeemPoints", qxPointsRange.maxQxPoints );
      handleChange( "minRedeemPoints", qxPointsRange.minQxPoints );
      handleChange( "maxRedeemPoints", qxPointsRange.maxQxPoints );
    }

    if( values.initialStartDateTransaction ) {
      handleChange( "startDateTransaction", new Date( values.initialStartDateTransaction ) );
      handleChange( "endDateTransaction", new Date( values.initialEndDateTransaction ) );
    }
  }, [ exchangeFilterList ] );

  useEffect( () => {
    if( values.initialMaxRedeemPoints && gamerId ) {
      dispatch( getExchangeTransactionAction( gamerId, requestParams ) );
    }
    else if( values.initialMaxRedeemPoints && gamerId === null ) {
      dispatch( exchangeAction( requestParams ) );
    }
  }, [
    values.sortOrder,
    values.page,
    values.sortBy,
    values.searchKey,
    values.exchGamerNames,
    values.gameNames,
    values.assetNames,
    values.minRedeemPoints,
    values.maxRedeemPoints,
    values.initialStartDateTransaction,
    values.initialEndDateTransaction,
  ] );
};

export default gamerExchangeEvent;
