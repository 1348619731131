import { debounce } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Constant from "../../../util/constant";
import { useSetUrlParams } from "../../../util/helpers";
import CustomSlider from "../../shared/customSlider";
import RangeDatePicker from "../../shared/datePicker/rangeDatePicker";
import MultiSelectCheckbox from "../../shared/multiSelectCheckbox";

const { BRANDING: { APP_SHORT_NAME } } = Constant;

const ExchangeFilterList = ( {
  values,
  handleChange,
  addQuery,
  gamerNames,
  gameNames,
  assetNames,
  transactionRanges,
  isGamerNames = true,
  locationState,
} ) => {
  const listHandle = ( obj, listName ) => {
    if( obj.length > 0 ) {
      handleChange( "page", 1 );
      handleChange( listName, obj );
      addQuery( useSetUrlParams( listName, JSON.stringify( obj ) ), locationState );
    }
    else {
      addQuery( useSetUrlParams( listName, "" ), locationState );
      handleChange( listName );
    }
  };

  const clearHandle = ( parmas ) => {
    if( parmas ) {
      addQuery( useSetUrlParams( parmas, "" ), locationState );
      handleChange( parmas, [] );
    }
  };

  const multiSelectedItems = useMemo( () => [ values.gameNames, values.assetNames, values.exchGamerNames ] );
  const handleSelectChange = useCallback(
    [ obj => listHandle( obj, "gameNames" ),
      obj => listHandle( obj, "assetNames" ),
      obj => listHandle( obj, "exchGamerNames" ),
    ],
    []
  );
  const handleClearMultiSelect = useCallback(
    parmas => clearHandle( parmas ), []
  );
  const changeHandler = useCallback(
    debounce( ( newValue, p1, p2 ) => {
      handleChange( p1, newValue[ 0 ] );
      handleChange( p2, newValue[ 1 ] );
    }, 1000 ),
    []
  );

  const debounceQxPointsHandler = ( e, newValue ) => {
    handleChange( "page", 1 );
    addQuery( useSetUrlParams( "minExRedeemPoints", newValue[ 0 ] ), locationState );
    addQuery( useSetUrlParams( "maxExRedeemPoints", newValue[ 1 ] ), locationState );
    handleChange( "initialMinRedeemPoints", newValue[ 0 ] );
    handleChange( "initialMaxRedeemPoints", newValue[ 1 ] );
    changeHandler( newValue, "minRedeemPoints", "maxRedeemPoints" );
  };

  return (
    <>
      <div className="filter-boxes trans-exchange-filter">
        <Row>
          <Col>
            <Form.Group className='custom-multiselect'>
              <Form.Label>Game Name</Form.Label>
              <MultiSelectCheckbox
                multiOptionsList={gameNames}
                handleSelectChange={handleSelectChange[ 0 ]}
                multiSelectedItems={multiSelectedItems[ 0 ]}
                handleClearMultiSelect={() => handleClearMultiSelect( "gameNames" )}/>
            </Form.Group>
          </Col>
          <Col>
            <CustomSlider
              values={values}
              pointsHandler={debounceQxPointsHandler}
              handleChange={handleChange}
              min={transactionRanges.minQxPoints}
              max={transactionRanges.maxQxPoints}
              minName={"initialMinRedeemPoints"}
              maxName={"initialMaxRedeemPoints"}
              label={`${ APP_SHORT_NAME } Points`}
            />
          </Col>
          <Col>
            <Form.Group className='custom-multiselect'>
              <Form.Label>Assets</Form.Label>
              {<MultiSelectCheckbox
                multiOptionsList={assetNames}
                handleSelectChange={handleSelectChange[ 1 ]}
                multiSelectedItems={multiSelectedItems[ 1 ]}
                handleClearMultiSelect={() => handleClearMultiSelect( "assetNames" )}/>}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='custom-datepicker range-picker'>
              <RangeDatePicker
                label='Date of Transaction'
                values={values}
                addQuery={addQuery}
                handleChange={handleChange}
                param={{ key1: "startDateTransaction", key2: "endDateTransaction", key3: "initialStartDateTransaction", key4: "initialEndDateTransaction", page: "page" }}
                locationState={locationState}
              />
            </Form.Group>
          </Col>
          {isGamerNames && <Col>
            <Form.Group className='custom-multiselect'>
              <Form.Label>Gamer Name</Form.Label>
              {<MultiSelectCheckbox
                multiOptionsList={gamerNames}
                handleSelectChange={handleSelectChange[ 2 ]}
                multiSelectedItems={multiSelectedItems[ 2 ]}
                handleClearMultiSelect={() => handleClearMultiSelect( "exchGamerNames" )}/>}
            </Form.Group>
          </Col> }
        </Row>
      </div>
    </>
  );
};

ExchangeFilterList.propTypes = {
  values: PropTypes.object,
  onSearch: PropTypes.func,
  handleChange: PropTypes.func,
  addQuery: PropTypes.func,
  gamerNames: PropTypes.array,
  gameNames: PropTypes.array,
  transactionRanges: PropTypes.object,
  assetNames: PropTypes.array,
  onCancel: PropTypes.func,
  isGamerNames: PropTypes.bool,
  locationState: PropTypes.string,
};

export default ExchangeFilterList;
