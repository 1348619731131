import React from "react";
import profilePending from "../../../assets/images/profile-pending.png";
import constant from "../../../util/constant";

const { BRANDING: { APP_SHORT_NAME } } = constant;

const ProfileDisabled = () => {
  return (
    <>
      <div className='main-wrapper'>
        <div className='main-right-wrapper'>
          <div className='profile-pending-box'>
            <div className='left-side'>
              <h2>Partner Disabled</h2>
              <p>
              You no longer have access to the { APP_SHORT_NAME } portal. Please contact the admin for further clarification.
              </p>
            </div>
            <div className='right-side'>
              <img src={profilePending} alt='Application Disabled' />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileDisabled;
