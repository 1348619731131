import GetAppIcon from "@material-ui/icons/GetApp";
import HelpIcon from "@material-ui/icons/Help";
import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useAddQuery, useForm, getUrlData, useToggle } from "../../../hooks";
import analyticsConstant from "../../../util/analyticsConstant";
import constant from "../../../util/constant";
import { useSetUrlParams, getUrlParameter, checkFilterApplied } from "../../../util/helpers";
import partnerGameConstant from "../../../util/partnerGameConstant";
import FilterIcon from "../../shared/FilterIcon/FilterIcon";
import Tabs from "../../shared/tabs";
import Dashboard from "./dashboard/dashboard";
import ExchangeList from "./exchangeList";
import GameList from "./gameList";
import LinkedGamers from "./linkedGamers/index";

const { ORDER_BY, VIEW, ROUTES, FILTERS_NAME } = constant;
const { GAME_LIST_SORT } = partnerGameConstant;
const { ANALYTICS_TAB, SEARCH_PLACEHOLDER } = analyticsConstant;

const Analytics = () => {
  const paramsQuery = new URLSearchParams( window.location.search );
  const activeTab = paramsQuery.get( "tab" );
  const { addQuery } = useAddQuery();
  const { gridViewToggle } = useToggle();
  const history = useHistory();
  const location = useLocation();
  const {
    exchangeTransaction,
    usdPerQxPoint,
    partnerCostUsdPerQxPoint,
    linkedGamerGamesRange,
  } = useSelector( state => ( {
    exchangeTransaction: state.partnerAnalytics.exchangeTransaction,
    linkedGamerGamesRange: state.partnerAnalytics.linkedGamerGamesRange,
    usdPerQxPoint: state.partner.partnerDetail.configuration?.usdPerQxPoint || 0,
    partnerCostUsdPerQxPoint: state.partner.partnerDetail.configuration?.partnerCostUsdPerQxPoint || 0,
  } ) );
  const { qxPointsRange } = exchangeTransaction;
  const sliderMinMaxObj = {
    minQxPoint: qxPointsRange.min,
    maxQxPoint: qxPointsRange.max,
    minLinkedGames: linkedGamerGamesRange.min,
    maxLinkedGames: linkedGamerGamesRange.max,
  };
  const [ sortColumns, setSortColumns ] = useState( [
    { col: GAME_LIST_SORT.GAME_NAME, value: "Game Name", status: true, order: ORDER_BY.ASC },
    { col: GAME_LIST_SORT.LINKED_GAMERS, value: "Linked Gamers", status: false, order: null },
    { col: GAME_LIST_SORT.PREMIUM_GAMERS, value: "Premium Gamers", status: false, order: null },
    { col: GAME_LIST_SORT.OFFER_REWARD_VALUE, value: "Offer Reward Value", status: false, order: null },
    { col: GAME_LIST_SORT.TOURNAMENT_REWARD_VALUE, value: "Tournament Reward Value", status: false, order: null },
    { col: GAME_LIST_SORT.ASSET_EXCHANGE_VALUE, value: "Asset Exchange Value", status: false, order: null },
  ] );

  const { values, handleChange } = useForm(
    {
      viewType: VIEW.GRID,
      tab: getUrlParameter( "tab", ANALYTICS_TAB.GAMES ),
      sortBy: "",
      sortOrder: ORDER_BY.ASC,
      searchKey: "",
      placeholder: SEARCH_PLACEHOLDER.games,
    },
    {}
  );

  const filtersName = ( values.tab === ANALYTICS_TAB.EXCHANGE_TRANSACTION )
    ? FILTERS_NAME.ANALYTICS_EXCHANGE
    : FILTERS_NAME.ANALYTICS_GAMERS;
  const isFilterApplied = checkFilterApplied( window.location.search, filtersName, sliderMinMaxObj );

  const toggleClass = ( params, order, isQueryParamsUpdated = false ) => {
    gridViewToggle( sortColumns, handleChange, params, setSortColumns, addQuery, useSetUrlParams,
      order, location.state, isQueryParamsUpdated );
  };

  const changeViewType = ( param ) => {
    handleChange( "viewType", param );
    addQuery( useSetUrlParams( "viewType", param ), location.state );
    getUrlData( handleChange );
  };

  const searchHandle = ( e ) => {
    addQuery( useSetUrlParams( "searchKey", e.target.value ), location.state );
    handleChange( "searchKey", e.target.value );
  };

  const setTab = ( val ) => {
    handleChange( "searchKey", "" );
    history.replace( `${ ROUTES.PARTNER_ANALYTICS }?tab=${ val }` );
  };

  useEffect( () => {
    handleChange( "tab", activeTab );
  }, [ activeTab ] );

  const setPreviousData = ( isGameList ) => {
    getUrlData( handleChange );

    if( isGameList && paramsQuery.get( "sortBy" ) && paramsQuery.get( "sortOrder" ) ) {
      toggleClass( paramsQuery.get( "sortBy" ), paramsQuery.get( "sortOrder" ), true );
    }

    handleChange( "placeholder", SEARCH_PLACEHOLDER[ values.tab ] );

    if( values.tab === ANALYTICS_TAB.GAMERS ) {
      handleChange( "placeholder", SEARCH_PLACEHOLDER.linkedGamers );
    }
  };

  const exportData = () => {
    //TODO: Will work on this.
  };

  const [ showFilter, setShowFilter ] = useState( false );
  const showFilterScreen = () => setShowFilter( !showFilter );

  return (
    <>
      <div className='main-wrapper'>
        <div className='main-right-wrapper'>
          <div className='page-head-wrapper'>
            <h4 className='page-heading'>
              Analytics
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-disabled">
                    Review and evaluate your KPIs here
                  </Tooltip>
                }
              >
                <HelpIcon className="help-icon" />
              </OverlayTrigger>
            </h4>
            <div className='page-head-right'>
              { ( values.tab !== ANALYTICS_TAB.DASHBOARD ) && (
                <Form.Group className='custom-search w-300 placeholder-ellipsis'>
                  <span className='icon-search'></span>
                  <Form.Control
                    type='text'
                    placeholder={values.placeholder}
                    onChange={searchHandle}
                    data-testid='search'
                    value={values.searchKey}
                    title={values.placeholder}
                  />
                </Form.Group>
              ) }
              {( ( values.tab === ANALYTICS_TAB.EXCHANGE_TRANSACTION )
              || ( values.tab === ANALYTICS_TAB.GAMERS ) )
              && ( <div className="middle-filter-btn">
                <FilterIcon
                  showFilter={showFilter}
                  showFilterScreen={showFilterScreen}
                  isFilterApplied={isFilterApplied} />
              </div> )
              }
              <Button
                variant='primary'
                className='small-btn'
                onClick={() => exportData() }
                data-testid='export-data'
              >
                <GetAppIcon />
                    Export
              </Button>
            </div>
          </div>
          <div className='filter-tabs-wrapper'>
            <div className='custom-tabs w-100'>
              <Tabs
                values={values}
                setTab={setTab}
                tab={ANALYTICS_TAB}
                isPartnerDetail={false}
              />
            </div>
            {values.tab === ANALYTICS_TAB.GAMES && <div className='right-side'>
              {values.viewType === VIEW.GRID && ( <Dropdown className='custom-dropdown sort-dropdown right-arrow'>
                <Dropdown.Toggle variant='success' id='dropdown-basic'>
                  <span className={values.sortOrder === "ASC" ? "icon-sort-asc" : "icon-sort"}></span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <span className='dropdown-heading'>Sort By</span>
                  {sortColumns.map( data => (
                    <Dropdown.Item key={data}
                      href='#'
                      className={data.status === true ? "active" : ""}
                      onClick={() => toggleClass( data.col, data.order )}
                    >
                      <span>{data.value}</span>
                    </Dropdown.Item>
                  ) )}
                </Dropdown.Menu>
              </Dropdown> )}
              <div className='grid-buttons'>
                <span className={values.viewType === VIEW.GRID ? "icon-grid-view active" : "icon-grid-view "} onClick={() => changeViewType( VIEW.GRID )}></span>
                <span className={values.viewType === VIEW.LIST ? "icon-list-view active" : "icon-list-view "} onClick={() => changeViewType( VIEW.LIST )}></span>
              </div>
            </div>
            }
          </div>
          { ( values.tab === ANALYTICS_TAB.DASHBOARD ) && <Dashboard/> }
          { ( values.tab === ANALYTICS_TAB.GAMES ) && <GameList
            viewType={values.viewType}
            sortBy={values.sortBy}
            sortOrder={values.sortOrder}
            searchKey={values.searchKey}
            setPreviousData={setPreviousData}
            usdPerQxPoint={usdPerQxPoint}
            partnerCostUsdPerQxPoint={partnerCostUsdPerQxPoint}
          />
          }
          { ( values.tab === ANALYTICS_TAB.EXCHANGE_TRANSACTION ) && <ExchangeList
            searchKey={values.searchKey}
            setPreviousData={setPreviousData}
            showFilter={showFilter}
            tab={values.tab}
          />
          }

          { ( values.tab === ANALYTICS_TAB.GAMERS ) &&
          <LinkedGamers
            searchKey={values.searchKey}
            setPreviousData={setPreviousData}
            showFilter={showFilter}
          />
          }
        </div>
      </div>
    </>
  );
};

export default Analytics;
