import { upperFirst } from "lodash";
import { convertDate } from "../util/helpers";
import offerConstant from "../util/offerConstant";

const { TRANSACTION_FOR } = offerConstant;

export class PartnerOptInTxnList {
  constructor( obj ) {
    this.gamerName = obj.gamerName || "N/A";
    this.transactionFor = upperFirst( TRANSACTION_FOR.find( val => val.transactionType === obj.transactionType )?.entityName );
    this.QXPoints = obj.entryFee;
    this.transactionDate = convertDate( obj.createdAt ) || "";
    this.date = obj.createdAt;
    this.offerName = obj?.offerName;
    this.gameName = obj.gameName;
  }
}
