import { toastr } from "react-redux-toastr";
import { AdminOfferDetails } from "../../entities/adminOfferDetails";
import { AdminPartnerLinkedGamerDetails } from "../../entities/adminPartnerLinkedGamerDetails";
import { PartnerGameDetails } from "../../entities/partnerGameDetails";
import { getAdminOffers } from "../../services/adminOffersService";
import {
  getPartnerList,
  getPartnerDetail,
  rejectPartnerRequest,
  setAmmendmentsRequest,
  confirmForDocuSign,
  resolveAmendment,
  updatePartnerStatus,
  approvePartnerManually,
  getPartnersFilterList,
  getAdminPartnerGames,
  getAdminLinkedGamerList,
  getAdminLinkedGamerFilterList
} from "../../services/adminServices";
import adminConstant from "../../util/adminConstant";
import constant from "../../util/constant";
import { prepareLabelValueFormatData } from "../../util/helpers";
import {
  START_LOADER,
  STOP_LOADER,
  PARTNER_LIST_SUCCESS,
  PARTNER_LIST_FAILURE,
  PARTNER_DETAIL_SUCCESS,
  PARTNER_DETAIL_FAILURE,
  ADMIN_API_REQUEST,
  ADMIN_API_END,
  REJECT_PARTNER_REQUEST_FAILURE,
  REJECT_PARTNER_REQUEST_SUCCESS,
  AMMENDMENTS_SUGGESTED_SUCCESS,
  AMMENDMENTS_SUGGESTED_FAILURE,
  CONFIRM_FOR_DOCUSIGN_SUCCESS,
  CONFIRM_FOR_DOCUSIGN_FAILURE,
  RESOLVE_AMENDMENT_SUCCESS,
  RESOLVE_AMENDMENT_FAILURE,
  UPDATE_PARTNER_STATUS_SUCCESS,
  UPDATE_PARTNER_STATUS_FAILURE,
  APPROVE_PARTNER_MANUAL_SUCCESS,
  APPROVE_PARTNER_MANUAL_FAILURE,
  PARTNER_FILTER_LIST_SUCCESS,
  PARTNER_FILTER_LIST_FAILURE,
  ADMIN_PARTNER_LIST_API_REQUEST,
  PARTNER_LIST_API_END,
  GET_ADMIN_PARTNER_GAMES_SUCCESS,
  GET_ADMIN_PARTNER_GAMES_FAILURE,
  GET_ADMIN_PARTNER_OFFERS_SUCCESS,
  GET_ADMIN_PARTNER_OFFERS_FAILURE,
  ADMIN_PARTNER_FILTER_LIST_API_REQUEST,
  ADMIN_LINKED_GAMER_LIST_API_REQUEST,
  ADMIN_LINKED_GAMERS_LIST_SUCCESS,
  ADMIN_LINKED_GAMERS_LIST_FAILURE,
  ADMIN_LINKED_GAMER_FILTER_LIST_API_REQUEST,
  ADMIN_LINKED_GAMERS_FILTER_LIST_SUCCESS,
  ADMIN_LINKED_GAMERS_FILTER_LIST_FAILURE
} from "../actionTypes";

const { ADMIN_API_TYPES } = adminConstant;
const { ERROR_MESSAGES } = constant;

export const getPartnersListAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_PARTNER_LIST_API_REQUEST,
      payload: ADMIN_API_TYPES.GET_PARTNER_LIST,
    } );

    const response = await getPartnerList( param );

    if( response ) {
      dispatch( {
        type: PARTNER_LIST_SUCCESS,
        payload: { dataList: response.data.partner_data, meta: response.data.meta },
      } );
    }
    else {
      dispatch( { type: PARTNER_LIST_FAILURE, payload: response.message || "" } );
    }

    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: PARTNER_LIST_FAILURE, payload: error } );
    dispatch( { type: STOP_LOADER } );
  }
  finally {
    dispatch( { type: PARTNER_LIST_API_END } );
  }
};

export const getPartnersFilterListAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_PARTNER_FILTER_LIST_API_REQUEST,
      payload: ADMIN_API_TYPES.GET_PARTNER_FILTER_LIST,
    } );

    const response = await getPartnersFilterList( param );

    if( response ) {
      const data = response.data.partner_data;
      const arrayUniqueByKey = [];
      const filterArr = [ "legal_entity_name", "email", "primary_name", "primary_phone" ];
      const filterArrNames = [ "partnerNames", "partnerEmails", "partnerContacts", "partnerPhones" ];
      const filterArrData = [ ];

      filterArr.forEach( async function( element, index ) {
        filterArrData[ filterArrNames[ index ] ] = data.map( val => ( { label: val[ element ], value: val[ element ] } ) );

        if( element !== "legal_entity_name" ) {
          filterArrData[ filterArrNames[ index ] ].sort( function( a, b ) {
            return a.label.localeCompare( b.label );
          } );
        }

        arrayUniqueByKey[ filterArrNames[ index ] ] = [ ...new Map( filterArrData[ filterArrNames[ index ] ].map( item =>
          [ item.value, item ] ) ).values() ];
      } );

      dispatch( {
        type: PARTNER_FILTER_LIST_SUCCESS,
        payload: Object.assign( {}, arrayUniqueByKey ),
      } );
    }
    else {
      dispatch( { type: PARTNER_FILTER_LIST_FAILURE, payload: response.message || "" } );
    }

    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: PARTNER_FILTER_LIST_FAILURE, payload: error } );
    dispatch( { type: STOP_LOADER } );
  }
  finally {
    dispatch( { type: PARTNER_LIST_API_END } );
  }
};

export const getPartnerDetailAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );

    const response = await getPartnerDetail( param.id );

    if( response ) {
      dispatch( { type: PARTNER_DETAIL_SUCCESS, payload: response.data } );
    }
    else {
      dispatch( {
        type: PARTNER_DETAIL_FAILURE,
        payload: response.message || "",
      } );
    }

    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: PARTNER_DETAIL_FAILURE, payload: error } );
    dispatch( { type: STOP_LOADER } );
  }
};

export const getAdminPartnerGamesAction = requestParams => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );

    const response = await getAdminPartnerGames( requestParams );
    const dataList = response.data.gameList.map(
      item => new PartnerGameDetails( item )
    );

    dispatch( {
      type: GET_ADMIN_PARTNER_GAMES_SUCCESS,
      payload: dataList,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GET_ADMIN_PARTNER_GAMES_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_API_END,
    } );
  }
};

export const getAdminPartnerOffersAction = requestParams => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );

    const response = await getAdminOffers( requestParams );
    const dataList = response.data.offerList.map(
      item => new AdminOfferDetails( item )
    );

    dispatch( {
      type: GET_ADMIN_PARTNER_OFFERS_SUCCESS,
      payload: dataList,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GET_ADMIN_PARTNER_OFFERS_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_API_END,
    } );
  }
};

export const rejectPartnerRequestAction = requestObj => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_API_REQUEST,
      payload: ADMIN_API_TYPES.REJECT_PARTNER_REQUEST,
    } );

    const response = await rejectPartnerRequest( requestObj );

    dispatch( { type: REJECT_PARTNER_REQUEST_SUCCESS, payload: response } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: REJECT_PARTNER_REQUEST_FAILURE, payload: error } );
    dispatch( { type: STOP_LOADER } );
    toastr.error( "Error", error?.error || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( { type: ADMIN_API_END } );
  }
};

export const setAmmendmentsRequestAction = requestObj => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_API_REQUEST,
      payload: ADMIN_API_TYPES.AMMENDMENTS_SUGGESTED,
    } );

    const response = await setAmmendmentsRequest( requestObj );

    dispatch( { type: AMMENDMENTS_SUGGESTED_SUCCESS, payload: response.data } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: AMMENDMENTS_SUGGESTED_FAILURE, payload: error } );
    dispatch( { type: STOP_LOADER } );
    toastr.error( "Error", error?.error || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( { type: ADMIN_API_END } );
  }
};

export const confirmForDocuSignAction = requestObj => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_API_REQUEST,
      payload: ADMIN_API_TYPES.CONFIRM_FOR_DOCUSIGN,
    } );

    const response = await confirmForDocuSign( requestObj );

    dispatch( { type: CONFIRM_FOR_DOCUSIGN_SUCCESS, payload: response.data } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: CONFIRM_FOR_DOCUSIGN_FAILURE, payload: error } );
    dispatch( { type: STOP_LOADER } );
    toastr.error( "Error", error?.error || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( { type: ADMIN_API_END } );
  }
};

export const resolveAmendmentAction = requestObj => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_API_REQUEST,
      payload: ADMIN_API_TYPES.RESOLVE_AMENDMENT,
    } );

    const response = await resolveAmendment( requestObj );

    dispatch( { type: RESOLVE_AMENDMENT_SUCCESS, payload: response.data } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: RESOLVE_AMENDMENT_FAILURE, payload: error } );
    dispatch( { type: STOP_LOADER } );
    toastr.error( "Error", error?.error || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( { type: ADMIN_API_END } );
  }
};

export const updatePartnerStatusAction = requestObj => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_API_REQUEST,
      payload: ADMIN_API_TYPES.UPDATE_PARTNER_STATUS,
    } );

    const response = await updatePartnerStatus( requestObj );

    dispatch( { type: UPDATE_PARTNER_STATUS_SUCCESS, payload: response.data } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: UPDATE_PARTNER_STATUS_FAILURE, payload: error } );
    dispatch( { type: STOP_LOADER } );
    toastr.error( error?.error || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( { type: ADMIN_API_END } );
  }
};

export const approvePartnerManualAction = requestObj => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_API_REQUEST,
      payload: ADMIN_API_TYPES.APPROVE_PARTNER_MANUALLY,
    } );

    const response = await approvePartnerManually( requestObj );

    dispatch( { type: APPROVE_PARTNER_MANUAL_SUCCESS, payload: response.data } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: APPROVE_PARTNER_MANUAL_FAILURE, payload: error } );
    dispatch( { type: STOP_LOADER } );
    toastr.error( error?.error || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( { type: ADMIN_API_END } );
  }
};

export const getAdminLinkedGamerAction = ( partnerId, params ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_LINKED_GAMER_LIST_API_REQUEST,
      payload: ADMIN_API_TYPES.ADMIN_LINKED_GAMERS_FILTER_LIST,
    } );

    const response = await getAdminLinkedGamerList( partnerId, params );
    const dataList = response.data.gamerList.map(
      item => new AdminPartnerLinkedGamerDetails( item )
    );

    dispatch( {
      type: ADMIN_LINKED_GAMERS_LIST_SUCCESS,
      payload: { dataList, meta: response.data.meta },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: ADMIN_LINKED_GAMERS_LIST_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_API_END,
    } );
  }
};

export const getLinkedGamerFilterAction = partnerId => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_LINKED_GAMER_FILTER_LIST_API_REQUEST,
      payload: ADMIN_API_TYPES.ADMIN_LINKED_GAMERS_FILTER_LIST,
    } );

    const response = await getAdminLinkedGamerFilterList( partnerId );
    const gamerNames = prepareLabelValueFormatData( response.data.gamerNames );
    const acqyrCashBalance = {
      min: response.data.acqyrCashBalanceRange.minAcqyrCashBalance,
      max: response.data.acqyrCashBalanceRange.maxAcqyrCashBalance,
    };
    const linkedGameRange = {
      min: response.data.linkedGameRange.minLinkedGame,
      max: response.data.linkedGameRange.maxLinkedGame,
    };

    dispatch( {
      type: ADMIN_LINKED_GAMERS_FILTER_LIST_SUCCESS,
      payload: { acqyrCashBalance, gamerNames, linkedGameRange },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: ADMIN_LINKED_GAMERS_FILTER_LIST_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_API_END,
    } );
  }
};
