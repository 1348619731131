import { Auth, Hub } from "aws-amplify";
import axios from "axios";
import { clearReduxStoreAction } from "../store/actions/auth";
import constant from "../util/constant";
import { disableOnLoadEvent } from "../util/helpers";
import { HTTP_METHODS } from "../util/httpMethods";

export function setTokenHeader( token ) {
  if( token ) {
    axios.defaults.headers.common.Authorization = `Bearer ${ token }`;
  }
  else {
    delete axios.defaults.headers.common.Authorization;
  }
}

export function request( method, url, data ) {
  return new Promise( ( resolve, reject ) => {
    const req = {
      method: method,
      url: url,
      data: data,
    };

    if( method === HTTP_METHODS.GET ) {
      req.params = data;
    }

    return axios( req )
      .then( ( res ) => {
        return resolve( res.data );
      } )
      .catch( ( err ) => {
        return reject( err.response );
      } );
  } );
}

export const tokenExpireRedirection = ( dispatch, callBack ) => {
  axios.interceptors.response.use(
    ( response ) => {
      return Promise.resolve( response );
    },
    async ( error ) => {
      const { status = "", data = {} } = error?.response || {};
      const { userNotExists, message } = data;

      if( status === 401 ) {
        try {
          await Auth.signOut();
          localStorage.removeItem( "role" );
          dispatch( clearReduxStoreAction() );
          Hub.dispatch( "UI Auth", {
            event: "AuthStateChange",
            message: "signedout",
          } );
          callBack();
          disableOnLoadEvent();
          // TODO: refactor to not reassign error
          // eslint-disable-next-line no-param-reassign
          error = {
            ...error,
            response: {
              ...error.response,
              data: {
                ...error.response.data,
                message: userNotExists ? message : constant.MESSAGE.TOKEN_EXPIRE_MESSAGE,
              },
            },
          };
        }
        catch ( err ) {
          // TODO: Consider better logging than just console
          // eslint-disable-next-line no-console
          console.log( "error signing out: ", err );
        }
      }

      return Promise.reject( error );
    }
  );
};
