import HelpIcon from "@material-ui/icons/Help";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { Auth } from "aws-amplify";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Row from "react-bootstrap/Row";
import Tooltip from "react-bootstrap/Tooltip";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useForm } from "../../../hooks";

const initialState = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
  ctype: "password",
  ntype: "password",
  cftype: "password",
};
const initialDirty = {
  currentPassword: false,
  newPassword: false,
  confirmPassword: false,
};

const PasswordReset = ( ) => {
  const dispatch = useDispatch();
  const { values, errors, isError, handleChange, showAllErrorMessage, reset } =
    useForm(
      initialState,
      initialDirty
    );

  const handleInputChange = ( e ) => {
    const { name, value } = e.target;

    handleChange( name, value );
  };

  const handleUpdatePassword = () => {
    showAllErrorMessage();

    if( !isError ) {
      if( values.newPassword.length < 12 ) {
        toastr.error( "Password must consist of at least 12 characters" );
      }
      else if( values.newPassword === values.confirmPassword ) {
        dispatch( { type: "START_LOADER" } );
        Auth.currentAuthenticatedUser().then( ( user ) => {
          return Auth.changePassword( user, values.currentPassword, values.newPassword );
        } )
          .then( () => {
            reset();
            dispatch( { type: "STOP_LOADER" } );
            toastr.success( "Your password changed successfully" );
          } )
          .catch( ( err ) => {
            reset();
            toastr.error( err.message );
            dispatch( { type: "STOP_LOADER" } );
          } );
      }
      else {
        toastr.error( "New password and confirm password do not match" );
      }
    }
  };

  const togglePassword = ( type ) => {
    if( values[ type ] === "password" ) {
      handleChange( type, "text" );
    }
    else {
      handleChange( type, "password" );
    }
  };

  const passwordToolTip =
    <OverlayTrigger placement='right' overlay=
      {<Tooltip id="tooltip-disabled">
        Password must contain a lower case letter, an upper case letter, a special character, a number and at least 12 characters
      </Tooltip>}>
      <HelpIcon />
    </OverlayTrigger>
  ;

  return (
    <>
      <Form>
        <Row>
          <Col sm={12}>
            <div className="content-section mt-4">
              <div className="content-label bold-small-font">Change Password</div>
              <div className="divider m-0"></div>
            </div>
          </Col>
          <Col sm={6}>
            <Form.Group>
              <Form.Label>Current Password</Form.Label>
              <Form.Control
                data-testid='currentPassword'
                type={values.ctype}
                name='currentPassword'
                onChange={handleInputChange}
                value={values.currentPassword}
                maxLength={30}/>
              <div className='invalid-feedback d-block'>
                {errors.currentPassword}
              </div>
              <Button variant="text btn-password-toggle">
                { values.ctype === "text" && <VisibilityIcon onClick={() => togglePassword( "ctype" )} data-testid='text-toggle'/>}
                { values.ctype === "password" && <VisibilityOffIcon onClick={() => togglePassword( "ctype" )} data-testid='password-toggle'/>}
              </Button>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group>
              <Form.Label className='label-with-icon'>
                New Password
                {passwordToolTip}
              </Form.Label>
              <Form.Control
                data-testid='newPassword'
                type={values.ntype}
                name='newPassword'
                onChange={handleInputChange}
                value={values.newPassword}
                maxLength={30}/>
              <div className='invalid-feedback d-block'>
                {errors.newPassword}
              </div>
              <Button variant="text btn-password-toggle">
                { values.ntype === "text" && <VisibilityIcon onClick={() => togglePassword( "ntype" )} data-testid='new-text-toggle'/>}
                { values.ntype === "password" && <VisibilityOffIcon onClick={() => togglePassword( "ntype" )} data-testid='new-password-toggle'/>}
              </Button>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group className="mb-0">
              <Form.Label className='label-with-icon'>
                Confirm New Password
                {passwordToolTip}
              </Form.Label>
              <Form.Control
                data-testid='confirmPassword'
                type={values.cftype}
                name='confirmPassword'
                onChange={handleInputChange}
                value={values.confirmPassword}
                maxLength={30} />
              <div className='invalid-feedback d-block'>
                {errors.confirmPassword}
              </div>
              <Button variant="text btn-password-toggle">
                { values.cftype === "text" && <VisibilityIcon onClick={() => togglePassword( "cftype" )} data-testid='confirm-text-toggle'/>}
                { values.cftype === "password" && <VisibilityOffIcon onClick={() => togglePassword( "cftype" )} data-testid='confirm-password-toggle'/>}
              </Button>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="text-right px-0 fixed-footer-wrapper">
            <Button
              variant="primary"
              className="btn-md"
              onClick={() => handleUpdatePassword()}
              data-testid='updateKey'
            >Update</Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default PasswordReset;
