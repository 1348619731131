import { toastr } from "react-redux-toastr";
import { uploadLogo } from "../../services/profileServices";
import partnerProfileConstant from "../../util/partnerProfileConstant";
import {
  SET_CONTACT_INFO_API_REQUEST,
  SET_CONTACT_INFO_SUCCESS,
  SET_CONTACT_INFO_FAILURE,
  UPLOAD_LOGO_FAILURE,
  UPLOAD_LOGO_SUCCESS,
  SET_PARTNER_INFO_EMPTY,
  SET_GENERAL_INFO_API_REQUEST,
  SET_GENERAL_INFO_SUCCESS,
  SET_GENERAL_INFO_FAILURE,
  SET_GENERAL_INFO_API_END,
  SET_OTHER_INFO_API_REQUEST,
  SET_OTHER_INFO_SUCCESS,
  SET_OTHER_INFO_FAILURE,
  SET_TERMS_INFO_API_REQUEST,
  SET_TERMS_INFO_SUCCESS,
  SET_TERMS_INFO_FAILURE,
  START_LOADER,
  STOP_LOADER

} from "../actionTypes";

const { API_TYPES } = partnerProfileConstant;

export const setGeneralInfoAction = requestObj => async ( dispatch ) => {
  try {
    dispatch( {
      type: SET_GENERAL_INFO_API_REQUEST,
      payload: API_TYPES.SET_GENERAL_INFO,
    } );
    await dispatch( { type: SET_GENERAL_INFO_SUCCESS, payload: requestObj } );
  }
  catch ( error ) {
    dispatch( { type: SET_GENERAL_INFO_FAILURE, payload: error } );
  }
  finally {
    dispatch( {
      type: SET_GENERAL_INFO_API_END,
    } );
  }
};

export const setContactInfoAction = requestObj => async ( dispatch ) => {
  try {
    dispatch( {
      type: SET_CONTACT_INFO_API_REQUEST,
      payload: API_TYPES.SET_CONTACT_INFO,
    } );
    await dispatch( { type: SET_CONTACT_INFO_SUCCESS, payload: requestObj } );
  }
  catch ( error ) {
    toastr.error( error );
    dispatch( { type: SET_CONTACT_INFO_FAILURE, payload: error } );
  }
  finally {
    dispatch( {
      type: SET_GENERAL_INFO_API_END,
    } );
  }
};

export const setOtherInfoAction = requestObj => async ( dispatch ) => {
  try {
    dispatch( {
      type: SET_OTHER_INFO_API_REQUEST,
      payload: API_TYPES.SET_OTHER_INFO,
    } );
    await dispatch( { type: SET_OTHER_INFO_SUCCESS, payload: requestObj } );
  }
  catch ( error ) {
    toastr.error( error );
    dispatch( { type: SET_OTHER_INFO_FAILURE, payload: error } );
  }
  finally {
    dispatch( {
      type: SET_GENERAL_INFO_API_END,
    } );
  }
};

export const uploadLogoAction = data => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );

    const response = await uploadLogo( data );

    dispatch( { type: UPLOAD_LOGO_SUCCESS, payload: response } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( { type: UPLOAD_LOGO_FAILURE, payload: error } );
  }
};

export const setEmptyPartnerInfoAction = ( payload ) => {
  return {
    type: SET_PARTNER_INFO_EMPTY,
    payload,
  };
};


export const setTermsInfoAction = data => async ( dispatch ) => {
  try {
    dispatch( {
      type: SET_TERMS_INFO_API_REQUEST,
      payload: API_TYPES.SET_TERMS_INFO,
    } );
    await dispatch( { type: SET_TERMS_INFO_SUCCESS, payload: data } );
  }
  catch ( error ) {
    toastr.error( error );
    dispatch( { type: SET_TERMS_INFO_FAILURE, payload: error } );
  }
  finally {
    dispatch( {
      type: SET_GENERAL_INFO_API_END,
    } );
  }
};

export const startLoader = ( ) => {
  return {
    type: START_LOADER,
  };
};
