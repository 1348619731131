import { convertDate } from "../util/helpers";
import { AdminOfferDetails } from "./adminOfferDetails";

export class AdminTournamentDetails extends AdminOfferDetails {
  constructor( obj ) {
    super( obj );
    this.iconUrl = obj.Partner.icon_url || "";
    this.tournamentName = obj.name || "";
    this.endDate = obj?.OfferSchedule?.end_date
      ? convertDate( obj?.OfferSchedule?.end_date )
      : "N/A";
    this.startDate = obj?.OfferSchedule?.start_date
      ? convertDate( obj?.OfferSchedule?.start_date )
      : "N/A";
  }
}
