const constant = {
  ROLE: {
    ADMIN: "admin",
    PARTNER: "partner",
  },
  ROUTES: {
    PARTNER_GENERAL_INFO: "/partner/generalInfo",
    PARTNER_CONTACT_INFO: "/partner/contactInfo",
    PARTNER_OTHER_INFO: "/partner/otherInfo",
    PARTNER_TERMS_CONDITIONS: "/partner/termsConditions",
    PARTNER_DOCUSIGN: "/partner/docusign",
    ADMIN_PARTNER_LIST: "/admin/partners",
    PARTNER_PROFILE_PENDING: "/partner/profilePending",
    PARTNER_REQUEST_DETAIL: "/admin/partnerRequestDetail/:id",
    PARTNER_REQUEST_DETAILS: "/admin/partnerRequestDetail/",
    PARTNER_DASHBOARD: "/partner",
    PARTNER_DOCUSIGN_PENDING: "/partner/docuSignPending",
    PARTNER_GAMES: "/partner/games",
    PARTNER_PROFILE_REJECTED: "/partner/profileRejected",
    PARTNER_PROFILE_DISABLED: "/partner/profileDisabled",
    PARTNER_ADD_EDIT_GAME: "/partner/game/:gameId",
    ADMIN_GAME_LIST: "/admin/games",
    ADMIN_GAME_DETAILS: "/admin/gameDetails/:gameId",
    PARTNER_EVENT_LIST: "/partner/events",
    PARTNER_ADD_EDIT_EVENT: "/partner/event/:eventId",
    ADMIN_EVENT_LIST: "/admin/events",
    ADMIN_ADD_EDIT_EVENT: "/admin/event/:eventId",
    ADMIN_EVENT_DETAIL: "/admin/eventDetails/:eventId",
    PARTNER_EVENT_DETAIL: "/partner/eventDetails/:eventId",
    ADMIN_USER_LIST: "/admin/userList",
    ADMIN_ADD_EDIT_USER: "/admin/user/:userId",
    PARTNER_OFFER_LIST: "/partner/offers",
    PARTNER_CREATE_AND_EDIT_OFFER: "/partner/offer/:offerId",
    ADMIN_OFFER_LIST: "/admin/offers",
    ADMIN_ADD_EDIT_OFFER: "/admin/offer/:offerId",
    ADMIN_OFFER_DETAILS: "/admin/offerDetails/:offerId",
    PARTNER_OFFER_DETAIL: "/partner/offerDetails/:offerId",
    ADMIN_NOTIFICATIONS: "/admin/notifications",
    PARTNER_NOTIFICATIONS: "/partner/notifications",
    PARTNER_SETTING: "/partner/settings",
    ADMIN_SETTING: "/admin/settings",
    ADMIN_NOT_FOUND: "admin/404",
    GAMER_LIST: "/admin/gamerList",
    GAMER_DETAIL: "/admin/gamer/:gamerId",
    GAMER_OFFER_LIST: "/admin/gamer/offers/:gamerId",
    GAMER_GAME_DETAILS: "/admin/gamerGameDetails/:gameId/:gamerId",
    GAMER_SECTION: "/admin/gamerSection/:gamerId",
    TRANSACTION_LIST: "/admin/transactionList",
    REDEEM_TRANSACTION_DETAIL: "/admin/redeemTxDetail/:transactionId",
    GAMER_EXCHANGE_TRANSACTION: "/admin/gamer/exchangeTransaction/:gamerId",
    REDEEM_TRANSACTIONS: "/admin/redeemTransactions/:gamerId",
    GAMER_ASSETS: "/admin/assets/:gamerId",
    PARTNER_ANALYTICS: "/partner/analytics",
    ANALYTIC_VIEW_GAME: "/partner/partnerDetail/:gameId",
    PARTNER_TOURNAMENTS: "/partner/tournaments",
    PARTNER_CREATE_EDIT_TOURNAMENT: "/partner/tournament/:tournamentId",
    ADMIN_TOURNAMENTS_LIST: "/admin/tournaments",
    ADMIN_TOURNAMENT_DETAILS: "/admin/tournamentDetail/:tournamentId",
    PARTNER_TOURNAMENT_DETAIL: "/partner/tournamentDetail/:offerId",
    PARTNER_ANALYTICS_GAMER_ASSETS: "/partner/assets/:gameId/:gamerId",
    PARTNER_GAMER_LIST: "/partner/gamerList",
    PARTNER_GAMER_SECTION: "/partner/gamerSection/:gamerId",
    PARTNER_GAMER_TOURNAMENTS: "/partner/gamer/tournaments/:gamerId",
    PARTNER_GAMER_TOURNAMENT_DETAILS: "/partner/gamer/tournaments/:gamerId/details/:offerId",
    ADMIN_GAMER_TOURNAMENTS: "/admin/gamer/tournaments/:gamerId",
    ADMIN_GAMER_TOURNAMENT_DETAILS: "/admin/gamer/tournaments/:gamerId/details/:tournamentId",
    ADMIN_GAMER_LINKED_OFFER_DETAILS: "/admin/gamer/linkedOffers/:offerId/details/:gamerId",
    PARTNER_LEADERBOARD_PARTICIPANTS: "/partner/leaderboard/:tournamentId/participants",
    PARTNER_GAMER_LEADERBOARD_PARTICIPANTS: "/partner/gamer/leaderboard/:gamerId/participants/:tournamentId",
    ADMIN_LEADERBOARD_PARTICIPANTS: "/admin/leaderboard/:tournamentId/participants",
    ADMIN_GAMER_LEADERBOARD_PARTICIPANTS: "/admin/gamer/leaderboard/:gamerId/participants/:tournamentId",
    PARTNER_GAMER_ASSETS: "/partner/gamer/assets/:gamerId",
    PARTNER_GAMER_LINKED_GAMES: "/partner/gamer/linkedGames/:gamerId",
    PARTNER_GAMER_LINKED_GAME_DETAIL: "/partner/gamer/:gamerId/linkedGamesDetail/:gameId",
    PARTNER_LINKED_GAMES_OFFER_DETAIL: "/partner/linkedGames/:gamerId/GameOffers/:gameId/detail/:offerId",
    PARTNER_GAMER_EXCHANGE_TRANSACTION: "/partner/gamer/exchangeTransaction/:gamerId",
    ADMIN_GAMER_LINKED_GAMES_OFFER_DETAIL: "/admin/linkedGames/:gamerId/GamerOffers/:gameId/detail/:offerId",
    PARTNER_GAMER_LINKED_OFFERS: "/partner/gamer/linkedOffers/:gamerId",
    PARTNER_GAMER_LINKED_OFFER_DETAIL: "/partner/linkedOffers/:gamerId/linkedOfferDetail/:offerId",
    ADMIN_ADD_EDIT_TOURNAMENT: "/admin/tournament/:offerId",
    ADMIN_GAMER_TRANSACTION_HISTORY: "/admin/gamer/transactionHistory/:gamerId",
  },
  ADMIN_NAV_ENTITY: {
    PARTNERS: [ "partners", "partnerRequestDetail" ],
    GAMES: [ "games", "gameDetails" ],
    OFFERS: [ "offers", "offer", "offerDetails" ],
    EVENTS: [ "events" ],
    ADMIN_USERS: [ "userList", "user" ],
    GAMERS: [ "gamers", "gamerList", "gamer", "gamerGameDetails", "gamerSection", "redeemTransactions",
      "assets", "gamerTournamentDetail", "leaderboard", "linkedGames" ],
    SETTINGS: [ "settings" ],
    TRANSACTIONS: [ "transactionList", "redeemTxDetail" ],
    ADMIN_TOURNAMENTS_LIST: [ "tournaments", "tournament", "tournamentDetail" ],
  },
  PARTNER_NAV_ENTITY: {
    GAMES: [ "games", "game" ],
    OFFERS: [ "offers", "offer", "offerDetails" ],
    EVENTS: [ "events", "event" ],
    SETTING: [ "settings" ],
    PARTNER_ANALYTICS: [ "analytics", "partnerDetail", "assets" ],
    TOURNAMENTS: [ "tournaments", "tournament", "tournamentDetail" ],
    GAMERS: [ "gamer", "gamerList", "gamerSection", "gamerTournamentDetail", "linkedGames" ],
  },
  FILE_SIZE_UNIT: {
    BYTES: "Bytes",
    KB: "KB",
    MB: "MB",
    GB: "GB",
    TB: "TB",
    EB: "EB",
  },
  ICON_FILE_MAX_SIZE: {
    SIZE_IN_KB: 100,
    SIZE_IN_MB: 2,
  },
  CATEGORY: [
    {
      label: "Game publisher",
      id: "0",
    },
    {
      label: "Game developer",
      id: "1",
    },
    {
      label: "Loyalty rewards program retail",
      id: "2",
    },
  ],
  REASON_QX_JOIN: [
    {
      label: "Loyalty rewards program retail",
      id: "0",
    },
    {
      label:
        "Use the QX portal to define and issue offers, with associated rewards, based upon in-game events or actions",
      id: "1",
    },
    {
      label: "Both",
      id: "2",
    },
  ],
  SOFTWARE_DEVELOPMENT_ABILITY: [
    {
      label: "Our company can do all the software integration work needed",
      id: "0",
    },
    {
      label:
        "Our company can do some of the software integration work, but we'll need help from acQyr eXchange",
      id: "1",
    },
    {
      label:
        "Our company has very few resources to assign to integration; we'd like to hear back from acQyr eXchange as to the help they can provide",
      id: "2",
    },
    {
      label: "I'm not sure if we can do the software integration work",
      id: "3",
    },
    {
      label: "I'm not sure",
      id: "4",
    },
  ],
  API_STATUS: {
    START: "START",
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE",
    END: "END",
  },
  APPLICATION_STATUS: {
    PENDING: "0",
    AMENDMENTS_SUGGESTED: "1",
    DOCUSIGN_PENDING: "2",
    APPROVED: "3",
    REJECTED: "4",
    DISABLED: "5",
    ADD_PARTNER: "-1",
  },
  API_TYPES: {
    UPLOAD_PARTNER_DETAIL: "UPLOAD_PARTNER_DETAIL",
    UPDATE_PARTNER_DETAIL: "UPDATE_PARTNER_DETAIL",
    GET_PARTNER_LOOKUP: "GET_PARTNER_LOOKUP",
    GET_OFFER_LIST: "GET_OFFER_LIST",
    GET_LEADER_BOARD: "GET_LEADER_BOARD",
  },
  BACK_BUTTON_COMPONENTS: [
    "redeemTxDetail",
    "game",
    "event",
    "eventDetails",
    "user",
    "notifications",
  ],
  SECTION_NAME: [
    "General Information",
    "Contact Information",
    "Other Information",
  ],
  ORDER_BY: {
    ASC: "ASC",
    DESC: "DESC",
  },
  ERROR_MESSAGES: {
    EXCEPTION_ERROR: "Something went wrong",
  },
  MONTHS: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  QX_POINT: 0.01,
  ENTITY_NAME: {
    PARTNER_DRAFT: "partner_application_draft",
  },
  DEFAULT_UUID: "00000000-0000-0000-0000-000000000000",
  TIME_INTERVAL_FOR_AUTO_SAVE: 15000,
  NOT_FOUND: 404,
  NOTIFICATION_API_TYPES: {
    GET_PARTNER_NOTIFICATION_LIST: "GET_PARTNER_NOTIFICATION_LIST",
    GET_ADMIN_NOTIFICATION_LIST: "GET_ADMIN_NOTIFICATION_LIST",
    MARK_READ_NOTIFICATIONS: "MARK_READ_NOTIFICATIONS",
    NOTIFICATIONS_SUCCESS: "NOTIFICATIONS_SUCCESS",
  },
  MESSAGE: {
    OFFER_HEADER_DESCRIPTION: "Fill in the fields and submit to save your changes.",
    OFFER_HEADER_UPDATE_DESCRIPTION: "Update in the fields and submit to save your changes.",
    REMOVE_OFFER_DRAFT_SUCCESS_MESSAGE: "Offer draft removed successfully.",
    REMOVE_OFFER_TEMPLATE_SUCCESS_MESSAGE: "Offer template removed successfully.",
    TOKEN_EXPIRE_MESSAGE: "Your session has expired. Please login again.",
    REMOVE_TOURNAMENT_DRAFT_SUCCESS_MESSAGE: "Tournament draft removed successfully.",
    REMOVE_TOURNAMENT_TEMPLATE_SUCCESS_MESSAGE: "Tournament template removed successfully.",
  },
  ADVERTISE_OPTIONS: [
    {
      label: "Only when the offer becomes active",
      value: "1",
    },
    {
      label: "As soon as the offer is approved",
      value: "2",
    },
    {
      label: "On some specific date",
      value: "3",
    },
    {
      label: "No. of days before the offer becomes active",
      value: "4",
    },
  ],
  ADVERTISE_STATUS: {
    OFFER_BECOMES_ACTIVE: "1",
    AFTER_OFFER_APPROVED: "2",
    SOME_SPECIFIC_DATE: "3",
    NO_OF_DAYS: "4",
  },
  DATE_FORMAT: "YYYY-MM-DD",
  TIME_FORMAT: "HH:mm:ss",
  EDITOR_OPTIONS: {
    options: [
      "inline", "blockType",
      "list", "emoji", "remove", "history" ],
    inline: {
      options: [
        "bold", "italic",
        "strikethrough", "monospace" ],
    },
  },
  VIEW_TYPE: {
    LIST: 0,
    GRID: 1,
  },
  TRANSLATION_MESSAGE: {
    USERNAME_ERROR: "1 validation error detected: Value at 'username' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+",
    PASSWORD_WITH_USERNAME_ERROR: "2 validation errors detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$; Value at 'username' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+",
    VERIFY_EMAIL_ERROR: "1 validation error detected: Value null at 'attributeName' failed to satisfy constraint: Member must not be null",
    EMAIL_BLANK_SPACE_ERROR: "1 validation error detected: Value '' at 'toEmail' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+@[\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+",
    PASSWORD_POLICY_ERROR: "Password did not conform with policy: Password not long enough",
    USERNAME_WITH_SPACE_ERROR: "2 validation errors detected: Value at 'userAlias' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+; Value at 'userName' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+",
  },
  AUTH_STATE: {
    AUTHENTICATED: "authenticated",
    SIGN_IN: "signIn",
    SIGN_OUT: "signOut",
  },
  NO_NOTIFICATION_HEADER: [
    "gamer",
    "partnerRequestDetail",
    "gamerSection",
    "gamerGameDetails",
    "redeemTransactions",
    "assets",
    "partnerDetail",
    "gameDetails",
    "tournamentDetail",
    "gamerTournamentDetail",
    "offerDetails",
    "leaderboard",
    "tourament",
    "linkedGames",
    "linkedOffers",
    "offer",
    "tournament",
  ],
  CASHBACK_METHOD: {
    PAYPAL: "PayPal",
    DWOLLA: "Dwolla",
  },
  MIN_MAX: {
    MIN: "min",
    MAX: "max",
  },
  ACTION_TYPES: {
    OTHER_SELECT: 1,
    MULTI_SELECT: 2,
    SLIDER_POINT: 3,
    DATE: 4,
  },
  PARAMS_ARRAY: [
    { type: 4, key: "startDate" },
    { type: 4, key: "endDate" },
    { type: 1, key: "viewType" },
    { type: 1, key: "searchKey" },
    { type: 1, key: "sortBy" },
    { type: 1, key: "sortOrder" },
    { type: 2, key: "assetId" },
    { type: 2, key: "gameIds" },
    { type: 2, key: "assetName" },
    { type: 2, key: "gamerName" },
    { type: 2, key: "gameName" },
    { type: 2, key: "gamerPlans" },
    { type: 2, key: "offerNames" },
    { type: 2, key: "items" },
    { type: 2, key: "modes" },
    { type: 2, key: "status" },
    { type: 2, key: "transactionFor" },
    { type: 2, key: "gamerNames" },
    { type: 2, key: "offerStatus" },
    { type: 2, key: "partnerNames" },
    { type: 2, key: "gameNames" },
    { type: 3, key: "minQxPoint" },
    { type: 3, key: "maxQxPoint" },
    { type: 3, key: "minQuantity" },
    { type: 3, key: "maxQuantity" },
    { type: 3, key: "minUnitPrice" },
    { type: 3, key: "maxUnitPrice" },
    { type: 3, key: "minGamerPlace" },
    { type: 3, key: "maxGamerPlace" },
    { type: 3, key: "minLinkedGames" },
    { type: 3, key: "maxLinkedGames" },
  ],
  VIEW: {
    LIST: "LIST",
    GRID: "GRID",
  },
  QUERY_PARAMS: [ "linkedGameCount", "minLinkedGames", "maxLinkedGames", "maxAcqyrCashBalance",
    "minAcqyrCashBalance", "tab", "searchKey", "sortBy", "sortOrder", "minRedeemPoints",
    "maxRedeemPoints", "minNetAmount", "maxNetAmount", "startDateRedmeed", "endDateRedmeed",
    "startDateTransaction", "endDateTransaction", "maxExRedeemPoints", "minExRedeemPoints" ],

  FILTERS_NAME: {
    PARTNER: [ "partnerNames", "partnerEmails", "primaryContacts", "primaryPhones", "partnerStatus" ],
    GAMES: [ "gameList", "partnerList", "publishedOn", "statusList" ],
    OFFERS: [ "offerList", "gameList", "partnerList", "statusList", "gameOfferList", "gameTournamentList", "optInTypes" ],
    EVENTS: [ "eventIdsList", "eventNameList", "unitList", "partnerList" ],
    ADMIN_USERS: [ "fullNames", "usernames", "adminEmails", "adminStatus" ],
    ADMIN_GAMERS: [ "fullNames", "minLinkedGames", "maxLinkedGames", "minAcqyrCashBalance", "maxAcqyrCashBalance", "minQxPoint", "maxQxPoint", "planNames" ],
    EXCHANGE_TRANSACTION: [ "gameNames", "assetNames", "exchGamerNames", "minExRedeemPoints", "maxExRedeemPoints", "startDateTransaction", "endDateTransaction" ],
    REDEEM_TRANSACTION: [ "paymentVendors", "minRedeemPoints", "maxRedeemPoints", "transactionStatus", "minNetAmount",
      "maxNetAmount", "startDateRedmeed", "endDateRedmeed", "gamerNames" ],
    PARTNER_EVENTS: [ "eventIdLists", "eventIds", "unitIds", "partnerIds" ],
    PARTNER_OFFERS: [ "offerIds", "gameIds", "startDate", "endDate", "statusIds", "tournamentIds", "minGamerPlace", "maxGamerPlace" ],
    PARTNER_GAMES: [ "gameIds", "statusIds" ],
    ANALYTICS_EXCHANGE: [ "gameIds", "assetName", "gamerName", "minQxPoint", "maxQxPoint", "startDate", "endDate" ],
    GAMER_DETAIL: [ "gameIds", "startDate", "endDate" ],
    GAMER_EXCHANGE_LIST: [ "gameNames", "assetNames", "minExRedeemPoints", "maxExRedeemPoints", "startDateTransaction", "endDateTransaction" ],
    GAMER_REDEEM_TRANSACTIONS: [ "qxPtsRdmMinPoint", "qxPtsRdmMaxPoint", "status", "startDateRedeemed", "endDateRedeemed", "netRdmAmtMin", "netRdmAmtMax" ],
    GAMER_LINKED_OFFERS: [ "offerNames", "gameNames", "startDate", "endDate", "optInTypes", "partnerNames", "minQxPoint", "maxQxPoint",
      "minGamerPlace", "maxGamerPlace", "offerStatus" ],
    GAMER_ASSETS: [ "assetName", "assetId", "gameIds", "minQxPoint", "maxQxPoint" ],
    PARTNER_LINKED_GAMERS: [ "fullNames", "minLinkedGames", "maxLinkedGames", "minAcqyrCashBalance", "maxAcqyrCashBalance" ],
    ANALYTICS_GAMERS: [ "gameName", "gamerName", "gamerPlans", "minLinkedGames", "maxLinkedGames", "gamerNames" ],
    ANALYTICS_ASSETS: [ "assetId", "assetName", "minQuantity", "maxQuantity", "minUnitPrice", "maxUnitPrice" ],
    ADMIN_TOURNAMENTS: [ "tournamentList", "partnerList", "gameList", "status", "startDate", "endDate" ],
    ADMIN_GAMER_TOURNAMENTS: [ "offerNames", "gameNames", "place", "startDate", "endDate", "optInTypes" ],
    PARTNER_GAMER_ASSETS: [ "assetNames", "assetIds", "gameNames", "minQxPoint", "maxQxPoint" ],
    PARTNER_GAMER_EXCHANGE_LIST: [ "gameNames", "assetNames", "minQxPoint", "maxQxPoint", "startDate", "endDate" ],
    PARTNER_GAMER_LINKED_OFFERS: [ "offerNames", "gameNames", "startDate", "endDate", "progress" ],
    ANALYTICS_REWARD_TXN: [ "gameIds", "gameName", "gamerName", "minQxPoint", "maxQxPoint", "startDate", "endDate",
      "offerNames", "minGamerPlace", "maxGamerPlace", "assetName", "gamerPlans" ],
    ADMIN_GAMER_TXN_HISTORY: [ "items", "modes", "startDate", "endDate", "status" ],
  },
  DATE_TIME_FORMAT: {
    DATE: "DD MMM YYYY",
    TIME: "hh:mm A",
    FULL_DATE: "YYYY-MM-DDThh:mm:ssZ",
  },
  PROFILE_IMAGE_ALT_TEXT: {
    PARTNER_IMAGE: "Partner Image",
    GAMER_IMAGE: "Gamer Image",
  },
  OPT_IN_STATUS: {
    YES: "Yes",
    NO: "No",
    FEE: "Fee",
  },
  TOOLTIP_CLASS: {
    SHOW: "event-tooltip",
    HIDE: "hide-tooltip",
  },
  INTERVAL_TYPE: {
    ALL: "All",
    WEEKLY: "Weekly",
    MONTHLY: "Monthly",
    YEARLY: "Yearly",
  },
  GRAPH_FILTERS: [
    { value: "All", label: "All" },
    { value: "Weekly", label: "Weekly" },
    { value: "Monthly", label: "Monthly" },
    { value: "Yearly", label: "Yearly" },
  ],
  GRAPH_TYPE: {
    QX_MEMBERS_LINE_GRAPH: "QxMembersLineGraph",
    GAMES_LINE_GRAPH: "gamesLineGraph",
    EXCHANGE_TXN_LINE_GRAPH: "exchangeTxnLineGraph",
  },
  NAVIGATION_TYPE: {
    NEXT: "Next",
    PREV: "Prev",
  },
  GRAPH_UNIT: {
    DOLLAR: "$",
  },
  NUMBER_FORMATTING: {
    PARTNER_COST_DECIMAL_PLACES: 3,
    TRANSACTION_AMOUNT_DECIMAL_PLACES: 2,
  },
  BRANDING: {
    APP_SHORT_NAME: "VQ",
    APP_NAME: "Victory Quest",
  },
  BUSINESS_ENTITY: {
    OFFER: "offer",
    TOURNAMENT: "tournament",
  },
};

export default constant;
