import PropTypes from "prop-types";
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import constant from "../../../../util/constant";
import { currencyFormat } from "../../../../util/helpers";
import { ScrollPage } from "../../../shared/customScrollBar";
import TableHeaderRow from "../../../shared/tableHeadingRow";

const { NUMBER_FORMATTING } = constant;

const TxnHistoryListing = ( {
  transactionHitoryList = [],
  memoizedTableConfig,
  memoizedHandleClick,
  showFilter,
} ) => {
  return (
    <>
      <div className="table-head">
        <div className="table-row pos-relative">
          <TableHeaderRow
            configData={memoizedTableConfig}
            onAscOrder={memoizedHandleClick}
            onDescOrder={memoizedHandleClick}
          />
        </div>
      </div>
      <Scrollbars renderThumbVertical={ScrollPage}
        className={`custom-scroll-height max ${ !showFilter ? "" : "full-height" }`}
      >
        <div className="table-body no-scrollbar" >
          {Boolean( transactionHitoryList.length ) && transactionHitoryList.map( ( txn, index ) => (
            <div className="table-row" key={index}>
              <div className="td">{txn.transactionDate}</div>
              <div className="td">{txn.item || "N/A"}</div>
              <div className="td">
                <span className='text-green'>
                  { currencyFormat( {
                    data: txn.amount,
                    minimumFractionDigits: NUMBER_FORMATTING.TRANSACTION_AMOUNT_DECIMAL_PLACES,
                  } ) }
                </span>
              </div>
              <div className="td">
                {txn.mode || "N/A"}
              </div>
              <div className="td">
                <span className={`badge ${ txn.statusTitle.class }`}>
                  {txn.statusTitle.title}
                </span>
              </div>
              <div className="td">
                { txn.isRecurring ? `${ txn.validFromDate } to ${ txn.validToDate }` : "N/A"}
              </div>
            </div>
          ) )}
          {!transactionHitoryList.length && (
            <div className='no-record' data-testid='no-record-found'>
                No Transaction History Found
            </div>
          )}
        </div>
      </Scrollbars>
    </>
  );
};

TxnHistoryListing.propTypes = {
  transactionHitoryList: PropTypes.array,
  memoizedTableConfig: PropTypes.object,
  memoizedHandleClick: PropTypes.func,
  showFilter: PropTypes.bool,
};

export default TxnHistoryListing;

