import { API_URL } from "../util/apiPaths";
import { request } from "./api.js";

export const getPartnerGamerDetail = ( param ) => {
  return request( "GET", `${ API_URL.PARTNER_GAMERS }/${ param }` );
};

export const getPartnerGamerTournaments = ( param ) => {
  return request( "GET", `${ API_URL.PARTNER_GAMERS }/${ param }/tournaments` );
};

export const getPartnerGamerAssets = ( gamerId ) => {
  return request( "GET", `${ API_URL.PARTNER_GAMERS }/${ gamerId }/exchange/assets` );
};

export const getPartnerGamerAllLinkedGames = ( gamerId, param ) => {
  return request( "GET", `${ API_URL.PARTNER_GAMERS }/${ gamerId }/games`, param );
};

export const getPartnerGamerLinkedGameDetail = ( gameId, param ) => {
  return request( "GET", `${ API_URL.PARTNER_GAMES }/${ gameId }`, param );
};

export const getPartnerGamerLinkedGameAssets = ( param ) => {
  return request( "GET", `${ API_URL.PARTNER_GAMERS }/${ param.gamerId }/${ param.gameId }/assets` );
};

export const getPartnerGamerLinkedGameOffers = ( gamerId, param ) => {
  return request( "GET", `${ API_URL.PARTNER_GAMERS }/${ gamerId }/offers`, param );
};

export const getPartnerGamerExchangeTransaction = ( gamerId, param ) => {
  return request( "GET", `${ API_URL.PARTNER_GAMERS }/${ gamerId }/exchange/tx`, param );
};
