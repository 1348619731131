import { sortBy } from "lodash";
import moment from "moment";
import Enums from "../util/Enums";
import constant from "../util/constant";

const {
  LEADER_BOARD_TOP_PLACES: {
    FIRST_PLACE,
    SECOND_PLACE,
    THIRD_PLACE,
    FIRST_ROW_MIN_PLACE,
    FIRST_ROW_MAX_PLACE,
    SECOND_ROW_MIN_PLACE,
    SECOND_ROW_MAX_PLACE,
  },
} = Enums;
const { DATE_TIME_FORMAT } = constant;

export class Leaderboard {
  findRank = ( list, requestedRank ) =>
    list.find( ranking => ranking.rank === requestedRank ) || null;

  findDateAndTime = ( dateObj, format ) => moment( new Date( dateObj ) ).format( format );

  sortByRanking = list => sortBy( list, ranking => ranking.rank );

  constructor( obj ) {
    this.tournamentId = obj.tournamentId;
    this.tournamentName = obj.tournamentName;
    this.tournamentEndDate = this.findDateAndTime( obj.tournamentEndDate, DATE_TIME_FORMAT.DATE );
    this.tournamentEndTime = this.findDateAndTime( obj.tournamentEndDate, DATE_TIME_FORMAT.TIME );
    this.participants = obj.participants;
    this.sortByRanking( obj.leaderboardRanking );
    this.firstPlace = this.findRank( obj.leaderboardRanking, FIRST_PLACE );
    this.secondPlace = this.findRank( obj.leaderboardRanking, SECOND_PLACE );
    this.thirdPlace = this.findRank( obj.leaderboardRanking, THIRD_PLACE );
    this.firstRowPlaces = this.sortByRanking(
      obj.leaderboardRanking.filter(
        ranking => ranking.rank > FIRST_ROW_MIN_PLACE && ranking.rank < FIRST_ROW_MAX_PLACE )
    );
    this.secondRowPlaces = this.sortByRanking(
      obj.leaderboardRanking.filter(
        ranking => ranking.rank > SECOND_ROW_MIN_PLACE && ranking.rank < SECOND_ROW_MAX_PLACE )
    );
    this.participantsList = obj.leaderboardRanking;
  }
}
