import { AdminFilterList } from "../../entities/adminFilterList";
import {
  getAdminFilterList
} from "../../services/adminServices";
import adminConstant from "../../util/adminConstant";
import {
  ADMIN_FILTER_LIST_SUCCESS,
  ADMIN_FILTER_LIST_FAILURE,
  ADMIN_FILTER_LIST_API_REQUEST,
  ADMIN_FILTER_LIST_API_END
} from "../actionTypes";

const { ADMIN_API_TYPES } = adminConstant;

export const getAdminFilterListAction = param => async ( dispatch ) => {
  try {
    dispatch( {
      type: ADMIN_FILTER_LIST_API_REQUEST,
      payload: ADMIN_API_TYPES.GET_ADMIN_FILTER_LIST,
    } );

    const response = await getAdminFilterList( param );

    if( response ) {
      const data = new AdminFilterList( response.data );

      dispatch( {
        type: ADMIN_FILTER_LIST_SUCCESS,
        payload: data,
      } );
    }
    else {
      dispatch( { type: ADMIN_FILTER_LIST_FAILURE, payload: response.message || "" } );
    }
  }
  catch ( error ) {
    dispatch( { type: ADMIN_FILTER_LIST_FAILURE, payload: error } );
  }
  finally {
    dispatch( { type: ADMIN_FILTER_LIST_API_END } );
  }
};
