import PropTypes from "prop-types";
import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import analyticsConstant from "../../../util/analyticsConstant";
import RangeDatePicker from "../datePicker/rangeDatePicker";
import MultiSelectCheckbox from "../multiSelectCheckbox";

const { ANALYTICS_TAB } = analyticsConstant;

const AnalyticsTxnFilters = ( {
  tab,
  values,
  handleChange,
  addQuery,
  gamerNames,
  isGameId,
  locationState,
  onSelectFilterOptions,
  multiSelectedItems,
  clearAllSelectedOptions,
} ) => {
  return (
    <>
      <Col>
        <Form.Group className='custom-datepicker range-picker'>
          <RangeDatePicker
            label={`Date of ${ [ ANALYTICS_TAB.EXCHANGE_TRANSACTION, ANALYTICS_TAB.OPT_IN_TXN ].includes( tab ) ? "Transaction" : "Achievement" }`}
            values={values}
            addQuery={addQuery}
            handleChange={handleChange}
            param={{ key1: "startDate", key2: "endDate", key3: "", key4: "", page: "page" }}
            isExtraParam={isGameId}
            locationState={locationState}
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group className='custom-multiselect'>
          <Form.Label>Gamer Name</Form.Label>
          {<MultiSelectCheckbox
            multiOptionsList={gamerNames}
            handleSelectChange={onSelectFilterOptions[ 2 ]}
            multiSelectedItems={multiSelectedItems[ 2 ]}
            handleClearMultiSelect={() => clearAllSelectedOptions( "gamerName" )}/>}
        </Form.Group>
      </Col>
    </>
  );
};

AnalyticsTxnFilters.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  addQuery: PropTypes.func,
  gamerNames: PropTypes.array,
  isGameId: PropTypes.bool,
  locationState: PropTypes.string,
  onSelectFilterOptions: PropTypes.func,
  multiSelectedItems: PropTypes.array,
  clearAllSelectedOptions: PropTypes.func,
  tab: PropTypes.string,
};

export default AnalyticsTxnFilters;
