import constant from "../../util/constant";
import {
  GET_PARTNER_DETAIL_SUCCESS,
  GET_PARTNER_DETAIL_FAILURE,
  GET_PARTNER_DETAIL_REQUEST,
  PARTNER_API_REQUEST,
  UPLOAD_PARTNER_DETAIL_SUCCESS,
  UPLOAD_PARTNER_DETAIL_FAILURE,
  PARTNER_API_END,
  UPDATE_PARTNER_DETAIL_FAILURE,
  UPDATE_PARTNER_DETAIL_SUCCESS,
  UPDATE_PARTNER_PROFILE_SUCCESS,
  UPDATE_PARTNER_PROFILE_FAILURE,
  READ_NOTIFICATIONS_SUCCESS
} from "../actionTypes";

const { API_STATUS } = constant;

const DEFAULT_STATE = {
  loading: false,
  message: "",
  status: "",
  apiType: "",
  partnerDetail: {},
};

const partner = ( state = DEFAULT_STATE, action = "" ) => {
  switch ( action.type ) {
    case GET_PARTNER_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PARTNER_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
      };
    case PARTNER_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case GET_PARTNER_DETAIL_SUCCESS:
      return {
        ...state,
        partnerDetail: action.payload,
        loading: false,
      };
    case GET_PARTNER_DETAIL_FAILURE:
      return {
        ...state,
        partnerDetail: {},
        loading: false,
      };
    case UPLOAD_PARTNER_DETAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        partnerDetail: {
          ...state.partnerDetail,
          applicationStatus: "0",
        },
      };
    case UPDATE_PARTNER_DETAIL_SUCCESS:
    case UPDATE_PARTNER_PROFILE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        partnerDetail: action.payload.partnerDetail,
        message: action.payload.message,
      };
    case UPDATE_PARTNER_PROFILE_FAILURE:
    case UPDATE_PARTNER_DETAIL_FAILURE:
    case UPLOAD_PARTNER_DETAIL_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
      };
    case READ_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        partnerDetail: {
          ...state.partnerDetail,
          unreadNotificationsCount: 0,
        },
      };
    default:
      return state;
  }
};

export default partner;
