import React, { useEffect, useState } from "react";
import { Col, Modal, Row, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory, Link, useLocation } from "react-router-dom";
import { getGamerDetailAction, clearGamerOffersPreviousState, clearGamerRedeemTransactionsPreviousState } from "../../../store/actions/adminGamer";
import { resetExchangeTransactionFilter } from "../../../store/actions/adminTransaction";
import adminUserConstant from "../../../util/adminUserConstant";
import constant from "../../../util/constant";
import { formatNumberAsPerLocale, currencyFormat, multipleArrayExist, getBackNavigationRoute } from "../../../util/helpers";
import {
  gamesIcon,
  transactionsIcon,
  offerIcon,
  eventsIcon,
  tournamentIcon
} from "../../../util/icons";
import BackButton from "../../shared/backButton";
import ProfileImage from "../../shared/profileImage";
import ToolTipError from "./toolTipError";

const { ROUTES, BRANDING: { APP_SHORT_NAME } } = constant;
const { ADMIN_PERMISSIONS } = adminUserConstant;

const GamerSection = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { gamerId: URLString } = useParams();
  const { 0: gamerId } = String( URLString ).split( "?" );
  const paramsQuery = new URLSearchParams( window.location.search );
  const fromScreen = paramsQuery.get( "from" );
  const backScreens = [ "partnersLinkedGamers", "gamesLinkedGamers", "gamers" ];
  const { gamerDetail, usdPerQxPoint, adminDetail } = useSelector( state => ( {
    gamerDetail: state.adminGamer.gamerDetail,
    usdPerQxPoint: state.adminDetail.data.usdPerQxPoint,
    adminDetail: state.adminDetail.data,
  } ) );

  useEffect( () => {
    if( gamerId !== "0" ) {
      dispatch( getGamerDetailAction( gamerId ) );
    }
  }, [ gamerId ] );

  const [ isModal, setModal ] = useState( false );

  const closeModal = () => {
    setModal( false );
  };

  const viewAllGames = () => {
    setModal( true );
  };

  const handleBackNavigation = () => {
    return getBackNavigationRoute( ROUTES.GAMER_LIST, location, backScreens );
  };

  const storeGamerDetailPageState = () => {
    return [ ...location.state, ...[ { from: "adminGamerDetail", path: location.pathname, state: location.search } ] ];
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="main-right-wrapper">
          <BackButton
            backLink={handleBackNavigation()}
            state={fromScreen === "gamers" ? "" : location.state}
          />
          <div className="page-head-wrapper">
            <div className="d-flex align-items-center">
              <ProfileImage
                profileImage={gamerDetail?.profileImage}
                isGamerDetail={true}
              />
              <div className="d-flex flex-column">
                <div className="text">
                  <span className={"badge badge-primary"}>{gamerDetail.plan || "N/A"}</span>
                </div>
                <div className="profile-title">
                  {gamerDetail.gamerFullName}{" "}
                </div>
                <div className="profile-text">{gamerDetail.email}</div>
                <div className="profile-text mb-0">{gamerDetail.phone}</div>
              </div>
            </div>
            <div className="right-more-content">
              <div className="text-gamer-bold">
                <ToolTipError gameNames={gamerDetail.serverErrorsGameName} />
                {`${ formatNumberAsPerLocale(
                  gamerDetail.acqyrCashBalance || 0
                ) } ${ APP_SHORT_NAME } Points (${ currencyFormat( {
                  data: gamerDetail.acqyrCashBalance * usdPerQxPoint || 0,
                } ) })`}
              </div>
              {gamerDetail.activeCashbackMethod?.cashBackMethod && (
                <div className="content-with-image">
                  <span>Cashback:</span>
                  <img
                    src={
                      gamerDetail.activeCashbackMethod?.cashBackMethod.iconUrl
                    }
                    alt="cashback"
                  />{" "}
                  {gamerDetail.defaultCashbackName}
                </div>
              )}
              {gamerDetail?.userCashbackMethods?.length > 1 && (
                <Link
                  onClick={() => viewAllGames()}
                  className="link"
                  title="More"
                >
                  Show All
                </Link>
              )}
            </div>
          </div>
          <Row>
            <Col sm={4}>
              <div className="gamer-card">
                <div className="card-content">
                  <div className="card-title">
                    {`${ formatNumberAsPerLocale( gamerDetail.linkedGamesCount || 0 ) }`}
                  </div>
                  <div className="card-text">Linked Games</div>
                  <span
                    className="link cursor-pointer"
                    title="Show All"
                    onClick={() => {
                      history.push( {
                        pathname: `${ ROUTES.GAMER_DETAIL.replace(
                          ":gamerId",
                          gamerId
                        ) }`,
                        state: storeGamerDetailPageState(),
                      } );
                    }}
                  >
                    Show All
                  </span>
                  <img src={gamesIcon} alt="link-icon" className="card-icon" />
                </div>
              </div>
            </Col>
            { ( multipleArrayExist( adminDetail?.permissions,
              [ ADMIN_PERMISSIONS.MANAGE_REDEEM_REQUESTS,
                ADMIN_PERMISSIONS.VIEW_TRANSACTIONS ] ) ) &&
            <>
              <Col sm={4}>
                <div className="gamer-card">
                  <div className="card-content">
                    <div className="card-title">
                      {`${ formatNumberAsPerLocale( gamerDetail.exchangeTransactionsCount || 0 ) }`}
                    </div>
                    <div className="card-text">Exchange Transactions</div>
                    <div
                      to="#"
                      className="link cursor-pointer"
                      onClick={() => {
                        dispatch( resetExchangeTransactionFilter() );
                        history.push( {
                          pathname: `${ ROUTES.GAMER_EXCHANGE_TRANSACTION.replace(
                            ":gamerId",
                            gamerId
                          ) }`,
                          state: storeGamerDetailPageState(),
                        } );
                      }}
                    >
                    Show All
                    </div>
                    <img
                      src={transactionsIcon}
                      alt="link-icon"
                      className="card-icon"
                    />
                  </div>
                </div>
              </Col>
              <Col sm={4}>
                <div className="gamer-card">
                  <div className="card-content">
                    <div className="card-title">
                      {`${ formatNumberAsPerLocale( gamerDetail.redeemTransactionsCount || 0 ) }`}
                    </div>
                    <div className="card-text">Redeem Transactions</div>
                    <span
                      className="link cursor-pointer"
                      title="Show All"
                      onClick={() => {
                        dispatch( clearGamerRedeemTransactionsPreviousState() );
                        history.push( {
                          pathname: ROUTES.REDEEM_TRANSACTIONS.replace(
                            ":gamerId",
                            gamerId
                          ),
                          state: storeGamerDetailPageState(),
                        } );
                      }}
                    >
                    Show All
                    </span>
                    <img
                      src={transactionsIcon}
                      alt="link-icon"
                      className="card-icon"
                    />
                  </div>
                </div>
              </Col></>
            }
            <Col sm={4}>
              <div className="gamer-card">
                <div className="card-content">
                  <div className="card-title">
                    {`${ formatNumberAsPerLocale( gamerDetail.linkedOffersCount || 0 ) }`}
                  </div>
                  <div className="card-text">Offers</div>
                  <div
                    className="link cursor-pointer"
                    onClick={() => {
                      dispatch( clearGamerOffersPreviousState() );
                      history.push( {
                        pathname: `${ ROUTES.GAMER_OFFER_LIST.replace(
                          ":gamerId",
                          gamerId
                        ) }?tab=offers`,
                        state: storeGamerDetailPageState(),
                      } );
                    }}
                  >
                    Show All
                  </div>
                  <img src={offerIcon} alt="link-icon" className="card-icon" />
                </div>
              </div>
            </Col>
            <Col sm={4}>
              <div className="gamer-card">
                <div className="card-content">
                  <div className="card-title">{`${ formatNumberAsPerLocale(
                    gamerDetail.assetsCount || 0
                  ) }`}</div>
                  <div className="card-text">
                    Assets
                    <ToolTipError gameNames={gamerDetail.serverErrorsGameName} />
                  </div>
                  <span
                    className="link cursor-pointer"
                    title="Show All"
                    onClick={() => {
                      history.push( {
                        pathname: ROUTES.GAMER_ASSETS.replace(
                          ":gamerId",
                          gamerId
                        ),
                        state: storeGamerDetailPageState(),
                      } );
                    }}
                  >
                    Show All
                  </span>
                  <img src={eventsIcon} alt="link-icon" className="card-icon" />
                </div>
              </div>
            </Col>
            <Col sm={4}>
              <div className="gamer-card">
                <div className="card-content">
                  <div className="card-title">
                    {`${ formatNumberAsPerLocale( gamerDetail?.linkedTournamentsCount ?? 0 ) }`}
                  </div>
                  <div className="card-text">
                    Tournaments
                  </div>
                  <span
                    className="link cursor-pointer"
                    title="Show All"
                    onClick={() => {
                      history.push( {
                        pathname: `${ ROUTES.ADMIN_GAMER_TOURNAMENTS.replace(
                          ":gamerId",
                          gamerId
                        ) }?tab=tournaments`,
                        state: storeGamerDetailPageState(),
                      } );
                    }}
                  >
                    Show All
                  </span>
                  <img src={tournamentIcon} alt="link-icon" className="card-icon" />
                </div>
              </div>
            </Col>
            <Col sm={4}>
              <div className="gamer-card">
                <div className="card-content">
                  <div className="card-title">
                    {`${ formatNumberAsPerLocale( gamerDetail.transactionHistoryCount || 0 ) }`}
                  </div>
                  <div className="card-text">Billing Transactions</div>
                  <span
                    className="link cursor-pointer"
                    title="Show All"
                    onClick={() => {
                      history.push( {
                        pathname: ROUTES.ADMIN_GAMER_TRANSACTION_HISTORY.replace(
                          ":gamerId",
                          gamerId
                        ),
                        state: storeGamerDetailPageState(),
                      } );
                    }}
                  >
                  Show All
                  </span>
                  <img
                    src={transactionsIcon}
                    alt="link-icon"
                    className="card-icon"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        show={isModal}
        onHide={closeModal}
        centered
        className="gamer-cashback-modal"
      >
        <Modal.Header>
          <h2 className="modal-heading">Cashback Methods</h2>
        </Modal.Header>
        <Modal.Body>
          <div className="gamer-game-list">
            {Boolean( gamerDetail?.userCashbackMethods?.length ) &&
              gamerDetail.userCashbackMethods.map( ( data, index ) => (
                <div className="list-items" key={index}>
                  <img
                    src={data.iconUrl}
                    className="game-icon"
                    alt="Cashback icon"
                  />
                  <p className="game-name">{data.cashbackName}</p>
                </div>
              ) )}
          </div>
          <div className="button-container">
            <Button
              variant="primary"
              className="btn-md"
              onClick={() => closeModal()}
            >
              Done
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GamerSection;
