import { convertDate, formatNumberAsPerLocale, currencyFormat } from "../util/helpers";

export class AdminRedeemTransactionListDetail {
  constructor( obj ) {
    this.transactionId = obj.transactionId;
    this.redeemRequestId = obj.redeemRequestId || "N/A";
    this.fullName = obj.fullName || "N/A";
    this.acQyrCashSold = formatNumberAsPerLocale( obj.acQyrCashSold, 0 );
    this.redeemedFee = obj.redeemedFee || 0;
    this.amount = obj.amount || 0;
    this.status = obj.status || "Pending";
    this.whenRequested = convertDate( obj.whenRequested ) || "";
    this.cashbackName = obj?.cashbackName || "";
    this.iconUrl = obj?.iconUrl || "";
    this.netRedeemAmount = currencyFormat( { data: obj?.netRedeemAmount || 0 } );
  }
}
