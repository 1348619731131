import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { useAddQuery, useForm, useFilter } from "../../../../hooks";
import { getPartnerGamerAssetsAction, getPartnerGamerDetailAction } from "../../../../store/actions/partnerGamer";
import constant from "../../../../util/constant";
import { useSetUrlParams, checkFilterApplied } from "../../../../util/helpers";
import partnerGamerConstant from "../../../../util/partnerGamerConstant";
import { partnerGamerAssetsTableConfigs } from "../../../../util/tableConstant";
import AssetsList from "../../../admin/gamer/assetList";
import AssetsFilters from "./assetsFilters";
import AssetsHeader from "./assetsHeader";

const { API_STATUS, FILTERS_NAME, ORDER_BY } = constant;
const { API_TYPES } = partnerGamerConstant;

const PartnerGamerAssets = () => {
  const { addQuery } = useAddQuery();
  const dispatch = useDispatch();
  const { gamerId } = useParams();
  const paramsQuery = new URLSearchParams( window.location.search );
  const { setPureData, filteredData, filterData } = useFilter();
  const location = useLocation();
  const {
    apiType,
    assetNames,
    assetIds,
    gamerDetail,
    gameNames,
    partnerGamerAssetList,
    status,
    qxPointRange,
  } = useSelector( state => ( {
    gamerDetail: state.partnerGamer.gamerDetail,
    status: state.partnerGamer.status,
    apiType: state.partnerGamer.apiType,
    partnerGamerAssetList: state.partnerGamer.assets.assets,
    gameNames: state.partnerGamer.assets.gameNames,
    assetNames: state.partnerGamer.assets.assetNames,
    assetIds: state.partnerGamer.assets.assetIds,
    qxPointRange: state.partnerGamer.assets.qxPointRange,
  } ) );
  const sliderMinMaxObj = {
    minQxPoint: qxPointRange.min,
    maxQxPoint: qxPointRange.max,
  };
  const isFilterApplied = checkFilterApplied( window.location.search, FILTERS_NAME.PARTNER_GAMER_ASSETS, sliderMinMaxObj );

  useEffect( () => {
    dispatch( getPartnerGamerAssetsAction( gamerId ) );
  }, [] );

  const { values, handleChange } = useForm(
    {
      searchKey: "",
      sortBy: "assetName",
      sortOrder: ORDER_BY.ASC,
      assetIds: [],
      assetNames: [],
      gameNames: [],
      minQxPoint: 0,
      maxQxPoint: 0,
      compareKeyArray: [ "QXPoints" ],
    },
    {}
  );
  const requestParams = Object.assign(
    {
      sortOrder: values.sortOrder,
      searchCol: [ "assetName", "gameName" ],
      assetIds: values.assetIds?.map( value => value.value ) || [],
      assetNames: values.assetNames?.map( value => value.value ) || [],
      gameNames: values.gameNames?.map( value => value.value ) || [],
      searchKey: values.searchKey,
      sortBy: values.sortBy,
      minQxPoint: values.minQxPoint,
      maxQxPoint: values.maxQxPoint,
      compareKeyArray: values.compareKeyArray,
    }
  );

  const updateStateFromUrl = () => {
    if( paramsQuery.get( "sortBy" ) && paramsQuery.get( "sortOrder" ) ) {
      handleChange( "sortBy", paramsQuery.get( "sortBy" ) );
      handleChange( "sortOrder", paramsQuery.get( "sortOrder" ) );
    }

    if( paramsQuery.get( "searchKey" ) ) {
      handleChange( "searchKey", paramsQuery.get( "searchKey" ) );
    }

    if( paramsQuery.get( "assetNames" ) ) {
      handleChange( "assetNames", JSON.parse( paramsQuery.get( "assetNames" ) ) );
    }

    if( paramsQuery.get( "assetIds" ) ) {
      handleChange( "assetIds", JSON.parse( paramsQuery.get( "assetIds" ) ) );
    }

    if( paramsQuery.get( "gameNames" ) ) {
      handleChange( "gameNames", JSON.parse( paramsQuery.get( "gameNames" ) ) );
    }

    if( paramsQuery.get( "minQxPoint" ) ) {
      handleChange( "minQxPoint", JSON.parse( paramsQuery.get( "minQxPoint" ) ) );
    }

    if( paramsQuery.get( "maxQxPoint" ) ) {
      handleChange( "maxQxPoint", JSON.parse( paramsQuery.get( "maxQxPoint" ) ) );
    }
  };

  const handleSorting = ( sortBy, sortingType ) => {
    handleChange( "sortBy", sortBy );
    handleChange( "sortOrder", sortingType );
    addQuery( useSetUrlParams( "sortBy", sortBy ), location.state );
    addQuery( useSetUrlParams( "sortOrder", sortingType ), location.state );
  };

  const memoizedTableConfig = useMemo( () => partnerGamerAssetsTableConfigs, [] );
  const memoizedHandleClick = useCallback( ( sortBy, orderType ) => handleSorting( sortBy, orderType ), [] );

  useEffect( () => {
    if(
      ( status === API_STATUS.SUCCESS ) &&
      ( apiType === API_TYPES.GET_GAMER_ASSETS )
    ) {
      dispatch( getPartnerGamerDetailAction( gamerId ) );
      handleChange( "minQxPoint", qxPointRange.min );
      handleChange( "maxQxPoint", qxPointRange.max );
      setPureData( partnerGamerAssetList );
      updateStateFromUrl();
      filterData( requestParams );
    }
  }, [ status, apiType ] );
  useEffect( () => {
    filterData( requestParams );
  }, [
    values.sortOrder,
    values.sortBy,
    values.searchKey,
    values.assetIds,
    values.assetNames,
    values.gameNames,
    values.minQxPoint,
    values.maxQxPoint,
  ] );

  const [ showFilter, setShowFilter ] = useState( false );

  return (
    <div className="main-wrapper">
      <div className="main-right-wrapper">
        <AssetsHeader
          gamerDetail={gamerDetail}
          gamerId={gamerId}
          handleChange={handleChange}
          isFilterApplied={isFilterApplied}
          setShowFilter={setShowFilter}
          showFilter={showFilter}
          values={values}
          location={location}
        />
        <AssetsFilters
          assetNames={assetNames}
          assetIds={assetIds}
          filteredData={filteredData}
          handleChange={handleChange}
          gameNames={gameNames}
          qxPointRange={qxPointRange}
          showFilter={showFilter}
          values={values}
          location={location}
        />
        <AssetsList
          assets={filteredData}
          gamerDetail={gamerDetail}
          showFilter={showFilter}
          memoizedTableConfig={memoizedTableConfig}
          memoizedHandleClick={memoizedHandleClick}
        />
      </div>
    </div>
  );
};

export default PartnerGamerAssets;
