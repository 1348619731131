import PropTypes from "prop-types";
import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useAddQuery } from "../../../hooks";
import { useSetUrlParams } from "../../../util/helpers";
import offerConstant from "../../../util/offerConstant";
import CustomDatePicker from "../../shared/customDatePicker";
import MultiSelectCheckbox from "../../shared/multiSelectCheckbox";

const { TOURNAMENT_STATUS_DROPDOWN, OPT_IN_STATUS_DROPDOWN } = offerConstant;

const TounamentFilters = ( {
  values,
  handleChange,
  showFilter,
  tournamentList,
  partnerList,
  gameTournamentList,
  handleSelectChange,
  multiSelectedItems,
  handleClearMultiSelect,
} ) => {
  const { addQuery } = useAddQuery();

  return (
    <div className={`filter-tabs-wrapper ${ !showFilter ? "" : "remove-filter" }`}>
      <div className='filter-boxes offer-filter mr-0'>
        <Row>
          <Col>
            <Form.Group className='custom-multiselect mb-20' data-testid="full-name">
              <Form.Label>Tournament Name</Form.Label>
              <MultiSelectCheckbox
                multiOptionsList={tournamentList}
                handleSelectChange={handleSelectChange[ 0 ]}
                multiSelectedItems={multiSelectedItems[ 0 ]}
                handleClearMultiSelect={() => handleClearMultiSelect( "tournamentList" )} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='custom-multiselect mb-20' data-testid="full-name">
              <Form.Label>Partner Name</Form.Label>
              <MultiSelectCheckbox
                multiOptionsList={partnerList}
                handleSelectChange={handleSelectChange[ 1 ]}
                multiSelectedItems={multiSelectedItems[ 1 ]}
                handleClearMultiSelect={() => handleClearMultiSelect( "partnerList" )} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='custom-multiselect mb-20' data-testid="username">
              <Form.Label>Game Name</Form.Label>
              <MultiSelectCheckbox
                multiOptionsList={gameTournamentList}
                handleSelectChange={handleSelectChange[ 2 ]}
                multiSelectedItems={multiSelectedItems[ 2 ]}
                handleClearMultiSelect={() => handleClearMultiSelect( "gameTournamentList" )} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='custom-datepicker mb-20'>
              <Form.Label>Start Date</Form.Label>
              <CustomDatePicker
                value={values.startDate}
                handleChange={( e ) => {
                  if( e ) {
                    addQuery( useSetUrlParams( "startDate", new Date( e ).toLocaleDateString( "fr-CA" ) ) );
                    handleChange( "startDate", new Date( e ).toLocaleDateString( "fr-CA" ) );
                  }
                  else {
                    addQuery( useSetUrlParams( "startDate", "" ) );
                    handleChange( "startDate", false );
                  }
                }
                }
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='custom-datepicker mb-20'>
              <Form.Label>End Date</Form.Label>
              <CustomDatePicker
                value={values.endDate}
                handleChange={( e ) => {
                  if( e ) {
                    handleChange( "page", 1 );
                    addQuery( useSetUrlParams( "endDate", new Date( e ).toLocaleDateString( "fr-CA" ) ) );
                    handleChange( "endDate", new Date( e ).toLocaleDateString( "fr-CA" ) );
                  }
                  else {
                    addQuery( useSetUrlParams( "endDate", "" ) );
                    handleChange( "endDate", false );
                  }
                }
                }
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='custom-multiselect mb-0'>
              <Form.Label>Status</Form.Label>
              <MultiSelectCheckbox
                multiOptionsList={TOURNAMENT_STATUS_DROPDOWN}
                handleSelectChange={handleSelectChange[ 3 ]}
                multiSelectedItems={multiSelectedItems[ 3 ]}
                handleClearMultiSelect={() => handleClearMultiSelect( "status" )} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='custom-multiselect mb-0'>
              <Form.Label>Opt In</Form.Label>
              <MultiSelectCheckbox
                multiOptionsList={ OPT_IN_STATUS_DROPDOWN }
                handleSelectChange={handleSelectChange[ 4 ]}
                multiSelectedItems={multiSelectedItems[ 4 ]}
                handleClearMultiSelect={() => handleClearMultiSelect( "optInTypes" )}
              />
            </Form.Group>
          </Col>
        </Row>
      </div>
    </div>
  );
};

TounamentFilters.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  showFilter: PropTypes.object,
  tournamentList: PropTypes.array,
  handleSelectChange: PropTypes.func,
  multiSelectedItems: PropTypes.array,
  handleClearMultiSelect: PropTypes.func,
  partnerList: PropTypes.array,
  gameTournamentList: PropTypes.array,
};

export default TounamentFilters;
