import { toastr } from "react-redux-toastr";
import { OfferDraftDetail } from "../../entities/offerDraftDetail";
import { updatePartnerDraftOfferDetail, addAdminDraftOffer, getPartnerDraftOfferTemplateDetail } from "../../services/adminOffersService";
import constant from "../../util/constant";
import offerConstant from "../../util/offerConstant";
import {
  START_LOADER,
  STOP_LOADER,
  PARTNER_OFFER_DRAFT_API_REQUEST,
  PARTNER_OFFER_DRAFT_API_END,
  ADD_PARTNER_NEW_OFFER_DRAFT_SUCCESS,
  ADD_PARTNER_NEW_OFFER_DRAFT_FAILURE,
  GET_PARTNER_DRAFT_OFFER_DETAIL_SUCCESS,
  GET_PARTNER_DRAFT_OFFER_DETAIL_FAILURE,
  GET_PARTNER_OFFER_TEMPLATE_DETAIL_SUCCESS,
  UPDATE_PARTNER_OFFER_DRAFT_SUCCESS,
  UPDATE_PARTNER_OFFER_DRAFT_FAILURE,
  SET_PARTNER_OFFER_TEMPLATE_DETAIL,
  ADD_PARTNER_CLONE_OFFER_DRAFT_SUCCESS
} from "../actionTypes";

const { OFFER_API_TYPES } = offerConstant;
const { ERROR_MESSAGES } = constant;

export const addNewPartnerOfferDraftAction = ( param1, parm2, isLoader, isDraftClone ) => async ( dispatch ) => {
  try {
    if( isLoader ) {
      dispatch( { type: START_LOADER } );
    }

    dispatch( {
      type: PARTNER_OFFER_DRAFT_API_REQUEST,
      payload: isDraftClone ? OFFER_API_TYPES.ADD_PARTNER_CLONE_DRAFT_OFFER : OFFER_API_TYPES.ADD_ADMIN_NEW_DRAFT_OFFER,
    } );

    // eslint-disable-next-line camelcase
    const response = await addAdminDraftOffer( { entity_name: parm2, draft_content: param1 } );

    if( !isLoader ) {
      dispatch( {
        type: GET_PARTNER_OFFER_TEMPLATE_DETAIL_SUCCESS,
        payload: new OfferDraftDetail( { ...response.data.draft_content, entityName: response.data.entity_name, entityId: response.data.entity_id } ),
      } );
    }

    dispatch( {
      type: isDraftClone ? ADD_PARTNER_CLONE_OFFER_DRAFT_SUCCESS : ADD_PARTNER_NEW_OFFER_DRAFT_SUCCESS,
      payload: response.data,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: ADD_PARTNER_NEW_OFFER_DRAFT_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: PARTNER_OFFER_DRAFT_API_END,
    } );
  }
};

export const getPartnerOfferDraftDetailAction = ( offerId, isViewEditTournament = false, isViewDraft = false ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_OFFER_DRAFT_API_REQUEST,
      payload: OFFER_API_TYPES.GET_PARTNER_DRAFT_OFFER_TEMPLATE_DETAIL,
    } );

    const response = await getPartnerDraftOfferTemplateDetail( offerId );

    dispatch( {
      type: GET_PARTNER_OFFER_TEMPLATE_DETAIL_SUCCESS,
      payload: new OfferDraftDetail( { ...response.data.draft_content, entityName: response.data.entity_name, isViewEditTournament, isViewDraft } ),
    } );
    dispatch( {
      type: GET_PARTNER_DRAFT_OFFER_DETAIL_SUCCESS,
      payload: new OfferDraftDetail( { ...response.data.draft_content, entityName: response.data.entity_name, isViewEditTournament, isViewDraft } ),
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GET_PARTNER_DRAFT_OFFER_DETAIL_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: PARTNER_OFFER_DRAFT_API_END,
    } );
  }
};

export const updatePartnerOfferDraftAction = ( param1, parm2, isLoader ) => async ( dispatch ) => {
  try {
    if( isLoader ) {
      dispatch( { type: START_LOADER } );
    }

    dispatch( {
      type: PARTNER_OFFER_DRAFT_API_REQUEST,
      payload: OFFER_API_TYPES.UPDATE_PARTNER_OFFER_DRAFT_TEMPLATE,
    } );

    // eslint-disable-next-line camelcase
    const response = await updatePartnerDraftOfferDetail( { entityId: parm2, draft_content: param1 } );

    if( !isLoader ) {
      dispatch( {
        type: SET_PARTNER_OFFER_TEMPLATE_DETAIL,
        payload: new OfferDraftDetail( { ...response.data.draft_content, entityName: response.data.entity_name, entityId: response.data.entity_id } ),
      } );
    }

    dispatch( {
      type: UPDATE_PARTNER_OFFER_DRAFT_SUCCESS,
      payload: response.data,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: UPDATE_PARTNER_OFFER_DRAFT_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: PARTNER_OFFER_DRAFT_API_END,
    } );
  }
};
