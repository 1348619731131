import constant from "../../util/constant";
import {
  GET_PARTNER_GAME_LIST_SUCCESS,
  GET_PARTNER_GAME_LIST_FAILURE,
  GET_PARTNER_GAME_DETAIL_REQUEST,
  PARTNER_GAME_API_REQUEST,
  PARTNER_GAME_API_END,
  SCROLL_SUCCESS,
  UPLOAD_GAME_ICON_SUCCESS,
  UPLOAD_GAME_ICON_FAILURE,
  ADD_GAME_SUCCESS,
  ADD_GAME_FAILURE,
  PARTNER_ALL_GAME_LIST_SUCCESS,
  PARTNER_ALL_GAME_LIST_FAILURE,
  GET_GAME_DETAIL_SUCCESS,
  GET_GAME_DETAIL_FAILURE,
  UPDATE_GAME_SUCCESS,
  UPDATE_GAME_FAILURE,
  ADD_PARTNER_COMMENT_SUCCESS,
  ADD_PARTNER_COMMENT_FAILURE,
  GET_PARTNER_ANALYTICS_GAME_LIST_SUCCESS,
  CLEAR_GAMES_GRAPH_DATA
} from "../actionTypes";

const { API_STATUS } = constant;

const DEFAULT_STATE = {
  loading: false,
  message: "",
  status: "",
  apiType: "",
  partnerGameDetail: {},
  partnerGames: { partnerGamesList: [], meta: "" },
  gameDetails: {},
  gameIcon: "",
  partnerAllGames: [],
  partnerAnalyticsGames: [],
};

// TODO: refactor to avoid complexity
// eslint-disable-next-line complexity
const partnerGame = ( state = DEFAULT_STATE, action = "" ) => {
  switch ( action.type ) {
    case GET_PARTNER_GAME_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PARTNER_GAME_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
      };
    case PARTNER_GAME_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case GET_PARTNER_GAME_LIST_SUCCESS:
      return {
        ...state,
        partnerGames: {
          partnerGamesList: action.payload.gamesList,
          meta: action.payload.meta,
        },
        loading: false,
        status: API_STATUS.SUCCESS,
      };
    case GET_PARTNER_GAME_LIST_FAILURE:
      return {
        ...state,
        partnerGames: { ...state.partnerGames },
        loading: false,
      };
    case SCROLL_SUCCESS:
      return {
        ...state,
        partnerGames: {
          partnerGamesList: [
            ...state.partnerGames.partnerGamesList,
            ...action.payload.partner_data,
          ],
          meta: action.payload.meta,
        },
        loading: false,
        status: API_STATUS.SUCCESS,
      };
    case UPLOAD_GAME_ICON_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        gameIcon: action.payload.data.path,
      };
    case ADD_GAME_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
      };
    case UPDATE_GAME_SUCCESS:
    case GET_GAME_DETAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        gameDetails: action.payload,
      };
    case ADD_GAME_FAILURE:
    case PARTNER_ALL_GAME_LIST_FAILURE:
    case UPDATE_GAME_FAILURE:
    case UPLOAD_GAME_ICON_FAILURE:
    case GET_GAME_DETAIL_FAILURE:
    case ADD_PARTNER_COMMENT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
      };
    case PARTNER_ALL_GAME_LIST_SUCCESS:
      return {
        ...state,
        partnerAllGames: action.payload,
        status: API_STATUS.SUCCESS,
      };
    case ADD_PARTNER_COMMENT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        gameDetails: {
          ...state.gameDetails,
          GameTestingComments: [ ...state.gameDetails.GameTestingComments, { ...action.payload, partnerId: action.payload.partner_id } ],
        },
      };
    case GET_PARTNER_ANALYTICS_GAME_LIST_SUCCESS:
      return {
        ...state,
        partnerAnalyticsGames: action.payload.gamesList,
        analyticsGraphData: action.payload?.analyticsGraphData,
        totalGames: action.payload.totalGames,
        initialDate: action.payload.initialDate,
        loading: false,
        status: API_STATUS.SUCCESS,
      };
    case CLEAR_GAMES_GRAPH_DATA:
      return {
        ...state,
        partnerAnalyticsGames: [],
        analyticsGraphData: "",
        totalGames: 0,
        initialDate: null,
      };
    default:
      return state;
  }
};

export default partnerGame;
