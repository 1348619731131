const ADMIN_SUBMIT_FOR_APPROVAL = "Submit for Approval";
const adminGameConstant = {
  ADMIN_GAME_API_TYPES: {
    GET_ADMIN_GAMES: "GET_ADMIN_GAMES",
    GET_ADMIN_GAME_DETAILS: "GET_ADMIN_GAME_DETAILS",
    ADMIN_ADD_MODIFICATION: "ADMIN_ADD_MODIFICATION",
    UPDATE_PARTNER_GAME_STATUS: "UPDATE_PARTNER_GAME_STATUS",
    ADD_ADMIN_COMMENT: "ADD_ADMIN_COMMENT",
    ENABLE_DISABLE_PARTNER_GAME: "ENABLE_DISABLE_PARTNER_GAME",
    ADMIN_GAMES_LINKED_GAMERS_LIST: "ADMIN_GAMES_LINKED_GAMERS_LIST",
    ADMIN_GAMES_LINKED_GAMERS_FILTER_LIST: "ADMIN_GAMES_LINKED_GAMERS_FILTER_LIST",
  },
  GAME_LIST_TAB: {
    ALL: "all",
    APPROVED: "approved",
  },
  ADMIN_GAME_STATUS: [
    {
      status: "1",
      title: "In Development",
    },
    {
      status: "2",
      title: ADMIN_SUBMIT_FOR_APPROVAL,
    },
    {
      status: "3",
      title: "Approved",
    },
    {
      status: "4",
      title: "Rejected",
    },
    {
      status: "5",
      title: "Disabled",
    },
  ],
  ADMIN_GAME_STATUS_TYPE: {
    IN_DEVELOPMENT: "1",
    READY_FOR_TESTING: "2",
    APPROVED: "3",
    REJECTED: "4",
    DISABLED: "5",
  },
  GAME_STATUS_DROPDOWN: [
    { label: "In-Development", value: 1 },
    { label: ADMIN_SUBMIT_FOR_APPROVAL, value: 2 },
    { label: "Approved", value: 3 },
    { label: "Rejected", value: 4 },
    { label: "Disabled", value: 5 },
  ],
  TABS: {
    LINKED_GAMERS: "LINKED_GAMERS",
    GENERAL_INFORMATION: "GENERAL_INFORMATION",
    COMMENTS: "COMMENTS",
  },
};

export default adminGameConstant;
