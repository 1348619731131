import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useParams } from "react-router-dom";
import { getAdminEventDetailAction } from "../../../store/actions/adminEvent";
import constant from "../../../util/constant";
import eventConstant from "../../../util/eventConstant";
import { ViewEvent } from "../../shared/events/";

const { API_STATUS } = constant;
const { EVENT_API_TYPES } = eventConstant;

const AdminEventDetail = () => {
  const dispatch = useDispatch();
  const { apiType, status, adminEventDetail, message } = useSelector( state => ( {
    apiType: state.adminEvent.apiType,
    status: state.adminEvent.status,
    adminEventDetail: state.adminEvent.adminEventDetail,
    message: state.adminEvent.message,
  } ) );
  const { eventId } = useParams();

  useEffect( () => {
    if( eventId !== "0" ) {
      dispatch( getAdminEventDetailAction( eventId ) );
    }
  }, [ eventId ] );
  useEffect( () => {
    if(
      status === API_STATUS.SUCCESS &&
        apiType === EVENT_API_TYPES.ENABLE_DISABLE_PARTNER_GAME
    ) {
      toastr.success( message.message );
    }
  }, [] );

  return <ViewEvent eventDetail={adminEventDetail} type='admin'/>;
};

export default AdminEventDetail;
