import { API_URL } from "../util/apiPaths";
import { request } from "./api.js";

export function getPartnerGameList( params ) {
  return request( "GET", API_URL.PARTNER_GAMES, params );
}

export function addGame( params ) {
  return request( "POST", API_URL.PARTNER_GAMES, params );
}

export function getPartnerAllGameList( params ) {
  return request( "GET", API_URL.PARTNER_ALL_GAMES_LIST, params );
}

export function getGameDetails( params ) {
  return request( "GET", `${ API_URL.PARTNER_GAMES }/${ params }` );
}

export function updateGame( params ) {
  return request( "PUT", API_URL.PARTNER_GAMES, params );
}

export function addPartnerComment( params ) {
  return request( "POST", API_URL.ADD_PARTNER_COMMENT, params );
}

export const partnerGameServices = {
  getPartnerGameList,
  addGame,
  getPartnerAllGameList,
  getGameDetails,
  updateGame,
  addPartnerComment,
};
