import { getEligibleSubscriptionPlans } from "../util/helpers";

const planName = eligiblePlans => ( eligiblePlans.length ? eligiblePlans[ 0 ].name : "" );

/**
 * used to filter unique gamers with linked games from the list.
 * We are using the same api used in partner analytics games listing to get the list of gamers.
 * Api response contains the list of duplicate gamers(separate entry for each linked game).
 * We changed the key `fullName` to `fullNames` and `acQyrCashBalance` to
 * `acqyrCashBalances` for code reusability.
 * @function filterGamers
 * @param {Array} gamerslist
 * @param {Array} membershipTypes
 * @returns {Array} filterUniqueGamers with linked games and linked games count.
 */
const filterGamers = ( gamerslist, membershipTypes ) => {
  const filterUniqueGamers = gamerslist.reduce( ( accumulator, obj ) => {
    const exists = accumulator.find( ( { userId, fullName } ) => obj.userId === userId && obj.fullName === fullName );

    if( !exists ) {
      accumulator.push( { ...obj, linkedGames: [], linkedGamesCount: 0 } );
    }

    return accumulator;
  }, [] );

  // Filter the linked games for each unique gamer.
  filterUniqueGamers.forEach( ( obj ) => {
    gamerslist.forEach( ( item ) => {
      if( obj.userId === item.userId ) {
        if( item.gameId ) {
          // Pushing the linked games in the array.
          obj.linkedGames.push(
            {
              gameName: item.gameName ?? "",
              gameId: item.gameId ?? "",
              iconUrl: item.iconUrl ?? "",
            }
          );
        }
      }
    } );

    obj.gamerPlan = planName( getEligibleSubscriptionPlans( obj.membershipId, membershipTypes ) );
    obj.linkedGamesCount = obj.linkedGames.length;
    obj.fullNames = obj.fullName || "N/A"; //fullNames key is already in use in admin gamers listing
    obj.acqyrCashBalance = obj.acQyrCashBalance; //acqyrCashBalance key is already in use in admin gamers listing
    delete obj.acQyrCashBalance;
  } );

  return filterUniqueGamers;
};

export class PartnerGamerDetails {
  constructor( gamerslist, membershipTypes ) {
    this.partnerGamerList = filterGamers( gamerslist, membershipTypes ).sort( ( a, b ) => a.fullNames.localeCompare( b.fullNames ) ) ?? [];
  }
}
