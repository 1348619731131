import moment from "moment";
import Enums from "../util/Enums";
import constant from "../util/constant";
import { convertDate, convertTimeStampToTime } from "../util/helpers";
import offerConstant from "../util/offerConstant";
import { OfferDetail } from "./offerDetail";

const { OFFER_STATUS } = offerConstant;
const { FREEMIUM_MEMBERSHIP_ID } = Enums;
const { ADVERTISE_OPTIONS, ADVERTISE_STATUS, DATE_FORMAT, OPT_IN_STATUS } = constant;

// Used to get the offer opt-in type when edit offer.
// If the offer have opt-in 'Fee' then it will return the 'Yes' because the 'Fee' is the same as 'Yes' with entry fee.
const getOptInType = ( optInType ) => {
  if( optInType === OPT_IN_STATUS.FEE ) {
    return OPT_IN_STATUS.YES;
  }

  return optInType;
};

const noOfDays = ( obj ) => {
  if( obj.noOfDays ) {
    return obj.noOfDays;
  }
  else if( ( obj.advertise_to_gamer === ADVERTISE_STATUS.NO_OF_DAYS ) && obj.advertise_date ) {
    const advertiseDate = new Date( obj.advertise_date );
    const startDate = new Date( obj.OfferSchedule.start_date );
    const differenceInTime = startDate.getTime() - advertiseDate.getTime();

    return parseInt( differenceInTime / ( 1000 * 3600 * 24 ), 10 ); // Round-off to integer no. of days.
  }

  return 0;
};

export class OfferTemplateDetail extends OfferDetail {
  // TODO: refactor to avoid complexity
  // eslint-disable-next-line complexity
  constructor( obj ) {
    super( obj );
    this.isPartnerActive = obj?.Partner?.is_active || false;
    this.partnerCostUsdPerQxPoint = obj?.Partner?.partner_cost_usd_per_qx_point || 0;
    this.isGameActive = obj?.game_is_active || "";
    this.isActive = obj.is_active || "";
    this.rejectionReason = obj.rejection_reason || "";
    this.approvalStatus = obj.approval_status || "";
    this.statusTitle = OFFER_STATUS.find(
      offer => offer.status === obj.approval_status
    );
    this.offerType = obj.offer_type || "";
    this.offerSchedule = obj.OfferSchedule
      ? {
        maxRewardCount: obj.OfferSchedule.max_reward_count_per_user,
        gameName: obj.OfferSchedule.Game.name,
        gameUrl: obj.OfferSchedule.Game.icon_url,
        targetAudience: obj.OfferAudienceMappings[ 0 ]?.TargetAudiences.name || "",
        startDate: convertDate( obj.OfferSchedule.start_date ),
        endDate: convertDate( obj.OfferSchedule.end_date ),
        startTime: obj.isEditTournament ? new Date( obj.OfferSchedule.start_date ) : convertTimeStampToTime( obj.OfferSchedule.start_date ),
        endTime: obj.isEditTournament ? new Date( obj.OfferSchedule.end_date ) : convertTimeStampToTime( obj.OfferSchedule.end_date ),
        selectedGame: { label: obj.OfferSchedule.Game.name, value: obj.OfferSchedule.Game.game_id, gameIconUrl: obj.OfferSchedule.Game?.icon_url || "" },
        selectedAudience: { label: obj.OfferAudienceMappings[ 0 ]?.TargetAudiences.name, value: obj.OfferAudienceMappings[ 0 ]?.TargetAudiences.audience_id },
        startDateForValue: moment( obj.OfferSchedule.start_date ).format( obj.isEditTournament ? "" : DATE_FORMAT ),
        endDateForValue: moment( obj.OfferSchedule.end_date ).format( obj.isEditTournament ? "" : DATE_FORMAT ),
      }
      : null;

    if( obj.isEditTournament ) {
      this.offerSchedule = { ...this.offerSchedule, timeZone: new Intl.DateTimeFormat().resolvedOptions().timeZone };
    }

    this.advertiseToGamer = obj?.advertise_to_gamer ? ADVERTISE_OPTIONS.find( option => option.value === obj.advertise_to_gamer ) : ADVERTISE_OPTIONS[ 0 ];
    this.selectedAdvertiseDate = obj.advertise_date ? convertDate( obj.advertise_date ) : "";
    this.advertiseDate = moment( obj.advertise_date ).format( DATE_FORMAT );
    this.selectedAdvertise = ADVERTISE_OPTIONS.find( option => option.value === obj.advertise_to_gamer )?.label || "";
    this.noOfDays = noOfDays( obj );
    this.advertiseStatus = obj?.advertise_to_gamer;
    this.offerDependencyDisabled = obj?.offer_dependency_disabled;
    this.parentTemplateId = obj.parent_template_id || "";
    this.parentTemplateName = obj.parentTemplateName || "";
    this.optIn = {
      optInType: getOptInType( obj.opt_in ),
      membershipId: obj.membership_id || FREEMIUM_MEMBERSHIP_ID,
      entryFee: Number( obj.entry_fee ),
      minParticipants: obj.min_participants,
      maxParticipants: obj.max_participants,
      minParticipantsNoLimit: obj.min_participants === null,
      maxParticipantsNoLimit: obj.max_participants === null,
    };
    this.optInType = getOptInType( obj.opt_in );
  }
}
