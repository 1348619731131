import constant from "../util/constant";

export class GameRequest {
  /* eslint-disable camelcase */
  constructor( obj ) {
    this.name = obj.gameName;
    this.icon_url = obj.gameIcon;
    this.description = obj.rawDescription;
    this.download_urls = obj.downloadUrls;
    this.description_text = obj.descriptionText;
    this.game_id = obj?.gameId;
    this.game_assets = obj.exchangeableAssets
      .filter( item => item.name && item.price )
      .map( ( assets ) => {
        return {
          asset_id: assets?.gameAssetId ? assets?.gameAssetId : constant.DEFAULT_UUID,
          name: assets.name,
          unit_price_qx_points: Number( assets.price ),
        };
      } );
  }
  /* eslint-enable camelcase */
}
