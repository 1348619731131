import PropTypes from "prop-types";
import React from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

const SuggestModification = ( { onChange, testingComments, errorMessage } ) => (
  <Modal.Body className='pb-0'>
    <Form.Group className='mb-0'>
      <Form.Control
        as="textarea"
        name='testingComments'
        placeholder=''
        value={testingComments}
        onChange={onChange}
        data-testid='testingComments'
      />
      <div className='invalid-feedback d-block'>{errorMessage}</div>
    </Form.Group>
  </Modal.Body>
);

SuggestModification.propTypes = {
  onChange: PropTypes.func,
  testingComments: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default SuggestModification;
