import constant from "../../util/constant";
import {
  PARTNER_OFFER_API_REQUEST,
  PARTNER_OFFER_API_END,
  GET_OFFER_TEMPLATES_SUCCESS,
  GET_OFFER_TEMPLATES_FAILURE,
  ADD_PARTNER_NEW_OFFER_TEMPLATE_SUCCESS,
  ADD_PARTNER_NEW_OFFER_TEMPLATE_FAILURE,
  GET_PARTNER_OFFER_TEMPLATE_DETAIL_SUCCESS,
  GET_PARTNER_OFFER_TEMPLATE_DETAIL_FAILURE,
  ADD_PARTNER_OFFER_FROM_SCRATCH_FAILURE,
  ADD_PARTNER_OFFER_FROM_SCRATCH_SUCCESS,
  GET_PARTNER_OFFER_SUCCESS,
  UPDATE_PARTNER_OFFER_TEMPLATE_FAILURE,
  UPDATE_PARTNER_OFFER_TEMPLATE_SUCCESS,
  SET_PARTNER_OFFER_TEMPLATE_DETAIL,
  DELETE_PARTNER_OFFER_SUCCESS,
  DELETE_PARTNER_OFFER_FAILURE,
  CLEAR_OFFER_DETAIL,
  CLONE_OFFER_TEMPLATE_SUCCESS
} from "../actionTypes";

const { API_STATUS } = constant;

const DEFAULT_STATE = {
  loading: false,
  message: "",
  status: "",
  apiType: "",
  offerTemplates: [],
  offers: [],
  offerDetail: {},
  cloneOfferDetail: {},
};

const partnerOffer = ( state = DEFAULT_STATE, action = "" ) => {
  switch ( action.type ) {
    case PARTNER_OFFER_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
      };
    case PARTNER_OFFER_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case GET_OFFER_TEMPLATES_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        offerTemplates: action.payload,
      };
    case GET_PARTNER_OFFER_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        offers: action.payload,
      };
    case GET_PARTNER_OFFER_TEMPLATE_DETAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        offerDetail: action.payload,
      };
    case SET_PARTNER_OFFER_TEMPLATE_DETAIL:
      return {
        ...state,
        offerDetail: action.payload,
      };
    case DELETE_PARTNER_OFFER_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        offers: state.offers.filter( offer => offer.offerId !== action.payload.offerId ),
      };
    case ADD_PARTNER_NEW_OFFER_TEMPLATE_SUCCESS:
    case ADD_PARTNER_OFFER_FROM_SCRATCH_SUCCESS:
    case UPDATE_PARTNER_OFFER_TEMPLATE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
      };
    case CLONE_OFFER_TEMPLATE_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        cloneOfferDetail: action.payload,
      };
    case GET_OFFER_TEMPLATES_FAILURE:
    case ADD_PARTNER_NEW_OFFER_TEMPLATE_FAILURE:
    case GET_PARTNER_OFFER_TEMPLATE_DETAIL_FAILURE:
    case ADD_PARTNER_OFFER_FROM_SCRATCH_FAILURE:
    case UPDATE_PARTNER_OFFER_TEMPLATE_FAILURE:
    case DELETE_PARTNER_OFFER_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        cloneOfferDetail: {},
      };
    case CLEAR_OFFER_DETAIL:
      return {
        ...state,
        offerDetail: {},
      };
    default:
      return state;
  }
};

export default partnerOffer;
