import { toastr } from "react-redux-toastr";
import { OfferDraftDetail } from "../../entities/offerDraftDetail";
import { addAdminDraftOffer, updateAdminDraftOfferDetail, getPartnerDraftOfferTemplateDetail } from "../../services/adminOffersService";
import constant from "../../util/constant";
import offerConstant from "../../util/offerConstant";
import {
  START_LOADER,
  STOP_LOADER,
  ADMIN_OFFER_DRAFT_API_REQUEST,
  ADMIN_OFFER_DRAFT_API_END,
  ADD_ADMIN_NEW_OFFER_DRAFT_SUCCESS,
  ADD_ADMIN_NEW_OFFER_DRAFT_FAILURE,
  UPDATE_ADMIN_OFFER_DRAFT_SUCCESS,
  UPDATE_ADMIN_OFFER_DRAFT_FAILURE,
  GET_ADMIN_OFFER_DETAILS_SUCCESS,
  GET_ADMIN_DRAFT_OFFER_DETAIL_SUCCESS,
  GET_ADMIN_DRAFT_OFFER_DETAIL_FAILURE
} from "../actionTypes";

const { OFFER_API_TYPES } = offerConstant;
const { ERROR_MESSAGES } = constant;

export const addNewAdminOfferDraftAction = ( entityName, params ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_OFFER_DRAFT_API_REQUEST,
      payload: OFFER_API_TYPES.ADD_ADMIN_NEW_DRAFT_OFFER,
    } );

    // eslint-disable-next-line camelcase
    const response = await addAdminDraftOffer( { entity_name: entityName, draft_content: params } );

    dispatch( {
      type: ADD_ADMIN_NEW_OFFER_DRAFT_SUCCESS,
      payload: response.data,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: ADD_ADMIN_NEW_OFFER_DRAFT_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_OFFER_DRAFT_API_END,
    } );
  }
};

export const updateAdminOfferDraftAction = ( draftContent, entityId ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_OFFER_DRAFT_API_REQUEST,
      payload: OFFER_API_TYPES.UPDATE_ADMIN_OFFER_DRAFT_TEMPLATE,
    } );

    // eslint-disable-next-line camelcase
    const response = await updateAdminDraftOfferDetail( { entityId: entityId, draft_content: draftContent } );

    dispatch( {
      type: UPDATE_ADMIN_OFFER_DRAFT_SUCCESS,
      payload: response.data,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: UPDATE_ADMIN_OFFER_DRAFT_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_OFFER_DRAFT_API_END,
    } );
  }
};

export const getAdminOfferDraftDetailAction = ( param, isViewEditTournament = false ) => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_OFFER_DRAFT_API_REQUEST,
      payload: OFFER_API_TYPES.GET_PARTNER_DRAFT_OFFER_TEMPLATE_DETAIL,
    } );

    const response = await getPartnerDraftOfferTemplateDetail( param );

    dispatch( {
      type: GET_ADMIN_OFFER_DETAILS_SUCCESS,
      payload: new OfferDraftDetail( { ...response?.data?.draft_content, isViewEditTournament } ),
    } );
    dispatch( {
      type: GET_ADMIN_DRAFT_OFFER_DETAIL_SUCCESS,
      payload: new OfferDraftDetail( { ...response?.data?.draft_content, isViewEditTournament } ),
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GET_ADMIN_DRAFT_OFFER_DETAIL_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_OFFER_DRAFT_API_END,
    } );
  }
};
