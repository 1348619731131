const tournamentConstant = {
  TOURNAMENT_TYPE_STATUS: {
    TOURNAMENT_TEMPLATE: "1",
    TOURNAMENT: "2",
    DRAFT: "3",
    CLONE: "4",
  },
  TOURNAMENTS_API_TYPES: {
    GET_ADMIN_TOURNAMENTS: "GET_ADMIN_TOURNAMENTS",
    GET_ADMIN_TOURNAMENT_DETAILS: "GET_ADMIN_TOURNAMENT_DETAILS",
  },
  TOURNAMENT_TYPES: {
    TOURNAMENT: "4",
    TOURNAMENT_TEMPLATE: "5",
    TOURNAMENT_TEMPLATE_DRAFT: "6",
  },
  TABS: {
    TOURNAMENT_DETAIL: "tournament-detail",
    LEADER_BOARD: "leaderboard",
  },
};

export default tournamentConstant;
