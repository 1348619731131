import VisibilityIcon from "@material-ui/icons/Visibility";
import PropTypes from "prop-types";
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useHistory } from "react-router-dom";
import constant from "../../../../util/constant";
import { linkIcon } from "../../../../util/icons";
import ToolTipError from "../../../admin/gamer/toolTipError";
import { ScrollPage } from "../../../shared/customScrollBar";


const { ROUTES } = constant;

const LinkedGamesList = ( {
  gamerDetail,
  gamerId,
  linkedGamesList,
  values,
  location,
} ) => {
  const history = useHistory();

  const handleViewPage = ( gameId, pageType ) => {
    const url = `${ ROUTES.PARTNER_GAMER_LINKED_GAME_DETAIL
      .replace( ":gameId", gameId )
      .replace( ":gamerId", gamerId )
    }`;

    history.push( {
      pathname: `${ url }?type=${ pageType }`,
      state: [ ...location.state,
        ...[ { from: "gamerLinkedGameList", path: location.pathname, state: location.search } ],
      ],
    } );
  };

  return (
    <>
      <Scrollbars
        renderThumbVertical={ScrollPage}
        className={`track-horizontal small-height ${ ( !values.viewType ) ? "track-horizontal-list-small" : "" }`}
      >
        { linkedGamesList?.length
          ? (
            <div className={`games-grid-wrapper no-scrollbar ${ ( !values.viewType ) ? "games-list-wrapper" : "" }`}>
              {linkedGamesList.map( linkedGame => (
                <div className="col-games-grid-box fixed-width" key={linkedGame.gameId} >
                  <div className="games-grid-box gamer-box grid-only cursor-pointer"
                    onClick={() => handleViewPage( linkedGame.gameId, "gameTab" )}
                  >
                    <div className="games-image-box">
                      <img src={linkedGame.iconUrl} alt="Game Icon"/>
                    </div>
                    <div className="games-grid-box-body">
                      <strong>{linkedGame.name}</strong>
                      <span className="games-anchor">
                        <img src={linkIcon} alt='link-icon' />
                      </span>
                    </div>
                  </div>
                  <div className="table-body gamer-game-body list-only">
                    <div className="table-row">
                      <div className="td">
                        <img src={linkedGame.iconUrl} alt="Game Icon" className='game-image' />
                        <strong>{linkedGame.name}</strong>
                      </div>
                      <div className="td">
                        {linkedGame.dateLinked}
                      </div>
                      <div className="td">
                        <span className="link cursor-pointer" title={linkedGame.offersCount}
                          onClick={() => handleViewPage( linkedGame.gameId, "offersTab" )}>
                          {linkedGame.offersCount} Offers</span>
                      </div>
                      <div className="td">
                        {!gamerDetail.serverErrorsGameName && (
                          <span className="link cursor-pointer" title={linkedGame.assetsCount}
                            onClick={() => handleViewPage( linkedGame.gameId, "assetsTab" )}>
                            {linkedGame.assetsCount} Assets</span>
                        )}
                        {gamerDetail.serverErrorsGameName && (
                          <span className="big-text-common ml-3">{"--"}</span>
                        )}
                        {gamerDetail.serverErrorsGameName && (
                          <ToolTipError message='Error in fetching assets for this game.'/>
                        )}
                      </div>
                      <div className="td">
                        <div
                          className='icon cursor-pointer'
                        >
                          <VisibilityIcon onClick={() => handleViewPage( linkedGame.gameId, "gameTab" )}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) )}
            </div> )
          : (
            <div className='no-record' data-testid='no-record-found'>No Linked Games Found</div>
          )}
      </Scrollbars>
    </>
  );
};

LinkedGamesList.propTypes = {
  gamerId: PropTypes.string,
  values: PropTypes.object,
  gamerDetail: PropTypes.object,
  linkedGamesList: PropTypes.array,
  location: PropTypes.object,
};

export default LinkedGamesList;
