/* eslint max-lines: [ 'warn', { 'max': 775, 'skipBlankLines': true, 'skipComments': true } ] */
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { draftToMarkdown } from "markdown-draft-js";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Row from "react-bootstrap/Row";
import Tooltip from "react-bootstrap/Tooltip";
import { Editor } from "react-draft-wysiwyg";
import { useDropzone } from "react-dropzone";
import { useSelector, useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useHistory, useParams } from "react-router-dom";
import { GameRequest } from "../../../entities/gameRequest";
import { useForm } from "../../../hooks";
import {
  uploadGameIconAction,
  addGameAction,
  getGameDetailsAction,
  updateGameAction,
  addPartnerCommentAction
} from "../../../store/actions/partnerGame";
import {
  startLoader
} from "../../../store/actions/profileInfo";
import Enums from "../../../util/Enums";
import adminGameConstant from "../../../util/adminGameConstant";
import constant from "../../../util/constant";
import {
  formatFileSize,
  validateFile,
  exchangeableAssetsDisabled,
  stripHtml,
  convertDate,
  resizeImageFile
} from "../../../util/helpers";
import { uploadIcon } from "../../../util/icons";
import partnerGameConstant from "../../../util/partnerGameConstant";
import {
  validateDownloadUrl,
  validateExchangeableAssets
} from "../../../util/validateMultipleFields";
import AssetValueInfo from "../../shared/asset/assetValueInfo";

const { ADMIN_GAME_STATUS_TYPE } = adminGameConstant;
const { NUMERIC_VALUE } = Enums;
const {
  FILE_SIZE_UNIT,
  API_STATUS,
  EDITOR_OPTIONS,
  ICON_FILE_MAX_SIZE,
  BRANDING: { APP_SHORT_NAME },
} = constant;
const { PARTNER_GAME_API_TYPES, GAME_STATUS_WITH_KEY } = partnerGameConstant;

const initialState = {
  gameIcon: "",
  gameName: "",
  status: "",
  rawDescription: "",
  description: EditorState.createEmpty(),
  urlErrors: [],
  assetsError: [],
  testingComments: "",
  comment: "",
  descriptionText: "",
  gameId: "",
  gameAssetId: "",
};
const initialDirty = {
  gameIcon: false,
  gameName: false,
  descriptionText: false,
};

const WRAPPER_RANDOM_MAX = 10000;

// TODO: refactor to avoid complexity
// eslint-disable-next-line complexity
const AddEditGame = ( props ) => {
  // This is a workaround for snapshot testing where the Editor component
  // uses a random ID for the wrapperId. This will allow tests to specify
  // the wrapperId (making it fixed for the snapshot), but still work
  // exactly as expected during normal operations
  const wrapperId = props.wrapperId
    ? props.wrapperId
    : Math.floor( Math.random() * WRAPPER_RANDOM_MAX );
  const dispatch = useDispatch();
  const history = useHistory();
  const { gameId } = useParams();
  const [ isEditGame, setEditGameStatus ] = useState( false );
  const [ viewType, setViewType ] = useState( true );
  const [ commentError, setcommentError ] = useState();
  const {
    partnerGame: { apiType, status: apiStatus, gameIcon, gameDetails },
    partner: { partnerDetail },
  } = useSelector( state => state );
  const { partnerCostUsdPerQxPoint, usdPerQxPoint } = partnerDetail?.configuration || { partnerCostUsdPerQxPoint: 0, usdPerQxPoint: 0 };
  const { GameTestingComments = [] } = gameDetails;
  const { values, errors, isError, handleChange, showAllErrorMessage } =
    useForm(
      {
        ...initialState,
        ...{
          downloadUrls: [ "" ],
          apiUrls: [ "" ],
          exchangeableAssets: [
            {
              name: "",
              price: "",
            },
          ],
        },
      },
      initialDirty
    );

  const handleUploadImage = ( img, file ) => {
    handleChange( "gameIcon", img );

    const fileData = new FormData();

    fileData.append( "file", file );
    dispatch( uploadGameIconAction( fileData ) );
  };

  const isExchangeableAssetsDisabled =
    exchangeableAssetsDisabled( gameDetails ) && isEditGame;

  const isDisable = isEditGame && gameDetails?.currentStatusId === ADMIN_GAME_STATUS_TYPE.READY_FOR_TESTING;

  const handleUploadGameIcon = async ( file ) => {
    const img = URL.createObjectURL( file );
    const { fileSize, size } = formatFileSize( file.size );

    if( !validateFile( file ) ) {
      toastr.error( "Game icon should be only image type." );
    }
    else if(
      ( ( fileSize <= ICON_FILE_MAX_SIZE.SIZE_IN_KB ) && ( size === FILE_SIZE_UNIT.KB ) )
      || ( size === FILE_SIZE_UNIT.BYTES )
    ) {
      handleUploadImage( img, file );
    }
    else if( ( ( fileSize <= ICON_FILE_MAX_SIZE.SIZE_IN_MB ) && ( size === FILE_SIZE_UNIT.MB ) ) || ( size === FILE_SIZE_UNIT.KB ) ) {
      dispatch( startLoader() );

      const resizedFile = await resizeImageFile( file );

      handleUploadImage( img, resizedFile );
    }
    else {
      toastr.error( `Game icon should not be bigger than ${ ICON_FILE_MAX_SIZE.SIZE_IN_MB } ${ FILE_SIZE_UNIT.MB }.` );
    }
  };

  const onDrop = useCallback( ( acceptedFiles ) => {
    const file = acceptedFiles[ NUMERIC_VALUE.ZERO ];

    handleUploadGameIcon( file );
  }, [] );

  const { getRootProps, getInputProps } = useDropzone( { onDrop } );

  const isExistDownloadUrlErrors = () =>
    validateDownloadUrl( values.downloadUrls ).some( err => err );
  const isExistExchangeableAssetsErrors = () =>
    validateExchangeableAssets( values.exchangeableAssets ).filter(
      exError => exError.name || exError.price
    )?.length === 0;

  const handleInputChange = ( e ) => {
    const { name, value } = e.target;

    handleChange( name, value );
  };

  useEffect( () => {
    if( gameId !== "0" ) {
      setEditGameStatus( true );
      dispatch( getGameDetailsAction( gameId ) );
    }
  }, [ gameId ] );
  useEffect( () => {
    if(
      apiType === PARTNER_GAME_API_TYPES.UPLOAD_GAME_ICON &&
      apiStatus === API_STATUS.SUCCESS
    ) {
      handleChange( "gameIcon", gameIcon );
    }
    else if(
      ( apiType === PARTNER_GAME_API_TYPES.ADD_GAME || apiType === PARTNER_GAME_API_TYPES.UPDATE_GAME ) &&
      apiStatus === API_STATUS.SUCCESS
    ) {
      toastr.success( apiType === PARTNER_GAME_API_TYPES.ADD_GAME ? "Game added successfully" : "Game updated successfully" );
      history.goBack();
    }
    else if(
      apiType === PARTNER_GAME_API_TYPES.GET_GAME_DETAIL &&
      apiStatus === API_STATUS.FAILURE
    ) {
      setEditGameStatus( false );
    }
    else if(
      apiType === PARTNER_GAME_API_TYPES.GET_GAME_DETAIL &&
      apiStatus === API_STATUS.SUCCESS
    ) {
      handleChange( "gameIcon", gameDetails.gameIcon );
      handleChange( "gameName", gameDetails.gameName );
      handleChange( "rawDescription", gameDetails.rawDescription );
      handleChange( "downloadUrls", gameDetails.downloadUrls );
      handleChange( "apiUrls", gameDetails.apiUrls );
      handleChange( "exchangeableAssets", gameDetails.exchangeableAssets );
      handleChange( "status", gameDetails.status );
      handleChange( "testingComments", gameDetails.testingComments );
      handleChange( "gameId", gameDetails.gameId );

      const contentBlock = htmlToDraft( gameDetails.description );
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );

      handleChange( "description", EditorState.createWithContent( contentState ) );
      handleChange( "descriptionText", gameDetails.descriptionText );
    }
    else if(
      apiType === PARTNER_GAME_API_TYPES.ADD_PARTNER_COMMENT &&
      apiStatus === API_STATUS.SUCCESS
    ) {
      toastr.success( "Comment added successfully" );
      handleChange( "comment", "" );
    }
  }, [ apiType, apiStatus, gameDetails ] );

  const handleDownloadURLChange = ( e, index ) => {
    const { name, value } = e.target;
    const updatedValue = values.downloadUrls;

    updatedValue[ index ] = value;

    const errorsObj = validateDownloadUrl( updatedValue );

    handleChange( name, updatedValue );
    handleChange( "urlErrors", errorsObj );
  };

  const handleExchangeableAssetsChange = ( e, index, type ) => {
    const { name, value } = e.target;
    const updatedExchangeableAssetsValue = values.exchangeableAssets;

    updatedExchangeableAssetsValue[ index ][ type ] = value;

    const exErrors = validateExchangeableAssets( updatedExchangeableAssetsValue );

    handleChange( name, updatedExchangeableAssetsValue );
    handleChange( "assetsError", exErrors );
  };

  const handleSubmit = () => {
    showAllErrorMessage();

    const errorsObj = validateDownloadUrl( values.downloadUrls );
    const exchangeableAssetsErrors = validateExchangeableAssets(
      values.exchangeableAssets
    );

    handleChange( "urlErrors", errorsObj );
    handleChange( "assetsError", exchangeableAssetsErrors );

    const downloadUrlError = isExistDownloadUrlErrors();
    const exchangeableAssetsError = isExistExchangeableAssetsErrors();

    if( !isError && !downloadUrlError && exchangeableAssetsError ) {
      const requestObj = new GameRequest( values );

      if( isEditGame ) {
        requestObj.status = values.status;
        // eslint-disable-next-line camelcase
        requestObj.game_id = gameDetails.gameId;
        dispatch( updateGameAction( requestObj ) );
      }
      else {
        dispatch( addGameAction( requestObj ) );
      }
    }
  };

  const handleAddDownloadUrl = () => {
    const updatedValue = values.downloadUrls;

    updatedValue.push( "" );
    handleChange( "downloadUrls", updatedValue );
  };

  const handleAddExchangeableAssets = () => {
    const exchangeableAssetsErrors = validateExchangeableAssets( values.exchangeableAssets, true );

    handleChange( "assetsError", exchangeableAssetsErrors );

    const isBlankField = exchangeableAssetsErrors.filter(
      exError => exError.name || exError.price
    )?.length;

    if( !isBlankField ) {
      const updatedExchangeableAssetsValue = values.exchangeableAssets;

      updatedExchangeableAssetsValue.push( {
        name: "",
        price: "",
      } );
      handleChange( "exchangeableAssets", updatedExchangeableAssetsValue );
    }
  };

  const handleRemoveDownloadUrl = ( index ) => {
    const updatedValue = values.downloadUrls;
    const errorsObj = values.urlErrors;

    errorsObj.splice( index, 1 );

    if( updatedValue.length === 1 ) {
      updatedValue[ 0 ] = "";
    }
    else {
      updatedValue.splice( index, 1 );
    }

    handleChange( "downloadUrls", updatedValue );
    handleChange( "urlErrors", errorsObj );
  };

  const handleRemoveExchangeableAssets = ( index ) => {
    const updatedExchangeableAssetsValue = values.exchangeableAssets;
    const assetsErrors = values.assetsError;

    assetsErrors.splice( index, 1 );

    if( updatedExchangeableAssetsValue.length === 1 ) {
      updatedExchangeableAssetsValue[ 0 ] = { name: "", price: "" };
    }
    else {
      updatedExchangeableAssetsValue.splice( index, 1 );
    }

    handleChange( "exchangeableAssets", updatedExchangeableAssetsValue );
    handleChange( "assetsError", assetsErrors );
  };

  const onEditorStateChange = ( editorState ) => {
    handleChange( "description", editorState );

    const rawContent = convertToRaw( editorState.getCurrentContent() );
    const htmlElement = draftToHtml( rawContent );
    const htmlText = stripHtml( htmlElement );

    handleChange( "rawDescription", draftToMarkdown( rawContent ) );
    handleChange( "descriptionText", htmlText.trim() );
  };

  const handleUploadLogo = ( e ) => {
    const { files } = e.target;
    const file = files[ NUMERIC_VALUE.ZERO ];

    handleUploadGameIcon( file );
  };

  const handleSubmitComment = () => {
    if( values.comment !== "" && isEditGame ) {
      setcommentError( "" );
      dispatch( addPartnerCommentAction( { comment: values.comment, gameId: gameDetails.gameId } ) );
    }
    else {
      setcommentError( "Please enter comment" );
    }
  };

  return (
    <>
      <div className='main-wrapper'>
        <div className='main-right-wrapper'>
          {!isEditGame && (
            <div className='page-head-wrapper'>
              <h4 className='page-heading'>Add Game</h4>
              <div className='page-head-right'>
                <button
                  className='link-btn back-btn'
                  onClick={() => history.goBack()}
                >
                  Cancel
                </button>
                <Button variant='primary' onClick={handleSubmit}>
                  Submit
                </Button>
              </div>
            </div>
          )}
          {isEditGame && gameDetails.currentStatusId !== ADMIN_GAME_STATUS_TYPE.REJECTED && gameDetails.isActive && (
            <div className='page-head-wrapper pos-relative'>
              <h4 className='page-heading'>Edit Game</h4>
              { viewType && (
                <div className='page-head-right'>
                  <button
                    className='link-btn back-btn'
                    onClick={() => history.goBack()}
                  >
                  Cancel
                  </button>
                  {isDisable && (
                    <OverlayTrigger
                      placement='left'
                      overlay={<Tooltip id="tooltip-disabled">You cannot make any changes while the game is in &ldquo;Submit for Approval&rdquo; status.</Tooltip>
                      }>
                      <span className='icon-circle-help disable-btn-overlay'></span>
                    </OverlayTrigger>
                  )}
                  <Button variant='primary' onClick={handleSubmit} disabled={isDisable} >
                  Update
                  </Button>
                </div>
              )}
            </div>
          )}
          <div className='page-inner-box profile-steps-box message-main-box'>
            { isEditGame && (
              <Nav variant="tabs" defaultActiveKey="/home">
                <Nav.Item>
                  <Nav.Link className="pt-0" active={viewType} onClick={() => setViewType( true )} >Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="pt-0" active={!viewType} onClick={() => setViewType( false )} >Comments</Nav.Link>
                </Nav.Item>
              </Nav>
            )}
            { ( props.isComment ) || ( !viewType ) ?
              ( <div className="comment-container">
                <div className="comment-content scrollbar">
                  {GameTestingComments.length > 0 && GameTestingComments.map( ( { comment, createdAt, partnerId }, index ) => {
                    const isPartnerComment = partnerId === gameDetails.partnerId;

                    return (
                      <div key={index} className={isPartnerComment ? "comment-content-box right-box" : "comment-content-box"}>
                        <div className="comment-head">
                          <span className="green-color">{isPartnerComment ? partnerDetail.legalEntityName : "Admin"}</span>{" "}
                          <span>added a comment on</span>{" "}
                          <span>{convertDate( createdAt )}</span>
                        </div>
                        <div key={index} className="comment-inner">{comment}</div>
                      </div>
                    );
                  }
                  )}
                </div>
                <div className="comment-box">
                  <Form.Control
                    type='text'
                    placeholder='Write a reply…'
                    name='comment'
                    onChange={handleInputChange}
                    value={values.comment}
                    data-testid='comment'
                  />
                  <div className='invalid-feedback d-block'>
                    {commentError}
                  </div>
                  <Button variant='primary' className="btn-md" onClick={handleSubmitComment}>
                  Send
                  </Button>
                </div>
              </div> )
              : null}
            { viewType && (
              <div className={`${ isEditGame && (
                ( !gameDetails?.isActive ) || ( gameDetails.currentStatusId === ADMIN_GAME_STATUS_TYPE.REJECTED )
              )
                ? "div-disable"
                : "" }`}>
                <Form className="position-relative">
                  <div className='custom-image-upload-wrapper'>
                    <strong>
                      Game Icon
                    </strong>
                    <div
                      className='custom-image-upload-box uploaded-image-wrapper'
                      {...getRootProps()}
                    >
                      <Form.Control {...getInputProps()} disabled={isDisable} />
                      {Boolean( values.gameIcon ) && (
                        <div className='uploaded-image'>
                          <img src={values.gameIcon} alt='games' />
                        </div>
                      )}
                      {!values.gameIcon && (
                        <label htmlFor='imageUpload'>
                          <img src={uploadIcon} alt='upload-icon' />
                          <p>
                        Drop file to upload or <span>browse</span>
                          </p>
                        </label>
                      )}
                    </div>
                    {Boolean( values.gameIcon ) && (
                      <div className='custom-image-upload'>
                        <Form.Control
                          type='file'
                          id='logoUpload'
                          name='gameIcon'
                          onChange={handleUploadLogo}
                          accept='image/*'
                          disabled={isDisable}
                        />
                        <label htmlFor='logoUpload'>
                          <img src={uploadIcon} alt='upload-icon' />
                      Upload
                        </label>
                      </div>
                    )}
                    <div className='invalid-feedback d-block'>
                      {errors.gameIcon}
                    </div>
                  </div>
                  {isEditGame && (
                    <div className='status-input'>
                      <div className='content-section'>
                        <div className='content-label'>Current Status</div>
                        <div className='content-des'>{gameDetails.isActive ? gameDetails.currentStatus : "Disabled"}</div>
                      </div>
                      <Form.Group>
                        <label>Set Status As</label>
                        <select
                          className='form-control'
                          name='status'
                          value={values.status}
                          onChange={handleInputChange}
                          disabled={isDisable}
                        >
                          {GAME_STATUS_WITH_KEY.map( status => (
                            <option value={status.id} key={status.id} >{status.label}</option>
                          ) )}
                        </select>
                      </Form.Group>
                    </div>
                  )}
                  <Row>
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label className='label-with-icon small-label'>
                      Game Name
                          <OverlayTrigger placement='right' overlay={<Tooltip id="tooltip-disabled">Gamers will see this when they click on your game in the { APP_SHORT_NAME } App.</Tooltip>}>
                            <span className='icon-circle-help'></span>
                          </OverlayTrigger>
                        </Form.Label>
                        <div className='input-with-icon d-block'>
                          <Form.Control
                            type='text'
                            placeholder='Enter Game Name'
                            value={values.gameName}
                            name='gameName'
                            onChange={handleInputChange}
                            maxLength={45}
                            data-testid='gameName'
                            disabled={isDisable}
                          />
                          <div className='invalid-feedback d-block'>
                            {errors.gameName}
                          </div>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    { values.gameId && (
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label className='label-with-icon small-label'>
                            { APP_SHORT_NAME }-assigned Game Id
                            <OverlayTrigger placement='right' overlay={<Tooltip id="tooltip-disabled">
                          Game id to be used by your game server when communicating with the { APP_SHORT_NAME } Server.</Tooltip>}>
                              <span className='icon-circle-help'></span>
                            </OverlayTrigger>
                          </Form.Label>
                          <div className='input-with-icon d-block'>
                            <Form.Control
                              disabled
                              value={values.gameId}
                              name='gameId'
                            />
                          </div>
                        </Form.Group>
                      </Col> )}
                  </Row>
                  <Row>
                    <Col sm={6}>
                      {values.downloadUrls.map( ( _, index ) => (
                        <Form.Group key={values}>
                          {index === 0 && <Form.Label className='label-with-icon small-label'>Download URLs
                            <OverlayTrigger placement='right' overlay={<Tooltip id="tooltip-disabled">Enter all the urls a gamer can go to in order to download your game.
                        Please add the complete link including the https://</Tooltip>}>
                              <span className='icon-circle-help'></span>
                            </OverlayTrigger>
                          </Form.Label>}
                          <div className='input-with-icon'>
                            <Form.Control
                              type='text'
                              placeholder='Enter Download URL'
                              value={values.downloadUrls[ index ]}
                              name='downloadUrls'
                              onChange={e => handleDownloadURLChange( e, index )}
                              maxLength={250}
                              data-testid='downloadUrls'
                              disabled={isDisable}
                            />
                            {!isDisable && (
                              values.downloadUrls.length - 1 === index
                                ? (
                                  <div>
                                    {( values.downloadUrls.length > 1 ||
                              ( values.downloadUrls.length === 1 && values.downloadUrls[ 0 ] ) ) && (
                                      <span
                                        className='icon-delete icon'
                                        onClick={() => handleRemoveDownloadUrl( index )}
                                      ></span>
                                    )}
                                    <span
                                      className='icon-circle-plus-green icon'
                                      onClick={handleAddDownloadUrl}
                                    ></span>
                                  </div>
                                )
                                : (
                                  <span
                                    className='icon-delete icon'
                                    onClick={() => handleRemoveDownloadUrl( index )}
                                  ></span>
                                )
                            ) }
                          </div>
                          {values.urlErrors[ index ] && (
                            <div className='invalid-feedback d-block'>
                              {values.urlErrors[ index ]}
                            </div>
                          )}
                        </Form.Group>
                      ) )}
                    </Col>
                  </Row>
                  { values.gameId && ( <Row>
                    <Col sm={6}>
                      {values.apiUrls.map( ( _, index ) => (
                        <Form.Group key={values}>
                          <Form.Label className='label-with-icon'>
                      API URLs
                            <OverlayTrigger placement='right' overlay={<Tooltip id="tooltip-disabled">
                        The urls that the { APP_SHORT_NAME } Server will use to contact the game server for this game.</Tooltip>}>
                              <span className='icon-circle-help'></span>
                            </OverlayTrigger>
                          </Form.Label>
                          <Form.Control
                            disabled
                            type='text'
                            placeholder='Enter API URLs'
                            value={values.apiUrls[ index ]}
                            onChange={handleInputChange}
                            maxLength={45}
                          />
                        </Form.Group>
                      ) )}
                    </Col>
                  </Row> )}
                  <Form.Group>
                    <Form.Label className='label-with-icon'>Description
                      <OverlayTrigger placement='left' overlay={<Tooltip id="tooltip-disabled">
                  Gamers will see the description when they see your game in the { APP_SHORT_NAME } app.</Tooltip>}>
                        <span className='icon-circle-help'></span>
                      </OverlayTrigger>
                    </Form.Label>
                    <Editor
                      editorState={values.description}
                      toolbarClassName='toolbarClassName'
                      wrapperClassName='wrapperClassName'
                      wrapperId={wrapperId}
                      editorClassName='editorClassName'
                      onEditorStateChange={onEditorStateChange}
                      readOnly={isDisable}
                      toolbar={
                        EDITOR_OPTIONS
                      }
                    />
                    <div className='invalid-feedback d-block'>
                      {errors.descriptionText}
                    </div>
                  </Form.Group>
                  <h4 className='games-inner-heading'>
                Exchangeable Assets (Optional)
                  </h4>
                  {values.exchangeableAssets.map( ( assets, index ) => (
                    <div className='input-group-with-icons' key={index}>
                      <div className='group-col col'>
                        <Form.Group>
                          <Form.Label className='label-with-icon'>Asset Name
                            <OverlayTrigger placement='right' overlay={<Tooltip id="tooltip-disabled">
                        This name must be unique for the game. Different game may have an asset of the same name, but they are different assets.</Tooltip>}>
                              <span className='icon-circle-help'></span>
                            </OverlayTrigger>
                          </Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter Asset Name'
                            value={values.exchangeableAssets[ index ].name}
                            name='exchangeableAssets'
                            onChange={e =>
                              handleExchangeableAssetsChange( e, index, "name" )
                            }
                            maxLength={45}
                            data-testid='exchangeableAssets'
                            disabled={isExchangeableAssetsDisabled || assets?.isApproved || isDisable}
                          />
                          <div className='invalid-feedback d-block'>
                            {values.assetsError[ index ]?.name}
                          </div>
                        </Form.Group>
                      </div>
                      <div className='group-col col'>
                        <Form.Group>
                          <Form.Label>Unit Value - { APP_SHORT_NAME } Points</Form.Label>
                          <Form.Control
                            type='number'
                            min="0"
                            placeholder='Enter Value'
                            value={values.exchangeableAssets[ index ].price}
                            name='exchangeableAssets'
                            onChange={e =>
                              handleExchangeableAssetsChange( e, index, "price" )
                            }
                            maxLength={45}
                            data-testid='exchangeableAssets'
                            disabled={isExchangeableAssetsDisabled || assets?.isApproved || isDisable }
                          />
                          { values.exchangeableAssets[ index ].price && (
                            <AssetValueInfo
                              qxPoints={values.exchangeableAssets[ index ].price}
                              usdPerQxPoint={usdPerQxPoint}
                              partnerCostUsdPerQxPoint={partnerCostUsdPerQxPoint}
                            />
                          ) }
                          <div className='invalid-feedback d-block'>
                            {values.assetsError[ index ]?.price}
                          </div>
                        </Form.Group>
                      </div>
                      { values.exchangeableAssets[ index ].gameAssetId && ( <div className='group-col col'>
                        <Form.Group>
                          <Form.Label className='label-with-icon' > Asset Id
                            <OverlayTrigger placement='left' overlay={<Tooltip id="tooltip-disabled">
                        Id to be used by { APP_SHORT_NAME } and your game server to identify this asset.</Tooltip>}>
                              <span className='icon-circle-help'></span>
                            </OverlayTrigger>
                          </Form.Label>
                          <Form.Control
                            disabled
                            value={values.exchangeableAssets[ index ].gameAssetId}
                            name='gameAssetId'
                          />
                        </Form.Group>
                      </div> )}
                      {( !isExchangeableAssetsDisabled || !isDisable ) && (
                        <div className='icon-col'>
                          <div className='icon-wrapper'>
                            {values.exchangeableAssets.length - 1 === index
                              ? (
                                <div>
                                  {( values.exchangeableAssets.length > 1 || ( values.exchangeableAssets.length === 1 &&
                            ( values.exchangeableAssets[ 0 ].name || values.exchangeableAssets[ 0 ].price ) ) ) && (
                                    ( !values.exchangeableAssets[ index ]?.isApproved ) && ( !isDisable ) && (
                                      <span
                                        className='icon-delete icon'
                                        onClick={() =>
                                          handleRemoveExchangeableAssets( index )
                                        }
                                      />
                                    )
                                  )}
                                  { ( ( !isDisable ) && (
                                    <span
                                      className='icon-circle-plus-green icon'
                                      onClick={handleAddExchangeableAssets}>
                                    </span> ) )
                                  }
                                </div>
                              )
                              : (
                                !values.exchangeableAssets[ index ]?.isApproved && !isDisable && (
                                  <span
                                    className='icon-delete icon'
                                    onClick={() =>
                                      handleRemoveExchangeableAssets( index )
                                    }
                                  />
                                )
                              )}
                          </div>
                        </div>
                      )}
                    </div>
                  ) )}
                </Form>
              </div>
            ) }
          </div>
        </div>
      </div>
    </>
  );
};

AddEditGame.propTypes = {
  isComment: PropTypes.string,
  wrapperId: PropTypes.string,
};

export default AddEditGame;
