import constant from "../../util/constant";
import {
  PARTNER_OFFER_DRAFT_API_REQUEST,
  ADD_PARTNER_NEW_OFFER_DRAFT_FAILURE,
  ADD_PARTNER_NEW_OFFER_DRAFT_SUCCESS,
  PARTNER_OFFER_DRAFT_API_END,
  GET_PARTNER_DRAFT_OFFER_DETAIL_SUCCESS,
  GET_PARTNER_DRAFT_OFFER_DETAIL_FAILURE,
  UPDATE_PARTNER_OFFER_DRAFT_SUCCESS,
  UPDATE_PARTNER_OFFER_DRAFT_FAILURE,
  ADD_PARTNER_CLONE_OFFER_DRAFT_SUCCESS
} from "../actionTypes";

const { API_STATUS } = constant;

const DEFAULT_STATE = {
  loading: false,
  message: "",
  status: "",
  apiType: "",
  partnerOffers: { offers: [], meta: "" },
  offerDetails: {},
  cloneDraft: {},
};

const partnerOfferDraft = ( state = DEFAULT_STATE, action = "" ) => {
  switch ( action.type ) {
    case PARTNER_OFFER_DRAFT_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
      };
    case PARTNER_OFFER_DRAFT_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case ADD_PARTNER_NEW_OFFER_DRAFT_FAILURE:
    case GET_PARTNER_DRAFT_OFFER_DETAIL_FAILURE:
    case UPDATE_PARTNER_OFFER_DRAFT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
      };
    case ADD_PARTNER_NEW_OFFER_DRAFT_SUCCESS:
    case UPDATE_PARTNER_OFFER_DRAFT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
      };
    case GET_PARTNER_DRAFT_OFFER_DETAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        message: action.payload,
      };
    case ADD_PARTNER_CLONE_OFFER_DRAFT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        cloneDraft: action.payload,
      };

    default:
      return state;
  }
};

export default partnerOfferDraft;


