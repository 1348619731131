import { toastr } from "react-redux-toastr";
import { EventDetails } from "../../entities/eventDetails";
import { addEvent, getPartnerEvents, updatePartnerEvent, getPartnerEventDetail } from "../../services/partnerEventServices";
import constant from "../../util/constant";
import eventConstant from "../../util/eventConstant";
import {
  START_LOADER,
  STOP_LOADER,
  PARTNER_EVENT_API_REQUEST,
  PARTNER_EVENT_API_END,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAILURE,
  GET_PARTNER_EVENTS_SUCCESS,
  GET_PARTNER_EVENTS_FAILURE,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAILURE,
  GET_PARTNER_EVENT_DETAIL_SUCCESS,
  GET_PARTNER_EVENT_DETAIL_FAILURE
} from "../actionTypes";

const { EVENT_API_TYPES } = eventConstant;
const { ERROR_MESSAGES } = constant;

export const addEventAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_EVENT_API_REQUEST,
      payload: EVENT_API_TYPES.ADD_EVENT,
    } );

    const response = await addEvent( param );

    dispatch( { type: ADD_EVENT_SUCCESS, payload: response.data } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    const err = Object.values( error?.data?.error || {} ) || "";

    dispatch( { type: STOP_LOADER } );
    dispatch( { type: ADD_EVENT_FAILURE, payload: error } );

    if( err[ 0 ] ) {
      toastr.error( err[ 0 ] );
    }
    else {
      toastr.error( error.data.message );
    }
  }
  finally {
    dispatch( {
      type: PARTNER_EVENT_API_END,
    } );
  }
};

export const getPartnerEventsAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_EVENT_API_REQUEST,
      payload: EVENT_API_TYPES.GET_PARTNER_EVENTS,
    } );

    const response = await getPartnerEvents( param );
    const dataList = response.data.eventData.map(
      item => new EventDetails( item )
    );

    dispatch( {
      type: GET_PARTNER_EVENTS_SUCCESS,
      payload: { dataList, meta: response.data.meta },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GET_PARTNER_EVENTS_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: PARTNER_EVENT_API_END,
    } );
  }
};

export const updatePartnerEventAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_EVENT_API_REQUEST,
      payload: EVENT_API_TYPES.UPDATE_PARTNER_EVENT,
    } );

    const response = await updatePartnerEvent( param );

    dispatch( { type: UPDATE_EVENT_SUCCESS, payload: response.data } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    const err = Object.values( error?.data?.error || {} ) || "";

    dispatch( { type: STOP_LOADER } );
    dispatch( { type: UPDATE_EVENT_FAILURE, payload: error } );

    if( err[ 0 ] ) {
      toastr.error( err[ 0 ] );
    }
    else {
      toastr.error( error.data.message );
    }
  }
  finally {
    dispatch( {
      type: PARTNER_EVENT_API_END,
    } );
  }
};

export const getPartnerEventDetailAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: PARTNER_EVENT_API_REQUEST,
      payload: EVENT_API_TYPES.GET_PARTNER_EVENT_DETAIL,
    } );

    const response = await getPartnerEventDetail( param );
    const newResponse = new EventDetails( response.data );

    dispatch( {
      type: GET_PARTNER_EVENT_DETAIL_SUCCESS,
      payload: newResponse,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GET_PARTNER_EVENT_DETAIL_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: PARTNER_EVENT_API_END,
    } );
  }
};
