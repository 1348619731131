const eventConstant = {
  EVENT_API_TYPES: {
    ADD_EVENT: "ADD_EVENT",
    GET_PARTNER_EVENTS: "GET_PARTNER_EVENTS",
    GET_ADMIN_EVENTS: "GET_ADMIN_EVENTS",
    UPDATE_PARTNER_EVENT: "UPDATE_PARTNER_EVENT",
    GET_PARTNER_EVENT_DETAIL: "GET_PARTNER_EVENT_DETAIL",
    UPDATE_ADMIN_EVENT: "UPDATE_ADMIN_EVENT",
    GET_ADMIN_EVENT_DETAIL: "GET_ADMIN_EVENT_DETAIL",
    ENABLE_DISABLE_EVENT_GAME: "ENABLE_DISABLE_EVENT_GAME",
  },
  UNITS: [
    {
      value: "1",
      label: "Duration",
    },
    {
      value: "2",
      label: "Currency",
    },
    {
      value: "3",
      label: "Occurrence",
    },
    {
      value: "4",
      label: "Partner specific",
    },
  ],
  PARTNER_TAB: {
    TAB1: {
      title: "My Events",
      tab: "MY_EVENT",
    },
    TAB2: {
      title: "QX Events",
      tab: "QX_EVENT",
    },
  },

  ADMIN_TAB: {
    TAB1: {
      title: "System",
      tab: "SYSTEM_EVENT",
    },
    TAB2: {
      title: "Partner Defined",
      tab: "PARTNER_EVENT",
    },
  },
};

export default eventConstant;
