import { uniqBy } from "lodash";
import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { adminRestrictComponents } from "../../src/util/helpers";
import NotFound from "../components/shared/404";
import { rolesConfig } from "../config/rolesConfig";
/*eslint import/namespace: ['error', { allowComputed: true }]*/
import constant from "../util/constant";
import NavRoute from "./NavRoute";
import * as Routes from "./index";


const { ROUTES, APPLICATION_STATUS } = constant;

const PrivateRoutes = () => {
  const { partnerDetail = {}, adminDetail } = useSelector( state => ( {
    partnerDetail: state.partner.partnerDetail,
    adminDetail: state.adminDetail.data,
  } ) );
  const [ allowedRoutes, updateAllowedRoutes ] = useState( [] );
  const location = useLocation();
  const history = useHistory();
  const { pathname } = location;
  const accessComponent = {
    COMPLETE_PROFILE: [
      "GeneralInfo",
      "ContactInfo",
      "OtherInfo",
      "TermsConditions",
    ],
    PARTNERS: [
      "GeneralInfo",
      "ContactInfo",
      "OtherInfo",
      "TermsConditions",
      "ProfilePending",
      "ProfileRejected",
      "DocuSignPending",
      "ProfileDisabled",
    ],
  };

  const configPaths = ( role ) => {
    let paths;

    if( role === constant.ROLE.ADMIN && pathname.includes( "/admin" ) ) {
      paths = [ "common", constant.ROLE.ADMIN ];
    }

    if( role === constant.ROLE.PARTNER && pathname.includes( "/partner" ) ) {
      paths = [ "common", constant.ROLE.PARTNER ];
    }

    return paths;
  };

  const redirectPath = ( role ) => {
    if( role === constant.ROLE.ADMIN ) {
      history.push( ROUTES.ADMIN_PARTNER_LIST );
    }
    else {
      history.push( ROUTES.PARTNER_GENERAL_INFO );
    }
  };

  useEffect( () => {
    const role = localStorage.getItem( "role" );
    const appStatus = partnerDetail?.applicationStatus;
    const adminPermissions = adminDetail?.permissions || [];

    if( role ) {
      const paths = configPaths( role );

      if( !paths ) {
        redirectPath( role );
      }
      else {
        let replacePath;
        let allowedRoutesList = paths.reduce( ( acc, pathRole ) => {
          const configPath = rolesConfig[ pathRole ].routes.map( ( route ) => {
            if( pathRole === constant.ROLE.PARTNER ) {
              const isPartnerActive = Object.keys( partnerDetail ).length;

              if( !isPartnerActive && !appStatus ) {
                replacePath = ROUTES.PARTNER_PROFILE_DISABLED;

                return { ...route, replacePath };
              }
              else if( appStatus === APPLICATION_STATUS.ADD_PARTNER ) {
                replacePath = !accessComponent.COMPLETE_PROFILE.includes(
                  route.component
                )
                  ? ROUTES.PARTNER_GENERAL_INFO
                  : "";

                return { ...route, replacePath };
              }
              else if( appStatus === APPLICATION_STATUS.REJECTED ) {
                replacePath = ROUTES.PARTNER_PROFILE_REJECTED;

                return { ...route, replacePath };
              }
              else if( appStatus === APPLICATION_STATUS.APPROVED ) {
                replacePath = accessComponent.PARTNERS.includes( route.component )
                  ? ROUTES.PARTNER_DASHBOARD
                  : "";

                return { ...route, replacePath };
              }
              else if( appStatus === APPLICATION_STATUS.PENDING ) {
                replacePath = ROUTES.PARTNER_PROFILE_PENDING;

                return { ...route, replacePath };
              }
              else if( appStatus === APPLICATION_STATUS.DOCUSIGN_PENDING ) {
                replacePath = ROUTES.PARTNER_DOCUSIGN_PENDING;

                return { ...route, replacePath };
              }
              else if(
                appStatus === APPLICATION_STATUS.AMENDMENTS_SUGGESTED
              ) {
                replacePath = !accessComponent.PARTNERS.includes(
                  route.component
                )
                  ? ROUTES.PARTNER_PROFILE_PENDING
                  : "";

                return { ...route, replacePath };
              }

              return { ...route, replacePath };
            }
            else if( pathRole === constant.ROLE.ADMIN ) {
              replacePath = !adminRestrictComponents( "admin", adminPermissions ).includes(
                route.component
              )
                ? ROUTES.ADMIN_OFFER_LIST
                : "";

              return { ...route, replacePath };
            }

            return { ...route, replacePath };
          } );

          return [ ...acc, ...configPath ];
        }, [] );

        allowedRoutesList = uniqBy( allowedRoutesList, "url" );
        updateAllowedRoutes( allowedRoutesList );
      }
    }
    else {
      history.push( "/" );
    }
  }, [ pathname ] );

  return (
    <Fragment>
      <Switch>
        {allowedRoutes.map( route => (
          <NavRoute key={route}
            exact
            component={Routes[ route.component ]}
            path={route.url}
            replacePath={route.replacePath}
          />
        ) )}
        <Route component={NotFound} />
      </Switch>
    </Fragment>
  );
};

export default PrivateRoutes;
