import PropTypes from "prop-types";
import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import constant from "../../../util/constant";
import { getTimeZone, formatNumberAsPerLocale } from "../../../util/helpers";

const { ADVERTISE_STATUS } = constant;

const OfferScheduleDetail = ( { offerScheduleDetail, offerDetail, isTournament, title } ) => (
  <>
    <h4 className="page-inner-heading">{title} Schedule</h4>
    <Row>
      <Col sm={12}>
        <div className="content-section">
          <div className="content-label">Choose Game</div>
          <div className="image-tag">
            {offerScheduleDetail.gameUrl && (
              <div className="image-box">
                <img src={offerScheduleDetail.gameUrl} alt="Game icon" />
              </div>
            )}
            <span>{offerScheduleDetail.gameName}</span>
          </div>
        </div>
      </Col>
      {!isTournament &&
      <>
        <Col sm={12}>
          <div className="content-section">
            <div className="content-label">Choose Targeted Gamers</div>
            <div className="content-des">{offerScheduleDetail.targetAudience}</div>
          </div>
        </Col>
        <Col sm={6}>
          <div className="content-section">
            <div className="content-label">Start Date</div>
            <div className="content-des">{offerScheduleDetail.startDate}</div>
          </div>
        </Col>
        <Col sm={6}>
          <div className="content-section">
            <div className="content-label">End Date</div>
            <div className="content-des">{offerScheduleDetail.endDate}</div>
          </div>
        </Col>
        <Col sm={6}>
          <div className="content-section">
            <div className="content-label">When should offer be visible to gamers?</div>
            <div className="content-des">{offerDetail.selectedAdvertise}</div>
          </div>
        </Col>
      </>
      }

      {isTournament && <>

        <Col sm={12}>
          <div className="content-section">
            <div className="content-label">Time Zone</div>
            <div className="content-des">{getTimeZone( offerScheduleDetail.startDate )}</div>
          </div>
        </Col>
        <Col sm={6}>
          <div className="content-section">
            <div className="content-label">Start Date</div>
            <div className="content-des">{offerScheduleDetail.startDate}</div>
          </div>
          <div className="content-section">
            <div className="content-label">End Date</div>
            <div className="content-des">{offerScheduleDetail.endDate}</div>
          </div>
        </Col>
        <Col sm={6}>
          <div className="content-section">
            <div className="content-label">Start Time</div>
            <div className="content-des">{offerScheduleDetail.startTime}</div>
          </div>
          <div className="content-section">
            <div className="content-label">End Time</div>
            <div className="content-des">{offerScheduleDetail.endTime}</div>
          </div>
        </Col>
        <Col sm={6}>
          <div className="content-section">
            <div className="content-label">When should tournament be visible to gamers?</div>
            <div className="content-des">{( offerDetail.selectedAdvertise ).replace( "offer", "tournament" )}</div>
          </div>
        </Col>
      </>
      }
      {( offerDetail.selectedAdvertiseDate ) && ( offerDetail.advertiseStatus === ADVERTISE_STATUS.SOME_SPECIFIC_DATE ) && (
        <Col sm={6}>
          <div className="content-section">
            <div className="content-label">Advertise Date for Gamer</div>
            <div className="content-des">{offerDetail.selectedAdvertiseDate}</div>
          </div>
        </Col>
      )}
      {( Boolean( offerDetail.noOfDays ) ) && ( offerDetail.advertiseStatus === ADVERTISE_STATUS.NO_OF_DAYS ) && (
        <Col sm={6}>
          <div className="content-section">
            <div className="content-label">Number of Days</div>
            <div className="content-des">{formatNumberAsPerLocale( offerDetail.noOfDays )}</div>
          </div>
        </Col>
      )}
    </Row>
  </>
);

OfferScheduleDetail.propTypes = {
  offerScheduleDetail: PropTypes.object,
  offerDetail: PropTypes.object,
  isTournament: PropTypes.bool,
  title: PropTypes.string,
};

export default OfferScheduleDetail;
