import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";

import { useForm, useFilter } from "../../../hooks";
import { getLeaderboardAction } from "../../../store/actions/leaderboard";
import constant from "../../../util/constant";
import partnerGamerConstant from "../../../util/partnerGamerConstant";
import LeaderboardAllParticipants from "../../shared/tournamentLeaderboard/leaderboardAllParticipants";

const { API_STATUS, ORDER_BY } = constant;
const { API_TYPES } = partnerGamerConstant;

const PartnerLeaderboardAllParticipants = () => {
  const isAdmin = false;
  const { tournamentId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { setPureData, filteredData, filterData } = useFilter();
  const paramsQuery = new URLSearchParams( window.location.search );

  const {
    apiType,
    leaderboard: {
      participants = 0,
      participantsList = [],
    },
    status,
  } = useSelector( state => ( {
    leaderboard: state.leaderboard.data,
    apiType: state.leaderboard.apiType,
    status: state.leaderboard.status,
  } ) );

  const { values, handleChange } = useForm(
    {
      searchKey: "",
      sortBy: "rank",
      sortOrder: ORDER_BY.ASC,
      searchCol: [ "name" ],
    },
    {}
  );

  const requestParams = Object.assign( {
    sortOrder: values.sortOrder,
    searchCol: [ "name" ],
  },
  values.searchKey && { searchKey: values.searchKey },
  values.sortBy && { sortBy: values.sortBy }
  );

  useEffect( () => {
    dispatch( getLeaderboardAction( { tournamentId, isAdmin }, { fetchAllRecords: true } ) );
  }, [ tournamentId ] );

  useEffect( () => {
    if(
      ( status === API_STATUS.SUCCESS ) &&
      ( apiType === API_TYPES.GET_LEADER_BOARD )
    ) {
      setPureData( participantsList );
      updateStateFromUrl();
      filterData( requestParams );
    }
  }, [ status, apiType ] );

  useEffect( () => {
    filterData( requestParams );
  }, [
    values.sortOrder,
    values.sortBy,
    values.searchKey,
  ] );

  const updateStateFromUrl = () => {
    if( paramsQuery.get( "sortBy" ) && paramsQuery.get( "sortOrder" ) ) {
      handleChange( "sortBy", paramsQuery.get( "sortBy" ) );
      handleChange( "sortOrder", paramsQuery.get( "sortOrder" ) );
    }

    if( paramsQuery.get( "searchKey" ) ) {
      handleChange( "searchKey", paramsQuery.get( "searchKey" ) );
    }
  };

  return (
    <>
      <LeaderboardAllParticipants
        participants={participants}
        participantsList={filteredData}
        values={values}
        handleChange={handleChange}
        locationState={location.state}
      />
    </>
  );
};

export default PartnerLeaderboardAllParticipants;
