import VisibilityIcon from "@material-ui/icons/Visibility";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useCallback, useRef } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Scrollbars } from "react-custom-scrollbars";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useForm, useAddQuery, useScroll } from "../../../hooks";
import { getAdminFilterListAction } from "../../../store/actions/adminFilterList";
import { getAdminGamesAction } from "../../../store/actions/adminGames";
import adminGameConstant from "../../../util/adminGameConstant";
import constant from "../../../util/constant";
import { concatStrings, useSetUrlParams, checkFilterApplied } from "../../../util/helpers";
import { games as gameIcon } from "../../../util/icons";
import { adminGameTableConfigs } from "../../../util/tableConstant";
import { ScrollPage } from "../../shared/customScrollBar";
import { SubHeader } from "../../shared/games";
import TableHeaderRow from "../../shared/tableHeadingRow";

const { GAME_LIST_TAB, GAME_STATUS_DROPDOWN, ADMIN_GAME_API_TYPES, TABS } = adminGameConstant;
const { ORDER_BY, ROUTES, API_STATUS, FILTERS_NAME } = constant;

const GameList = () => {
  const { games, gamesMetaData, gameList, partnerList, status, apiType } = useSelector( state => ( {
    games: state.adminGame.adminGames.games,
    gamesMetaData: state.adminGame.adminGames.meta,
    gameList: state.adminFilterList.filterData.gameList,
    partnerList: state.adminFilterList.filterData.partnerList,
    status: state.adminGame.status,
    apiType: state.adminGame.apiType,
  } ) );
  const ref = useRef( null );
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isFilterApplied = checkFilterApplied( location.search, FILTERS_NAME.GAMES );
  const { addQuery } = useAddQuery();
  const { scrollEvent } = useScroll();
  const paramsQuery = new URLSearchParams( window.location.search );

  function getUrlParameter( key, col ) {
    let paramsQuerys;

    if( key === "searchKey" || key === "sortColumn" || key === "sortOrder" ) {
      paramsQuerys = paramsQuery.get( key ) === "" ? col : paramsQuery.get( key );
    }

    if( key === "gameList" || key === "partnerList" || key === "statusList" ) {
      paramsQuerys = paramsQuery.get( key ) === "" ? [] : JSON.parse( paramsQuery.get( key ) );
    }

    return paramsQuerys;
  }

  const { values, handleChange } = useForm(
    {
      activeTab: GAME_LIST_TAB.ALL,
      searchDummyText: getUrlParameter( "searchKey", "" ),
      searchKey: getUrlParameter( "searchKey", "" ),
      sortBy: getUrlParameter( "sortColumn", "" ),
      sortOrder: getUrlParameter( "sortOrder", ORDER_BY.ASC ),
      page: 1,
      gameList: getUrlParameter( "gameList", "" ),
      partnerList: getUrlParameter( "partnerList", "" ),
      statusList: getUrlParameter( "statusList", "" ),
      publishedOn: ( paramsQuery.get( "publishedOn" ) === null || paramsQuery.get( "publishedOn" ) === "" ) ? false : paramsQuery.get( "publishedOn" ),
    },
    {}
  );

  useEffect( () => {
    if(
      status === API_STATUS.SUCCESS &&
      apiType === ADMIN_GAME_API_TYPES.GET_ADMIN_GAMES
    ) {
      dispatch( getAdminFilterListAction( { entities: "Game,ApprovedPartner" } ) );
    }
  }, [ status, apiType ] );

  useEffect( () => {
    const requestParams = Object.assign(
      {
        sortOrder: values.sortOrder,
        perPage: 10,
        page: values.page,
        // TODO: refactor to remove use of undefined
        // May require a change to API
        // eslint-disable-next-line no-undefined
        gameIds: concatStrings( values.gameList, "," ) || undefined,
        // TODO: refactor to remove use of undefined
        // May require a change to API
        // eslint-disable-next-line no-undefined
        partnerIds: concatStrings( values.partnerList, "," ) || undefined,
        // TODO: refactor to remove use of undefined
        // May require a change to API
        // eslint-disable-next-line no-undefined
        status: concatStrings( values.statusList, "," ) || undefined,
        publishedOn: values.publishedOn === false ? "" : values.publishedOn,
      },
      values.searchKey && { searchKey: values.searchKey },
      values.sortBy && { sortBy: values.sortBy }
    );

    dispatch( getAdminGamesAction( requestParams ) );
  }, [
    values.sortOrder,
    values.page,
    values.sortBy,
    values.searchKey,
    values.gameList,
    values.partnerList,
    values.statusList,
    values.publishedOn,
  ] );

  const onScroll = ( e ) => {
    scrollEvent( e, games, values, handleChange, gamesMetaData, "page" );
  };

  const debouncedSave = useCallback(
    debounce( nextValue => handleChange( "searchKey", nextValue ), 1000 ),
    []
  );

  const searchHandle = ( e ) => {
    const { value: nextValue } = e.target;

    addQuery( useSetUrlParams( "searchKey", nextValue ) );
    handleChange( "searchDummyText", nextValue );
    debouncedSave( nextValue );
    handleChange( "page", 1 );
  };


  const handleSorting = ( sortBy, sortingType ) => {
    addQuery( useSetUrlParams( "sortColumn", sortBy ) );
    addQuery( useSetUrlParams( "sortOrder", sortingType ) );
    handleChange( "sortBy", sortBy );
    handleChange( "sortOrder", sortingType );
    handleChange( "page", 1 );
  };

  const memoizedTableConfig = useMemo( () => adminGameTableConfigs, [] );
  const memoizedHandleClick = useCallback(
    ( sortBy, orderType ) => handleSorting( sortBy, orderType ),
    []
  );

  return (
    <>
      <div className='main-wrapper'>
        <div className='main-right-wrapper'>
          <SubHeader
            values={values}
            onSearch={searchHandle}
            handleChange={handleChange}
            gameList={gameList}
            partnerList={partnerList}
            statusList={GAME_STATUS_DROPDOWN}
            addQuery={addQuery}
            isFilterApplied={isFilterApplied}
          />
          <div className='table-container game-seven-column'>
            <div className='table-head'>
              <div className='table-row'>
                <TableHeaderRow
                  configData={memoizedTableConfig}
                  onAscOrder={memoizedHandleClick}
                  onDescOrder={memoizedHandleClick}
                />
              </div>
            </div>
            <Scrollbars
              renderThumbVertical={ScrollPage}
              className='custom-scroll-height'
              onScroll={onScroll}>
              <div className='table-body no-scrollbar'>
                {Boolean( games.length ) &&
                  games.map( ( item, index ) => (
                    <div className='table-row' key={index}>
                      <div className='td'>
                        <img
                          src={item.gameIcon || gameIcon}
                          alt='Game'
                          className='game-image'
                        />
                      </div>
                      <div className='td'>
                        <span className='strong'>{item.gameName}</span>
                      </div>
                      <div className='td'>{item.partnerName}</div>
                      <div className='td' ref={ref}>
                        <OverlayTrigger
                          container={ref}
                          placement='auto'
                          overlay={<Tooltip className="event-tooltip">{item.descriptionText}</Tooltip>}
                        >
                          <span className='text-truncate'>{item.descriptionText}</span>
                        </OverlayTrigger>
                      </div>
                      <div className='td'>{item.approvedDate || "N/A"}</div>
                      <div className='td'>{item.statusTitle}</div>
                      <div className='td'>
                        <span
                          className='icon cursor-pointer'
                          onClick={() =>
                            history.push( {
                              pathname: `${ ROUTES.ADMIN_GAME_DETAILS.replace(
                                ":gameId",
                                item.gameId
                              ) }?tab=${ TABS.GENERAL_INFORMATION }&from=games`,
                              state: [
                                { from: "games", path: location.pathname, state: location.search },
                              ],
                            } )
                          }
                        >
                          <VisibilityIcon />
                        </span>
                      </div>
                    </div>
                  ) )}
              </div>
              {!games.length && (
                <div className='no-record' data-testid='no-record-found'>
                  No Game Found
                </div>
              )}
            </Scrollbars>
          </div>
        </div>
      </div>
    </>
  );
};

export default GameList;
