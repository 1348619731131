import PropTypes from "prop-types";
import React from "react";
import { Col, Modal, Row } from "react-bootstrap";

const ViewAllGamesModal = ( {
  values,
  closeModal,
} ) => {
  return (
    <>
      <Modal
        show={values.showModal}
        onHide={closeModal}
        centered
        className='game-list-modal'
      >
        <Modal.Header>
          <h2 className='modal-heading'>{values?.linkedGames?.length} Games</h2>
        </Modal.Header>
        <Modal.Body>
          <Row className='gamer-game-list'>
            {Boolean( values?.linkedGames?.length ) && values.linkedGames?.map( ( data, index ) => (
              <Col sm={12} key={index}>
                <img src={data.iconUrl} className='game-icon' alt='Game icon' />
                <p className='game-name' >{data.gameName}</p>
              </Col>
            ) )}
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

ViewAllGamesModal.propTypes = {
  values: PropTypes.object,
  closeModal: PropTypes.func,
};

export default ViewAllGamesModal;
