import { useState, useEffect } from "react";
import validateForm from "../util/validateForm";

const useForm = (
  initialState,
  initialDirty
) => {
  const [ values, setValues ] = useState( initialState );
  const [ errors, setErrors ] = useState( {} );
  const [ dirtyObj, setDirtyObj ] = useState( initialDirty );
  const [ isError, setErrorStatus ] = useState( false );
  const [ isNotRequireFormValid, setIsNotRequireFormValid ] = useState( false );

  const reset = () => {
    setValues( initialState );
    setErrors( {} );
    setDirtyObj( initialDirty );
    setErrorStatus( false );
  };

  const updateDirtyObj = ( name ) => {
    setDirtyObj( data => ( { ...data, [ name ]: true } ) );
  };

  const handleBlur = name => updateDirtyObj( name );

  const handleChange = ( name, value ) => {
    setValues( data => ( { ...data, [ name ]: value } ) );
    updateDirtyObj( name );
  };

  const showAllErrorMessage = () => {
    Object.keys( values ).forEach( ( key ) => {
      if( Object.keys( dirtyObj ).includes( key ) ) {
        handleChange( key, values[ key ] );
      }
    } );
  };

  const changeDirtyObj = async ( dirtyState ) => {
    await setDirtyObj( dirtyState );

    const newErrorObj = {};

    Object.keys( dirtyState ).forEach( ( key ) => {
      // TODO: refactor to remove use of undefined
      // Setting a key to undefined essentially means it doesn't exist
      // so not sure what the intent was here
      // eslint-disable-next-line no-undefined
      newErrorObj[ key ] = undefined;
    } );
    setErrors( newErrorObj );
    setIsNotRequireFormValid( true );
  };

  useEffect( () => {
    const { formErrors, errorStatus } = validateForm( values, dirtyObj, isNotRequireFormValid );

    setErrors( formErrors );
    setErrorStatus( errorStatus );
  }, [ values, dirtyObj, isNotRequireFormValid ] );

  return {
    values,
    errors,
    isError,
    handleChange,
    handleBlur,
    showAllErrorMessage,
    setDirtyObj,
    changeDirtyObj,
    reset,
  };
};

export default useForm;
