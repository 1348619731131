import VisibilityIcon from "@material-ui/icons/Visibility";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import React, { useMemo, useCallback, useRef, useEffect } from "react";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Scrollbars } from "react-custom-scrollbars";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useAddQuery, useForm } from "../../../hooks";
import { getAdminPartnerOffersAction } from "../../../store/actions/admin";
import constant from "../../../util/constant";
import { getUrlParameter, useSetUrlParams } from "../../../util/helpers";
import offerConstant from "../../../util/offerConstant";
import { adminPartnerOfferTableConfigs } from "../../../util/tableConstant";
import { ScrollPage } from "../../shared/customScrollBar";
import TableHeaderRow from "../tableHeadingRow";

const { ROUTES, ORDER_BY } = constant;
const { OFFER_API_STATUS } = offerConstant;

const OfferListing = ( { selectedPartnerId, previousState } ) => {
  const ref = useRef( null );
  const { addQuery } = useAddQuery();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const {
    admin: { partnerOffers },
  } = useSelector( state => state );

  const { values, handleChange } = useForm( {
    searchKey: getUrlParameter( "searchKey", null ),
    searchDummyText: getUrlParameter( "searchKey", null ),
    sortBy: "name",
    sortOrder: ORDER_BY.ASC,
  }, {} );

  const requestParamsOffers = Object.assign(
    {
      partnerIds: selectedPartnerId,
      fetchAllRecords: true,
      sortOrder: values.sortOrder,
      sortBy: values.sortBy,
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      searchKey: values.searchKey || undefined,
      offerTypes: OFFER_API_STATUS.OFFER,
    }
  );

  const memoizedTableConfig = useMemo( () => {
    return adminPartnerOfferTableConfigs;
  }, [ ] );

  const onSorting = ( sortBy, orderType ) => {
    handleChange( "page", 1 );
    handleChange( "sortOrder", orderType );
    handleChange( "sortBy", sortBy );
  };

  const memoizedHandleClick = useCallback(
    ( sortBy, orderType ) => onSorting( sortBy, orderType ),
    []
  );

  useEffect( () => {
    dispatch( getAdminPartnerOffersAction( requestParamsOffers ) );
  }, [ values.sortBy, values.searchKey, values.sortOrder ] );

  const searchHandle = ( e ) => {
    const { value: nextValue } = e.target;

    handleChange( "page", 1 );
    addQuery( useSetUrlParams( "searchKey", nextValue ), previousState );
    handleChange( "searchDummyText", nextValue );
    debouncedSave( nextValue );
  };

  const debouncedSave = useCallback(
    debounce( nextValue => handleChange( "searchKey", nextValue ), 1000 ),
    []
  );

  return (
    <>

      {/* Table start from here */}
      <div className='table-container admin-offer-table'>
        <div className='absolute-search'>
          <Form.Group className='custom-search w-200'>
            <span className='icon-search'></span>
            <Form.Control
              type='text'
              placeholder= 'Search by Offer Name ...'
              onChange= {searchHandle}
              value= {values.searchDummyText}
              data-testid='searchKey'
              title='Search by Offer Name, and Description'
            />
          </Form.Group>
        </div>
        <div className='table-head'>
          <div className='table-row'>
            <TableHeaderRow
              configData={memoizedTableConfig}
              onAscOrder={memoizedHandleClick}
              onDescOrder={memoizedHandleClick}
            />
          </div>
        </div>
        <Scrollbars renderThumbVertical={ScrollPage} style={{ height: "calc(100vh - 365px)" }}
        >
          <div className='table-body no-scrollbar'>
            {Boolean( partnerOffers?.offers.length ) && partnerOffers.offers.map( ( offer, index ) => (
              <div className='table-row' key={index}>
                <div className='td'>
                  <span className='strong'>{offer?.offerName}</span>
                </div>
                <div className='td' ref={ref}>
                  <OverlayTrigger container={ref}
                    placement='auto'
                    overlay={<Tooltip className="event-tooltip">{offer?.descriptionText}</Tooltip>}
                  >
                    <span className='text-truncate'>{offer?.descriptionText}</span>
                  </OverlayTrigger>
                </div>
                <div className='td'>
                  { offer?.gameName
                    ? ( <div className='link'
                      onClick={() =>
                        history.push( {
                          pathname: `${ ROUTES.ADMIN_GAME_DETAILS.replace(
                            ":gameId",
                            offer?.gameId
                          ) }?tab=GENERAL_INFORMATION`,
                          state: [ ...location.state,
                            ...[ { from: "partnerOffersGame", path: location.pathname, state: location.search } ],
                          ],
                          backScreenRoute: `${ ROUTES.PARTNER_REQUEST_DETAIL.replace( ":id", selectedPartnerId ) }`,
                        } ) }
                    >
                      <span className='link cursor-pointer'>{ offer?.gameName }</span>
                    </div> )
                    : ( <span className='nav-text'>N/A</span> )}</div>
                <div className='td'>
                  <div className={`badge ${ offer?.statusTitle?.class }` }>
                    { offer?.statusTitle?.title ?? "N/A" }
                  </div>
                </div>
                <div className='td'>
                  <div
                    className='icon cursor-pointer'
                    onClick={() =>
                      history.push( {
                        pathname: ROUTES.ADMIN_OFFER_DETAILS.replace(
                          ":offerId",
                          offer?.offerId
                        ),
                        state: [ ...location.state,
                          ...[ { from: "partnerOffersView", path: location.pathname, state: location.search } ],
                        ],
                      } )
                    }
                  >
                    <VisibilityIcon />
                  </div>
                </div>
              </div>
            ) )}
            {!partnerOffers.offers?.length && (
              <div className='no-record' data-testid='no-record-found'>
                  No Offer Found
              </div>
            )}
          </div>
        </Scrollbars>
      </div>
      {/* end table */}

    </>
  );
};

OfferListing.propTypes = {
  selectedPartnerId: PropTypes.string,
  value: PropTypes.object,
  previousState: PropTypes.string,
};

export default OfferListing;
