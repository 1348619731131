import { sortBy } from "lodash";
import constant from "../util/constant";
import { convertDate } from "../util/helpers";
import offerConstant from "../util/offerConstant";

const { OFFER_STATUS } = offerConstant;
const { DEFAULT_UUID, OPT_IN_STATUS } = constant;

export class AdminOfferDetails {
  rewardArray = obj => obj?.OfferRewards?.map( ( reward ) => {
    return {
      place: reward.place,
      rewardValue: reward.reward_value,
    };
  } ) || [];

  constructor( obj ) {
    this.createdAt = obj.createdAt
      ? convertDate( obj.createdAt )
      : null;
    this.updatedAt = obj.updatedAt
      ? convertDate( obj.updatedAt )
      : null;
    this.description = obj.description || "";
    this.offerId = obj.offer_id || "";
    this.offerName = obj.name || "";
    this.partnerId = obj.partner_id || "";
    this.descriptionText = obj.description_text || "";
    this.parentTemplateId = obj.parent_template_id || "";
    this.rejectionReason = obj.rejection_reason || "";
    this.approvalStatus = obj.approval_status || "";
    this.offerType = obj.offer_type || "";
    this.isActive = obj.is_active || "";
    this.offerSchedule = obj.OfferSchedule || "";
    this.partnerName = obj?.Partner?.legal_entity_name || "";
    this.gameName = obj?.OfferSchedule?.Game?.name || "";
    this.gameId = obj?.OfferSchedule?.Game?.game_id || "";
    this.statusTitle = OFFER_STATUS.find(
      offer => offer.status === obj.approval_status
    );
    this.reward = obj?.OfferRewards?.length ? obj.OfferRewards[ 0 ].reward_value : "";
    this.rewards = sortBy( this.rewardArray( obj ), reward => reward.place );
    this.isAdmin = obj?.parent_template_id === DEFAULT_UUID;
    this.optInDetail = {
      optIn: obj?.opt_in || OPT_IN_STATUS.NO,
      membershipId: obj?.membership_id,
      entryFee: obj?.entry_fee || "",
      minParticipants: obj?.min_participants,
      maxParticipants: obj?.max_participants,
    };
  }
}
