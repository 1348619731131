const analyticsConstant = {
  API_TYPES: {
    GET_EXCHANGE_TRANSACTION: "GET_EXCHANGE_TRANSACTION",
    GET_GAME_DETAILS: "GET_GAME_DETAILS",
    GET_GAMER_LISTS: "GET_GAMER_LISTS",
    GET_ASSETS_LISTS: "GET_ASSETS_LISTS",
    GET_ANALYTICS_LINKED_GAMERS: "GET_ANALYTICS_LINKED_GAMERS",
  },
  ANALYTICS_TAB: {
    GAMES: "games",
    EXCHANGE_TRANSACTION: "exchangeTransaction",
    GAMERS: "gamerLists",
    DASHBOARD: "dashboard",
    OFFER_REWARD_TXN: "offerRewardTxn",
    TOURNAMENT_REWARD_TXN: "tournamentRewardTxn",
    OPT_IN_TXN: "optInTxn",
  },
  SEARCH_PLACEHOLDER: {
    games: "Search by Game Name",
    exchangeTransaction: "Search by Game, Asset, and Gamer Name",
    gamerLists: "Search by Game and Gamer Name",
    offerRewardTxn: "Search by Game, Offer, and Gamer Name",
    tournamentRewardTxn: "Search by Game, Tournament, and Gamer Name",
    optInTxn: "Search by Game, Transaction For, Offer/Tournament Name and Gamer Name",
    linkedGamers: "Search by Gamer Name",
  },
};

export default analyticsConstant;
