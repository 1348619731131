import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAdminGameDetailsAction } from "../../../store/actions/adminGames";
import ViewGameDetail from "./viewGameDetail";

const GameDetail = ( props, viewType ) => {
  const paramsQuery = new URLSearchParams( window.location.search );
  const tab = paramsQuery.get( "type" );
  const dispatch = useDispatch();
  const { gameDetails, usdPerQxPoint, partnerCostUsdPerQxPoint } = useSelector( state => ( {
    gameDetails: state.adminGame.gameDetails,
    usdPerQxPoint: state.adminDetail.data?.usdPerQxPoint || 0,
    partnerCostUsdPerQxPoint: state.adminDetail.data?.configuration.partnerCostUsdPerQxPoint || 0,
  } ) );
  const gameId = props.props.match?.params?.gameId || "";

  useEffect( () => {
    if( gameId ) {
      dispatch( getAdminGameDetailsAction( gameId ) );
    }
  }, [ gameId ] );

  return (
    <>
      {viewType && (
        <ViewGameDetail
          viewType = {viewType}
          gameDetails = {gameDetails}
          tab = {tab}
          gameId = {gameId}
          gamerId = {props.gamerId}
          usdPerQxPoint = {usdPerQxPoint}
          partnerCostUsdPerQxPoint={partnerCostUsdPerQxPoint}
        />
      )}
    </>
  );
};

GameDetail.propTypes = {
  match: PropTypes.string,
  props: PropTypes.object,
  viewType: PropTypes.bool,
  gamerId: PropTypes.string,
  gameId: PropTypes.string,
};

export default GameDetail;
