import constant from "../../util/constant";
import {
  ADMIN_EVENT_API_REQUEST,
  ADMIN_EVENT_API_END,
  ADD_ADMIN_EVENT_SUCCESS,
  ADD_ADMIN_EVENT_FAILURE,
  GET_ADMIN_EVENTS_SUCCESS,
  GET_ADMIN_EVENTS_FAILURE,
  GET_ADMIN_EVENT_DETAIL_SUCCESS,
  UPDATE_ADMIN_EVENT_SUCCESS,
  UPDATE_ADMIN_EVENT_FAILURE,
  GET_ADMIN_EVENT_DETAIL_FAILURE,
  ENABLE_DISABLE_EVENT_SUCCESS,
  ENABLE_DISABLE_EVENT_FAILURE
} from "../actionTypes";

const { API_STATUS } = constant;

const DEFAULT_STATE = {
  loading: false,
  message: "",
  status: "",
  apiType: "",
  adminEvents: { events: [], meta: "" },
  adminEventDetail: {},
};

const adminEvent = ( state = DEFAULT_STATE, action = "" ) => {
  switch ( action.type ) {
    case ADMIN_EVENT_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
      };
    case ADMIN_EVENT_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case ADD_ADMIN_EVENT_SUCCESS:
    case UPDATE_ADMIN_EVENT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
      };
    case ADD_ADMIN_EVENT_FAILURE:
    case GET_ADMIN_EVENT_DETAIL_FAILURE:
    case UPDATE_ADMIN_EVENT_FAILURE:
    case ENABLE_DISABLE_EVENT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
      };
    case GET_ADMIN_EVENTS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        partnerEvents: { events: [], meta: "" },
      };
    case GET_ADMIN_EVENTS_SUCCESS:
      return {
        ...state,
        adminEvents: {
          events:
          action.payload.meta.page === 1
            ? action.payload.dataList
            : [ ...state.adminEvents.events, ...action.payload.dataList ],
          meta: action.payload.meta,
        },
        status: API_STATUS.SUCCESS,
      };
    case GET_ADMIN_EVENT_DETAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        adminEventDetail: action.payload,
      };
    case ENABLE_DISABLE_EVENT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        message: action.payload,
        adminEventDetail: {
          ...state.adminEventDetail,
          isActive: !state.adminEventDetail.isActive,
        },
      };
    default:
      return state;
  }
};

export default adminEvent;
