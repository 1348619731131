import DeleteIcon from "@material-ui/icons/Delete";
import HelpIcon from "@material-ui/icons/Help";
import PropTypes from "prop-types";
import React from "react";
import { Form, Col, Button, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";

// Local-imports
import constant from "../../../util/constant";
import { customFilterOption } from "../../../util/helpers";
import offerConstant from "../../../util/offerConstant";
import SelectBox from "../selectBox";

const { FUNCTIONS, COMPARISONS, RANKING_OBJECTIVE } = offerConstant;
const { EDITOR_OPTIONS, BRANDING: { APP_SHORT_NAME } } = constant;

const WRAPPER_RANDOM_MAX = 10000;

const Achievements = (
  { values: { achievements, achievementErrors },
    events,
    onChange,
    onDelete,
    onSelect,
    onEditorStateChange,
    onBlur,
    editorOnBlur,
    editorOnFocus,
    displayTimeLimit,
    wrapperId: propsWrapperId,
  } ) => {
  // This is a workaround for snapshot testing where the Editor component
  // uses a random ID for the wrapperId. This will allow tests to specify
  // the wrapperId (making it fixed for the snapshot), but still work
  // exactly as expected during normal operations
  const wrapperId = propsWrapperId
    ? propsWrapperId
    : Math.floor( Math.random() * WRAPPER_RANDOM_MAX );
  const error = ( index, name ) => achievementErrors[ index ] && <div className='invalid-feedback d-block'>
    {achievementErrors[ index ][ name ]}
  </div>;

  return (
    <>
      <h4 className='page-inner-heading small'>
      Achievement
        <OverlayTrigger placement='right' overlay={<Tooltip>Define the achievement.</Tooltip>}>
          <HelpIcon className="help-icon" />
        </OverlayTrigger>
      </h4>
      {achievements.map( ( achievement, index ) =>
        <Row key={index}>
          {index !== 0 && (
            <Col sm={12} className='pb-3 mb-2'>
              <Button variant='secondary btn-and' disabled>AND</Button>
            </Col>
          )}
          <Col sm={6}>
            <Form.Group>
              <Form.Label>Achievement Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Achievement Name'
                value={achievement.name}
                name='name'
                onChange={e => onChange( e, index )}
                maxLength={50}
                data-testid='name'
                onBlur={onBlur}
              />
              {error( index, "name" )}
            </Form.Group>
          </Col>
          {achievements.length > 1 && (
            <Col sm={6} className='text-right'>
              <div className='text-danger delete-form-button cursor-pointer' onClick={() => onDelete( index )}>
                <DeleteIcon />
              </div>
            </Col>
          )}
          <Col sm={12}>
            <Form.Group>
              <Form.Label className='label-md'>Description</Form.Label>
              <Editor
                editorState={achievement.description}
                placeholder='Write Description'
                toolbarClassName='toolbarClassName'
                wrapperClassName='wrapperClassName'
                wrapperId={wrapperId}
                editorClassName='editorClassName'
                onEditorStateChange={editorState => onEditorStateChange( editorState, index )}
                onBlur={editorOnBlur}
                onFocus={editorOnFocus}
                toolbar={
                  EDITOR_OPTIONS
                }
              />
              {error( index, "descriptionText" )}
            </Form.Group>
          </Col>
          <Col sm={12}>
            <div className='border-container'>
              <Col sm={12}>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label className='label-with-icon'>
                        Event Name
                        <OverlayTrigger placement='right' overlay={<Tooltip>Choose from { APP_SHORT_NAME } events or  from your own, predefined events.</Tooltip>}>
                          <HelpIcon />
                        </OverlayTrigger>
                      </Form.Label>
                      <SelectBox
                        options={events} placeholder='Choose Event Name'
                        onChange={value => onSelect( "event", value, index )}
                        value={achievement.event}
                        onBlur={onBlur}
                        filterOption={customFilterOption}
                      />
                      {error( index, "event" )}
                    </Form.Group>
                  </Col>
                  {Boolean( achievement.eventUnit ) && (
                    <Col sm={6}>
                      <Form.Group>
                        <Form.Label>Units</Form.Label>
                        <Form.Control
                          type='text'
                          value={achievement.eventUnit}
                          data-testid='units'
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  )}
                </Row>
              </Col>
              {Boolean( achievement.eventDescription ) && (
                <Col sm={12}>
                  <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as='textarea'
                      name='description'
                      value={achievement.eventDescription}
                      maxLength={500}
                      data-testid='description'
                      disabled
                    />
                  </Form.Group>
                </Col>
              )}
              <Col sm={12}>
                <Row>
                  <Col sm={4}>
                    <Form.Group>
                      <Form.Label>Function</Form.Label>
                      <SelectBox
                        value={achievement.function}
                        options={FUNCTIONS}
                        placeholder='Choose Function'
                        isSearchable={false}
                        onChange={value => onSelect( "function", value, index )}
                        onBlur={onBlur}
                      />
                      {error( index, "function" )}
                    </Form.Group>
                  </Col>
                  {displayTimeLimit && (
                    <>
                      <Col sm={4}>
                        <Form.Group>
                          <Form.Label>Comparison</Form.Label>
                          <SelectBox
                            value={achievement.comparison}
                            options={COMPARISONS}
                            placeholder='Choose Comparison'
                            isSearchable={false}
                            onChange={value => onSelect( "comparison", value, index )}
                            onBlur={onBlur}
                          />
                          {error( index, "comparison" )}
                        </Form.Group>
                      </Col>
                      <Col sm={4}>
                        <Form.Group>
                          <Form.Label>Value</Form.Label>
                          <Form.Control
                            type='text'
                            value={achievement.value}
                            name='value'
                            onChange={e => onChange( e, index )}
                            data-testid='value'
                            maxLength={25}
                            placeholder="0"
                            onBlur={onBlur}
                          />
                          {error( index, "value" )}
                        </Form.Group>
                      </Col>
                    </>
                  )}
                  {!displayTimeLimit && (
                    <Col sm={4}>
                      <Form.Group>
                        <Form.Label>Ranking Objective</Form.Label>
                        <SelectBox
                          value={achievement.ranking}
                          options={RANKING_OBJECTIVE}
                          placeholder='Choose Ranking Objective'
                          isSearchable={false}
                          onChange={value => onSelect( "ranking", value, index )}
                          onBlur={onBlur}
                        />
                        {error( index, "ranking" )}
                      </Form.Group>
                    </Col>
                  )}
                </Row>
              </Col>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

Achievements.propTypes = {
  values: PropTypes.object,
  events: PropTypes.array,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onSelect: PropTypes.func,
  onEditorStateChange: PropTypes.func,
  onBlur: PropTypes.func,
  editorOnBlur: PropTypes.func,
  editorOnFocus: PropTypes.func,
  displayTimeLimit: PropTypes.bool,
  wrapperId: PropTypes.string,
};

export default Achievements;
