import { API_URL } from "../util/apiPaths";
import { request } from "./api.js";

export function getPartnerDetail() {
  return request( "GET", API_URL.PARTNER );
}

export function uploadPartnerDetail( requestObj ) {
  return request( "POST", API_URL.PARTNER, requestObj );
}

export function updatePartnerDetail( requestObj ) {
  return request( "PUT", API_URL.PARTNER, requestObj );
}

export function getPartnerLookup( requestObj ) {
  return request( "GET", API_URL.GET_PARTNER_LOOKUP, requestObj );
}

export function updatePartnerProfileDetail( requestObj ) {
  return request( "PUT", API_URL.PARTNER_PROFILE_UPDATE, requestObj );
}

export const partnerServices = {
  getPartnerDetail,
  uploadPartnerDetail,
  getPartnerLookup,
  updatePartnerProfileDetail,
};
