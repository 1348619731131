import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useHistory, useParams } from "react-router-dom";
import { addEventAction, updateAdminEventAction, getAdminEventDetailAction } from "../../../store/actions/adminEvent";
import constant from "../../../util/constant";
import eventConstant from "../../../util/eventConstant";
import { CreateEvent } from "../../shared/events";

const { API_STATUS } = constant;
const { EVENT_API_TYPES } = eventConstant;

const AddEditAdminEvent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { apiType, status, adminEventDetail } = useSelector( state => ( {
    apiType: state.adminEvent.apiType,
    status: state.adminEvent.status,
    adminEventDetail: state.adminEvent.adminEventDetail,
  } ) );
  const { eventId } = useParams();
  const [ isEditEvent, setEditEventStatus ] = useState( false );
  const [ adminEventDetails, setAdminEventDetails ] = useState();

  useEffect( () => {
    if( eventId !== "0" ) {
      setEditEventStatus( true );
      dispatch( getAdminEventDetailAction( eventId ) );
    }
  }, [ eventId ] );

  useEffect( () => {
    if(
      status === API_STATUS.SUCCESS &&
      ( apiType === EVENT_API_TYPES.ADD_EVENT || apiType === EVENT_API_TYPES.UPDATE_ADMIN_EVENT )
    ) {
      toastr.success( EVENT_API_TYPES.ADD_EVENT === apiType ? "Event added successfully" : "Event updated successfully" );
      history.goBack();
    }
    else if( ( status === API_STATUS.SUCCESS ) &&
      ( apiType === EVENT_API_TYPES.GET_ADMIN_EVENT_DETAIL ) ) {
      setAdminEventDetails( adminEventDetail );
    }
    else if( ( status === API_STATUS.FAILURE ) &&
      ( apiType === EVENT_API_TYPES.GET_ADMIN_EVENT_DETAIL ) ) {
      history.goBack();
    }
  }, [ apiType, status ] );

  const handleOnSave = ( values ) => {
    const requestObj = {
      // eslint-disable-next-line camelcase
      event_name: values.eventName,
      description: values.description,
      unit: values.units.value,
    };

    if( isEditEvent ) {
      // eslint-disable-next-line camelcase
      requestObj.event_id = adminEventDetails.eventId;
      dispatch( updateAdminEventAction( requestObj ) );
    }
    else {
      dispatch( addEventAction( requestObj ) );
    }
  };

  return <CreateEvent isEditEvent={isEditEvent} eventDetail={adminEventDetails} onSave={handleOnSave} />;
};

export default AddEditAdminEvent;
