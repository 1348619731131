import React from "react";
import { Link } from "react-router-dom";

// eslint-disable-next-line react/prop-types
const ButtonMailto = ( { mailto, label } ) => {
  return (
    <Link
      to='#'
      onClick={( e ) => {
        window.location = mailto;
        e.preventDefault();
      }} className='link'
    >
      {label}
    </Link>
  );
};

export default ButtonMailto;
