import Enums from "../util/Enums";

const { SERVER_ERROR } = Enums;

export class AdminGamerAssets {
  constructor( obj ) {
    this.gameId = obj.gameId;
    this.status = obj.status || "";
    this.totalQxPoints = obj.QxPoints || 0;
    this.assets = obj?.assets?.length
      ? obj?.assets?.map( ( val ) => {
        return {
          gameServerError: val.status === SERVER_ERROR ? true : false,
          assetId: val?.assetId || "",
          gameId: val?.game_id || "",
          gameName: val?.name || "",
          quantity: val?.quantity || 0,
          unitPriceQxPoints: val?.unitPriceQxPoints || 0,
          qxPoints: ( val?.quantity * val?.unitPriceQxPoints ) || 0,
        };
      } )
      : [];
  }
}
