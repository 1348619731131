import constant from "../../util/constant";
import {
  ADMIN_GAMERS_LIST_SUCCESS,
  ADMIN_GAMERS_LIST_FAILURE,
  ADMIN_GAMER_LIST_API_REQUEST,
  ADMIN_GAMERS_API_END,
  ADMIN_GAMER_FILTER_LIST_API_REQUEST,
  ADMIN_GAMERS_FILTER_LIST_FAILURE,
  ADMIN_GAMERS_FILTER_LIST_SUCCESS,
  ADMIN_GAMER_API_REQUEST,
  ADMIN_GAMER_DETAIL_FAILURE,
  ADMIN_GAMER_DETAIL_SUCCESS,
  CLEAR_ADMIN_GAMER_DETAIL,
  ADMIN_GAMER_ASSET_API_REQUEST,
  ADMIN_GAMER_OFFER_API_REQUEST,
  ADMIN_GAMER_ASSET_LIST_SUCCESS,
  ADMIN_GAMER_ASSET_LIST_FAILURE,
  ADMIN_GAMER_OFFER_LIST_SUCCESS,
  ADMIN_GAMER_OFFER_LIST_FAILURE,
  ADMIN_GAMER_LIST_SUCCESS,
  ADMIN_GAMER_LIST_FAILURE,
  ADMIN_GAMER_ALL_LIST_SUCCESS,
  ADMIN_GAMER_ALL_LIST_FAILURE,
  GAMER_LINKED_OFFERS_SUCCESS,
  GAMER_LINKED_OFFERS_FAILURE,
  GAMER_EXCHANGE_TRANSACTION_SUCCESS,
  GAMER_EXCHANGE_TRANSACTION_FAILURE,
  ADMIN_GAMER_REDEEM_TRANS_SUCCESS,
  ADMIN_GAMER_REDEEM_TRANS_FAILURE,
  ADMIN_GAMER_REDEEM_TX_FILTER_SUCCESS,
  ADMIN_GAMER_REDEEM_TX_FILTER_FAILURE,
  GAMER_ASSETS_FAILURE,
  GAMER_ASSETS_SUCCESS,
  GAMER_OFFER_FILTER_SUCCESS,
  GAMER_OFFER_FILTER_FAILURE,
  ADMIN_GAMER_TOURNAMENTS_LIST_API_REQUEST,
  ADMIN_GAMER_TOURNAMENTS_LIST_SUCCESS,
  ADMIN_GAMER_TOURNAMENTS_LIST_FAILURE,
  ADMIN_GAMER_TOURNAMENTS_FILTER_SUCCESS,
  ADMIN_GAMER_TOURNAMENTS_FILTER_FAILURE,
  CLEAR_ADMIN_REDEEM_TRANSACTION,
  ADMIN_GAMER_TXN_HISTORY_LIST_API_REQUEST,
  ADMIN_GAMER_TXN_HISTORY_LIST_SUCCESS,
  ADMIN_GAMER_TXN_HISTORY_LIST_FAILURE,
  GAMER_LINKED_OFFERS_API_REQUEST,
  CLEAR_ADMIN_GAMER_OFFERS,
  GAMER_OFFER_REWARD_TXN_API_REQUEST,
  GAMER_OFFER_REWARD_TXN_SUCCESS,
  GAMER_OFFER_REWARD_TXN_FAILURE
} from "../actionTypes";

const { API_STATUS } = constant;

const DEFAULT_STATE = {
  loading: false,
  message: "",
  status: "",
  apiType: "",
  adminGamers: { gamers: [], meta: "" },
  adminGamerDetail: {},
  gamersFilterList: { gamersList: [], maxLinkedGames: 0, maxAcqyrCashBalance: 0 },
  gamerDetail: {},
  adminFilterDetail: {},
  gamerAssetList: {},
  gamerOfferList: [],
  gamerGameList: [],
  gamerAllGameList: [],
  gamerLinkedOffers: {
    linkedOffers: [],
    meta: "",
    offerNames: [],
    gameNames: [],
    offerStatus: [],
  },
  exchangeTransaction: { transaction: [], meta: "" },
  redeemTransaction: { transactions: [], meta: {} },
  offerFilters: { offerList: [], gameList: [] },
  redeemTxFilters: {
    qxPointsRange: {},
    netRedeemAmountRange: {},
    status: [],
  },
  assets: {
    assets: [],
    gameNames: [],
    assetNames: [],
    assetIds: [],
    qxPointMinMax: { min: 0, max: 0 },
  },
  gamerTournamentList: { tournamentList: [], meta: "" },
  gamerTournamentFilters: { tournamentList: [], gameList: [] },
  txnHistoryList: [],
  txnHistoryFilters: {
    itemNames: [],
    modeNames: [],
    statusNames: [],
  },
  gamerRewardTxn: {
    rewardTxnList: [],
    rewardTxnFilters: {
      offerNames: [],
      partnerNames: [],
      gameNames: [],
      qxPointMinMax: { min: 0, max: 0 },
      gamerPlaceMinMax: { min: 0, max: 0 },
    },
  },
};

// TODO: refactor to avoid complexity
// eslint-disable-next-line complexity
const adminGamer = ( state = DEFAULT_STATE, action = "" ) => {
  switch ( action.type ) {
    case ADMIN_GAMER_LIST_API_REQUEST:
    case ADMIN_GAMER_FILTER_LIST_API_REQUEST:
    case ADMIN_GAMER_API_REQUEST:
    case ADMIN_GAMER_ASSET_API_REQUEST:
    case ADMIN_GAMER_OFFER_API_REQUEST:
    case ADMIN_GAMER_TOURNAMENTS_LIST_API_REQUEST:
    case ADMIN_GAMER_TXN_HISTORY_LIST_API_REQUEST:
    case GAMER_LINKED_OFFERS_API_REQUEST:
    case GAMER_OFFER_REWARD_TXN_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
      };
    case ADMIN_GAMERS_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case ADMIN_GAMERS_LIST_SUCCESS:
      return {
        ...state,
        adminGamers: {
          gamers:
              action.payload.meta.page === 1
                ? action.payload.dataList
                : [ ...state.adminGamers.gamers, ...action.payload.dataList ],
          meta: action.payload.meta,
        },
        status: API_STATUS.SUCCESS,
      };
    case ADMIN_GAMERS_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        adminGamers: { gamers: [], meta: "" },
      };
    case ADMIN_GAMERS_FILTER_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        gamersFilterList: {
          gamersList: action.payload?.dataList,
          maxLinkedGames: action.payload?.maxLinkedGames,
          maxAcqyrCashBalance: action.payload?.maxAcqyrCashBalance,
          gamersPlan: action.payload?.gamersPlan,
        },
      };
    case ADMIN_GAMER_REDEEM_TRANS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        redeemTransaction: {
          transactions: Number( action.payload.meta.page ) === 1
            ? action.payload.dataList
            : [ ...state.redeemTransaction.transactions, ...action.payload.dataList ],
          meta: action.payload.meta,
        },
      };
    case GAMER_ASSETS_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        assets: {
          assets: action.payload.dataList,
          gameNames: action.payload.gameNames,
          assetNames: action.payload.assetNames,
          assetIds: action.payload.assetIds,
          qxPointMinMax: action.payload.qxPointMinMax,
        },
      };
    case ADMIN_GAMER_REDEEM_TX_FILTER_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        redeemTxFilters: action.payload,
      };
    case GAMER_OFFER_FILTER_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        offerFilters: { offerList: action.payload.offerNames, gameList: action.payload.gameNames },
      };
    case ADMIN_GAMERS_FILTER_LIST_FAILURE:
    case ADMIN_GAMER_ASSET_LIST_FAILURE:
    case ADMIN_GAMER_OFFER_LIST_FAILURE:
    case ADMIN_GAMER_LIST_FAILURE:
    case ADMIN_GAMER_ALL_LIST_FAILURE:
    case ADMIN_GAMER_REDEEM_TRANS_FAILURE:
    case ADMIN_GAMER_REDEEM_TX_FILTER_FAILURE:
    case GAMER_OFFER_FILTER_FAILURE:
    case GAMER_ASSETS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        loading: false,
      };
    case ADMIN_GAMER_DETAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        gamerDetail: action.payload,
      };
    case ADMIN_GAMER_DETAIL_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        gamerDetail: {},
      };
    case CLEAR_ADMIN_GAMER_DETAIL:
      return {
        ...state,
        gamerDetail: {},
      };
    case ADMIN_GAMER_ASSET_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        gamerAssetList: action.payload.dataList,
      };
    case ADMIN_GAMER_OFFER_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        gamerOfferList: action.payload.dataList,
      };
    case ADMIN_GAMER_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        gamerGameList: action.payload.userGames,
      };
    case ADMIN_GAMER_ALL_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        gamerAllGameList: action.payload,
      };
    case GAMER_LINKED_OFFERS_SUCCESS:
      return {
        ...state,
        gamerLinkedOffers: {
          linkedOffers:
                action.payload.meta.page === 1
                  ? action.payload.currentOfferList
                  : [ ...state.gamerLinkedOffers.linkedOffers, ...action.payload.currentOfferList ],
          meta: action.payload.meta,
          gameNames: action.payload.gameNames,
          offerNames: action.payload.offerNames,
          offerStatus: action.payload.offerStatus,
        },
        status: API_STATUS.SUCCESS,
      };
    case GAMER_LINKED_OFFERS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        gamerLinkedOffers: {
          linkedOffers: [],
          meta: "",
          gameNames: [],
          offerNames: [],
          offerStatus: [],
        },
      };
    case CLEAR_ADMIN_GAMER_OFFERS:
      return {
        ...state,
        gamerLinkedOffers: {
          linkedOffers: [],
          meta: "",
          gameNames: [],
          offerNames: [],
          offerStatus: [],
        },
      };
    case GAMER_EXCHANGE_TRANSACTION_SUCCESS:
      return {
        ...state,
        exchangeTransaction: {
          transaction:
                action.payload.meta.page === 1
                  ? action.payload.transactionList
                  : [ ...state.exchangeTransaction.transaction, ...action.payload.transactionList ],
          meta: action.payload.meta,
          analyticsGraphData: action.payload?.analyticsGraphData,
        },
        status: API_STATUS.SUCCESS,
      };

    case GAMER_EXCHANGE_TRANSACTION_FAILURE: {
      return {
        ...state,
        status: API_STATUS.FAILURE,
        exchangeTransaction: { transaction: [], meta: "" },
      };
    }

    case ADMIN_GAMER_TOURNAMENTS_LIST_SUCCESS: {
      return {
        ...state,
        gamerTournamentList: {
          tournamentList:
                action.payload.meta.page === 1
                  ? action.payload.tournamentList
                  : [ ...state.gamerTournamentList.tournamentList, ...action.payload.tournamentList ],
          meta: action.payload.meta,
        },
        status: API_STATUS.SUCCESS,
      };
    }

    case ADMIN_GAMER_TOURNAMENTS_LIST_FAILURE: {
      return {
        ...state,
        status: API_STATUS.FAILURE,
        gamerTournamentList: { tournamentList: [], meta: "" },
      };
    }

    case ADMIN_GAMER_TOURNAMENTS_FILTER_SUCCESS: {
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        gamerTournamentFilters: { tournamentList: action.payload.offerNames, gameList: action.payload.gameNames },
      };
    }

    case ADMIN_GAMER_TOURNAMENTS_FILTER_FAILURE: {
      return {
        ...state,
        status: API_STATUS.FAILURE,
        gamerTournamentFilters: { tournamentList: [], gameList: [] },
      };
    }

    case CLEAR_ADMIN_REDEEM_TRANSACTION:
      return {
        ...state,
        redeemTxFilters: {
          qxPointsRange: {},
          netRedeemAmountRange: {},
          status: [],
        },
      };
    case ADMIN_GAMER_TXN_HISTORY_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        txnHistoryList: action.payload.txnHistoryList,
        txnHistoryFilters: {
          itemNames: action.payload.itemNames,
          modeNames: action.payload.modeNames,
          statusNames: action.payload.statusNames,
        },
      };

    case ADMIN_GAMER_TXN_HISTORY_LIST_FAILURE: {
      return {
        ...state,
        status: API_STATUS.FAILURE,
        loading: false,
        txnHistoryList: [],
        txnHistoryFilters: {
          itemNames: [],
          modeNames: [],
          statusNames: [],
        },
      };
    }

    case GAMER_OFFER_REWARD_TXN_SUCCESS: {
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        gamerRewardTxn: {
          rewardTxnList: action.payload.rewardTxnList,
          rewardTxnFilters: {
            offerNames: action.payload.offerNames,
            partnerNames: action.payload.partnerNames,
            gameNames: action.payload.gameNames,
            qxPointMinMax: action.payload.qxPointMinMax,
            gamerPlaceMinMax: action.payload.gamerPlaceMinMax,
          },
        },
      };
    }

    case GAMER_OFFER_REWARD_TXN_FAILURE: {
      return {
        ...state,
        status: API_STATUS.FAILURE,
        gamerRewardTxn: {
          rewardTxnList: [],
          rewardTxnFilters: {
            offerNames: [],
            partnerNames: [],
            gameNames: [],
            qxPointMinMax: { min: 0, max: 0 },
            gamerPlaceMinMax: { min: 0, max: 0 },
          },
        },
      };
    }

    default:
      return state;
  }
};

export default adminGamer;

