import { debounce } from "lodash";
import React, { useMemo, useCallback, useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { useAddQuery, useForm } from "../../../hooks";
import { getGamerRedeemTxnAction, getGamerRedeemFilterAction } from "../../../store/actions/adminGamer";
import adminGamerConstant from "../../../util/adminGamerConstant";
import constant from "../../../util/constant";
import { useSetUrlParams, sliderStep, checkFilterApplied } from "../../../util/helpers";
import { redeemTxTableConfigs } from "../../../util/tableConstant";
import CustomDatePicker from "../../shared/customDatePicker";
import CustomSlider from "../../shared/customSlider";
import MultiSelectCheckbox from "../../shared/multiSelectCheckbox";
import ListSubHeader from "./listSubHeader";
import RedeemTransactionList from "./redeemTransactionList";

const { API_STATUS, ORDER_BY, FILTERS_NAME, BRANDING: { APP_SHORT_NAME } } = constant;
const { GAMER_USERS_API_TYPES } = adminGamerConstant;

const RedeemTransactions = () => {
  const { addQuery } = useAddQuery();
  const dispatch = useDispatch();
  const location = useLocation();
  const { apiType, status, transactions, txStatus, netRedeemAmountRange, qxPointsRange, listMetaData } = useSelector( state => ( {
    apiType: state.adminGamer.apiType,
    status: state.adminGamer.status,
    transactions: state.adminGamer.redeemTransaction.transactions,
    listMetaData: state.adminGamer.redeemTransaction.meta,
    txStatus: state.adminGamer.redeemTxFilters.status,
    netRedeemAmountRange: state.adminGamer.redeemTxFilters.netRedeemAmountRange,
    qxPointsRange: state.adminGamer.redeemTxFilters.qxPointsRange,
  } ) );
  const paramsQuery = new URLSearchParams( window.location.search );

  function getUrlParameter( key, col ) {
    let paramsQuerys;
    const keys = [ "searchKey", "sortBy", "sortOrder", "qxPtsRdmMaxPoint", "qxPtsRdmMinPoint", "netRdmAmtMaxPoint", "netRdmAmtMinPoint", "startDateRedeemed", "endDateRedeemed" ];

    if( keys.includes( key ) ) {
      paramsQuerys = paramsQuery.get( key ) === null ? col : paramsQuery.get( key );
    }

    if( key === "status" ) {
      paramsQuerys = paramsQuery.get( key ) === "" ? [] : JSON.parse( paramsQuery.get( key ) );
    }

    return paramsQuerys;
  }

  const { values, handleChange } = useForm(
    {
      searchText: getUrlParameter( "searchText", null ),
      searchTextValue: getUrlParameter( "searchText", null ),
      sortBy: getUrlParameter( "sortBy", null ),
      sortOrder: getUrlParameter( "sortOrder", ORDER_BY.ASC ),
      page: 1,
      status: getUrlParameter( "status", "" ),
      qxPtsRdmMax: getUrlParameter( "qxPtsRdmMaxPoint" ),
      qxPtsRdmMin: getUrlParameter( "qxPtsRdmMinPoint" ),
      qxPtsRdmMaxPoint: getUrlParameter( "qxPtsRdmMaxPoint" ),
      qxPtsRdmMinPoint: getUrlParameter( "qxPtsRdmMinPoint" ),
      netRdmAmtMax: getUrlParameter( "netRdmAmtMaxPoint" ),
      netRdmAmtMin: getUrlParameter( "netRdmAmtMinPoint" ),
      netRdmAmtMaxPoint: getUrlParameter( "netRdmAmtMaxPoint" ),
      netRdmAmtMinPoint: getUrlParameter( "netRdmAmtMinPoint" ),
      startDateRedeemed: getUrlParameter( "startDateRedeemed" ) ? new Date( getUrlParameter( "startDateRedeemed" ) ) : null,
      endDateRedeemed: getUrlParameter( "endDateRedeemed" ) ? new Date( getUrlParameter( "endDateRedeemed" ) ) : null,
      initialStartDateRedeemed: getUrlParameter( "startDateRedeemed" ),
      initialEndDateRedeemed: getUrlParameter( "endDateRedeemed" ),
    },
    {}
  );
  const sliderMinMaxObj = {
    qxPtsRdmMinPoint: qxPointsRange.minQxPoints,
    qxPtsRdmMaxPoint: qxPointsRange.maxQxPoints,
    netRdmAmtMinPoint: netRedeemAmountRange.minNetAmount,
    netRdmAmtMaxPoint: netRedeemAmountRange.minNetAmount,
  };
  const isFilterApplied = checkFilterApplied( window.location.search, FILTERS_NAME.GAMER_REDEEM_TRANSACTIONS, sliderMinMaxObj );
  const { gamerId } = useParams();
  const memoizedTableConfig = useMemo( () => redeemTxTableConfigs, [] );

  const handleSorting = ( sortBy, sortingType ) => {
    handleChange( "page", 1 );
    handleChange( "sortBy", sortBy );
    handleChange( "sortOrder", sortingType );
    addQuery( useSetUrlParams( "sortBy", sortBy ), location.state );
    addQuery( useSetUrlParams( "sortOrder", sortingType ), location.state );
  };

  const memoizedHandleClick = useCallback( ( sortBy, orderType ) => handleSorting( sortBy, orderType ), [] );

  const multiSelectedItems = useMemo( () => {
    return {
      status: values.status,
    };
  }, [ values.status ] );

  const optionsLists = useMemo( () => {
    return {
      txStatus,
    };
  }, [ txStatus ] );

  const debouncedSave = useCallback(
    debounce( nextValue => handleChange( "searchText", nextValue ), 1000 ),
    []
  );

  const handleSearch = useCallback( ( e ) => {
    const { value } = e.target;

    addQuery( useSetUrlParams( "searchText", value ), location.state );
    handleChange( "searchTextValue", value );
    debouncedSave( value );
    handleChange( "page", 1 );
  } );

  const handleSelectChange = useCallback( ( obj, name ) => listHandle( obj, name ), [] );

  const listHandle = ( obj, name ) => {
    if( obj.length > 0 ) {
      handleChange( "page", 1 );
      handleChange( name, obj );
      addQuery( useSetUrlParams( name, JSON.stringify( obj ) ), location.state );
    }
    else {
      addQuery( useSetUrlParams( name, "" ), location.state );
      handleChange( name );
    }
  };

  const clearHandle = ( params ) => {
    if( params ) {
      addQuery( useSetUrlParams( params, "" ), location.state );
      handleChange( params, [] );
    }
  };

  const handleClearMultiSelect = useCallback(
    params => clearHandle( params ), []
  );

  useEffect( () => {
    if( !values.qxPtsRdmMaxPoint ) {
      handleChange( "qxPtsRdmMax", qxPointsRange.maxQxPoints );
      handleChange( "qxPtsRdmMin", qxPointsRange.minQxPoints );
      handleChange( "qxPtsRdmMaxPoint", qxPointsRange.maxQxPoints );
      handleChange( "qxPtsRdmMinPoint", qxPointsRange.minQxPoints );
    }

    if( !values.netRdmAmtMaxPoint ) {
      handleChange( "netRdmAmtMax", netRedeemAmountRange.maxNetAmount );
      handleChange( "netRdmAmtMin", netRedeemAmountRange.minNetAmount );
      handleChange( "netRdmAmtMaxPoint", netRedeemAmountRange.maxNetAmount );
      handleChange( "netRdmAmtMinPoint", netRedeemAmountRange.minNetAmount );
    }
  }, [ qxPointsRange, netRedeemAmountRange ] );

  useEffect( () => {
    if( ( apiType === GAMER_USERS_API_TYPES.GET_GAMER_REDEEM_TRANSACTION ) && ( status === API_STATUS.SUCCESS ) ) {
      dispatch( getGamerRedeemFilterAction( { gamerQxId: gamerId } ) );
    }
  }, [ apiType, status ] );

  const requestParams = Object.assign(
    {
      sortOrder: values.sortOrder,
      perPage: 10,
      page: values.page,
      sortBy: values.sortBy,
      searchKey: values.searchText,
      startDate: values.initialStartDateRedeemed,
      endDate: values.initialEndDateRedeemed,
      minQxPoints: values.qxPtsRdmMin,
      maxQxPoints: values.qxPtsRdmMax,
      minNetAmount: values.netRdmAmtMin,
      maxNetAmount: values.netRdmAmtMax,
    }
  );

  useEffect( () => {
    const redeemTrxStatus = values.status?.map( s => s.value ).join( "" ) || "";

    if( redeemTrxStatus ) {
      requestParams.redeemTrxStatus = redeemTrxStatus;
    }

    dispatch( getGamerRedeemTxnAction( gamerId, requestParams ) );
  }, [
    values.page,
    values.initialStartDateRedeemed,
    values.initialEndDateRedeemed,
    values.sortBy,
    values.sortOrder,
    values.qxPtsRdmMax,
    values.qxPtsRdmMin,
    values.netRdmAmtMax,
    values.netRdmAmtMin,
    values.searchText,
    values.status,
  ] );

  const debounceSlider = useCallback(
    debounce( ( newValue, minKey, maxKey ) => {
      handleChange( [ minKey ], newValue[ 0 ] );
      handleChange( [ maxKey ], newValue[ 1 ] );
    }, 1000 ),
    []
  );

  const handlePointsChangeSlider = ( e, newValue ) => {
    handleChange( "page", 1 );
    addQuery( useSetUrlParams( "qxPtsRdmMinPoint", newValue[ 0 ] ), location.state );
    addQuery( useSetUrlParams( "qxPtsRdmMaxPoint", newValue[ 1 ] ), location.state );
    handleChange( "qxPtsRdmMinPoint", newValue[ 0 ] );
    handleChange( "qxPtsRdmMaxPoint", newValue[ 1 ] );
    debounceSlider( newValue, "qxPtsRdmMin", "qxPtsRdmMax" );
  };

  const handleRedeemAmountChangeSlider = ( e, newValue ) => {
    handleChange( "page", 1 );
    addQuery( useSetUrlParams( "netRdmAmtMinPoint", newValue[ 0 ] ), location.state );
    addQuery( useSetUrlParams( "netRdmAmtMaxPoint", newValue[ 1 ] ), location.state );
    handleChange( "netRdmAmtMinPoint", newValue[ 0 ] );
    handleChange( "netRdmAmtMaxPoint", newValue[ 1 ] );
    debounceSlider( newValue, "netRdmAmtMin", "netRdmAmtMax" );
  };

  const [ showFilter, setShowFilter ] = useState( false );
  const showFilterScreen = () => setShowFilter( !showFilter );

  return (
    <div className="main-wrapper">
      <div className="main-right-wrapper">
        <ListSubHeader
          title='Redeem Transactions'
          searchPlaceholder='Search by Status'
          gamerId={gamerId}
          searchText={values.searchTextValue}
          searchHandle={handleSearch}
          isFilterApplied={isFilterApplied}
          showFilterScreen={showFilterScreen}
          showFilter={showFilter}
        />
        <div className='filter-tabs-wrapper mb-0'>
          <div className='filter-boxes offer-filter mr-0'>
            <div className={`filter-boxes mr-0 bottom-margin ${ !showFilter ? "" : "remove-filter" }`}>
              <Row>
                <Col md={3}>
                  <Form.Group className='custom-multiselect mb-0' data-testid="full-name">
                    <CustomSlider
                      values={values}
                      pointsHandler={handlePointsChangeSlider}
                      handleChange={handleChange}
                      min={qxPointsRange.minQxPoints}
                      max={qxPointsRange.maxQxPoints}
                      minName={"qxPtsRdmMinPoint"}
                      maxName={"qxPtsRdmMaxPoint"}
                      label={`${ APP_SHORT_NAME } Points Redeemed`}
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className='custom-multiselect mb-0' data-testid="full-name">
                    <Form.Label>Status</Form.Label>
                    <MultiSelectCheckbox
                      multiOptionsList={optionsLists.txStatus}
                      handleSelectChange={handleSelectChange}
                      multiSelectedItems={multiSelectedItems.status}
                      handleClearMultiSelect={handleClearMultiSelect}
                      name='status'
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className='custom-multiselect mb-0' data-testid="full-name">
                    <CustomSlider
                      values={values}
                      pointsHandler={handleRedeemAmountChangeSlider}
                      step={sliderStep( netRedeemAmountRange.minNetAmount, netRedeemAmountRange.maxNetAmount )}
                      handleChange={handleChange}
                      min={netRedeemAmountRange.minNetAmount}
                      max={netRedeemAmountRange.maxNetAmount}
                      minName={"netRdmAmtMinPoint"}
                      maxName={"netRdmAmtMaxPoint"}
                      label={"Net Redeem Amount"}
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className='custom-datepicker range-picker' data-testid="full-name">
                    <Form.Label>Date Redeemed</Form.Label>
                    <CustomDatePicker
                      value={values.startDateRedeemed}
                      startDate={values.startDateRedeemed}
                      endDate={values.endDateRedeemed}
                      placeholder={"dd mmm yyyy - dd mmm yyyy"}
                      handleChange={( e ) => {
                        if( e[ 0 ] === null ) {
                          handleChange( "startDateRedeemed", null );
                          handleChange( "endDateRedeemed", null );
                          addQuery( useSetUrlParams( "endDateRedeemed", "" ), location.state );
                          addQuery( useSetUrlParams( "startDateRedeemed", "" ), location.state );
                          handleChange( "initialStartDateRedeemed", null );
                          handleChange( "initialEndDateRedeemed", null );
                        }
                        else {
                          handleChange( "startDateRedeemed", e[ 0 ] );

                          if( values.endDateRedeemed ) {
                            handleChange( "endDateRedeemed", null );
                          }

                          if( e[ 1 ] ) {
                            handleChange( "page", 1 );
                            addQuery( useSetUrlParams( "startDateRedeemed", new Date( e[ 0 ] ).toLocaleDateString( "fr-CA" ) ), location.state );
                            handleChange( "initialStartDateRedeemed", new Date( e[ 0 ] ).toLocaleDateString( "fr-CA" ) );
                            handleChange( "initialEndDateRedeemed", new Date( e[ 1 ] ).toLocaleDateString( "fr-CA" ) );
                            addQuery( useSetUrlParams( "endDateRedeemed", new Date( e[ 1 ] ).toLocaleDateString( "fr-CA" ) ), location.state );
                            handleChange( "endDateRedeemed", e[ 1 ] );
                          }
                        }
                      }}
                      selectsRange={true}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <RedeemTransactionList
          values={values}
          transactions={transactions}
          handleChange={handleChange}
          memoizedTableConfig={memoizedTableConfig}
          memoizedHandleClick={memoizedHandleClick}
          listMetaData={listMetaData}
          showFilter={showFilter}
        />
      </div>
    </div>
  );
};

export default RedeemTransactions;
