import { forEach } from "lodash";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { useForm, useAddQuery, useFilter } from "../../../../hooks";
import { getPartnerGamerDetailAction, getPartnerGamerLinkedGamesAction } from "../../../../store/actions/partnerGamer";
import Enums from "../../../../util/Enums";
import adminGamerConstant from "../../../../util/adminGamerConstant";
import constant from "../../../../util/constant";
import { concatStrings, useSetUrlParams, handleParseJSON } from "../../../../util/helpers";
import partnerGamerConstant from "../../../../util/partnerGamerConstant";
import { partnerGamerGameTableConfigs } from "../../../../util/tableConstant";
import TableHeaderRow from "../../../shared/tableHeadingRow";
import LinkedGamesFilters from "./linkedGamesFilters";
import LinkedGamesHeader from "./linkedGamesHeader";
import LinkedGamesList from "./linkedGamesList";

const { API_STATUS, ORDER_BY } = constant;
const { GAME_LIST_SORT } = adminGamerConstant;
const { API_TYPES } = partnerGamerConstant;
const { DEFAULT_RADIX, DATA_VIEW_TYPE } = Enums;

const LinkedGames = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { gamerId: URLString } = useParams();
  const { 0: gamerId } = String( URLString ).split( "?" );
  const { addQuery } = useAddQuery();
  const paramsQuery = new URLSearchParams( window.location.search );
  const { setPureData, filteredData, filterData, handleFilterChange, clearSelectedFilter } = useFilter();
  const {
    apiType,
    gamerDetail,
    status,
    gameList,
    gamerLinkedGamesList,
  } = useSelector( state => ( {
    gamerLinkedGamesList: state.partnerGamer.gamerLinkedGamesList,
    gameList: state.partnerGamer.gameList,
    gamerDetail: state.partnerGamer.gamerDetail,
    status: state.partnerGamer.status,
    apiType: state.partnerGamer.apiType,
  } ) );
  const { values, handleChange } = useForm(
    {
      viewType: parseInt( DATA_VIEW_TYPE.GRID, DEFAULT_RADIX ),
      sortBy: "name",
      sortOrder: ORDER_BY.ASC,
      gameIds: [],
      startDate: false,
      endDate: false,
      initialStartDate: paramsQuery.get( "startDate" ),
      initialEndDate: paramsQuery.get( "endDate" ),
    },
    {}
  );
  const requestParams = Object.assign(
    {
      sortOrder: values.sortOrder,
      sortBy: values.sortBy,
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      gameIds: concatStrings( values.gameIds, "," ) || undefined,
      startDate: values.initialStartDate,
      endDate: values.initialEndDate,
      gameFilterKey: "gameId",
    }
  );

  const updateStateFromUrl = () => {
    if( paramsQuery.get( "viewType" ) ) {
      handleChange( "viewType", parseInt( paramsQuery.get( "viewType" ), DEFAULT_RADIX ) );
    }

    if( paramsQuery.get( "sortBy" ) && paramsQuery.get( "sortOrder" ) ) {
      handleChange( "sortBy", paramsQuery.get( "sortBy" ) );
      handleChange( "sortOrder", paramsQuery.get( "sortOrder" ) );
      toggleClass( paramsQuery.get( "sortBy" ), paramsQuery.get( "sortOrder" ), true );
    }

    if( paramsQuery.get( "gameIds" ) ) {
      const parseData = handleParseJSON( paramsQuery.get( "gameIds" ) );

      handleChange( "gameIds", parseData );
    }

    if( paramsQuery.get( "startDate" ) ) {
      handleChange( "startDate", paramsQuery.get( "startDate" ) );
    }

    if( paramsQuery.get( "endDate" ) ) {
      handleChange( "endDate", paramsQuery.get( "endDate" ) );
    }
  };

  useEffect( () => {
    dispatch( getPartnerGamerLinkedGamesAction( gamerId, { fetchAllRecords: true } ) );
  }, [] );
  useEffect( () => {
    if(
      ( status === API_STATUS.SUCCESS ) &&
      ( apiType === API_TYPES.GET_GAMER_LINKED_GAMES )
    ) {
      dispatch( getPartnerGamerDetailAction( gamerId ) );
      handleChange( "viewType", values.viewType );
      setPureData( gamerLinkedGamesList );
      updateStateFromUrl();
      filterData( requestParams );
    }
  }, [ status, apiType ] );
  useEffect( () => {
    filterData( requestParams );
  }, [
    values.gameIds,
    values.initialStartDate,
    values.initialEndDate,
    values.sortBy,
    values.sortOrder,
  ] );

  const handleListSorting = ( sortBy, sortingType ) => {
    handleChange( "sortBy", sortBy );
    handleChange( "sortOrder", sortingType );
    addQuery( useSetUrlParams( "sortBy", sortBy ), location.state );
    addQuery( useSetUrlParams( "sortOrder", sortingType ), location.state );
    toggleClass( sortBy, sortingType, true );
  };

  const handleMemoizedSortingClick = useCallback(
    ( sortBy, orderType ) => handleListSorting( sortBy, orderType ),
    []
  );
  const memoizedGamesTableConfig = useMemo( () => partnerGamerGameTableConfigs, [] );
  const [ showFilter, setShowFilter ] = useState( false );
  const showFilterScreen = () => setShowFilter( !showFilter );

  const toggleClass = ( params, order, isQueryParamsUpdated = false ) => {
    let newOrder = ORDER_BY.ASC;

    forEach( sortColumns, ( value ) => {
      value.status = false;

      if( value.col === params ) {
        value.status = true;

        if( isQueryParamsUpdated ) {
          newOrder = order;
        }
        else {
          if( order === ORDER_BY.ASC ) {
            value.order = ORDER_BY.DESC;
          }
          else {
            value.order = ORDER_BY.ASC;
          }

          newOrder = value.order;
        }
      }
    } );
    setSortColumns( sortColumns );
    handleChange( "sortBy", params );
    handleChange( "sortOrder", newOrder );
    addQuery( useSetUrlParams( "sortBy", params ), location.state );
    addQuery( useSetUrlParams( "sortOrder", newOrder ), location.state );
  };

  const [ sortColumns, setSortColumns ] = useState( [
    {
      col: GAME_LIST_SORT.GAME_NAME,
      value: "Game Name",
      status: true,
      order: ORDER_BY.ASC,
    },
    {
      col: "startDateOriginal",
      value: GAME_LIST_SORT.DATE_LINKED,
      status: false,
    },
  ] );

  return (
    <>
      <div className="main-wrapper">
        <div className="main-right-wrapper">
          <LinkedGamesHeader
            gamerDetail={gamerDetail}
            gamerId={gamerId}
            handleChange={handleChange}
            showFilter={showFilter}
            showFilterScreen={showFilterScreen}
            location={location}
          />
          <LinkedGamesFilters
            showFilter={showFilter}
            gameList={gameList}
            handleChange={handleChange}
            values={values}
            sortColumns={sortColumns}
            toggleClass={toggleClass}
            location={location}
            handleFilterChange={handleFilterChange}
            clearSelectedFilter={clearSelectedFilter}
          />
          <div className={`games-list-table-head gamer-game-head ${ ( !values.viewType ) ? "active" : "" }`}>
            <div className='table-head-body'>
              <TableHeaderRow
                configData={memoizedGamesTableConfig}
                onAscOrder={handleMemoizedSortingClick}
                onDescOrder={handleMemoizedSortingClick}
              />
            </div>
          </div>
          <LinkedGamesList
            gamerId={gamerId}
            gamerDetail={gamerDetail}
            linkedGamesList={filteredData}
            values={values}
            location={location}
          />
        </div>
      </div>
    </>
  );
};

export default LinkedGames;
