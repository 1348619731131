import HelpIcon from "@material-ui/icons/Help";
import React, { useState, useMemo, useCallback, useEffect, useRef } from "react";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Row from "react-bootstrap/Row";
import Tooltip from "react-bootstrap/Tooltip";
import { Scrollbars } from "react-custom-scrollbars";
import { useSelector, useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useHistory, Link, useLocation } from "react-router-dom";
import { OfferTemplateRequest } from "../../../entities/newOfferTemplateRequest";
import { OfferTemplateDetail } from "../../../entities/offerTemplateDetail";
import { useForm, useFilter, useAddQuery, useModal, useToggle } from "../../../hooks";
import { getPartnerLookupAction } from "../../../store/actions/partnerLookup";
import { getPartnerOfferAction, deletePartnerOfferAction, addNewOfferTemplateAction } from "../../../store/actions/partnerOffer";
import { addNewPartnerOfferDraftAction } from "../../../store/actions/partnerOfferDraft";
import Enums from "../../../util/Enums";
import adminConstant from "../../../util/adminConstant";
import constant from "../../../util/constant";
import {
  getUniqueName,
  useSetUrlParams,
  checkFilterApplied,
  formatNumberAsPerLocale,
  getEligibleSubscriptionPlans
} from "../../../util/helpers";
import { games, logoColor } from "../../../util/icons";
import offerConstant from "../../../util/offerConstant";
import { partnerOfferTableConfigs, partnerTournamentTableConfigs } from "../../../util/tableConstant";
import tournamentConstant from "../../../util/tournamentConstant";
import CustomDatePicker from "../../shared/customDatePicker";
import { ScrollPage } from "../../shared/customScrollBar";
import MultiSelectCheckbox from "../../shared/multiSelectCheckbox";
import SearchHeader from "../../shared/searchHeader";
import TableHeaderRow from "../../shared/tableHeadingRow";
import ModalPopup from "../modal";
import MoreOptions from "./moreOptions";
import OptInDetails from "./optInDetails";
import TournamentOptions from "./tournamentOptions";

const {
  ROUTES,
  ORDER_BY,
  API_STATUS,
  FILTERS_NAME,
  MESSAGE: {
    REMOVE_OFFER_DRAFT_SUCCESS_MESSAGE,
    REMOVE_OFFER_TEMPLATE_SUCCESS_MESSAGE,
    REMOVE_TOURNAMENT_DRAFT_SUCCESS_MESSAGE,
    REMOVE_TOURNAMENT_TEMPLATE_SUCCESS_MESSAGE,
  },
  BRANDING: { APP_SHORT_NAME },
} = constant;
const {
  OFFER_API_TYPES,
  OFFER_LIST_SORT,
  OFFER_STATUS_DROPDOWN,
  OFFER_DETAIL_STATUS,
  OFFER_TYPE_STATUS,
  TYPE_TO_BE_EDITED,
  REQUEST_TYPE,
  SCREEN_TYPE,
  TOURNAMENT_STATUS_DROPDOWN,
  OPT_IN_STATUS_DROPDOWN,
} = offerConstant;
const { MODAL_TYPE_DETAIL } = adminConstant;
const { TOURNAMENT_TYPE_STATUS, TABS } = tournamentConstant;
const { PER_PAGE_RECORDS_LIMIT } = Enums;

// TODO: refactor to avoid complexity
// eslint-disable-next-line complexity
const PartnerOfferList = () => {
  const ref = useRef( null );
  const history = useHistory();
  const location = useLocation();
  const { setPureData, filteredData, filterData } = useFilter();
  const { addQuery } = useAddQuery();
  const { gridViewToggle } = useToggle();
  const dispatch = useDispatch();
  const [ , , screenType ] = location.pathname.split( "/" );
  const isOfferScreen = screenType === SCREEN_TYPE.OFFER;
  const entityType = isOfferScreen ? "Offer" : "Tournament";
  const {
    partnerLogo,
    partnerId,
    offers,
    status,
    apiType,
    lookup,
    draftApiType,
    draftStatus,
    cloneDraft,
    configuration,
    cloneOfferDetail } = useSelector( state => ( {
    partnerLogo: state.partner.partnerDetail.logo,
    partnerId: state.partner.partnerDetail.partnerId,
    offers: state.partnerOffer.offers,
    status: state.partnerOffer.status,
    apiType: state.partnerOffer.apiType,
    lookup: state.partnerLookup.lookup,
    draftApiType: state.partnerOfferDraft.apiType,
    draftStatus: state.partnerOfferDraft.status,
    cloneDraft: state.partnerOfferDraft.cloneDraft,
    cloneOfferDetail: state.partnerOffer.cloneOfferDetail,
    configuration: state.partner.partnerDetail?.configuration || {},
  } ) );
  const [ sortColumns, setSortColumns ] = useState( [
    { col: OFFER_LIST_SORT.OFFER_NAME, value: isOfferScreen ? "Offer Name" : "Tournament Name", status: true, order: ORDER_BY.ASC },
    { col: OFFER_LIST_SORT.GAME_NAME, value: "Game Name", status: false, order: null },
    { col: OFFER_LIST_SORT.STARTDATE, value: "Start Date", status: false, order: null },
    { col: OFFER_LIST_SORT.ENDDATE, value: "End Date", status: false, order: null },
    { col: OFFER_LIST_SORT.OPT_IN, value: "Opt In", status: false, order: null },
  ] );
  const [ errorMessage, setErrorMessage ] = useState( "" );
  const [ cloneName, setCloneName ] = useState( "" );
  const { isOpened, closeModal, openModal, modalType, setModalType, modalData, setModalData } = useModal();
  const isFilterApplied = checkFilterApplied( window.location.search, FILTERS_NAME.PARTNER_OFFERS );
  const paramsQuery = new URLSearchParams( window.location.search );
  const searchKeys = isOfferScreen ? [ "offerName", "descriptionText" ] : [ "offerName", "descriptionText", "gameName" ];
  const [ dataDeleted, setDataDeleted ] = useState( false );
  const { values, handleChange } = useForm(
    {
      viewType: 1,
      searchKey: "",
      sortBy: "name",
      sortOrder: ORDER_BY.ASC,
      searchCol: searchKeys,
      perPage: PER_PAGE_RECORDS_LIMIT.MAX,
      offerIds: [],
      gameIds: [],
      statusIds: [],
      startDate: false,
      endDate: false,
      optInTypes: [],
    },
    {}
  );

  useEffect( () => {
    dispatch( getPartnerOfferAction( {
      perPage: PER_PAGE_RECORDS_LIMIT.MAX,
      partnerId: partnerId,
      currentTimeZone: new Date().toUTCString(),
      offerType: entityType.toLocaleLowerCase(),
    } ) );
  }, [] );

  const changeViewType = ( param ) => {
    handleChange( "viewType", param );
    addQuery( useSetUrlParams( "viewType", param ) );
  };

  const memoizedTableConfig = useMemo( () => {
    return isOfferScreen ? partnerOfferTableConfigs : partnerTournamentTableConfigs;
  }, [] );
  const memoizedHandleClick = useCallback(
    ( sortBy, orderType ) => handleSorting( sortBy, orderType ),
    []
  );

  const handleSorting = ( sortBy, sortingType ) => {
    handleChange( "sortBy", sortBy );
    handleChange( "sortOrder", sortingType );
    addQuery( useSetUrlParams( "sortColumn", sortBy ) );
    addQuery( useSetUrlParams( "sortOrder", sortingType ) );
    toggleClass( sortBy, sortingType, true );
  };

  const searchHandle = ( e ) => {
    const { name, value } = e.target;

    handleChange( name, value );
    addQuery( useSetUrlParams( "searchKey", value ) );
  };

  const requestParams = Object.assign(
    {
      sortOrder: values.sortOrder,
      perPage: values.perPage,
      searchCol: searchKeys,
      gameIds: values.gameIds?.map( value => value.value ) || [],
      offerIds: values.offerIds?.map( value => value.value ) || [],
      statusIds: values.statusIds?.map( value => value.value ) || [],
      startDate: values.startDate === false ? "" : values.startDate,
      endDate: values.endDate === false ? "" : values.endDate,
      offerType: entityType.toLocaleLowerCase(),
      optInTypes: values.optInTypes?.map( value => value.value ) || [],
    },
    values.searchKey && { searchKey: values.searchKey },
    values.sortBy && { sortBy: values.sortBy }
  );

  useEffect( () => {
    if(
      status === API_STATUS.SUCCESS &&
      apiType === OFFER_API_TYPES.GET_PARTNER_OFFER
    ) {
      dispatch( getPartnerLookupAction( { entities: "Offer,Tournament,GameOffer,GameTournament" } ) );
      setPureData( offers );
      updateStateFromUrl();
      filterData( requestParams );
    }
    else if( ( status === API_STATUS.SUCCESS ) && ( apiType === OFFER_API_TYPES.DELETE_PARTNER_OFFER ) ) {
      showSuccessMessage();
      updateStateFromUrl();
      filterData( requestParams );
      setPureData( offers );
      setDataDeleted( true );
    }
    else if( ( draftStatus === API_STATUS.SUCCESS ) && ( draftApiType === OFFER_API_TYPES.ADD_PARTNER_CLONE_DRAFT_OFFER ) ) {
      setTimeout( () => {
        const routePath = isOfferScreen
          ? ROUTES.PARTNER_CREATE_AND_EDIT_OFFER.replace( ":offerId", cloneDraft.entity_id )
          : ROUTES.PARTNER_CREATE_EDIT_TOURNAMENT.replace( ":tournamentId", cloneDraft.entity_id );

        history.push( {
          pathname: `${ routePath }?status=${ OFFER_TYPE_STATUS.DRAFT }&from=${ isOfferScreen ? "partnerOffers" : "partnerTournaments" }`,
          state: [
            { from: ( isOfferScreen ? "partnerOffers" : "partnerTournaments" ), path: location.pathname, state: location.search },
          ],
        } );
      }, 500 );
    }
    else if( ( status === API_STATUS.SUCCESS ) && ( apiType === OFFER_API_TYPES.ADD_PARTNER_NEW_OFFER_TEMPLATE ) ) {
      setTimeout( () => {
        const routePath = isOfferScreen
          ? ROUTES.PARTNER_CREATE_AND_EDIT_OFFER.replace( ":offerId", cloneOfferDetail.offer_id )
          : ROUTES.PARTNER_CREATE_EDIT_TOURNAMENT.replace( ":tournamentId", cloneOfferDetail.offer_id );

        history.push( {
          pathname: `${ routePath }?from=${ isOfferScreen ? "partnerOffers" : "partnerTournaments" }`,
          state: [
            { from: ( isOfferScreen ? "partnerOffers" : "partnerTournaments" ), path: location.pathname, state: location.search },
          ],
        } );
      }, 500 );
    }
  }, [ status, apiType, draftStatus, draftApiType ] );

  useEffect( () => {
    filterData( requestParams );
  }, [
    values.sortOrder,
    values.page,
    values.sortBy,
    values.searchKey,
    values.gameIds,
    values.offerIds,
    values.statusIds,
    values.startDate,
    values.endDate,
    values.optInTypes,
  ] );

  function updateStateFromUrl() {
    if( paramsQuery.get( "sortColumn" ) && paramsQuery.get( "sortOrder" ) ) {
      handleChange( "sortBy", paramsQuery.get( "sortColumn" ) );
      handleChange( "sortOrder", paramsQuery.get( "sortOrder" ) );
      toggleClass( paramsQuery.get( "sortColumn" ), paramsQuery.get( "sortOrder" ), true );
    }

    if( paramsQuery.get( "viewType" ) ) {
      handleChange( "viewType", Number( paramsQuery.get( "viewType" ) ) );
    }

    if( paramsQuery.get( "searchKey" ) ) {
      handleChange( "searchKey", paramsQuery.get( "searchKey" ) );
    }

    if( paramsQuery.get( "sortColumn" ) ) {
      handleChange( "sortBy", paramsQuery.get( "sortColumn" ) );
    }

    if( paramsQuery.get( "gameIds" ) ) {
      handleChange( "gameIds", JSON.parse( paramsQuery.get( "gameIds" ) ) );
    }

    if( paramsQuery.get( "offerIds" ) ) {
      handleChange( "offerIds", JSON.parse( paramsQuery.get( "offerIds" ) ) );
    }

    if( paramsQuery.get( "statusIds" ) ) {
      handleChange( "statusIds", JSON.parse( paramsQuery.get( "statusIds" ) ) );
    }

    if( paramsQuery.get( "startDate" ) ) {
      handleChange( "startDate", ( paramsQuery.get( "startDate" ) === null || paramsQuery.get( "startDate" ) === "" ) ? false : paramsQuery.get( "startDate" ) );
    }

    if( paramsQuery.get( "endDate" ) ) {
      handleChange( "endDate", ( paramsQuery.get( "endDate" ) === null || paramsQuery.get( "endDate" ) === "" ) ? false : paramsQuery.get( "endDate" ) );
    }

    if( paramsQuery.get( "optInTypes" ) ) {
      handleChange( "optInTypes", JSON.parse( paramsQuery.get( "optInTypes" ) ) );
    }
  }

  const toggleClass = ( params, order, isQueryParamsUpdated = false ) => {
    gridViewToggle( sortColumns, handleChange, params, setSortColumns, addQuery, useSetUrlParams,
      order, location.state, isQueryParamsUpdated, true );
  };

  const listHandle = ( obj, listName ) => {
    if( obj.length > 0 ) {
      handleChange( listName, obj );
      addQuery( useSetUrlParams( listName, JSON.stringify( obj ) ) );
    }
    else {
      addQuery( useSetUrlParams( listName, "" ) );
      handleChange( listName );
    }
  };

  const clearHandle = ( parmas ) => {
    if( parmas ) {
      addQuery( useSetUrlParams( parmas, "" ) );
      handleChange( parmas, [] );
    }
  };

  const multiSelectedItems = useMemo( () => [ values.offerIds, values.gameIds, values.statusIds, values.optInTypes ] );
  const handleSelectChange = useCallback(
    [ obj => listHandle( obj, "offerIds" ),
      obj => listHandle( obj, "gameIds" ),
      obj => listHandle( obj, "statusIds" ),
      obj => listHandle( obj, "optInTypes" ),
    ],
    []
  );
  const handleClearMultiSelect = useCallback(
    parmas => clearHandle( parmas ), []
  );

  const navigateOnClick = ( offer, route, isEditTournament = false ) => {
    let statusParams = "";

    if( isOfferScreen ) {
      if( offer.approvalStatus === OFFER_DETAIL_STATUS.DRAFT ) {
        statusParams = `?status=${ OFFER_TYPE_STATUS.DRAFT }&from=partnerOffers`;
      }
      else {
        statusParams = "?from=partnerOffers";
      }
    }
    else if( ( !isOfferScreen ) && offer.approvalStatus === OFFER_DETAIL_STATUS.DRAFT ) {
      statusParams = `?status=${ OFFER_TYPE_STATUS.DRAFT }&tab=${ TABS.TOURNAMENT_DETAIL }&from=partnerTournaments`;
    }
    else {
      statusParams = `${ statusParams }?tab=${ TABS.TOURNAMENT_DETAIL }&from=partnerTournaments`;
    }

    const routePath = ( ( !isOfferScreen ) && isEditTournament ) ?
      route.replace( ":tournamentId", offer.offerId ) :
      route.replace( ":offerId", offer.offerId );

    history.push( {
      pathname: `${ routePath }${ statusParams }`,
      state: [
        { from: ( isOfferScreen ? "partnerOffers" : "partnerTournaments" ), path: location.pathname, state: location.search },
      ],
    } );
  };

  const handleOpenModal = ( mType, data ) => {
    setModalType( mType );
    setModalData( data );
    openModal();
  };

  const handleConfirm = () => {
    if( modalType === MODAL_TYPE_DETAIL.SHOW_ALL_REWARD.type ) {
      closeModal();
    }
    else if( ( modalType === MODAL_TYPE_DETAIL.CLONE_DRAFT.type ) || ( modalType === MODAL_TYPE_DETAIL.CLONE_TEMPLATE.type ) ) {
      if( cloneName.trim() ) {
        const [ isLoader, isClone ] = Array( 2 ).fill( true );

        if( modalType === MODAL_TYPE_DETAIL.CLONE_DRAFT.type ) {
          const requestObj = { ...modalData.draftContent, name: cloneName };

          dispatch( addNewPartnerOfferDraftAction( requestObj, modalData.entityName, isLoader, isClone ) );
        }
        else {
          const { partnerCostUsdPerQxPoint } = configuration;

          if( isOfferScreen ) {
            const offerData = new OfferTemplateDetail( modalData.offerData );
            const requestObj = new OfferTemplateRequest( { ...offerData, offerName: cloneName, offerId: "", partnerCostUsdPerQxPoint, requestType: REQUEST_TYPE.OFFER_TEMPLATE, isClone: true } );

            dispatch( addNewOfferTemplateAction( requestObj, isClone ) );
          }
          else {
            const requestObj = new OfferTemplateRequest( { ...modalData, rawDescription: modalData.description, tournamentName: cloneName, offerId: "", partnerCostUsdPerQxPoint,
              requestType: REQUEST_TYPE.TOURNAMENT_TEMPLATE } );

            dispatch( addNewOfferTemplateAction( requestObj, isClone ) );
          }
        }

        closeModal();
      }
      else {
        setErrorMessage( modalType === MODAL_TYPE_DETAIL.CLONE_DRAFT.type ? "Please enter draft name." : "Please enter template name." );
      }
    }
    else {
      dispatch( deletePartnerOfferAction( modalData ) );

      const offerIds = JSON.parse( paramsQuery.get( "offerIds" ) );
      const filterOfferIds = offerIds?.filter( val => val.value !== modalData?.offerId );

      if( filterOfferIds ) {
        addQuery( useSetUrlParams( "offerIds", JSON.stringify( filterOfferIds ) ) );
        handleChange( "offerIds", filterOfferIds );
      }

      closeModal();
    }
  };

  const handleClickOnClone = ( offer, route, isCloneTournament = false ) => {
    const offerList = offers?.map( item => item?.offerName ) || [];

    if( ( offer.offerType === TYPE_TO_BE_EDITED.DRAFT ) ||
      ( offer.offerType === TYPE_TO_BE_EDITED.TOURNAMENT_DRAFT ) ) {
      setCloneName( getUniqueName( `Clone - ${ offer.offerName }`, offerList ) );
      handleOpenModal( MODAL_TYPE_DETAIL.CLONE_DRAFT.type, offer );
    }
    else if( ( offer.offerType === TYPE_TO_BE_EDITED.OFFER_TEMPLATE ) ||
      ( offer.offerType === TYPE_TO_BE_EDITED.TOURNAMENT_TEMPLATE ) ) {
      setCloneName( getUniqueName( `Clone - ${ offer.offerName }`, offerList ) );
      handleOpenModal( MODAL_TYPE_DETAIL.CLONE_TEMPLATE.type, offer );
    }
    else {
      const routePath = isCloneTournament ?
        route.replace( ":tournamentId", offer.offerId ) :
        route.replace( ":offerId", offer.offerId );

      history.push( {
        pathname: `${ routePath }?clone=true&from=${ isOfferScreen ? "partnerOffers" : "partnerTournaments" }`,
        state: [
          { from: ( isOfferScreen ? "partnerOffers" : "partnerTournaments" ), path: location.pathname, state: location.search },
        ],
      } );
    }
  };

  const handleInputChange = ( e ) => {
    setCloneName( e.target.value );

    if( errorMessage ) {
      setErrorMessage( "" );
    }
  };

  const onCloseModal = () => {
    closeModal();
    setErrorMessage();
  };

  const handleClickOnOfferTemplate = ( offer ) => {
    const params = `?status=${ OFFER_TYPE_STATUS.OFFER_FROM_TEMPLATE }&fromTemplate=true`;

    history.push( `${ ROUTES.PARTNER_CREATE_AND_EDIT_OFFER.replace( ":offerId", offer.offerId ) }${ params }` );
  };

  const [ showFilter, setShowFilter ] = useState( false );
  const showFilterScreen = () => setShowFilter( !showFilter );

  const handleShowAll = ( rewards ) => {
    setModalType( MODAL_TYPE_DETAIL.SHOW_ALL_REWARD.type );
    setModalData( { rewards } );
    openModal();
  };

  const showSuccessMessage = () => {
    if( entityType === "Offer" ) {
      toastr.success( ( modalType === MODAL_TYPE_DETAIL.REMOVE_DRAFT.type ) ? REMOVE_OFFER_DRAFT_SUCCESS_MESSAGE : REMOVE_OFFER_TEMPLATE_SUCCESS_MESSAGE );
    }
    else {
      toastr.success( ( modalType === MODAL_TYPE_DETAIL.REMOVE_DRAFT.type ) ? REMOVE_TOURNAMENT_DRAFT_SUCCESS_MESSAGE : REMOVE_TOURNAMENT_TEMPLATE_SUCCESS_MESSAGE );
    }
  };

  useEffect( () => {
    filterData( requestParams );

    return ( () => {
      setDataDeleted( false );
    } );
  }, [ dataDeleted ] );

  return (
    <>
      <div className="main-wrapper">
        <div className="main-right-wrapper">
          <SearchHeader
            searchHandle={searchHandle}
            searchKey={values.searchKey}
            headerTitle={isOfferScreen ? "Offers" : "Tournaments" }
            showFilter={showFilter}
            showFilterScreen={showFilterScreen}
            isFilterApplied={isFilterApplied}
            searchPlaceholder={`Search by ${ entityType } Name...`}
            searchTitle={isOfferScreen ?
              `Search by ${ entityType } Name and Description`
              : `Search by ${ entityType } Name, Game Name and Description`
            }
            dropdownItems={() =>
              ( isOfferScreen ?
                ( <>
                  <Dropdown.Item
                    onClick={() => history.push( {
                      pathname: `${ ROUTES.PARTNER_CREATE_AND_EDIT_OFFER
                        .replace( ":offerId", 0 ) }?status=${ OFFER_TYPE_STATUS.OFFER_FROM_SCRATCH }&from=partnerOffers`,
                      state: [
                        { from: "partnerOffers", path: location.pathname, state: location.search },
                      ],
                    }
                    ) }
                  ><span>Create an Offer</span></Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => history.push( {
                      pathname: `${ ROUTES.PARTNER_CREATE_AND_EDIT_OFFER
                        .replace( ":offerId", 0 ) }?status=${ OFFER_TYPE_STATUS.OFFER_TEMPLATE }&from=partnerOffers`,
                      state: [
                        { from: "partnerOffers", path: location.pathname, state: location.search },
                      ],
                    }
                    ) }
                  ><span>Create an Offer Template</span></Dropdown.Item>
                </>
                )
                : (
                  <>
                    <Dropdown.Item
                      onClick={() => history.push( {
                        pathname: `${ ROUTES.PARTNER_CREATE_EDIT_TOURNAMENT
                          .replace( ":tournamentId", 0 ) }?status=${ TOURNAMENT_TYPE_STATUS.TOURNAMENT }&from=partnerTournaments`,
                        state: [
                          { from: "partnerTournaments", path: location.pathname, state: location.search },
                        ],
                      }
                      ) }
                    ><span>Create a Tournament</span></Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => history.push( {
                        pathname: `${ ROUTES.PARTNER_CREATE_EDIT_TOURNAMENT
                          .replace( ":tournamentId", 0 ) }?status=${ TOURNAMENT_TYPE_STATUS.TOURNAMENT_TEMPLATE }&from=partnerTournaments`,
                        state: [
                          { from: "partnerTournaments", path: location.pathname, state: location.search },
                        ],
                      }
                      ) }
                    ><span>Create a Tournament Template</span></Dropdown.Item>
                  </>
                ) )
            }
          />
          <div className="filter-tabs-wrapper align-items-end">
            <div className="filter-boxes">
              <Row className={`${ !showFilter ? "" : "remove-filter" }`}>
                <Col md={3} className="mb-20">
                  <Form.Group className='custom-multiselect mb-0'>
                    <Form.Label>{entityType} Name</Form.Label>
                    <MultiSelectCheckbox
                      multiOptionsList={isOfferScreen ? lookup?.Offer : lookup?.Tournament}
                      handleSelectChange={handleSelectChange[ 0 ]}
                      multiSelectedItems={multiSelectedItems[ 0 ]}
                      handleClearMultiSelect={() => handleClearMultiSelect( "offerIds" )} />
                  </Form.Group>
                </Col>
                <Col md={3} className="mb-20">
                  <Form.Group className='custom-multiselect mb-0'>
                    <Form.Label>Game Name</Form.Label>
                    <MultiSelectCheckbox
                      multiOptionsList={isOfferScreen ? lookup?.GameOffer : lookup?.GameTournament}
                      handleSelectChange={handleSelectChange[ 1 ]}
                      multiSelectedItems={multiSelectedItems[ 1 ]}
                      handleClearMultiSelect={() => handleClearMultiSelect( "gameIds" )} />
                  </Form.Group>
                </Col>
                <Col md={3} className="mb-20">
                  <Form.Group className='custom-datepicker mb-0'>
                    <Form.Label className='label-with-icon'>Start Date
                      <OverlayTrigger placement='right' overlay={<Tooltip>All items starting on or after the date you choose.</Tooltip>}>
                        <HelpIcon />
                      </OverlayTrigger>
                    </Form.Label>
                    <CustomDatePicker
                      value={values.startDate}
                      handleChange={( e ) => {
                        if( e ) {
                          addQuery( useSetUrlParams( "startDate", new Date( e ).toLocaleDateString( "fr-CA" ) ) );
                          handleChange( "startDate", new Date( e ).toLocaleDateString( "fr-CA" ) );
                        }
                        else {
                          addQuery( useSetUrlParams( "startDate", "" ) );
                          handleChange( "startDate", false );
                        }
                      }
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={3} className="mb-20">
                  <Form.Group className='custom-datepicker mb-0'>
                    <Form.Label className='label-with-icon'>End Date
                      <OverlayTrigger placement='left' overlay={<Tooltip>All items ending on or before the date you choose.</Tooltip>}>
                        <HelpIcon />
                      </OverlayTrigger>
                    </Form.Label>
                    <CustomDatePicker
                      value={values.endDate}
                      handleChange={( e ) => {
                        if( e ) {
                          addQuery( useSetUrlParams( "endDate", new Date( e ).toLocaleDateString( "fr-CA" ) ) );
                          handleChange( "endDate", new Date( e ).toLocaleDateString( "fr-CA" ) );
                        }
                        else {
                          addQuery( useSetUrlParams( "endDate", "" ) );
                          handleChange( "endDate", false );
                        }
                      }
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className='custom-multiselect mb-0'>
                    <Form.Label>Status</Form.Label>
                    <MultiSelectCheckbox
                      multiOptionsList={isOfferScreen ? OFFER_STATUS_DROPDOWN : TOURNAMENT_STATUS_DROPDOWN}
                      handleSelectChange={handleSelectChange[ 2 ]}
                      multiSelectedItems={multiSelectedItems[ 2 ]}
                      handleClearMultiSelect={() => handleClearMultiSelect( "statusIds" )}
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className='custom-multiselect mb-0'>
                    <Form.Label>Opt In</Form.Label>
                    <MultiSelectCheckbox
                      multiOptionsList={ OPT_IN_STATUS_DROPDOWN }
                      handleSelectChange={handleSelectChange[ 3 ]}
                      multiSelectedItems={multiSelectedItems[ 3 ]}
                      handleClearMultiSelect={() => handleClearMultiSelect( "optInTypes" )}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
            <div className={`${ !showFilter ? "right-side top-align-content" : "right-side" }`}>
              {values.viewType === 1 && ( <Dropdown className="custom-dropdown sort-dropdown">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <span className={values.sortOrder === "DESC" ? "icon-sort" : "icon-sort-asc"}></span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <span className="dropdown-heading">Sort By</span>
                  {sortColumns.map( ( data, i ) => (
                    <Dropdown.Item key={i}
                      href='#'
                      className={data.status === true ? "active" : ""}
                      onClick={() => toggleClass( data.col, data.order )}
                    >
                      <span>{data.value}</span>
                    </Dropdown.Item>
                  ) )}
                </Dropdown.Menu>
              </Dropdown> )}
              <div className="grid-buttons">
                <span className={values.viewType === 1 ? "icon-grid-view active" : "icon-grid-view "} onClick={() => changeViewType( 1 )}></span>
                <span className={values.viewType === 0 ? "icon-list-view active" : "icon-list-view "} onClick={() => changeViewType( 0 )}></span>
              </div>
            </div>
          </div>
          {/* Add active class after games-list-table-head class to show this table head */}
          <div
            className={`games-list-table-head ${ values.viewType === 0 ? "active" : "" } offer-table-head`}
          >
            <TableHeaderRow
              configData={memoizedTableConfig}
              onAscOrder={memoizedHandleClick}
              onDescOrder={memoizedHandleClick}
            />
          </div>
          <Scrollbars
            renderThumbVertical={ScrollPage}
            className={`track-horizontal custom-scroll-height ${ values.viewType === 0 ? "max" : "medium" } ${ showFilter ? "full-height" : "" }`}
          >
            <div className={`games-grid-wrapper ${ values.viewType === 0 ? "games-list-wrapper" : "" } no-scrollbar`}
            >
              {/* repeat this div */}
              {Boolean( filteredData.length ) && filteredData.map( ( offer, index ) => (
                <div className="col-games-grid-box width-50" key={index}>
                  <div className="offer-detail-box" ref={ref}>
                    <div className="offer-detail-box-top grid-only">
                      <div className="image-box">
                        <img src={games} alt="games" />
                      </div>
                      <div className="offer-detail-box-top-body">
                        <div className="left-side opt-in-main">
                          <strong>{offer.offerName}</strong>
                          <div className="opt-in-container">
                            <p className='date'>{offer.startDate ?? "N/A"} - {offer.endDate ?? "N/A"}</p>
                            <div className='opt-in-text'>
                              Opt In:
                              <OptInDetails
                                optInDetail={offer.optInDetail}
                                eligibleSubPlans={getEligibleSubscriptionPlans( offer.optInDetail.membershipId, configuration.membershipTypes )}
                              />
                            </div>
                          </div>
                          <span className={`badge ${ offer?.statusTitle?.class }`}>{offer?.statusTitle?.title}</span>
                        </div>
                        <div className="right-side">
                          {isOfferScreen
                            ? (
                              <MoreOptions
                                offer={offer}
                                partnerId={partnerId}
                                onClickEdit={() => navigateOnClick( offer, ROUTES.PARTNER_CREATE_AND_EDIT_OFFER )}
                                onClickClone={() => handleClickOnClone( offer, ROUTES.PARTNER_CREATE_AND_EDIT_OFFER, false )}
                                OnClickView={() => navigateOnClick( offer, ROUTES.PARTNER_OFFER_DETAIL )}
                                onClickDelete={() => handleOpenModal( offer.offerType === TYPE_TO_BE_EDITED.DRAFT
                                  ? MODAL_TYPE_DETAIL.REMOVE_DRAFT.type
                                  : MODAL_TYPE_DETAIL.REMOVE_OFFER.type,
                                { offerId: offer.offerId, offerType: offer.offerType } )}
                                onClickCreateFromTemplate={() => handleClickOnOfferTemplate( offer )}
                              />
                            )
                            : (
                              <TournamentOptions
                                OnClickView={() => navigateOnClick( offer, ROUTES.PARTNER_TOURNAMENT_DETAIL )}
                                onClickEdit={() => navigateOnClick( offer, ROUTES.PARTNER_CREATE_EDIT_TOURNAMENT, true )}
                                onClickClone={() => handleClickOnClone( offer, ROUTES.PARTNER_CREATE_EDIT_TOURNAMENT, true )}
                                offer={offer}
                                onClickDelete={() => handleOpenModal( offer.offerType === TYPE_TO_BE_EDITED.TOURNAMENT_DRAFT
                                  ? MODAL_TYPE_DETAIL.REMOVE_DRAFT.type
                                  : MODAL_TYPE_DETAIL.REMOVE_TOURNAMENT.type,
                                { offerId: offer.offerId, offerType: offer.offerType } )}
                              />
                            )}
                          <img src={offer.partnerId === partnerId ? partnerLogo : logoColor} alt="User Icon" className="partner-image" />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between grid-only mt-4 mb-3">
                      <div className="games-tags-list-wrapper my-0 grid-only">
                        <strong>Games: </strong>

                        {offer?.gameName
                          ? (
                            <div className="games-tags-list">
                              <div className="games-tags cursor-pointer"
                                onClick={() =>
                                  history.push(
                                    ROUTES.PARTNER_ADD_EDIT_GAME.replace(
                                      ":gameId",
                                      offer.gameId
                                    )
                                  )
                                }>
                                <div className="image-box">
                                  {offer.gameUrl && <img src={offer.gameUrl} alt="User Icon" />}
                                </div>
                                <span className='link'>{offer.gameName}</span>
                              </div>
                            </div>
                          )
                          : <div className="games-desc-text"> <p>No game assigned</p> </div>}
                      </div>
                      {offer.reward && isOfferScreen && (
                        <div className="games-desc-text text-right">
                          <strong className='mb-0'>Reward:</strong>
                          <p>{formatNumberAsPerLocale( offer.reward )} { APP_SHORT_NAME } Points</p>
                        </div>
                      )}
                      { offer?.rewards && ( !isOfferScreen ) && (
                        <div className="list-content" >
                          {/* Show only rewards for first 2 places in the card view */}
                          {[ ...offer.rewards ].splice( 0, 2 ).map( ( reward, i ) => (
                            <div className="text-block" key={i}>
                              <span>{reward.place}</span>
                              <span>{formatNumberAsPerLocale( reward.rewardValue )}</span>
                              <span>{ APP_SHORT_NAME } Points</span>
                            </div>
                          ) )}
                          {offer.rewards.length > 2 && (
                            <div className="text-block text-right">
                              <Link
                                className="link"
                                title="Show All"
                                onClick={() => handleShowAll( offer.rewards )}
                              >
                              Show All
                              </Link>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="games-desc-text grid-only">
                      <strong>Description:</strong>
                      <OverlayTrigger
                        container={ref}
                        placement='auto'
                        overlay={<Tooltip><p>{offer.descriptionText}</p></Tooltip>}
                      >
                        <p className="text-truncate">{offer.descriptionText}</p>
                      </OverlayTrigger>
                    </div>
                    <div className="offer-table-body list-only">
                      <div className="td"><strong className='line-ellipses'>{offer.offerName}</strong></div>
                      <OverlayTrigger
                        container={ref}
                        placement='auto'
                        overlay={<Tooltip><p>{offer.descriptionText}</p></Tooltip>}
                      >
                        <div className="td"><p className="line-ellipses">{offer.descriptionText}</p></div>
                      </OverlayTrigger>
                      <div className="td"><img src={offer.partnerId === partnerId ? partnerLogo : logoColor} alt="User Icon" className="partner-image" /></div>
                      <div className="td">
                        {offer?.gameName
                          ? ( <span className='link cursor-pointer' onClick={() =>
                            history.push(
                              ROUTES.PARTNER_ADD_EDIT_GAME.replace(
                                ":gameId",
                                offer.gameId
                              )
                            )
                          }>{offer.gameName}</span> )
                          : "N/A"}
                      </div>
                      <div className="td">{offer.startDate ?? "N/A"}</div>
                      <div className="td">{offer.endDate ?? "N/A"}</div>
                      <div className="td">
                        <span className="opt-in-text">
                          <OptInDetails
                            optInDetail={offer.optInDetail}
                            eligibleSubPlans={getEligibleSubscriptionPlans( offer.optInDetail.membershipId, configuration.membershipTypes )}
                          />
                        </span>
                      </div>
                      <div className="td"><span className={`badge ${ offer?.statusTitle?.class }`}>{offer?.statusTitle?.title}</span></div>
                      <div className="td">
                        {isOfferScreen
                          ? (
                            <MoreOptions
                              offer={offer}
                              partnerId={partnerId}
                              onClickEdit={() => navigateOnClick( offer, ROUTES.PARTNER_CREATE_AND_EDIT_OFFER )}
                              onClickClone={() => handleClickOnClone( offer, ROUTES.PARTNER_CREATE_AND_EDIT_OFFER, false )}
                              OnClickView={() => navigateOnClick( offer, ROUTES.PARTNER_OFFER_DETAIL )}
                              marginZero
                              onClickDelete={() => handleOpenModal( offer.offerType === TYPE_TO_BE_EDITED.DRAFT
                                ? MODAL_TYPE_DETAIL.REMOVE_DRAFT.type
                                : MODAL_TYPE_DETAIL.REMOVE_OFFER.type,
                              { offerId: offer.offerId, offerType: offer.offerType } )}
                              onClickCreateFromTemplate={() => handleClickOnOfferTemplate( offer )}
                            />
                          )
                          : (
                            <TournamentOptions
                              offer={offer}
                              marginZero
                              OnClickView={() => navigateOnClick( offer, ROUTES.PARTNER_TOURNAMENT_DETAIL )}
                              onClickEdit={() => navigateOnClick( offer, ROUTES.PARTNER_CREATE_EDIT_TOURNAMENT, true )}
                              onClickClone={() => handleClickOnClone( offer, ROUTES.PARTNER_CREATE_EDIT_TOURNAMENT, true )}
                              onClickDelete={() => handleOpenModal( offer.offerType === TYPE_TO_BE_EDITED.TOURNAMENT_DRAFT
                                ? MODAL_TYPE_DETAIL.REMOVE_DRAFT.type
                                : MODAL_TYPE_DETAIL.REMOVE_TOURNAMENT.type,
                              { offerId: offer.offerId, offerType: offer.offerType } )}
                            />
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              ) )}
              {!filteredData.length && (
                <div className='no-record' data-testid='no-record-found'>
                  No {entityType} Found
                </div>
              )}
            </div></Scrollbars>
        </div>
      </div>
      <ModalPopup
        isOpened={isOpened}
        onClose={onCloseModal}
        type={modalType}
        onConfirm={handleConfirm}
        errorMessage={errorMessage}
        onChangeInput={handleInputChange}
        cloneName={cloneName}
        offerName={modalData.offerName}
        dataList={modalData.rewards || []}
      />
    </>
  );
};

export default PartnerOfferList;
