import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSetUrlParams } from "../../../util/helpers";
import CustomDatePicker from "../../shared/customDatePicker";
import MultiSelectCheckbox from "../../shared/multiSelectCheckbox";

const SubHeader = ( {
  values,
  handleChange,
  addQuery,
  gameList,
  showFilter,
  state,
} ) => {
  const listHandle = ( obj, listName ) => {
    if( obj.length > 0 ) {
      handleChange( "page", 1 );
      handleChange( listName, obj );
      addQuery( useSetUrlParams( listName, JSON.stringify( obj ) ), state );
    }
    else {
      addQuery( useSetUrlParams( listName, "" ), state );
      handleChange( listName );
    }
  };

  const clearHandle = ( parmas ) => {
    if( parmas ) {
      addQuery( useSetUrlParams( parmas, "" ), state );
      handleChange( parmas, [] );
    }
  };

  const multiSelectedItems = useMemo( () => [ values.gameIds ] );
  const handleSelectChange = useCallback(
    [ obj => listHandle( obj, "gameIds" ),
    ],
    []
  );
  const handleClearMultiSelect = useCallback(
    parmas => clearHandle( parmas ), []
  );

  return (
    <>
      <div className="filter-boxes mr-70">
        <div className={`filter-boxes mr-0 ${ !showFilter ? "" : "remove-filter" }`}>
          <Row>
            <Col md={4}>
              <Form.Group className='custom-multiselect mb-0'>
                <Form.Label>Game Name</Form.Label>
                <MultiSelectCheckbox
                  multiOptionsList={gameList}
                  handleSelectChange={handleSelectChange[ 0 ]}
                  multiSelectedItems={multiSelectedItems[ 0 ]}
                  handleClearMultiSelect={() => handleClearMultiSelect( "gameIds" )} />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className='custom-datepicker mb-0'>
                <Form.Label>Start Date</Form.Label>
                <CustomDatePicker
                  value={values.startDate}
                  handleChange={( e ) => {
                    if( e ) {
                      addQuery( useSetUrlParams( "startDate", new Date( e ).toLocaleDateString( "fr-CA" ) ), state );
                      handleChange( "startDate", new Date( e ).toLocaleDateString( "fr-CA" ) );
                      handleChange( "initialStartDate", new Date( e ).toLocaleDateString( "fr-CA" ) );
                    }
                    else {
                      addQuery( useSetUrlParams( "startDate", "" ), state );
                      handleChange( "startDate", false );
                      handleChange( "initialStartDate", null );
                    }
                  }
                  }
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className='custom-datepicker mb-0'>
                <Form.Label>End Date</Form.Label>
                <CustomDatePicker
                  value={values.endDate}
                  handleChange={( e ) => {
                    if( e ) {
                      addQuery( useSetUrlParams( "endDate", new Date( e ).toLocaleDateString( "fr-CA" ) ), state );
                      handleChange( "endDate", new Date( e ).toLocaleDateString( "fr-CA" ) );
                      handleChange( "initialEndDate", new Date( e ).toLocaleDateString( "fr-CA" ) );
                    }
                    else {
                      addQuery( useSetUrlParams( "endDate", "" ), state );
                      handleChange( "endDate", false );
                      handleChange( "initialEndDate", null );
                    }
                  }
                  }
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

SubHeader.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  addQuery: PropTypes.func,
  gameList: PropTypes.array,
  state: PropTypes.string,
  showFilter: PropTypes.string,
};

export default SubHeader;
