import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { Dropdown } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Scrollbars } from "react-custom-scrollbars";
import { useHistory, useLocation } from "react-router-dom";
import { useScroll } from "../../../hooks";
import constant from "../../../util/constant";
import { getEligibleSubscriptionPlans } from "../../../util/helpers";
import offerConstant from "../../../util/offerConstant";
import tournamentConstant from "../../../util/tournamentConstant";
import OptInDetails from "../../partner/offers/optInDetails";
import { ScrollPage } from "../../shared/customScrollBar";
import TableHeaderRow from "../../shared/tableHeadingRow";

const { ROUTES } = constant;
const { TABS } = tournamentConstant;
const { OFFER_DETAIL_STATUS, OFFER_TYPE_STATUS } = offerConstant;

const TournamentList = ( {
  values,
  handleChange,
  usersMetaData,
  memoizedTableConfig,
  memoizedHandleClick,
  showFilter,
  tournaments,
  configuration,
} ) => {
  const location = useLocation();
  const ref = useRef( null );
  const history = useHistory();
  const { scrollEvent } = useScroll();

  const onClickViewAndEdit = ( offer, route, isView ) => {
    let statusParams = "";

    if( offer.approvalStatus === OFFER_DETAIL_STATUS.DRAFT ) {
      statusParams = `?status=${ OFFER_TYPE_STATUS.DRAFT }&tab=${ TABS.TOURNAMENT_DETAIL }&from=tournaments`;
    }
    else {
      statusParams = `?tab=${ TABS.TOURNAMENT_DETAIL }&from=tournaments`;
    }

    history.push( {
      pathname: `${ isView ? route.replace( ":tournamentId", offer.offerId ) : route.replace( ":offerId", offer.offerId ) }${ statusParams }`,
      state: [
        { from: "tournaments", path: location.pathname, state: location.search },
      ],
    } );
  };

  const onScroll = ( e ) => {
    scrollEvent( e, tournaments, values, handleChange, usersMetaData, "page" );
  };

  return (
    <div className="table-container admin-tournaments-table">
      <div className="table-head">
        <div className="table-row">
          <TableHeaderRow
            configData={memoizedTableConfig}
            onAscOrder={memoizedHandleClick}
            onDescOrder={memoizedHandleClick}
          />
        </div>
      </div>
      <Scrollbars
        renderThumbVertical={ScrollPage}
        className={`custom-scroll-height max ${ !showFilter ? "" : "full-page-height" }`}
        onScroll={onScroll}>
        <div className="table-body no-scrollbar " >
          {Boolean( tournaments.length ) && tournaments.map( ( tnm, index ) => (
            <div className="table-row" key={index}>
              <div className="td"><span className='big-text-common line-ellipses'>{tnm.tournamentName}</span></div>
              <div className="td" ref={ref}><OverlayTrigger
                container={ref}
                placement='auto'
                overlay={<Tooltip className="event-tooltip">{tnm.descriptionText}</Tooltip>}
              >
                <span className='text-truncate'>{tnm.descriptionText}</span>
              </OverlayTrigger></div>
              <div className="td">
                <span className='line-ellipses'>{tnm.partnerName}</span>
              </div>
              <div className="td">{tnm.gameName
                ? <span className='line-ellipses link cursor-pointer' onClick={() =>
                  history.push( {
                    pathname: `${ ROUTES.ADMIN_GAME_DETAILS.replace(
                      ":gameId",
                      tnm.gameId
                    ) }?tab=GENERAL_INFORMATION&from=tournaments`,
                    state: [
                      { from: "tournaments", path: location.pathname, state: location.search },
                    ],
                    backScreenRoute: ROUTES.ADMIN_TOURNAMENTS_LIST,
                  }
                  )
                }> {tnm.gameName}</span>
                : "N/A"}</div>
              <div className="td">{tnm.startDate}</div>
              <div className="td">{tnm.endDate}</div>
              <div className='td opt-in-text'>
                <OptInDetails
                  optInDetail={tnm.optInDetail}
                  eligibleSubPlans={getEligibleSubscriptionPlans( tnm.optInDetail.membershipId, configuration.membershipTypes )}
                />
              </div>
              <div className='td'><span className={`badge ${ tnm?.statusTitle?.class }`}>{tnm?.statusTitle?.title}</span></div>
              <div className='td'>
                <Dropdown className='custom-dropdown more-dropdown'>
                  <Dropdown.Toggle variant='success' id='' className='m-0'>
                    <MoreVertIcon />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="three-dropdown-item">
                    <Dropdown.Item ><span onClick={() => onClickViewAndEdit( tnm, ROUTES.ADMIN_TOURNAMENT_DETAILS, true )}><VisibilityIcon />View</span></Dropdown.Item>
                    <div className={ tnm.approvalStatus !== OFFER_DETAIL_STATUS.DRAFT ? "disabled" : "" }>
                      <Dropdown.Item onClick={() => onClickViewAndEdit( tnm, ROUTES.ADMIN_ADD_EDIT_TOURNAMENT, false )}>
                        <span className='rounded'>
                          <EditIcon />
                        </span>
                        <span>Edit</span>
                      </Dropdown.Item>
                    </div>
                    <div className='disabled'>
                      <Dropdown.Item ><DeleteIcon /> <span>Delete</span></Dropdown.Item>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          ) )}
          {!tournaments.length && (
            <div className='no-record' data-testid='no-record-found'>
                  No Tournament Found
            </div>
          )}
        </div>
      </Scrollbars>
    </div>
  );
};

TournamentList.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  usersMetaData: PropTypes.object,
  memoizedTableConfig: PropTypes.object,
  memoizedHandleClick: PropTypes.func,
  showFilter: PropTypes.string,
  tournaments: PropTypes.object,
  configuration: PropTypes.object,
};

export default TournamentList;
