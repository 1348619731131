import { API_URL } from "../util/apiPaths";
import { request } from "./api.js";

export const getAdminUsers = ( params ) => {
  return request( "GET", API_URL.ADMIN_USERS, params );
};

export const addAdminUser = ( params ) => {
  return request( "POST", API_URL.ADMIN_USERS, params );
};

export const getAdminUserDetail = ( params ) => {
  return request( "GET", `${ API_URL.ADMIN_USERS }/${ params }` );
};

export const updateAdminUserPermission = ( params ) => {
  return request( "PUT", API_URL.UPDATE_ADMIN_USER_PERMISSION, params );
};

export const getAdminUserList = ( filters ) => {
  return request( "GET", API_URL.ADMIN_USERS, filters );
};

export const enableDisableAdminUser = ( params ) => {
  return request( "PUT", API_URL.ENABLE_DISABLE_ADMIN_USER, params );
};

export const updateAdminSignOutConfirmation = ( param ) => {
  return request( "PUT", API_URL.ADMIN_SIGN_OUT_CONFIRMATION, param );
};
