import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getPartnerEventDetailAction } from "../../../store/actions/partnerEvent";
import { ViewEvent } from "../../shared/events/";

const PartnerEventDetail = () => {
  const dispatch = useDispatch();
  const { partnerEventDetail } = useSelector( state => ( {
    partnerEventDetail: state.partnerEvent.partnerEventDetail,
  } ) );
  const { eventId } = useParams();

  useEffect( () => {
    if( eventId !== "0" ) {
      dispatch( getPartnerEventDetailAction( eventId ) );
    }
  }, [ eventId ] );

  return <ViewEvent eventDetail={partnerEventDetail} type='partner'/>;
};

export default PartnerEventDetail;
