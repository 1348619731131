import { setTokenHeader } from "../../services/api";
import { SET_CURRENT_USER, CLEAR_STORE } from "../actionTypes";

export function setAuthorizationToken( token ) {
  setTokenHeader( token );
}

export function setCurrentUser( user ) {
  return {
    type: SET_CURRENT_USER,
    user,
  };
}

export const clearReduxStoreAction = () => ( dispatch ) => {
  dispatch( { type: CLEAR_STORE } );
};
