import { getStatesOfCountry, getAllCountries } from "../util/helpers";

export class PartnerProfileDetail {
  constructor( obj ) {
    /* eslint-disable camelcase */
    this.dba = obj.dba;
    this.business_location_add1 = obj.address1;
    this.business_location_add2 = obj.address2;
    this.business_location_city = obj.city;
    this.business_location_state = getStatesOfCountry( obj.country )?.find(
      state => state.value === obj.state
    )?.label || "";
    this.business_location_zip_code = obj.zip;
    this.business_location_country = getAllCountries( obj.country )?.find(
      country => country.value === obj.country
    )?.label || "";
    this.country_code = obj.country;
    this.state_code = obj.state;
    this.primary_name = obj.primaryName;
    this.primary_title = obj.primaryTitle;
    this.primary_email = obj.primaryEmail;
    this.primary_phone = obj.primaryPhoneNumber;
    this.primary_alternative_phone = obj.primaryAlternativePhoneNumber;
    this.sec_name = obj.secondaryName;
    this.sec_title = obj.secondaryTitle;
    this.sec_email = obj.secondaryEmail;
    this.sec_phone = obj.secondaryPhoneNumber;
    this.sec_alternative_phone = obj.secondaryAlternativePhoneNumber;
    this.icon_url = obj.logo;
    this.signout_without_confirmation = obj.signoutWithoutConfirmation;
    /* eslint-enable camelcase */
  }
}
