import PropTypes from "prop-types";
import React, { useEffect, useCallback, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
// Local-import.
import { useAddQuery, useForm, useScroll } from "../../../hooks";
import { getRedeemTransactionAction, getRedeemFilterListAction } from "../../../store/actions/adminTransaction";
import adminUserConstant from "../../../util/adminUserConstant";
import constant from "../../../util/constant";
import { useSetUrlParams, concatStrings, getUrlParameter, checkFilterApplied } from "../../../util/helpers";
import { ScrollPage } from "../../shared/customScrollBar";
import TableHeaderRow from "../../shared/tableHeadingRow";
import RedeemFilterList from "./redeemFilterList";
import TransactionHeader from "./transactionHeader";

const { ROUTES, ORDER_BY, FILTERS_NAME, CASHBACK_METHOD: { PAYPAL } } = constant;
const { ADMIN_PERMISSIONS } = adminUserConstant;

const RedeemList = ( { memoizedTableConfig, tab } ) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { addQuery } = useAddQuery();
  const { scrollEvent } = useScroll();
  const location = useLocation();

  const {
    redeemTransaction,
    usersMetaData,
    redeemFilterList,
    adminDetail,
  } = useSelector( state => ( {
    redeemTransaction: state.adminTransaction.redeemTransaction.transaction,
    usersMetaData: state.adminTransaction.redeemTransaction.meta,
    apiType: state.adminTransaction.apiType,
    status: state.adminTransaction.status,
    redeemFilterList: state.adminTransaction.redeemFilterList,
    adminDetail: state.adminDetail.data,
  } ) );
  const { qxPointsRange, netRedeemAmountRange } = redeemFilterList.transactionRanges;

  const { values, handleChange } = useForm(
    {
      searchTempText: getUrlParameter( "searchKey", null ),
      searchKey: getUrlParameter( "searchKey", null ),
      sortBy: getUrlParameter( "sortBy", "whenRedeemed" ),
      sortOrder: getUrlParameter( "sortOrder", ORDER_BY.DESC ),
      page: 1,
      minRedeemPoints: getUrlParameter( "minRedeemPoints" ),
      maxRedeemPoints: getUrlParameter( "maxRedeemPoints" ),
      initialMinRedeemPoints: getUrlParameter( "minRedeemPoints" ),
      initialMaxRedeemPoints: getUrlParameter( "maxRedeemPoints" ),
      minNetAmount: getUrlParameter( "minNetAmount" ),
      maxNetAmount: getUrlParameter( "maxNetAmount" ),
      initialMinNetAmount: getUrlParameter( "minNetAmount" ),
      initialMaxNetAmount: getUrlParameter( "maxNetAmount" ),
      gamerNames: getUrlParameter( "gamerNames" ),
      paymentVendors: getUrlParameter( "paymentVendors" ),
      transactionStatus: getUrlParameter( "transactionStatus" ),
      startDateRedmeed: null,
      endDateRedmeed: null,
      initialStartDateRedmeed: getUrlParameter( "startDateRedmeed" ),
      initialEndDateRedmeed: getUrlParameter( "endDateRedmeed" ),
    },
    {}
  );

  const sliderMinMaxObj = {
    minRedeemPoints: qxPointsRange.minQxPoints,
    maxRedeemPoints: qxPointsRange.maxQxPoints,
    minNetAmount: netRedeemAmountRange.minNetAmount,
    maxNetAmount: netRedeemAmountRange.maxNetAmount,
  };
  const isFilterApplied = checkFilterApplied( location.search, FILTERS_NAME.REDEEM_TRANSACTION, sliderMinMaxObj );
  const requestParams = Object.assign(
    {
      sortOrder: values.sortOrder,
      perPage: 10,
      page: values.page,
      sortBy: values.sortBy,
      searchKey: values.searchKey,
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      fullNames: concatStrings( values.gamerNames, "," ) || undefined,
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      cashbackVendorNames: concatStrings( values.paymentVendors, "," ) || undefined,
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      redeemTrxStatus: concatStrings( values.transactionStatus, "," ) || undefined,
      minQxPoints: values.minRedeemPoints,
      maxQxPoints: values.maxRedeemPoints,
      minNetAmount: values.minNetAmount,
      maxNetAmount: values.maxNetAmount,
      startDate: values.initialStartDateRedmeed,
      endDate: values.initialEndDateRedmeed,
    }
  );

  const handleSorting = ( sortBy, sortingType ) => {
    handleChange( "page", 1 );
    handleChange( "sortBy", sortBy );
    handleChange( "sortOrder", sortingType );
    addQuery( useSetUrlParams( "sortBy", sortBy ) );
    addQuery( useSetUrlParams( "sortOrder", sortingType ) );
  };

  const memoizedHandleClick = useCallback(
    ( sortBy, orderType ) => handleSorting( sortBy, orderType ),
    []
  );

  useEffect( () => {
    if( adminDetail?.permissions?.some( val => [ ADMIN_PERMISSIONS.VIEW_TRANSACTIONS, ADMIN_PERMISSIONS.MANAGE_REDEEM_REQUESTS ].includes( val ) ) ) {
      dispatch( getRedeemFilterListAction() );
    }
  }, [] );

  useEffect( () => {
    if( values.initialMinRedeemPoints || values.initialMaxRedeemPoints ) {
      handleChange( "initialMinRedeemPoints", values.initialMinRedeemPoints );
      handleChange( "initialMaxRedeemPoints", values.initialMaxRedeemPoints );
      handleChange( "minRedeemPoints", values.initialMinRedeemPoints );
      handleChange( "maxRedeemPoints", values.initialMaxRedeemPoints );
    }
    else {
      handleChange( "initialMinRedeemPoints", qxPointsRange.minQxPoints );
      handleChange( "initialMaxRedeemPoints", qxPointsRange.maxQxPoints );
      handleChange( "minRedeemPoints", qxPointsRange.minQxPoints );
      handleChange( "maxRedeemPoints", qxPointsRange.maxQxPoints );
    }

    if( values.initialMinNetAmount || values.initialMaxNetAmount ) {
      handleChange( "initialMinNetAmount", values.initialMinNetAmount );
      handleChange( "initialMaxNetAmount", values.initialMaxNetAmount );
      handleChange( "minNetAmount", values.initialMinNetAmount );
      handleChange( "maxNetAmount", values.initialMaxNetAmount );
    }
    else {
      handleChange( "initialMinNetAmount", netRedeemAmountRange.minNetAmount );
      handleChange( "initialMaxNetAmount", netRedeemAmountRange.maxNetAmount );
      handleChange( "minNetAmount", netRedeemAmountRange.minNetAmount );
      handleChange( "maxNetAmount", netRedeemAmountRange.maxNetAmount );
    }

    if( values.initialStartDateRedmeed || values.initialEndDateRedmeed ) {
      handleChange( "startDateRedmeed", new Date( values.initialStartDateRedmeed ) );
      handleChange( "endDateRedmeed", new Date( values.initialEndDateRedmeed ) );
    }
  }, [ redeemFilterList ] );

  useEffect( () => {
    if( values.initialMaxRedeemPoints ) {
      dispatch( getRedeemTransactionAction( requestParams ) );
    }
  }, [
    values.sortOrder,
    values.page,
    values.sortBy,
    values.searchKey,
    values.gamerNames,
    values.paymentVendors,
    values.transactionStatus,
    values.minRedeemPoints,
    values.maxRedeemPoints,
    values.minNetAmount,
    values.maxNetAmount,
    values.initialStartDateRedmeed,
    values.initialEndDateRedmeed,
  ] );

  const onScroll = ( e ) => {
    scrollEvent( e, redeemTransaction, values, handleChange, usersMetaData, "page" );
  };

  const redeemTransactionDetails = ( txn ) => {
    if( txn.redeemRequestId !== "N/A" ) {
      history.push(
        ROUTES.REDEEM_TRANSACTION_DETAIL.replace( ":transactionId", txn.transactionId )
      );
    }
  };

  const [ showFilter, setShowFilter ] = useState( false );
  const showFilterScreen = () => setShowFilter( !showFilter );

  return ( <>
    <TransactionHeader
      tab={tab}
      addQuery={addQuery}
      values={values}
      searchBoxPlaceholder='Search by Request ID ...'
      searchBoxTitle='Search by Request ID, Status and Gamer Name.'
      handleChange={handleChange}
      showFilter={showFilter}
      showFilterScreen={showFilterScreen}
      isFilterApplied={isFilterApplied} />
    <div className={`filter-tabs-wrapper mb-0 ${ !showFilter ? "" : "remove-filter" }`}>
      <RedeemFilterList
        values={values}
        handleChange={handleChange}
        addQuery={addQuery}
        paymentVendors={redeemFilterList.paymentVendors}
        transactionStatus={redeemFilterList.transactionStatus}
        gamerNames={redeemFilterList.gamerNames}
        transactionRanges={redeemFilterList.transactionRanges}
      />
    </div>
    <div className="table-container trans-redeem-table">
      <div className="table-head">
        <div className="table-row">
          <TableHeaderRow
            configData={memoizedTableConfig}
            onAscOrder={memoizedHandleClick}
            onDescOrder={memoizedHandleClick}
          />
        </div>
      </div>
      <Scrollbars
        renderThumbVertical={ScrollPage}
        className={`custom-scroll-height max ${ !showFilter ? "" : "full-height" }`}
        onScroll={onScroll}
      >
        <div className="table-body no-scrollbar" >
          {Boolean( redeemTransaction.length ) && redeemTransaction.map( ( txn, index ) => (
            <div className={`table-row ${ txn.cashbackName === PAYPAL ? "cursor-pointer" : "" }`} key={index} onClick={() => redeemTransactionDetails( txn )}>
              <div className="td"><img src={txn.iconUrl} alt={txn.cashbackName} className='logo' /> {txn.cashbackName}</div>
              <div className="td">{txn.redeemRequestId}</div>
              <div className="td"><span className='big-text'>{txn.acQyrCashSold}</span></div>
              <div className="td text-capitalize">{txn.status}</div>
              <div className="td"><span className='text-green'>{txn.netRedeemAmount}</span></div>
              <div className="td">{txn.whenRequested}</div>
              <div className="td">{txn.fullName}</div>
            </div>
          ) )}
          {!redeemTransaction.length && (
            <div className='no-record' data-testid='no-redeem-transaction-found'>
              No Redeem Transaction Found
            </div>
          )}
        </div>
      </Scrollbars>
    </div>
  </>
  );
};

RedeemList.propTypes = {
  memoizedTableConfig: PropTypes.array,
  tab: PropTypes.string,
};

export default RedeemList;
