import PropTypes from "prop-types";
import React from "react";
import Form from "react-bootstrap/Form";

export const CloneDraft = ( { onChange, cloneName, errorMessage, placeholder } ) => (
  <Form.Group className='mb-0'>
    <div className='inline-form'>
      <Form.Label>Name*</Form.Label>
      <Form.Control
        type='text'
        name='cloneName'
        placeholder={placeholder}
        value={cloneName}
        onChange={onChange}
      />
    </div>
    <div className='invalid-feedback d-block clone-draft-error'>{errorMessage}</div>
  </Form.Group>
);

CloneDraft.propTypes = {
  onChange: PropTypes.func,
  cloneName: PropTypes.string,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
};
