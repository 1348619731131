import React, { memo } from "react";
import reactHtmlParser from "react-html-parser";
import constant from "../../../util/constant";

// NOTE: reactHtmlParser documentation likes to use PascalCase
// for ReactHtmlParser. However, this is not a class, but
// just a function. Since this isn't a React component or
// anything else that requires it to be PascalCase, switching
// the import to camelCase.
const TableHeaderRow = ( { configData, onAscOrder, onDescOrder } ) => {
  return (
    configData.map( ( item, index ) => (
      <div className={item.sorter ? "th with-sort" : "th"} key={index}>
        {reactHtmlParser( item.title )}
        {item.sorter && (
          <div className='sort-btn-box'>
            <span
              className='icon-up-arrow'
              onClick={() =>
                onAscOrder( item.sorterName, constant.ORDER_BY.ASC )
              }
              data-testid={item.ascTestId}
            ></span>
            <span
              className='icon-down-arrow'
              onClick={() =>
                onDescOrder( item.sorterName, constant.ORDER_BY.DESC )
              }
              data-testid={item.descTestId}
            ></span>
          </div>
        )}
      </div>
    ) )
  );
};

export default memo( TableHeaderRow );
