import React, { useEffect, useMemo, useCallback, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm, useFilter, useAddQuery } from "../../../hooks";
import { getPartnerEventsAction } from "../../../store/actions/partnerEvent";
import { getPartnerLookupAction } from "../../../store/actions/partnerLookup";
import constant from "../../../util/constant";
import eventConstant from "../../../util/eventConstant";
import {
  useSetUrlParams, checkFilterApplied
} from "../../../util/helpers";
import FilterIcon from "../../shared/FilterIcon/FilterIcon";
import { Events } from "../../shared/events";
import MultiSelectCheckbox from "../../shared/multiSelectCheckbox";

// Deconstructing location from globalThis. In the past,
// this would be window, but globalThis has been supported
// for several years as a replacement to window
const { location } = globalThis;

const { ROUTES, ORDER_BY, API_STATUS, FILTERS_NAME } = constant;
const { EVENT_API_TYPES } = eventConstant;

const PartnerEventList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { partnerId, partnerName, events, status,
    apiType, lookup } = useSelector( state => ( {
    partnerId: state.partner.partnerDetail.partnerId,
    partnerName: state.partner?.partnerDetail?.legalEntityName,
    events: state.partnerEvent.partnerEvents.events,
    status: state.partnerEvent.status,
    apiType: state.partnerEvent.apiType,
    lookup: state.partnerLookup.lookup,
  } ) );
  const EVENT_PARTNER_FILTER = [
    { label: partnerName, value: partnerId },
    { label: "Admin", value: "00000000-0000-0000-0000-000000000000" },
  ];
  const { setPureData, filteredData, filterData } = useFilter();
  const { addQuery } = useAddQuery();
  const isFilterApplied = checkFilterApplied( location.search, FILTERS_NAME.PARTNER_EVENTS );
  const { values, handleChange } = useForm(
    {
      searchKey: "",
      sortBy: "eventName",
      sortOrder: ORDER_BY.ASC,
      showPartnerEvents: false,
      searchCol: [ "eventName", "description" ],
      perPage: 1000,
      eventIds: [],
      eventIdLists: [],
      unitIds: [],
      partnerIds: [],
      compareKeyArray: [ "eventIdLists" ],
    },
    {}
  );

  useEffect( () => {
    dispatch( getPartnerEventsAction( { perPage: 200, partnerId: partnerId } ) );
  }, [] );

  const handleSorting = ( sortBy, sortingType ) => {
    handleChange( "sortBy", sortBy );
    handleChange( "sortOrder", sortingType );
    addQuery( useSetUrlParams( "sortColumn", sortBy ) );
    addQuery( useSetUrlParams( "sortOrder", sortingType ) );
  };

  const searchHandle = ( e ) => {
    const { name, value } = e.target;

    handleChange( name, value );
    addQuery( useSetUrlParams( "searchKey", value ) );
  };

  const requestParams = Object.assign(
    {
      eventModifiedIdLists: [],
      sortOrder: values.sortOrder,
      perPage: 200,
      page: values.page,
      partnerId: partnerId,
      searchCol: [ "eventName", "description" ],
      eventIds: values.eventIds?.map( value => value.value ) || [],
      unitIds: values.unitIds?.map( value => value.value ) || [],
      partnerIds: values.partnerIds?.map( value => value.value ) || [],
      eventIdLists: values.eventIdLists?.map( value => value.value ) || [],
      compareKeyArray: values.compareKeyArray,
    },
    values.searchKey && { searchKey: values.searchKey },
    values.sortBy && { sortBy: values.sortBy }
  );

  useEffect( () => {
    if(
      status === API_STATUS.SUCCESS &&
      apiType === EVENT_API_TYPES.GET_PARTNER_EVENTS
    ) {
      dispatch( getPartnerLookupAction( { entities: "Event,EventUnit,Partner" } ) );
      setPureData( events );
      updateStateFromUrl();
      filterData( requestParams );

      const eventModifiedIdLists = events.map( s => ( { label: s.eventId, value: s.eventId } ) ) || [];

      handleChange( "eventModifiedIdLists", eventModifiedIdLists );
    }
  }, [ status, apiType ] );

  useEffect( () => {
    filterData( requestParams );
  }, [ values.activeTab,
    values.sortOrder,
    values.page,
    values.sortBy,
    values.searchKey,
    values.eventIds,
    values.unitIds,
    values.eventIdLists,
    values.partnerIds ] );

  function updateStateFromUrl() {
    const paramsQuery = new URLSearchParams( window.location.search );

    if( paramsQuery.get( "sortOrder" ) ) {
      handleChange( "sortOrder", paramsQuery.get( "sortOrder" ) );
    }

    if( paramsQuery.get( "partnerIds" ) ) {
      handleChange( "partnerIds", JSON.parse( paramsQuery.get( "partnerIds" ) ) );
    }

    if( paramsQuery.get( "eventIdLists" ) ) {
      handleChange( "eventIdLists", JSON.parse( paramsQuery.get( "eventIdLists" ) ) );
    }

    if( paramsQuery.get( "eventIds" ) ) {
      handleChange( "eventIds", JSON.parse( paramsQuery.get( "eventIds" ) ) );
    }

    if( paramsQuery.get( "unitIds" ) ) {
      handleChange( "unitIds", JSON.parse( paramsQuery.get( "unitIds" ) ) );
    }

    if( paramsQuery.get( "searchKey" ) ) {
      handleChange( "searchKey", paramsQuery.get( "searchKey" ) );
    }

    if( paramsQuery.get( "sortColumn" ) ) {
      handleChange( "sortBy", paramsQuery.get( "sortColumn" ) );
    }
  }

  const listHandle = ( obj, listName ) => {
    if( obj.length > 0 ) {
      handleChange( listName, obj );
      addQuery( useSetUrlParams( listName, JSON.stringify( obj ) ) );
    }
    else {
      addQuery( useSetUrlParams( listName, "" ) );
      handleChange( listName );
    }
  };

  const clearHandle = ( parmas ) => {
    if( parmas ) {
      addQuery( useSetUrlParams( parmas, "" ) );
      handleChange( parmas, [] );
    }
  };

  const multiSelectedItems = useMemo( () => [ values.eventIdLists, values.eventIds, values.unitIds, values.partnerIds ] );
  const handleSelectChange = useCallback(
    [ obj => listHandle( obj, "eventIdLists" ),
      obj => listHandle( obj, "eventIds" ),
      obj => listHandle( obj, "unitIds" ),
      obj => listHandle( obj, "partnerIds" ) ],
    []
  );
  const handleClearMultiSelect = useCallback(
    parmas => clearHandle( parmas ), []
  );
  const [ showFilter, setShowFilter ] = useState( false );
  const showFilterScreen = () => setShowFilter( !showFilter );

  return (
    <>
      <div className='main-wrapper'>
        <div className='main-right-wrapper'>
          <div className='page-head-wrapper'>
            <h4 className='page-heading'>Events</h4>
            <div className='page-head-right'>
              <Form.Group className='custom-search w-300'>
                <span className='icon-search'></span>
                <Form.Control
                  type='text'
                  placeholder='Search by Event Name...'
                  onChange={searchHandle}
                  name='searchKey'
                  data-testid='searchKey'
                  value={values.searchKey}
                  title='Search by Event Name, and Description'
                />
              </Form.Group>
              <Button
                variant='primary'
                className='small-btn'
                onClick={() => history.push(
                  ROUTES.PARTNER_ADD_EDIT_EVENT.replace( ":eventId", 0 )
                )}
                data-testid='addEventKey'
              >
                <span className='icon-circle-plus-green icon'></span>
                Add New
              </Button>
              <FilterIcon
                showFilter={showFilter}
                showFilterScreen={showFilterScreen}
                isFilterApplied={isFilterApplied} />
            </div>
          </div>
          <div className={`filter-boxes mr-0 bottom-margin ${ !showFilter ? "" : "remove-filter" }`}>
            <Row>
              <Col md={3}>
                <Form.Group className='custom-multiselect mb-0'>
                  <Form.Label>Event ID</Form.Label>
                  <MultiSelectCheckbox
                    multiOptionsList={values.eventModifiedIdLists}
                    handleSelectChange={handleSelectChange[ 0 ]}
                    multiSelectedItems={multiSelectedItems[ 0 ]}
                    handleClearMultiSelect={() => handleClearMultiSelect( "eventIdLists" )} />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className='custom-multiselect mb-0'>
                  <Form.Label>Event Name</Form.Label>
                  <MultiSelectCheckbox
                    multiOptionsList={lookup?.Event}
                    handleSelectChange={handleSelectChange[ 1 ]}
                    multiSelectedItems={multiSelectedItems[ 1 ]}
                    handleClearMultiSelect={() => handleClearMultiSelect( "eventIds" )} />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className='custom-multiselect mb-0'>
                  <Form.Label>Units</Form.Label>
                  <MultiSelectCheckbox
                    multiOptionsList={lookup?.EventUnit}
                    handleSelectChange={handleSelectChange[ 2 ]}
                    multiSelectedItems={multiSelectedItems[ 2 ]}
                    handleClearMultiSelect={() => handleClearMultiSelect( "unitIds" )} />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className='custom-multiselect mb-0'>
                  <Form.Label>Partner</Form.Label>
                  <MultiSelectCheckbox
                    multiOptionsList={EVENT_PARTNER_FILTER}
                    handleSelectChange={handleSelectChange[ 3 ]}
                    multiSelectedItems={multiSelectedItems[ 3 ]}
                    handleClearMultiSelect={() => handleClearMultiSelect( "partnerIds" )} />
                </Form.Group>
              </Col>
            </Row>
          </div>
          <Events
            events={filteredData}
            onSorting={handleSorting}
            eventEditUrl={ROUTES.PARTNER_ADD_EDIT_EVENT}
            role='partner'
            showFilter={showFilter}
          />
        </div>
      </div>
    </>
  );
};

export default PartnerEventList;
