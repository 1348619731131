import { orderBy } from "lodash";
import moment from "moment";
import { useState, useEffect } from "react";
import { useSetUrlParams } from "../util/helpers";

const useFilter = (
) => {
  const [ pureData, setPureData ] = useState( [] );
  const [ filteredData, setFilteredData ] = useState( [] );
  const formatChangedColumnList = [
    "QXPoints",
    "rank",
    "soldAssetQuantity",
    "quantity",
    "unitPriceQxPoints",
    "linkedGamesCount",
    "acqyrCashBalance",
    "quantity",
    "myPlace",
    "LinkedGamers",
    "PremiumGamers",
    "OfferRewardValue",
    "TournamentRewardValue",
    "AssetExchangedValue",
    "amount",
  ];

  const deepSearcher = ( fields, query ) => ( object ) => {
    const keys = Object.keys( object );

    return keys.some( ( key ) => {
      const value = object[ key ];

      if( fields.includes( key ) ) {
        return value.toLowerCase().includes( query.toLowerCase() );
      }

      return false;
    } );
  };

  const sortingData = ( params, findData ) => {
    let sortByData = findData;

    if( params?.sortBy ) {
      if( formatChangedColumnList.includes( params.sortBy ) ) {
        sortByData = orderBy( findData, [ params.sortBy ], [ params.sortOrder?.toLowerCase() ] );
      }
      else {
        sortByData = orderBy( findData, [ a => a[ params.sortBy ]?.toLowerCase() ], [ params.sortOrder?.toLowerCase() ] );
      }
    }

    return sortByData;
  };

  /**
   * @param {Object} params - have filter keys & values.
   */
  // TODO: refactor to avoid complexity
  // eslint-disable-next-line complexity
  const filterData = ( params ) => {
    let findData = pureData.slice( 0, params.perPage );

    if( params.searchKey ) {
      const customFilter = deepSearcher( params.searchCol, params.searchKey );

      findData = pureData.filter( customFilter );
    }

    const statusObj = [];
    const statusIds = params.status ?? params.statusIds;

    if( ( statusIds ) && ( statusIds.length ) ) {
      findData.forEach( ( data ) => {
        statusIds.forEach( ( val ) => {
          if( ( data?.status || data?.approvalStatus )?.includes( val ) ) {
            statusObj.push( data );
          }
        } );
      } );
      findData = statusObj;
    }

    if( params.gameIds?.length ) {
      if( params?.gameFilterKey ) {
        findData = findData.filter( data => params.gameIds.includes( data[ params.gameFilterKey ] ) );
      }
      else {
        findData = findData.filter( data => params.gameIds.includes( data.name || data.game_id || data.gameId || data.gameName ) );
      }
    }

    const offerAssetIdObj = [];

    if( ( params?.assetId ) && ( params?.assetId?.length ) ) {
      findData.forEach( ( data ) => {
        params.assetId.forEach( ( val ) => {
          if( ( data?.assetId )?.includes( val ) ) {
            offerAssetIdObj.push( data );
          }
        } );
      } );
      findData = offerAssetIdObj;
    }

    const offerAssetNameObj = [];

    if( ( params?.assetName ) && ( params?.assetName?.length ) ) {
      findData.forEach( ( data ) => {
        params.assetName.forEach( ( val ) => {
          if( ( data?.assetName || data?.name )?.includes( val ) ) {
            offerAssetNameObj.push( data );
          }
        } );
      } );
      findData = offerAssetNameObj;
    }

    const offerIdsObj = [];

    if( ( params?.offerIds ) && ( params?.offerIds?.length ) ) {
      findData.forEach( ( data ) => {
        params.offerIds.forEach( ( val ) => {
          if( data?.offerId.includes( val ) ) {
            offerIdsObj.push( data );
          }
        } );
      } );
      findData = offerIdsObj;
    }

    if( params?.eventIdLists?.length ) {
      findData = findData.reduce( ( data, currentItem ) => {
        if( params.eventIdLists.includes( currentItem[ params.compareKeyArray[ 0 ] ] ) ) {
          data.push( currentItem );
        }

        return data;
      },
      [] );
    }

    const eventIdsObj = [];

    if( ( params?.eventIds ) && ( params?.eventIds?.length ) ) {
      findData.forEach( ( data ) => {
        params.eventIds.forEach( ( val ) => {
          if( data?.eventId?.includes( val ) ) {
            eventIdsObj.push( data );
          }
        } );
      } );
      findData = eventIdsObj;
    }

    const unitIdsObj = [];

    if( params.unitIds?.length ) {
      findData.forEach( ( data ) => {
        params.unitIds.forEach( ( val ) => {
          if( data.unitId === val ) {
            unitIdsObj.push( data );
          }
        } );
      } );
      findData = unitIdsObj;
    }

    const partnerIdsObj = [];

    if( params?.partnerIds ) {
      if( params.partnerIds.length ) {
        findData.forEach( ( data ) => {
          params.partnerIds.forEach( ( val ) => {
            if( data.partnerId === val ) {
              partnerIdsObj.push( data );
            }
          } );
        } );
        findData = partnerIdsObj;
      }
    }

    if( params?.gamerName?.length ) { // if filter is being applied on gamer names
      findData = findData.reduce( ( data, currentItem ) => {
        if( params.gamerName.includes( currentItem[ params.compareKeyArray[ 1 ] ] ) ) {
          data.push( currentItem );
        }

        return data;
      }, [] );
    }

    if( params?.gameName?.length ) { // if filter is being applied on game names
      findData = findData.reduce( ( data, currentItem ) => {
        if( params.gameName.includes( currentItem[ params.compareKeyArray[ 0 ] ] ) ) {
          data.push( currentItem );
        }

        return data;
      }, [] );
    }

    if( ( params?.minQxPoint >= 0 ) ||
        ( params?.maxQxPoint >= 0 ) ) {
      const sortedData = findData.filter(
        data => (
          ( data[ params?.compareKeyArray[ 0 ] ] >= params?.minQxPoint ) &&
           ( data[ params?.compareKeyArray[ 0 ] ] <= params?.maxQxPoint )
        )
      );

      findData = sortedData;
    }

    const startDateData = [];

    if( params?.startDate && !params?.endDate ) {
      findData.forEach( ( data ) => {
        const date1 = new Date( data.startDateOriginal );
        const date2 = new Date( params.startDate );

        if( date1.getTime() >= date2.getTime() ) {
          startDateData.push( data );
        }
      } );
      findData = startDateData;
    }

    if( params?.endDate && !params?.startDate ) {
      findData.forEach( ( data ) => {
        const date1 = new Date( data.endDateOriginal );
        const date2 = new Date( params.endDate );

        if( date1.getTime() <= date2.getTime() ) {
          startDateData.push( data );
        }
      } );
      findData = startDateData;
    }

    if( params?.startDate && params?.endDate ) {
      const date1 = new Date( params.startDate );
      const date2 = new Date( params.endDate );
      const startEndData = findData.filter( ( data ) => {
        let newDate;

        if( params?.isSingleDate ) { // if only one field of date is availble on API
          newDate = moment( data.date ).isBetween( date1, date2, "day", "[]" );
        }
        else {
          newDate = ( ( date1.getTime() <= new Date( data.startDateOriginal ).getTime() ) &&
        ( date2.getTime() >= new Date( data.endDateOriginal ).getTime() ) );
        }

        return newDate;
      }
      );

      findData = startEndData;
    }

    if( params?.minUnitPrice || params?.maxUnitPrice ) {
      const sortedData = findData.filter(
        data => (
          ( data[ params?.compareKeyArray[ 0 ] ] >= params?.minUnitPrice ) &&
           ( data[ params?.compareKeyArray[ 0 ] ] <= params?.maxUnitPrice )
        )
      );

      findData = sortedData;
    }

    if( params?.minQuantity || params?.maxQuantity ) {
      const exchangeData = findData.filter(
        data => (
          ( data[ params?.compareKeyArray[ 1 ] ] ) >= ( params?.minQuantity ) &&
          ( data[ params?.compareKeyArray[ 1 ] ] ) <= ( params?.maxQuantity ) )
      );

      findData = exchangeData;
    }

    // Filter tournaments list data based on the selected values of the filter key: tournamentIds
    const selectedTournamentNamesList = [];

    if( params?.tournamentIds?.length ) {
      findData.forEach( ( data ) => {
        params.tournamentIds.forEach( ( val ) => {
          if( data?.tournamentId.includes( val ) ) {
            selectedTournamentNamesList.push( data );
          }
        } );
      } );
      findData = selectedTournamentNamesList;
    }

    if( ( params?.minGamerPlace >= 0 ) ||
    ( params?.maxGamerPlace >= 0 ) ) {
      const gamerPlaceFilteredData = findData.filter(
        data => (
          ( data.myPlace ) >= ( params?.minGamerPlace ) &&
          ( data.myPlace ) <= ( params?.maxGamerPlace ) )
      );

      findData = gamerPlaceFilteredData;
    }

    if( params?.fullNames?.length ) { // if filter is being applied on gamer full names
      findData = findData.reduce( ( data, currentItem ) => {
        if( params.fullNames.includes( currentItem[ params.compareKeyArray[ 2 ] ] ) ) {
          data.push( currentItem );
        }

        return data;
      }, [] );
    }

    if( ( params?.minLinkedGames >= 0 ) ||
        ( params?.maxLinkedGames >= 0 ) ) {
      const gamerLinkedGamesData = findData.filter(
        data => (
          ( data[ params?.compareKeyArray[ 1 ] ] ) >= ( params?.minLinkedGames ) &&
          ( data[ params?.compareKeyArray[ 1 ] ] ) <= ( params?.maxLinkedGames ) )
      );

      findData = gamerLinkedGamesData;
    }

    // Filter list data based on the selected values of the filter keys 'assetNames', 'assetIds' and 'gameNames'.
    const filteredAssetNameList = [];

    if( params?.assetNames?.length ) {
      findData.forEach( ( data ) => {
        if( params.assetNames.includes( data?.assetName ) ) {
          filteredAssetNameList.push( data );
        }
      } );
      findData = filteredAssetNameList;
    }

    const filteredAssetIdList = [];

    if( params?.assetIds?.length ) {
      findData.forEach( ( data ) => {
        if( params.assetIds.includes( data?.assetId ) ) {
          filteredAssetIdList.push( data );
        }
      } );
      findData = filteredAssetIdList;
    }

    const filteredGameNamesList = [];

    if( params?.gameNames?.length ) {
      findData.forEach( ( data ) => {
        if( params.gameNames.includes( data?.gameName ) || params.gameNames.includes( data?.name ) ) {
          filteredGameNamesList.push( data );
        }
      } );
      findData = filteredGameNamesList;
    }

    if( params?.offerNames?.length ) {
      findData = findData.filter( item => params.offerNames.includes( item.offerName ) );
    }

    const optInStatusList = [];

    if( params?.optInTypes?.length ) {
      findData.forEach( ( data ) => {
        if( params.optInTypes.includes( data?.optInType ) ) {
          optInStatusList.push( data );
        }
      } );
      findData = optInStatusList;
    }

    if( params?.gamerPlans?.length ) {
      findData = findData.filter( item => params.gamerPlans.includes( item.gamerPlan ) );
    }

    if( params?.items?.length ) {
      findData = findData.filter( item => params.items.includes( item.item ) );
    }

    if( params?.modes?.length ) {
      findData = findData.filter( item => params.modes.includes( item.mode ) );
    }

    if( params?.status?.length ) {
      findData = findData.filter( item => params.status.includes( item.status ) );
    }

    if( params?.transactionFor?.length ) {
      findData = findData.filter( item => params.transactionFor.includes( item.transactionFor ) );
    }

    if( params?.gamerNames?.length ) {
      findData = findData.reduce( ( data, currentItem ) => {
        if( params.gamerNames.includes( currentItem[ params.compareKeyArray[ 0 ] ] ) ) {
          data.push( currentItem );
        }

        return data;
      }, [] );
    }

    if( params?.partnerNames?.length ) {
      findData = findData.filter( item => params.partnerNames.includes( item.partnerName ) );
    }

    if( params?.offerStatus?.length ) {
      findData = findData.filter( item => params.offerStatus.includes( item.offerStatus ) );
    }

    const sortingDatas = sortingData( params, findData );

    setFilteredData( sortingDatas );
  };

  const handleFilterChange = ( filterName, obj, handleChange, addQuery, locationState ) => {
    if( obj.length > 0 ) {
      handleChange( "page", 1 );
      handleChange( filterName, obj );
      addQuery( useSetUrlParams( filterName, JSON.stringify( obj ) ), locationState );
    }
    else {
      addQuery( useSetUrlParams( filterName, "" ), locationState );
      handleChange( filterName );
    }
  };

  const clearSelectedFilter = ( params, addQuery, handleChange, locationState ) => {
    if( params ) {
      addQuery( useSetUrlParams( params, "" ), locationState );
      handleChange( params, [] );
    }
  };

  const handleSorting = ( sortBy, orderType, handleChange, addQuery, locationState ) => {
    handleChange( "sortBy", sortBy );
    handleChange( "sortOrder", orderType );
    addQuery( useSetUrlParams( "sortBy", sortBy ), locationState );
    addQuery( useSetUrlParams( "sortOrder", orderType ), locationState );
  };

  useEffect( () => {
    if( pureData.length ) {
      setFilteredData( pureData );
    }
  }, [ pureData ] );

  return {
    setPureData,
    filteredData,
    filterData,
    handleFilterChange,
    clearSelectedFilter,
    handleSorting,
  };
};

export default useFilter;
