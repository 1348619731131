import PropTypes from "prop-types";
import React, { useMemo, useCallback } from "react";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";

import { useAddQuery } from "../../../hooks";
import constant from "../../../util/constant";
import { useSetUrlParams, getBackNavigationRoute } from "../../../util/helpers";
import { leaderboardParticipantsTableConfigs } from "../../../util/tableConstant";
import BackButton from "../backButton";
import ParticipantList from "./participantList";

const { ROUTES } = constant;

const LeaderboardAllParticipants = ( {
  participants,
  participantsList = [],
  values,
  handleChange,
  locationState,
} ) => {
  const { addQuery } = useAddQuery();
  const location = useLocation();
  const backScreens = [ "gamerTournamentDetail" ];

  const handleSearch = ( e ) => {
    const { name, value } = e.target;

    handleChange( name, value );
    addQuery( useSetUrlParams( "searchKey", value ), locationState );
  };

  const memoizedTableConfig = useMemo( () => {
    return leaderboardParticipantsTableConfigs;
  }, [] );

  const handleSorting = ( sortBy, sortingType ) => {
    handleChange( "sortBy", sortBy );
    handleChange( "sortOrder", sortingType );
    addQuery( useSetUrlParams( "sortBy", sortBy ), locationState );
    addQuery( useSetUrlParams( "sortOrder", sortingType ), locationState );
  };

  const memoizedHandleClick = useCallback(
    ( sortBy, orderType ) => handleSorting( sortBy, orderType ),
    []
  );

  const handleBackNavigation = () => {
    return getBackNavigationRoute( ROUTES.ADMIN_TOURNAMENT_DETAILS, location, backScreens );
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="main-right-wrapper">
          <BackButton
            backLink={handleBackNavigation()}
            state={location.state}
          />
          <div className="page-head-wrapper">
            <h4 className="page-heading">Participants: {participants}</h4>
            <div className="page-head-right">
              <Form.Group className="custom-search w-300 mr-0">
                <span className="icon-search"></span>
                <Form.Control
                  type="text"
                  placeholder="Search by name"
                  data-testid='searchKey'
                  onChange={handleSearch}
                  value={values.searchKey}
                  name='searchKey'
                  title='Search by name'
                />
              </Form.Group>
            </div>
          </div>
          <ParticipantList
            memoizedTableConfig={memoizedTableConfig}
            memoizedHandleClick={memoizedHandleClick}
            participantsList={participantsList}
            values={values}
            handleChange={handleChange}
          />
        </div>
      </div>
    </>
  );
};

LeaderboardAllParticipants.propTypes = {
  participants: PropTypes.number,
  participantsList: PropTypes.array,
  values: PropTypes.object,
  handleChange: PropTypes.func,
  locationState: PropTypes.string,
};

export default LeaderboardAllParticipants;
