import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
// local-imports
import { getUrlData, useAddQuery, useForm, useFilter } from "../../../../hooks";
import { getGamerDetailAction, getGamerTxnHistoryAction } from "../../../../store/actions/adminGamer";
import adminGamerConstant from "../../../../util/adminGamerConstant";
import constant from "../../../../util/constant";
import {
  useSetUrlParams,
  getUrlParameter,
  getBackNavigationRoute
} from "../../../../util/helpers";
import { adminGamerTxnHistoryTableConfigs } from "../../../../util/tableConstant";
import BackButton from "../../../shared/backButton";
import TxnHistoryFilters from "./txnHistoryFilters";
import TxnHistoryHeader from "./txnHistoryHeader";
import TxnHistoryListing from "./txnHistoryListing";

const { API_STATUS, ROUTES } = constant;
const { GAMER_USERS_API_TYPES } = adminGamerConstant;

const TransactionHistory = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { addQuery } = useAddQuery();
  const backScreens = [ "adminGamerDetail" ];
  const { gamerId: URLString } = useParams();
  const { 0: gamerId } = String( URLString ).split( "?" );
  const { setPureData, filteredData, filterData, handleFilterChange, clearSelectedFilter } = useFilter();

  const {
    txnHistoryList,
    txnHistoryFilters,
    apiType,
    status,
    gamerDetail,
  } = useSelector( state => ( {
    txnHistoryList: state.adminGamer.txnHistoryList,
    txnHistoryFilters: state.adminGamer.txnHistoryFilters,
    apiType: state.adminGamer.apiType,
    status: state.adminGamer.status,
    gamerDetail: state.adminGamer.gamerDetail,
  } ) );

  const { itemNames, modeNames, statusNames } = txnHistoryFilters;

  const { values, handleChange } = useForm(
    {
      searchKey: "",
      sortBy: "",
      sortOrder: "",
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      items: getUrlParameter( "items", undefined ),
      modes: [],
      status: [],
      startDate: null,
      endDate: null,
      searchCol: [ "mode", "item", "status" ],
    },
    {}
  );

  const handleSearch = ( e ) => {
    addQuery( useSetUrlParams( "searchKey", e.target.value ), location.state );
    handleChange( "searchKey", e.target.value );
  };

  const handleSorting = ( sortBy, sortingType ) => {
    handleChange( "sortBy", sortBy );
    handleChange( "sortOrder", sortingType );
    addQuery( useSetUrlParams( "sortBy", sortBy ), location.state );
    addQuery( useSetUrlParams( "sortOrder", sortingType ), location.state );
  };

  const memoizedTableConfig = useMemo( () => adminGamerTxnHistoryTableConfigs, [] );

  const memoizedHandleClick = useCallback( ( sortBy, orderType ) => handleSorting( sortBy, orderType ), [] );

  const [ showFilter, setShowFilter ] = useState( false );

  const handleBackNavigation = () => {
    return getBackNavigationRoute( ROUTES.GAMER_SECTION, location, backScreens );
  };

  const queryParams = Object.assign(
    {
      isSingleDate: true,
      sortOrder: values.sortOrder,
      searchCol: values.searchCol,
      items: values.items?.map( value => value.value ) || [],
      modes: values.modes?.map( value => value.value ) || [],
      status: values.status?.map( value => value.value ) || [],
      searchKey: values.searchKey,
      sortBy: values.sortBy,
      startDate: values.startDate,
      endDate: values.endDate,
    },
    {}
  );

  useEffect( () => {
    dispatch( getGamerTxnHistoryAction( gamerId ) );
  }, [] );

  useEffect( () => {
    if(
      ( status === API_STATUS.SUCCESS ) &&
      ( apiType === GAMER_USERS_API_TYPES.GET_GAMER_TXN_HISTORY_LIST )
    ) {
      dispatch( getGamerDetailAction( gamerId ) );
      setPureData( txnHistoryList );
      getUrlData( handleChange );
      filterData( queryParams );
    }
  }, [ status, apiType ] );

  useEffect( () => {
    filterData( queryParams );
  }, [
    values.sortOrder,
    values.sortBy,
    values.searchKey,
    values.items,
    values.modes,
    values.status,
    values.startDate,
    values.endDate,
  ] );

  return (
    <>
      <div className="main-wrapper">
        <div className="main-right-wrapper">
          <BackButton
            backLink={handleBackNavigation()}
            state={location.state}
          />
          <TxnHistoryHeader
            gamerDetail={gamerDetail}
            handleSearch={handleSearch}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            values={values}
            searchPlaceholder='Search by Item, Mode and Status'
            transactionHitoryList={filteredData}
          />
          <div className="page-head-wrapper">
            <h4 className="page-heading">Billing Transactions</h4>
            <div className="page-head-right">
              <div className="right-more-content">
                <span className="badge badge-primary mb-8px">{gamerDetail.plan || "N/A"}</span>
                <div className="text">
                  Renewal Date: <span className="text-gamer-bold">{gamerDetail.subscriptionRenewalDate || "N/A"}</span>
                </div>
              </div>
            </div>
          </div>
          <div className={`filter-tabs-wrapper mb-8px ${ !showFilter ? "" : "remove-filter" }`}>
            <TxnHistoryFilters
              itemNames={itemNames}
              modeNames={modeNames}
              statusNames={statusNames}
              addQuery={addQuery}
              values={values}
              handleChange={handleChange}
              handleFilterChange={handleFilterChange}
              clearSelectedFilter={clearSelectedFilter}
              locationState={location.state}
            />
          </div>
          <div className="table-container gamer-txn-history-table">
            <TxnHistoryListing
              transactionHitoryList={filteredData}
              memoizedTableConfig={memoizedTableConfig}
              memoizedHandleClick={memoizedHandleClick}
              showFilter={showFilter}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionHistory;
