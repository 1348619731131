export class EmailDetail {
  constructor( obj ) {
    this.acceptAll = obj.accept_all || "";
    this.didYouMean = obj.did_you_mean || "";
    this.disposable = obj.disposable || "";
    this.domain = obj.domain || "";
    this.email = obj.email || "";
    this.free = obj.free || "";
    this.message = obj.message || "";
    this.reason = obj.reason || "";
    this.result = obj.result || "";
    this.role = obj.role || "";
    this.sendex = obj.sendex || 0;
    this.success = obj.success || "";
    this.user = obj?.user || "";
  }
}
