import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSetUrlParams } from "../../../util/helpers";
import FilterIcon from "../../shared/FilterIcon/FilterIcon";
import MultiSelectCheckbox from "../../shared/multiSelectCheckbox";

const SubHeader = ( {
  values,
  onAddNew,
  onSearch,
  handleChange,
  eventNameList,
  eventUnitList,
  partnerList,
  eventIdsList,
  addQuery,
  isFilterApplied,
  updateShowFilterStatus,
} ) => {
  const listHandle = ( obj, listName ) => {
    if( obj.length > 0 ) {
      handleChange( listName, obj );
      handleChange( "page", 1 );
      addQuery( useSetUrlParams( listName, JSON.stringify( obj ) ) );
    }
    else {
      addQuery( useSetUrlParams( listName, "" ) );
      handleChange( listName );
    }
  };

  const clearHandle = ( parmas ) => {
    if( parmas ) {
      addQuery( useSetUrlParams( parmas, "" ) );
      handleChange( parmas, [] );
    }
  };

  const multiSelectedItems = useMemo( () => [ values.eventIdsList, values.eventNameList, values.unitList, values.partnerList ] );
  const handleSelectChange = useCallback(
    [
      obj => listHandle( obj, "eventIdsList" ),
      obj => listHandle( obj, "eventNameList" ),
      obj => listHandle( obj, "unitList" ),
      obj => listHandle( obj, "partnerList" ),
    ],
    []
  );
  const handleClearMultiSelect = useCallback(
    parmas => clearHandle( parmas ), []
  );

  const [ showFilter, setShowFilter ] = useState( false );

  const showFilterScreen = () => {
    setShowFilter( !showFilter );
    updateShowFilterStatus( !showFilter );
  };

  return (
    <>
      <div className='page-head-wrapper'>
        <h4 className='page-heading'>Events</h4>
        <div className='page-head-right'>
          <Form.Group className='custom-search w-300'>
            <span className='icon-search'></span>
            <Form.Control
              type='text'
              value={values.searchDummyText}
              placeholder={values?.isAdmin ? "Search by Event Name, Partner..." : "Search by Event Name, Description"}
              onChange={onSearch}
              name='searchKey'
              data-testid='searchKey'
              title={values?.isAdmin ? "Search by Event Name, Partner, and Description" : "Search by Event Name, and Description"}
            />
          </Form.Group>
          <Button
            variant='primary'
            className='small-btn'
            onClick={onAddNew}
            data-testid='addEventKey'
          >
            <span className='icon-circle-plus-green icon'></span>
            Add New
          </Button>
          <FilterIcon
            showFilter={showFilter}
            showFilterScreen={showFilterScreen}
            isFilterApplied={isFilterApplied} />
        </div>
      </div>
      <div className={`filter-boxes mr-0 bottom-margin ${ !showFilter ? "" : "remove-filter" }`}>
        <Row>
          <Col md={3}>
            <Form.Group className='custom-multiselect mb-0'>
              <Form.Label>Event ID</Form.Label>
              <MultiSelectCheckbox multiOptionsList={eventIdsList}
                handleSelectChange={handleSelectChange[ 0 ]}
                multiSelectedItems={multiSelectedItems[ 0 ]}
                handleClearMultiSelect={() => handleClearMultiSelect( "eventIdsList" )} />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className='custom-multiselect mb-0'>
              <Form.Label>Event Name</Form.Label>
              <MultiSelectCheckbox multiOptionsList={eventNameList}
                handleSelectChange={handleSelectChange[ 1 ]}
                multiSelectedItems={multiSelectedItems[ 1 ]}
                handleClearMultiSelect={() => handleClearMultiSelect( "eventNameList" )} />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className='custom-multiselect mb-0'>
              <Form.Label>Units</Form.Label>
              <MultiSelectCheckbox multiOptionsList={eventUnitList}
                handleSelectChange={handleSelectChange[ 2 ]}
                multiSelectedItems={multiSelectedItems[ 2 ]}
                handleClearMultiSelect={() => handleClearMultiSelect( "unitList" )} />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className='custom-multiselect mb-0'>
              <Form.Label>Partner</Form.Label>
              <MultiSelectCheckbox multiOptionsList={partnerList}
                handleSelectChange={handleSelectChange[ 3 ]}
                multiSelectedItems={multiSelectedItems[ 3 ]}
                handleClearMultiSelect={() => handleClearMultiSelect( "partnerList" )} />
            </Form.Group>
          </Col>
        </Row>
      </div>
    </>
  );
};

SubHeader.propTypes = {
  values: PropTypes.object,
  onAddNew: PropTypes.func,
  onSearch: PropTypes.func,
  handleChange: PropTypes.func,
  eventNameList: PropTypes.array,
  eventUnitList: PropTypes.array,
  partnerList: PropTypes.array,
  addQuery: PropTypes.func,
  eventIdsList: PropTypes.array,
  isFilterApplied: PropTypes.func,
  updateShowFilterStatus: PropTypes.func,
};

export default SubHeader;
