import constant from "../../util/constant";
import {
  PARTNER_LIST_SUCCESS,
  PARTNER_LIST_FAILURE,
  PARTNER_DETAIL_SUCCESS,
  PARTNER_DETAIL_FAILURE,
  ADMIN_API_REQUEST,
  ADMIN_API_END,
  REJECT_PARTNER_REQUEST_FAILURE,
  REJECT_PARTNER_REQUEST_SUCCESS,
  AMMENDMENTS_SUGGESTED_SUCCESS,
  AMMENDMENTS_SUGGESTED_FAILURE,
  CONFIRM_FOR_DOCUSIGN_SUCCESS,
  CONFIRM_FOR_DOCUSIGN_FAILURE,
  RESOLVE_AMENDMENT_SUCCESS,
  RESOLVE_AMENDMENT_FAILURE,
  UPDATE_PARTNER_STATUS_SUCCESS,
  UPDATE_PARTNER_STATUS_FAILURE,
  APPROVE_PARTNER_MANUAL_SUCCESS,
  APPROVE_PARTNER_MANUAL_FAILURE,
  PARTNER_FILTER_LIST_SUCCESS,
  PARTNER_FILTER_LIST_FAILURE,
  ADMIN_PARTNER_LIST_API_REQUEST,
  PARTNER_LIST_API_END,
  GET_ADMIN_DETAIL_SUCCESS,
  GET_ADMIN_DETAIL_FAILURE,
  ADMIN_DETAIL_API_REQUEST,
  ADMIN_DETAIL_API_END,
  GET_ADMIN_PARTNER_GAMES_SUCCESS,
  GET_ADMIN_PARTNER_GAMES_FAILURE,
  GET_ADMIN_PARTNER_OFFERS_SUCCESS,
  GET_ADMIN_PARTNER_OFFERS_FAILURE,
  ADMIN_PARTNER_FILTER_LIST_API_REQUEST,
  READ_NOTIFICATIONS_SUCCESS,
  UPDATE_ADMIN_DETAIL_SUCCESS,
  ADMIN_LINKED_GAMERS_LIST_SUCCESS,
  ADMIN_LINKED_GAMERS_LIST_FAILURE,
  ADMIN_LINKED_GAMERS_FILTER_LIST_SUCCESS,
  ADMIN_LINKED_GAMERS_FILTER_LIST_FAILURE,
  ADMIN_LINKED_GAMER_LIST_API_REQUEST,
  ADMIN_LINKED_GAMER_FILTER_LIST_API_REQUEST
} from "../actionTypes";

const { API_STATUS } = constant;

const DEFAULT_STATE = {
  message: "",
  partners: [],
  partnerDetail: [],
  partnerFilterDetail: {},
  status: "",
  apiType: "",
  partnerGames: { games: [], meta: "" },
  partnerOffers: { offers: [], meta: "" },
  partnerLinkedGamers: { gamers: [], meta: "" },
  adminLinkedGamerFilter: {
    acqyrCashBalance: { min: 0, max: 0 }, gamerNames: [],
    linkedGameRange: { min: 0, max: 0 },
  },
  partnersMeta: {},
};

export const partnerList = ( state = DEFAULT_STATE, action = "" ) => {
  switch ( action.type ) {
    case ADMIN_PARTNER_LIST_API_REQUEST:
    case ADMIN_PARTNER_FILTER_LIST_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
      };
    case PARTNER_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        partners: action.payload.meta.page === 1
          ? action.payload.dataList
          : [ ...state.partners, ...action.payload.dataList ],
        partnersMeta: action.payload.meta,
      };
    case PARTNER_FILTER_LIST_SUCCESS:
      return {
        ...state,
        partnerFilterDetail: action.payload,
        status: API_STATUS.SUCCESS,
      };
    case PARTNER_LIST_FAILURE:
    case PARTNER_FILTER_LIST_FAILURE:
      return {
        ...state,
        partners: [ ...state.partners ],
      };
    case PARTNER_LIST_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    default:
      return state;
  }
};

export const partnerDetail = ( state = DEFAULT_STATE, action = "" ) => {
  switch ( action.type ) {
    case PARTNER_DETAIL_SUCCESS:
      return {
        ...state,
        partnerDetail: action.payload,
      };
    case PARTNER_DETAIL_FAILURE:
    case GET_ADMIN_PARTNER_OFFERS_FAILURE:
    case GET_ADMIN_PARTNER_GAMES_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};

// TODO: refactor to avoid complexity
// eslint-disable-next-line complexity
export const admin = ( state = DEFAULT_STATE, action = "" ) => {
  switch ( action.type ) {
    case ADMIN_API_REQUEST:
    case ADMIN_LINKED_GAMER_LIST_API_REQUEST:
    case ADMIN_LINKED_GAMER_FILTER_LIST_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
      };
    case ADMIN_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case PARTNER_DETAIL_SUCCESS:
      return {
        ...state,
        partnerDetail: action.payload,
      };
    case GET_ADMIN_PARTNER_GAMES_SUCCESS:
      return {
        ...state,
        partnerGames: {
          games: action.payload,
        },
      };
    case GET_ADMIN_PARTNER_OFFERS_SUCCESS:
      return {
        ...state,
        partnerOffers: {
          offers: action.payload,
        },
      };
    case PARTNER_DETAIL_FAILURE:
      return {
        ...state,
      };
    case REJECT_PARTNER_REQUEST_SUCCESS:
    case AMMENDMENTS_SUGGESTED_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
      };
    case CONFIRM_FOR_DOCUSIGN_SUCCESS:
      return {
        ...state,
        partnerDetail: {
          ...partnerDetail,
          ...action.payload,
        },
        status: API_STATUS.SUCCESS,
      };

    case RESOLVE_AMENDMENT_SUCCESS:
    // eslint-disable-next-line prefer-destructuring
    { const suggestedObj = action.payload[ 0 ];
      const newPartnerAmendmentDetails =
        state.partnerDetail.PartnerAmendmentDetails.map( ( suggestion ) => {
          if( suggestion.section_name === suggestedObj.section ) {
            return {
              ...suggestion,
              // eslint-disable-next-line camelcase
              is_resolved: true,
            };
          }

          return suggestion;
        } );

      return {
        ...state,
        partnerDetail: {
          ...state.partnerDetail,
          PartnerAmendmentDetails: newPartnerAmendmentDetails,
        },
        status: API_STATUS.SUCCESS,
      };
    }

    case UPDATE_PARTNER_STATUS_SUCCESS:
      return {
        ...state,
        partnerDetail: {
          ...state.partnerDetail,
          // eslint-disable-next-line camelcase
          is_active: !state.partnerDetail.is_active,
        },
        status: API_STATUS.SUCCESS,
      };
    case REJECT_PARTNER_REQUEST_FAILURE:
    case AMMENDMENTS_SUGGESTED_FAILURE:
    case CONFIRM_FOR_DOCUSIGN_FAILURE:
    case RESOLVE_AMENDMENT_FAILURE:
    case APPROVE_PARTNER_MANUAL_FAILURE:
    case UPDATE_PARTNER_STATUS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
      };
    case APPROVE_PARTNER_MANUAL_SUCCESS:
      return {
        ...state,
        partnerDetail: action.payload,
        status: API_STATUS.SUCCESS,
      };
    case ADMIN_LINKED_GAMERS_FILTER_LIST_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        adminLinkedGamerFilter: {
          acqyrCashBalance: action.payload.acqyrCashBalance,
          gamerNames: action.payload.gamerNames,
          linkedGameRange: action.payload.linkedGameRange,
        },
      };
    case ADMIN_LINKED_GAMERS_LIST_SUCCESS:
      return {
        ...state,
        partnerLinkedGamers: {
          gamers:
                  action.payload.meta.page === 1
                    ? action.payload.dataList
                    : [ ...state.partnerLinkedGamers.gamers, ...action.payload.dataList ],
          meta: action.payload.meta,
        },
        status: API_STATUS.SUCCESS,
      };
    case ADMIN_LINKED_GAMERS_LIST_FAILURE:
    case ADMIN_LINKED_GAMERS_FILTER_LIST_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        partnerLinkedGamers: { gamers: [], meta: "" },
        adminLinkedGamerFilter: {
          acqyrCashBalance: { min: 0, max: 0 },
          gamerNames: [],
          linkedGameRange: { min: 0, max: 0 },
        },
      };
    default:
      return state;
  }
};

export const adminDetail = ( state = { loading: false, data: {}, apiType: "", status: "" }, action = "" ) => {
  switch ( action.type ) {
    case ADMIN_DETAIL_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
        loading: true,
      };
    case ADMIN_DETAIL_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case GET_ADMIN_DETAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        data: action.payload,
        loading: false,
      };
    case UPDATE_ADMIN_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          // To update the new permission of active admin
          permissions: action.payload.isActiveAdmin
            ? action.payload.admin_permissions
            : state.data.permissions,
        },
        status: API_STATUS.SUCCESS,
      };
    case GET_ADMIN_DETAIL_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        loading: false,
      };
    case READ_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          unreadNotificationsCount: 0,
        },
      };
    default:
      return state;
  }
};
