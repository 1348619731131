import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory, useParams } from "react-router-dom";
// Local-Imports
import { useForm, useAddQuery, getUrlData } from "../../../hooks";
import { getPartnerGameDetailsAction } from "../../../store/actions/partnerAnalytics";
import Enums from "../../../util/Enums";
import analyticsConstant from "../../../util/analyticsConstant";
import constant from "../../../util/constant";
import { getBackNavigationRoute, useSetUrlParams, checkFilterApplied } from "../../../util/helpers";
import FilterIcon from "../../shared/FilterIcon/FilterIcon";
import BackButton from "../../shared/backButton";
import PartnerGameDetail from "../../shared/games/partnerGameDetail";
import Tabs from "../../shared/tabs";
import ExchangeList from "./exchangeList";
import GamerList from "./gamerList";
import OfferRewardTransactions from "./offerRewardTxn/offerRewardTransactions";
import OptInTxn from "./optInTxn/optInTxn";

const { ROUTES, FILTERS_NAME, BUSINESS_ENTITY } = constant;
const { DEFAULT_USD_PER_QX_POINT, DEFAULT_PARTNER_COST_USD_PER_QX_POINT } = Enums;

const ViewGame = () => {
  const location = useLocation();
  const paramsQuery = new URLSearchParams( window.location.search );
  const activeTab = paramsQuery.get( "tab" );
  const history = useHistory();
  const dispatch = useDispatch();
  const { addQuery } = useAddQuery();
  const backScreens = [ "analtyicsGames" ];
  const [ entityType, setEntityType ] = useState( BUSINESS_ENTITY.OFFER );
  const { gameDetails, usdPerQxPoint, exchangeTransaction, partnerCostUsdPerQxPoint } = useSelector( state => ( {
    gameDetails: state.partnerAnalytics.gameDetails,
    usdPerQxPoint: state.partner.partnerDetail.configuration?.usdPerQxPoint || DEFAULT_USD_PER_QX_POINT,
    exchangeTransaction: state.partnerAnalytics.exchangeTransaction,
    partnerCostUsdPerQxPoint: state.partner.partnerDetail.configuration?.partnerCostUsdPerQxPoint || DEFAULT_PARTNER_COST_USD_PER_QX_POINT,
  } ) );
  const { qxPointsRange, gamerPlaceRange } = exchangeTransaction;
  const { ANALYTICS_TAB, SEARCH_PLACEHOLDER } = analyticsConstant;
  const { gameId } = useParams();
  const [ selectedGameId ] = gameId.split( "?" );
  const sliderMinMaxObj = {
    minQxPoint: qxPointsRange.min,
    maxQxPoint: qxPointsRange.max,
    minGamerPlace: gamerPlaceRange.min,
    maxGamerPlace: gamerPlaceRange.max,
  };
  const isFilterApplied = checkFilterApplied( window.location.search, FILTERS_NAME.ANALYTICS_REWARD_TXN, sliderMinMaxObj );

  useEffect( () => {
    if( selectedGameId ) {
      dispatch( getPartnerGameDetailsAction( selectedGameId, { getOffers: true }, entityType ) );
    }
  }, [ selectedGameId, entityType ] );

  const { values, handleChange } = useForm(
    {
      tab: "",
      searchKey: "",
      placeholder: SEARCH_PLACEHOLDER.games,
    },
    {}
  );

  const setTab = ( val ) => {
    if( ( val === BUSINESS_ENTITY.OFFER ) || ( val === BUSINESS_ENTITY.TOURNAMENT ) ) {
      setEntityType( val );
    }
    else {
      handleChange( "searchKey", "" );

      const url = `${ ROUTES.ANALYTIC_VIEW_GAME.replace( ":gameId", selectedGameId ) }`;

      history.push( {
        pathname: `${ url }?tab=${ val }`,
        state: location.state,
      } );
    }
  };

  useEffect( () => {
    handleChange( "tab", activeTab );
  }, [ activeTab ] );

  const handleBackNavigation = () => {
    return getBackNavigationRoute( ROUTES.PARTNER_ANALYTICS, location, backScreens );
  };

  const searchHandle = ( e ) => {
    addQuery( useSetUrlParams( "searchKey", e.target.value ), location.state );
    handleChange( "searchKey", e.target.value );
  };

  const setPreviousData = () => {
    getUrlData( handleChange );
    handleChange( "placeholder", SEARCH_PLACEHOLDER[ values.tab ] );
  };

  const [ showFilter, setShowFilter ] = useState( false );
  const showFilterScreen = () => setShowFilter( !showFilter );

  return (
    <div className="main-wrapper">
      <div className="main-right-wrapper">
        <BackButton
          backLink={handleBackNavigation()}
          state=''
        />
        <div className='filter-tabs-wrapper'>
          <div className='custom-tabs'>
            <Tabs
              values={values}
              setTab={setTab}
              tab={ANALYTICS_TAB}
              isPartnerDetail={true}
            />
          </div>
        </div>
        <div className='page-head-wrapper'>
          <h4 className='page-heading'></h4>
          <div className='page-head-right'>
            { ( values.tab !== ANALYTICS_TAB.GAMES ) && (
              <>
                <Form.Group className='custom-search w-300 mr-0 placeholder-ellipsis'>
                  <span className='icon-search'></span>
                  <Form.Control
                    type='text'
                    placeholder={values.placeholder}
                    onChange={searchHandle}
                    data-testid='search'
                    value={values.searchKey}
                    title={values.placeholder}
                  />
                </Form.Group>
                <FilterIcon
                  showFilter={showFilter}
                  showFilterScreen={showFilterScreen}
                  isFilterApplied={isFilterApplied}
                />
              </>
            ) }
          </div>
        </div>
        { ( values.tab === ANALYTICS_TAB.GAMES ) && (
          <div className="page-inner-box with-back-header">
            <PartnerGameDetail
              viewType={true}
              gameId={gameId}
              gameDetails={gameDetails}
              usdPerQxPoint={usdPerQxPoint}
              setTab={setTab}
              entityType={entityType}
              partnerCostUsdPerQxPoint={gameDetails?.partnerCostUsdPerQxPoint || partnerCostUsdPerQxPoint}
            />
          </div>
        )}
        { ( values.tab === ANALYTICS_TAB.EXCHANGE_TRANSACTION ) && (
          <ExchangeList
            gameId={selectedGameId}
            setPreviousData={setPreviousData}
            gameName={gameDetails.gameName}
            tab={values.tab}
            showFilter={showFilter}
            searchKey={values.searchKey}
          />
        )}
        { ( values.tab === ANALYTICS_TAB.GAMERS ) && (
          <GamerList
            gameId={selectedGameId}
            setPreviousData={setPreviousData}
            gameName={gameDetails.gameName}
            showFilter={showFilter}
            searchKey={values.searchKey}
          />
        )}
        { ( values.tab === ANALYTICS_TAB.OFFER_REWARD_TXN ) && (
          <OfferRewardTransactions
            gameId={selectedGameId}
            setPreviousData={setPreviousData}
            gameName={gameDetails.gameName}
            tab={values.tab}
            showFilter={showFilter}
            searchKey={values.searchKey}
          />
        )}
        { ( values.tab === ANALYTICS_TAB.TOURNAMENT_REWARD_TXN ) && (
          <OfferRewardTransactions
            gameId={selectedGameId}
            setPreviousData={setPreviousData}
            gameName={gameDetails.gameName}
            tab={values.tab}
            showFilter={showFilter}
            searchKey={values.searchKey}
          />
        )}
        { ( values.tab === ANALYTICS_TAB.OPT_IN_TXN ) && (
          <OptInTxn
            gameId={selectedGameId}
            setPreviousData={setPreviousData}
            gameName={gameDetails.gameName}
            tab={values.tab}
            showFilter={showFilter}
            searchKey={values.searchKey}
          />
        )}
      </div>
    </div>
  );
};

ViewGame.propTypes = {
  match: PropTypes.string,
  isComment: PropTypes.string,
};

export default ViewGame;
