import { toastr } from "react-redux-toastr";
import { EventDetails } from "../../entities/eventDetails";
import { enableDisableEventRequest, addEvent, getAdminEvents, updateAdminEvent, getAdminEventDetail } from "../../services/adminEventServices";
import constant from "../../util/constant";
import eventConstant from "../../util/eventConstant";
import {
  START_LOADER,
  STOP_LOADER,
  ADMIN_EVENT_API_REQUEST,
  ADMIN_EVENT_API_END,
  ADD_ADMIN_EVENT_SUCCESS,
  ADD_ADMIN_EVENT_FAILURE,
  GET_ADMIN_EVENTS_SUCCESS,
  GET_ADMIN_EVENTS_FAILURE,
  UPDATE_ADMIN_EVENT_SUCCESS,
  UPDATE_ADMIN_EVENT_FAILURE,
  GET_ADMIN_EVENT_DETAIL_SUCCESS,
  GET_ADMIN_EVENT_DETAIL_FAILURE,
  ENABLE_DISABLE_EVENT_SUCCESS,
  ENABLE_DISABLE_EVENT_FAILURE
} from "../actionTypes";

const { EVENT_API_TYPES } = eventConstant;
const { ERROR_MESSAGES } = constant;

export const addEventAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_EVENT_API_REQUEST,
      payload: EVENT_API_TYPES.ADD_EVENT,
    } );

    const response = await addEvent( param );

    dispatch( { type: ADD_ADMIN_EVENT_SUCCESS, payload: response.data } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    const err = Object.values( error?.data?.error || {} ) || "";

    dispatch( { type: STOP_LOADER } );
    dispatch( { type: ADD_ADMIN_EVENT_FAILURE, payload: error } );

    if( err[ 0 ] ) {
      toastr.error( err[ 0 ] );
    }
    else {
      toastr.error( error.data.message );
    }
  }
  finally {
    dispatch( {
      type: ADMIN_EVENT_API_END,
    } );
  }
};

export const getAdminEventsAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_EVENT_API_REQUEST,
      payload: EVENT_API_TYPES.GET_ADMIN_EVENTS,
    } );

    const response = await getAdminEvents( param );
    const dataList = response.data.eventData.map(
      item => new EventDetails( item )
    );

    dispatch( {
      type: GET_ADMIN_EVENTS_SUCCESS,
      payload: { dataList, meta: response.data.meta },
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GET_ADMIN_EVENTS_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_EVENT_API_END,
    } );
  }
};

export const updateAdminEventAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_EVENT_API_REQUEST,
      payload: EVENT_API_TYPES.UPDATE_ADMIN_EVENT,
    } );

    const response = await updateAdminEvent( param );

    dispatch( { type: UPDATE_ADMIN_EVENT_SUCCESS, payload: response.data } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    const err = Object.values( error?.data?.error || {} ) || "";

    dispatch( { type: STOP_LOADER } );
    dispatch( { type: UPDATE_ADMIN_EVENT_FAILURE, payload: error } );

    if( err[ 0 ] ) {
      toastr.error( err[ 0 ] );
    }
    else {
      toastr.error( error.data.message );
    }
  }
  finally {
    dispatch( {
      type: ADMIN_EVENT_API_END,
    } );
  }
};

export const getAdminEventDetailAction = param => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_EVENT_API_REQUEST,
      payload: EVENT_API_TYPES.GET_ADMIN_EVENT_DETAIL,
    } );

    const response = await getAdminEventDetail( param );
    const newResponse = new EventDetails( response.data );

    dispatch( {
      type: GET_ADMIN_EVENT_DETAIL_SUCCESS,
      payload: newResponse,
    } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: STOP_LOADER } );
    dispatch( {
      type: GET_ADMIN_EVENT_DETAIL_FAILURE,
      payload: error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR,
    } );
    toastr.error( error?.data?.message || ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( {
      type: ADMIN_EVENT_API_END,
    } );
  }
};

export const enableDisableEventAction = requestObj => async ( dispatch ) => {
  try {
    dispatch( { type: START_LOADER } );
    dispatch( {
      type: ADMIN_EVENT_API_REQUEST,
      payload: EVENT_API_TYPES.ENABLE_DISABLE_PARTNER_GAME,
    } );

    const response = await enableDisableEventRequest( requestObj );

    dispatch( { type: ENABLE_DISABLE_EVENT_SUCCESS, payload: response } );
    dispatch( { type: STOP_LOADER } );
  }
  catch ( error ) {
    dispatch( { type: ENABLE_DISABLE_EVENT_FAILURE, payload: error } );
    dispatch( { type: STOP_LOADER } );
    toastr.error( error?.error || constant.ERROR_MESSAGES.EXCEPTION_ERROR );
  }
  finally {
    dispatch( { type: ADMIN_EVENT_API_END } );
  }
};
