import { getOfferStatus, convertDate } from "../util/helpers";
import offerConstant from "../util/offerConstant";

const { OFFER_STATUS } = offerConstant;

export class AdminGamerOffers {
  constructor( obj ) {
    this.gameName = obj?.Games?.name;
    this.gameIcon = obj?.Games?.icon_url;
    this.associatedOffers = obj?.Games?.OfferSchedules?.length
      ? obj.Games.OfferSchedules.map( ( val ) => {
        return {
          offerId: val?.offer_id || "",
          startDate: convertDate( val?.start_date ) || "",
          endDate: convertDate( val?.end_date ) || "",
          offerName: val?.Offer?.name || "",
          statusTitle: OFFER_STATUS.find(
            offer => offer.status === getOfferStatus( val )
          ),
          maxRewardCountPerUser: val?.max_reward_count_per_user || "",
          descriptionText: val?.Offer?.description_text || "",
          rewardUnit: val?.Offer?.OfferReward?.reward_unit || "",
          rewardValue: val?.Offer?.OfferReward?.reward_value || "",
        };
      } )
      : [];
  }
}
