import VisibilityIcon from "@material-ui/icons/Visibility";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { useAddQuery, useForm, useFilter, getUrlData } from "../../../hooks";
import { getGamerListsAction } from "../../../store/actions/partnerAnalytics";
import analyticsConstant from "../../../util/analyticsConstant";
import constant from "../../../util/constant";
import { useSetUrlParams } from "../../../util/helpers";
import { analyticsGamersTableConfigs } from "../../../util/tableConstant";
import { ScrollPage } from "../../shared/customScrollBar";
import TableHeaderRow from "../../shared/tableHeadingRow";
import AnalyticsGamerFilterList from "./analyticsGamerFilterList";

const { API_STATUS, ROUTES } = constant;
const { API_TYPES } = analyticsConstant;

const GamerList = ( {
  searchKey = "",
  showFilter,
  gameId = "",
  setPreviousData = "",
  gameName = "",
} ) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { addQuery } = useAddQuery();
  const { filteredData, filterData, setPureData } = useFilter();
  const [ , , screenType ] = location.pathname.split( "/" );
  const {
    gamerLists,
    gamerFilterList,
    apiType,
    status,
  } = useSelector( state => ( {
    gamerLists: state.partnerAnalytics.gamerLists,
    gamerFilterList: state.partnerAnalytics.gamerFilterList,
    apiType: state.partnerAnalytics.apiType,
    status: state.partnerAnalytics.status,
  } ) );
  const isGameId = Boolean( gameId );
  const { fullName } = gamerLists;
  const { values, handleChange } = useForm(
    {
      searchKey: "",
      sortBy: "gameName",
      sortOrder: "ASC",
      gamerName: [],
      gameName: [],
      gamerPlans: [],
      searchCol: [ "fullName", "gameName" ],
      compareKeyArray: [ "gameName", "fullName" ],
    },
    {}
  );

  const requestParams = Object.assign(
    {
      isSingleDate: true,
      searchCol: values.searchCol,
      sortOrder: values.sortOrder,
      sortBy: values.sortBy,
      searchKey: values.searchKey,
      gamerName: values.gamerName?.map( value => value.value ) || [],
      gameName: values.gameName?.map( value => value.value ) || [],
      gamerPlans: values.gamerPlans?.map( value => value.value ) || [],
      compareKeyArray: values.compareKeyArray,
    }
  );

  const handleSorting = ( sortBy, orderType ) => {
    handleChange( "sortBy", sortBy );
    handleChange( "sortOrder", orderType );
    addQuery( useSetUrlParams( "sortBy", sortBy ), location.state, isGameId );
    addQuery( useSetUrlParams( "sortOrder", orderType ), location.state, isGameId );
  };

  const memoizedHandleClick = useCallback(
    ( sortBy, orderType ) => handleSorting( sortBy, orderType ),
    []
  );

  useEffect( () => {
    dispatch( getGamerListsAction( requestParams ) );
  }, [] );

  const memoizedTableConfig = useMemo( () => {
    return analyticsGamersTableConfigs;
  }, [] );

  useEffect( () => {
    filterData( requestParams );
  }, [
    values.sortOrder,
    values.sortBy,
    values.searchKey,
    values.gameName,
    values.gamerName,
    values.gamerPlans,
  ] );

  useEffect( () => {
    handleChange( "searchKey", searchKey );
  }, [ searchKey ] );

  useEffect( () => {
    if( gameId && gameName ) {
      const selectedGame = { label: gameName, value: gameName };

      handleChange( "gameName", [ selectedGame ] );
      addQuery( useSetUrlParams( "gameName", JSON.stringify( [ selectedGame ] ) ), location.state, isGameId );
    }
  }, [ gameId, gameName ] );

  useEffect( () => {
    if(
      status === API_STATUS.SUCCESS &&
      apiType === API_TYPES.GET_GAMER_LISTS
    ) {
      setPureData( gamerLists );
      setTimeout( () => {
        getUrlData( handleChange );
        setPreviousData();
      }, 2000 );
    }
  }, [ status, apiType ] );

  return ( <>
    <div className='filter-tabs-wrapper mb-0'>
      <AnalyticsGamerFilterList
        values={values}
        handleChange={handleChange}
        addQuery={addQuery}
        gameNames={gamerFilterList.gameNames}
        gamerNames={gamerFilterList.gamerNames}
        fullName={fullName}
        isGameId={isGameId}
        showFilter={showFilter}
        locationState={location.state}
        gamerPlans={gamerFilterList.gamerPlans}
      />
    </div>

    <div className="table-container partner-analytics-gamers">
      <div className="table-head">
        <div className="table-row">
          <TableHeaderRow
            configData={memoizedTableConfig}
            onAscOrder={memoizedHandleClick}
            onDescOrder={memoizedHandleClick}
          />
        </div>
      </div>
      <Scrollbars renderThumbVertical={ScrollPage}
        className={`custom-scroll-height medium ${ !showFilter ? "" : "full-height" }`}
      >
        <div className="table-body no-scrollbar" >
          {Boolean( filteredData.length ) && filteredData.map( ( txn, index ) => (
            <div className="table-row" key={index}>
              <div className="td">{txn.fullName}</div>
              <div className="td">
                <span className='text-green font-medium'>
                  {txn.gameName}
                </span>
              </div>
              <div className="td">{ txn.gamerPlan}</div>
              <div className="td" onClick={() =>
                history.push( {
                  pathname: `${ ROUTES.PARTNER_ANALYTICS_GAMER_ASSETS.replace(
                    ":gameId",
                    txn?.gameId
                  ).replace(
                    ":gamerId",
                    txn?.userId
                  ) }?from=${ screenType }`,
                  state: ( screenType === "analytics" ) ?
                    [ { from: "analyticsLinkedGamers", path: location.pathname, state: location.search } ]
                    : [ ...location.state,
                      ...[ { from: "analyticsLinkedGamers", path: location.pathname, state: location.search } ],
                    ],
                } )
              }>
                <span className='cursor-pointer'><VisibilityIcon /></span>
              </div>
            </div>
          ) )}
          {!filteredData.length && (
            <div className='no-record' data-testid='no-record-found'>
              No Gamer Found
            </div>
          )}
        </div>
      </Scrollbars>
    </div>
  </>
  );
};

GamerList.propTypes = {
  searchKey: PropTypes.string,
  gameId: PropTypes.string,
  setPreviousData: PropTypes.func,
  fullName: PropTypes.string,
  gameName: PropTypes.string,
  gamerPlans: PropTypes.array,
  showFilter: PropTypes.string,
};

export default GamerList;
