import React, { useEffect, useState, useRef } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm, usePartnerDraft } from "../../../hooks";
import { setContactInfoAction } from "../../../store/actions/profileInfo";
import constant from "../../../util/constant";
import {
  getAllCountries,
  getStatesOfCountry,
  getCitiesOfState,
  phoneFormat,
  enableOnLoadEvent,
  disableOnLoadEvent
} from "../../../util/helpers";
import partnerProfileConstant from "../../../util/partnerProfileConstant";
import ApplicationFooter from "../../shared/partner/applicationFooter";
import BusinessLocationAddress from "./businessLocationAddress";
import PrimaryContactInfo from "./primaryContactInfo";
import SecondaryContactInfo from "./secondaryContactInfo";

const { API_TYPES, TAB } = partnerProfileConstant;
const { ROUTES, API_STATUS } = constant;

const initialState = {
  dba: "",
  address1: "",
  address2: "",
  country: "",
  state: "",
  city: "",
  zip: "",
  primaryName: "",
  primaryTitle: "",
  primaryPhoneNumber: "",
  primaryAlternativePhoneNumber: "",
  primaryEmail: "",
  secondaryName: "",
  secondaryTitle: "",
  secondaryPhoneNumber: "",
  secondaryAlternativePhoneNumber: "",
  secondaryEmail: "",
  authorizedName: "",
  authorizedTitle: "",
};
const initialDirty = {
  address1: false,
  country: false,
  state: false,
  city: false,
  zip: false,
  primaryName: false,
  primaryTitle: false,
  primaryPhoneNumber: false,
  primaryEmail: false,
  secondaryName: false,
  secondaryTitle: false,
  secondaryPhoneNumber: false,
  secondaryEmail: false,
  authorizedName: false,
  authorizedTitle: false,
};

const ContactInfo = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [ isAutoSave, setAutoSave ] = useState( true );
  const [ isMount, setMount ] = useState( true );
  const {
    profileInfo: { data, apiType, status: apiStatus },
    partnerDraft: { partnerDraftDetail, status: draftApiStatus, apiType: draftApiType },
  } = useSelector( state => state );
  const valuesRef = useRef( null );
  const { values, errors, handleChange } =
    useForm(
      {
        ...initialState,
        countryList: [],
        stateList: [],
        cityList: [],
      },
      initialDirty
    );

  valuesRef.current = values;

  const getRequestValue = name => data[ name ] || "";

  const handleInputChange = ( e ) => {
    const { name, value } = e.target;

    if(
      name === "primaryPhoneNumber" ||
      name === "secondaryPhoneNumber" ||
      name === "secondaryAlternativePhoneNumber" ||
      name === "primaryAlternativePhoneNumber"
    ) {
      handleChange( name, phoneFormat( value ) );
    }
    else {
      handleChange( name, value );
    }
  };

  const saveContactInfo = ( isRefValue ) => {
    const { country, state, city } = values;
    const contactData = isRefValue ? valuesRef.current : values;

    dispatch( setContactInfoAction( {
      ...contactData,
      country: isRefValue ? contactData.country.value : country.value,
      state: isRefValue ? contactData.state.value : state.value,
      city: isRefValue ? contactData.city.value : city.value,
    } ) );
  };

  const handleNext = () => {
    setAutoSave( false );
    saveContactInfo();
  };

  useEffect( () => {
    return () => saveContactInfo( true );
  }, [] );

  usePartnerDraft( getRequestValue, partnerDraftDetail, apiType, apiStatus, TAB.CONTACT_INFO, !isAutoSave );

  useEffect( () => {
    if(
      ( ( draftApiType === API_TYPES.ADD_CONTACT_DRAFT_DETAIL ) || ( draftApiType === API_TYPES.UPDATE_CONTACT_DRAFT_DETAIL ) ) &&
      ( draftApiStatus === API_STATUS.SUCCESS ) && !isAutoSave
    ) {
      disableOnLoadEvent();
      history.push( ROUTES.PARTNER_OTHER_INFO );
    }
  }, [ draftApiType, draftApiStatus ] );

  useEffect( () => {
    if( isMount && partnerDraftDetail.data ) {
      enableOnLoadEvent();
      setMount( false );
      Object.keys( initialState ).forEach( ( key ) => {
        if( partnerDraftDetail.data[ key ] ) {
          handleChange( key, partnerDraftDetail.data[ key ] );
        }
      } );

      const countries = getAllCountries( partnerDraftDetail.data.country || "US" );
      const states = getStatesOfCountry( partnerDraftDetail.data.country || "US" );

      handleChange( "stateList", states );
      handleChange( "countryList", countries );

      if( partnerDraftDetail.data.country ) {
        handleChange( "country", {
          label: countries.find( country => country.value === partnerDraftDetail.data.country ).label,
          value: partnerDraftDetail.data.country,
        } );
      }

      if( partnerDraftDetail.data.state ) {
        handleChange( "state", {
          label: states.find( state => state.value === partnerDraftDetail.data.state ).label,
          value: partnerDraftDetail.data.state,
        } );
      }

      if( partnerDraftDetail.data.city ) {
        handleChange( "city", {
          label: partnerDraftDetail.data.city,
          value: partnerDraftDetail.data.city,
        } );
      }
    }
  }, [ partnerDraftDetail ] );

  useEffect( () => {
    const cities = getCitiesOfState(
      values.country.value || "US",
      values.state.value || "AL"
    );

    handleChange( "cityList", cities );
  }, [ values.state ] );

  const handleSelectInput = ( name, value ) => {
    if( name === "country" ) {
      const states = getStatesOfCountry( value.value || "US" );

      handleChange( "stateList", states );

      if( values.state ) {
        handleChange( "state", "" );
      }

      if( values.city ) {
        handleChange( "city", "" );
      }
    }
    else if( ( name === "state" ) && values.city ) {
      handleChange( "city", "" );
    }

    handleChange( name, value );
  };

  return (
    <div className='main-wrapper'>
      <div className='main-right-wrapper'>
        <div className='page-head-wrapper'>
          <h4 className='page-heading'>Contact Information</h4>
          <div className='page-head-right'>
            <button
              className='link-btn back-btn'
              onClick={() => history.goBack()}
            >Back</button>
            <button className='btn btn-primary' onClick={handleNext}>Next</button>
          </div>
        </div>
        <div className='page-inner-box profile-steps-box with-fixed-footer'>
          <Form>
            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>DBA (Optional)</Form.Label>
                  <Form.Control
                    type='text'
                    value={values.dba}
                    name='dba'
                    onChange={handleInputChange}
                    maxLength={45}
                    onBlur={saveContactInfo}
                  />
                </Form.Group>
              </Col>
            </Row>
            <BusinessLocationAddress
              values={values}
              errors={errors}
              handleInputChange={handleInputChange}
              handleSelectInput={handleSelectInput}
              onBlur={saveContactInfo}
            />
            <PrimaryContactInfo values={values} errors={errors} handleInputChange={handleInputChange} onBlur={saveContactInfo} />
            <SecondaryContactInfo values={values} errors={errors} handleInputChange={handleInputChange} onBlur={saveContactInfo} />
            <h4 className='page-inner-heading'>Authorized Signatory</h4>
            <Row>
              <Col sm={6}>
                <Form.Group className='last-form-group'>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type='text'
                    value={values.authorizedName}
                    name='authorizedName'
                    onChange={handleInputChange}
                    maxLength={45}
                    onBlur={saveContactInfo}
                  />
                  <div className='invalid-feedback d-block'>{errors.authorizedName}</div>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className='last-form-group'>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type='text'
                    value={values.authorizedTitle}
                    name='authorizedTitle'
                    onChange={handleInputChange}
                    maxLength={45}
                    onBlur={saveContactInfo}
                  />
                  <div className='invalid-feedback d-block'>{errors.authorizedTitle}</div>
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <ApplicationFooter></ApplicationFooter>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
