import { capitalize } from "lodash";

export class RedeemTxFilters {
  constructor( obj ) {
    this.netRedeemAmountRange = obj.transactionRanges.netRedeemAmountRange;
    this.qxPointsRange = obj.transactionRanges.qxPointsRange;
    this.status = Object.values( obj.transactionStatus ).map( ( value ) => {
      return {
        label: capitalize( value ),
        value,
      };
    } );
    this.cashbackVendors = obj.paymentVendors.map( ( cb ) => {
      return {
        label: cb.name,
        value: cb.methodId,
      };
    } );
  }
}
