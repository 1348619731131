import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import { useSelector, useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useForm } from "../../../hooks";
import { checkEmailVerifyAction } from "../../../store/actions/emailVerify";
import {
  getPartnerDetailAction,
  updatePartnerProfileAction
} from "../../../store/actions/partner";
import {
  uploadLogoAction,
  startLoader
} from "../../../store/actions/profileInfo";
import Enums from "../../../util/Enums";
import constant from "../../../util/constant";
import emailVerifyConstant from "../../../util/emailVerifyConstant";
import { formatFileSize,
  getAllCountries,
  getStatesOfCountry,
  getCitiesOfState,
  phoneFormat,
  resizeImageFile
} from "../../../util/helpers";
import partnerProfileConstant from "../../../util/partnerProfileConstant";
import { EmailReset } from "../../shared/emailReset";
import { PasswordReset } from "../../shared/passwordReset";
import { GeneralInformation, ContactInformation, OtherInformation } from "../../shared/profiles";


const { TAB, SETTING_TAB } = partnerProfileConstant;
const { FILE_SIZE_UNIT, API_STATUS, SOFTWARE_DEVELOPMENT_ABILITY, API_TYPES, ICON_FILE_MAX_SIZE } = constant;
const { API_TYPE } = emailVerifyConstant;
const { NUMERIC_VALUE } = Enums;

const initialState = {
  settingTab: SETTING_TAB.EDIT_PROFILE,
  partnerDetailTab: TAB.GENERAL_INFO,
  legalEntityName: "",
  categoryId: "0",
  joinReasonId: "0",
  logo: null,
  dba: "",
  address1: "",
  address2: "",
  zip: "",
  city: "Abbeyille",
  state: "AL",
  country: "US",
  primaryName: "",
  primaryTitle: "",
  primaryPhoneNumber: "",
  primaryAlternativePhoneNumber: "",
  primaryEmail: "",
  secondaryName: "",
  secondaryTitle: "",
  secondaryPhoneNumber: "",
  secondaryAlternativePhoneNumber: "",
  secondaryEmail: "",
  authorizedName: "",
  authorizedTitle: "",
  estimatedOfGames: "",
  estimatedOfGamers: "",
  gameGrowth: "",
  gamersGrowth: "",
  softwareDevelopmentAbility: "0",
  isEmailVerified: false,
};
const initialDirty = {
  legalEntityName: false,
  logo: false,
  address1: false,
  zip: false,
  city: false,
  state: false,
  country: false,
  primaryName: false,
  primaryTitle: false,
  primaryPhoneNumber: false,
  primaryEmail: false,
  secondaryName: false,
  secondaryTitle: false,
  secondaryPhoneNumber: false,
  secondaryEmail: false,
  authorizedName: false,
  authorizedTitle: false,
  estimatedOfGames: false,
  estimatedOfGamers: false,
  gameGrowth: false,
  gamersGrowth: false,
};

const PartnerSettings = ( ) => {
  const {
    profileInfo: { data },
    partner: { apiType, status, message },
    emailVerify: { emailData, apiType: emailApiType, status: emailStatus },
  } = useSelector( state => state );
  const dispatch = useDispatch();

  const setTab = ( tab, type ) => {
    if( type === 1 ) {
      handleChange( "settingTab", tab );
    }
    else {
      handleChange( "partnerDetailTab", tab );
    }
  };

  const { values, errors, isError, handleChange, showAllErrorMessage } = useForm(
    { ...initialState, ...{ countryList: [], stateList: [], cityList: [] } },
    initialDirty );

  useEffect( () => {
    Object.keys( initialState ).forEach( ( key ) => {
      if( data[ key ] ) {
        handleChange( key, data[ key ] );
      }
    } );

    const countries = getAllCountries( data.country || "US" );
    const states = getStatesOfCountry( data.country || "US" );

    handleChange( "stateList", states );
    handleChange( "countryList", countries );

    if( data.country ) {
      handleChange( "country", {
        label: countries.find( country => country.value === data.country ).label,
        value: data.country,
      } );
    }

    if( data.state ) {
      handleChange( "state", {
        label: states.find( state => state.value === data.state ).label,
        value: data.state,
      } );
    }

    if( data.city ) {
      handleChange( "city", {
        label: data.city,
        value: data.city,
      } );
    }
  }, [ data ] );

  const handleUploadImage = ( file ) => {
    const fileData = new FormData();

    fileData.append( "file", file );
    dispatch( uploadLogoAction( fileData ) );
  };

  const handleUploadLogo = async ( e ) => {
    const { files } = e.target;
    const file = files[ NUMERIC_VALUE.ZERO ];
    const { fileSize, size } = formatFileSize( file.size );

    if(
      ( ( fileSize <= ICON_FILE_MAX_SIZE.SIZE_IN_KB ) && ( size === FILE_SIZE_UNIT.KB ) ) ||
          size === FILE_SIZE_UNIT.BYTES
    ) {
      handleUploadImage( file );
    }
    else if( ( ( fileSize <= ICON_FILE_MAX_SIZE.SIZE_IN_MB ) && ( size === FILE_SIZE_UNIT.MB ) ) || ( size === FILE_SIZE_UNIT.KB ) ) {
      dispatch( startLoader() );

      const resizedFile = await resizeImageFile( file );

      handleUploadImage( resizedFile );
    }
    else {
      toastr.error( `Logo should not be bigger than ${ ICON_FILE_MAX_SIZE.SIZE_IN_MB } ${ FILE_SIZE_UNIT.MB }` );
    }
  };

  const clearImage = ( event ) => {
    const { target = {} } = event || {};

    target.value = "";
  };

  const handleInputChange = ( e ) => {
    const { name, value } = e.target;

    if(
      name === "primaryPhoneNumber" ||
      name === "secondaryPhoneNumber" ||
      name === "secondaryAlternativePhoneNumber" ||
      name === "primaryAlternativePhoneNumber"
    ) {
      handleChange( name, phoneFormat( value ) );
    }
    else {
      handleChange( name, value );
    }
  };

  useEffect( () => {
    const cities = getCitiesOfState(
      values.country.value || "US",
      values.state.value || "AL"
    );

    handleChange( "cityList", cities );
  }, [ values.state ] );

  const handleNext = () => {
    showAllErrorMessage();

    if( !isError ) {
      const request = {
        /* eslint-disable camelcase */
        // TODO: fix need to use camelcase
        dba: values.dba,
        business_location_add1: values.address1,
        business_location_add2: values.address2,
        business_location_city: values.city.value,
        business_location_state: getStatesOfCountry( values.country.value )?.find(
          state => state.value === values.state.value
        )?.label || "",
        business_location_zip_code: values.zip,
        business_location_country: getAllCountries( values.country.value )?.find(
          country => country.value === values.country.value
        )?.label || "",
        country_code: values.country.value,
        state_code: values.state.value,
        primary_name: values.primaryName,
        primary_title: values.primaryTitle,
        primary_email: values.primaryEmail,
        primary_phone: values.primaryPhoneNumber,
        primary_alternative_phone: values
          .primaryAlternativePhoneNumber,
        sec_name: values.secondaryName,
        sec_title: values.secondaryTitle,
        sec_email: values.secondaryEmail,
        sec_phone: values.secondaryPhoneNumber,
        sec_alternative_phone: values.secondaryAlternativePhoneNumber,
        icon_url: values.logo,
        /* eslint-enable camelcase */
      };

      dispatch( updatePartnerProfileAction( request ) );
    }
    else {
      toastr.error( "Please fill required fields on each step." );
    }
  };

  const handleOnBlur = ( e ) => {
    dispatch( checkEmailVerifyAction( { email: e.target.value, name: e.target.name } ) );
  };

  useEffect( () => {
    if(
      ( status === API_STATUS.SUCCESS ) &&
      ( apiType === API_TYPES.UPDATE_PARTNER_DETAIL )
    ) {
      toastr.success( message );
      dispatch( getPartnerDetailAction( data.partnerId ) );
    }

    if(
      ( emailStatus === API_STATUS.SUCCESS ) &&
      ( emailApiType === API_TYPE.EMAIL_VERIFY )
    ) {
      if( emailData?.sendex < 0.4 ) {
        handleChange( "isEmailVerified", true );
        handleChange( emailData?.fieldname, "" );
        toastr.error( "The added email address doesn't seem to be valid" );
      }
      else {
        handleChange( "isEmailVerified", false );
      }
    }
  }, [ status, apiType, message, emailApiType, emailStatus ] );

  const handleSelectInputChange = ( name, value ) => {
    if( name === "country" ) {
      const states = getStatesOfCountry( value.value || "US" );

      handleChange( "stateList", states );

      if( values.state ) {
        handleChange( "state", "" );
      }

      if( values.city ) {
        handleChange( "city", "" );
      }
    }
    else if( ( name === "state" ) && values.city ) {
      handleChange( "city", "" );
    }

    handleChange( name, value );
  };

  return (
    <div className="main-wrapper">

      <div className="main-right-wrapper">

        <div className="page-head-wrapper">
          <h4 className="page-heading">Settings</h4>
        </div>
        <div className="filter-tabs-wrapper">
          <div className="custom-tabs w-100">
            <span className={values.settingTab === SETTING_TAB.EDIT_PROFILE ? "active" : "" }onClick={() => setTab( SETTING_TAB.EDIT_PROFILE, 1 )} >Edit Profile</span>
            <span className={values.settingTab === SETTING_TAB.PASSWORD_RESET ? "active" : "" }onClick={() => setTab( SETTING_TAB.PASSWORD_RESET, 1 )} >Edit Account</span>
          </div>
        </div>

        <div className="page-inner-box profile-steps-box setting-box-partner">
          <div className={values.settingTab === SETTING_TAB.EDIT_PROFILE ? "active" : "d-none" }>
            <Nav variant="tabs" defaultActiveKey="#">
              <Nav.Item>
                <Nav.Link href="#"
                  onClick={() => setTab( TAB.GENERAL_INFO, 2 )} data-testid='set-general-info'>General Information</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="link-1"
                  onClick={() => setTab( TAB.CONTACT_INFO, 2 )}data-testid='set-contact-info'>Contact Information</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="link-2"
                  onClick={() => setTab( TAB.OTHER_INFO, 2 )}
                  data-testid='set-other-info'>Other Information</Nav.Link>
              </Nav.Item>
            </Nav>
            <Form>
              <div className={values.partnerDetailTab === TAB.GENERAL_INFO ? "active" : "d-none" }>
                <GeneralInformation
                  values={values}
                  handleUploadLogo={handleUploadLogo}
                  clearImage={clearImage}
                  errors = {errors}
                  handleInputChange={handleInputChange}
                  constant={constant}
                />
              </div>
              <div className={values.partnerDetailTab === TAB.CONTACT_INFO ? "active" : "d-none" }>
                <ContactInformation
                  values={values}
                  errors = {errors}
                  handleInputChange={handleInputChange}
                  onBlur={handleOnBlur}
                  handleSelectInput={handleSelectInputChange}
                />
              </div>
              <div className={values.partnerDetailTab === TAB.OTHER_INFO ? "active" : "d-none" }>
                <OtherInformation
                  values={values}
                  errors = {errors}
                  handleInputChange={handleInputChange}
                  constant={SOFTWARE_DEVELOPMENT_ABILITY}
                />
              </div>
              <Col sm="12" className="text-right px-0 fixed-footer-wrapper">
                <Button variant={`primary ${ values.isEmailVerified === true ? " div-disable" : "" }` }
                  onClick={() => handleNext()} data-testid='update-partner-profile'>Update</Button>
              </Col>
            </Form>
          </div>
          <div className={values.settingTab === SETTING_TAB.PASSWORD_RESET ? "active" : "d-none" }>
            <EmailReset email={data.email} roleType='partner'/>
            <PasswordReset/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerSettings;
