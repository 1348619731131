import HelpIcon from "@material-ui/icons/Help";
import PropTypes from "prop-types";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import constant from "../../../util/constant";
import Markdown from "../markDown";

const { BRANDING: { APP_SHORT_NAME } } = constant;

const GameInfo = ( {
  gamerId,
  gameDetails,
} ) => {
  return (
    <>
      <div className="image-with-content">
        <div className="image-section">
          <img src={gameDetails.gameIcon} alt="Game icon" />
        </div>
        <div className="content-section mb-0">
          <div className="content-top">
            <div className="content-title">Game Name</div>
            <div className="content-text">{gameDetails.gameName}</div>
          </div>
          {Boolean( gamerId ) && <div className="content-right game-content-right">
            <div className="content-title">
              { APP_SHORT_NAME } Game ID
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-disabled">
                    Game id to be used by your game server when
                    communicating with the { APP_SHORT_NAME } Server.
                  </Tooltip>
                }
              >
                <HelpIcon className="help-icon" />
              </OverlayTrigger>
            </div>
            <div className="content-text">{gameDetails.gameId}</div>
          </div>}
          <div className="content-bottom">
            <div className="content-title">Download URL</div>
            {gameDetails.downloadUrls?.map( url => (
              <a
                key={url}
                className="content-text url-link mb-0"
                href={url}
                target="_blank"
                rel="noreferrer"
              >
                {url}
              </a>
            ) )}
          </div>
        </div>
      </div>
      {Boolean( gameDetails?.apiUrls?.length ) && <div className="content-bottom mb-4">
        <div className="content-title">
              Game Server URLs{" "}
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="tooltip-disabled">
                    The urls that the { APP_SHORT_NAME } Server will use to contact the game
                    server for this game.
              </Tooltip>
            }
          >
            <HelpIcon className="help-icon" />
          </OverlayTrigger>
        </div>

        {gameDetails?.apiUrls?.map( url => (
          <span key={url} className="content-text">
            {url}
          </span>
        ) )}
      </div>}
      <div className="content-title">Description</div>
      <div className="content-text pb-3 inner-content">
        <Markdown text={gameDetails.description}></Markdown>
      </div>
    </>
  );
};

GameInfo.propTypes = {
  gamerId: PropTypes.string,
  gameDetails: PropTypes.object,
};

export default GameInfo;
