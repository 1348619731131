import GetAppIcon from "@material-ui/icons/GetApp";
import PropTypes from "prop-types";
import React from "react";
import { Form } from "react-bootstrap";
import { CSVLink } from "react-csv";
import constant from "../../../../util/constant";
import { ADMIN_GAMER_EXPORT_CONFIG } from "../../../../util/exportCSVConstant";
import { checkFilterApplied, getExportCSVData } from "../../../../util/helpers";
import FilterIcon from "../../../shared/FilterIcon/FilterIcon";
import ProfileImage from "../../../shared/profileImage";

const { FILTERS_NAME } = constant;
const { EXPORT_FILE_NAME, LIST_HEADERS, LIST_ENTITY_KEYS } = ADMIN_GAMER_EXPORT_CONFIG;

const TxnHistoryHeader = ( {
  gamerDetail,
  handleSearch,
  showFilter,
  setShowFilter,
  values,
  searchPlaceholder,
  transactionHitoryList,
} ) => {
  const isFilterApplied = checkFilterApplied( window.location.search, FILTERS_NAME.ADMIN_GAMER_TXN_HISTORY );
  const showFilterScreen = () => setShowFilter( !showFilter );

  return (
    <>
      <div className="page-head-wrapper">
        <div className='d-flex align-items-center'>
          <ProfileImage profileImage={gamerDetail?.profileImage} />
          <div className='profile-title mb-0'>{gamerDetail?.gamerFullName}</div>
        </div>
        <div className="page-head-right">
          <Form.Group className='custom-search w-300 mr-20 placeholder-ellipsis'>
            <span className='icon-search'></span>
            <Form.Control
              type='text'
              placeholder={searchPlaceholder}
              onChange={handleSearch}
              data-testid='search'
              value={values.searchKey}
              title={searchPlaceholder}
            />
          </Form.Group>
          <CSVLink
            data={getExportCSVData( transactionHitoryList, LIST_HEADERS, LIST_ENTITY_KEYS )}
            className='btn btn-primary small-btn'
            filename={EXPORT_FILE_NAME}
            target="_blank"
            data-testid='export-data'
          >
            <GetAppIcon />
            Export CSV
          </CSVLink>
          <FilterIcon
            showFilter={showFilter}
            showFilterScreen={showFilterScreen}
            isFilterApplied={isFilterApplied} />
        </div>
      </div>
    </>
  );
};

TxnHistoryHeader.propTypes = {
  gamerDetail: PropTypes.object,
  handleSearch: PropTypes.func,
  setShowFilter: PropTypes.func,
  values: PropTypes.object,
  showFilter: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  transactionHitoryList: PropTypes.array,
};

export default TxnHistoryHeader;
