
import PropTypes from "prop-types";
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import adminConstant from "../../../util/adminConstant";

const { MODAL_TYPE_DETAIL } = adminConstant;

const ModalFooter = ( {
  onClose,
  onConfirm,
  cancelText,
  confirmText,
  type,
} ) => (
  <Modal.Footer>
    {Boolean( cancelText ) && (
      <Button
        variant={type === MODAL_TYPE_DETAIL.CLONE_DRAFT.type ? "outline-secondary" : "outline-primary"}
        className='btn-md'
        onClick={onClose}
      >
        {cancelText}
      </Button>
    )}
    {Boolean( confirmText ) && (
      <Button variant='primary' className='btn-md' onClick={onConfirm}>
        {confirmText}
      </Button>
    )}
  </Modal.Footer>
);

ModalFooter.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  type: PropTypes.string,
};

export default ModalFooter;
