import PropTypes from "prop-types";
import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { uploadIcon, logoColor } from "../../../util/icons";

const GeneralInformation = ( { values, handleUploadLogo, clearImage, errors, handleInputChange, constant } ) => {
  return (
    <>
      <div className='page-inner-box profile-steps-box'>
        <Form>
          <div className='add-logo-wrapper'>
            <strong>Add Logo</strong>
            <div className='add-logo-wrapper-inner'>
              <div className='logo-box'>
                <img
                  src={values?.logo ? values?.logo : logoColor}
                  alt='logo'
                />
              </div>
              <div className='custom-image-upload'>
                <Form.Control
                  accept=".svg,.jpg,.jpeg,.png"
                  type='file'
                  id='logoUpload'
                  name='logo'
                  onChange={handleUploadLogo}
                  onClick={clearImage}
                />
                <label htmlFor='logoUpload'>
                  <img src={uploadIcon} alt='upload-icon' />
                      Upload
                </label>
              </div>
            </div>
            <div className='invalid-feedback d-block'>{errors?.logo}</div>
          </div>
          <div className='profile-form'>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Legal Entity Name</Form.Label>
                <Form.Control
                  type='text'
                  value={values?.legalEntityName}
                  name='legalEntityName'
                  onChange={handleInputChange}
                  maxLength={45}
                  data-testid='legalEntityName'
                  disabled
                />
                <div className='invalid-feedback d-block'>
                  {errors?.legalEntityName}
                </div>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Select Category(s)</Form.Label>
                <Form.Control
                  as='select'
                  disabled
                  name='categoryId'
                  value={values?.categoryId}
                  onChange={handleInputChange}
                >
                  {constant?.CATEGORY.map( category => (
                    <option key={category.id} value={category.id}>{category.label}</option>
                  ) )}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group className='last-form-group'>
                <Form.Label>Reason to join { constant.BRANDING.APP_SHORT_NAME }</Form.Label>
                <Form.Control
                  disabled
                  as='select'
                  name='joinReasonId'
                  value={values?.joinReasonId}
                  onChange={handleInputChange}
                >
                  {constant?.REASON_QX_JOIN.map( item => (
                    <option key={item.id} value={item.id}>{item.label}</option>
                  ) )}
                </Form.Control>
              </Form.Group>
            </Col>
          </div>
        </Form>
      </div>
    </>
  );
};

GeneralInformation.propTypes = {
  values: PropTypes.object,
  handleUploadLogo: PropTypes.func,
  clearImage: PropTypes.func,
  errors: PropTypes.object,
  handleInputChange: PropTypes.func,
  constant: PropTypes.array,
};

export default GeneralInformation;
