const modalClassNames = {
  DELETE_MODAL: "delete-modal",
  MODIFICATION_MODAL: "modification-modal",
  SIGNOUT_MODAL: "signout-modal",
  LIST_MODAL: "gamer-cashback-modal",
};
const adminConstant = {
  PARTNER_TAB: {
    APPROVED: "3",
    REQUEST: [ "0", "1", "2" ],
    REJECTED: "4",
    DISABLED: "5",
  },
  APPLICATION_STATUS: [
    "Pending",
    "Amendments Suggested",
    "Docusign Pending",
    "Approved",
    "Rejected",
    "Disabled",
  ],
  PARTNER_LIST_SORT: {
    LEGAL_ENTITY_NAME: "legal_entity_name",
    EMAIL: "email",
    PRIMARY_PHONE: "primary_phone",
    PRIMARY_NAME: "primary_name",
    APPLICATION_STATUS: "application_status",
    STATUS: "is_active",
  },
  ORDER_BY: {
    ASC: "ASC",
    DESC: "DESC",
  },
  REASON_QX_JOIN: [
    "Track and exchange in-game issued rewards (assets)",
    "Use the QX portal to define and issue offers, with associated rewards, based upon in-game events or actions",
    "Both",
  ],

  MODAL_TYPE_DETAIL: {
    DISABLE_GAME: {
      headerText: "Disable Game?",
      cancelText: "Cancel",
      confirmText: "Confirm",
      type: "DISABLE_GAME",
      className: "delete-modal",
    },
    DRAFT_SAVE_ALERT: {
      headerText: "Are you sure you want to save the progress as draft?",
      cancelText: "Cancel",
      confirmText: "Confirm",
      type: "DRAFT_SAVE_ALERT",
      className: modalClassNames.DELETE_MODAL,
    },
    REASON_REJECTION: {
      headerText: "Reason for Rejection",
      cancelText: "Cancel",
      confirmText: "Confirm",
      type: "REASON_REJECTION",
      className: modalClassNames.MODIFICATION_MODAL,
    },
    DOCUSIGN: {
      headerText: "DocuSign Envelope ID",
      cancelText: "Cancel",
      confirmText: "Submit",
      type: "DOCUSIGN",
      className: modalClassNames.MODIFICATION_MODAL,
    },
    ACTIVE_GAME: {
      headerText: "Deactivate Game?",
      cancelText: "Cancel",
      confirmText: "Confirm",
      type: "ACTIVE_GAME",
      className: modalClassNames.DELETE_MODAL,
    },
    INACTIVE_GAME: {
      headerText: "Activate Game?",
      cancelText: "Cancel",
      confirmText: "Confirm",
      type: "INACTIVE_GAME",
      className: modalClassNames.DELETE_MODAL,
    },
    ACTIVE_EVENT: {
      headerText: "Deactivate Event?",
      cancelText: "Cancel",
      confirmText: "Confirm",
      type: "ACTIVE_EVENT",
      className: modalClassNames.DELETE_MODAL,
    },
    INACTIVE_EVENT: {
      headerText: "Activate Event?",
      cancelText: "Cancel",
      confirmText: "Confirm",
      type: "INACTIVE_EVENT",
      className: modalClassNames.DELETE_MODAL,
    },
    ACTIVE_OFFER: {
      headerText: "Activate Offer?",
      cancelText: "Cancel",
      confirmText: "Confirm",
      type: "ACTIVE_OFFER",
      className: modalClassNames.DELETE_MODAL,
    },
    INACTIVE_OFFER: {
      headerText: "Deactivate Offer?",
      cancelText: "Cancel",
      confirmText: "Confirm",
      type: "INACTIVE_OFFER",
      className: modalClassNames.DELETE_MODAL,
    },
    ACTIVE_PARTNER: {
      headerText: "Deactivate Partner?",
      cancelText: "Cancel",
      confirmText: "Confirm",
      type: "ACTIVE_PARTNER",
      className: modalClassNames.DELETE_MODAL,
    },
    INACTIVE_PARTNER: {
      headerText: "Activate Partner?",
      cancelText: "Cancel",
      confirmText: "Confirm",
      type: "INACTIVE_PARTNER",
      className: modalClassNames.DELETE_MODAL,
    },
    APPROVE_PARTNER_MANUALLY: {
      headerText: "Approve Manually?",
      cancelText: "Cancel",
      confirmText: "Confirm",
      type: "APPROVE_PARTNER_MANUALLY",
      className: modalClassNames.DELETE_MODAL,
    },
    SUGGEST_MODIFICATION_GAME: {
      headerText: "Suggest Modification",
      cancelText: "Cancel",
      confirmText: "Send",
      type: "SUGGEST_MODIFICATION_GAME",
      className: modalClassNames.MODIFICATION_MODAL,
    },
    APPROVE_GAME: {
      headerText: "Approve Game?",
      cancelText: "Cancel",
      confirmText: "Confirm",
      type: "APPROVE_GAME",
      className: modalClassNames.DELETE_MODAL,
    },
    REJECT_GAME: {
      headerText: "Reject Game?",
      cancelText: "Cancel",
      confirmText: "Confirm",
      type: "REJECT_GAME",
      className: modalClassNames.DELETE_MODAL,
    },
    APPROVE_OFFER: {
      headerText: "Approve Offer?",
      cancelText: "Cancel",
      confirmText: "Confirm",
      type: "APPROVE_OFFER",
      className: modalClassNames.DELETE_MODAL,
    },
    APPROVE_TOURNAMENT: {
      headerText: "Approve Tournament?",
      cancelText: "Cancel",
      confirmText: "Confirm",
      type: "APPROVE_TOURNAMENT",
      className: modalClassNames.DELETE_MODAL,
    },
    ENABLED_ADMIN: {
      headerText: "Disable Admin?",
      cancelText: "Cancel",
      confirmText: "Confirm",
      type: "ENABLED_ADMIN",
      className: modalClassNames.DELETE_MODAL,
    },
    DISABLED_ADMIN: {
      headerText: "Enable Admin?",
      cancelText: "Cancel",
      confirmText: "Confirm",
      type: "DISABLED_ADMIN",
      className: modalClassNames.DELETE_MODAL,
    },
    REMOVE_OFFER: {
      headerText: "Remove an Offer Template",
      cancelText: "Cancel",
      confirmText: "Confirm",
      type: "REMOVE_OFFER",
      className: modalClassNames.DELETE_MODAL,
    },
    REMOVE_DRAFT: {
      headerText: "Remove a Draft",
      cancelText: "Cancel",
      confirmText: "Confirm",
      type: "REMOVE_DRAFT",
      className: modalClassNames.DELETE_MODAL,
    },
    ERROR_LIST: {
      headerText: "Errors",
      cancelText: "",
      confirmText: "Close",
      type: "ERROR_LIST",
      className: modalClassNames.DELETE_MODAL,
    },
    ACCEPT_REDEEM_REQUEST: {
      headerText: "Accept",
      cancelText: "Cancel",
      confirmText: "Confirm",
      type: "ACCEPT_REDEEM_REQUEST",
      className: modalClassNames.DELETE_MODAL,
    },
    CLONE_DRAFT: {
      headerText: "Clone a Draft:",
      cancelText: "Cancel",
      confirmText: "Create",
      type: "CLONE_DRAFT",
      className: modalClassNames.SIGNOUT_MODAL,
    },
    CLONE_TEMPLATE: {
      headerText: "Clone a Template:",
      cancelText: "Cancel",
      confirmText: "Create",
      type: "CLONE_TEMPLATE",
      className: modalClassNames.SIGNOUT_MODAL,
    },
    SHOW_ALL_REWARD: {
      headerText: "Rewards",
      cancelText: "",
      confirmText: "Close",
      type: "SHOW_ALL_REWARD",
      className: modalClassNames.LIST_MODAL,
    },
    REMOVE_TOURNAMENT: {
      headerText: "Remove a Tournament Template",
      cancelText: "Cancel",
      confirmText: "Confirm",
      type: "REMOVE_TOURNAMENT",
      className: modalClassNames.DELETE_MODAL,
    },
    ACTIVE_TOURNAMENT: {
      headerText: "Activate Tournament?",
      cancelText: "Cancel",
      confirmText: "Confirm",
      type: "ACTIVE_TOURNAMENT",
      className: modalClassNames.DELETE_MODAL,
    },
    INACTIVE_TOURNAMENT: {
      headerText: "Deactivate Tournament?",
      cancelText: "Cancel",
      confirmText: "Confirm",
      type: "INACTIVE_TOURNAMENT",
      className: modalClassNames.DELETE_MODAL,
    },
  },
  MODAL_TYPE: {
    REASON_REJECTION: "REASON_REJECTION",
  },
  ADMIN_API_TYPES: {
    REJECT_PARTNER_REQUEST: "REJECT_PARTNER_REQUEST",
    AMMENDMENTS_SUGGESTED: "AMMENDMENTS_SUGGESTED",
    CONFIRM_FOR_DOCUSIGN: "CONFIRM_FOR_DOCUSIGN",
    RESOLVE_AMENDMENT: "RESOLVE_AMENDMENT",
    UPDATE_PARTNER_STATUS: "UPDATE_PARTNER_STATUS",
    APPROVE_PARTNER_MANUALLY: "APPROVE_PARTNER_MANUALLY",
    GET_PARTNER_LIST: "GET_PARTNER_LIST",
    GET_PARTNER_FILTER_LIST: "GET_PARTNER_FILTER_LIST",
    GET_ADMIN_FILTER_LIST: "GET_ADMIN_FILTER_LIST",
    GET_LINKED_GAMERS_LIST: "GET_LINKED_GAMERS_LIST",
    ADMIN_LINKED_GAMERS_FILTER_LIST: "ADMIN_LINKED_GAMERS_FILTER_LIST",
  },
  SECTION_NAME_DATA: {
    GENERAL: { key: "1", value: "General Information" },
    CONTACT: { key: "2", value: "Contact Information" },
    OTHER: { key: "3", value: "Other Information" },
  },
  PARTNER_FILTER_LIST: [
    { label: "Pending", value: 0 },
    { label: "Amendments Suggested", value: 1 },
    { label: "Docusign Pending", value: 2 },
    { label: "Approved", value: 3 },
    { label: "Rejected", value: 4 },
    { label: "Disabled", value: 5 },
  ],
  QX_ADMIN_ID: "00000000-0000-0000-0000-000000000000",
};


export default adminConstant;
