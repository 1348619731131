import PropTypes from "prop-types";
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import constant from "../../../util/constant";

const { ORDER_BY } = constant;

const SortDropdown = ( {
  values,
  sortColumns,
  toggleClass,
  isGamersTournaments,
} ) => {
  return (
    <>
      {Boolean( values.viewType ) && (
        <Dropdown className={`custom-dropdown sort-dropdown ${ isGamersTournaments ? "tm-dropdown-menu" : "" }`}>
          <Dropdown.Toggle variant='success' id='dropdown-basic'>
            <span className={values.sortOrder === ORDER_BY.ASC ? "icon-sort-asc" : "icon-sort"}></span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <span className='dropdown-heading'>Sort By</span>
            {sortColumns.map( data => (
              <Dropdown.Item key={data}
                href='#'
                className={data.status === true ? "active" : ""}
                onClick={() => toggleClass( data.col, data.order )}
              >
                <span>{data.value}</span>
              </Dropdown.Item>
            ) )}
          </Dropdown.Menu>
        </Dropdown>
      ) }
    </>
  );
};

SortDropdown.propTypes = {
  values: PropTypes.object,
  sortColumns: PropTypes.array,
  toggleClass: PropTypes.func,
  isGamersTournaments: PropTypes.bool,
};

export default SortDropdown;
