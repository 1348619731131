import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import HelpIcon from "@material-ui/icons/Help";
import { EditorState } from "draft-js";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useSelector, useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { DraftOfferRequest } from "../../../entities/draftOfferRequest";
import { OfferTemplateRequest } from "../../../entities/newOfferTemplateRequest";
import { PartnerOfferFromScratchRequest } from "../../../entities/partnerOfferFromScratchRequest";
import { useForm, useModal, useInputChange } from "../../../hooks";
import { getPartnerEventDetailAction } from "../../../store/actions/partnerEvent";
import { getPartnerLookupAction, getOfferListAction } from "../../../store/actions/partnerLookup";
import {
  addNewOfferTemplateAction,
  getPartnerOfferTemplateDetailAction,
  addOfferTemplateFromScratchAction,
  updatePartnerOfferTemplateAction,
  clearOfferDetail,
  getPartnerOfferAction
} from "../../../store/actions/partnerOffer";
import { addNewPartnerOfferDraftAction, getPartnerOfferDraftDetailAction, updatePartnerOfferDraftAction } from "../../../store/actions/partnerOfferDraft";
import Enums from "../../../util/Enums";
import adminConstant from "../../../util/adminConstant";
import constant from "../../../util/constant";
import eventConstant from "../../../util/eventConstant";
import {
  getOfferHeaderData,
  doAchievementsHaveNoError,
  randomString,
  getUniqueName,
  getBackNavigationRoute
} from "../../../util/helpers";
import offerConstant from "../../../util/offerConstant";
import { validateAchievements } from "../../../util/validateMultipleFields";
import BackButton from "../../shared/backButton";
import { OfferTemplate, ScheduleOffer, ChooseTemplate } from "../../shared/offers";
import ModalPopup from "../modal";

const {
  API_STATUS, ROUTES, TIME_INTERVAL_FOR_AUTO_SAVE, ADVERTISE_OPTIONS, API_TYPES, OPT_IN_STATUS,
} = constant;
const { EVENT_API_TYPES } = eventConstant;
const {
  OFFER_API_TYPES,
  OFFER_TYPE_STATUS,
  DRAFT_ENTITY_NAME,
  TYPE_TO_BE_EDITED,
  REQUEST_TYPE,
} = offerConstant;
const {
  MODAL_TYPE_DETAIL,
} = adminConstant;
const { PER_PAGE_RECORDS_LIMIT } = Enums;

const initialState = {
  description: EditorState.createEmpty(),
  descriptionText: "",
  offerName: "",
  qxPoint: 0,
  offerCanBeAchievedCount: "",
  startDate: false,
  endDate: false,
  relationExpression: "",
  rawDescription: "",
  achievementErrors: [],
  offerTemplate: "",
  game: "",
  gamers: "",
  isEditOffer: false,
  isEditDraft: false,
  isOffer: false,
  isAutoSave: true,
  saveToDraft: false,
  advertiseToGamer: ADVERTISE_OPTIONS[ 0 ],
  noOfDays: 0,
  advertiseDate: false,
  optInType: OPT_IN_STATUS.NO,
  membershipId: "",
  entryFee: "",
  minParticipants: "",
  maxParticipants: "",
  minParticipantsNoLimit: true,
  maxParticipantsNoLimit: true,
};
const initialDirty = {
  descriptionText: false,
  offerName: false,
};
const templateInitialDirty = {
  descriptionText: false,
  offerName: false,
  qxPoint: false,
  startDate: false,
  endDate: false,
  game: false,
  gamers: false,
  noOfDays: true,
  advertiseDate: true,
  offerCanBeAchievedCount: true,
  minParticipants: true,
  maxParticipants: true,
  entryFee: true,
};

let saveToDraft;

const CreateAndEditOffer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { handleInputChanges } = useInputChange();
  const {
    apiType,
    status,
    eventApiType,
    eventStatus,
    lookup,
    partnerEventDetail,
    offerDetail,
    draftApiType,
    draftStatus,
    lookupStatus,
    configuration,
    offerNames,
    offerNamesApiType,
    offerNamesStatus,
    lookupApiType,
    membershipTypes,
    offers,
    partnerId } = useSelector( state => ( {
    apiType: state.partnerOffer.apiType,
    status: state.partnerOffer.status,
    offerDetail: state.partnerOffer.offerDetail,
    eventApiType: state.partnerEvent.apiType,
    eventStatus: state.partnerEvent.status,
    partnerEventDetail: state.partnerEvent.partnerEventDetail,
    lookup: state.partnerLookup.lookup,
    draftApiType: state.partnerOfferDraft.apiType,
    draftStatus: state.partnerOfferDraft.status,
    lookupStatus: state.partnerLookup.status,
    lookupApiType: state.partnerLookup.apiType,
    configuration: state.partner.partnerDetail?.configuration || {},
    offerNames: state.partnerOfferNames.offerNames,
    offerNamesStatus: state.partnerOfferNames.status,
    offerNamesApiType: state.partnerOfferNames.apiType,
    membershipTypes: state.partner.partnerDetail?.membershipTypes || [],
    offers: state.partnerOffer.offers,
    partnerId: state.partner.partnerDetail.partnerId,
  } ) );
  const [ errorMessage, setErrorMessage ] = useState( "" );
  const [ isTimeInterval, setIsTimeInterval ] = useState( false );
  const { offerId: urlParamString } = useParams();
  const location = useLocation();
  const backScreens = [ "partnerOffers", "partnerOfferDetail" ];
  const { 0: offerId } = String( urlParamString ).split( "?" );
  const paramsQuery = new URLSearchParams( window.location.search );
  const offerType = paramsQuery.get( "status" );
  const isClone = paramsQuery.get( "clone" );
  const statusType = paramsQuery.get( "statusType" );
  const isOfferFromTemplate = paramsQuery.get( "fromTemplate" );
  const fromScreen = paramsQuery.get( "from" );
  const { isOpened, closeModal, openModal, modalType, setModalType } = useModal();
  const { values, errors, isError, handleChange, showAllErrorMessage, changeDirtyObj } =
    useForm(
      { ...initialState,
        achievements: [ {
          name: "",
          description: EditorState.createEmpty(),
          descriptionText: "",
          rawDescription: "",
          event: "",
          function: "",
          comparison: "",
          value: "",
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
          eventDescription: "",
          eventUnit: "",
        } ],
      },
      offerType === OFFER_TYPE_STATUS.OFFER_TEMPLATE ? initialDirty : templateInitialDirty
    );
  const headerDetail = getOfferHeaderData( statusType ?? offerType, offerDetail?.entityName, isClone );

  const handleInputChange = ( e ) => {
    handleInputChanges( e, handleChange );
  };

  const handleScheduleInputChange = ( e ) => {
    const { name, value } = e.target;

    handleChange( name, value );
  };

  const handleDateChange = ( name, e ) => {
    if( e ) {
      handleChange( [ name ], new Date( e ) );
    }
    else {
      handleChange( [ name ], false );

      if( name === "startDate" ) {
        handleChange( "advertiseDate", false );
        handleChange( "noOfDays", 0 );
      }
    }
  };

  const handleAdvertiseDate = ( e ) => {
    if( e ) {
      handleChange( "advertiseDate", new Date( e ) );
    }
    else {
      handleChange( "advertiseDate", false );
    }
  };

  const onSelectedEvent = eventId => dispatch( getPartnerEventDetailAction( eventId ) );

  const handleSelectInput = ( name, value = "" ) => {
    handleChange( name, value );

    if( name === "advertiseToGamer" ) {
      handleChange( "noOfDays", 0 );
      handleChange( "advertiseDate", false );
    }

    if( name === "offerTemplate" ) {
      if( value?.value ) {
        handleChange( "saveToDraft", false );
        handleChange( "offerName", "" );
        dispatch( getPartnerOfferTemplateDetailAction( value.value ) );
      }
      else {
        handleChange( "achievements", [ {
          name: "",
          description: EditorState.createEmpty(),
          descriptionText: "",
          rawDescription: "",
          event: "",
          function: "",
          comparison: "",
          value: "",
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
          eventDescription: "",
          eventUnit: "",
        } ] );
        handleChange( "description", EditorState.createEmpty() );
        handleChange( "descriptionText", "" );
        handleChange( "rawDescription", "" );
        handleChange( "qxPoint", 0 );
      }
    }
  };

  useEffect( () => {
    if( values.membershipId === "" ) {
      handleChange( "membershipId", membershipTypes[ 0 ].id );
    }
  }, [] );

  useEffect( () => {
    if( offerId === "0" ) {
      dispatch( clearOfferDetail() );
    }

    dispatch( getOfferListAction( { entities: "Offer" } ) );

    return () => dispatch( clearOfferDetail() );
  }, [] );

  useEffect( () => {
    dispatch( getPartnerOfferAction( {
      perPage: PER_PAGE_RECORDS_LIMIT.MAX,
      partnerId: partnerId,
      currentTimeZone: new Date().toUTCString(),
      offerType: "offer",
    } ) );
  }, [] );

  useEffect( () => {
    if( ( lookupStatus === API_STATUS.SUCCESS ) && ( lookupApiType === API_TYPES.GET_PARTNER_LOOKUP ) ) {
      if( offerType === OFFER_TYPE_STATUS.DRAFT ) {
        handleChange( "isEditDraft", true );
        dispatch( getPartnerOfferDraftDetailAction( offerId ) );
      }
      else if( offerId !== "0" ) {
        dispatch( getPartnerOfferTemplateDetailAction( offerId ) );

        if( !isOfferFromTemplate ) {
          handleChange( "isEditOffer", true );
        }

        if( ( !isClone ) && ( !isOfferFromTemplate ) ) {
          changeDirtyObj( initialDirty );
        }
      }
    }
  }, [ lookupStatus ] );

  useEffect( () => {
    if( ( offerNamesStatus === API_STATUS.SUCCESS ) && ( offerNamesApiType === API_TYPES.GET_OFFER_LIST ) ) {
      dispatch( getPartnerLookupAction( { entities: "TargetAudience,OfferTemplate,ApprovedGame,EnabledEvent" } ) );

      if( offerType === OFFER_TYPE_STATUS.OFFER_FROM_TEMPLATE || offerType === OFFER_TYPE_STATUS.OFFER_FROM_SCRATCH ) {
        handleChange( "isOffer", true );
      }
    }
  }, [ offerNamesStatus ] );

  const handleDraft = ( isLoader ) => {
    const offerNameForBlank = `Draft -${ randomString( 7 ) }`;

    if( !values.offerName ) {
      const templateName = values.offerTemplate?.label || "My Offer";
      const defaultOfferName = getUniqueName( templateName, offerNames );

      values.offerName = defaultOfferName;
      handleChange( "offerName", defaultOfferName );
    }

    if( !values.descriptionText ) {
      values.descriptionText = offerNameForBlank;
      values.rawDescription = offerNameForBlank;
    }

    if( isOfferFromTemplate && !values?.offerTemplate ) {
      const offerTemplate = getSelectedOfferTemplate();

      values.offerTemplate = offerTemplate;
      handleChange( "offerTemplate", offerTemplate );
    }

    if( !values.isEditDraft && !offerDetail.entityId ) {
      if( offerType === OFFER_TYPE_STATUS.OFFER_TEMPLATE ) {
        const requestObj = new DraftOfferRequest( values );

        dispatch( addNewPartnerOfferDraftAction( requestObj, DRAFT_ENTITY_NAME.OFFER_TEMPLATE_DRAFT, isLoader ) );
      }
      else if( ( offerType === OFFER_TYPE_STATUS.OFFER_FROM_SCRATCH ) || ( offerType === OFFER_TYPE_STATUS.OFFER_FROM_TEMPLATE ) || isClone ) {
        const draftRequest = isClone ? { ...values, cloneOfferName: offerDetail.offerName, cloneOfferId: offerDetail.offerId } : values;
        const requestObj = new DraftOfferRequest( draftRequest );

        dispatch( addNewPartnerOfferDraftAction( requestObj, DRAFT_ENTITY_NAME.PARTNER_OFFER_DRAFT, isLoader ) );
      }
    }
    else {
      const id = ( ( offerId === "0" || isClone ) && offerDetail.entityId ) ? offerDetail.entityId : offerId;
      const draftRequest = isClone ? { ...values, cloneOfferName: offerDetail.cloneOfferName, cloneOfferId: offerDetail.cloneOfferId } : values;
      const requestObj = new DraftOfferRequest( { ...draftRequest, offerId: id } );

      dispatch( updatePartnerOfferDraftAction( requestObj, id, isLoader ) );
    }
  };

  useEffect( () => {
    if( values.saveToDraft ) {
      handleDraft();
    }
  }, [ values.saveToDraft ] );

  const getSelectedOfferTemplate = () => {
    const { OfferTemplate: offerTemplates = [] } = lookup;
    const key = offerDetail?.offerName || offerDetail.name;

    return offerTemplates.find( offer => offer.label === key );
  };

  useEffect( () => {
    if( isOfferFromTemplate && !values.offerTemplate ) {
      const offerTemplate = getSelectedOfferTemplate();

      if( ( offerDetail?.offerName || offerDetail.name ) && offerTemplate ) {
        const templateName = offerTemplate?.label || "My Offer";
        const defaultOfferName = getUniqueName( templateName, offerNames );

        handleChange( "offerName", defaultOfferName );
      }

      handleChange( "offerTemplate", offerTemplate );
    }
  }, [ lookupStatus, lookupApiType, status, apiType ] );

  // TODO: refactor to avoid complexity
  // eslint-disable-next-line complexity
  useEffect( () => {
    if( ( status === API_STATUS.SUCCESS ) && ( apiType === OFFER_API_TYPES.ADD_PARTNER_NEW_OFFER_TEMPLATE || apiType === OFFER_API_TYPES.UPDATE_PARTNER_OFFER_TEMPLATE ) ) {
      toastr.success( values.isEditOffer ? "Offer template updated successfully" : "Offer template added successfully" );
      history.goBack();
    }
    else if( status === API_STATUS.SUCCESS && apiType === OFFER_API_TYPES.ADD_PARTNER_OFFER_FROM_SCRATCH ) {
      if( isClone ) {
        toastr.success( "Offer cloned successfully" );
        history.goBack();
      }
      else {
        toastr.success( "Offer added successfully" );
        history.goBack();
      }
    }
    else if( ( draftStatus === API_STATUS.SUCCESS ) &&
    ( draftApiType === OFFER_API_TYPES.ADD_ADMIN_NEW_DRAFT_OFFER || draftApiType === OFFER_API_TYPES.UPDATE_PARTNER_OFFER_DRAFT_TEMPLATE ) ) {
      if( !isTimeInterval ) {
        handleChange( "description", offerDetail.description );
        handleChange( "descriptionText", offerDetail.descriptionText );
        handleChange( "rawDescription", offerDetail.rawDescription );
      }

      if( !values.isAutoSave ) {
        const draftEntityName = offerDetail.entityName !== DRAFT_ENTITY_NAME.PARTNER_OFFER_DRAFT ? " template" : "";

        toastr.success( values.isEditDraft ? `Offer${ draftEntityName } draft updated successfully` : `Offer${ draftEntityName } draft added successfully` );
        history.goBack();
      }
      else if( ( offerId === "0" || isClone || isOfferFromTemplate ) && !values.isEditDraft ) {
        let urlParams = isClone ?
          `?status=${ OFFER_TYPE_STATUS.DRAFT }&clone=true` :
          `?status=${ OFFER_TYPE_STATUS.DRAFT }&statusType=${ offerType }`;

        urlParams = isOfferFromTemplate ? `${ urlParams }&fromTemplate=true` : urlParams;
        urlParams = fromScreen === "partnerOffers" ? `${ urlParams }&from=partnerOffers` : urlParams;
        history.replace( {
          pathname: `${ ROUTES.PARTNER_CREATE_AND_EDIT_OFFER.replace( ":offerId", offerDetail.entityId ) }${ urlParams }`,
          state: location.state,
        } );
        handleChange( "isEditDraft", true );
      }
    }
    else if( ( ( status === API_STATUS.SUCCESS ) && ( apiType === OFFER_API_TYPES.GET_PARTNER_OFFER_TEMPLATE_DETAIL ) )
          || ( ( draftStatus === API_STATUS.SUCCESS ) && ( draftApiType === OFFER_API_TYPES.GET_PARTNER_DRAFT_OFFER_TEMPLATE_DETAIL ) ) ) {
      handleChange( "achievements", offerDetail.achievements );
      handleChange( "optInType", offerDetail.optIn.optInType );
      handleChange( "membershipId", offerDetail.optIn.membershipId );
      handleChange( "entryFee", offerDetail.optIn.entryFee );
      handleChange( "minParticipants", offerDetail.optIn.minParticipants );
      handleChange( "maxParticipants", offerDetail.optIn.maxParticipants );
      handleChange( "minParticipantsNoLimit", offerDetail.optIn.minParticipantsNoLimit );
      handleChange( "maxParticipantsNoLimit", offerDetail.optIn.maxParticipantsNoLimit );

      if( !isTimeInterval ) {
        handleChange( "description", offerDetail.description );
        handleChange( "descriptionText", offerDetail.descriptionText );
        handleChange( "rawDescription", offerDetail.rawDescription );
      }

      handleChange( "qxPoint", offerDetail.qxPoint );
      handleChange( "achievementErrors", validateAchievements( offerDetail.achievements, values.achievementErrors ) );

      if( offerDetail?.offerTemplate && values.isEditDraft ) {
        handleChange( "offerTemplate", offerDetail.offerTemplate );
        handleChange( "isOffer", true );
      }

      if( values.offerTemplate || offerDetail.isOffer || offerDetail.offerType === TYPE_TO_BE_EDITED.OFFER ) {
        handleChange( "isOffer", true );
      }

      if( values.isEditOffer || values.isEditDraft || isOfferFromTemplate ) {
        if( !isClone && !values.offerName && !isOfferFromTemplate ) {
          handleChange( "offerName", offerDetail.offerName );
        }

        if( isOfferFromTemplate && values.isEditDraft ) {
          handleChange( "offerName", offerDetail.offerName );
        }

        if( isClone && values.isEditDraft ) {
          handleChange( "offerName", offerDetail.offerName );
        }

        if( isClone && ( !values.offerName ) && ( !values.isEditDraft ) ) {
          const existingOfferNames = offers?.map( item => item.offerName ) || [];
          const cloneOfferName = getUniqueName( `Clone - ${ offerDetail.offerName }`, existingOfferNames );

          handleChange( "offerName", cloneOfferName );
        }

        if( ( !values.offerName ) && values.offerTemplate ) {
          const templateOfferName = getUniqueName( values.offerTemplate?.label, offerNames );

          handleChange( "offerName", templateOfferName );
        }
      }

      if( offerDetail.isAvailableOfferSchedule ) {
        handleChange( "game", offerDetail.offerSchedule.selectedGame );
        handleChange( "gamers", offerDetail.offerSchedule.selectedAudience );
        handleChange( "offerCanBeAchievedCount", offerDetail.offerSchedule.maxRewardCount );
        handleChange( "startDate", offerDetail.offerSchedule.startDateForValue );
        handleChange( "endDate", offerDetail.offerSchedule.endDateForValue );
        handleChange( "advertiseToGamer", offerDetail.advertiseToGamer );
        handleChange( "noOfDays", offerDetail.noOfDays );
        handleChange( "advertiseDate", offerDetail.advertiseDate );
      }

      if( ( ( values.isEditDraft || offerId === "0" ) && !isClone ) || ( isClone && !values.isEditDraft ) || isOfferFromTemplate ) {
        handleChange( "saveToDraft", true );
      }

      if( ( !offerDetail?.offerTemplate ) && ( !values.offerTemplate ) && ( !offerDetail.isOffer ) && ( !isClone ) && ( !isOfferFromTemplate ) ) {
        changeDirtyObj( initialDirty );
      }
    }
    else if( eventStatus === API_STATUS.SUCCESS && eventApiType === EVENT_API_TYPES.GET_PARTNER_EVENT_DETAIL ) {
      const { achievements } = values;
      const updatedAchievement = achievements.map( ( achievement ) => {
        if( achievement.event.value === partnerEventDetail.eventId ) {
          return { ...achievement, eventDescription: partnerEventDetail.description, eventUnit: partnerEventDetail.unitName };
        }

        return achievement;
      } );

      handleChange( "achievements", updatedAchievement );
    }
  }, [ eventApiType, eventStatus, status, apiType, draftApiType, draftStatus ] );

  const handleSubmit = ( params ) => {
    if( params === "draft" ) {
      handleOpenModal( MODAL_TYPE_DETAIL.DRAFT_SAVE_ALERT.type );
    }
    else {
      showAllErrorMessage();
      handleChange( "achievementErrors", validateAchievements( values.achievements, values.achievementErrors ) );

      const noErrorInAchievements = doAchievementsHaveNoError( values.achievements, values.achievementErrors );

      if( !isError && noErrorInAchievements ) {
        const { partnerCostUsdPerQxPoint } = configuration;

        if( ( offerType === OFFER_TYPE_STATUS.OFFER_TEMPLATE || !values.isOffer ) && !values.isEditOffer ) {
          let requestObj = new OfferTemplateRequest( { ...values, partnerCostUsdPerQxPoint, requestType: REQUEST_TYPE.OFFER_TEMPLATE } );

          requestObj = offerId && offerId !== "0" ? { ...requestObj, offerId: offerId } : requestObj;
          dispatch( addNewOfferTemplateAction( requestObj ) );
        }
        else if( offerType === OFFER_TYPE_STATUS.OFFER_FROM_SCRATCH || offerType === OFFER_TYPE_STATUS.OFFER_FROM_TEMPLATE || values.isOffer ) {
          let requestObj = new PartnerOfferFromScratchRequest( { ...values, partnerCostUsdPerQxPoint, requestType: REQUEST_TYPE.OFFER } );

          requestObj = offerId && offerId !== "0" ? { ...requestObj, offerId: offerId } : requestObj;

          if( isClone && !values.isEditDraft ) {
            delete requestObj.offerId;
          }

          dispatch( addOfferTemplateFromScratchAction( requestObj ) );
        }
        else if( values.isEditOffer ) {
          const requestObj = new OfferTemplateRequest( { ...values, partnerCostUsdPerQxPoint, offerId, requestType: REQUEST_TYPE.OFFER_TEMPLATE } );

          dispatch( updatePartnerOfferTemplateAction( requestObj ) );
        }
      }
    }
  };

  const handleOpenModal = ( mType ) => {
    setModalType( mType );
    openModal();
  };

  const handleCloseModal = () => {
    closeModal();
    setErrorMessage();
    handleChange( "rejectionReason", "" );
  };

  const handleReject = () => {
    if( modalType === MODAL_TYPE_DETAIL.DRAFT_SAVE_ALERT.type ) {
      closeModal();
      handleChange( "isAutoSave", false );
      handleDraft( true );
    }
    else {
      closeModal();
    }
  };

  const handleOnBlur = () => {
    if( ( !values.isEditOffer ) || isClone ) {
      handleDraft();
    }
  };

  useEffect( () => {
    if( isTimeInterval && ( ( !values.isEditOffer ) || isClone ) ) {
      saveToDraft = setInterval( () => {
        handleDraft();
      }, TIME_INTERVAL_FOR_AUTO_SAVE );
    }
  }, [ isTimeInterval ] );

  const handleEditorOnBlur = () => {
    setIsTimeInterval( false );
    handleDraft();
    clearInterval( saveToDraft );
  };

  const handleBackNavigation = () => {
    return getBackNavigationRoute( ROUTES.PARTNER_OFFER_LIST, location, backScreens );
  };

  return (
    <>
      <div className='main-wrapper'>
        <div className='main-right-wrapper'>
          <BackButton
            backLink={handleBackNavigation()}
            state={fromScreen === "partnerOffers" ? "" : location.state}
          />
          <div className='page-head-wrapper'>
            <h4 className='page-heading small-icon'>
              {headerDetail.title}
              <OverlayTrigger placement='right' overlay={<Tooltip>{headerDetail.description}</Tooltip>}>
                <HelpIcon />
              </OverlayTrigger>
            </h4>
            <div className='page-head-right'>
              <Button variant='outline-secondary btn-md mr-4' onClick={() => history.goBack()}>Cancel</Button>
              <Button variant='primary btn-md mr-4' onClick={() => handleSubmit( "" )}>
                {( !values.isEditOffer || isClone || values.isEditDraft || isOfferFromTemplate ) ? "Submit" : "Update"}</Button>
              {( !values.isEditOffer || isClone || isOfferFromTemplate ) && (
                <Button variant='primary btn-md' onClick={() => handleSubmit( "draft" )}>
                  {values.isEditDraft ? "Update as Draft" : "Save as Draft"}
                </Button>
              )}
            </div>
          </div>
          <div className='page-inner-box profile-steps-box'>
            <Form>
              { values.isOffer && (
                <ChooseTemplate
                  isClone={isClone}
                  clickOnCloned={() => {
                    history.push( {
                      pathname: `${ ROUTES.PARTNER_OFFER_DETAIL
                        .replace( ":offerId", offerDetail.cloneOfferId ? offerDetail.cloneOfferId : offerId ) }?from=clonedOffer`,
                      state: [ ...location.state,
                        ...[ { from: "partnerClonedOffer", path: location.pathname, state: location.search } ],
                      ],
                    } );
                  } }
                  clonedName={offerDetail.cloneOfferName ? offerDetail.cloneOfferName : offerDetail.offerName}
                  templates={lookup?.OfferTemplate || []}
                  onChangeTemplate={value => handleSelectInput( "offerTemplate", value )}
                  selectedTemplate={values.offerTemplate || ""}
                  type='Offer'
                />
              )}
              <OfferTemplate
                isTemplate={!values.isOffer}
                values={values}
                errors={errors}
                lookup={lookup}
                handleInputChange={handleInputChange}
                handleChange={handleChange}
                onSelectedEvent={onSelectedEvent}
                onBlur={handleOnBlur}
                editorOnBlur={handleEditorOnBlur}
                editorOnFocus={() => setIsTimeInterval( true )}
                configuration={configuration}
                type='Offer'
                typeName='offerName'
                displayTimeLimit={true}
                membershipTypes={membershipTypes}
              />
              { values.isOffer && (
                <ScheduleOffer
                  onChange={handleScheduleInputChange}
                  values={values}
                  games={lookup?.ApprovedGame || []}
                  gamers={lookup?.TargetAudience || []}
                  errors={errors}
                  onSelect={handleSelectInput}
                  onBlur={handleOnBlur}
                  startDateChange={e => handleDateChange( "startDate", e )}
                  endDateChange={e => handleDateChange( "endDate", e )}
                  advertiseDateChange={handleAdvertiseDate}
                />
              )}
            </Form>
          </div>
        </div>
      </div>
      <ModalPopup
        isOpened={isOpened}
        onClose={handleCloseModal}
        type={modalType}
        values={values}
        onChangeInput={handleInputChange}
        errorMessage={errorMessage}
        onConfirm={handleReject}
      />
    </>
  );
};


export default CreateAndEditOffer;
