import constant from "../util/constant";
import { OfferAchievements } from "./offerAchievements";

const { DEFAULT_UUID } = constant;

export class ConvertOfferDetailToDraftRequest extends OfferAchievements {
  constructor( obj ) {
    super( obj );
    /* eslint-disable camelcase */
    this.name = obj.offerName.trim();
    this.description = obj.rawDescription;
    this.description_text = obj.descriptionText;
    this.offerType = obj.requestType;
    this.template = obj.parent_template_id !== DEFAULT_UUID
      ? {
        label: obj.parentTemplateName,
        value: obj.parentTemplateId,
      }
      : null;
    this.template_id = "";
    this.offerId = obj.offerId || "";
    this.entity_id = obj.offerId || "";
    this.isOffer = true;
    this.offerSchedule = {
      game: obj.offerSchedule.selectedGame,
      gamers: obj.offerSchedule.selectedAudience,
      game_id: obj.offerSchedule.selectedGame.value,
      audience_id: obj.offerSchedule.selectedAudience?.value || "",
      start_date: obj.offerSchedule.startDate,
      end_date: obj.offerSchedule.endDate,
      max_reward_count_per_user: obj.offerSchedule.offerCanBeAchievedCount || null,
      template_id: obj?.offerTemplate?.value || "",
    };
    this.reward = {
      qx_points: obj.qxPoint,
      partner_cost: obj.qxPoint * obj.partnerCostUsdPerQxPoint,
    };
    /* eslint-enable camelcase */
  }
}
