import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useSelector, useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useForm } from "../../../hooks";
import {
  getAdminGameDetailsAction,
  addAdminCommentAction
} from "../../../store/actions/adminGames";
import adminGameConstant from "../../../util/adminGameConstant";
import constant from "../../../util/constant";
import { convertDate } from "../../../util/helpers";

const { API_STATUS } = constant;
const { ADMIN_GAME_API_TYPES } = adminGameConstant;

const initialState = {
  comment: "",
};

const GameCommentSection = ( { props, gameTestingComments } ) => {
  const dispatch = useDispatch();
  const { gameDetails, apiType, status, msg } = useSelector( state => ( {
    gameDetails: state.adminGame.gameDetails,
    apiType: state.adminGame.apiType,
    status: state.adminGame.status,
    msg: state.adminGame.msg,
  } ) );
  const [ commentError, setCommentError ] = useState();
  const { values, handleChange } = useForm( initialState, {} );
  const gameId = props.match?.params?.gameId || "";

  useEffect( () => {
    if( gameId ) {
      dispatch( getAdminGameDetailsAction( gameId ) );
    }
  }, [ gameId, msg ] );
  useEffect( () => {
    if(
      apiType === ADMIN_GAME_API_TYPES.ADD_ADMIN_COMMENT &&
      status === API_STATUS.SUCCESS
    ) {
      toastr.success( "Comment added successfully" );
      handleChange( "comment", "" );
    }
  }, [ apiType, status, msg ] );

  const handleInputChange = ( e ) => {
    const { name, value } = e.target;

    handleChange( name, value );

    if( commentError ) {
      setCommentError( "" );
    }
  };

  const handleAddComment = () => {
    if( values.comment !== "" ) {
      dispatch( addAdminCommentAction( { comment: values.comment, gameId: gameDetails.gameId } ) );
    }
    else {
      setCommentError( "Please enter comment" );
    }
  };

  return (
    <>
      <div className='comment-container admin-comment-container'>
        <div className='comment-content scrollbar'>
          {gameTestingComments.length > 0 && gameTestingComments.map( ( { comment, createdAt, partnerId }, index ) => {
            const isPartnerComment = partnerId === gameDetails.partnerId;

            return (
              <div key={index} className={!isPartnerComment ? "comment-content-box right-box" : "comment-content-box"}>
                <div className="comment-head">
                  <span className="green-color">{isPartnerComment ? gameDetails.partnerName : "Admin"}</span>{" "}
                  <span>added a comment on</span>{" "}
                  <span>{convertDate( createdAt )}</span>
                </div>
                <div key={index} className="comment-inner">{comment}</div>
              </div>
            );
          }
          )}
        </div>
        <div className="comment-box">
          <Form.Control
            type='text'
            placeholder='Write a reply…'
            name='comment'
            onChange={handleInputChange}
            value={values.comment}
            data-testid='comment'
          />
          <div className='invalid-feedback d-block'>
            {commentError}
          </div>
          <Button variant='primary' className="btn-md" onClick={handleAddComment}>
    Send
          </Button>
        </div>
      </div>
    </> );
};

GameCommentSection.propTypes = {
  match: PropTypes.string,
  props: PropTypes.object,
  isComment: PropTypes.string,
  gameTestingComments: PropTypes.array,
};

export default GameCommentSection;
