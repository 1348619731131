import PropTypes from "prop-types";
import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

const PrimaryContactInfo = ( {
  values,
  errors,
  handleInputChange,
  primaryEmailRef,
  primaryNameRef,
  primaryTitleRef,
  primaryPhoneNumberRef,
  onBlur,
} ) => (
  <>
    <h4 className='page-inner-heading'>
      Primary Contact Information
    </h4>
    <Row>
      <Col sm={6}>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type='text'
            value={values.primaryName}
            name='primaryName'
            onChange={handleInputChange}
            maxLength={50}
            ref={primaryNameRef}
            onBlur={onBlur}
          />
          <div className='invalid-feedback d-block'>
            {errors.primaryName}
          </div>
        </Form.Group>
      </Col>
      <Col sm={6}>
        <Form.Group>
          <Form.Label>Title</Form.Label>
          <Form.Control
            type='text'
            value={values.primaryTitle}
            name='primaryTitle'
            onChange={handleInputChange}
            maxLength={50}
            ref={primaryTitleRef}
            onBlur={onBlur}
          />
          <div className='invalid-feedback d-block'>
            {errors.primaryTitle}
          </div>
        </Form.Group>
      </Col>
      <Col sm={6}>
        <Form.Group>
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type='text'
            value={values.primaryPhoneNumber}
            name='primaryPhoneNumber'
            onChange={handleInputChange}
            maxLength={14}
            ref={primaryPhoneNumberRef}
            onBlur={onBlur}
          />
          <div className='invalid-feedback d-block'>
            {errors.primaryPhoneNumber}
          </div>
        </Form.Group>
      </Col>
      <Col sm={6}>
        <Form.Group>
          <Form.Label>Alternative Phone Number (Optional)</Form.Label>
          <Form.Control
            type='text'
            value={values.primaryAlternativePhoneNumber}
            name='primaryAlternativePhoneNumber'
            onChange={handleInputChange}
            maxLength={14}
            onBlur={onBlur}
          />
        </Form.Group>
      </Col>
      <Col sm={6}>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control
            type='email'
            value={values.primaryEmail}
            name='primaryEmail'
            onChange={handleInputChange}
            ref={primaryEmailRef}
            onBlur={onBlur}
          />
          <div className='invalid-feedback d-block'>
            {errors.primaryEmail}
          </div>
        </Form.Group>
      </Col>
    </Row>
  </>
);

PrimaryContactInfo.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  handleInputChange: PropTypes.func,
  handleSelectInput: PropTypes.func,
  primaryEmailRef: PropTypes.func,
  primaryNameRef: PropTypes.func,
  primaryTitleRef: PropTypes.func,
  primaryPhoneNumberRef: PropTypes.func,
  onBlur: PropTypes.func,
};

export default PrimaryContactInfo;
