import PropTypes from "prop-types";
import React from "react";

import SortDropdown from "../sortDropdown";
import ViewType from "../viewType";

const SortAndViewToggleList = ( {
  values,
  addQuery,
  showFilter = true,
  sortColumns,
  toggleClass,
  handleChange,
  locationState,
  isGamersTournaments = false,
} ) => {
  return (
    <>
      <div className={`${ !showFilter ? "right-side top-align-content" : "right-side" }`}>
        <SortDropdown
          values={values}
          sortColumns={sortColumns}
          toggleClass={toggleClass}
          isGamersTournaments={isGamersTournaments}
        />
        <ViewType
          values={values}
          addQuery={addQuery}
          handleChange={handleChange}
          locationState={locationState}
        />
      </div>
    </>
  );
};

SortAndViewToggleList.propTypes = {
  values: PropTypes.object,
  addQuery: PropTypes.func,
  showFilter: PropTypes.bool,
  sortColumns: PropTypes.array,
  toggleClass: PropTypes.func,
  handleChange: PropTypes.func,
  locationState: PropTypes.string,
  isGamersTournaments: PropTypes.bool,
};

export default SortAndViewToggleList;
