import PropTypes from "prop-types";
import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import adminConstant from "../../../util/adminConstant";
import adminUserConstant from "../../../util/adminUserConstant";
import constant from "../../../util/constant";
import offerConstant from "../../../util/offerConstant";

const { BUSINESS_ENTITY } = constant;
const { MODAL_TYPE_DETAIL } = adminConstant;
const { ADMIN_PERMISSIONS } = adminUserConstant;
const { OFFER_DETAIL_STATUS } = offerConstant;

const OfferViewActions = ( {
  adminDetail,
  offerDetails,
  handleOpenModal,
  businessEntity,
} ) => {
  const enableAction = () => {
    let modalType = "";

    if( offerDetails?.isActive ) {
      modalType = ( businessEntity === BUSINESS_ENTITY.TOURNAMENT )
        ? MODAL_TYPE_DETAIL.INACTIVE_TOURNAMENT.type
        : MODAL_TYPE_DETAIL.INACTIVE_OFFER.type;
    }
    else if( businessEntity === BUSINESS_ENTITY.TOURNAMENT ) {
      modalType = MODAL_TYPE_DETAIL.ACTIVE_TOURNAMENT.type;
    }
    else {
      modalType = MODAL_TYPE_DETAIL.ACTIVE_OFFER.type;
    }

    handleOpenModal( modalType );
  };

  return (
    <>
      <div className="page-head-wrapper">
        <h4 className="page-heading">{offerDetails?.offerName}</h4>
        {( ( offerDetails?.isPartnerActive === true )
            && ( adminDetail?.permissions?.includes( ADMIN_PERMISSIONS.APPROVE_OFFERS ) )
            && ( offerDetails?.approvalStatus === OFFER_DETAIL_STATUS.IN_REVIEW )
            && ( !offerDetails?.offerDependencyDisabled )
            && ( <div className='page-head-right'>
              <Button
                className='btn btn-md mr-4'
                onClick={() =>
                  handleOpenModal( MODAL_TYPE_DETAIL.REASON_REJECTION.type )
                }
                variant='outline-danger'
              >Reject
              </Button>

              <Button
                className='btn btn-primary btn-lg'
                onClick={() =>
                  handleOpenModal(
                    ( businessEntity === BUSINESS_ENTITY.TOURNAMENT )
                      ? MODAL_TYPE_DETAIL.APPROVE_TOURNAMENT.type
                      : MODAL_TYPE_DETAIL.APPROVE_OFFER.type
                  )
                }
              >Approve
              </Button>
            </div> ) )}
        { ( ( offerDetails?.isPartnerActive === true )
            && ( adminDetail?.permissions?.includes( ADMIN_PERMISSIONS.APPROVE_OFFERS ) )
            && ( offerDetails?.approvalStatus === OFFER_DETAIL_STATUS.APPROVED )
            && ( !offerDetails?.offerDependencyDisabled )
            && ( <div className='btn-switch large'>
              <Form.Check
                type='switch'
                id='custom-switch'
                label='Enabled'
                checked={offerDetails?.isActive || false}
                onChange={() => enableAction()}
              />
            </div> ) )}
      </div>
    </>
  );
};

OfferViewActions.propTypes = {
  adminDetail: PropTypes.object,
  offerDetails: PropTypes.object,
  handleOpenModal: PropTypes.func,
  businessEntity: PropTypes.string,
};

export default OfferViewActions;
