export class AdminFilterList {
  getGames = ( obj ) => {
    if( obj?.ApprovedGame ) {
      return obj.ApprovedGame;
    }

    return obj?.Game;
  };

  getPartners = ( obj ) => {
    if( obj?.ApprovedPartner ) {
      return obj.ApprovedPartner;
    }

    return obj?.Partner;
  };

  constructor( obj ) {
    this.eventIdList = obj?.Event?.map( s => ( { label: s.event_id, value: s.event_id } ) );
    this.eventNameList = obj?.Event?.map( s => ( { label: s.event_name, value: s.event_name } ) );
    this.partnerList = this.getPartners( obj )?.map( s => ( { label: s.legal_entity_name, value: s.partner_id } ) );
    this.eventUnitList = obj?.EventUnit?.map( s => ( { label: s.name, value: s.event_unit_id } ) );
    this.gameList = this.getGames( obj )?.map( s => ( { label: s.name, value: s.game_id } ) );
    this.offerList = obj?.Offer?.map( s => ( {
      label: s.name,
      value: s.offer_id }
    ) )
      .sort( ( a, b ) => a.label.localeCompare( b.label ) ) ?? [];
    this.userGames = obj?.gamerGames?.map( s => ( { label: s.name, value: s.gameId } ) ) || [];
    this.tournamentList = obj?.Tournament?.map( s => ( { label: s.name, value: s.offer_id } ) );
    this.gameOfferList = obj.GameOffer?.map( s => ( {
      label: s.name,
      value: s.game_id }
    ) )
      .sort( ( a, b ) => a.label.localeCompare( b.label ) ) ?? [];
    this.gameTournamentList = obj.GameTournament?.map( s => ( {
      label: s.name,
      value: s.game_id }
    ) )
      .sort( ( a, b ) => a.label.localeCompare( b.label ) ) ?? [];
  }
}
