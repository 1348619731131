import React from "react";
import constant from "../../../util/constant";

const { BRANDING: { APP_SHORT_NAME, APP_NAME } } = constant;

const TermsConditionsComponent = () => (
  <div className='tc-content scrollbar'>
    <p>
      This Game Partner Agreement (the “GPA”) is entered into by Targeted
      Shopping Solutions, Inc. (“TSS”), dba { APP_NAME }, with an address of
      Post Office Box 631837, Highlands Ranch, CO 80163 and the business
      specified and associated with this Game Partner (“GP”), as detailed in
      Addendum 01, collectively referred to as the “Parties.” This GPA shall
      govern all services and obligations from the date of execution of this GPA
      (“Effective Date”) through the last date of service (“Termination Date”),
      as both dates are defined in Section 5.
    </p>
    <ol>
      <li>
        <span className='underline'>Definitions.</span> In addition to the other
        terms defined elsewhere in this Agreement, the following capitalized
        terms will have the corresponding meaning set forth below:
        <ul className='lower-alpha'>
          <li>
            <span className='underline'>{ APP_NAME } (“{ APP_SHORT_NAME }”)</span> – TSS
            offering that allows { APP_SHORT_NAME } Members to (1) track and exchange In-Game
            Rewards from Linkable Games, (2) achieve GP Offers for earning { APP_SHORT_NAME }P,
            (3) receive Affiliate Partners’ rewards as { APP_SHORT_NAME }P, (4) optionally
            receive other value-added Premium Services, and (5) redeem { APP_SHORT_NAME }P for
            cash and other future redemption options.{" "}
          </li>
          <li>
            <span className='underline'>{ APP_NAME } Points (“{ APP_SHORT_NAME }P”)</span> – A
            rewarded value that is used to (1) track and exchange GP In-Game
            Reward value, (2) reward GP Offer achievements, and (3) reward other
            Affiliate Partner value to { APP_SHORT_NAME } Members. { APP_SHORT_NAME }P are available for
            redemption as United States Dollars (“USD”) and for other currency
            and redemption options in future versions. One { APP_SHORT_NAME }P has a monetary
            value of one cent (USD - $.01).
          </li>
          <li>
            <span className='underline'>Affiliate Partner</span> – An Affiliate
            Partner is a non-GP partner who provides value-added services for
            { APP_SHORT_NAME }, its GP, and its { APP_SHORT_NAME } Members, including (1) Premium Services, (2)
            { APP_SHORT_NAME }P rewards for { APP_SHORT_NAME } Members that are earned through programs not
            associated with Linkable Games, and (3) { APP_SHORT_NAME } functions and features.{" "}
          </li>
          <li>
            <span className='underline'>Game Partner (“GP”)</span> – A business
            entity that has signed this GPA and is responsible for operating one
            or more Linkable Games and the associated rules, terms, conditions,
            and payments for the Linkable Games. The GP is also responsible for
            determining which In-Game Rewards are tracked and exchangeable
            through { APP_SHORT_NAME }, the { APP_SHORT_NAME }P value of those In-Game Rewards, and any Offers
            targeted to { APP_SHORT_NAME } Members.
          </li>
          <li>
            <span className='underline'>In-Game Reward (“IGR”)</span> – A reward
            or digital asset specified by a GP that is issued within a Linkable
            Game to a { APP_SHORT_NAME } Member for (1) achieving in-game levels or time of game
            play, (2) in-game ad engagement, (3) in-game tournament play, or (4)
            other in-game actions or events which the GP deems appropriate for
            IGR issuance. The IGR has a defined { APP_SHORT_NAME }P value that is tracked and
            exchangeable within the { APP_SHORT_NAME } app.
          </li>
          <li>
            <span className='underline'>Linkable Game</span> – A mobile/video
            game (1) issued and operated by a GP, (2) enabled by GP to be linked
            to { APP_SHORT_NAME } for the purpose of tracking and exchanging In-Game Rewards
            and/or having targeted Offers, and (3) downloaded, linked, and
            played by { APP_SHORT_NAME } Members.
          </li>
          <li>
            <span className='underline'>Offer</span> – A GP-defined offer that
            is presented to targeted { APP_SHORT_NAME } Members within the { APP_SHORT_NAME } app for their
            review and subsequent achievement. Offers will typically have
            defined time periods, in-game actions or events that must be
            achieved to receive the Offer’s { APP_SHORT_NAME }P reward, and other Offer terms
            and conditions.
          </li>
          <li>
            <span className='underline'>Premium Services</span> – Value-added
            services offered to { APP_SHORT_NAME } Members who upgrade their membership level.
            These services include bonus { APP_SHORT_NAME }P, Affiliate Partner services, and GP
            offers and discounts not otherwise available to { APP_SHORT_NAME } Members.
          </li>
          <li>
            <span className='underline'>{ APP_SHORT_NAME } app</span> – a
            free-to-download-and-use web application that allows { APP_SHORT_NAME } Members to
            (1) track, manage and exchange their In-Game Rewards to { APP_SHORT_NAME }P from
            multiple GP Linkable Games, (2) receive GP-defined targeted Offers
            for earning { APP_SHORT_NAME }P, (3) redeem { APP_SHORT_NAME }P for USD and other future redemption
            options, and (4) upgrade to premium membership levels to receive
            Premium Services.
          </li>
          <li>
            <span className='underline'>{ APP_SHORT_NAME } Member</span> – an individual who has
            downloaded the { APP_SHORT_NAME } app, signed up to be a { APP_SHORT_NAME } Member, and has agreed
            to { APP_SHORT_NAME } terms and conditions. A { APP_SHORT_NAME } Member also downloads, acquires,
            and plays one or more Linkable Games under the rules, terms,
            conditions, and payments of GP. A { APP_SHORT_NAME } Member can optionally upgrade
            their free membership to a paid membership to receive Premium
            Services
          </li>
        </ul>
      </li>

      <li>
        <span className='underline'>{ APP_SHORT_NAME } Participation.</span> GP agrees to
        participate in the { APP_SHORT_NAME }, including:
        <ul className='lower-alpha'>
          <li>
            NOTE: Phase 1 Participation is highlighted in{" "}
            <span className='text-green'>green</span>. Phase 2 Participation is
            planned to be available in 4Q2021 and is highlighted in{" "}
            <span className='text-blue'>blue</span>.
          </li>
          <li>
            <span className='text-green'>
              Active promotion of { APP_SHORT_NAME } within the Linkable Games, including
              promoting the ability for a { APP_SHORT_NAME } Member to link the Linkable Games
              to the Game Player’s { APP_SHORT_NAME } account.
            </span>
          </li>
          <li>
            <span className='text-green'>
              For each Linkable Game, (1) rewarding { APP_SHORT_NAME } Members with In-Game
              Rewards that can be exchanged for { APP_SHORT_NAME }P at a GP-specified exchange
              rate
            </span>{" "}
            <span className='text-blue'>
              and/or (2) defining and issuing targeted Offers that are achieved
              through in-game play on Linkable Games and which earn { APP_SHORT_NAME }P for the
              { APP_SHORT_NAME } Member when achieved.
            </span>
          </li>
          <li>
            <span className='text-green'>
              Defining and, as necessary, updating the value of each In-Game
              Reward unit (in { APP_SHORT_NAME }P).
            </span>
          </li>
          <li>
            <span className='text-green'>
              Implementing the TSS-provided Software Development Kit (“SDK”) and
              specified APIs for linking the Linkable Games to { APP_SHORT_NAME } and for proper
              accounting of In-Game Reward tracking and exchange.
            </span>
          </li>
          <li>
            <span className='text-blue'>
              Reporting of in-game events and gamer actions that are associated
              with defined Offers using { APP_SHORT_NAME } reporting methods and APIs.
            </span>
          </li>
          <li>
            <span className='text-green'>
              Daily reconciliation and transfer of funds applicable to all
              In-Game Reward exchanges
            </span>{" "}
            <span className='text-blue'>and Offer reward values,</span>{" "}
            <span className='text-green'>
              plus any applicable fees, revenue share, or amounts due (to/from
              { APP_SHORT_NAME } and GP).
            </span>
          </li>
          <li>
            <span className='text-green'>
              Providing feedback on the effectiveness of { APP_SHORT_NAME } in further
              attracting, engaging, and retaining game players for their
              Linkable Games.
            </span>
          </li>
        </ul>
      </li>

      <li>
        <span className='underline'>{ APP_SHORT_NAME } Services.</span> Services to be delivered
        by TSS through the { APP_SHORT_NAME } include the following:
        <ul className='lower-alpha'>
          <li>
            NOTE: Phase 1 Services are available immediately and are highlighted
            in <span className='text-green'>green</span>. Phase 2 Services are
            planned to be available in 4Q2021 and are highlighted in{" "}
            <span className='text-blue'>blue</span>.
          </li>
          <li>
            <span className='text-green'>
              Provide { APP_SHORT_NAME } Members the ability to download and use the free { APP_SHORT_NAME } app
              to (1) track, manage and exchange their In-Game Rewards to { APP_SHORT_NAME }P
              from multiple GP Linkable Games,
            </span>{" "}
            <span className='text-blue'>
              (2) receive GP-defined targeted Offers for earning { APP_SHORT_NAME }P,
            </span>{" "}
            <span className='text-green'>(3) redeem { APP_SHORT_NAME }P for USD</span>{" "}
            <span className='text-blue'>
              and other future redemption options, and (4) upgrade to premium
              membership levels to receive Premium Services.
            </span>
          </li>
          <li>
            <span className='text-green'>
              Promote and encourage { APP_SHORT_NAME } Members to link and play GP Linkable
              Games to earn In-Game Rewards,
            </span>{" "}
            <span className='text-green'>achieve Offers,</span>{" "}
            <span className='text-green'>
              and maintain loyalty and extended game play within the Linkable
              Games.
            </span>
          </li>
          <li>
            <span className='text-green'>
              Connect GP Linkable Games to { APP_SHORT_NAME }, as specified and implemented
              through our Platform and the GP’s implementation of our SDK.
            </span>
          </li>
          <li>
            <span className='text-green'>
              Track In-Game Rewards and execute { APP_SHORT_NAME }-Member requested exchanges,
              using GP-implemented APIs.
            </span>
          </li>
          <li>
            <span className='text-blue'>
              Provide a GP portal for defining targeted Offers, GP Linkable Game
              details, and other GP information required to maintain this
              partnership.
            </span>
          </li>
          <li>
            <span className='text-blue'>
              Target GP-defined Offers to the appropriate { APP_SHORT_NAME } Members, track { APP_SHORT_NAME }
              Member progress toward Offer achievement, and issue { APP_SHORT_NAME }P rewards
              for Offer achievement on behalf of the GP.
            </span>
          </li>
          <li>
            <span className='text-blue'>
              Through the GP portal, share metrics and reporting to GP to track
              the impact of { APP_SHORT_NAME } services on customer acquisition costs, customer
              retention, and overall ROI from { APP_SHORT_NAME } Services.
            </span>
          </li>
          <li>
            <span className='text-green'>
              Execute daily reconciliation and transfer of funds applicable to
              all In-Game Reward exchanges,
            </span>{" "}
            <span className='text-blue'>and Offer reward values,</span>{" "}
            <span className='text-green'>
              plus any applicable fees, revenue share, or amounts due (to/from
              { APP_SHORT_NAME } and GP).
            </span>
          </li>
        </ul>
      </li>
      <li>
        <span className='underline'>
          Level of Service, Pricing, and Payment Terms.
        </span>{" "}
        The GP elects to participate in { APP_SHORT_NAME } at the selected { APP_SHORT_NAME } Level, { APP_SHORT_NAME } revenue
        share, and associated { APP_SHORT_NAME }P values, as further specified in Addendum #1 of
        this GPA. The daily total value of In-Game Reward exchanges and Offer
        Rewards and any applicable fees, { APP_SHORT_NAME } revenue share, or other amounts due
        will be settled through daily reconciliation and funds transfer (to/from
        { APP_SHORT_NAME } and GP). Additional Services not covered in this Agreement will be
        separately and mutually agreed to, billed, invoiced, and paid.
      </li>

      <li>
        <span className='underline'>Service Term.</span> The “Effective Date” of
        this Agreement is defined as the date of the submission of this GPA by
        the GP. The “Termination Date” of this Agreement is one year from the
        Effective Date or upon cancellation by either party. The Agreement will
        be automatically extended at the end of each year for an additional
        year, unless cancelled by either party. Either party may cancel this
        Agreement for any reason by giving the other party thirty (30) days
        written notice. Upon termination or cancellation, the GP will remove all
        { APP_SHORT_NAME } SDK and API code from their Game(s) and no longer promote the { APP_SHORT_NAME }
        services to its game players. Upon termination or cancellation, the GP
        and { APP_SHORT_NAME } also agree to reconcile and transfer any amounts due under the
        Terms of Section 4 of this GPA. The { APP_SHORT_NAME } Services, as defined by this GPA
        in Section 3, will cease upon termination or cancellation on the
        effective Termination Date.
      </li>

      <li>
        <span className='underline'>Other Terms and Conditions.</span> This
        Agreement is the entire agreement and understanding between the Parties
        concerning the subject matter contained herein, and as of its Effective
        Date, terminates and supersedes all prior or contemporaneous agreements
        and understandings, whether written or oral, between the Parties
        covering the subject matter hereof. Additional Terms and Conditions
        included as part of this Agreement are as follows:
        <ul className='lower-alpha'>
          <li>
            <span className='underline'>Construction and Governing Law.</span>{" "}
            In the event of a conflict between any provision of this GPA or any
            Addendums, the conflict shall be resolved by reference to the GPA
            and then to the Addendums. This GPA and all Addendums shall be
            governed by the laws of the State of Colorado.
          </li>
          <li>
            <span className='underline'>Limitation of Liability.</span> TSS
            obligations under this Agreement are limited to the delivery of
            Services defined in Section 3 of the GPA. TSS WILL NOT BE LIABLE TO
            GP OR ANY THIRD PARTY FOR ANY INDIRECT, CONSEQUENTIAL, INCIDENTAL,
            RELIANCE, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGE, INCLUDING LOSS OF
            PROFITS OR LOSS OF REVENUES. In no event will TSS be liable to GP
            for any amount in excess of the aggregate amount TSS has collected
            from GP.
          </li>
          <li>
            <span className='underline'>Indemnity.</span> GP and TSS shall each
            indemnify the other from any claims by third parties and expenses
            (including attorney fees and associated costs) for damage to
            tangible property, personal injury, or death caused by the other
            party’s negligence or willful misconduct. GP agrees to indemnify and
            hold harmless TSS from any losses, damages, costs, or expenses
            resulting from any GP, game player, or third-party claim or
            allegation arising out of acts or omissions related to { APP_SHORT_NAME } and GP’s
            products and services.
          </li>
          <li>
            <span className='underline'>Disclaimer of Warranties.</span> UNLESS
            OTHERWISE SPECIFIED HEREIN, TSS MAKES NO WARRANTY TO GP OR ANY OTHER
            PERSON OR ENTITY, WHETHER EXPRESS, IMPLIED, OR STATUTORY, AS TO THE
            DESCRIPTION, QUALITY, MERCHANTABILITY, COMPLETENESS, OR FITNESS FOR
            ANY PURPOSE, OF ANY SERVICE PROVIDED HEREUNDER.
          </li>
          <li>
            <span className='underline'>Assignment.</span> Neither Party may
            assign this GPA without the express written consent of the other
            party, which consent shall not be unreasonably withheld.
            Notwithstanding the foregoing, either Party may assign all of its
            rights and obligations hereunder to any purchaser or other acquirer
            of substantially all of such Party’s assets or equity. No such
            assignment of obligations shall relieve the assignor of any
            liability or obligation hereunder unless otherwise agreed to in
            writing by both Parties.
          </li>
          <li>
            <span className='underline'>Confidentiality:</span>
            <ol className='decimal'>
              <li /* eslint-disable react/no-unescaped-entities */>
                Parties acknowledge that, in the course of performing their
                respective obligations hereunder, each may obtain information
                relating to the other and the other's products that are of a
                confidential and proprietary nature to such other party
                ("Confidential Information"). Such Confidential Information
                includes any information, technical data or know-how, including,
                without limitation, that which relates to computer software
                programs or documentation, specifications, source code, object
                code, research, inventions, processes, designs, drawings,
                engineering, products, services, customers, markets or finances
                of the disclosing party which (a) has been marked as
                confidential or proprietary, (b) is identified as confidential
                at the time of disclosure either orally or in writing, or (c)
                which, due to its character and nature, a reasonable person
                under like circumstances would treat as confidential.
              </li>
              <li>
                Each Party agrees that it will (a) use the other party's
                Confidential Information only in connection with fulfilling its
                obligations and exercising its rights and licenses under this
                Agreement; (b) hold the other party's Confidential Information
                in strict confidence and exercise due care with respect to its
                handling and protection, consistent with its own policies
                concerning protection of its confidential information, (c)
                unless otherwise permitted hereunder or by law, not directly or
                indirectly reverse engineer, disassemble, or decompile the other
                party’s Confidential Information consisting of binary code; (d)
                not disclose, divulge, or publish the other party's Confidential
                Information except to such of its responsible employees,
                subcontractors, sublicenses and consultants (collectively,
                "Personnel") who have a bona fide need to know to the extent
                necessary to fulfill such party's obligations under this
                Agreement; and (e) instruct all such Personnel not to disclose
                the other party's Confidential Information to third parties,
                without the prior written permission of the other party. Each
                party shall require all Personnel who shall come into contact
                with the Confidential Information of the other party to execute
                a confidentiality agreement at least as protective of the rights
                in such Confidential Information as the terms and conditions of
                this Agreement, prior to such Personnel being given access to
                any Confidential Information.
              </li>
              <li>
                The obligations set forth in Section 6.f.2 above will not apply
                to either party's Confidential Information which (i) is or
                becomes public knowledge without the fault or action of the
                recipient party, or which (ii) the recipient party can document
                was independently developed by it without use of Confidential
                Information of the other party; or which (iii) the recipient
                party can document was already known to it prior to the receipt
                of the other party's Confidential Information.
              </li>
              <li>
                If either party is required to disclose any Confidential
                Information pursuant to an order under law, it shall use its
                reasonable efforts to give the party owning the Confidential
                Information sufficient notice of such required disclosure to
                allow the party owning the Confidential Information reasonable
                opportunity to object to and take necessary legal action to
                prevent such disclosure.
              </li>
            </ol>
          </li>
          <li>
            <span className='underline'>Use of Name.</span> Each Party may use
            the other’s name and logo in promoting and advertising { APP_SHORT_NAME }, including
            identifying GP as a TSS client and TSS as a supplier to GP. Without
            the other Party’s written consent, neither Party shall refer to
            itself as an authorized representative of the other Party or use the
            other Party’s logos, trademarks, service marks, or any variation
            thereof except for the promotion, advertising, execution, and
            delivery of the { APP_SHORT_NAME } Program. Each Party agrees to change or correct
            at its own expense any material statement or activity that the
            affected Party determines to be inaccurate, misleading, or otherwise
            objectionable under this section.
          </li>
          <li>
            <span className='underline'>Force Majeure.</span> Neither Party
            shall be deemed in default of this GPA if and to the extent that
            such Party’s performance is delayed or prevented due to Force
            Majeure. “Force Majeure means an occurrence that is beyond the
            reasonable control of the party affected and occurs without its
            fault or negligence, including acts of God, fire, explosion,
            vandalism, terrorism, flood, storm, pandemic, epidemic, or other
            similar catastrophe, any law, order, regulation, direction, action,
            or request of any governmental entity or court or civil or military
            authority having jurisdiction over either party, national
            emergencies, insurrections, riots, wars, or strikes, lock outs, and
            other work stoppages of third parties.
          </li>
          <li>
            <span className='underline'>Subject to Laws.</span> Both Parties
            shall comply with and give all notices required by all applicable
            federal, state, and local laws, ordinances, rules, regulations, and
            lawful orders of any public authority. Both Parties agree to obtain
            and maintain all certifications, permits, licenses, approvals, or
            authorizations required by any regulatory or governmental entity.
            Each Party shall indemnify and hold harmless the other from all
            claims, losses, demands, causes of action, including all related
            costs and expenses arising from the other Party’s violation of this
            section.
          </li>
          <li>
            <span className='underline'>Notices.</span> All notices shall be in
            writing and shall be deemed to have been duly given and effective as
            of the date of confirmed delivery to the mail, e-mail, or fax
            addresses provided below:
            <ol>
              <li>
                <span className='underline'>Notices to TSS, Inc:</span>
                <br />
                TSS, Inc.
                <br />
                Attention: Contract Administration
                <br />
                9938 Longview Drive
                <br />
                Lone Tree, CO 80124
              </li>
              <li>
                E-mail:{" "}
                <a href='mailto:customerservice@acqyrexchange.com'>
                  customerservice@acqyrexchange.com
                </a>
                <br />
                <span className='underline'>
                  Notices to Game Publisher:
                </span>{" "}
                The GP Address and E-mail are as specified in Addendum #1 and
                associated with this GPA.
              </li>
            </ol>
          </li>
          <li>
            <span className='underline'>Material Breach Notices.</span> To be
            effective, written notice of any material must prominently state
            that the correspondence is a formal notice of breach and must be
            sent via certified mail, registered mail, or overnight mail service.
          </li>
          <li>
            <span className='underline'>Additional Terms and Conditions.</span>{" "}
            The Website Terms and Conditions, presented at our website,{" "}
            <a
              href='https://www.acqyrexchange.com'
              target='_blank'
              className='underline'
              rel='noreferrer'
            >
              www.acqyrexchange.com
            </a>
            , as they relate to { APP_SHORT_NAME } Members, are hereby incorporated into this
            GPA by reference.
          </li>
        </ul>
      </li>
      <li>
        <span className='underline'>Right of Rescission.</span> Either Party has
        five (5) days from the Effective Date of this GPA to cancel the GPA
        without any penalty or obligation of either Party. If GP desires to
        cancel this GPA, the GP can do so by notifying TSS using the contact
        information listed in Section 6(i)(1) above. If TSS desires to cancel
        this GPA, TSS can do so by notifying GP using the contact information
        referenced in Section 6(i)(2) above.
      </li>
    </ol>
  </div>
);

export default TermsConditionsComponent;
