import { orderBy } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useAddQuery, useForm, useFilter, getUrlData } from "../../../../hooks";
import { getOptInTransactionAction } from "../../../../store/actions/partnerAnalytics";
import analyticsConstant from "../../../../util/analyticsConstant";
import constant from "../../../../util/constant";
import { useSetUrlParams } from "../../../../util/helpers";
import OptInTxnFilters from "./optInTxnFilters";
import OptInTxnList from "./optInTxnList";

const { API_STATUS } = constant;
const { ANALYTICS_TAB } = analyticsConstant;

const OptInTxn = ( {
  searchKey = "",
  setPreviousData,
  showFilter,
  gameId = "",
  gameName = "",
  tab = ANALYTICS_TAB.OFFER_REWARD_TXN,
} ) => {
  const isGameId = Boolean( gameId );
  const ref = useRef( null );
  const dispatch = useDispatch();
  const location = useLocation();
  const { addQuery } = useAddQuery();
  const { setPureData, filteredData, filterData, handleFilterChange, clearSelectedFilter } = useFilter();

  const {
    optInTransaction,
    apiType,
    status,
    usdPerQxPoint,
  } = useSelector( state => ( {
    optInTransaction: state.partnerAnalytics.optInTransaction,
    apiType: state.partnerAnalytics.apiType,
    status: state.partnerAnalytics.status,
    usdPerQxPoint: state.partner.partnerDetail.configuration?.usdPerQxPoint || 0.01,
  } ) );

  const { transactionList, gameNames, offerNames, gamerNames, qxPointsRange, transactionFor } = optInTransaction;

  const { values, handleChange } = useForm(
    {
      searchKey: "",
      sortBy: "",
      sortOrder: "",
      gamerName: [],
      gameIds: [],
      offerNames: [],
      transactionFor: [],
      minQxPoint: 0,
      maxQxPoint: 0,
      startDate: null,
      endDate: null,
      searchCol: [ "gameName", "gamerName", "offerName", "transactionFor" ],
      compareKeyArray: [ "QXPoints", "gamerName", "name" ],
    },
    {}
  );

  const requestParams = Object.assign(
    {
      isSingleDate: true,
      searchCol: values.searchCol,
      sortOrder: values.sortOrder,
      sortBy: values.sortBy,
      searchKey: values.searchKey,
      gamerName: values.gamerName?.map( item => item.value ) || [],
      gameIds: values.gameIds?.map( item => item.value ) || [],
      offerNames: values.offerNames?.map( item => item.value ) || [],
      transactionFor: values.transactionFor?.map( item => item.value ) || [],
      minQxPoint: values.minQxPoint,
      maxQxPoint: values.maxQxPoint,
      startDate: values.startDate,
      endDate: values.endDate,
      compareKeyArray: values.compareKeyArray,
    }
  );

  const handleSorting = ( sortBy, orderType ) => {
    handleChange( "sortBy", sortBy );
    handleChange( "sortOrder", orderType );
    addQuery( useSetUrlParams( "sortBy", sortBy ), location.state, isGameId );
    addQuery( useSetUrlParams( "sortOrder", orderType ), location.state, isGameId );
  };

  useEffect( () => {
    dispatch( getOptInTransactionAction(
      { type: tab }
    ) );
  }, [] );

  useEffect( () => {
    if( gameId && gameName ) {
      const selectedGame = { label: gameName, value: gameName };

      handleChange( "gameIds", [ selectedGame ] );
      addQuery( useSetUrlParams( "gameIds", JSON.stringify( [ selectedGame ] ) ), location.state, isGameId );
    }
  }, [ gameId, gameName ] );

  useEffect( () => {
    if(
      ( status === API_STATUS.SUCCESS )
    ) {
      const sortTransactionList = orderBy( transactionList, [ "date" ], [ "desc" ] );

      handleChange( "minQxPoint", qxPointsRange.min );
      handleChange( "maxQxPoint", qxPointsRange.max );
      setPureData( sortTransactionList );
      getUrlData( handleChange );
      setPreviousData();
    }
  }, [ status, apiType ] );

  useEffect( () => {
    filterData( requestParams );
  }, [
    values.sortOrder,
    values.sortBy,
    values.searchKey,
    values.gamerName,
    values.gameIds,
    values.offerNames,
    values.transactionFor,
    values.minQxPoint,
    values.maxQxPoint,
    values.startDate,
    values.endDate,
  ] );

  useEffect( () => {
    handleChange( "searchKey", searchKey );
  }, [ searchKey ] );

  return ( <>
    <div className={`filter-tabs-wrapper mb-0 ${ ( !showFilter ) ? "" : "remove-filter" }`}>
      <OptInTxnFilters
        values={values}
        handleChange={handleChange}
        addQuery={addQuery}
        gamerNames={gamerNames}
        gameNames={gameNames}
        offerNames={offerNames}
        transactionFor={transactionFor}
        transactionRanges={qxPointsRange}
        isGameId={isGameId}
        locationState={location.state}
        tab={tab}
        ref={ref}
        handleFilterChange={handleFilterChange}
        clearSelectedFilter={clearSelectedFilter}
      />
    </div>
    <div className='table-container partner-analytics-optIn-transaction'>
      <OptInTxnList
        showFilter={showFilter}
        handleSorting={handleSorting}
        dataList={filteredData}
        tab={tab}
        usdPerQxPoint={usdPerQxPoint}
      />
    </div>
  </>
  );
};

OptInTxn.propTypes = {
  searchKey: PropTypes.string,
  gameId: PropTypes.string,
  setPreviousData: PropTypes.func,
  gameName: PropTypes.string,
  showFilter: PropTypes.string,
  tab: PropTypes.string,
};

export default OptInTxn;
