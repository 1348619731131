import constant from "../../util/constant";
import {
  EMAIL_UPDATE_API_REQUEST,
  EMAIL_UPDATE_API_END,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_FAILURE
} from "../actionTypes";

const { API_STATUS } = constant;

const DEFAULT_STATE = {
  loading: false,
  message: "",
  status: "",
  apiType: "",
};

const emailUpdate = ( state = DEFAULT_STATE, action = "" ) => {
  switch ( action.type ) {
    case EMAIL_UPDATE_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
      };
    case EMAIL_UPDATE_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
      };
    case UPDATE_EMAIL_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
      };
    default:
      return state;
  }
};

export default emailUpdate;

