import constant from "../../util/constant";
import {
  ADMIN_USER_API_REQUEST,
  ADMIN_USER_API_END,
  GET_ADMIN_USER_SUCCESS,
  GET_ADMIN_USER_FAILURE,
  ADD_ADMIN_USER_FAILURE,
  ADD_ADMIN_USER_SUCCESS,
  GET_ADMIN_USER_DETAIL_SUCCESS,
  GET_ADMIN_USER_DETAIL_FAILURE,
  UPDATE_ADMIN_USER_PERMISSION_SUCCESS,
  UPDATE_ADMIN_USER_PERMISSION_FAILURE,
  ADMIN_USERS_FILTER_LIST_SUCCESS,
  ADMIN_USERS_FILTER_LIST_FAILURE,
  ENABLE_DISABLE_ADMIN_USER_FAILURE
} from "../actionTypes";

const { API_STATUS } = constant;

const DEFAULT_STATE = {
  loading: false,
  message: "",
  status: "",
  apiType: "",
  adminUsers: { users: [], meta: "" },
  adminUserDetail: {},
  adminFilterDetail: {},
};

const adminUser = ( state = DEFAULT_STATE, action = "" ) => {
  switch ( action.type ) {
    case ADMIN_USER_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
      };
    case ADMIN_USER_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case GET_ADMIN_USER_SUCCESS:
      return {
        ...state,
        adminUsers: {
          users:
            action.payload.meta.page === 1
              ? action.payload.dataList
              : [ ...state.adminUsers.users, ...action.payload.dataList ],
          meta: action.payload.meta,
        },
        status: API_STATUS.SUCCESS,
      };
    case GET_ADMIN_USER_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        adminUsers: { users: [], meta: "" },
      };
    case ADD_ADMIN_USER_FAILURE:
    case UPDATE_ADMIN_USER_PERMISSION_FAILURE:
    case GET_ADMIN_USER_DETAIL_FAILURE:
    case ADMIN_USERS_FILTER_LIST_FAILURE:
    case ENABLE_DISABLE_ADMIN_USER_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        loading: false,
      };
    case ADD_ADMIN_USER_SUCCESS:
    case UPDATE_ADMIN_USER_PERMISSION_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
      };
    case GET_ADMIN_USER_DETAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        adminUserDetail: action.payload,
      };
    case ADMIN_USERS_FILTER_LIST_SUCCESS:
      return {
        ...state,
        adminFilterDetail: action.payload,
      };
    default:
      return state;
  }
};

export default adminUser;
