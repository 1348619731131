import PropTypes from "prop-types";
import React from "react";
import Modal from "react-bootstrap/Modal";
import adminConstant from "../../../util/adminConstant";
import { ModalFooter } from "../../shared/customModal";
import { CloneDraft } from "./cloneDraft";
import { ErrorsModalBody } from "./errorsModalBody";
import { ListBody } from "./listBody";

const { MODAL_TYPE_DETAIL } = adminConstant;

const ModalPopup = ( {
  isOpened,
  onClose,
  type,
  onConfirm,
  errorList = [],
  onChangeInput,
  errorMessage,
  cloneName,
  offerName = "",
  dataList = [],
} ) => {
  const modalData = MODAL_TYPE_DETAIL[ type ] || {
    headerText: "",
    cancelText: "",
    confirmText: "",
    className: "",
  };

  return (
    <Modal show={isOpened} centered className={modalData.className}>
      <Modal.Header>
        <h2 className='modal-heading'>{`${ modalData.headerText } ${ offerName }`}</h2>
      </Modal.Header>
      {
        {
          DISABLE_GAME: (
            <Modal.Body>
              <p>Are you sure you want to delete this game?</p>
            </Modal.Body>
          ),
          REMOVE_OFFER: (
            <Modal.Body>
              <p>Are you sure you want to remove this offer template?</p>
            </Modal.Body>
          ),
          REMOVE_DRAFT: (
            <Modal.Body>
              <p>Are you sure you want to remove this draft?</p>
            </Modal.Body>
          ),
          ERROR_LIST: (
            <Modal.Body className="pb-0">
              <p className="mb-3">Found some information missing while adding your business.</p>
              <ErrorsModalBody errors={errorList} />
            </Modal.Body>
          ),
          CLONE_DRAFT: (
            <Modal.Body className="pb-0">
              <CloneDraft
                onChange={onChangeInput}
                cloneName={cloneName}
                errorMessage={errorMessage}
                placeholder='Enter clone draft name'
              />
            </Modal.Body>
          ),
          CLONE_TEMPLATE: (
            <Modal.Body className="pb-0">
              <CloneDraft
                onChange={onChangeInput}
                cloneName={cloneName}
                errorMessage={errorMessage}
                placeholder='Enter clone template name'
              />
            </Modal.Body>
          ),
          SHOW_ALL_REWARD: (
            <Modal.Body>
              <ListBody list={dataList} />
            </Modal.Body>
          ),
          REMOVE_TOURNAMENT: (
            <Modal.Body>
              <p>Are you sure you want to remove this tournament template?</p>
            </Modal.Body>
          ),
        }[ type ]
      }
      <ModalFooter
        onClose={onClose}
        onConfirm={onConfirm}
        cancelText={modalData.cancelText}
        confirmText={modalData.confirmText}
        type={type}
      />
    </Modal>
  );
};

ModalPopup.propTypes = {
  isOpened: PropTypes.string,
  onClose: PropTypes.func,
  type: PropTypes.string,
  onConfirm: PropTypes.func,
  errorList: PropTypes.array,
  onChangeInput: PropTypes.func,
  errorMessage: PropTypes.string,
  cloneName: PropTypes.string,
  offerName: PropTypes.string,
  dataList: PropTypes.array,
};

export default ModalPopup;
