import PropTypes from "prop-types";
import React from "react";
import constant from "../../../../util/constant";
import { checkFilterApplied, getBackNavigationRoute } from "../../../../util/helpers";
import FilterIcon from "../../../shared/FilterIcon/FilterIcon";
import BackButton from "../../../shared/backButton";
import ProfileImage from "../../../shared/profileImage";

const { FILTERS_NAME, ROUTES } = constant;

const LinkedGamesHeader = ( {
  gamerDetail,
  gamerId,
  showFilter,
  showFilterScreen,
  location,
} ) => {
  const backScreens = [ "partnerGamerDetail" ];
  const isFilterApplied = checkFilterApplied( location.search, FILTERS_NAME.GAMER_DETAIL );

  const handleBackNavigation = () => {
    return getBackNavigationRoute( ROUTES.PARTNER_GAMER_SECTION.replace( ":gamerId", gamerId ), location, backScreens );
  };

  return (
    <>
      <BackButton
        backLink={handleBackNavigation()}
        state={location.state}
      />
      <div className="page-head-wrapper">
        <div className='d-flex align-items-center'>
          <ProfileImage profileImage={gamerDetail?.profileImage} />
          <div className='profile-title mb-0'>{gamerDetail.gamerFullName}</div>
        </div>
      </div>
      <div className="page-head-wrapper">
        <h4 className="page-heading">Games</h4>
        <FilterIcon
          showFilter={showFilter}
          showFilterScreen={showFilterScreen}
          isFilterApplied={isFilterApplied} />
      </div>
    </>
  );
};

LinkedGamesHeader.propTypes = {
  gamerDetail: PropTypes.object,
  gamerId: PropTypes.string,
  handleChange: PropTypes.func,
  isFilterApplied: PropTypes.bool,
  showFilter: PropTypes.bool,
  showFilterScreen: PropTypes.func,
  values: PropTypes.object,
  location: PropTypes.object,
};

export default LinkedGamesHeader;
