import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { Col, Row, Form } from "react-bootstrap";

import { useAddQuery } from "../../../../hooks";
import constant from "../../../../util/constant";
import { useSetUrlParams } from "../../../../util/helpers";
import CustomSlider from "../../../shared/customSlider";
import MultiSelectCheckbox from "../../../shared/multiSelectCheckbox";

const { BRANDING: { APP_SHORT_NAME } } = constant;

const AssetsFilters = ( {
  assetNames,
  assetIds,
  gameNames,
  handleChange,
  showFilter,
  qxPointRange,
  values,
  location,
} ) => {
  const { addQuery } = useAddQuery();
  const assetsFilterArray = useMemo( () => [ values.assetNames, values.assetIds, values.gameNames ] );

  const handleAssetChange = ( obj, filterListName ) => {
    if( obj?.length ) {
      handleChange( filterListName, obj );
      addQuery( useSetUrlParams( filterListName, JSON.stringify( obj ) ), location.state );
    }
    else {
      addQuery( useSetUrlParams( filterListName, "" ), location.state );
      handleChange( filterListName );
    }
  };

  const handleAssetFilterChange = useCallback(
    [ obj => handleAssetChange( obj, "assetNames" ),
      obj => handleAssetChange( obj, "assetIds" ),
      obj => handleAssetChange( obj, "gameNames" ) ],
    []
  );

  const handleFilterClear = ( params ) => {
    if( params ) {
      addQuery( useSetUrlParams( params, "" ), location.state );
      handleChange( params, [] );
    }
  };

  const qxPointsHandler = ( e, newValue ) => {
    addQuery( useSetUrlParams( "minQxPoint", newValue[ 0 ] ), location.state );
    addQuery( useSetUrlParams( "maxQxPoint", newValue[ 1 ] ), location.state );
    handleChange( "minQxPoint", newValue[ 0 ] );
    handleChange( "maxQxPoint", newValue[ 1 ] );
  };

  return (
    <>
      <div className='filter-tabs-wrapper mb-0'>
        <div className='filter-boxes offer-filter mr-0'>
          <div className={`filter-boxes mr-0 bottom-margin ${ !showFilter ? "" : "remove-filter" }`}>
            <Row>
              <Col md={3}>
                <Form.Group className='custom-multiselect mb-0' data-testid="full-name">
                  <Form.Label>Asset Name</Form.Label>
                  <MultiSelectCheckbox
                    multiOptionsList={assetNames}
                    handleSelectChange={handleAssetFilterChange[ 0 ]}
                    multiSelectedItems={assetsFilterArray[ 0 ]}
                    handleClearMultiSelect={() => handleFilterClear( "assetNames" )}
                    name='assetNames'
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className='custom-multiselect mb-0' data-testid="full-name">
                  <Form.Label>Asset ID</Form.Label>
                  <MultiSelectCheckbox
                    multiOptionsList={assetIds}
                    handleSelectChange={handleAssetFilterChange[ 1 ]}
                    multiSelectedItems={assetsFilterArray[ 1 ]}
                    handleClearMultiSelect={() => handleFilterClear( "assetIds" )}
                    name='assetIds'
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className='custom-multiselect mb-0' data-testid="full-name">
                  <Form.Label>Game</Form.Label>
                  <MultiSelectCheckbox
                    multiOptionsList={gameNames}
                    handleSelectChange={handleAssetFilterChange[ 2 ]}
                    multiSelectedItems={assetsFilterArray[ 2 ]}
                    handleClearMultiSelect={() => handleFilterClear( "gameNames" )}
                    name='gameNames'
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className='custom-multiselect mb-0' data-testid="full-name">
                  <CustomSlider
                    values={values}
                    pointsHandler={qxPointsHandler}
                    handleChange={handleChange}
                    min={qxPointRange.min}
                    max={qxPointRange.max}
                    minName={"minQxPoint"}
                    maxName={"maxQxPoint"}
                    label={`${ APP_SHORT_NAME } Points`}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

AssetsFilters.propTypes = {
  assetNames: PropTypes.array,
  assetIds: PropTypes.array,
  handleChange: PropTypes.func,
  gameNames: PropTypes.array,
  showFilter: PropTypes.bool,
  qxPointRange: PropTypes.object,
  values: PropTypes.object,
  location: PropTypes.object,
};

export default AssetsFilters;
