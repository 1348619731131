import HelpIcon from "@material-ui/icons/Help";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";

import { useAddQuery } from "../../../../hooks";
import { useSetUrlParams } from "../../../../util/helpers";
import offerConstant from "../../../../util/offerConstant";
import CustomDatePicker from "../../../shared/customDatePicker";
import CustomSlider from "../../../shared/customSlider";
import MultiSelectCheckbox from "../../../shared/multiSelectCheckbox";
import SortAndViewToggleList from "../../../shared/sortAndViewToggleList";

const { OPT_IN_STATUS_DROPDOWN } = offerConstant;

const TournamentsFilters = ( {
  tournamentNameFilterList,
  gameNameFilterList,
  gamerPlaceRange,
  handleChange,
  showFilter,
  values,
  sortColumns,
  toggleClass,
  location,
  handleFilterChange,
  clearSelectedFilter,
} ) => {
  const { addQuery } = useAddQuery();

  const handleSelect = ( filterName, obj ) => {
    handleFilterChange( filterName, obj, handleChange, addQuery, location.state );
  };

  const onSelectFilterItems = useCallback(
    [ obj => handleSelect( "tournamentIds", obj ),
      obj => handleSelect( "gameIds", obj ),
      obj => handleSelect( "optInTypes", obj ),
    ],
    []
  );

  const multiSelectedItems = useMemo( () => [ values.tournamentIds, values.gameIds, values.optInTypes ] );

  const clearAllSelectedOptions = useCallback(
    params => clearSelectedFilter( params, addQuery, handleChange, location.state ), []
  );

  const tournamentGamerPlaceHandler = ( e, newValue ) => {
    addQuery( useSetUrlParams( "minGamerPlace", newValue[ 0 ] ), location.state );
    addQuery( useSetUrlParams( "maxGamerPlace", newValue[ 1 ] ), location.state );
    handleChange( "minGamerPlace", newValue[ 0 ] );
    handleChange( "maxGamerPlace", newValue[ 1 ] );
  };

  return (
    <>
      <div className='filter-tabs-wrapper align-items-end'>
        <div className="filter-boxes offer-filter tm-filer-box">
          <div className={`filter-boxes mr-0 ${ ( !showFilter ) ? "" : "remove-filter" }`}>
            <Row>
              <Col md={3} className="mb-20">
                <Form.Group className='custom-multiselect mb-0'>
                  <Form.Label>Tournament Name</Form.Label>
                  <MultiSelectCheckbox
                    multiOptionsList={tournamentNameFilterList}
                    handleSelectChange={onSelectFilterItems[ 0 ]}
                    multiSelectedItems={multiSelectedItems[ 0 ]}
                    handleClearMultiSelect={() => clearAllSelectedOptions( "tournamentIds" )} />
                </Form.Group>
              </Col>
              <Col md={3} className="mb-20">
                <Form.Group className='custom-multiselect mb-0'>
                  <Form.Label>Game Name</Form.Label>
                  <MultiSelectCheckbox
                    multiOptionsList={gameNameFilterList}
                    handleSelectChange={onSelectFilterItems[ 1 ]}
                    multiSelectedItems={multiSelectedItems[ 1 ]}
                    handleClearMultiSelect={() => clearAllSelectedOptions( "gameIds" )} />
                </Form.Group>
              </Col>
              <Col md={3} className="mb-20">
                <Form.Group className='custom-datepicker mb-0'>
                  <Form.Label className='label-with-icon'>Start Date
                    <OverlayTrigger placement='right' overlay={<Tooltip>All items starting on or after the date you choose.</Tooltip>}>
                      <HelpIcon />
                    </OverlayTrigger>
                  </Form.Label>
                  <CustomDatePicker
                    value={values.startDate}
                    handleChange={( e ) => {
                      if( e ) {
                        addQuery( useSetUrlParams( "startDate", new Date( e ).toLocaleDateString( "fr-CA" ) ), location.state );
                        handleChange( "startDate", new Date( e ).toLocaleDateString( "fr-CA" ) );
                      }
                      else {
                        addQuery( useSetUrlParams( "startDate", "" ), location.state );
                        handleChange( "startDate", false );
                      }
                    }
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={3} className="mb-20">
                <Form.Group className='custom-datepicker mb-0'>
                  <Form.Label className='label-with-icon'>End Date
                    <OverlayTrigger placement='left' overlay={<Tooltip>All items ending on or before the date you choose.</Tooltip>}>
                      <HelpIcon />
                    </OverlayTrigger>
                  </Form.Label>
                  <CustomDatePicker
                    value={values.endDate}
                    handleChange={( e ) => {
                      if( e ) {
                        addQuery( useSetUrlParams( "endDate", new Date( e ).toLocaleDateString( "fr-CA" ) ), location.state );
                        handleChange( "endDate", new Date( e ).toLocaleDateString( "fr-CA" ) );
                      }
                      else {
                        addQuery( useSetUrlParams( "endDate", "" ), location.state );
                        handleChange( "endDate", false );
                      }
                    }
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <CustomSlider
                  values={values}
                  pointsHandler={tournamentGamerPlaceHandler}
                  handleChange={handleChange}
                  min={gamerPlaceRange.min}
                  max={gamerPlaceRange.max}
                  minName={"minGamerPlace"}
                  maxName={"maxGamerPlace"}
                  label={"Place"}
                />
              </Col>
              <Col md={3}>
                <Form.Group className='custom-multiselect mb-0'>
                  <Form.Label>Opt In</Form.Label>
                  <MultiSelectCheckbox
                    multiOptionsList={ OPT_IN_STATUS_DROPDOWN }
                    handleSelectChange={onSelectFilterItems[ 2 ]}
                    multiSelectedItems={multiSelectedItems[ 2 ]}
                    handleClearMultiSelect={() => clearAllSelectedOptions( "optInTypes" )}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </div>
        <SortAndViewToggleList
          values={values}
          addQuery={addQuery}
          showFilter={showFilter}
          sortColumns={sortColumns}
          toggleClass={toggleClass}
          handleChange={handleChange}
          locationState={location.state}
          isGamersTournaments={true}
        />
      </div>
    </>
  );
};

TournamentsFilters.propTypes = {
  tournamentNameFilterList: PropTypes.array,
  gameNameFilterList: PropTypes.array,
  handleChange: PropTypes.func,
  showFilter: PropTypes.bool,
  values: PropTypes.object,
  sortColumns: PropTypes.array,
  toggleClass: PropTypes.func,
  location: PropTypes.object,
  gamerPlaceRange: PropTypes.object,
  handleFilterChange: PropTypes.func,
  clearSelectedFilter: PropTypes.func,
};

export default TournamentsFilters;
