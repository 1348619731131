import constant from "../../util/constant";
import {
  LEADER_BOARD_API_END,
  GET_LEADER_BOARD_SUCCESS,
  GET_LEADER_BOARD_FAILURE,
  LEADER_BOARD_API_REQUEST
} from "../actionTypes";

const { API_STATUS } = constant;

const DEFAULT_STATE = {
  data: {},
};

const leaderboard = ( state = DEFAULT_STATE, action = "" ) => {
  switch ( action.type ) {
    case LEADER_BOARD_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
      };
    case LEADER_BOARD_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case GET_LEADER_BOARD_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        data: action.payload,
      };
    case GET_LEADER_BOARD_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        data: {},
      };
    default:
      return state;
  }
};

export default leaderboard;
