import { API_URL } from "../util/apiPaths";
import { request } from "./api.js";

export function getOfferTemplates() {
  return request( "GET", API_URL.GET_PARTNER_OFFER_TEMPLATES );
}

export function addPartnerNewOfferTemplate( params ) {
  return request( "POST", API_URL.OFFER_TEMPLATE, params );
}

export function addPartnerOfferFromScratch( params ) {
  return request( "POST", API_URL.OFFERS, params );
}

export function getPartnerOfferTemplateDetail( params ) {
  return request( "GET", `${ API_URL.OFFERS }/${ params }` );
}

export function getPartnerOffer( params ) {
  return request( "GET", API_URL.GET_PARTNER_OFFER, params );
}

export function updatePartnerOfferTemplate( params ) {
  return request( "PUT", API_URL.OFFER_TEMPLATE, params );
}

export function deletePartnerOfferTemplate( params ) {
  return request( "DELETE", `${ API_URL.OFFERS }/${ params.offerId }`, params );
}

export const partnerOfferServices = {
  getOfferTemplates,
  addPartnerNewOfferTemplate,
  getPartnerOfferTemplateDetail,
  addPartnerOfferFromScratch,
  updatePartnerOfferTemplate,
  deletePartnerOfferTemplate,
};
