import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { readAdminNotificationsAction, readPartnerNotificationsAction, getAdminNotificationsAction, getPartnerNotificationsAction } from "../../../store/actions/notification";
import constant from "../../../util/constant";
import { dateFormat } from "../../../util/notifications.helpers";

const { API_STATUS, NOTIFICATION_API_TYPES } = constant;

const Notifications = () => {
  const dispatch = useDispatch();
  const { partnerId, notifications, isAdmin, apiType, status, partnerDetail, adminDetail } = useSelector( state => ( {
    notifications: state.notification?.notification?.notifications,
    partnerId: state.user.user[ "custom:role" ] === "admin" ? state.user.user.sub : constant.DEFAULT_UUID,
    isAdmin: state.user.user[ "custom:role" ] === "admin",
    apiType: state.notification.apiType,
    status: state.notification.status,
    partnerDetail: state.partner.partnerDetail,
    adminDetail: state.adminDetail,
  } ) );
  const readNotificationsAction = isAdmin ? readAdminNotificationsAction : readPartnerNotificationsAction;

  const getNofications = () => {
    if( isAdmin ) {
      dispatch( getAdminNotificationsAction() );
    }
    else if( partnerId && partnerId !== "0" ) {
      dispatch( getPartnerNotificationsAction( partnerId ) );
    }
  };

  const [ loading, setLoading ] = useState( true );
  const [ unreadNotificationsCount, setunreadNotificationsCount ] = useState( 0 );

  useEffect( () => {
    getNofications();

    const count = isAdmin ? adminDetail.data.unreadNotificationsCount : partnerDetail.unreadNotificationsCount;

    setunreadNotificationsCount( count );
  }, [ ] );

  useEffect( () => {
    if( ( status === API_STATUS.SUCCESS ) && ( apiType === NOTIFICATION_API_TYPES.MARK_READ_NOTIFICATIONS ) ) {
      getNofications();
      setunreadNotificationsCount( 0 );
    }

    if( ( status === API_STATUS.SUCCESS ) && ( apiType === NOTIFICATION_API_TYPES.NOTIFICATIONS_SUCCESS ) ) {
      setLoading( false );
    }
  }, [ apiType, status ] );

  const handleMarkAllRead = () => {
    const unreadNotifications = notifications.filter( n => !n.read ).map( notification => notification.notificationId );

    if( unreadNotifications.length ) {
      dispatch( readNotificationsAction( unreadNotifications ) );
    }
  };

  return (
    <div className='main-wrapper'>
      <div className='main-right-wrapper'>
        <div className='page-head-wrapper'>
          <h4 className='page-heading' >
            Notifications
          </h4>
          {unreadNotificationsCount !== 0 && <a onClick={handleMarkAllRead} className="big-link cursor-pointer" title="Mark All Read" data-testid='notification'>Mark All Read</a>}
        </div>
        <div className='page-head-right w-100'>
          <ul className="notification-container">
            {Boolean( notifications.length ) && notifications.map( ( notification, i ) => {
              return (
                <li key={i} className={notification.read ? "read" : ""}>
                  <span className="notification-name">
                    {
                      notification.iconUrl === "" ? "N" : <img src={notification.iconUrl} alt="notification"/>
                    }
                  </span>
                  <div>
                    <p className='heading-text'>
                      {notification.message}
                    </p>
                    <p className='time'>
                      {dateFormat( notification.dateTimeReceived )}
                    </p>
                  </div>
                </li>
              );
            } )}
            {!loading && !notifications.length && (
              <div className='no-record' data-testid='no-record-found'>
                No Notification Found
              </div>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
