import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import RangeDatePicker from "../../../shared/datePicker/rangeDatePicker";
import MultiSelectCheckbox from "../../../shared/multiSelectCheckbox";

const TxnHistoryFilters = ( {
  addQuery,
  values,
  handleChange,
  locationState,
  itemNames,
  modeNames,
  statusNames,
  handleFilterChange,
  clearSelectedFilter,
} ) => {
  const multiSelectedItems = useMemo( () => [ values.items, values.modes, values.status ] );

  const handleSelect = ( filterName, obj ) => {
    handleFilterChange( filterName, obj, handleChange, addQuery, locationState );
  };

  const onSelectFilterItems = useCallback(
    [ obj => handleSelect( "items", obj ),
      obj => handleSelect( "modes", obj ),
      obj => handleSelect( "status", obj ),
    ],
    []
  );

  const clearAllSelectedOptions = useCallback(
    params => clearSelectedFilter( params, addQuery, handleChange, locationState ), []
  );

  return (
    <>
      <div className="filter-boxes offer-filter mr-0 mb-20">
        <Row>
          <Col md={3}>
            <Form.Group className='custom-datepicker range-picker'>
              <RangeDatePicker
                label='Date of Transaction'
                values={values}
                addQuery={addQuery}
                handleChange={handleChange}
                param={{ key1: "startDate", key2: "endDate", key3: "", key4: "", page: "page" }}
                locationState={locationState}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className='custom-multiselect'>
              <Form.Label>Item</Form.Label>
              <MultiSelectCheckbox
                multiOptionsList={itemNames}
                handleSelectChange={onSelectFilterItems[ 0 ]}
                multiSelectedItems={multiSelectedItems[ 0 ]}
                handleClearMultiSelect={() => clearAllSelectedOptions( "items" )}/>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className='custom-multiselect'>
              <Form.Label>Mode</Form.Label>
              {<MultiSelectCheckbox
                multiOptionsList={modeNames}
                handleSelectChange={onSelectFilterItems[ 1 ]}
                multiSelectedItems={multiSelectedItems[ 1 ]}
                handleClearMultiSelect={() => clearAllSelectedOptions( "modes" )}/>}
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className='custom-multiselect'>
              <Form.Label>Status</Form.Label>
              {<MultiSelectCheckbox
                multiOptionsList={statusNames}
                handleSelectChange={onSelectFilterItems[ 2 ]}
                multiSelectedItems={multiSelectedItems[ 2 ]}
                handleClearMultiSelect={() => clearAllSelectedOptions( "status" )}/>}
            </Form.Group>
          </Col>
        </Row>
      </div>
    </>
  );
};

TxnHistoryFilters.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  addQuery: PropTypes.func,
  locationState: PropTypes.string,
  itemNames: PropTypes.array,
  modeNames: PropTypes.array,
  statusNames: PropTypes.array,
  handleFilterChange: PropTypes.func,
  clearSelectedFilter: PropTypes.func,
};

export default TxnHistoryFilters;
