import VisibilityIcon from "@material-ui/icons/Visibility";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import Tooltip from "react-bootstrap/Tooltip";
import { Scrollbars } from "react-custom-scrollbars";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import constant from "../../../../util/constant";
import { formatNumberAsPerLocale, getEligibleSubscriptionPlans } from "../../../../util/helpers";
import { games } from "../../../../util/icons";
import { ScrollPage } from "../../../shared/customScrollBar";
import OptInDetails from "../../offers/optInDetails";

const { ROUTES, BRANDING: { APP_SHORT_NAME } } = constant;

const LinkedOfferList = ( {
  gamerId,
  linkedOffersList,
  values,
  location,
} ) => {
  const ref = useRef( null );
  const history = useHistory();
  const { configuration } = useSelector( state => ( {
    configuration: state.partner.partnerDetail?.configuration || {},
  } ) );

  const handleViewPage = ( offerId ) => {
    history.push( {
      pathname: ROUTES.PARTNER_GAMER_LINKED_OFFER_DETAIL
        .replace( ":gamerId", gamerId )
        .replace( ":offerId", offerId ),
      state: [ ...location.state,
        ...[ { from: "gamerLinkedOffers", path: location.pathname, state: location.search } ],
      ],
    } );
  };

  return (
    <>
      <Scrollbars renderThumbVertical={ScrollPage} className={`track-horizontal ${ values.viewType === 0 ? "track-horizontal-list" : "" }`}>
        <div className={`games-grid-wrapper ${ values.viewType === 0 ? "games-list-wrapper" : "" } no-scrollbar`}
        >
          {Boolean( linkedOffersList.length ) && linkedOffersList.map( ( offer, index ) => (
            <div className="col-games-grid-box width-50" key={index}>
              <div className="offer-detail-box">
                <div className="offer-detail-box-top grid-only">
                  <div className="image-box">
                    <img src={games} alt="games" />
                  </div>
                  <div className="offer-detail-box-top-body flex-column">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <div className="ribbon-container">
                        <span className={`ribbon badge ${ offer?.statusTitle?.class }`}>{offer?.statusTitle?.title}</span>
                      </div>
                      <strong className='text-truncate'>{offer.offerName}</strong>
                      <div className='icon cursor-pointer'>
                        <VisibilityIcon onClick={() => handleViewPage( offer.offerId )}/>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="left-side">
                        <p>{offer.startDate} - {offer.endDate}</p>
                        <p className="mb-0">{offer.offerProgress}</p>
                      </div>
                      <div className='opt-in-text align-items-start'>
                              Opt In:
                        <OptInDetails
                          optInDetail={offer.optInDetail}
                          eligibleSubPlans={getEligibleSubscriptionPlans( offer.optInDetail.membershipId, configuration.membershipTypes )}
                        />
                      </div>
                      <div className="right-side">
                        <img src={ offer.partnerLogo} alt="Partner Icon" className="partner-image position-static" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between grid-only mt-4 mb-3">
                  <div className="games-tags-list-wrapper my-0 grid-only">
                    <strong>Games: </strong>
                    <div className="games-tags-list">
                      <div className="games-tags">
                        <div className="image-box">
                          <img src={offer.gameIcon} alt="User Icon" />
                        </div>
                        <span>{offer.gameName}</span>
                      </div>
                    </div>
                  </div>
                  <div className="games-desc-text text-right">
                    <strong className='mb-0'>Reward:</strong>
                    <p>{formatNumberAsPerLocale( offer.rewardValue )} { APP_SHORT_NAME } Points</p>
                  </div>
                </div>
                <div className="games-desc-text grid-only" ref={ref}>
                  <strong>Description:</strong>
                  <OverlayTrigger
                    container={ref}
                    placement='bottom'
                    overlay={<Tooltip><p>{offer.descriptionText}</p></Tooltip>}>
                    <p className="text-truncate">{offer.descriptionText}</p>
                  </OverlayTrigger>
                </div>
                <div className="gamer-offer-table-body list-only" ref={ref}>
                  <div className="td"><strong className='line-ellipses'>{offer.offerName}</strong></div>
                  <OverlayTrigger
                    container={ref}
                    placement='bottom'
                    overlay={<Tooltip><p>{offer.descriptionText}</p></Tooltip>}
                  >
                    <div className="td"><p className="line-ellipses">{offer.descriptionText}</p></div>
                  </OverlayTrigger>
                  <div className="td">{offer.gameName}</div>
                  <div className="td">{offer.startDate}</div>
                  <div className="td">{offer.endDate}</div>
                  <div className="td">
                    <span className="opt-in-text">
                      <OptInDetails
                        optInDetail={offer.optInDetail}
                        eligibleSubPlans={getEligibleSubscriptionPlans( offer.optInDetail.membershipId, configuration.membershipTypes )}
                      />
                    </span>
                  </div>
                  <div className='td'>
                    <span className={`badge ${ offer?.statusTitle?.class }`}>{offer?.statusTitle?.title}</span>
                  </div>
                  <div className="td">{offer.offerProgress}</div>
                  <div className="td">
                    <div className='icon cursor-pointer'>
                      <VisibilityIcon onClick={() => handleViewPage( offer.offerId )}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) )}
          {!linkedOffersList.length && (
            <div className='no-record' data-testId='no-record-found'>
                  No Offer Found
            </div>
          )}
        </div></Scrollbars>
    </>
  );
};

LinkedOfferList.propTypes = {
  gamerId: PropTypes.string,
  values: PropTypes.object,
  linkedOffersList: PropTypes.array,
  location: PropTypes.object,
};

export default LinkedOfferList;
