import VisibilityIcon from "@material-ui/icons/Visibility";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import React, { useMemo, useCallback, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Scrollbars } from "react-custom-scrollbars";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useAddQuery, useForm } from "../../../hooks";
import {
  getAdminPartnerGamesAction
} from "../../../store/actions/admin";
import constant from "../../../util/constant";
import { getUrlParameter, useSetUrlParams } from "../../../util/helpers";
import { logoColor } from "../../../util/icons";
import { adminPartnerGameTableConfigs } from "../../../util/tableConstant";
import { ScrollPage } from "../../shared/customScrollBar";
import TableHeaderRow from "../tableHeadingRow";

const { ROUTES, ORDER_BY } = constant;

const GameListing = ( { selectedPartnerId, previousState } ) => {
  const { addQuery } = useAddQuery();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const {
    admin: { partnerGames },
  } = useSelector( state => state );

  const { values, handleChange } = useForm( {
    searchKey: getUrlParameter( "searchKey", null ),
    searchDummyText: getUrlParameter( "searchKey", null ),
    sortBy: "name",
    sortOrder: ORDER_BY.ASC,
  }, {} );
  const requestParamsGames = Object.assign(
    {
      id: selectedPartnerId,
      fetchAllRecords: true,
      sortOrder: values.sortOrder,
      sortBy: values.sortBy,
      // TODO: refactor to remove use of undefined
      // May require a change to API
      // eslint-disable-next-line no-undefined
      searchKey: values.searchKey || undefined,
    }
  );

  const memoizedTableConfig = useMemo( () => {
    return adminPartnerGameTableConfigs;
  }, [ ] );

  const onSorting = ( sortBy, orderType ) => {
    handleChange( "page", 1 );
    handleChange( "sortOrder", orderType );
    handleChange( "sortBy", sortBy );
  };

  const memoizedHandleClick = useCallback(
    ( sortBy, orderType ) => onSorting( sortBy, orderType ),
    []
  );

  useEffect( () => {
    dispatch( getAdminPartnerGamesAction( requestParamsGames ) );
  }, [ values.sortBy, values.searchKey, values.sortOrder ] );

  const searchHandle = ( e ) => {
    const { value: nextValue } = e.target;

    handleChange( "page", 1 );
    addQuery( useSetUrlParams( "searchKey", nextValue ), previousState );
    handleChange( "searchDummyText", nextValue );
    debouncedSave( nextValue );
  };

  const debouncedSave = useCallback(
    debounce( nextValue => handleChange( "searchKey", nextValue ), 1000 ),
    []
  );

  return (
    <>
      {/* Table start from here */}
      <div className='table-container five-column'>
        <div className='absolute-search'>
          <Form.Group className='custom-search w-200'>
            <span className='icon-search'></span>
            <Form.Control
              type='text'
              placeholder= 'Search by Game Name'
              onChange= {searchHandle}
              value= {values.searchDummyText}
              data-testid='searchKey'
            />
          </Form.Group>
        </div>
        <div className='table-head'>
          <div className='table-row'>
            <TableHeaderRow
              configData={memoizedTableConfig}
              onAscOrder={memoizedHandleClick}
              onDescOrder={memoizedHandleClick}
            />
          </div>
        </div>
        <Scrollbars renderThumbVertical={ScrollPage} style={{ height: "calc(100vh - 365px)" }} >
          <div
            className='table-body no-scrollbar'>
            {Boolean( partnerGames?.games.length ) && partnerGames.games.map( ( game, index ) => (
              <div className='table-row' key={index}>
                <div className='td'>
                  <img
                    src={game?.gameIcon ?? logoColor}
                    alt='Game'
                    className='game-image'
                  />
                  <span className='strong'>{game?.gameName}</span>
                </div>
                <div className='td'>{game?.UserGames.length}</div>
                <div className='td'>{game?.offerSchedules.length}</div>

                <div className='td'>
                  {game?.currentStatus}
                </div>
                <div className='td'>
                  <div
                    className='icon cursor-pointer'
                    onClick={() =>
                      history.push( {
                        pathname: `${ ROUTES.ADMIN_GAME_DETAILS.replace(
                          ":gameId",
                          game?.gameId
                        ) }?tab=GENERAL_INFORMATION`,
                        state: [ ...location.state,
                          ...[ { from: "partnersGamesView", path: location.pathname, state: location.search } ],
                        ],
                        backScreenRoute: `${ ROUTES.PARTNER_REQUEST_DETAIL.replace( ":id", selectedPartnerId ) }`,
                      } )
                    }
                  >
                    <VisibilityIcon />
                  </div>
                </div>
              </div>
            ) )}
            {!partnerGames?.games.length && (
              <div className='no-record' data-testid='no-record-found'>
                  No Game Found
              </div>
            )}
          </div>
        </Scrollbars>
      </div>
      {/* end table */}

    </>
  );
};

GameListing.propTypes = {
  selectedPartnerId: PropTypes.string,
  value: PropTypes.object,
  previousState: PropTypes.string,
};

export default GameListing;
