import { forEach } from "lodash";
import constant from "../util/constant";
import { getUrlParamsValue } from "../util/helpers";

const { PARAMS_ARRAY } = constant;

const getUrlData = ( handleChange ) => {
  const paramsQuery = new URLSearchParams( window.location.search );

  forEach( PARAMS_ARRAY, ( data ) => {
    const value = paramsQuery.get( data.key );

    if( value ) {
      const actualValue = getUrlParamsValue( value, data );

      handleChange( data.key, actualValue );
    }
  } );
};

export default getUrlData;
