import PropTypes from "prop-types";
import { useEffect } from "react";
import { withRouter } from "react-router-dom";

const ScrollToTop = ( { children, location: { pathname } } ) => {
  useEffect( () => {
    window.scrollTo( {
      top: 0,
      left: 0,
    } );
  }, [ pathname ] );

  return children || null;
};

ScrollToTop.propTypes = {
  children: PropTypes.array,
  location: PropTypes.object,
};

export default withRouter( ScrollToTop );
