import _ from "lodash";
import moment from "moment";


export const dateFormat = date => (
  moment( date ).format( "DD MMM YYYY | hh:mma" )
);

export const allNotificationIds = notifications => _.map( notifications, "notificationId" );

export const updateNotificationsData = ( oldNotification, newNotification ) => (
  _( oldNotification ).differenceBy( newNotification, "notificationId" )
    .concat( newNotification )
    .value()
);

export const sortNotifications = ( notifications ) => {
  const n = notifications ? notifications : [];

  return _.orderBy( n, ( notification ) => {
    return (
      new Date( notification.dateTimeReceived ) );
  }, [ "desc" ] );
};
