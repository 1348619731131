import { API_URL } from "../util/apiPaths";
import { request } from "./api.js";

export function getPartnerList( param ) {
  return request( "GET", API_URL.GET_PARTNERS_LIST, param );
}

export function getPartnersFilterList( param ) {
  return request( "GET", API_URL.GET_PARTNERS_LIST, param );
}

export function getPartnerDetail( id ) {
  return request( "GET", API_URL.GET_PARTNER_DETAIL + id );
}

export function rejectPartnerRequest( params ) {
  return request( "PUT", API_URL.REJECT_PARTNER, params );
}

export function setAmmendmentsRequest( params ) {
  return request( "POST", API_URL.SET_AMMENDMENTS_REQUEST, params );
}

export function confirmForDocuSign( params ) {
  return request( "PUT", API_URL.CONFIRM_FOR_DOCUSIGN, params );
}

export function resolveAmendment( params ) {
  return request( "PUT", API_URL.RESOLVE_AMENDMENT, params );
}

export function updatePartnerStatus( params ) {
  return request( "PUT", API_URL.UPDATE_PARTNER_STATUS, params );
}

export function approvePartnerManually( params ) {
  return request( "PUT", API_URL.APPROVE_PARTNER_MANUAL_REQUEST, params );
}

export function getAdminFilterList( params ) {
  return request( "GET", API_URL.ADMIN_FILTER_REQUEST, params );
}

export function getAdminLookup( requestObj ) {
  return request( "GET", API_URL.GET_ADMIN_LOOKUP, requestObj );
}

export function getAdminPartnerGames( params ) {
  return request( "GET", API_URL.GET_ADMIN_PARTNER_GAMES.replace(
    ":Id", params.id ), params );
}

export function getAdminLinkedGamerList( partnerId, params ) {
  return request( "GET", API_URL.ADMIN_PARTNER_LINKED_GAMERS.replace( ":partnerId", partnerId ),
    params );
}

export function getAdminLinkedGamerFilterList( partnerId ) {
  return request( "GET", API_URL.ADMIN_PARTNER_GAMERS_FILTER_LIST.replace( ":partnerId", partnerId ) );
}

export const adminServices = {
  getPartnerList,
  getPartnerDetail,
  rejectPartnerRequest,
  confirmForDocuSign,
  resolveAmendment,
  updatePartnerStatus,
  getAdminFilterList,
  getAdminLookup,
  getAdminPartnerGames,
  getAdminLinkedGamerList,
  getAdminLinkedGamerFilterList,
};
