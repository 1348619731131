import PropTypes from "prop-types";
import React from "react";

import constant from "../../../util/constant";
import { useSetUrlParams } from "../../../util/helpers";

const { VIEW_TYPE } = constant;

// values: containing a viewType property that specifies the current view type (grid: 1 or list: 0).
const ViewType = ( {
  values,
  addQuery,
  handleChange,
  locationState,
} ) => {
  const changeViewType = ( type ) => {
    handleChange( "viewType", type );
    addQuery( useSetUrlParams( "viewType", type ), locationState );
  };

  return (
    <>
      <div className='grid-buttons'>
        <span
          className={values.viewType ? "icon-grid-view active" : "icon-grid-view "}
          onClick={() => changeViewType( VIEW_TYPE.GRID )}/>
        <span
          className={!values.viewType ? "icon-list-view active" : "icon-list-view "}
          onClick={() => changeViewType( VIEW_TYPE.LIST )}/>
      </div>
    </>
  );
};

ViewType.propTypes = {
  values: PropTypes.object,
  addQuery: PropTypes.func,
  handleChange: PropTypes.func,
  locationState: PropTypes.string,
};

export default ViewType;
