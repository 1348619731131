import { API_URL } from "../util/apiPaths";
import { request } from "./api.js";

export function getPartnerDraftDetail( params ) {
  return request( "GET", `${ API_URL.PARTNER_DRAFT }/${ params }` );
}

export function addPartnerDraftDetail( params ) {
  return request( "POST", API_URL.PARTNER_DRAFT, params );
}

export function updatePartnerDraftDetail( params ) {
  return request( "PUT", API_URL.PARTNER_DRAFT, params );
}

export function deletePartnerDraftDetail( params ) {
  return request( "DELETE", API_URL.PARTNER_DELETE_DRAFT.replace(
    ":Id", params ), params );
}

export const partnerDraftServices = {
  getPartnerDraftDetail,
  addPartnerDraftDetail,
  updatePartnerDraftDetail,
  deletePartnerDraftDetail,
};
