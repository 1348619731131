import PropTypes from "prop-types";
import React from "react";
import { getAppVersion } from "../../../util/versionHelper";

const AppVersion = ( props ) => {
  return (
    <>
      <div className="version">
        <p>{ getAppVersion( props.serverVersion ) }</p>
      </div>
    </>
  );
};

AppVersion.propTypes = {
  serverVersion: PropTypes.string,
};

export default AppVersion;
