// component's config object.
import constant from "../util/constant";

const { ROUTES } = constant;

const components = {
  admin: [
    {
      component: "AdminDashboard",
      url: "/admin",
    },
    {
      component: "PartnerList",
      url: ROUTES.ADMIN_PARTNER_LIST,
    },
    {
      component: "PartnerRequestDetail",
      url: ROUTES.PARTNER_REQUEST_DETAIL,
    },
    {
      component: "GameList",
      url: ROUTES.ADMIN_GAME_LIST,
    },
    { component: "GameDetails", url: ROUTES.ADMIN_GAME_DETAILS },
    { component: "AdminEventList", url: ROUTES.ADMIN_EVENT_LIST },
    { component: "AddEditAdminEvent", url: ROUTES.ADMIN_ADD_EDIT_EVENT },
    { component: "AdminEventDetail", url: ROUTES.ADMIN_EVENT_DETAIL },
    { component: "AdminUserList", url: ROUTES.ADMIN_USER_LIST },
    { component: "AddEditAdminUser", url: ROUTES.ADMIN_ADD_EDIT_USER },
    { component: "AdminOfferList", url: ROUTES.ADMIN_OFFER_LIST },
    { component: "AddEditAdminOffer", url: ROUTES.ADMIN_ADD_EDIT_OFFER },
    { component: "AdminOfferDetail", url: ROUTES.ADMIN_OFFER_DETAILS },
    { component: "Notifications", url: ROUTES.ADMIN_NOTIFICATIONS },
    { component: "AdminSettings", url: ROUTES.ADMIN_SETTING },
    { component: "NotFound", url: ROUTES.ADMIN_NOT_FOUND },
    { component: "GamerList", url: ROUTES.GAMER_LIST },
    { component: "GamerDetail", url: ROUTES.GAMER_DETAIL },
    { component: "GamerGameDetail", url: ROUTES.GAMER_GAME_DETAILS },
    { component: "GamerSection", url: ROUTES.GAMER_SECTION },
    { component: "TransactionList", url: ROUTES.TRANSACTION_LIST },
    { component: "RedeemTxDetail", url: ROUTES.REDEEM_TRANSACTION_DETAIL },
    { component: "AdminGamerOffers", url: ROUTES.GAMER_OFFER_LIST },
    { component: "GamerExchangeList", url: ROUTES.GAMER_EXCHANGE_TRANSACTION },
    { component: "RedeemTransactions", url: ROUTES.REDEEM_TRANSACTIONS },
    { component: "Assets", url: ROUTES.GAMER_ASSETS },
    { component: "AdminTournamentList", url: ROUTES.ADMIN_TOURNAMENTS_LIST },
    { component: "AdminTournamentDetail", url: ROUTES.ADMIN_TOURNAMENT_DETAILS },
    { component: "AdminGamerOffers", url: ROUTES.ADMIN_GAMER_TOURNAMENTS },
    { component: "AdminTournamentDetail", url: ROUTES.ADMIN_GAMER_TOURNAMENT_DETAILS },
    { component: "AdminOfferDetail", url: ROUTES.ADMIN_GAMER_LINKED_OFFER_DETAILS },
    { component: "AdminLeaderboardAllParticipants", url: ROUTES.ADMIN_GAMER_LEADERBOARD_PARTICIPANTS },
    { component: "AdminLeaderboardAllParticipants", url: ROUTES.ADMIN_LEADERBOARD_PARTICIPANTS },
    { component: "AdminOfferDetail", url: ROUTES.ADMIN_GAMER_LINKED_GAMES_OFFER_DETAIL },
    { component: "AddEditAdminOffer", url: ROUTES.ADMIN_ADD_EDIT_TOURNAMENT },
    { component: "TransactionHistory", url: ROUTES.ADMIN_GAMER_TRANSACTION_HISTORY },
  ],
  partner: [
    {
      component: "PartnerDashboard",
      url: "/partner",
    },
    {
      component: "GeneralInfo",
      url: ROUTES.PARTNER_GENERAL_INFO,
    },
    {
      component: "ContactInfo",
      url: ROUTES.PARTNER_CONTACT_INFO,
    },
    {
      component: "OtherInfo",
      url: ROUTES.PARTNER_OTHER_INFO,
    },
    {
      component: "TermsConditions",
      url: ROUTES.PARTNER_TERMS_CONDITIONS,
    },
    {
      component: "Docusign",
      url: ROUTES.PARTNER_DOCUSIGN,
    },
    {
      component: "ProfilePending",
      url: ROUTES.PARTNER_PROFILE_PENDING,
    },
    {
      component: "DocuSignPending",
      url: ROUTES.PARTNER_DOCUSIGN_PENDING,
    },
    {
      component: "PartnerGameList",
      url: ROUTES.PARTNER_GAMES,
    },
    {
      component: "ProfileRejected",
      url: ROUTES.PARTNER_PROFILE_REJECTED,
    },
    { component: "AddEditGame", url: ROUTES.PARTNER_ADD_EDIT_GAME },
    { component: "PartnerEventList", url: ROUTES.PARTNER_EVENT_LIST },
    { component: "AddEditEvent", url: ROUTES.PARTNER_ADD_EDIT_EVENT },
    { component: "PartnerOfferList", url: ROUTES.PARTNER_OFFER_LIST },
    { component: "CreateAndEditOffer", url: ROUTES.PARTNER_CREATE_AND_EDIT_OFFER },
    { component: "PartnerOfferDetail", url: ROUTES.PARTNER_OFFER_DETAIL },
    { component: "Notifications", url: ROUTES.PARTNER_NOTIFICATIONS },
    { component: "PartnerSettings", url: ROUTES.PARTNER_SETTING },
    { component: "PartnerEventDetail", url: ROUTES.PARTNER_EVENT_DETAIL },
    { component: "ProfileDisabled", url: ROUTES.PARTNER_PROFILE_DISABLED },
    { component: "Analytics", url: ROUTES.PARTNER_ANALYTICS },
    { component: "ViewGame", url: ROUTES.ANALYTIC_VIEW_GAME },
    { component: "PartnerOfferList", url: ROUTES.PARTNER_TOURNAMENTS },
    { component: "CreateAndEditTournament", url: ROUTES.PARTNER_CREATE_EDIT_TOURNAMENT },
    { component: "PartnerOfferDetail", url: ROUTES.PARTNER_TOURNAMENT_DETAIL },
    { component: "AssetsList", url: ROUTES.PARTNER_ANALYTICS_GAMER_ASSETS },
    { component: "PartnerGamerList", url: ROUTES.PARTNER_GAMER_LIST },
    { component: "PartnerGamerSection", url: ROUTES.PARTNER_GAMER_SECTION },
    { component: "PartnerGamerTournaments", url: ROUTES.PARTNER_GAMER_TOURNAMENTS },
    { component: "PartnerOfferDetail", url: ROUTES.PARTNER_GAMER_TOURNAMENT_DETAILS },
    { component: "PartnerLeaderboardAllParticipants", url: ROUTES.PARTNER_GAMER_LEADERBOARD_PARTICIPANTS },
    { component: "PartnerLeaderboardAllParticipants", url: ROUTES.PARTNER_LEADERBOARD_PARTICIPANTS },
    { component: "PartnerGamerAssets", url: ROUTES.PARTNER_GAMER_ASSETS },
    { component: "LinkedGames", url: ROUTES.PARTNER_GAMER_LINKED_GAMES },
    { component: "GamerLinkedGameDetail", url: ROUTES.PARTNER_GAMER_LINKED_GAME_DETAIL },
    { component: "PartnerOfferDetail", url: ROUTES.PARTNER_LINKED_GAMES_OFFER_DETAIL },
    { component: "PartnerGamerExchangeTransaction", url: ROUTES.PARTNER_GAMER_EXCHANGE_TRANSACTION },
    { component: "LinkedOffers", url: ROUTES.PARTNER_GAMER_LINKED_OFFERS },
    { component: "PartnerOfferDetail", url: ROUTES.PARTNER_GAMER_LINKED_OFFER_DETAIL },
  ],
};

const rolesConfig = {
  admin: {
    routes: components.admin,
  },
  partner: {
    routes: components.partner,
  },
  common: {
    routes: [],
  },
};

export { rolesConfig };
