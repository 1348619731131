import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Route, useHistory } from "react-router-dom";
import Header from "../components/shared/header";
import Navbar from "../components/shared/navbar";
import constant from "../util/constant";
import ScrollToTop from "./scrollToTop";

const { ROUTES } = constant;

const NavRoute = ( { exact, path, component: Component, replacePath } ) => {
  const history = useHistory();
  const [ toggleDrawer, setToggleDrawer ] = useState( true );

  useEffect( () => {
    if( replacePath ) {
      history.replace( replacePath );
    }
  }, [ replacePath ] );

  const toggleDrawerAction = () => {
    setToggleDrawer( ( toggleDrawer !== true ) );
  };

  const handleToggleDrawer = () => {
    if( !toggleDrawer ) {
      return "navbar-slider";
    }

    return "";
  };

  return (
    <Route
      exact={exact}
      key={path}
      path={path}
      render={props =>
        ( path === ROUTES.PARTNER_DASHBOARD
          ? (
            <Component {...props} />
          )
          : (
            <div className={ `${ handleToggleDrawer() }`}>
              <ScrollToTop>
                <Navbar toggleDrawerAction={toggleDrawerAction} toggleDrawer={toggleDrawer} />
                <Header />
                <Component {...props} />
              </ScrollToTop>
            </div>
          ) )
      }
    />
  );
};

NavRoute.propTypes = {
  exact: PropTypes.bool,
  path: PropTypes.string,
  component: PropTypes.func,
  replacePath: PropTypes.string,
};

export default NavRoute;
