import { API_URL } from "../util/apiPaths";
import { request } from "./api.js";

export const getAdminNotifications = ( params ) => {
  return request( "GET", API_URL.GET_ADMIN_NOTIFICATION, params );
};

export const getPartnerNotifications = ( params ) => {
  return request( "GET", API_URL.GET_PARTNER_NOTIFICATION, params );
};

export const readPartnerNotifications = ( params ) => {
  return request( "PUT", API_URL.READ_PARTNER_NOTIFICATION, params );
};

export const readAdminNotifications = ( params ) => {
  return request( "PUT", API_URL.READ_ADMIN_NOTIFICATION, params );
};

export const notificationService = {
  getAdminNotifications, getPartnerNotifications,
};

