export class AdminGamerDetails {
  constructor( obj ) {
    this.acqyrCashBalance = obj.acqyrCashBalance;
    this.fullNames = obj.fullName || "N/A";
    this.userId = obj.userId || "";
    this.linkedGamesCount = obj.LinkedGamesCount;
    this.linkedGames = obj?.LinkedGames?.length
      ? obj?.LinkedGames?.map( ( val ) => {
        return {
          gameId: val?.game_id || "",
          gameName: val?.Games?.name || "",
          iconUrl: val?.Games?.icon_url || "",
        };
      } ).sort( ( a, b ) => a.gameName.localeCompare( b.gameName ) )
      : [];
    this.gamerPlan = obj?.planName || "";
  }
}

