import { useState } from "react";

const useModal = () => {
  const [ isOpened, setIsOpened ] = useState( false );
  const [ modalType, setModalType ] = useState();
  const [ modalData, setModalData ] = useState( {} );

  const openModal = () => setIsOpened( true );

  const closeModal = () => {
    setIsOpened( false );
    setModalData( {} );
  };

  return { isOpened, openModal, closeModal, modalType, setModalType, modalData, setModalData };
};

export default useModal;
