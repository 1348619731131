import React from "react";
import { docusignRequired } from "../../../util/icons";

const DocuSignPending = () => (
  <>
    <div className='main-wrapper'>
      <div className='main-right-wrapper'>
        <div className='profile-pending-box'>
          <div className='left-side'>
            <h2>DocuSign Required!</h2>
            <p>
              Your application has been reviewed from admin and to complete please
              sign the DocuSign document.
            </p>
          </div>
          <div className='right-side'>
            <img src={docusignRequired} alt='Application Pending' />
          </div>
        </div>
      </div>
    </div>
  </>
);

export default DocuSignPending;
