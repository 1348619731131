import { orderBy } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useAddQuery, useForm, useFilter, getUrlData } from "../../../hooks";
import { getExchangeTransactionAction } from "../../../store/actions/partnerAnalytics";
import constant from "../../../util/constant";
import { useSetUrlParams } from "../../../util/helpers";
import { analyticsExchangeTableConfigs } from "../../../util/tableConstant";
import { ScrollPage } from "../../shared/customScrollBar";
import TableHeaderRow from "../../shared/tableHeadingRow";
import AnalyticsExchangeFilterList from "./analyticsExchangeFilterList";

const { API_STATUS, BRANDING: { APP_SHORT_NAME } } = constant;

const ExchangeList = ( {
  searchKey = "",
  setPreviousData,
  showFilter,
  gameId = "",
  gameName = "",
  tab,
} ) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { addQuery } = useAddQuery();
  const { setPureData, filteredData, filterData } = useFilter();
  const {
    exchangeTransaction,
    apiType,
    status,
  } = useSelector( state => ( {
    exchangeTransaction: state.partnerAnalytics.exchangeTransaction,
    apiType: state.partnerAnalytics.apiType,
    status: state.partnerAnalytics.status,
  } ) );
  const isGameId = Boolean( gameId );
  const { transaction, gameNames, assetNames, gamerNames, qxPointsRange } = exchangeTransaction;

  const { values, handleChange } = useForm(
    {
      searchKey: "",
      sortBy: "",
      sortOrder: "",
      gamerName: [],
      gameIds: [],
      assetName: [],
      minQxPoint: 0,
      maxQxPoint: 0,
      startDate: null,
      endDate: null,
      searchCol: [ "name", "gamerName", "assetName" ],
      compareKeyArray: [ "QXPoints", "gamerName", "name" ],
    },
    {}
  );

  const requestParams = Object.assign(
    {
      isSingleDate: true,
      searchCol: values.searchCol,
      sortOrder: values.sortOrder,
      sortBy: values.sortBy,
      searchKey: values.searchKey,
      gamerName: values.gamerName?.map( value => value.value ) || [],
      gameIds: values.gameIds?.map( value => value.value ) || [],
      assetName: values.assetName?.map( value => value.value ) || [],
      minQxPoint: values.minQxPoint,
      maxQxPoint: values.maxQxPoint,
      startDate: values.startDate,
      endDate: values.endDate,
      compareKeyArray: values.compareKeyArray,
    }
  );

  const handleSorting = ( sortBy, orderType ) => {
    handleChange( "sortBy", sortBy );
    handleChange( "sortOrder", orderType );
    addQuery( useSetUrlParams( "sortBy", sortBy ), location.state, isGameId );
    addQuery( useSetUrlParams( "sortOrder", orderType ), location.state, isGameId );
  };

  const memoizedHandleClick = useCallback(
    ( sortBy, orderType ) => handleSorting( sortBy, orderType ),
    []
  );

  useEffect( () => {
    dispatch( getExchangeTransactionAction( { type: "exchangeTxn" } ) );
  }, [] );

  useEffect( () => {
    if( gameId && gameName ) {
      const selectedGame = { label: gameName, value: gameName };

      handleChange( "gameIds", [ selectedGame ] );
      addQuery( useSetUrlParams( "gameIds", JSON.stringify( [ selectedGame ] ) ), location.state, isGameId );
    }
  }, [ gameId, gameName ] );

  useEffect( () => {
    if(
      status === API_STATUS.SUCCESS
    ) {
      const sortTransactionList = orderBy( transaction, [ "date" ], [ "desc" ] );

      handleChange( "minQxPoint", qxPointsRange.min );
      handleChange( "maxQxPoint", qxPointsRange.max );
      setPureData( sortTransactionList );
      getUrlData( handleChange );
      setPreviousData( false );
    }
  }, [ status, apiType ] );

  const memoizedTableConfig = useMemo( () => {
    return analyticsExchangeTableConfigs;
  }, [] );

  useEffect( () => {
    filterData( requestParams );
  }, [
    values.sortOrder,
    values.sortBy,
    values.searchKey,
    values.gamerName,
    values.gameIds,
    values.assetName,
    values.minQxPoint,
    values.maxQxPoint,
    values.startDate,
    values.endDate,
  ] );

  useEffect( () => {
    handleChange( "searchKey", searchKey );
  }, [ searchKey ] );

  return ( <>
    <div className={`filter-tabs-wrapper mb-0 ${ !showFilter ? "" : "remove-filter" }`}>
      <AnalyticsExchangeFilterList
        values={values}
        handleChange={handleChange}
        addQuery={addQuery}
        gamerNames={gamerNames}
        gameNames={gameNames}
        assetNames={assetNames}
        transactionRanges={qxPointsRange}
        isGameId={isGameId}
        locationState={location.state}
        tab={tab}
      />
    </div>
    <div className="table-container partner-analytics-transaction">
      <div className="table-head">
        <div className="table-row">
          <TableHeaderRow
            configData={memoizedTableConfig}
            onAscOrder={memoizedHandleClick}
            onDescOrder={memoizedHandleClick}
          />
        </div>
      </div>
      <Scrollbars renderThumbVertical={ScrollPage}
        className={`custom-scroll-height max ${ !showFilter ? "" : "full-height" }`}
      >
        <div className="table-body no-scrollbar" >
          {Boolean( filteredData.length ) && filteredData.map( ( txn, index ) => (
            <div className="table-row" key={index}>
              <div className="td">
                <span className='text-green font-medium'>
                  {txn.name}
                </span>
              </div>
              <div className="td">{txn.QXPoints}</div>
              <div className="td"><span className='text-green font-medium'>{txn.soldAssetQuantity}</span></div>
              <div className="td multiline-text">
                <span className='d-block'>{txn.assetName} ({txn.unitPriceQxPoints} { APP_SHORT_NAME } Points each)</span>
              </div>
              <div className="td">{txn.transactionDate}</div>
              <div className="td">{txn.gamerName}</div>
            </div>
          ) )}
          {!filteredData.length && (
            <div className='no-record' data-testid='no-record-found'>
              No Exchange Transaction Found
            </div>
          )}
        </div>
      </Scrollbars>
    </div>
  </>
  );
};

ExchangeList.propTypes = {
  searchKey: PropTypes.string,
  gameId: PropTypes.string,
  setPreviousData: PropTypes.func,
  gameName: PropTypes.string,
  showFilter: PropTypes.string,
  tab: PropTypes.string,
};

export default ExchangeList;
