import PropTypes from "prop-types";
import React from "react";
import { defaultUser } from "../../../util/icons";

const LeaderboardGamerRow = ( { gamerPlacesDetails } ) => {
  return (
    <>
      <div className='leader-box-container'>
        {gamerPlacesDetails.map( gamer => (
          <div className='leader-box' key={gamer.rank}>
            <div className='leader-content'>
              <img src={gamer.gamer_profile} alt="Gamer picture" className="leader-image" />
              <img src={defaultUser} alt="First gamer picture" className="leader-image default" />
              <span className='leader-text' title={gamer.name}>{gamer.name}</span>
            </div>
            <div className='badge badge-sqaure'>{gamer.rank}</div>
          </div>
        ) )}
      </div>
    </>
  );
};

LeaderboardGamerRow.propTypes = {
  gamerPlacesDetails: PropTypes.array,
};

export default LeaderboardGamerRow;
