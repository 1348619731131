import PropTypes from "prop-types";
import React from "react";
import constant from "../../../util/constant";
import { currencyFormat } from "../../../util/helpers";

const { NUMBER_FORMATTING, BRANDING: { APP_SHORT_NAME } } = constant;

const AssetValueInfo = ( {
  qxPoints,
  usdPerQxPoint,
  partnerCostUsdPerQxPoint,
} ) => {
  const getAssetCostAndValue = ( points ) => {
    const gamerValue = currencyFormat( { data: usdPerQxPoint * points } );
    const gamerValueString = `Gamer value: ${ gamerValue } (${ usdPerQxPoint } per ${ APP_SHORT_NAME } point)`;

    const partnerCost = currencyFormat( {
      data: partnerCostUsdPerQxPoint * points,
      minimumFractionDigits: NUMBER_FORMATTING.PARTNER_COST_DECIMAL_PLACES,
    } );

    const formattedPartnerCostUsdPerQxPoint = currencyFormat( {
      data: partnerCostUsdPerQxPoint,
      minimumFractionDigits: NUMBER_FORMATTING.PARTNER_COST_DECIMAL_PLACES,
    } );

    const partnerCostString = `Partner cost: ${ partnerCost } (${ formattedPartnerCostUsdPerQxPoint } per ${ APP_SHORT_NAME } point)`;

    return `${ gamerValueString } ; ${ partnerCostString }`;
  };

  return (
    <>
      <span className='form-text'>
        {getAssetCostAndValue( qxPoints )}
      </span>
    </>
  );
};

AssetValueInfo.propTypes = {
  qxPoints: PropTypes.number,
  usdPerQxPoint: PropTypes.number,
  partnerCostUsdPerQxPoint: PropTypes.number,
};

export default AssetValueInfo;
