import PropTypes from "prop-types";
import React from "react";
import Modal from "react-bootstrap/Modal";
import adminConstant from "../../../util/adminConstant";
import { ModalFooter } from "../../shared/customModal";
import DocuSign from "./docuSign";
import ReasonRejection from "./reasonRejection";
import SuggestModification from "./suggestModification";

const { MODAL_TYPE_DETAIL } = adminConstant;

const ModalPopup = ( {
  isOpened,
  onClose,
  type,
  onConfirm,
  onChangeInput,
  values = {},
  errorMessage,
} ) => {
  const modalData = MODAL_TYPE_DETAIL[ type ] || {
    headerText: "",
    cancelText: "",
    confirmText: "",
    className: "",
  };

  return (
    <Modal show={isOpened} centered className={modalData.className}>
      <Modal.Header>
        <h2 className='modal-heading'>{modalData.headerText}</h2>
      </Modal.Header>
      {
        {
          REASON_REJECTION: (
            <ReasonRejection
              onChange={onChangeInput}
              rejectionReason={values.rejectionReason}
              errorMessage={errorMessage}
            />
          ),
          DOCUSIGN: (
            <DocuSign
              onChange={onChangeInput}
              rejectionReason={values.envelopeId}
              errorMessage={errorMessage}
            />
          ),
          ACTIVE_PARTNER: (
            <Modal.Body>
              <p>Are you sure you want to deactivate this partner?</p>
            </Modal.Body>
          ),
          INACTIVE_PARTNER: (
            <Modal.Body>
              <p>Are you sure you want to activate this partner?</p>
            </Modal.Body>
          ),
          APPROVE_PARTNER_MANUALLY: (
            <Modal.Body>
              <p>Are you sure you want to approve the partner manually?</p>
            </Modal.Body>
          ),
          ACCEPT_REDEEM_REQUEST: (
            <Modal.Body>
              <p>Are you sure you want to accept this request?</p>
            </Modal.Body>
          ),
          SUGGEST_MODIFICATION_GAME: (
            <SuggestModification
              onChange={onChangeInput}
              testingComments={values.testingComments}
              errorMessage={errorMessage}
            />
          ),
          APPROVE_GAME: (
            <Modal.Body>
              <p>Are you sure you want to approve this game?</p>
            </Modal.Body>
          ),
          APPROVE_OFFER: (
            <Modal.Body>
              <p>Are you sure you want to approve this offer?</p>
            </Modal.Body>
          ),
          APPROVE_TOURNAMENT: (
            <Modal.Body>
              <p>Are you sure you want to approve this tournament?</p>
            </Modal.Body>
          ),
          REJECT_GAME: (
            <Modal.Body>
              <p>Are you sure you want to reject this game?</p>
            </Modal.Body>
          ),
          REJECT_REDEEM_TRANSACTION: (
            <ReasonRejection
              onChange={onChangeInput}
              rejectionReason={values.rejectionReason}
              errorMessage={errorMessage}
            />
          ),
        }[ type ]
      }
      <ModalFooter
        onClose={onClose}
        onConfirm={onConfirm}
        cancelText={modalData.cancelText}
        confirmText={modalData.confirmText}
      />
    </Modal>
  );
};

ModalPopup.propTypes = {
  isOpened: PropTypes.string,
  onClose: PropTypes.func,
  type: PropTypes.string,
  onConfirm: PropTypes.func,
  onChangeInput: PropTypes.func,
  values: PropTypes.object,
  errorMessage: PropTypes.string,
};

export default ModalPopup;
