import constant from "../../util/constant";
import {
  PARTNER_EVENT_API_REQUEST,
  PARTNER_EVENT_API_END,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAILURE,
  GET_PARTNER_EVENTS_SUCCESS,
  GET_PARTNER_EVENTS_FAILURE,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAILURE,
  GET_PARTNER_EVENT_DETAIL_SUCCESS,
  GET_PARTNER_EVENT_DETAIL_FAILURE
} from "../actionTypes";

const { API_STATUS } = constant;

const DEFAULT_STATE = {
  loading: false,
  message: "",
  status: "",
  apiType: "",
  partnerEvents: { events: [], meta: "" },
  partnerEventDetail: {},
};

const partnerEvent = ( state = DEFAULT_STATE, action = "" ) => {
  switch ( action.type ) {
    case PARTNER_EVENT_API_REQUEST:
      return {
        ...state,
        status: API_STATUS.START,
        apiType: action.payload,
      };
    case PARTNER_EVENT_API_END:
      return {
        ...state,
        status: API_STATUS.END,
        apiType: "",
      };
    case ADD_EVENT_SUCCESS:
    case UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
      };
    case ADD_EVENT_FAILURE:
    case GET_PARTNER_EVENT_DETAIL_FAILURE:
    case UPDATE_EVENT_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
      };
    case GET_PARTNER_EVENTS_FAILURE:
      return {
        ...state,
        status: API_STATUS.FAILURE,
        partnerEvents: { events: [], meta: "" },
      };
    case GET_PARTNER_EVENTS_SUCCESS:
      return {
        ...state,
        partnerEvents: {
          events:
            action.payload.meta.page === 1
              ? action.payload.dataList
              : [ ...state.partnerEvents.events, ...action.payload.dataList ],
          meta: action.payload.meta,
        },
        status: API_STATUS.SUCCESS,
      };
    case GET_PARTNER_EVENT_DETAIL_SUCCESS:
      return {
        ...state,
        status: API_STATUS.SUCCESS,
        partnerEventDetail: action.payload,
      };
    default:
      return state;
  }
};

export default partnerEvent;
