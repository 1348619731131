import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PropTypes from "prop-types";
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import adminConstant from "../../../util/adminConstant";
import offerConstant from "../../../util/offerConstant";

const { TYPE_TO_BE_EDITED, OFFER_DETAIL_STATUS } = offerConstant;
const { QX_ADMIN_ID } = adminConstant;

const TournamentOptions = ( {
  offer,
  marginZero,
  OnClickView,
  onClickEdit,
  onClickDelete,
  onClickClone,
} ) => {
  const canPartnerEditOrDelete = ( ( ( offer?.offerType === TYPE_TO_BE_EDITED.TOURNAMENT_DRAFT ) ||
    ( offer?.offerType === TYPE_TO_BE_EDITED.TOURNAMENT_TEMPLATE ) ) &&
    ( offer?.partnerId !== QX_ADMIN_ID ) );
  const cloneableTournaments = [ OFFER_DETAIL_STATUS.IN_REVIEW, OFFER_DETAIL_STATUS.APPROVED, OFFER_DETAIL_STATUS.REJECTED, OFFER_DETAIL_STATUS.DRAFT
    , OFFER_DETAIL_STATUS.OFFER_TEMPLATE, OFFER_DETAIL_STATUS.EXPIRED, OFFER_DETAIL_STATUS.DISABLED, OFFER_DETAIL_STATUS.CURRENT ];

  return (
    <Dropdown className="custom-dropdown more-dropdown">
      <Dropdown.Toggle variant="success" id="" className={marginZero ? "m-0" : ""}>
        <MoreVertIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu align='right' className="three-dropdown-item" >
        <div className={ ( !canPartnerEditOrDelete ) ? "disabled" : ""}>
          <Dropdown.Item onClick={onClickEdit}><span className="rounded"><EditIcon /></span> <span>Edit</span></Dropdown.Item>
        </div>
        <div className={ ( !cloneableTournaments.includes( offer?.approvalStatus ) ) ? "disabled" : ""}>
          <Dropdown.Item onClick={onClickClone}><FileCopyIcon /> <span>Clone</span></Dropdown.Item>
        </div>
        <Dropdown.Item onClick={OnClickView}><VisibilityIcon /> <span>View</span></Dropdown.Item>
        <div className={ ( !canPartnerEditOrDelete ) ? "disabled" : ""}>
          <Dropdown.Item onClick={onClickDelete}><DeleteIcon /> <span>Delete</span></Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

TournamentOptions.propTypes = {
  offer: PropTypes.object,
  marginZero: PropTypes.string,
  OnClickView: PropTypes.func,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
  onClickClone: PropTypes.func,
};

export default TournamentOptions;
