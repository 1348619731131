const adminPartnerConstant = {
  TABS: {
    OFFERS: "OFFERS",
    GAMES: "GAMES",
    INFO: "INFO",
    LINKED_GAMERS: "LINKED_GAMERS",
  },
};

export default adminPartnerConstant;
