import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import CanvasJSReact from "../../../../assets/canvasJS/canvasjs.react";

const { CanvasJSChart } = CanvasJSReact;

const Graph = ( { options } ) => {
  return (
    <>
      <div className="tag-image">
        { !isEmpty( options )
          ? (
            <>
              <CanvasJSChart options = {options} />
            </>
          )
          : <div className="no-record">No Record Found</div> }
      </div>
    </>
  );
};

Graph.propTypes = {
  options: PropTypes.object,
};

export default Graph;
