import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import profilePending from "../../../assets/images/profile-pending.png";
import constant from "../../../util/constant";

const { APPLICATION_STATUS, ROUTES, SECTION_NAME } = constant;

const ProfilePending = () => {
  const history = useHistory();
  const { partnerDetail = {} } = useSelector( state => state.partner );
  const { partnerAmendmentDetails = [] } = partnerDetail;

  return (
    <>
      <div className='main-wrapper'>
        <div className='main-right-wrapper'>
          <div className='profile-pending-box'>
            <div className='left-side'>
              <h2>Application Pending</h2>
              <p>
                Your application is under review from admin. Please wait and allow
                admin to approve the account
              </p>
            </div>
            <div className='right-side'>
              <img src={profilePending} alt='Application Pending' />
            </div>
          </div>
          {partnerDetail?.applicationStatus ===
            APPLICATION_STATUS.AMENDMENTS_SUGGESTED && (
            <div className='heading-with-textarea-outer-wrapper'>
              <div className='heading-with-textarea-wrapper'>
                <strong>Suggested Changes</strong>
                <Form.Group>
                  <div className='tc-content scrollbar'>
                    {partnerAmendmentDetails.map( suggestion => (
                      <div key={suggestion}>
                        <strong className='bold-heading'>
                          {SECTION_NAME[ Number( suggestion.section_name ) - 1 ]}
                        </strong>
                        <p>{suggestion.review_note}</p>
                        <br />
                      </div>
                    ) )}
                  </div>
                </Form.Group>
                <div className='btn-wrapper'>
                  <Button
                    variant='primary'
                    onClick={() => history.push( ROUTES.PARTNER_GENERAL_INFO )}
                  >
                    Update
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfilePending;
