import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import { useSelector, useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useModal, useForm } from "../../../hooks";
import {
  getAdminGameDetailsAction,
  addAdminModificationAction,
  updatePartnerGameStatusAction,
  enableDisableGameAction
} from "../../../store/actions/adminGames";
import adminConstant from "../../../util/adminConstant";
import adminGameConstant from "../../../util/adminGameConstant";
import adminUserConstant from "../../../util/adminUserConstant";
import constant from "../../../util/constant";
import { getBackNavigationRoute } from "../../../util/helpers";
import BackButton from "../../shared/backButton";
import { GameDetail, GameCommentSection } from "../../shared/games";
import ModalPopup from "../modal";
import GameGamerListing from "./gameGamerListing";

const { API_STATUS, ROUTES } = constant;
const { MODAL_TYPE_DETAIL } = adminConstant;
const { ADMIN_GAME_API_TYPES, ADMIN_GAME_STATUS_TYPE, TABS } = adminGameConstant;
const { ADMIN_PERMISSIONS } = adminUserConstant;

const initialState = {
  comment: "",
  tab: "",
};

// TODO: refactor to avoid complexity
// eslint-disable-next-line complexity
const GameDetails = ( props ) => {
  const { gameId: selectedGameID } = useParams();
  const { 0: gameId } = String( selectedGameID ).split( "?" );
  const paramsQuery = new URLSearchParams( window.location.search );
  const activeTab = paramsQuery.get( "tab" );
  const fromScreen = paramsQuery.get( "from" );
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const backScreens = [ "partnersGamesView", "partnerOffersGame", "games", "offers", "tournaments" ];
  const unAllowStateBackScreens = [ "games", "offers", "tournaments" ];
  const { gameDetails, apiType, status, msg, adminDetail } = useSelector(
    state => ( {
      gameDetails: state.adminGame.gameDetails,
      apiType: state.adminGame.apiType,
      status: state.adminGame.status,
      msg: state.adminGame.msg,
      adminDetail: state.adminDetail.data,
    } )
  );
  const { GameTestingComments = [] } = gameDetails;
  const [ viewType, setViewType ] = useState( true );
  const [ errorMessage, setErrorMessage ] = useState( "" );
  const [ commentError, setCommentError ] = useState();
  const { isOpened, closeModal, openModal, modalType, setModalType } =
    useModal();
  const { values, handleChange } = useForm( initialState, {} );

  useEffect( () => {
    if( gameId ) {
      dispatch( getAdminGameDetailsAction( gameId ) );
    }
  }, [ gameId, msg ] );
  useEffect( () => {
    if(
      status === API_STATUS.FAILURE &&
      apiType === ADMIN_GAME_API_TYPES.GET_ADMIN_GAME_DETAILS
    ) {
      history.goBack();
    }
    else if(
      status === API_STATUS.SUCCESS &&
      apiType === ADMIN_GAME_API_TYPES.ENABLE_DISABLE_PARTNER_GAME
    ) {
      handleCloseModal();
      toastr.success( msg.message );
    }
    else if(
      status === API_STATUS.SUCCESS &&
      apiType === ADMIN_GAME_API_TYPES.ADMIN_ADD_MODIFICATION
    ) {
      toastr.success( "Suggestion added successfully" );
      history.goBack();
    }
    else if(
      status === API_STATUS.SUCCESS &&
      apiType === ADMIN_GAME_API_TYPES.UPDATE_PARTNER_GAME_STATUS
    ) {
      toastr.success(
        modalType === MODAL_TYPE_DETAIL.REJECT_GAME.type
          ? "Game rejected successfully."
          : "Game approved successfully."
      );
      history.goBack();
    }
    else if(
      apiType === ADMIN_GAME_API_TYPES.ADD_ADMIN_COMMENT &&
      status === API_STATUS.SUCCESS
    ) {
      toastr.success( "Comment added successfully" );
      handleChange( "comment", "" );
    }
  }, [ apiType, status, msg ] );

  const handleCloseModal = () => {
    closeModal();
    setErrorMessage();
    handleChange( "testingComments", "" );
  };

  const handleInputChange = ( e ) => {
    const { name, value } = e.target;

    handleChange( name, value );

    if( commentError ) {
      setCommentError( "" );
    }
  };

  const handleConfirm = () => {
    const { testingComments } = values;
    const requestObj = {
      partnerId: gameDetails.partnerId,
      gameId: gameDetails.gameId,
    };

    if( modalType === MODAL_TYPE_DETAIL.SUGGEST_MODIFICATION_GAME.type ) {
      if( testingComments ) {
        handleCloseModal();
        dispatch(
          addAdminModificationAction( {
            suggestedModification: testingComments,
            ...requestObj,
          } )
        );
      }
      else {
        setErrorMessage( "Please suggest some modification" );
      }
    }
    else if(
      modalType === MODAL_TYPE_DETAIL.ACTIVE_GAME.type ||
      modalType === MODAL_TYPE_DETAIL.INACTIVE_GAME.type
    ) {
      dispatch(
        enableDisableGameAction( {
          gameId: requestObj.gameId,
          setEnabled:
            modalType === MODAL_TYPE_DETAIL.ACTIVE_GAME.type ? false : true,
        } )
      );
    }
    else if(
      modalType === MODAL_TYPE_DETAIL.REJECT_GAME.type ||
      modalType === MODAL_TYPE_DETAIL.APPROVE_GAME.type
    ) {
      closeModal();
      dispatch(
        updatePartnerGameStatusAction( {
          ...requestObj,
          status:
            modalType === MODAL_TYPE_DETAIL.REJECT_GAME.type
              ? ADMIN_GAME_STATUS_TYPE.REJECTED
              : ADMIN_GAME_STATUS_TYPE.APPROVED,
        } )
      );
    }
    else {
      closeModal();
    }
  };

  const handleOpenModal = ( mType ) => {
    setModalType( mType );
    openModal();
  };

  let viewPermission = true;

  if(
    gameDetails.isPartnerActive === true &&
    adminDetail?.permissions?.includes( ADMIN_PERMISSIONS.MANAGE_GAMES ) &&
    gameDetails.status === ADMIN_GAME_STATUS_TYPE.READY_FOR_TESTING
  ) {
    viewPermission = false;
  }

  const { backScreenRoute } = location;

  const setTab = ( tab ) => {
    if( tab === TABS.GENERAL_INFORMATION ) {
      setViewType( true );
    }

    history.push( {
      pathname: `${ ROUTES.ADMIN_GAME_DETAILS.replace( ":gameId", gameId ) }?tab=${ tab }&from=${ fromScreen }`,
      state: location.state,
      backScreenRoute: backScreenRoute,
    } );
  };

  if( location.state === "" ) {
    location.state = "?tab=GAMES";
  }

  useEffect( () => {
    handleChange( "tab", activeTab );
  }, [ activeTab ] );

  const handleBackNavigation = () => {
    return getBackNavigationRoute( ROUTES.ADMIN_GAME_LIST, location, backScreens );
  };

  return (
    <div className="main-wrapper">
      <div className="main-right-wrapper">
        <BackButton
          backLink={handleBackNavigation()}
          state={ unAllowStateBackScreens.includes( fromScreen ) ? "" : location.state }
        />
        <div className="page-head-wrapper">
          <h4 className="page-heading" data-testid="gameNameTitle">
            {gameDetails.gameName}
          </h4>
          <div className="page-head-right">
            {gameDetails.isPartnerActive === true &&
              adminDetail?.permissions?.includes(
                ADMIN_PERMISSIONS.MANAGE_GAMES
              ) &&
              ( gameDetails.currentStatusId ===
                ADMIN_GAME_STATUS_TYPE.APPROVED ||
                gameDetails.currentStatusId ===
                  ADMIN_GAME_STATUS_TYPE.DISABLED ) && (
              <div className="btn-switch large mr-3">
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="Enabled"
                  checked={gameDetails?.isActive || false}
                  onChange={() =>
                    handleOpenModal(
                      gameDetails?.isActive
                        ? MODAL_TYPE_DETAIL.ACTIVE_GAME.type
                        : MODAL_TYPE_DETAIL.INACTIVE_GAME.type
                    )
                  }
                />
              </div>
            )}
            {gameDetails.currentStatusId ===
              ADMIN_GAME_STATUS_TYPE.READY_FOR_TESTING && (
              <div className="reject-approve">
                <Button
                  disabled={viewPermission}
                  variant="outline-danger"
                  className="btn-md mr-4"
                  onClick={() =>
                    handleOpenModal( MODAL_TYPE_DETAIL.REJECT_GAME.type )
                  }
                >
                  Reject
                </Button>
                <Button
                  disabled={viewPermission}
                  variant="primary"
                  className="btn-lg"
                  onClick={() =>
                    handleOpenModal( MODAL_TYPE_DETAIL.APPROVE_GAME.type )
                  }
                >
                  Approve
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="page-inner-box profile-steps-box message-main-box">
          <>
            <Nav
              variant="tabs"
              defaultActiveKey="link-1"
              className="extra-top-margin"
            >
              <Nav.Item>
                <Nav.Link
                  className="pt-0"
                  active={values.tab === TABS.GENERAL_INFORMATION ? true : "" }
                  onClick={() => setTab( TABS.GENERAL_INFORMATION )}
                  data-testid="setView"
                >
                  General Information
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="pt-0"
                  active={values.tab === TABS.COMMENTS ? true : "" }
                  onClick={() => setTab( TABS.COMMENTS )}
                  data-testid="comments"
                >
                  Comments
                </Nav.Link>
              </Nav.Item>
              { gameDetails.currentStatusId ===
                ADMIN_GAME_STATUS_TYPE.APPROVED &&
              <Nav.Item>
                <Nav.Link
                  className="pt-0"
                  active={values.tab === TABS.LINKED_GAMERS ? true : "" }
                  onClick={() => setTab( TABS.LINKED_GAMERS )}
                  data-testid="comments"
                >
                  Linked Gamers
                </Nav.Link>
              </Nav.Item>
              }
            </Nav>
          </>
          {/* TODO: add props validation. See rule for more information */}
          {/* eslint-disable-next-line react/prop-types */}
          {props.isComment && viewType && (
            <>
              <Button
                variant="outline-primary btn-xl absolute-btn"
                data-testid="suggestModification"
                onClick={() => setViewType( false )}
              >
                Suggest Modification
              </Button>
            </>
          )}
          {/* TODO: add props validation. See rule for more information */}
          {/* eslint-disable-next-line react/prop-types */}
          {( ( props.isComment ) || ( values.tab === TABS.COMMENTS ) ) && (
            <GameCommentSection
              props={props}
              gameTestingComments={GameTestingComments}
            />
          )}
          { values.tab === TABS.LINKED_GAMERS && (
            <GameGamerListing
              gameId={gameId}
              previousState={location.state}
            />
          )}
          { values.tab === TABS.GENERAL_INFORMATION &&
           <GameDetail props={props} viewType={viewType} />}
        </div>
      </div>
      <ModalPopup
        isOpened={isOpened}
        onClose={handleCloseModal}
        type={modalType}
        values={values}
        onChangeInput={handleInputChange}
        errorMessage={errorMessage}
        onConfirm={handleConfirm}
      />
    </div>
  );
};


export default GameDetails;
